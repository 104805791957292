function SvgLoopLimit(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path d='M1.5 24.5l8-8h44l8 8v22h-60z' fill='#fcc' stroke='#838383' />
    </svg>
  );
}
export default SvgLoopLimit;
