import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import useTaxCalculator from '@pw/compliance/useTaxCalculator';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidPriceCurve from '@pw/components/Liquid/LiquidPriceCurve';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import DependentSKUModal from '@pw/components/SKUSelector/modals/DependentSKUModal';
import { Body1, H5, Overline } from '@pw/components/Typography';
import CurveSearch from '@pw/components_v2/search/curves';
import { THING_TYPES } from '@pw/consts/thing';
import { ABV_UNIT_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useIsUsUser } from '@pw/redux/containers/User';
import debounce from '@pw/utilities/debounce';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function propertyFields(fields = {}) {
  const {
    abv = {},
    liquid_type = '',
    price_curve,
    things = [],
  } = fields;

  return {
    abv: unitField(abv),
    liquid_type: [
      liquid_type,
      yup.string().required('Liquid type is required!'),
    ],
    price_curve: [price_curve, yup.object()],
    things: [things, yup.array().of(yup.object())],
  };
}

function LiquidTaxSettings() {
  const { values } = useContext(FormikContext);
  const isUsUser = useIsUsUser();

  const { getTaxCode } = useTaxCalculator();

  const liquidType = values?.['liquid_type'];
  const abv = values?.['abv'];

  const taxCode = useMemo(() => getTaxCode(liquidType, abv), [values]);

  if (!liquidType || !abv || !abv.value || !abv.unit) {
    return;
  }

  if (!taxCode) {
    return (
      <FlexBox className="creator-view">
        <Stack spacing={0.5} className="column">
          <Overline>TAX CODE REQUIRED</Overline>
          <Body1>Please contact support@proofworks.com to enable this</Body1>
        </Stack>
      </FlexBox>
    );
  }

  return (
    <FlexBox className="creator-view">
      <Stack spacing={0.5} className="column">
        <Overline>TAX CODE</Overline>
        <IDDisplay value={taxCode?.code} />
        <DescriptionDisplay value={taxCode?.name} />

        {isUsUser && (
          <CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (<100K)`} value={taxCode?.rate[0].rate}
                       code={taxCode?.currency} />
        )}
        {!isUsUser && (
          <CostDisplay label={`Standard per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[0].rate}
                       code={taxCode?.currency} />
        )}

        {taxCode?.rate.length === 2 && (
          <>
            {isUsUser && (
              <CostDisplay label={`Price per ${taxCode?.unit?.toLocaleUpperCase()} (>100K)`}
                           value={taxCode?.rate[1].rate} code={taxCode?.currency} />
            )}
            {!isUsUser && (
              <CostDisplay label={`Reduced per ${taxCode?.unit?.toLocaleUpperCase()}`} value={taxCode?.rate[1].rate}
                           code={taxCode?.currency} />
            )}
          </>
        )}
      </Stack>
    </FlexBox>
  );
}

function Properties() {
  const { t } = useTranslation();
  const { setFieldValue, values } = useContext(FormikContext);

  const liquidTypes = useLiquidTypes();

  const thingsProps = useMemo(
    () => ({
      title: 'BOM',
      filter: {
        thing_types: [THING_TYPES.LIQUID],
      },
      initialThings: values?.things,
      SKUModal: DependentSKUModal,
    }),
    [values?.things],
  );

  const [[things], , , DependentInventory, DependentModals] =
    useInventorySelectorHook(thingsProps);

  useEffect(() => {
    console.log('Updating things', things);
    debounce(() => setFieldValue('things', things), 25);
  }, [things]);

  return (
    <>
      <FormikProvider path="properties">
        <Stack spacing={3}>
          <Divider><Overline>Properties</Overline></Divider>
          <FlexBox alignItems="top">
            <FormikSelect
              label="Liquid type"
              name="liquid_type"
              options={liquidTypes}
              fullWidth
            />

            <FormikUnitField
              label={`${t('abvTarget')}`}
              name="abv"
              options={ABV_UNIT_OPTIONS}
              fullWidth
            />
          </FlexBox>

          <LiquidTaxSettings />

          <DependentInventory />

          <CurveSearch field="price_curve" />

        </Stack>
      </FormikProvider>
      <DependentModals />
    </>
  );
}

function ThingPage(props) {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...thingSettingsFields(initialValues, THING_TYPES.LIQUID),
      properties: propertyFields(initialValues.properties),
    }),
    [],
  );

  const handleBeforeSave = (entity) => entity;

  return (
    <ThingForm
      thingLabel="Liquid"
      thingType={THING_TYPES.LIQUID}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      {...props}
    >
      <Properties />
    </ThingForm>
  );
}

export default withAppLayout(ThingPage, { title: 'Liquids' });
