import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import PlantView from '@pw/components_v2/CompanyView/modals/plant/PlantView';

function SpecsModal({ company, open, onClose }) {
  return (
    <ModalWithClose onClose={() => onClose()} open={open} title="Plant Management">
      <PlantView company={company} />
    </ModalWithClose>
  );
}

export default SpecsModal;
