import { ArrowForward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { THING_EDIT_URL, THING_TYPE_REVERSE } from '@pw/consts/thing';
import { useCallback } from 'react';

export function ThingListItemComponent({ item }) {
	const thingType =
		THING_TYPE_REVERSE[item.type] ?? item.type ?? '';

	const openThing = useCallback(
		(e) => {
			e.preventDefault();
			e.stopPropagation();

			const route = THING_EDIT_URL?.[item.type];
			console.log('Routing to', route, item._id);
			window.open(`${route}/${item._id}`, '_blank').focus();
		},
		[item],
	);

	return (
		<Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
			<Stack spacing={0.5}>
				<IDDisplay value={`${item._id} [${thingType}]`} onClick={openThing} />
				<NameDisplay name={item.name} />
				<DescriptionDisplay value={item.description} />
			</Stack>
		</Stack>
	);
}

function ThingListComponent({item, onClick, buttonIcon = <ArrowForward />}) {
	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack spacing={0.75}>
					<ThingListItemComponent item={item} />
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onClick && (
					<IconCircleButton onClick={() => onClick(item)}>
						{buttonIcon}
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

export default ThingListComponent;
