import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';

function SalesReturnForm(props) {
  const [FormSubmitter, submitForm] = useFormSubmissionHook();
  const filter = {
    thing_types: [
      THING_TYPES.FINISHED,
      THING_TYPES.SERVICES,
      THING_TYPES.CONSUMABLE,
    ],
  };

  const [[things], [sourceAssets], initSources, SourceInventory, SourceModal] =
    useInventorySelectorHook({
      title: 'Items',
      filter: filter,
      submitForm,
    });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        things: entity?.sources?.things,
        assets: entity?.sources?.assets,
      });
    },
    [initSources],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      return {
        ...entity,
        sources: {
          things,
          assets: sourceAssets,
        },
      };
    },
    [things, sourceAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel='Sales Return'
        requestType={REQUEST_TYPES.SALES_RETURN}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />
        <FormSubmitter />
      </RequestForm>
      <SourceModal />
    </>
  );
}

export default withAppLayout(SalesReturnForm, { title: 'Sales Return' });
