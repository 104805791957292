import { Stack } from '@mui/material';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { useMemo } from 'react';

function TeamView({ item }) {
  const { name, roles = [] } = item;
  const users = useMemo(() => roles.reduce((uc, role) => uc + (role.users?.length ?? 0), 0), [item]);

  return (
    <Stack>
      <EntryTitle value={name} />
      <EntryLabel label="Users" value={users} />
    </Stack>
  );
}
export default TeamView;
