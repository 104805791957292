import { ACCOUNT_STATUS } from '@pw/consts/account';
import { COMPANY_TYPE } from '@pw/consts/company';
import { THING_TYPES } from '@pw/consts/thing';
import getPermissions from '@pw/utilities/getPermissions';
import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';

// Selectors
export const activeUser = (state) => state[SLICE];
export const selectAccountId = createSelector(
	activeUser,
	(user) => user?.account_id,
);
export const selectAccountIdentity = createSelector(
	activeUser,
	(user) => user?.account_identity,
);
export const selectAccountName = createSelector(
	activeUser,
	(user) => user?.account_name,
);
export const selectAccountTokenActive = createSelector(
	activeUser,
	(user) => user?.token_active,
)
export const selectAccountUsername = createSelector(
	activeUser,
	(user) => user?.account_username,
);
export const selectAccountEmail = createSelector(
	activeUser,
	(user) => user?.account_email,
);
export const selectAccountLocation = createSelector(
	activeUser,
	(user) => user?.account_location,
);
export const selectAccountPasskeys = createSelector(
	activeUser,
	(user) => user?.passkeys ?? [],
);
export const selectAccountLastAccess = createSelector(
	activeUser,
	(user) => user?.account_last_access,
);
export const selectAccountStatus = createSelector(
	activeUser,
	(user) => user?.account_status,
);
export const selectUnitFormat = createSelector(
	activeUser,
	(user) => user?.unit_format,
);
export const selectAccountPerms = createSelector(
	activeUser,
	(user) => user?.permissions,
);
export const selectLiquidParsedData = createSelector(
	activeUser,
	(user) => user?.parsedLiquidData,
);
export const selectAccountPlatform = createSelector(
	activeUser,
	(user) => user?.platform,
);
export const selectAccountChain = createSelector(
	activeUser,
	(user) => user?.chain,
);

export const selectIsAccountActive = createSelector(
	selectAccountStatus,
	(status) => status === ACCOUNT_STATUS.ACTIVE,
);

// List of CompanyView the user is associated with
export const selectAllCompanies = createSelector(
	activeUser,
	(user) => user?.companies,
);

// Get the current active company
export const activeCompany = createSelector(
	activeUser,
	(user) => user?.company,
);

export const selectCompanyId = createSelector(
	activeCompany,
	(company) => company?._id,
);

export const selectCompanyName = createSelector(
	activeCompany,
	(company) => company?.name,
);

export const selectCompanyCC = createSelector(
	activeCompany,
	(company) => company?.address?.country,
);

export const selectCompanyType = createSelector(
	activeCompany,
	(company) => company?.company_type,
);

export const selectIsBrokerCompany = createSelector(
	activeCompany,
	(company) => company?.company_type === COMPANY_TYPE.BROKER,
);

export const selectCompanyHasOwnInventory = createSelector(
	selectCompanyType,
	(companyType) => ![COMPANY_TYPE.BROKER, COMPANY_TYPE.BRAND].includes(companyType),
);

export const selectCompanyHasExternalInventory = createSelector(
	selectCompanyType,
	(companyType) => ![COMPANY_TYPE.WAREHOUSE].includes(companyType),
);

export const selectIsCompanyActive = createSelector(activeCompany, () => true);

export const selectIsUsUser = createSelector(activeCompany, (company) =>
	['US'].includes(company?.company_cc),
);

export const selectCanEdit = createSelector(
	selectIsCompanyActive,
	selectIsAccountActive,
	(companyActive, accountActive) => companyActive && accountActive,
);

export const selectCompanyUsers = createSelector(
	activeCompany,
	(company) => company?.users ?? [],
);

export const selectCompanySKUs = createSelector(
	activeCompany,
	(company) => company?.skus ?? [],
);

export const selectCompanyThings = createSelector(
	activeCompany,
	(company) => company?.things ?? [],
);

export const selectCompanyPolicies = createSelector(
	activeCompany,
	(company) => company?.policies ?? [],
);

export const selectCompanySpecifications = createSelector(
	activeCompany,
	(company) => company?.specifications ?? [],
);

export const selectCompanyFacilities = createSelector(
	activeCompany,
	(companies) => companies?.facilities ?? [],
);

export const selectCompanyPriceCurves = createSelector(
	activeCompany,
	(companies) => companies?.price_curves ?? [],
);

export const selectCompanyLiquidTypes = createSelector(
	selectCompanySKUs,
	(skus) => skus.filter((sku) => sku?.sku_type === THING_TYPES.LIQUID),
);

export const selectCompanyRoles = createSelector(
	activeCompany,
	(company) => company?.roles ?? [],
);

export const selectCompanyPermissions = createSelector(
	activeCompany,
	(company) => company?.permissions ?? [],
);

export const selectCompanyTeams = createSelector(
	activeCompany,
	(company) => company?.teams ?? [],
);

export const selectBaseUnits = createSelector(
	activeUser,
	(user) => user?.units,
);

export const selectCompanySensors = createSelector(
	activeCompany,
	(company) => company?.sensors ?? [],
);

export const selectCompanyPartners = createSelector(
	activeCompany,
	(company) => company?.partners ?? [],
);

export const selectCompanyOwners = createSelector(
	activeCompany,
	(company) => company?.owners ?? [],
);
