import { loadInviteThunk } from '@pw/redux/thunks/invite';
import { createSlice } from '@reduxjs/toolkit';
import { SLICE } from './consts';

const initialState = {
  invite: {
    loading: false,
    state: null,
  },
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    .addCase(loadInviteThunk.pending, (state) => {
      console.log('Loading invite');
      state.invite = { loading: true, state: null };
    })
    .addCase(loadInviteThunk.fulfilled, (state, { payload }) => {
      console.log('Loaded invite', payload);
      state.invite = { loading: false, state: payload };
    })
    .addCase(loadInviteThunk.rejected, (state) => {
      state.invite = { loading: false, state: null };
    });
  },
});

export const { reducer, actions } = slice;
// export const {
// } = actions;
