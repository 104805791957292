import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import LedgerSearch from '@pw/components_v2/search/ledger';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { FormikProvider } from '@pw/providers/FormikProvider';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { tags = [] } = fields;

	return {
		tags: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
	};
}

function Properties({ entity }) {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<Divider><Overline>Properties</Overline></Divider>
				<TagSelect entity={entity} name='tags' type={TAG_CATEGORY.ASSET} />
			</Stack>
		</FormikProvider>
	);
}

function TankerForm(props) {
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...assetIdFields(initialValues),
			ledger: [initialValues?.ledger, yup.object()],
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	const handleInit = (entity) => {
		setEntity(entity);
	};

	return (
		<AssetForm
			assetLabel='Tanker'
			assetType={ASSET_TYPES.TANKER}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			onInit={handleInit}
			{...props}
		>
			<LedgerSearch field="ledger" label="Ledger" />
			<Properties entity={entity} />
		</AssetForm>
	);
}

export default withAppLayout(TankerForm, { title: 'Tanker Management' });
