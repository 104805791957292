import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import SearchField from '@pw/components/Search/SearchField';
import useSKUSearch from '@pw/utilities/hooks/logic/useSKUSearch';
import { useCallback, useEffect, useState } from 'react';
import SKUItem from './items/SKUItem';

function SearchSKU({ label, onItemClick, itemFilter = (x) => x, filter, disabled = false }) {
	const [search] = useSKUSearch();
	const [show, setShow] = useState(false);
	const [searchResults, setSearchResults] = useState([]);
	// const searchResults = useMemo(() =>{
	// 	console.log('Items', data, filter);
	// 	if (data){
	// 		if (filter && filter.length > 0) {
	// 			return data.filter((i) => filter.includes(i.sku_type));
	// 		}
	// 		return data;
	// 	}
	// 	return [];
	// },[data, filter]);

	const handleButtonClick = useCallback(
		(sku) => {
			if (onItemClick) {
				onItemClick(sku);
			}
		},
		[onItemClick],
	);

	const handleSearch = (val) => {
		const results = search(filter, val, itemFilter);
		console.log('Results', results);
		setSearchResults(results);
		setShow(results.length > 0);
	};

	const handleClear = () => {
		setShow(false);
	};

	useEffect(() => setShow(false), [disabled]);

	// // https://test.metacask.com:4430/admin/sku/edit/test_sku => /test_sku
	// const handleOnQrRead = async ({ text: qrString }) => {
	//   console.log(qrString);
	//   const path = qrString.replace('/', '');
	//   console.log('Search');
	//   handleSearch(path);
	// };

	return (
		<Stack spacing={2}>
			<Stack className='search-results' spacing={2}>
				<SearchField
					label={label}
					id={label}
					disabled={disabled}
					onClear={handleClear}
					onSearch={handleSearch}
					fullWidth
				/>
				{show && (
					<Collapse in={show}>
						<Stack className='list'>
							{searchResults.map((item) => (
								<SKUItem
									item={item}
									key={item.path}
									onClick={() => handleButtonClick(item)}
								/>
							))}
						</Stack>
					</Collapse>
				)}
			</Stack>
		</Stack>
	);
}

export default SearchSKU;
