import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function ProductionOfWhiskeyReport({
	name,
	title = 'Bourbon',
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='a'
					label='Kind'
					fullWidth
					sx={{ ...(!specify && styles.transaction) }}
				/>
				<FormikTextField
					name='b'
					label='Proof Gallons New Cooperage'
					fullWidth
				/>
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='c'
					label='Proof Gallons Used Cooperage'
					fullWidth
				/>
				<FormikTextField
					name='d'
					label='Proof Gallons Deposited in Tanks'
					fullWidth
				/>
			</FlexBox>
		</FormikProvider>
	);
}
