import { INVENTORY_STATUS, THING_TYPES } from '@pw/consts/thing';

// TODO: add losses and gain calculations
// TODO: check unit conversion
// TODO: add extra liquid details

// Summarize assets and Things
const assetSummary = (assets = [], things = []) => {
  let obl = 0,
    ola = 0,
    rbl = 0,
    rla = 0;
  let sbl = 0,
    sla = 0,
    si = 0,
    sg = 0;
  let nw = 0,
    gw = 0,
    ebl = 0,
    tbl = 0,
    tabv = 0;
  let executed = true;

  // Process assets
  assets.forEach((asset) => {
    const { properties = {}, processed = INVENTORY_STATUS.PENDING } = asset;
    const { liquid = {} } = properties;
    const { level = {} } = liquid;
    const {
      bl = 0,
      la = 0,
      updated_bl = 0,
      updated_la = 0,
      abv = 0,
      updated_abv = 0,
      tcf = 1.0,
      updated_tcf = 1.0,
      grossWeight = 0.0,
      tearWeight = 0.0,
    } = level;

    obl += Number(bl);
    ola += Number(la);
    rbl += Number(updated_bl);
    rla += Number(updated_la);

    const tcfv = Number(updated_tcf || tcf);
    const abvv = Number(updated_abv || abv);
    const bulk = Number(updated_bl || bl);

    tbl += bulk;
    ebl += bulk * tcfv;
    tabv += bulk * abvv;

    nw += Number(tearWeight);
    gw += Number(grossWeight);

    if (executed) executed = processed === INVENTORY_STATUS.CONFIRMED;
  });

  // Process SKUs
  things.forEach((thing) => {
    const {
      type,
      amount = 0,
      liquid_amount,
      processed = INVENTORY_STATUS.PENDING,
      entries = [],
      properties = {},
    } = thing;

    const {
      net_weight = { value: 0 },
      gross_weight = { value: 0 },
      count = 1,
    } = properties;

    if (type === THING_TYPES.FINISHED) {
      const qty = Number(amount);
      si += qty;
      sg += Math.ceil(qty / Number(count));

      sbl += Number(liquid_amount?.bl || 0);
      sla += Number(liquid_amount?.la || 0);

      tbl += Number(liquid_amount?.bl || 0);
      ebl += Number(liquid_amount?.bl || 0);
      tabv += Number(liquid_amount?.la || 0);

      nw += Number(net_weight.value) * qty;
      gw += Number(gross_weight.value) * qty;

      if (executed) executed = processed === INVENTORY_STATUS.CONFIRMED;
    } else if ([THING_TYPES.TRACKED, THING_TYPES.SERVICES].includes(type)) {
      if (executed) executed = processed === INVENTORY_STATUS.CONFIRMED;
    } else {
      if (executed) {
        executed = entries.every((entry) =>
          (entry?.storage || []).every(
            (storage) => storage.processed === INVENTORY_STATUS.CONFIRMED,
          ),
        );
      }
    }
  });

  // Calculate weighted averages and return results
  if (assets.length > 0 || si > 0) {
    const weightedAbv = tbl > 0 ? tabv / tbl : 0;
    const weightedTcf = tbl > 0 ? ebl / tbl : 1.0;

    /*
    expected_bl: {type: Number},
    actual_bl: {type: Number},
    // Tracks losses
    losses: { type: lossRecord, required: [true, 'Loss Records required!'] },
    // Compliance data
    compliance: { type: compliance },
  */

    return {
      count: assets.length,
      thing_count: si,
      obl,
      ola,
      rbl,
      rla,
      abv: parseFloat(weightedAbv.toFixed(2)),
      tcf: parseFloat(weightedTcf.toFixed(3)),
      tare_weight: nw,
      gross_weight: gw,
      bl: rbl + sbl,
      la: rla + sla,
      executed,
    };
  } else {
    return { executed };
  }
};

// Summarize inventory
export const summarizeInventory = (assets = [], things = []) => {
  return assetSummary(assets, things);
};
