import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikDatePicker, FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { CURRENCY_OPTIONS } from '@pw/consts/currencies';
import { TIME_UNIT_OPTIONS } from '@pw/consts/measures';
import { CHARGE_UNITS_OPTIONS, UNITS } from '@pw/consts/units';
import { useIsUnitFormatImperial } from '@pw/redux/containers/User';
import { mt2 } from '@pw/styles/margins';
import styles from '@pw/styles/modal.styles';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function CurveEditor({ item, open, onClose }) {
  const imperial = useIsUnitFormatImperial();
  const label = item?.date ? `Update Price` : `New Price`;
  // Properties from the vendor config..
  const { id, date, price = {}, unit, period } = item ?? {};

  const changeSet = useMemo(
    () => ({
      date: [date ?? Date.now(), yup.number().required('Date required!')],
      price: [
        price,
        yup.object().shape({
          value: yup.number().required('Price required!'),
          unit: yup.string(),
        }),
      ],
      unit: [
        unit ?? (imperial ? UNITS.gallons : UNITS.liters),
        yup.string().required('Unit is required!'),
      ],
      period: [
        period,
        yup.string().required('Period is required!'),
      ],
    }),
    [date, price, unit, imperial],
  );

  const handleSubmit = useCallback(
    (price) => {
      onClose(price);
    },
    [id],
  );

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Stack sx={styles} className='root' spacing={2}>
        <FlexBox>
          <H5>{label}</H5>
          <IconButton
            onClick={() => onClose()}
            className='close-btn'
            aria-label='Close'
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>

        <Box
          sx={{
            height: 'auto',
            maxHeight: 'calc(95vh - 9rem)',
          }}
        >
          <FormikForm
            changeSet={changeSet}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            <Stack spacing={1}>
              <FlexBox alignItems='top'>
                <FormikDatePicker
                  sx={{ ...mt2 }}
                  name='date'
                  label='Price From'
                  fullWidth
                  required
                  />

                 <FormikSelect
                  label='Period'
                  name='period'
                  unitLabel='Period'
                  fullWidth
                  options={TIME_UNIT_OPTIONS}
                />
              </FlexBox>
                <FormikUnitField
                  label='Price'
                  name='price'
                  unitLabel='Currency'
                  fullWidth
                  options={CURRENCY_OPTIONS}
                />
              <FormikSelect
                label='Unit'
                name='unit'
                fullWidth
                options={CHARGE_UNITS_OPTIONS}
              />

              <Errors />

              <FormButtons onCancel={onClose} />
            </Stack>
          </FormikForm>
        </Box>
      </Stack>
    </Modal>
  );
}

export default CurveEditor;
