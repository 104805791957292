import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { CircularProgress, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import {
  AssetListComponentV2,
  AssetListItemComponent,
} from '@pw/components/AssetListItem';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3 } from '@pw/components/Typography';
import debounce from '@pw/utilities/debounce';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useState } from 'react';
import AssetThingStorageItem from '../ThingSelector/items/AssetThingStorageItem';
import { useSnackbar } from 'notistack';

export default function PalletListItem({
  item,
  buttonIcon = <ArrowForward />,
  onClick = undefined,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [showDetails, setShowDetails] = useState(false);
  const [loadAsset, { data: entity, isError, isLoading }] = useAssetLazyQuery();

  const onChange = useCallback(() => {
    setShowDetails((v) => {
      if (v) {
        return false;
      }
      // See if we have not the entity
      if (!Object.keys(entity ?? {}).length) {
        // Trigger a load
        debounce(() => loadAsset({ id: item?._id }), 50);
      }
      return true;
    });
  }, [entity, item?._id, loadAsset]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Failed to load pallet contents', { variant: 'error' });
    }
  }, [isError]);

  const { child_assets = [], sku_storage = [] } = entity ?? {};

  return (
    <Stack spacing={1.25} className='listItem listCol'>
      <FlexBox>
        <Box className='listContent'>
          <AssetListItemComponent item={item} />
        </Box>
        <Stack className='listButtons'>
          <IconCircleButton onClick={onChange} disabled={isLoading}>
            {showDetails ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconCircleButton>
          {onClick && (
            <IconCircleButton onClick={() => onClick(item)}>
              {buttonIcon}
            </IconCircleButton>
          )}
        </Stack>
      </FlexBox>
      {showDetails && (entity || isError || isLoading) && (
        <Collapse in={showDetails} className='listExtended'>
          <Stack spacing={1.2}>
            <Stack className='listContent'>
              {!isLoading &&
                child_assets.length === 0 &&
                sku_storage.length === 0 && <Body3>Empty</Body3>}
              {child_assets.length > 0 &&
                child_assets.map((result) => (
                  <AssetListComponentV2
                    key={result.path ?? result.asset_id}
                    item={result}
                    buttonIcon={buttonIcon}
                    onClick={onClick}
                  />
                ))}
              {isLoading && <CircularProgress size={24} color='primary' />}
            </Stack>
            {sku_storage.length > 0 && (
              <FlexBox
                className='asset-container'
                justifyContent='start'
                gap='0.75rem'
              >
                {sku_storage.map((result) => (
                  <AssetThingStorageItem
                    key={result.thing_record_id}
                    item={result}
                    buttonIcon={buttonIcon}
                    onClick={onClick}
                  />
                ))}
              </FlexBox>
            )}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
}

//
// <Accordion
//   expanded={showDetails}
//   onChange={onChange}
//   sx={styles.pallet}
// >
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="search-results-content"
//     id="search-results"
//   >
//     <AssetListComponentV2 key={item.path} item={item} />
//   </AccordionSummary>
//
//   {entity?.child_assets?.length > 0 && (
//     <AccordionDetails sx={styles.palletContent}>
//       {entity?.child_assets.map((result) => (
//         <AssetListComponentV2
//           key={result.path}
//           item={result}
//           onClick={goToItem}
//         />
//       ))}
//     </AccordionDetails>
//   )}
//   {isLoading && (
//     <AccordionDetails sx={styles.palletContent}>
//       <CircularProgress color="primary" size={30} />
//     </AccordionDetails>
//   )}
// </Accordion>
