import { ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Filter } from '@pw/components/icons';
import { Liquid } from '@pw/components/icons';

const LayoutLiquid = ({ currentWidth, currentHeight }) => {
  const liquid = 234;
  const total = 400;

  const totalProductionData = [
    { id: 1, title: 'Total Liquid', value: 234, total: 400 },
    { id: 2, title: 'Bulk In', value: 0, total: 0 },
    { id: 3, title: 'Bulk Out', value: 0, total: 0 },
    { id: 4, title: 'Storage', value: 0, total: 0 },
    { id: 5, title: 'Operations', value: 0, total: 0 },
  ];
  const liquidMovementData = [
    { id: 1, title: 'Total Liquid', value: 234, total: 400 },
    { id: 2, title: 'Samples', value: 0, total: 0 },
    { id: 3, title: 'Transfers', value: 0, total: 0 },
    { id: 4, title: 'Recask', value: 0, total: 0 },
  ];

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;
  return (
    <Box id='LayoutLiquid'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: isSmallScreen ? '8px' : '16px',
          paddingRight: isSmallScreen ? '24px' : '54px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '16px',
            boxSizing: 'border-box',
          }}
        >
          <IconButton sx={{ marginRight: isSmallScreen ? '10px' : '20px' }}>
            <Liquid
              height={isSmallScreen ? 30 : 43}
              width={isSmallScreen ? 20 : 29}
            />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '9px',
            }}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? '16px' : '19px',
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              LIQUID
            </Typography>
            <Typography
              variant='body1'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? '14px' : '16px',
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              fr.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.01.24
              </Typography>
              <ArrowForward
                sx={{
                  verticalAlign: 'middle',
                  marginX: '4px',
                  width: isSmallScreen ? '30px' : '50px',
                }}
              />
              to.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.02.2024
              </Typography>
            </Typography>
          </Box>
        </Box>

        <IconButton>
          <Filter />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen
            ? '1fr'
            : isMediumScreen
              ? '1fr 1fr'
              : '1fr 1fr 1fr',
          margin: 'auto',
          paddingLeft: isSmallScreen ? '16px' : '42px',
          columnGap: isSmallScreen ? '20px' : '40px',
          rowGap: '7px',
          width: '100%',
        }}
      >
        {/* Total Liquid */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Total Liquid
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: '700',
                fontSize: '13px',
                lineHeight: '31px',
                color: '#393C3C',
              }}
            >
              In
            </Typography>
            <Box
              sx={{
                position: 'relative',
                height: '32px',
                backgroundColor: 'white',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: `${liquid / total}`,
                  backgroundColor: '#6B57D2',
                }}
              />
              <Typography
                component='span'
                sx={{
                  position: 'absolute',
                  left: `${10}px`,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  fontWeight: '700',
                  fontSize: '12px',
                  lineHeight: '31px',
                }}
              >
                {liquid}L
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: '700',
                fontSize: '13px',
                lineHeight: '31px',
                color: '#393C3C',
              }}
            >
              Out
            </Typography>
            <Box
              sx={{
                position: 'relative',
                height: '32px',
                backgroundColor: 'white',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundColor: '#C7DA65',
                }}
              />
              <Typography
                component='span'
                sx={{
                  position: 'absolute',
                  left: `${10}px`,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: 'white',
                  fontWeight: '700',
                  fontSize: '12px',
                  lineHeight: '31px',
                }}
              >
                {25}L
              </Typography>
            </Box>
          </Box>
          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Total Production */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Total Production
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //   padding: '20px',
            }}
          >
            {totalProductionData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom:
                    index !== totalProductionData.length - 1
                      ? '1px solid #DDDDDD'
                      : 'none',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '13px',
                    lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  {item.value}/
                  <Typography
                    component='span'
                    sx={{
                      fontWeight: '600',
                      fontSize: 'inherit',
                      color: 'inherit',
                    }}
                  >
                    {item.total}L
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Liquid Movement  */}
        <Box sx={{ position: 'relative', paddingRight: '54px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Liquid Movement
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //   padding: '20px',
            }}
          >
            {liquidMovementData.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom:
                    index !== liquidMovementData.length - 1
                      ? '1px solid #DDDDDD'
                      : 'none',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: '13px',
                    lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  {item.value}/
                  <Typography
                    component='span'
                    sx={{
                      fontWeight: '600',
                      fontSize: 'inherit',
                      color: 'inherit',
                    }}
                  >
                    {item.total}L
                  </Typography>
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutLiquid;
