function SvgDocument(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M1.5 15.5h60v29.376c-30-12.006-30 12.346-60-2.129z'
        fill='#cef'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgDocument;
