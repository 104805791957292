import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/designs`;

export const saveDesign = async (data, headers = {}) =>
	fetchPost(`${BASE}/recipes`, headers, data);

export const saveProcessDesign = async (data, headers = {}) =>
	fetchPost(`${BASE}/process`, headers, data);

export const listDesigns = async (params, headers = {}) =>
	fetchGet(`${BASE}/recipes`, headers);

export const saveDesignBatch = async (data, headers = {}) =>
	fetchPost(`${BASE}/batches`, headers, data);

export const saveDesignObservations = async (data, headers = {}) =>
	fetchPost(`${BASE}/observations`, headers, data);

export const getDesignObservations = async (params, headers = {}) =>
	fetchGet(`${BASE}/observations${objectToQueryString(params)}`, headers);

export const getDesign = async (params, headers = {}) =>
	fetchGet(`${BASE}/recipes/${encodeURIComponent(params)}`, headers);

export const getDesignBatch = async (batchId, headers = {}) =>
	fetchGet(`${BASE}/batches/${encodeURIComponent(batchId)}`, headers);

export const getDesignList = async (params, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);

export const getProcessDesign = async (params, headers = {}) =>
	fetchGet(`${BASE}/process/${encodeURIComponent(params)}`, headers);

export const getRecipeBatchesList = async (params, headers = {}) =>
	fetchGet(`${BASE}/batches${objectToQueryString(params)}`, headers);

