function SvgUploadCsv(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M1.737 19.205c-.412-.096-.796-.245-1.107-.546a1.955 1.955 0 01-.623-1.362 5.324 5.324 0 01-.004-.206c0-4.994.001-9.987-.003-14.98C0 1.312.312.696.993.277A1.968 1.968 0 012.048.001h3.075c2.945 0 5.889-.002 8.832 0 1.033 0 1.845.678 2.02 1.678.023.141.024.286.024.43 0 4.993.002 9.987 0 14.98 0 1.107-.608 1.855-1.687 2.085-.017.004-.031.019-.046.028H1.737v.003zM.8 9.603v7.482c0 .106 0 .214.019.317.115.63.6 1.007 1.296 1.007H13.87c.847 0 1.332-.49 1.332-1.343V2.141c0-.852-.485-1.342-1.333-1.342H2.13C1.285.8.8 1.29.8 2.142v7.463-.002z'
        fill='#404040'
      />
      <path
        d='M8.465 12.004c.238 0 .475-.004.712 0 .253.005.428.176.426.404-.004.22-.173.387-.418.39-.456.005-.911-.003-1.367.004-.315.005-.557.211-.603.489a.586.586 0 00.386.67c.104.034.22.028.332.038.224.022.455.011.668.07.662.179 1.079.83.99 1.506A1.39 1.39 0 018.244 16.8c-.468.01-.937.006-1.406.002-.255-.002-.432-.168-.435-.395-.002-.225.174-.396.427-.399.45-.005.9.007 1.35-.004.499-.012.783-.507.532-.913a.57.57 0 00-.497-.285c-.2-.005-.401 0-.598-.024-.714-.089-1.251-.728-1.214-1.431.041-.743.622-1.326 1.35-1.347.237-.006.475 0 .713 0h-.001zM2.403 14.393c0-.387-.004-.775 0-1.162.01-.72.516-1.223 1.233-1.227.5-.002 1-.001 1.5 0 .28 0 .464.164.462.403-.003.234-.178.39-.454.392-.48.003-.962 0-1.443.001-.356 0-.503.147-.503.499v2.193c0 .376.141.515.524.516h1.406c.289 0 .468.155.47.395 0 .24-.184.4-.466.401-.506.002-1.013.004-1.518 0a1.189 1.189 0 01-1.21-1.192c-.009-.406-.002-.812-.002-1.218l.001-.001zM12.002 15.004c.09-.29.164-.533.237-.776l.569-1.9c.056-.186.171-.308.37-.32.18-.01.33.07.383.24a.669.669 0 01-.006.362c-.352 1.198-.713 2.392-1.072 3.587-.026.089-.053.18-.082.268-.072.225-.203.337-.395.338-.195.001-.333-.116-.404-.35-.2-.656-.396-1.314-.594-1.971a953.803 953.803 0 01-.578-1.937c-.066-.247.038-.454.26-.524.217-.066.426.052.503.3.129.41.25.824.373 1.236l.436 1.447zM8.377 3.76v5.226c0 .037.001.075-.001.112a.355.355 0 01-.353.34.356.356 0 01-.343-.338c-.003-.037-.001-.074-.001-.112V3.757c-.045.043-.074.069-.1.097L6.32 5.146c-.118.12-.261.15-.399.088a.34.34 0 01-.203-.35.435.435 0 01.1-.227 306.98 306.98 0 011.96-2.014c.156-.16.353-.155.513.009.656.673 1.312 1.347 1.966 2.022a.368.368 0 01.012.513c-.136.144-.358.143-.506-.009-.439-.448-.875-.898-1.312-1.347-.018-.019-.036-.036-.075-.072z'
        fill='#404040'
      />
    </svg>
  );
}
export default SvgUploadCsv;
