const styles = {
  '.flex': {
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '0.5rem',
    alignItems: 'center',
  },
  '&.top-bar-root': {
        // position: 'absolute',
    position: 'absolute',
    top: 0,
    zIndex: 99,
    right: 0,
    // width: 'calc(100vw - 250px - 1rem)',
    width: '100%',
    backgroundColor: 'white',
    // display: 'flex',
    // flexFlow: 'row nowrap',
    // columnGap: '0.5rem',
    // alignItems: 'center',
    // position: 'absolute',
    // top: '2rem',
    padding: '1rem',
    borderRadius: '0.3rem',
    // width: 'calc( var(--window-width) - var(--horizontal-gap) )',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    columnGap: '3rem',
    backgroundColor: 'white',
    boxShadow: '0px 3px 3px 0px #00000012',
  },
  '.paging-buttons': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    columnGap: 0,

    '.viewing': {
      whiteSpace: 'pre',
      fontWeight: '500',
      fontSize: '14px',
      verticalAlign: 'baseline',
      width: '5rem', // prevent wriggling around as range changes
      transition: 'width 0.3s',
    },

    button: {
      px: '0.5rem'
    },
  },

  '.create-batch-root': {
    flexGrow: 1,
    // marginLeft: 'auto',
    // marginRight: 0,
    // justifyContent: 'flex-end',
    columnGap: '1rem',
    alignItems: 'center',
    justifyContent: 'space-between',

    // button: {
    //   flexGrow: 0,
    //   width: 'fit-content',
    //   flexShrink: 1,
    // },

    '.create-batch-select': {
      width: 'auto',
      flexGrow: 1,
      flexShrink: 0,
    },

    '.MuiButton-root': {
      flexGrow: 0,
      flexShrink: 1,
    },
  },

  '.viewmode-day': {
    '.viewing': {
      width: '2.5rem',
      flexShrink: 0,
      flexGrow: 0,
    },
  },

  '.sub-header': {
    justifyContent: 'space-between',
    columnGap: '3rem',
  },

  '.pipeline': {
    fontSize: '1.25rem',
  },
  '.recipe': {
    fontSize: '17px',
    textTransform: 'uppercase',
    columnGap: 0,
    marginLeft: 'auto',
  },

  '.recipe-select.MuiInputBase-root': {
    backgroundColor: 'unset',
    border: 'none',
    fontWeight: 400,

    '.MuiSelect-select, fieldset': {
      backgroundColor: 'unset',
      border: 'none',
    },

    '.MuiSelect-select': {
      py: '0.5rem',

      '&::after': {
        display: 'block',
        content: "''",
        position: 'absolute',
        height: '1rem',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '1rem',
        borderRight: '1px solid',
      },

    },

    svg: {
      display: 'block',
      marginRight: '-1rem',
    },
  },

  '.plant-list': {
    columnGap: '1.25rem'
  },
  '.plant-item': {
    backgroundColor: '#00A89D',
    color: 'white',
    borderRadius: '3px',
    padding: '0 0.5rem',
    height: '28px',
    lineHeight: 1,
    fontSize: '12px',

    '.label': {
      paddingRight: '0.25rem',
      textTransform: 'capitalize'
    },

    svg: {
      fontSize: '1.2rem',
    },

    '.count': {
      border: '1px solid #00A89D',
      backgroundColor: 'white',
      height: '100%',
      marginRight: '-0.5rem',
      padding: '0.5rem',
      borderRadius: '3px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      color: '#7B7B7B',
    },
  },

  '.timeline-body-root': {
    top: '5rem',
    bottom: 0,
    right: 0,
    width: 'var(--window-width)',
    position: 'absolute',
    zIndex: 99,
    overflowX: 'auto',
    // backgroundColor: '#fafafa',
    backgroundColor: '#F7F6F4',
    // backgroundColor: '#FFFFFF',
    columnGap: '0.5rem',
    borderBottomLeftRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
    overflow: 'hidden',
    boxShadow: '0px 3px 0 0px #00000012',

    // background: '#0ff2',
  },

  '.timeframe-buttons': { marginRight: 'auto' },
  '.viewmode, .pan': {
    fontSize: '0.75rem',
    borderRadius: '99rem',
    boxSizing: 'content-box',
    minHeight: 0,
    minWidth: 0,
    padding: '0.125rem 1rem',
    border: '2px solid transparent',

    '&:hover': {
      border: '2px solid #D9D9D9',
    },

    '&.active': {
      backgroundColor: '#D9D9D9',
    },
    '&:not([disabled])': {
      color: 'black',
    },
  },

  '.pan': {},

  '.overflow': {
    width: 'var(--horizontal-gap)',
    position: 'absolute',
    top: '9rem',
    marginTop: '-4px',
    paddingTop: '1rem',
    textAlign: 'center',
    bottom: 0,
    backgroundColor: 'white',

    '&.previous': {
      left: 0,
      borderRight: '1px solid #F7F6F4',
    },
    '&.next': {
      right: 0,
      borderLeft: '1px solid #F7F6F4',

    },
  },
}

export default styles;
