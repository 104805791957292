import Stack from '@mui/material/Stack';
import { FormikCheckBox, FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { CompanyType } from '@pw/components_v2/contact/consts';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

function CompanyTypeSelector() {
  const { values, setFieldValue } = useFormikContext();
  const featuresFieldName = 'features';

  useEffect(() => {
    if (values.company_type === CompanyType.DISTILLERY) {
      setFieldValue(`${featuresFieldName}.production`, true);
      setFieldValue(`${featuresFieldName}.warehousing`, true);
      setFieldValue(`${featuresFieldName}.bottling`, true);
    }
    if (values.company_type === CompanyType.WAREHOUSE) {
      setFieldValue(`${featuresFieldName}.production`, false);
      setFieldValue(`${featuresFieldName}.warehousing`, true);
      setFieldValue(`${featuresFieldName}.bottling`, false);
    }
    if (values.company_type === CompanyType.BRAND) {
      setFieldValue(`${featuresFieldName}.production`, false);
      setFieldValue(`${featuresFieldName}.warehousing`, false);
      setFieldValue(`${featuresFieldName}.bottling`, false);
    }
    if (values.company_type === CompanyType.BROKER) {
      setFieldValue(`${featuresFieldName}.production`, false);
      setFieldValue(`${featuresFieldName}.warehousing`, false);
      setFieldValue(`${featuresFieldName}.bottling`, false);
    }
    if (values.company_type === CompanyType.CO_PACKER) {
      setFieldValue(`${featuresFieldName}.production`, false);
      setFieldValue(`${featuresFieldName}.warehousing`, true);
      setFieldValue(`${featuresFieldName}.bottling`, true);
    }
  }, [values.company_type]);

  const companyOptions = [
    { value: CompanyType.DISTILLERY, label: 'Distillery' },
    { value: CompanyType.WAREHOUSE, label: 'Warehouse' },
    { value: CompanyType.BRAND, label: 'Brand' },
    { value: CompanyType.BROKER, label: 'Broker' },
    { value: CompanyType.CO_PACKER, label: 'Co-Packer' },
    { value: CompanyType.COOPERAGE, label: 'Cooperage' },
  ];

  return (
    <Stack spacing='1.5rem'>
      <H5>Profile</H5>
      <FormikSelect
        name='company_type'
        label='Company Type'
        options={companyOptions}
      />
      <FormikProvider path={featuresFieldName}>
        <FlexBox>
          <FormikCheckBox name='production' label='Production' disabled />
          <FormikCheckBox name='warehousing' label='Warehousing' disabled />
          <FormikCheckBox name='bottling' label='Bottling' disabled />
        </FlexBox>
      </FormikProvider>
    </Stack>
  );
}

export default CompanyTypeSelector;
