import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { validateRow } from '@pw/components/BatchUpload/utils';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetSummaryList from '@pw/components/ThingSelector/items/AssetSummaryList';
import { ASSET_CODE } from '@pw/consts/asset';
import { COMP, ID } from '@pw/utilities/comp';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import parseIdPattern from '@pw/utilities/parseIdPattern';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';

function AssetPreview({
  thing,
  rawData,
  mappings,
  model,
  assetType,
  onBack,
  onCancel,
  onConfirm,
}) {
  // Grab the raw data
  const { data: fileData } = rawData ?? {};
  const { data = [] } = fileData ?? {};

  const fieldsToDisplayKeyValueTuple = useMemo(
    () => Object.entries(mappings).filter(([, value]) => value),
    [mappings],
  );

  const [assets, initAssets, , , removeAsset] = useItemListManager();

  const assetList = useMemo(() => {
    const [prefix] = parseIdPattern(thing?.prefix);

    const allAssets = {};

    // We're going to build a unique asset code
    const today = dayjs();

    data.forEach((row) => {
      const matchedData = fieldsToDisplayKeyValueTuple.reduce(
        (acc2, [key, values]) => ({
          ...acc2,
          ...{ [key]: values.map((value) => row[value]).join(', ') },
        }),
        {},
      );
      if (!matchedData.rw_asset_id) {
        return;
      }
      // console.log('Row data', matchedData);
      const transformedData = validateRow(model, matchedData);
      // console.log(' --> transformed', transformedData);

      const {
        rw_parent_asset_id,
        rw_asset_id,
        parent_asset_id,
        check_in_date,
        date,
        liquid_tags,
        cask_tags,
        abv,
        bl,
        la,
        tcf,
      } = transformedData;

      let liquid;
      if (abv && bl && la) {
        let abvv = Number(abv.replace(/[^-0-9.]/g, ''));
        if (abvv < 1.0) {
          abvv *= 100;
        }

        liquid = {
          enable: true,
          liquid: liquid_tags ? model.liquid_tags?.transform(liquid_tags) : [],
          date: model.date?.transform(date, today).valueOf(),
          level: {
            enable: true,
            bl: Number(bl.trim()).toFixed(2),
            la: Number(la.trim()).toFixed(2),
            abv: Number(abvv ?? 0)?.toFixed(2),
            tcf: Number(tcf ?? 1.0).toFixed(3),
            filled_date: model.date?.transform(date, today).valueOf(),
          },
        };
      }

      const cd = model.check_in_date?.transform(check_in_date, today);
      const time = cd.valueOf();
      const dcode = cd.format('YYYYMM');

      let parent;

      // Construct the main asset
      const assetCode = ASSET_CODE[assetType];
      const asset_id = `${prefix}-${assetCode}-${rw_asset_id}-${dcode}`;

      if (rw_parent_asset_id && model.rw_parent_asset_id) {
        // Generate parent asset id
        const parentAssetCode =
          ASSET_CODE[model.rw_parent_asset_id?.asset_type];
        const parent_asset_id = `${prefix}-${parentAssetCode}-${rw_parent_asset_id}-${dcode}`;

        if (Object.hasOwn(allAssets, parent_asset_id)) {
          const existing = allAssets[parent_asset_id];
          // Update the children
          existing.children = [
            ...(existing.children ?? []),
            { asset_id, asset_type: assetType },
          ];
        } else {
          // New parent
          allAssets[parent_asset_id] = {
            asset_id: parent_asset_id,
            rw_asset_id: parent_asset_id,
            asset_type: model.rw_parent_asset_id?.asset_type,
            create_date: time,
            properties: {
              reference: rw_parent_asset_id,
            },
            children: [
              { rw_asset_id: asset_id, asset_id, asset_type: assetType },
            ],
          };
        }

        parent = {
          asset_id: parent_asset_id,
          rw_asset_id: parent_asset_id,
          asset_type: model.rw_parent_asset_id?.asset_type,
        };
      }

      // Main asset
      allAssets[asset_id] = {
        asset_id,
        rw_asset_id: asset_id,
        asset_type: assetType,
        create_date: time,
        properties: {
          reference: rw_asset_id,
          thing: thing?._id,
          cask: cask_tags ? model.cask_tags?.transform(cask_tags) : [],
          liquid,
        },
        parent_asset_id,
        parent_asset: parent,
      };
    });
    return Object.values(allAssets).sort(COMP.asset);
  }, [data]);

  useEffect(() => {
    console.log('Asset list', assetList.length);
    initAssets(assetList);
  }, [assetList]);

  const handleConfirm = useCallback(() => {
    onConfirm(assets);
  }, [assets]);

  return (
    <Stack spacing={1}>
      <AssetSummaryList items={assets} onRemove={removeAsset} />

      <FlexBox alignItems='baseline'>
        <TextButton size='small' handleClick={onBack} color='secondary'>
          Back
        </TextButton>

        <Box className='action-buttons'>
          <TextButton size='small' handleClick={onCancel} color='secondary'>
            Cancel
          </TextButton>
          <FilledButton size='small' handleClick={handleConfirm}>
            Confirm
          </FilledButton>
        </Box>
      </FlexBox>
    </Stack>
  );
}

export default AssetPreview;
