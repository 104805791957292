import handleError from '@pw/redux/thunks/utils/handleError';
import { createDesign, removeDesign, updateDesign } from '@pw/services/design.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertDesignThunk = createAsyncThunk(
  `design/upsert`,
  async (design, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting design', design);
      const response = (design._id) ? await updateDesign(design) : await createDesign(design);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const removeDesignThunk = createAsyncThunk(
  `design/remove`,
  async (design, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Removing design', design);
      const response = await removeDesign(design);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);
