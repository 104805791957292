import { Box } from '@mui/material';
import { useRef } from 'react';
import ReactSwipe from 'react-swipe';

function View({ ListView, Editor }) {
  const reactSwipeEl = useRef(null); 
  return (
    <ReactSwipe
      swipeOptions={{ continuous: false, disableScroll: true }}
      ref={reactSwipeEl}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'flex-start' }}>
        <ListView showEditor={() => reactSwipeEl.current?.next()} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'flex-start' }}>
        <Editor hideEditor={() => reactSwipeEl?.current?.prev()} />
      </Box>
    </ReactSwipe>
  );
}

export default View;
