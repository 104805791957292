function SvgSummingJunction(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <circle cx={31.5} cy={31.5} fill='#ccffd5' r={12} />
      <g fill='none'>
        <path d='M23.1 23.1l16.8 16.8M39.9 23.1L23.1 39.9' />
      </g>
    </svg>
  );
}
export default SvgSummingJunction;
