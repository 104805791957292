import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikCheckBox, FormikForm, RenderIF } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import DesignerContext from '@pw/context/DesignerContext';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';
import styles from './modal.styles';

function ModalWrapper({
  changeSetGenerator,
  useDefault = true,
  children
}) {
  const {
    selectedNode,
    setSelectedNode,
    selectedNodeData,
    saveNodeData,
  } = useContext(DesignerContext);

  const handleClose = useCallback(() => {
    setSelectedNode(null);
  }, [setSelectedNode]);

  const handleSubmit = useCallback((state) => {
    console.log('Updating node state', state);
    debounce(() => {
      saveNodeData({ ...selectedNodeData, ...state });
      setSelectedNode(null);
    }, 25);
  }, [saveNodeData, setSelectedNode]);

  const changeSet = useMemo(() => ({
    default: [selectedNodeData?.default ?? true, yup.boolean()],
    ...changeSetGenerator(selectedNodeData),
  }), [changeSetGenerator, selectedNodeData]);

  return (
    <ModalWithClose open={!!selectedNode} onClose={handleClose} title={selectedNode?.data?.label} modalStyles={styles}>
      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
      >
        <FormWrapper>
          {useDefault && (
            <>
              <FormikCheckBox name="default" label="Default" />
              <RenderIF check={({ values }) => !values?.default}>
                {children}
              </RenderIF>
            </>
          )}
          {!useDefault && (
            <>
              {children}
            </>
          )}

          <Box className='action-buttons'>
            <TextButton size='small' handleClick={handleClose} color='secondary'>
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </FormWrapper>
      </FormikForm>
    </ModalWithClose>
  );
}

export default ModalWrapper;
