import { Box } from '@mui/material';
import SvgUser from '@pw/components/icons/User';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function UsersDisplay({ label = undefined, count}) {
	if (!count) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<SvgUser style={{ width: '12px', height: 'auto' }} />
			<Body3>
				<Bolder>{label ?? 'Users'}</Bolder>
				&nbsp;
				{count}
			</Body3>
		</Box>
	);
}

export default UsersDisplay;
