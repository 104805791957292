import mapToOptions from '@pw/utilities/mapToOptions';

export const FACILITY_TYPE = {
	WAREHOUSE: "warehouse",//"WAREHOUSE"
	YARD: "yard",//"YARD"
	DISTILLERY: "distillery",//"DISTILLERY"
	LAB: "lab",//"LAB"
	WORKSHOP: "workshop",//"WORKSHOP"
	FACTORY: "factory",//"FACTORY"
	BOTTLING_HALL: "bottling_hall",//"BOTTLING_HALL"
};

export const FACILITY_STATUS = {
	ACTIVE: 'active',
	UNAVAILABLE: 'unavailable',
};

export const FACILITY_STATUS_OPTIONS = mapToOptions(FACILITY_STATUS);

export const FACILITY_TYPES_OPTIONS = [
	{ label: "Warehouse", value: FACILITY_TYPE.WAREHOUSE },
	{ label: "Yard", value: FACILITY_TYPE.YARD },
	{ label: "Distillery", value: FACILITY_TYPE.DISTILLERY },
	{ label: "Lab", value: FACILITY_TYPE.LAB },
	{ label: "Workshop", value: FACILITY_TYPE.WORKSHOP },
	{ label: "Factory", value: FACILITY_TYPE.FACTORY },
	{ label: "Bottling Hall", value: FACILITY_TYPE.BOTTLING_HALL },
];

export const LOCATION_PRINT_MAP = {
	ALL: 'all',
	FACILITIES: 'facilities',
	BAYS: 'bays',
	ROWS: 'rows',
	//all: 'a',
	// facilities: 'f',
	// bays: 'b',
	// rows: 'r',
};

export const LOCATION_PRINT_OPTIONS = [
	{ label: 'All Locations', value: LOCATION_PRINT_MAP.ALL },
	{ label: 'Facilities Only', value: LOCATION_PRINT_MAP.FACILITIES },
	{ label: 'Bays Only', value: LOCATION_PRINT_MAP.BAYS },
	{ label: 'Rows Only', value: LOCATION_PRINT_MAP.ROWS },
];
