function SvgBay(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 23 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Bay_svg__clip0_1_220)'>
        <path
          d='M9.406 14.527l.27-.144L23 6.36a.512.512 0 00-.274-.484 647.89 647.89 0 01-4.172-2.527.554.554 0 00-.184-.075.622.622 0 00-.156-.127A650.905 650.905 0 0114.042.62c-.184-.112-.346-.11-.54.009-1.642.995-2.92 1.857-4.193 2.612-.107.064-.196.087-.307.172A519.58 519.58 0 014.83 5.919l-.044.028a.794.794 0 01-.157.083.75.75 0 00-.111.056 515.701 515.701 0 01-4.27 2.565.62.62 0 00-.23.22.125.125 0 00.04.168l8.917 5.396c.109.066.31.127.432.092h-.001zm2.624-2.244l-.696.418c-.597.359-1.194.717-1.79 1.077l-.276.165-.308-.174v.002c-.852-.52-1.706-1.037-2.56-1.554l-.993-.602 1.947-1.171 1.14-.685.049-.03.646-.39.044.026c.061.037.099.06.128.07l.093.062c.023.017.042.03.054.037l.941.57c.848.514 1.724 1.044 2.595 1.567l-1.015.611v.001zm6.262-8.394l.498.302v.002l.05.03c1.116.67 2.226 1.344 3.3 1.995l-.304.183-.913.55-.672.404c-.62.372-1.24.745-1.86 1.12l-.178.108-.142-.063c-1.314-.79-2.537-1.531-3.731-2.26l2.023-1.217c.455-.274.91-.547 1.366-.818l.563-.335zm-6.12-1.769c.494-.298 1.005-.606 1.511-.902.047-.027.137-.03.169-.011 1.126.673 2.266 1.366 3.37 2.035l.406.247-.322.194-.895.539-.743.446c-.64.384-1.278.768-1.917 1.154l-.012.007c-1.325-.797-2.621-1.58-3.854-2.334L12.17 2.12h.002zM7.76 4.852c.492-.297 1-.603 1.504-.898l.123.074.123.063v-.013l.915.554a641.69 641.69 0 002.712 1.636l-.221.134-.905.544-.724.435c-.646.388-1.291.776-1.935 1.166-.074.044-.108.054-.149.041l-1.107-.667-.244-.147-2.05-1.245-.412-.25 2.372-1.427H7.76zM3.178 7.584c.498-.3 1.012-.61 1.522-.908a.238.238 0 01.122-.022l.164.1.123.062V6.81c1.078.646 2.17 1.308 3.227 1.949l.31.188-.316.19c-.303.184-.603.364-.901.543l-.739.444c-.64.386-1.193.68-1.834 1.066-.089.054-.124.063-.192.022-.917-.56-1.836-1.117-2.755-1.673L.893 8.923 3.18 7.585h-.001z'
          fill='#404040'
        />
      </g>
      <defs>
        <clipPath id='Bay_svg__clip0_1_220'>
          <path fill='#fff' transform='translate(0 .538)' d='M0 0h23v14H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgBay;
