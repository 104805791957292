import { SellOutlined } from "@mui/icons-material";
import TitledButton from "@pw/components/Buttons/TitledButton";
import { useSnackbar } from "notistack";
import { useState } from "react";
import PrintLabelsModal from "./PrintLabelsModal";

function PrintLabel({ thing }) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleShow = () => {
    const releases = thing?.properties?.releases ?? [];

    if (releases.length === 0) {
    	enqueueSnackbar('No releases found for this Thing!', { variant: 'error' });
    	return;
    }

    setOpen(true);
  };

  return (
    <>
      <TitledButton
        handleClick={() => handleShow()}
        label='Label'
      >
        <SellOutlined height={24} width={24} />
      </TitledButton>

      <PrintLabelsModal
        open={open}
        onClose={() => setOpen(false)}
        thing={thing}
      />
    </>
  )
}

export default PrintLabel;
