import { AccountCircle, VerifiedOutlined } from '@mui/icons-material';
import { Divider, Skeleton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import SocialRegister from '@pw/components/Auth/SocialRegister';
import FilledButton from '@pw/components/Buttons/FilledButton';
import {
  FormikCheckBox,
  FormikForm,
  FormikPasswordField,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Identity from '@pw/components/Identity';
import Instructions from '@pw/components/Instructions';
import WithHelp from '@pw/components/Instructions/WithHelp';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { CountdownTimer } from '@pw/components/Timer/Countdown';
import { Body1, Body2, H5 } from '@pw/components/Typography';
import { IDENTITY } from '@pw/consts/account';
import CountdownContext from '@pw/context/CountdownContext';
import CountdownProvider from '@pw/providers/CountdownProvider';
import TimerProvider from '@pw/providers/TimerProvider';
import { useInviteStateHook } from '@pw/redux/containers/Public/hooks';
import {
  challengeIdentResendThunk,
  challengeIdentThunk,
  registerUserThunk,
  verifyIdentThunk,
} from '@pw/redux/thunks/register';
import generateUsername from '@pw/utilities/generateUsername';
import useInviteHook from '@pw/utilities/hooks/service/useInviteHook';
import usePartnerConnectLinkProcess from '@pw/utilities/hooks/service/usePartnerConnectLinkProcess';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

function RegisterForm({ account, invite, showVerification, showRegistration }) {
  const dispatch = useDispatch();
  const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};
  const changeSet = {
    type: [type, yup.string().required('Identity type is required!')],
    ident: [
      ident,
      yup.string().test({
        name: 'valid_identity',
        test: (value, ctx) => {
          // console.log('Testing', value, ctx.parent.type);
          switch (ctx.parent.type) {
            case IDENTITY.EMAIL: {
              if (!value) {
                return ctx.createError({
                  path: 'identity',
                  message: 'Email is required!',
                });
              }
              const valid = yup.string().email().isValidSync(value);
              // console.log(' -->', value, valid);
              if (!valid) {
                return ctx.createError({
                  path: 'identity',
                  message: 'Invalid email!',
                });
              }
              break;
            }
            case IDENTITY.PHONE: {
              if (!value) {
                return ctx.createError({
                  path: 'identity',
                  message: 'Phone number is required!',
                });
              }
              const valid = yup.string().phone().isValidSync(value);
              // console.log(' -->', value, valid);
              if (!valid) {
                return ctx.createError({
                  path: 'identity',
                  message: 'Invalid phone number!',
                });
              }
              break;
            }
            default: {
              break;
            }
          }
          return true;
        },
      }),
    ],
  };

  const handleSubmit = async (values) => {
    dispatch(challengeIdentThunk({ ...values, invite }))
      .unwrap()
      .then((response) => {
        const { otp, name, identity } = response;
        if (otp) {
          // Move to the verification step
          showVerification(values);
        } else {
          // Move to the registration step
          showRegistration({ ...values, name, identity });
        }
      });
  };

  return (
    <>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={4}>
          <Instructions>
            Enter your email address or phone number to start the registration
            process.
          </Instructions>
          <Identity type='type' name='ident' nopasskey disbaled={invite} />
          <FilledButton
            icon={<AccountCircle />}
            iconPosition='start'
            label='Continue'
            type='submit'
          />
        </Stack>
      </FormikForm>

      <Divider>or</Divider>

      <SocialRegister invite={invite} showRegistration={showRegistration} />
    </>
  );
}

function ResendCode({ resendCode }) {
  const { done } = useContext(CountdownContext);

  return (
    <Box>
      {!done && (
        <Body2>
          Please wait&nbsp;
          <CountdownTimer />, before re-requesting the code.
        </Body2>
      )}
      {done && (
        <Body2>
          Click&nbsp;
          <span className='resend' onClick={resendCode}>
            here
          </span>
          &nbsp;to resend the code.
        </Body2>
      )}
    </Box>
  );
}

function VerificationForm({ account, changeIdentity, showRegistration }) {
  const dispatch = useDispatch();
  const { type = IDENTITY.EMAIL, ident = '' } = account ?? {};
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 5 * 60 * 1000),
  );

  const changeSet = {
    otp: ['', yup.string().required('Verification code is required!')],
  };

  const handleSubmit = async (values) => {
    dispatch(
      verifyIdentThunk({
        ...account,
        otp: values.otp,
      }),
    )
      .unwrap()
      .then((response) => {
        showRegistration({ ...account, ...response });
      });
  };

  const resendCode = async () => {
    dispatch(challengeIdentResendThunk(account))
      .unwrap()
      .then((response) => {
        console.log('Resending code, and restarting timer', response);
        setEndTime(new Date(new Date().getTime() + 5 * 60 * 1000));
      });
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Stack spacing={4}>
        <FlexBox className='identityDisplay' justifyContent='space-between'>
          <Body2>{ident}</Body2>
          <H5 onClick={changeIdentity} className='changeButton'>
            Change
          </H5>
        </FlexBox>

        {type === IDENTITY.EMAIL && (
          <Instructions instruction='Check email'>
            Please enter the verification code sent to your email address.
          </Instructions>
        )}
        {type === IDENTITY.PHONE && (
          <Instructions instruction='Check SMS'>
            Please enter the verification code sent to your phone.
          </Instructions>
        )}

        <FormikTextField
          name='otp'
          label='Verification Code'
          fullWidth
          required
        />

        <FilledButton
          icon={<VerifiedOutlined />}
          iconPosition='start'
          label='Submit'
          type='submit'
        />

        <TimerProvider>
          <CountdownProvider end={endTime}>
            <ResendCode resendCode={resendCode} />
          </CountdownProvider>
        </TimerProvider>
      </Stack>
    </FormikForm>
  );
}

function RegistrationForm({ state }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  // const { type, ident, code } = account ?? {};
  // const { name, identity } = signed ?? {};

  console.log('Registering', state);

  const { _id: relationship_id, target_id = {}, contact_id = {} } = state;
  const { _id, name, public_name, email, identity, signup_code } =
    state?.type === 'contact' ? contact_id : target_id;

  const [, type] = identity.split('/');

  const passwords = {
    password: ['', yup.string().password().required('Password is required!')],
    confirmPassword: [
      '',
      yup
        .string()
        .min(8, 'Must be at least 8 characters')
        .minUppercase(1, 'Must contain at least 1 uppercase character')
        .minNumbers(1, 'Must contain at least 1 number')
        .minSymbols(1, 'Must contain at least 1 symbol (excluding space)')
        .oneOf([yup.ref('password'), null], ' Passwords must match'),
    ],
  };

  const changeSet = {
    public_name: [
      public_name ?? generateUsername(),
      yup.string().required('Username is required!'),
    ],
    name: [name ?? '', yup.string().required('Full name is required!')],
    email: [
      email,
      yup.string().email('Invalid email!').required('Email required!'),
    ],
    ...([IDENTITY.PHONE, IDENTITY.EMAIL].includes(type) ? passwords : {}),
    signup_code: [
      signup_code ?? '',
      yup
        .string()
        .nullable()
        .matches(/^(?=.{5,12}$)[\p{L}\d_ -]+$/u, 'Invalid code'),
    ],
    notify_events: [true, yup.boolean()],
    notify_marketing: [true, yup.boolean()],
  };

  const { onAccountCreated } = usePartnerConnectLinkProcess();

  const handleSubmit = async (values) => {
    // Construct the updated account details
    dispatch(registerUserThunk({ ...values, _id, relationship_id, i18n }))
      .unwrap()
      .then(onAccountCreated);
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Stack spacing={4}>
        <FlexBox className='identityDisplay' justifyContent='space-between'>
          <Body2>{email}</Body2>
        </FlexBox>

        <H5>Configure account</H5>

        <WithHelp instruction='This is a publicly displayed, we do not recommend using your actual name here or email address. Other users will see this for your activity.'>
          <FormikTextField
            name='public_name'
            label='Username'
            fullWidth
            required
          />
        </WithHelp>

        <WithHelp instruction='This will be used for any internal communications, such as invoices, organizations etc.'>
          <FormikTextField name='name' label='Full name' fullWidth required />
        </WithHelp>

        {[IDENTITY.PHONE, IDENTITY.EMAIL].includes(type) && (
          <>
            <Divider />

            <WithHelp instruction='Password must contain at least one upper case letter, one number and one symbol.'>
              <FormikPasswordField
                name='password'
                label='Password'
                fullWidth
                required
                autoComplete='password'
              />
            </WithHelp>

            <FormikPasswordField
              name='confirmPassword'
              label='Confirm Password'
              fullWidth
              required
            />

            <Divider />
          </>
        )}

        <WithHelp instruction='If you have a special registration code, enter that here.'>
          <FormikTextField
            name='signup_code'
            label='Special Registration Code'
            fullWidth
            autoComplete='signup_code'
            disabled={!!signup_code}
          />
        </WithHelp>

        <WithHelp instruction='Uncheck this box if you do not want to receive any system notifications (updates to assets, assignments etc.)'>
          <FormikCheckBox name='notify_events' label='Platform notifications' />
        </WithHelp>

        <WithHelp instruction='Uncheck this box if you do not want to receive any marketing communications from us or our carefully curated partners.'>
          <FormikCheckBox
            name='notify_marketing'
            label='Marketing communications'
          />
        </WithHelp>

        <FilledButton
          icon={<AccountCircle />}
          iconPosition='start'
          label='Register'
          type='submit'
        />
      </Stack>
    </FormikForm>
  );
}

function PasswordRegister() {
  const loader = useInviteHook();

  // console.log('Account', account);
  const { loading, state } = useInviteStateHook();
  console.log('Loading state', loading, state);
  // const [page, setPage] = useState(0);

  useEffect(() => {
    console.log('Loading invite,,,');
    loader();
  }, [loader]);

  // const changeIdentity = () => setPage(0);
  //
  // const showVerification = (account) => {
  //   setAccount(account);
  //   setSigned(undefined);
  //   setPage(1);
  // };
  //
  // const showRegistration = (reg) => {
  //   const { type, ident, name, identity } = reg;
  //   setAccount({ type, ident });
  //   setSigned({ name, identity });
  //   setPage(2);
  // };

  return (
    <>
      {loading && (
        <Stack spacing={1.25}>
          <Skeleton variant={'text'} width={100} />
          <Skeleton variant={'text'} width={100} />
        </Stack>
      )}
      {!loading && state && <RegistrationForm state={state} />}
      {!loading && !state && (
        <Stack spacing={0.5}>
          <H5 color='error'>Invalid Invite!</H5>
          <Body1>
            Failed to load the invite, please request a new invite if this is in
            error!
          </Body1>
        </Stack>
      )}
      {/*{page === 0 && (*/}
      {/*  <RegisterForm*/}
      {/*    account={account}*/}
      {/*    invite={invite}*/}
      {/*    showVerification={showVerification}*/}
      {/*    showRegistration={showRegistration}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{page === 1 && (*/}
      {/*  <VerificationForm*/}
      {/*    account={account}*/}
      {/*    changeIdentity={changeIdentity}*/}
      {/*    showRegistration={showRegistration}*/}
      {/*  />*/}
      {/*)}*/}

      {/*{page === 2 && (*/}
      {/*  <RegistrationForm*/}
      {/*    account={account}*/}
      {/*    signed={signed}*/}
      {/*    invite={invite}*/}
      {/*    changeIdentity={changeIdentity}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
}

export default PasswordRegister;
