import { Box } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import LatestAddedRequests from '@pw/components/LatestAdded/LatestAddedRequests';
import RequestForm from '@pw/components/RequestForm/RequestForm';
import ItemSkeleton from '@pw/components/sekeletons/ItemSkeleton';
import SkeletonSummary from '@pw/components/sekeletons/SkeletonSummary';
import RequestSummary from '@pw/components/summary/RequestSummary';
import {
  ASSET_PROCESSED_STATUS,
  REQUEST_EDIT_URL,
  REQUEST_STATUS,
} from '@pw/consts/requests';
import { INVENTORY_STATUS } from '@pw/consts/thing';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import styles from '@pw/styles/content.styles';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import useRequestQuery from '@pw/utilities/hooks/service/useRequestQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const RequestFormHandlerImpl = withDialogCard(
  ({ children, requestType, readonly, onSave, ...rest }) => {
    const navigate = useNavigate();
    const [target, scroll] = useScrollTarget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleSave = (mutatedRequest) => {
      console.log('Mutated request', mutatedRequest);
      const route = REQUEST_EDIT_URL?.[requestType];
      navigate(`${route}/${mutatedRequest?._id}`, { replace: true });
      onSave && onSave();
    };

    return (
      <Box sx={styles} className='root' ref={target}>
        <RequestForm
          onSave={handleSave}
          requestType={requestType}
          readonly={readonly}
          {...rest}
        >
          {children}
        </RequestForm>
      </Box>
    );
  },
);

function NewForm({
  requestType,
  requestLabel,
  changeSetGenerator,
  onInit,
  ...rest
}) {
  let [searchParams] = useSearchParams();

  const formValues = useMemo(() => {
    // See if there is a clone parameter
    let clone = searchParams.get('clone');
    // let imp = searchParams.get('import');

    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        clone = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    if (clone && onInit) {
      console.log('Cloning', clone);
      const { sources = {}, destinations = {} } = clone;
      const { assets = [], things = [] } = sources;
      const { assets: destAssets = [], things: destThings = [] } = destinations;
      const adapted = {
        ...clone,
        sources: {
          assets: assets.map((s) => ({
            ...s,
            processed: ASSET_PROCESSED_STATUS.PENDING,
          })),
          things: things.map((s) => ({
            ...s,
            entries: (s.entries ?? []).map((e) => ({
              ...e,
              storage: (e.storage ?? []).map((es) => ({
                ...es,
                processed: ASSET_PROCESSED_STATUS.PENDING,
              })),
            })),
            processed: INVENTORY_STATUS.PENDING,
          })),
        },
        destinations: {
          assets: destAssets.map((s) => ({
            ...s,
            processed: ASSET_PROCESSED_STATUS.PENDING,
          })),
          things: destThings.map((s) => ({
            ...s,
            entries: (s.entries ?? []).map((e) => ({
              ...e,
              storage: (e.storage ?? []).map((es) => ({
                ...es,
                processed: ASSET_PROCESSED_STATUS.PENDING,
              })),
            })),
            processed: INVENTORY_STATUS.PENDING,
          })),
        },
        request_status: REQUEST_STATUS.PENDING,
      };
      onInit(adapted);
    } else {
      // onInit([]);
    }
    return { ...clone, request_type: requestType };
  }, [requestType]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  usePageTitleHook(`Create ${requestLabel}`);
  return (
    <RequestFormHandlerImpl
      requestType={requestType}
      formValues={formValues}
      changeSet={changeSet}
      {...rest}
    />
  );
}

function LoadRequest({
  id,
  path,
  requestType,
  requestLabel,
  changeSetGenerator,
  onInit,
  ...rest
}) {
  const { data: entity, error, refetch, isLoading } = useRequestQuery(id);
  const { readonly } = useReadOnly(entity);

  usePageTitleHook(`${requestLabel} ${entity?.name ?? '...'}`);

  // Generate the change set
  const formValues = useMemo(() => {
    // Specific initializer
    if (onInit) {
      onInit(entity);
    }
    return { ...(entity ?? {}), request_type: requestType };
  }, [entity, requestType]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  const refreshData = useCallback(() => {
    refetch(id);
  }, [id, refetch]);

  return (
    <Box className='adaptive-content'>
      {isLoading && (
        <>
          <SkeletonSummary />
          <ItemSkeleton />
        </>
      )}
      {error && (
        <NewForm
          requestType={requestType}
          requestLabel={requestLabel}
          onInit={onInit}
          changeSetGenerator={changeSetGenerator}
          {...rest}
        />
      )}
      {entity && (
        <>
          <RequestSummary
            id={id}
            path={path}
            entity={entity}
            refresh={refreshData}
          />
          <RequestFormHandlerImpl
            requestType={requestType}
            id={id}
            entity={entity}
            changeSet={changeSet}
            readonly={readonly}
            onSave={refreshData}
            edit
            {...rest}
          />
        </>
      )}

      <LatestAddedRequests requestType={requestType} />
    </Box>
  );
}

function RequestFormHandler(props) {
  let { id } = useParams();
  console.log('ID: ', id);

  return (
    <>
      {!id && (
        <Box className='adaptive-content'>
          <NewForm {...props} />
          <LatestAddedRequests requestType={props.requestType} />
        </Box>
      )}
      {id && <LoadRequest id={id} {...props} />}
    </>
  );
}

export default RequestFormHandler;
