import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { THING_TYPES } from '@pw/consts/thing';
import { useMemo } from 'react';

function ThingPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...thingSettingsFields(initialValues),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<ThingForm
			thingLabel='Consumable'
			thingType={THING_TYPES.CONSUMABLE}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		/>
	);
}

export default withAppLayout(ThingPage, { title: 'Consumables' });
