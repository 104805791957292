const styles = {
  '&.tile-root': {
    width: '3.875rem',
    textAlign: 'center',
    transition: 'all 0.2s',
    transformOrigin: 'bottom center',
    transform: 'translateY(0.5rem)',
    borderRadius: '0.25rem',
    py: 0,

    '&:hover': {
      transform: 'scale(1.05) translateY(0)',
    },
  },

  '.icon-container': {
    display: 'flex',
    padding: '0.5rem',
    width: '100%',
    height: '62px',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    boxShadow: '1px 1px 3px 0px #00000040',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(1rem) rotateZ(45deg)',
    transformOrigin: 'center center',
  },

  svg: {
    transform: 'rotateZ(-45deg)',
    display: 'block',
    boxSizing: 'content-box',
    fontSize: '2.5rem',
    lineHeight: 1,
    height: '1em',
    width: '1em'
  },

  '.label': {
    marginTop: '2rem',
    fontSize: '0.75rem',
    lineHeight: 1.2,
    fontWeight: 400,
    // color: '#393C3C',
    color: 'black',
    textAlign: 'center',
    textTransform: 'capitalize',
  },



  '&.material-tile': {
    // '.label': { color: '#00A89D' },
  },
  '&.machine-tile': {
    '.icon-container': { backgroundColor: '#00A89D', },
    // '.label': { color: '#00A89D' },
    svg: { color: 'white', },
  },
  '&.process-tile': {
    '.icon-container': { backgroundColor: '#00A89D', },
    // '.label': { color: '#00A89D' },
    svg: { color: 'white', },
  },
  '&.observation-tile': {
    '.icon-container': { backgroundColor: '#C753A8', },
    svg: { color: 'white', },
  },
  '&.operation-tile': {
    '.icon-container': { backgroundColor: '#C7DA65', },
    svg: { color: '#707070', },
  },

  '&:hover': {
    '.icon-container': {
      transition: 'all 0.3s',
      backgroundColor: '#C7DA65',
      svg: { color: 'black' }
    },
    '.label': {
      fontWeight: 500
    }
  },

};
export default styles;
