import { useState, useEffect, useRef } from "react";

export const UseElementDimensions = (ids) => {
  const [dimensions, setDimensions] = useState({});
  const [totalWidth, setTotalWidth] = useState(0);
  const prevDimensionsRef = useRef(dimensions); // Track previous dimensions to compare

  // Function to update dimensions
  const updateDimensions = () => {
    const newDimensions = {};

    ids.forEach((id) => {
      const childElement = document.getElementById(id);
      if (childElement) {
        const parentElement = childElement.closest(".flexlayout__tab");
        if (parentElement) {
          const computedStyles = window.getComputedStyle(parentElement);
          newDimensions[id] = {
            width: parseFloat(computedStyles.width),
            height: parseFloat(computedStyles.height),
            left: parseFloat(computedStyles.left),
            top: parseFloat(computedStyles.top),
          };
        }
      }
    });

    // Only update state if dimensions have changed
    if (JSON.stringify(newDimensions) !== JSON.stringify(prevDimensionsRef.current)) {
      const LayoutLiquid = newDimensions.LayoutLiquid?.width || 0;
      const LayoutAssets = newDimensions.LayoutAssets?.width || 0;
      const LayoutActivity = newDimensions.LayoutActivity?.width || 0;
      const LayoutMonitoring = newDimensions.LayoutMonitoring?.width || 0;
      const total = LayoutLiquid + LayoutAssets + LayoutActivity + LayoutMonitoring;
      setTotalWidth(total)
      setDimensions(newDimensions);
      prevDimensionsRef.current = newDimensions; // Update the previous dimensions
    }
  };

  useEffect(() => {
    // Calculate the dimensions initially
    updateDimensions();

    // Listen for resize events
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [ids]);

  return { dimensions, totalWidth };
};
