import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import SpecsView from './SpecsView';

function SpecsModal({ company, open, onClose }) {
  return (
    <ModalWithClose onClose={() => onClose()} open={open} title="Specification Management">
      <SpecsView company={company} />
    </ModalWithClose>
  );
}

export default SpecsModal;
