import { SwitchAccount } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgClone from '@pw/components/icons/Clone';
import SvgCurve from '@pw/components/icons/Curve';
import FacilitiesIcon from '@pw/components/icons/Facilities';
import SvgLabel from '@pw/components/icons/Label';
import SvgPlant from '@pw/components/icons/Plant';
import SvgPolicy from '@pw/components/icons/Policy';
import SvgSpec from '@pw/components/icons/Spec';
import SvgTeams from '@pw/components/icons/Teams';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import CompanyQRCode from '@pw/components_v2/CompanyView/CompanyQRCode';
import { PATH } from '@pw/components_v2/CompanyView/consts';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { useCompanyId } from '@pw/redux/containers/User/hooks';
import { switchCompanyThunk } from '@pw/redux/thunks/user';
import styles from '@pw/styles/content.styles';
import useIsAccountActiveV2 from '@pw/utilities/hooks/logic/useIsAccountActiveV2';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompanyCurves from './modals/curves';
import CompanyFacilities from './modals/facilities';
import CompanyLedgers from './modals/ledgers';
import CompanyDesigns from './modals/plant';
import CompanyPolicies from './modals/policies';
import CompanySpecs from './modals/specs';
import CompanyUsers from './modals/users';

const CompanyModals = {
  USERS: {
    show: (params) => (<CompanyUsers {...params} />)
  },
  FACILITIES: {
    show: (params) => (<CompanyFacilities {...params} />)
  },
  POLICIES: {
    show: (params) => (<CompanyPolicies {...params} />)
  },
  CURVES: {
    show: (params) => (<CompanyCurves {...params} />)
  },
  PLANT: {
    show: (params) => (<CompanyDesigns {...params} />)
  },
  /*
  SENSORS: {
    show: (params) => (<CompanyGateways {...params} />)
  },*/
  SPECIFICATIONS: {
    show: (params) => (<CompanySpecs {...params} />)
  },
  LEDGERS: {
    show: (params) => (<CompanyLedgers {...params} />)
  },
}

function Summary({ entity, refresh }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  console.log('Entity', entity);
  const { user = {} } = entity ?? {};
  console.log('User', user);

  // Get the user's permission for this company
  const checkPermissions = usePermissionsV2(entity);
  // See if user is active in this company
  const userIsActive = useIsAccountActiveV2(entity);

  // The user's currently logged in company
  const currentCompanyId = useCompanyId();

  const [activeModal, setActiveModal] = useState(null);

  const handleNew = () => {
    navigate(`${PATH}`, { replace: true });
  };

  const handleClone = () => {
    const str = JSON.stringify({ ...entity, _id: undefined, user: undefined });
    const base = Buffer.from(str).toString('base64');
    navigate(`${PATH}?clone=${base}`, { replace: true });
  };

  const handleSwitch = useCallback(() => {
    dispatch(switchCompanyThunk({ company_id: entity._id, i18n }));
  }, [i18n]);

  const onClose = () => {
    setActiveModal(null);
  };

  return (
    <Stack sx={styles} className="root" spacing={3}>
      <H5>Company Summary</H5>

      <Box className="summary-qr">
        <CompanyQRCode entity={entity} />
      </Box>

      <FlexBox justifyContent="center" sx={{ flexWrap: 'wrap' }}>
        <TitledButton
          handleClick={handleNew}
          disabled={!userIsActive}
          label="New"
        >
          <SvgAddNew height={24} width={24} />
        </TitledButton>

        <TitledButton
          handleClick={handleClone}
          disabled={!userIsActive}
          label="Clone"
        >
          <SvgClone height={24} width={24} />
        </TitledButton>

        {currentCompanyId !== entity._id && (
          <TitledButton
            handleClick={handleSwitch}
            label="Switch"
          >
            <SwitchAccount height={24} width={24} color="info" />
          </TitledButton>
        )}
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.USERS)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.USERS, PermissionType.READ)}
          label="Users"
        >
          <SvgTeams height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.FACILITIES)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.FACILITIES, PermissionType.READ)}
          label="Facilities"
        >
          <FacilitiesIcon height={24} width={24} />
        </TitledButton>
      </FlexBox>
      <FlexBox justifyContent="center" sx={{ flexWrap: 'wrap' }}>
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.POLICIES)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.POLICIES, PermissionType.READ)}
          label="Policies"
        >
          <SvgPolicy height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.CURVES)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.PRICE_CURVES, PermissionType.MODIFY)}
          label="Curves"
        >
          <SvgCurve height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.SPECIFICATIONS)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.SPECIFICATIONS, PermissionType.READ)}
          label="Specs"
        >
          <SvgSpec height={24} width={24} />
        </TitledButton>
        {(entity.features?.bottling || entity.features?.production) && (
          <TitledButton
            handleClick={() => setActiveModal(CompanyModals.PLANT)}
            disabled={!userIsActive}
            label="Plant"
          >
            <SvgPlant height={24} width={24} />
          </TitledButton>
        )}
        {
          /*
          <TitledButton
            handleClick={() => setActiveModal(CompanyModals.SENSORS)}
            disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.SENSORS, PermissionType.READ)}
            label="Sensors"
          >
            <SvgSensor height={24} width={24} />
          </TitledButton>
          */
        }
        <TitledButton
          handleClick={() => setActiveModal(CompanyModals.LEDGERS)}
          disabled={!userIsActive || !checkPermissions(GENERAL_FUNCTIONS.LEDGERS, PermissionType.READ)}
          label="Ledgers"
        >
          <SvgLabel height={24} width={24} />
        </TitledButton>
      </FlexBox>
      {activeModal && activeModal.show({ company: entity, open: !!activeModal, onClose })}
    </Stack>
  );
}

export default withSmallDialogCard(Summary);
