// @ts-ignore
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Model, Actions, DockLocation } from 'flexlayout-react';
import 'flexlayout-react/style/light.css';
import MonitoringPanel from './components/MonitoringPanel';
import LiquidPanel from './components/LiquidPanel';
import flexLayoutConfig from './flexLayoutConfig';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { UNIT } from '@pw/consts/units';
import { useSensorDataLazyQuery } from '@pw/utilities/hooks/service/useSensorDataQuery';
import { useLiquidOverviewLazyQuery } from '@pw/utilities/hooks/service/useLiquidOverviewQuery';
import {
  setParsedLiquidData,
  useCompanyId,
  useCompanyLiquidTypes,
} from '@pw/redux/containers/User';
import { processAssetList, processRequestList } from '../Tasks';
import AssetsSection from './components/AssetsSection';
import styles from './dashboard.styles';
import { Box, Stack } from '@mui/material';
import ScanAnything from './components/ScanAnything';
import ActivityPanel from './components/ActivityPanel';
import TotalAssetCountWidget from './components/TotalAssetCountWidget';
import LayoutAssetByTypeCountWidget from './components/LayoutAssetByTypeCountWidget';
import SensorDataWidget from './components/SensorDataWidget';
import LiquidBySkuWidget from './components/LiquidBySkuWidget';
import TasksWidget from './components/TasksWidget';
import DashboardHeader from './components/DashboardHeader';
import WidgetLibraryContainer from './components/WidgetLibrary';
import LayoutLiquid from './components/LayoutLiquid';
import LayoutAssets from './components/LayoutAssets';
import LayoutActivity from './components/LayoutActivity';
import LayoutMonitoring from './components/LayoutMonitoring';
import { useDashbaordLazyQuery } from '@pw/utilities/hooks/service/useDashbaordQuery';
import { UseElementDimensions } from './components/UseElementDimensions';
import { updateDashboard } from '@pw/services/company.service';
import { useStatsQuery } from '@pw/utilities/hooks/service/useStatsQuery';
import LayoutSalesOrders from './components/LayoutSalesOrders';
import LayoutPurchaseOrders from './components/LayoutPurchaseOrders';
import LayoutInvoices from './components/LayoutInvoices';
import LayoutDutyPayments from './components/LayoutDutyPayments';
import LayoutDutySubmissions from './components/LayoutDutySubmissions';
import LayoutDeliveries from './components/LayoutDeliveries';

export default function Dashboard({ data, refetch, facilities = [] }) {
  const dispatch = useDispatch();
  // @ts-ignore
  const { t } = useTranslation();
  const converter = useConverter();
  // @ts-ignore
  const bl_unit = converter.unit(UNIT.BL);
  // @ts-ignore
  const la_unit = converter.unit(UNIT.LA);
  const [unique_location_id, setUniqueLocationId] = useState();
  const [fetchSensorData, { data: sensorData = {}, isLoading }] =
    useSensorDataLazyQuery({});
  const [
    fetchLiquidData,
    { data: liquidData, isLoading: isLiquidDataLoading },
  ] = useLiquidOverviewLazyQuery(null);
  const liquidTypes = useCompanyLiquidTypes();

  // start loading with first facility
  useEffect(() => {
    facilities?.[0]?.id && setUniqueLocationId(facilities?.[0]?.id);
  }, [facilities]);

  // listen for changes in unique_location_id
  useEffect(() => {
    unique_location_id && fetchSensorData({ unique_location_id });
  }, [unique_location_id, fetchSensorData]);

  const [skuId, setSkuId] = useState();

  useEffect(() => {
    fetchLiquidData({ sku_id: skuId }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuId]);

  const isCompanyActive = true; // TODO: useSelector(selectIsCompanyActive);
  const {
    awaitingRequests = [],
    pendingAssets = [],
    casks = [],
    skus = [],
  } = data ?? {};

  const parsed = (liquidData ?? []).reduce(
    (acc, item) => {
      acc.total_bl += converter.from(item?.total_bl ?? 0, UNIT.BL);
      acc.total_la += converter.from(item?.total_la ?? 0, UNIT.LA);

      acc.total_bl_produced += converter.from(
        item?.total_bl_produced ?? 0,
        UNIT.BL,
      );
      acc.total_la_produced += converter.from(
        item?.total_la_produced ?? 0,
        UNIT.LA,
      );

      acc.bl.push(converter.from(item?.total_bl ?? 0, UNIT.BL));
      acc.la.push(converter.from(item?.total_la ?? 0, UNIT.LA));

      acc.produced_bl.push(
        converter.from(item.total_bl_produced ?? 0, UNIT.BL),
      );
      acc.produced_la.push(
        converter.from(item.total_la_produced ?? 0, UNIT.LA),
      );

      acc.total_bl_in += Math.abs(
        converter.from(item?.total_bl_in ?? 0, UNIT.BL),
      );
      acc.total_la_in += Math.abs(
        converter.from(item?.total_la_in ?? 0, UNIT.LA),
      );

      acc.total_bl_out += Math.abs(
        converter.from(item?.total_bl_out ?? 0, UNIT.BL),
      );
      acc.total_la_out += Math.abs(
        converter.from(item?.total_la_out ?? 0, UNIT.LA),
      );

      acc.dates.push(new Date(item.binned_timestamp));
      return acc;
    },
    {
      bl: [],
      la: [],
      produced_bl: [],
      produced_la: [],
      dates: [],
      total_bl: 0,
      total_la: 0,
      total_bl_produced: 0,
      total_la_produced: 0,
      total_bl_in: 0,
      total_la_in: 0,
      total_bl_out: 0,
      total_la_out: 0,
    },
  );

  const tasks = useMemo(
    () => [
      ...processAssetList({ items: pendingAssets }),
      ...processRequestList(awaitingRequests),
    ],
    [awaitingRequests, pendingAssets],
  );
  const ProducedWineGallons = parsed.total_bl;
  const ProducedProofGallons = parsed.total_la;
  dispatch(
    setParsedLiquidData({
      ...parsed,
      ProducedWineGallons,
      ProducedProofGallons,
    }),
  );

  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [model, setModel] = useState(Model.fromJson(flexLayoutConfig));
  // @ts-ignore
  const [updateFlag, setUpdateFlag] = useState(0);
  const [widgetCount, setWidgetCount] = useState(0);
  const company_id = useCompanyId();

  // @ts-ignore
  const [fetchDashboard, { data: dashboardData, isLoading: dashboardLoading }] =
    useDashbaordLazyQuery();
  const { data: statsData, isLoading: statsLoading } = useStatsQuery({
    company_id: company_id,
  });

  useEffect(() => {
    // Fetch dashboard data when `id` is available
    if (company_id) {
      fetchDashboard({ id: company_id });
    }
  }, [company_id]);

  useEffect(() => {
    if (dashboardData) {
      const layout_data = dashboardData?.layout_data || flexLayoutConfig;
      const hidden_widgets = dashboardData?.hidden_widgets || [];
      try {
        const initialModel = Model.fromJson(layout_data);
        setModel(initialModel);
        setSelectedWidgets(hidden_widgets);
      } catch (error) {
        console.error('Error parsing layout:', error);
        const fallbackModel = Model.fromJson(flexLayoutConfig);
        setModel(fallbackModel);
        setSelectedWidgets([]);
      }
    }
  }, [dashboardData]);

  const onModelChange = (model) => {
    const json = model.toJson();
    const layout = json;
    setUpdateFlag((prevFlag) => prevFlag + 1);
    saveLayoutToBackend(selectedWidgets, layout);
  };
  const saveLayoutToBackend = async (widgets = [], layout = {}) => {
    setWidgetCount(widgets.length);
    const payload = {
      layout_data: layout,
      hidden_widgets: widgets,
    };
    try {
      const response = await updateDashboard(payload);
      // console.log('Saved layout to backend:', response);
    } catch (error) {
      console.error('Error saving layout to backend:', error);
    }
  };

  // @ts-ignore
  const addNewTab = (widget) => {
    const tabConfig = {
      type: 'tab',
      name: widget.name,
      component: widget.component,
      id: widget.id,
      enableClose: false,
    };
    const action = Actions.addNode(
      tabConfig,
      widget.targetTabsetId,
      DockLocation.BOTTOM,
      -1,
      true,
    );
    const updatedModel = Model.fromJson(model.toJson());
    updatedModel.doAction(action);
    setModel(updatedModel);

    const updatedWidgets = [...selectedWidgets, widget.id];
    // const updatedWidgets = selectedWidgets.filter((id) => id !== widget.id);
    const layout = updatedModel.toJson();
    saveLayoutToBackend(updatedWidgets, layout);
  };

  // @ts-ignore
  const removeTab = (widgetId) => {
    let newSelectedWidgets = selectedWidgets.filter((id) => id !== widgetId);
    // let newSelectedWidgets = [...selectedWidgets, widgetId];
    setSelectedWidgets(newSelectedWidgets);

    const action = Actions.deleteTab(widgetId);
    const updatedModel = Model.fromJson(model.toJson());
    updatedModel.doAction(action);
    setModel(updatedModel);

    const layout = updatedModel.toJson();
    saveLayoutToBackend(newSelectedWidgets, layout);
  };

  const handleWidgetSelect = (widget) => {
    let newSelectedWidgets;

    if (selectedWidgets.includes(widget.id)) {
      newSelectedWidgets = selectedWidgets.filter((id) => id !== widget.id);
      removeTab(widget.id);
      // addNewTab(widget);
    } else {
      newSelectedWidgets = [...selectedWidgets, widget.id];
      addNewTab(widget);
      // removeTab(widget.id);
    }

    setSelectedWidgets(newSelectedWidgets);
  };

  const ids = [
    'LayoutMonitoring',
    'LayoutLiquid',
    'LayoutAssets',
    'LayoutActivity',
  ];
  const { dimensions, totalWidth } = UseElementDimensions(ids);

  const [layoutHeight, setLayoutHeight] = useState(0);
  const prevLayoutHeightRef = useRef(layoutHeight);

  useEffect(() => {
    if (totalWidth) {
      const totalWidgets = widgetCount || selectedWidgets.length;
      const screenWidth = window.innerWidth;
      const screenWidth60 = screenWidth * 0.6;

      if (totalWidgets === 0) {
        if (prevLayoutHeightRef.current !== 500) {
          setLayoutHeight(500);
          prevLayoutHeightRef.current = 500;
        }
        return;
      }

      const widgetWidth = totalWidth / (totalWidgets + 1);

      if (widgetWidth > screenWidth60) {
        if (prevLayoutHeightRef.current !== (totalWidgets + 1) * 600) {
          setLayoutHeight((totalWidgets + 1) * 600);
          prevLayoutHeightRef.current = (totalWidgets + 1) * 600;
        }
      } else {
        const heightFactor = screenWidth60 / widgetWidth;
        const calculatedHeight = Math.max(500, 2000 / heightFactor);

        if (prevLayoutHeightRef.current !== calculatedHeight) {
          setLayoutHeight(calculatedHeight);
          prevLayoutHeightRef.current = calculatedHeight;
        }
      }
    }
  }, [totalWidth, widgetCount, selectedWidgets]);

  const factory = (node) => {
    const component = node.getComponent();
    switch (component) {
      case 'LayoutLiquid':
        return (
          <LayoutLiquid
            currentWidth={dimensions['LayoutLiquid']?.width}
            currentHeight={dimensions['LayoutLiquid']?.height}
          />
        );
      case 'LayoutAssets':
        return (
          <LayoutAssets
            currentWidth={dimensions['LayoutAssets']?.width}
            currentHeight={dimensions['LayoutAssets']?.height}
          />
        );
      case 'LayoutActivity':
        return (
          <LayoutActivity
            currentWidth={dimensions['LayoutActivity']?.width}
            currentHeight={dimensions['LayoutActivity']?.height}
          />
        );
      case 'LayoutMonitoring':
        return (
          <LayoutMonitoring
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutSalesOrders':
        return (
          <LayoutSalesOrders
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutPurchaseOrders':
        return (
          <LayoutPurchaseOrders
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutInvoices':
        return (
          <LayoutInvoices
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutDutyPayments':
        return (
          <LayoutDutyPayments
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutDutySubmissions':
        return (
          <LayoutDutySubmissions
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );
      case 'LayoutDeliveries':
        return (
          <LayoutDeliveries
            currentWidth={dimensions['LayoutMonitoring']?.width}
            currentHeight={dimensions['LayoutMonitoring']?.height}
          />
        );

      case 'LiquidPanel':
        return (
          <LiquidPanel
            liquidTypes={liquidTypes}
            isLiquidDataLoading={isLiquidDataLoading}
            setSkuId={setSkuId}
            parsed={parsed}
          />
        );
      case 'AssetsPanel':
        return <AssetsSection skus={skus} casks={casks} />;
      case 'ActivityPanel':
        return <ActivityPanel refetch={refetch} tasks={tasks} data={data} />;
      case 'MonitoringPanel':
        return (
          <MonitoringPanel
            facilities={facilities}
            isLoading={isLoading}
            sensorData={sensorData}
            setUniqueLocationId={setUniqueLocationId}
          />
        );
      case 'TotalAssetCountWidget':
        return <TotalAssetCountWidget />;
      case 'LayoutAssetByTypeCountWidget':
        return <LayoutAssetByTypeCountWidget />;
      case 'SensorDataWidget':
        return <SensorDataWidget />;
      case 'LiquidBySkuWidget':
        return <LiquidBySkuWidget />;
      case 'TasksWidget':
        return <TasksWidget refetch={refetch} data={tasks ?? []} />;
      default:
        return <div>Unknown Component</div>;
    }
  };

  return (
    <Stack sx={styles} className='root'>
      {!isCompanyActive && <ScanAnything />}
      {isCompanyActive && (
        <>
          <DashboardHeader isOpen={isWidgetOpen} setIsOpen={setIsWidgetOpen} />
          {isWidgetOpen && (
            <WidgetLibraryContainer
              selectedWidgets={selectedWidgets}
              handleWidgetSelect={handleWidgetSelect}
            />
          )}
          <Box
            className='flexlayout'
            sx={{
              height: `${layoutHeight}px`,
              transition: 'height 0.3s ease',
            }}
          >
            <Layout
              model={model}
              factory={factory}
              onModelChange={onModelChange}
            />
          </Box>
        </>
      )}
    </Stack>
  );
}
