import FormikContext from '@pw/context/FormikContext';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';
import { useCallback, useContext, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import { Box, Stack } from '@mui/material';
import { Body2, H6 } from '../Typography';
import { FlexBox } from '../Layout/FlexBox';
import { FormikCheckBox } from '../Forms/FormikForm';

function LiquidStatus({ entity = null }) {
	const { values, setFieldValue } = useContext(FormikContext);
	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) =>
			e?.properties.imported === e2?.properties.imported &&
			e?.properties.duty_paid === e2?.properties.duty_paid,
	);
	const onRevert = useCallback(() => {
		setFieldValue('imported', history?.properties.imported);
		setFieldValue('duty_paid', history?.properties.duty_paid);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		setFieldValue('imported', entity?.properties.imported);
		setFieldValue('duty_paid', entity?.properties.duty_paid);
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => {
		return values['imported'] === history?.properties?.imported
			&& values['duty_paid'] === history?.properties?.duty_paid
	}, [history, values])

	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage
					title='Liquid Status'
					onRevert={onRevert}
					isReverted={isReverted}
					onUndo={onUndo}
					item={{
						imported: history?.properties?.imported,
						duty_paid: history?.properties?.duty_paid
					}}
					type="liquid_status"
				/>
			)}
			<Stack className='section'>
				<H6 className='section-title'>Liquid Status</H6>
				<FlexBox>
					<FormikCheckBox name='imported' label={<Body2>Imported</Body2>} />
					<FormikCheckBox name='duty_paid' label={<Body2>Duty Paid</Body2>} />
				</FlexBox>
			</Stack>
		</Box>
	);
}

export default LiquidStatus;
