const PATH = '/crm/contacts';
const PARTNER_CONTACT_FORM = 'partner_contact_form';
const CUSTOMER_RESET_PASSWORD = 'customer_reset_password';
const INVITE_CUSTOMER = 'invite_customer';
const PARTNER_INVITE = 'partner_connect';
const CONFIRM_PARTNER_INVITE = 'confirm_partner_invite';
const CONTACT_ASSETS = 'contact_assets';
const SCAN_QR = 'scan_qr';
// const RELATIONSHIP_STATUS = {
//   ACTIVE: 'active',
//   SUSPENDED: 'suspended',
//   TERMINATED: 'terminated',
//   REVIEW: 'review',
// };

export {
  PATH,
  PARTNER_CONTACT_FORM,
  CUSTOMER_RESET_PASSWORD,
  INVITE_CUSTOMER,
  CONTACT_ASSETS,
  PARTNER_INVITE,
  CONFIRM_PARTNER_INVITE,
  SCAN_QR,
};
