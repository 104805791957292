import { AddCircle, Delete } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { POLICY_COMPLIANCE, POLICY_CONDITION_OPTIONS, POLICY_ENTRY_TYPE_OPTIONS } from '@pw/consts/policy';
import { TIME_UNIT_OPTIONS, UNITS_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { ArrayFormikProvider } from '@pw/providers/FormikProvider';
import debounce from '@pw/utilities/debounce';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useMemo } from 'react';
import * as yup from 'yup';

function NumberType() {
  return (
    <>
      <FlexBox alignItems='top'>
        <FormikUnitField
          label='Min'
          name='min'
          options={UNITS_OPTIONS}
          fullWidth
        />
        <FormikUnitField
          label='Max'
          name='max'
          options={UNITS_OPTIONS}
          fullWidth
        />
      </FlexBox>
      <FormikUnitField
        label='Target'
        name='target'
        options={UNITS_OPTIONS}
        fullWidth
      />
    </>
  );
}

function SelectionOptions({ index, remove }) {
  return (
    <ArrayFormikProvider index={index} path='range'>
      <FlexBox>
        <FormikTextField label='Label' name='label' fullWidth />
        <FormikTextField label='Value' name='value' fullWidth />
        <TextButton
          handleClick={() => remove(index)}
          sx={{ width: 'max-content' }}
        >
          <Delete />
        </TextButton>
      </FlexBox>
    </ArrayFormikProvider>
  );
}

const option = { label: '', value: '' };

function SelectionType() {
  const { values, setFieldValue } = useContext(FormikContext);

  const opts = values?.range ?? [option];

  const addNew = useCallback(() => {
    debounce(() => {
      setFieldValue('range', [...values?.range, option]);
    }, 20);
  }, [values]);

  const remove = useCallback(
    (index) => {
      debounce(() => {
        setFieldValue(
          'range',
          values?.range?.filter((_, i) => i !== index),
        );
      }, 20);
    },
    [values],
  );

  return (
    <Stack spacing={1} className='section'>
      <H5 className='section-header'>Supported Options</H5>
      <Stack className='inventory'>
        <Stack gap={1}>
          {opts.map((i, index) => (
            <SelectionOptions index={index} remove={remove} />
          ))}
        </Stack>

        <Box className='inventory-header'>
          <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
          <Stack direction='row' spacing={1}>
            <TitledButton handleClick={() => addNew()} label='New'>
              <AddCircle height={24} width={24} />
            </TitledButton>
          </Stack>
        </Box>
      </Stack>

      {}
    </Stack>
  );
}

function Empty() {
  return <></>;
}

function TypeParams() {
  const { values } = useContext(FormikContext);

  return (
    <>
      {values?.type === 0 && <Empty />}
      {values?.type === 1 && <Empty />}
      {values?.type === 2 && <NumberType />}
      {values?.type === 3 && <SelectionType />}
    </>
  );
}

function PolicyEntryEditor({ item, open, onClose, stage , readonly}) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    name,
    description,
    type,
    min,
    max,
    target,
    range,
    condition,
    repeat,
  } = item ?? {};

  const label = item?.name ? `Update ${name}` : `New Entry`;

  const changeSet = useMemo(
    () => ({
      name: [name ?? '', yup.string().required('Name required!')],
      description: [
        description ?? '',
        yup.string().required('Description required!'),
      ],
      type: [type ?? '0', yup.string().required('Type required!')],
      min: unitField(min),
      target: unitField(target),
      max: unitField(max),
      range: [
        range ?? [],
        yup
          .array()
          .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
          .required('Selection option values are required'),
      ],
      condition: [condition ?? POLICY_COMPLIANCE.NON_BLOCKING, yup.string()],
      repeat: unitField(repeat),
    }),
    [name, description, type, target, min, max, range, condition],
  );

  const handleSubmit = (entry) => {
    try {
      console.log('Setting Entry', entry);
      onClose(entry);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={label}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
          <FormikTextField label='Name' name='name' fullWidth required />
          <FormikTextField label='Description' name='description' fullWidth required />
          <FormikSelect
            label='Type'
            name='type'
            options={POLICY_ENTRY_TYPE_OPTIONS}
            fullWidth
            required
          />

          <TypeParams />

          {stage === '1' && (
            <FormikUnitField
              label='Repeat'
              name='repeat'
              options={TIME_UNIT_OPTIONS}
              fullWidth
            />
          )}

          <FormikSelect
            label='Evaluation'
            name='condition'
            options={POLICY_CONDITION_OPTIONS}
            fullWidth
          />

          <FormButtons onCancel={onClose} readonly={readonly} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default PolicyEntryEditor;
