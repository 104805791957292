export const assetCardStyles = {
    '&.asset-card': {
        gap: 2,
        width: {
			xs: '100%',
			md: '560px',
		},
		display: 'flex',
		flexDirection: 'row',
        marginRight: 'auto',
		marginLeft: 'auto',
		padding: {
			xs: '1rem',
			lg: '1rem',
		},
		backgroundColor: '#C6DB5C',
		borderRadius: {
			xs: '0',
			md: '0.75rem',
		},
		'--shadow-color': '0deg 0% 54%',
		boxShadow: {
			xs: 'none',
			md: `0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
        0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
        1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34)`,
		},
    }
}