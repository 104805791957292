import { Box } from '@mui/material';

// components & fns
import Node from '@pw/components/ProductionDesigner/Diagram/NodeBase';

import NodeModal from '@pw/components/ProductionDesigner/Diagram/NodeModal/';
import ViewportObserver from '@pw/components/ProductionDesigner/Diagram/ViewportObserver';
// import Draggable from '@pw/components/ProductionDesigner/Draggable';
import DropZone from '@pw/components/ProductionDesigner/DropZone';
import { sourceTypes, targetTypes } from '@pw/components/ProductionDesigner/nodes';
import ToolBox from '@pw/components/ProductionDesigner/ToolBox/';
import DesignerContext from '@pw/context/DesignerContext';
import { nanoid } from 'nanoid/non-secure';
import { useCallback, useContext, useState } from 'react';

// drag-n-drop
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// react-flow
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  Controls
} from 'reactflow';
import 'reactflow/dist/style.css';
import ConfigurableEdge from '@pw/components/ProductionDesigner/Diagram/Edges/ConfigurableEdge';

import { globalStyles, gridGap, styles } from './ProductionDesigner.styles';

// these handles (on corners) are "handle-[top/right/bottom/left]-[type]"
// the other set (middle of side) are "handle-[top/bottom]-[left/right]-[type]"
// so we can quite easily tell which set it belongs to by whether it has the
// one or two coordinate system (top vs top-left etc)
const isPtoPHandle = (handleId) => handleId.split('-').length === 3;

const defaultEdgeTypes = {
  default: ConfigurableEdge
  // withLabel: CustomEdgeWithLabel
  // processEdge: ProcessEdge,
};

const defaultNodeTypes = {
  materialNode: Node,
  processNode: Node,
  observationNode: Node,
  operationNode: Node
};

function ProductionDesigner({
  nodeGroups = {},
  nodeTypes = defaultNodeTypes,
  edgeTypes = defaultEdgeTypes,
  isValidConnection: _isValidConnection,
  onConnect: _onConnect,
  onNodesChange: _onNodesChange,
  onEdgesChange: _onEdgesChange,
  onNodeClick: _onNodeClick,
  saveHandler,
  loadHandler
}) {
  const {
    isLoading,
    nodes,
    setNodes,
    edges,
    setEdges,
    setSelectedNode,
    type,
  } = useContext(DesignerContext);

  // if (mode === 'design' && !sku_id) {
  //   return <></>;
  // }

  const [showGlobalMenu, setShowGlobalMenu] = useState(true);
  const [viewport, setViewport] = useState();

  const onConnect = useCallback(
    (params) => {
      if (typeof _onConnect === 'function') {
        return _onConnect(params, setEdges, addEdge);
      }

      setEdges((eds) => addEdge({ ...params, id: nanoid() }, eds));
    },
    [setEdges]
  );

  const onNodesChange = useCallback(
    (changes) => {
      if (typeof _onNodesChange === 'function') {
        return _onNodesChange({ changes, nodes, applyNodeChanges });
      }

      setNodes((nds) => applyNodeChanges(changes, nds));
    }, [nodes, applyNodeChanges, _onNodesChange]
  );
  const onEdgesChange = useCallback((changes) => {
    if (typeof _onEdgesChange === 'function') {
      return _onEdgesChange({ changes, nodes, setNodes, edges, setEdges, applyEdgeChanges });
    }

    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, [nodes, setNodes, edges, setEdges, _onEdgesChange]);

  const onNodeClick = useCallback(
    (e) => {
      if (typeof _onNodeClick === 'function') return _onNodeClick(e);

      const clicked = e.target;
      const clickedMore = clicked.closest('.more');
      if (!clickedMore) return;
      const clickedRoot = clicked.closest('.node-root');
      const id = clickedRoot.id;
      const clickedNode = nodes.find((n) => id === n.id);
      if (!clickedNode.data.form) return;
      setSelectedNode(clickedNode);

    }, [nodes, _onNodeClick]);

  const isValidConnection = useCallback((args) => {
    if (_isValidConnection === 'function') return _isValidConnection(args);

    const { source, target, sourceHandle, targetHandle } = args;
    const sourceNode = nodes.find(({ id }) => id === source);
    const targetNode = nodes.find(({ id }) => id === target);

    if (sourceNode.type === 'processNode') {
      if (targetNode.type === 'processNode') {
        // only PtoP (corner) handles are valid for this situation
        return (isPtoPHandle(sourceHandle) && isPtoPHandle(targetHandle));
      }
      //  PtoP handles not valid for any other connection
      if (isPtoPHandle(sourceHandle)) return false;
      // process => observation, process => operation: valid. (excluded p -> p earlier)
      if (targetTypes.includes(targetNode.type)) return true;

      // pretty much just leaves process => material: not valid
      return false;
    }

    // only valid for PtoP, which we've covered earlier
    if (isPtoPHandle(targetHandle)) return false;

    // can't connect to self!
    if (targetNode === sourceNode) return false;

    // invalid
    if (!sourceTypes.includes(sourceNode.type) ||
      !targetTypes.includes(targetNode.type)) return false;

    // may want to prevent multiple connections between the same nodes
    // but it may be allowed (ie water -> sequence operation, could be added multi steps)
    return true;
  }, [nodes, edges, _isValidConnection]);

  return (
    <DndProvider backend={HTML5Backend}>
      {globalStyles(showGlobalMenu)}
      <Box sx={styles} className="diagram-root">
        <ToolBox
          nodeGroups={nodeGroups}
          showGlobalMenu={showGlobalMenu}
          setShowGlobalMenu={setShowGlobalMenu}
        />

        <DropZone
          name="diagram"
          // viewport={viewport}
          {...viewport}
          instructions={(canDrop) =>
            !nodes?.length && (
              <Box className="instructions">
                {canDrop ? 'Release to drop' : 'Drag components here'}
              </Box>
            )
          }
          showGlobalMenu={showGlobalMenu}
          isLoading={isLoading}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            snapToGrid={true}
            snapGrid={[gridGap / 2, gridGap / 2]}
            connectionMode="loose"
            connectionLineType={edgeTypes.default}
            connectionRadius={gridGap}
            onNodeClick={onNodeClick}
            edgesreconnectable={'true'}
            isValidConnection={isValidConnection}
          >
            <Controls position="bottom-right" />
            {/* <MiniMap /> */}
            <Background
              variant={BackgroundVariant.Lines}
              lineWidth={0.5}
              gap={40}
              color="#E4E3E3"
            />
            <ViewportObserver viewport={viewport} setViewport={setViewport} />
          </ReactFlow>
        </DropZone>

        <NodeModal />
      </Box>
    </DndProvider>
  );
}

export default ProductionDesigner;
