import * as yup from 'yup';

function addressFields(params, properties) {
  return {
    address_1: [
      params?.address_1 ?? '',
      (!!properties?.mandatory) ? yup.string().required('First line of Address is required!') : yup.string(),
    ],
    address_2: [params?.address_2, yup.string()],
    address_3: [params?.address_3, yup.string()],
    city: [
      params?.city,
      (!!properties?.mandatory) ? yup.string().required('City is required!') : yup.string(),
    ],
    state: [params?.state, yup.string()],
    country: [
      params?.country,
      (!!properties?.mandatory) ? yup.string().required('Country is required!') : yup.string(),
    ],
    post_code: [
      params?.post_code,
      (!!properties?.mandatory) ? yup.string().required('Post code is required!') : yup.string(),
    ],
  };
}

export default addressFields;
