import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function BasicListItemSkeleton() {
  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <Stack spacing={0.5} sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}>
          <Skeleton variant={'text'} width={120} />
        </Stack>
      </Box>
    </Box>
  );
}

export default BasicListItemSkeleton;
