import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Overline } from '@pw/components/Typography';
import ListItem from '@pw/components_v2/elements/lists/ListItem';

function BasicList({ loading, items, tools, identity, readonly, Content }) {
  return (
    <Stack sx={{ width: '100%', position: 'relative' }} spacing="0.25rem">
      {items?.map((i) => (
        <ListItem
          key={identity(i)}
          item={i}
          Content={Content}
          tools={tools}
          readonly={readonly}
        />
      ))}
      {loading && (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '2rem', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={32} color="secondary" />
          <Overline>Loading...</Overline>
        </Box>
      )}
    </Stack>
  );
}

export default BasicList;
