import { cloneThing, createThing, updateThing } from '@pw/services/thing.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const cloneThingThunk = createAsyncThunk(
	'thing/clone',
	async (thing, { rejectWithValue }) => {
		try {
			return await cloneThing(thing);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const upsertThingThunk = createAsyncThunk(
	'thing/upsert',
	async (thing, { rejectWithValue }) => {
		try {
			return (thing._id) ? await updateThing(thing) : await createThing(thing);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const updateThingsThunk = createAsyncThunk(
	'thing/update',
	async (skus) => skus,
);
