import {
  logout,
  refreshToken,
  registerCredentials,
  registrationOptions,
} from '@pw/services/auth.service';
import {
  createUser,
  invite,
  listUsers,
  removeUser,
  updateUser,
} from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { startRegistration } from '@simplewebauthn/browser';
import { handleSignin } from './utils/handleSignin';
import { updatePassword, updateProfile } from '@pw/services/profile.service';

export const refetchUsersThunk = createAsyncThunk(
  'user/refetch',
  async (_, { rejectWithValue }) => {
    try {
      return await listUsers();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const registerPasskeyThunk = createAsyncThunk(
  'user/registerPasskey',
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const opt = await registrationOptions({});
      console.debug('Options', opt);
      const reg = await startRegistration(opt);
      console.debug('Registration', reg);
      const verify = await registerCredentials({
        ...reg,
      });
      console.debug('Verify', verify);

      return fulfillWithValue(verify);
    } catch (err) {
      console.log('Error', err);
      return rejectWithValue(`Failed to register this passkey! ${err.message}`);
    }
  },
);

export const updatePasswordThunk = createAsyncThunk(
  'user/updatePassword',
  async (password, { rejectWithValue }) => {
    try {
      return await updatePassword(password);
    } catch (err) {
      return rejectWithValue(`Failed to update password! ${err.message}`);
    }
  },
);

export const upsertUserThunk = createAsyncThunk(
  'user/upsert',
  async (user, { rejectWithValue }) => {
    try {
      return user?._id ? await updateUser(user) : await createUser(user);
    } catch (err) {
      return rejectWithValue(`Failed to save user! ${err.message}`);
    }
  },
);

export const removeUserThunk = createAsyncThunk(
  'user/remove',
  async (user, { rejectWithValue }) => {
    try {
      return await removeUser(user);
    } catch (err) {
      return rejectWithValue(`Failed to remove user! ${err.message}`);
    }
  },
);

export const switchCompanyThunk = createAsyncThunk(
  'user/switchCompany',
  async (
    { i18n, ...params },
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    try {
      const response = await refreshToken(params);
      console.log('Switch response', params, response);
      return handleSignin(
        i18n,
        response,
        fulfillWithValue,
        rejectWithValue,
        dispatch,
      );
    } catch (err) {
      return rejectWithValue(`Failed to refresh token! ${err.message}`);
    }
  },
);

export const refreshTokenThunk = createAsyncThunk(
  'user/refreshToken',
  async (
    { i18n, ...params },
    { rejectWithValue, fulfillWithValue, dispatch },
  ) => {
    try {
      const response = await refreshToken(params);

      return handleSignin(
        i18n,
        response,
        fulfillWithValue,
        rejectWithValue,
        dispatch,
      );
    } catch (err) {
      return rejectWithValue(`Failed to refresh token! ${err.message}`);
    }
  },
);

export const logoutThunk = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      return await logout();
    } catch (err) {
      return rejectWithValue(`Failed to logout! ${err.message}`);
    }
  },
);

export const updateProfileThunk = createAsyncThunk(
  'user/updateProfile',
  async (profile, { rejectWithValue }) => {
    try {
      return await updateProfile(profile);
    } catch (err) {
      return rejectWithValue(`Failed to update profile! ${err.message}`);
    }
  },
);

export const inviteThunk = createAsyncThunk(
  'contact/invite',
  async (params, { rejectWithValue }) => {
    try {
      return await invite(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
