import { Add, Delete, Edit } from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import SensorCoverageModal from '@pw/components/admin/Sensors/SensorCoverageModal';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { Body1, H5, Overline } from '@pw/components/Typography';
import { upsertSensorThunk } from '@pw/redux/thunks/sensors';
import toLocation from '@pw/utilities/adapters/toLocation';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

// interface SensorUpsertRequest extends Sensor {
//     // id: companyPK(company_id);
//     // path: /sensor/0013A2004237EB6A
//     sensor_id?: string; //is available on on create
//     gateway_id: string; // /gateway/40:F5:20:BA:0F:90 TODO: we will need to make index here to be able to update sensor
//     sensorCoverage: Array<SensorCoverage>;
// }

// export interface SensorCoverage extends DynamoEntry {
//   // id: companyPK(company_id),
//   // path: /sn/uniqLocation
//   sensor_id: string; // /sensor/0013A2004237EB6A TODO: we will need to make index here to be able to update sensor location
//   location: UniqueLocationKey;
//   // date: number; ?
// }

function SensorCoverageItem({ item = {}, remove, edit }) {
	const {
		facility_id = '',
		bay = '',
		row = '',
		level = '',
	} = toLocation(item?.path?.replace('/sn/', ''));

	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<Stack spacing={0.5}>
					<IDDisplay value={facility_id.split('/')[1]} />
					<Stack className='listForm' direction='row' spacing={2}>
						<Stack alignItems='center'>
							<Body1>
								<strong>{bay}</strong>
							</Body1>
							<Overline>Bay</Overline>
						</Stack>
						<Stack alignItems='center'>
							<Body1>
								<strong>{row || '•'}</strong>
							</Body1>
							<Overline>Row</Overline>
						</Stack>
						<Stack alignItems='center'>
							<Body1>
								<strong>{level || '•'}</strong>
							</Body1>
							<Overline>Level</Overline>
						</Stack>
					</Stack>
				</Stack>
			</Box>

			<Stack className='listButtons'>
				<IconCircleButton onClick={edit}>
					<Edit />
				</IconCircleButton>
				<IconCircleButton onClick={remove}>
					<Delete />
				</IconCircleButton>
			</Stack>
		</Box>
	);
	// return (
	//   <Stack className="listItem">
	//     <Box className="listContent">
	//       <Stack className="listForm" direction="row" spacing="3rem">
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{facility_id.split('/')[1]}</Body2>
	//           <Overline>Facility</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{bay}</Body2>
	//           <Overline>Bay</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{row || '•'}</Body2>
	//           <Overline>Row</Overline>
	//         </Stack>
	//         <Stack alignItems="center">
	//           <Body2 sx={{ fontSize: '2rem' }}>{level || '•'}</Body2>
	//           <Overline>Level</Overline>
	//         </Stack>
	//       </Stack>
	//     </Box>

	//     <Stack className="listButton" direction="row" spacing="0.25rem">
	//       <IconCircleButton onClick={edit}><Edit /></IconCircleButton>
	//       <IconCircleButton onClick={remove}><Delete /></IconCircleButton>
	//     </Stack>
	//   </Stack>
	// );
}

function SensorModal({ entity, open, upsert, onClose, gateway_id }) {
	const dispatch = useDispatch();

	const editing = useMemo(() => !!entity?.path, [entity]);
	const title = useMemo(() => (editing ? 'Edit Sensor' : 'New Sensor'), [editing]);

	console.log('Sensor', entity);

	// const withId = useMemo(
	// 	() =>
	// 		(entity?.sensorCoverage ?? []).map((item) => ({
	// 			...item,
	// 			id: generateFastestUUID(),
	// 		})),
	// 	[entity],
	// );
	//
	// console.log('Sensor modal', withId);

	const [sensorCoverageState, , , upsertSensorCoverage, removeSensorCoverage] =
		useItemListManager({ initialData: entity?.sensorCoverage });

	const [location, setLocation] = useState(null);

	const changeSet = {
		path: [
			entity?.path?.replace('/sensor/', '') ?? '',
			yup.string().min(1).required('Sensor Address is required!'),
		],
	};

	const handleSubmit = useCallback((values) => {
			const payload = {
					...entity,
					...values,
					gateway_id,
					sensorCoverage: sensorCoverageState,
				};
			console.log('Upserting', payload);
			dispatch(upsertSensorThunk(payload))
				.unwrap()
					.then((res) => {
					upsert({ ...payload, ...res, sensorCoverage: sensorCoverageState });
					onClose();
				});
		},
		[entity, gateway_id, sensorCoverageState],
	);

	return (
		<>
			<ModalWithClose open={open} onClose={onClose} title={title}>
				<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
					<Stack spacing="1.5rem">
						<FormikTextField
							disabled={editing}
							label='Sensor address'
							name='path'
							fullWidth
						/>

						<FlexBox>
							<H5>Coverage Locations</H5>
							<IconCircleButton onClick={() => setLocation({})}>
								<Add />
							</IconCircleButton>
						</FlexBox>

						<Stack className='list'>
							{sensorCoverageState.length === 0 && (
								<Alert severity='warning'>
									No coverage locations configured.
								</Alert>
							)}
							{sensorCoverageState.map(
								(item, index) =>
									!location && (
										<SensorCoverageItem
											item={item}
											edit={() => setLocation(item)}
											remove={() => removeSensorCoverage(item)}
											key={`${item?.path ?? index}`}
										/>
									),
							)}
						</Stack>

						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
								label='Cancel'
							/>
							<FilledButton type='submit' size='small'>
								{editing ? 'Update' : 'Add'}
							</FilledButton>
						</Box>
					</Stack>
				</FormikForm>
			</ModalWithClose>
			{!!location && (
				<SensorCoverageModal
					open={!!location}
					entity={location}
					upsert={upsertSensorCoverage}
					onClose={() => setLocation(null)}
				/>
			)}
		</>
	);
}

export default SensorModal;
