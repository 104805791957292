import { AddCircle } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import PriceList from '@pw/components/Liquid/LiquidPriceCurve/PriceList';
import PriceModal from '@pw/components/Liquid/LiquidPriceCurve/PriceModal';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function LiquidPriceCurve(props) {
	const { title = 'Price Curve', field = 'price_curve' } = props;

	const confirm = useConfirm();

	const { values } = useContext(FormikContext);
	const [priceCurve, , , upsertPrices, removePrices] = useItemListManager({
		comp: COMP.date,
		initialData: values[field],
	});

	// Currently selected price
	const [selectedPrice, setSelectedPrice] = useState(null);

	const handleRemovePrice = (price) => (removeFunc) => {
		confirm({
			title: 'Remove Price',
			content: <Body1>{`Remove price entry?`}</Body1>,
		})
			.then(() => removeFunc(price))
			.catch(() => {});
	};

	return (
		<>
			<InventoryComponent
				priceCurve={priceCurve}
				field={field}
				title={title}
				setSelectedPrice={setSelectedPrice}
				handleRemovePrice={handleRemovePrice}
				removePrices={removePrices}
			/>
			<ModalComponents
				upsertPrices={upsertPrices}
				setSelectedPrice={setSelectedPrice}
				title={title}
				selectedPrice={selectedPrice}
			/>
		</>
	);
}

function InventoryComponent({
	priceCurve,
	field,
	title,
	setSelectedPrice,
	handleRemovePrice,
	removePrices,
}) {
	const { setFieldValue } = useContext(FormikContext);

	useEffect(() => {
		console.log('Updating prices', priceCurve);
		debounce(() => setFieldValue(field, priceCurve), 25);
	}, [priceCurve]);

	return (
		<Stack spacing={1} className='section'>
			{title && <H5 className='section-header'>{title}</H5>}

			<Stack className='inventory'>
				<Box className='inventory-header'>
					<Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
					<Stack direction='row' spacing={1}>
						<TitledButton
							handleClick={() => setSelectedPrice({})}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
					</Stack>
				</Box>
				{priceCurve.length > 0 && (
					<PriceList
						items={priceCurve}
						onEdit={(i) => setSelectedPrice(i)}
						onRemove={(i) => handleRemovePrice(i)(removePrices)}
					/>
				)}
				{priceCurve.length === 0 && (
					<Box p={2} className='inventory-footer'>
						<Body3>None</Body3>
					</Box>
				)}
			</Stack>
		</Stack>
	);
}

function ModalComponents({
	upsertPrices,
	setSelectedPrice,
	title,
	selectedPrice,
}) {
	const handlePriceUpdate = useCallback(
		(price) => {
			if (price) {
				upsertPrices(price);
			}
			setSelectedPrice(null);
		},
		[upsertPrices, setSelectedPrice],
	);

	return (
		<>
			{!!selectedPrice && (
				<PriceModal
					title={title}
					open={!!selectedPrice}
					item={selectedPrice}
					onClose={handlePriceUpdate}
				/>
			)}
		</>
	);
}

export default LiquidPriceCurve;
