import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikMeasuresField, FormikNumberField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SpecificationEditor from '@pw/components/SpecificationEditor';
import useSpecificationSelectorHook from '@pw/components/SpecificationSelector';
import { THING_TYPES } from '@pw/consts/thing';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import { useMemo } from 'react';
import * as yup from 'yup';

function MashThingModal({ item, open, onClose }) {
	// We track the "amount" we want at the Thing Item level
	const { name, description, percent = '', age = '', amount } = item ?? {};

    const [
        [specifications],
        SpecificationComponent,
        SpecificationModals,
      ] = useSpecificationSelectorHook({
        title: 'Specification',
        SpecificationModal: SpecificationEditor,
      });

	const changeSet = useMemo(
		() => ({
			percent: [
				percent,
				yup.number().positive('Must be positive!'),
			],
			age: [
				age,
				yup.number().positive('Must be positive!'),
			],
			amount: [
				amount,
				yup.number().positive('Must be positive!'),
			],
		}),
		[percent, age],
	);

	const handleSubmit = (values) => {
		const entry = {
			thing: item._id,
			...values,
		};
		console.log('Updating thing..', entry);
		onClose({entry, specifications});
	};

	return (
		<ModalWithClose title={name} open={open} onClose={() => onClose()}>
			<Instructions>{description}</Instructions>

			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={1}>
					{item.type === THING_TYPES.LIQUID && (
						<FlexBox alignItems='top'>
							<FormikNumberField label='Percent' name='percent' fullWidth />
							<FormikNumberField label='Age' name='age' fullWidth />
						</FlexBox>
					)}
					{item.type === THING_TYPES.CONSUMABLE && (
            <FormikMeasuresField label='Items' name='amount' fullWidth />
					)}

                    { item.type === THING_TYPES.FINISHED && (
                        <SpecificationComponent />
                    ) }

					<AlertView />

					<Box className='action-buttons'>
						<TextButton size='small' handleClick={() => onClose()} color='secondary'>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
            <SpecificationModals />
		</ModalWithClose>
	);
}

export default MashThingModal;
