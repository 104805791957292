import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import ThingSearch from '@pw/components_v2/search/thing';
import { LIQUID_ACCOUNT_TYPE_OPTIONS } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import ThingField from '../ThingSelector/ThingField';
import { TAG_CATEGORY } from '@pw/consts/tag';

export default function LiquidSource({
  disabled = false,
  useLiquidAccount = false,
  useLiquidSelector = true,
}) {
  return (
    <Stack spacing={2}>
      {useLiquidSelector && (
        <ThingSearch field="thing" label='Liquid' types={[THING_TYPES.LIQUID]} />
      )}
      {!!useLiquidAccount && (
        <FormikSelect
          disabled={disabled}
          label='Liquid Account'
          name='account'
          options={LIQUID_ACCOUNT_TYPE_OPTIONS}
          fullWidth
        />
      )}
      {
        // TODO: implement batches field
        /*
				<FlexBox alignItems='top'>
					<FormikTextField
						disabled={disabled}
						name='batches'
						label='Batches'
						fullWidth
					/>
				</FlexBox>
				*/
      }
      <TagSelect disabled={disabled} name='tags' type={TAG_CATEGORY.LIQUID} />
    </Stack>
  );
}
