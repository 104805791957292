import React from 'react';
import Monitoring from '@pw/components/icons/Monitoring';
import { H5 } from '@pw/components/Typography';
import SensorPanel from '@pw/components/Dashboard/components/SensorPanel';
import { Box } from '@mui/material';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';

const MonitoringPanel = ({ facilities, isLoading, setUniqueLocationId, sensorData}) => {
  return (
    <Box className='content-row content-light' >
    <Box className='content-header'>
      <Box className='title-box'>
        <Monitoring height={24} width={24} />
        <H5>Monitoring</H5>
      </Box>
      <BasicMenu
        menuOptions={facilities.map(({ id, title }) => ({
          title,
          onClick: () => setUniqueLocationId(id),
        }))}
        isLoading={isLoading}
      />
    </Box>
    <Box className='content-body content-3col'>
      <SensorPanel data={sensorData} />
    </Box>
  </Box>
  );
};

export default MonitoringPanel;
