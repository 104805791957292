import { ArrowForward, SwapVerticalCircleOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Filter } from '@pw/components/icons';

const LayoutInvoices = ({ currentWidth, currentHeight }) => {
  const totalInvoicesCount = 25;
  const totalInvoiceAmount = 15400;
  const nextDueInvoices = [
    {
      id: 1,
      invoiceNumber: 'INV1001',
      customerName: 'Customer A',
      dueDate: '2025-01-22',
      totalAmount: 2200,
    },
    {
      id: 2,
      invoiceNumber: 'INV1002',
      customerName: 'Customer B',
      dueDate: '2025-01-24',
      totalAmount: 1800,
    },
    {
      id: 3,
      invoiceNumber: 'INV1003',
      customerName: 'Customer C',
      dueDate: '2025-01-27',
      totalAmount: 3200,
    },
    {
      id: 4,
      invoiceNumber: 'INV1004',
      customerName: 'Customer D',
      dueDate: '2025-02-01',
      totalAmount: 4000,
    },
    {
      id: 5,
      invoiceNumber: 'INV1005',
      customerName: 'Customer E',
      dueDate: '2025-02-05',
      totalAmount: 2200,
    },
  ];

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;
  return (
    <Box id='LayoutInvoices'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: isSmallScreen ? '8px' : '16px',
          paddingRight: isSmallScreen ? '24px' : '54px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '16px',
            boxSizing: 'border-box',
          }}
        >
          <IconButton sx={{ marginRight: isSmallScreen ? '10px' : '20px' }}>
            <SwapVerticalCircleOutlined
              height={isSmallScreen ? 30 : 43}
              width={isSmallScreen ? 20 : 29}
            />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '9px',
            }}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? '16px' : '19px',
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              Invoices
            </Typography>
            <Typography
              variant='body1'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? '14px' : '16px',
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              fr.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.01.24
              </Typography>
              <ArrowForward
                sx={{
                  verticalAlign: 'middle',
                  marginX: '4px',
                  width: isSmallScreen ? '30px' : '50px',
                }}
              />
              to.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.02.2024
              </Typography>
            </Typography>
          </Box>
        </Box>

        <IconButton>
          <Filter />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen
            ? '1fr'
            : isMediumScreen
              ? '1fr 1fr'
              : '1fr 1fr 1fr',
          margin: 'auto',
          paddingLeft: isSmallScreen ? '16px' : '42px',
          columnGap: isSmallScreen ? '20px' : '40px',
          rowGap: '7px',
          width: '100%',
        }}
      >
        {/* Total Invoices in Period */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Total Invoices in Period
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: '400',
                fontSize: '19px',
                // lineHeight: '31px',
                color: '#393C3C',
              }}
            >
              {totalInvoicesCount} Invoices
            </Typography>
          </Box>

          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Next 5 Due Invoices */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Next 5 Due Invoices
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //   padding: '20px',
            }}
          >
            {nextDueInvoices.map((invoice, index) => (
              <Box
                key={invoice.id}
                sx={{
                  //   display: 'flex',
                  //   justifyContent: 'space-between',
                  //   alignItems: 'center',
                  borderBottom: '1px solid #DDDDDD',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    // lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  Invoice #{invoice.invoiceNumber}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    // lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  {invoice.customerName}
                </Typography>
                <Typography
                  // component='span'
                  sx={{
                    fontWeight: 400,
                    fontSize: '13px',
                    // lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  Due: {invoice.dueDate}
                </Typography>
                <Typography
                  // component='span'
                  sx={{
                    fontWeight: 700,
                    fontSize: '13px',
                    // lineHeight: '31px',
                    color: '#393C3C',
                  }}
                >
                  Total: ${invoice.totalAmount}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Total Invoices in Period  */}
        <Box sx={{ position: 'relative', paddingRight: '54px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Total Invoices in Period
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              //   padding: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                //   borderBottom:
                //     index !== liquidMovementData.length - 1
                //       ? '1px solid #DDDDDD'
                //       : 'none',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '19px',
                  // lineHeight: '31px',
                  color: '#393C3C',
                }}
              >
                ${totalInvoiceAmount}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutInvoices;
