import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { LIQUID_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      feed_size: unitField(initialValues?.feed_size),
      feed_interval: unitField(initialValues?.feed_interval),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <FlexBox>
        <FormikUnitField name="feed_size" label="Feed Size" options={LIQUID_UNIT_OPTIONS} fullWidth />
        <FormikUnitField name="feed_interval" label="Feed Interval" options={TIME_UNIT_OPTIONS} fullWidth />
      </FlexBox>
    </ModalWrapper>
  );
}

export default NodeModal;
