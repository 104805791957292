import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useState } from 'react';

export default function Section3({
	name,
	title = 'Section 3',
	disabled = false,
}) {
	const [expanded, setExpanded] = useState(false);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<H4>Completely Denatured Alcohol</H4>
					<FormikTextField
						name='COMPDENATURED_FN18'
						label='18'
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='COMPDENATURED_FN19'
						label='19'
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='COMPDENATURED_FN20'
						label='20'
						fullWidth
						disabled={disabled}
					/>
					<H4>Specially Denatured Alcohol</H4>

					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN1'
							label='1'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN2B'
							label='2-B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN2C'
							label='2-C'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN3A'
							label='3-A'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN3B'
							label='3-B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN4'
							label='4'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN6B'
							label='6-B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN12A'
							label='12-A'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN13A'
							label='13-A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN17'
							label='17'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN18'
							label='18'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN19'
							label='19'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN20'
							label='20'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN22'
							label='22'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN23A'
							label='23-A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN23F'
							label='23-F'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN23H'
							label='23H'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN25'
							label='25'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN25A'
							label='25A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN27'
							label='27'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN27A'
							label='27A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN27B'
							label='27B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN28A'
							label='28A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN29'
							label='29'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN30'
							label='30'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN31A'
							label='31A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN32'
							label='32'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN33'
							label='33'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN35'
							label='35'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN35A'
							label='35A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN36'
							label='36'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN37'
							label='37'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN38B'
							label='38B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN38C'
							label='38C'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN38D'
							label='38D'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN38F'
							label='38F'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN39'
							label='39'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN39A'
							label='39A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN39B'
							label='39B'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN39C'
							label='39C'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN39D'
							label='39D'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN40'
							label='40'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN40A'
							label='40A'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN40B'
							label='40B'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN40C'
							label='40C'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN42'
							label='42'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN44'
							label='44'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FN45'
							label='45'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FN46'
							label='46'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK1'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK1VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK2'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK2VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK3'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK3VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK4'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK4VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>

					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK5'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK5VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK6'
							label='Formula Number'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENTRDALC_FNBLANK6VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECFORM_FNBLANK1'
							label='Special Formulas'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECFORM_FNBLANK1VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECFORM_FNBLANK2'
							label='Special Formulas'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECFORM_FNBLANK2VALUE'
							label='Specially Denatured Alcohol'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<H4>Specially Denatured Rum</H4>
					<FormikTextField
						name='SPECDENRUM_FN4'
						label='4'
						fullWidth
						disabled={disabled}
					/>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENRUM_FNBLANK1'
							label='Specify'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENRUM_FNBLANK1VALUE'
							label='Specially Denatured Rum'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
					<FlexBox alignItems='top'>
						<FormikTextField
							name='SPECDENRUM_FNBLANK2'
							label='Specify'
							fullWidth
							disabled={disabled}
						/>
						<FormikTextField
							name='SPECDENRUM_FNBLANK2VALUE'
							label='Specially Denatured Rum'
							fullWidth
							disabled={disabled}
						/>
					</FlexBox>
				</>
			)}
		</FormikProvider>
	);
}
