import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part1Report({
	name,
	title = 'On Hand First of Month',
	disabled,
	bonded,
	inventory5,
	inventory21,
}) {
	const inventoryOptions = [
		{ value: 'Inventory Gain', label: 'Inventory Gain' },
		{ value: 'Other', label: 'Other' },
	];
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikSelect
				name='InventorOther_5'
				label='Other or Inventory Gain'
				options={inventoryOptions}
				fullWidth
				disabled={disabled}
				sx={{ ...(!inventory5 && styles.transaction) }}
			/>
			<FormikSelect
				name='InventorOther_21'
				label='Other or Inventory Gain'
				options={inventoryOptions}
				fullWidth
				disabled={disabled}
				sx={{ ...(!inventory21 && styles.transaction) }}
			/>
			<FormikTextField
				name='b'
				label='Whisky Distilled At 160° And Under'
				fullWidth
				sx={{ ...(!bonded && styles.transaction) }}
			/>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='c'
					label='Whisky Distilled At Over 160°'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
				<FormikTextField
					name='d'
					label='Brandy Distilled At 170° And Under'
					fullWidth
					sx={{ ...((inventory21 || inventory5) && styles.transaction) }}
				/>
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='e'
					label='Brandy Distilled At Over 170°'
					fullWidth
					sx={{ ...((inventory21 || inventory5) && styles.transaction) }}
				/>
				<FormikTextField
					name='f'
					label='Rum'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
				<FormikTextField
					name='g'
					label='Gin'
					fullWidth
					sx={{ ...(!bonded && styles.transaction) }}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
Part1Report.defaultProps = {
	inventory5: false,
	inventory21: false,
	bonded: false,
	disabled: false,
};
