function SvgDelay(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M1.5 16.5h46.383C55.95 16.5 62 22.929 62 31.5s-6.05 15-14.117 15H1.5z'
        fill='#ffc'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgDelay;
