import { THING_TYPES } from '@pw/consts/thing';
import calculateThingStorageLiquid from './calculateThingStorageLiquid';

const calculateSourcesGains = (
  assets = [],
  things = [],
  converter,
  thingList = [],
) => {
  let storageBLGain = 0;
  let storageLAGain = 0;
  let totalBL = 0;
  let totalLA = 0;
  let isImported = false;
  let isDutyPaid = false;

  if (!assets.length && !things.length) {
    return [0, 0, 0, 0, false, false];
  }
  assets.forEach((asset) => {
    const { properties = {} } = asset;
    const { liquid = {}, imported = false, duty_paid = false } = properties;
    const { level = {} } = liquid;
    const {
      bl = 0,
      expected_bl = 0,
      updated_abv = 0,
      updated_tcf = 1,
      updated_bl = 0,
    } = level;

    isImported = imported;
    isDutyPaid = duty_paid;

    const actualBL = Number(bl) - Number(updated_bl);
    const bulkGain = Number(expected_bl) - actualBL;

    storageBLGain += bulkGain;
    storageLAGain +=
      bulkGain * ((Number(updated_abv) * Number(updated_tcf)) / 100);

    totalBL += Number(expected_bl);
    totalLA +=
      Number(expected_bl) * ((Number(updated_abv) * Number(updated_tcf)) / 100);
  });

  things.forEach((thing) => {
    if (thing?.type === THING_TYPES.FINISHED) {
      const amount = Number(
        (thing.entries ?? []).reduce(
          (acc, curr) => acc + Number(curr.amount),
          0,
        ),
      );
      const { bl = 0, la = 0 } = calculateThingStorageLiquid(
        converter,
        amount,
        thing,
        thingList,
      );
      totalBL += Number(bl);
      totalLA += Number(la);
    }
  });

  console.log('Totals', storageBLGain, storageLAGain, totalBL, totalLA);
  return [
    storageBLGain,
    storageLAGain,
    totalBL,
    totalLA,
    isImported,
    isDutyPaid,
  ];
};

const calculateDestinationsGains = (
  assets = [],
  things = [],
  converter,
  thingList,
) => {
  let totalBL = 0;
  let totalLA = 0;

  if (!assets.length && !things.length) {
    return [0, 0];
  }
  assets.forEach((asset) => {
    const { properties = {} } = asset;
    const { liquid = {} } = properties;
    const { level = {} } = liquid;
    const { filled_bl = 0, updated_abv = 0, updated_tcf = 0 } = level;

    const absoluteBL = Math.abs(Number(filled_bl));
    totalBL += absoluteBL;
    totalLA += absoluteBL * ((Number(updated_abv) * Number(updated_tcf)) / 100);
  });
  things.forEach((thing) => {
    if (thing?.type === THING_TYPES.FINISHED) {
      const amount = Number(
        (thing.entries ?? []).reduce(
          (acc, curr) => acc + Number(curr.amount),
          0,
        ),
      );
      const { bl = 0, la = 0 } = calculateThingStorageLiquid(
        converter,
        amount,
        thing,
        thingList,
      );
      totalBL += Number(bl);
      totalLA += Number(la);
    }
  });
  return [totalBL, totalLA];
};

export const summarizeTransferLiquids = ({
  sourceAssets = [],
  destinationAssets = [],
  sourceThings = [],
  destinationSkus = [],
  converter,
  thingList,
}) => {
  const [
    sourceStorageBLGain,
    sourceStorageLAGain,
    sourceTotalBL,
    sourceTotalLA,
    isImported,
    isDutyPaid,
  ] = calculateSourcesGains(sourceAssets, sourceThings, converter, thingList);

  const [destTotalBL, destTotalLA] = calculateDestinationsGains(
    destinationAssets,
    destinationSkus,
    converter,
    thingList,
  );

  const operationsBLGain = Number(destTotalBL) - Number(sourceTotalBL);
  const operationsLAGain = Number(destTotalLA) - Number(sourceTotalLA);

  return {
    s_bl: sourceStorageBLGain,
    s_la: sourceStorageLAGain,
    o_bl: operationsBLGain,
    o_la: operationsLAGain,
    imported: isImported,
    duty_paid: isDutyPaid,
  };
};
