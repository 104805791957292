import { Box, Typography, IconButton } from "@mui/material";
import Filter from '@pw/components/icons/Filter';
import { useCompanyName } from "@pw/redux/containers/User";

const styles = {
  dashboardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "16px",
    boxSizing: "border-box",
    // border: "solid",
  },
  dashboardTitle: {
    fontWeight: "600",
    fontSize: "30px",
    lineHeight: "45px",
    color: "#393C3C",
  },
  dashboardSubtitle: {
    fontWeight: "400",
    fontSize: "23px",
    lineHeight: "34.5px",
    color: "#393C3C",
  },
};

const DashboardHeader = ({ isOpen, setIsOpen }) => {
  const companyName = useCompanyName();

  return (
    <Box sx={styles.dashboardHeader}>
      <Box>
        <Typography variant="h6" component="div" sx={styles.dashboardTitle}>
          DASHBOARD
        </Typography>
        <Typography variant="body1" component="div" sx={styles.dashboardSubtitle}>
          Welcome, {companyName}
        </Typography>
      </Box>

      <IconButton onClick={() => setIsOpen(prevState => !prevState)}>
        <Filter />
      </IconButton>
    </Box>
  );
};

export default DashboardHeader;
