import { Divider } from '@mui/material';
import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import LiquidProperties, { liquidPropertyFields } from '@pw/components/Liquid/LiquidProperties';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import { Overline } from '@pw/components/Typography';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import convertProperties from '@pw/utilities/convertProperties';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

function Properties() {
  return (
    <FormikProvider path='properties'>
      <Divider><Overline>Regauge</Overline></Divider>

      <LiquidProperties name='liquid' useWeight />
    </FormikProvider>
  );
}

function propertyFields(fields = {}, converter) {
  const { liquid = {} } = fields;

  return {
    liquid: liquidPropertyFields(liquid, converter, true, false),
  };
}

function RegaugeForm(props) {
  const [searchParams] = useSearchParams();
  const [fetchAsset] = useAssetLazyQuery();
  const asset = searchParams.get('asset');

  const converter = useConverter();

  const [
    [],
    [sourceAssets, initSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Assets',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.FILLING_TANK,
      ],
    },
  });

  const [
    [destinationThings],
    [],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Services',
    filter: {
      thing_types: [THING_TYPES.SERVICES],
    },
    ThingModal: DestinationThingModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      properties: propertyFields(initialValues?.properties, converter),
      policies: [initialValues?.policies, yup.array()],
      client: clientFields(initialValues?.client),
    }),
    [converter],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      initDestinations({ ...entity, things: entity?.destinations?.things });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      const properties = convertProperties(entity?.properties ?? {}, converter);

      return {
        ...entity,
        sources: {
          assets: sourceAssets.map((a) => ({
            ...a,
            properties: { ...a.properties, ...properties },
          })),
        },
        destinations: {
          things: destinationThings,
        },
        properties,
      };
    },
    [destinationThings, sourceAssets],
  );

  useEffect(() => {
    if (asset) {
      fetchAsset({ id: asset }).then((a) => {
        debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
      });
    }
  }, [asset, initSourceAssets, fetchAsset]);

  return (
    <>
      <RequestForm
        requestLabel='Regauge'
        requestType={REQUEST_TYPES.REGAUGE}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />
        <DestinationInventory />

        <Properties />

        <PolicySelector />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(RegaugeForm, { title: 'Regauge' });
