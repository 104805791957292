import {
  createContact,
  createPartnerContact,
  updateContact,
  updatePartnerContact,
} from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import handleError from './utils/handleError';

export const upsertContactThunk = createAsyncThunk(
  `company/upsertContactThunk`,
  async (contact, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting contact', contact);
      const response = contact?._id
        ? await updateContact(contact)
        : await createContact(contact);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const upsertPartnerContactThunk = createAsyncThunk(
  `company/upsertPartnerContactThunk`,
  async (company, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting partner contact', company);
      const response = company?._id
        ? await updatePartnerContact(company)
        : await createPartnerContact(company);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);
