function SvgAirQuality({color = 'black', ...props}) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 42 36'
      fill='none'
	  className='MuiSvgIcon-root'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M0 17.2274C0.237714 16.7552 0.644859 16.5864 1.14012 16.5777C1.31649 16.5745 1.49301 16.5735 1.66938 16.5735C12.1886 16.5734 22.7078 16.5656 33.227 16.5814C34.8837 16.5839 36.3735 16.1697 37.6218 15.0519C39.5583 13.3179 40.2484 11.1239 39.61 8.61422C38.9736 6.11227 37.3092 4.52898 34.8038 3.99482C31.2912 3.24618 27.9753 5.56578 27.3371 9.14308C27.277 9.4797 27.2578 9.82448 27.2296 10.1663C27.1769 10.8068 26.7898 11.1874 26.2153 11.1536C25.6917 11.1228 25.3161 10.6729 25.3331 10.0642C25.4515 5.83257 28.5551 2.59131 32.2979 2.02331C36.9578 1.3161 41.206 4.65443 41.6916 9.35526C42.1779 14.0621 38.7965 17.8284 34.7664 18.4153C34.1733 18.5017 33.5772 18.535 32.9827 18.5354C22.477 18.5427 11.9714 18.5405 1.46581 18.5416C0.879315 18.5416 0.310813 18.5126 0 17.8867V17.2274Z'
        fill={color}
      />
      <path
        d='M0 22.6664C0.327954 22.1831 0.80579 22.0769 1.34993 22.0771C11.3917 22.0819 21.4333 22.0773 31.4751 22.0825C34.8001 22.0842 37.401 24.2222 38.0151 27.432C38.6978 31.001 36.0714 34.5941 32.5023 34.9741C29.0133 35.3457 26.0226 33.0696 25.4236 29.5736C25.3619 29.2126 25.2956 28.841 25.3587 28.4704C25.4477 27.9494 25.8387 27.598 26.297 27.6102C26.7956 27.6234 27.1614 27.9887 27.2239 28.5312C27.263 28.8715 27.2793 29.2177 27.3538 29.5503C27.8379 31.7092 29.762 33.1959 31.9344 33.1044C34.0581 33.015 35.8284 31.3838 36.1528 29.2176C36.5278 26.7138 34.7676 24.3725 32.2945 24.0728C31.9428 24.03 31.5927 24.0118 31.2398 24.012C21.2388 24.0126 11.2377 24.0098 1.23674 24.0194C0.70195 24.02 0.275113 23.897 0 23.4081V22.6664Z'
        fill={color}
      />
      <path
        d='M0 11.7056C0.266897 11.2371 0.671917 11.0932 1.19594 11.0944C6.17603 11.1059 11.156 11.1026 16.1359 11.1003C17.5531 11.0996 18.7747 10.6178 19.7014 9.50915C20.7959 8.19982 21.097 6.69065 20.5454 5.07898C19.9772 3.41842 18.7912 2.3789 17.0651 2.09175C14.3358 1.63787 11.979 3.60078 11.8337 6.39791C11.81 6.85437 11.6688 7.23557 11.2217 7.42481C10.8595 7.57806 10.5234 7.4985 10.2408 7.23787C9.95678 6.97594 9.91102 6.62313 9.92519 6.25412C10.0299 3.51849 11.9978 1.02872 14.616 0.32166C18.3476 -0.686031 22.0825 1.74997 22.6311 5.61386C23.1923 9.56635 20.2524 12.7359 16.8569 12.9809C16.3823 13.0152 15.9094 13.0327 15.435 13.0328C10.7399 13.0336 6.04499 13.0305 1.34993 13.0367C0.80579 13.0374 0.327954 12.9306 0 12.4473V11.7056Z'
        fill={color}
      />
    </svg>
  );
}
export default SvgAirQuality;
