import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import AssetSearch from '@pw/components_v2/search/asset';
import LedgerSearch from '@pw/components_v2/search/ledger';
import ThingSearch from '@pw/components_v2/search/thing';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
  const {
    tags = [],
    thing,
  } = fields;

  return {
    tags: [
      typeof tags === 'string' ? tags?.split(',') : tags,
      yup.array().of(yup.string()),
    ],
    thing: [thing, yup.object()]
  };
}

function Properties({ entity }) {
  /*const filter = (item) =>
    item?.properties?.asset_type === ASSET_TYPES.CASK; */
  return (
    <FormikProvider path='properties'>
      <Stack spacing={2}>
        <Divider><Overline>Properties</Overline></Divider>
        <ThingSearch field="thing" label='Wood' types={[THING_TYPES.TRACKED]} />
        <TagSelect name='tags' type={TAG_CATEGORY.ASSET} entity={entity} />
      </Stack>
    </FormikProvider>
  );
}

function CaskPage(props) {
  const converter = useConverter();
  const [entity, setEntity] = useState(null);
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...assetIdFields(initialValues),
      ledger: [initialValues?.ledger, yup.object()],
      parent_asset: [initialValues?.parent_asset, yup.object()],
      properties: propertyFields(initialValues.properties),
    }),
    [converter],
  );

  const handleInit = useCallback(
    (entity) => {
      setEntity(entity);
    },
    [setEntity],
  );

  const handleBeforeSave = (entity) => {
    return entity;
  };

  return (
    <AssetForm
      assetLabel='Cask'
      assetType={ASSET_TYPES.CASK}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      onInit={handleInit}
      {...props}
    >
      <AssetSearch field="parent_asset" label='Pallet' types={[ASSET_TYPES.PALLET]} />
      <LedgerSearch field="ledger" label="Ledger" />
      <Properties entity={entity} />
    </AssetForm>
  );
}

export default withAppLayout(CaskPage, { title: 'Cask Management' });
