import { useMemo, useCallback, useContext, useState } from 'react';
import { Box } from '@mui/material';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { iconFromName, formLookup } from '@pw/components/ProductionDesigner/nodes';

import DesignerContext from "@pw/context/DesignerContext";
import NodeDataContextProvider from './NodeDataContext';

// import styles from './NodeModal.styles';
import { saveDesign, saveDesignBatch, saveDesignObservations } from '@pw/services/production.service';

const iconStyles = {
  fontSize: '3rem',

  svg: {
    display: 'block',
  },
}

const NodeModal = () => {
  const {
    nodesData,
    setNodesData,
    batch,
    mode,
    recipe,
    selectedNode: node,
    setSelectedNode,
    selectedNode,
    setIsLoading,
  } = useContext(DesignerContext);

  const [selfCausedUpdate, setSelfCausedUpdate] = useState(false);

  const nodeData = useMemo(
    () => nodesData[node?.id] || {},
    [nodesData, node?.id]
  );

  const Form = useCallback(
    () => formLookup(node?.data?.form || null),
    [node?.data?.form]
  );

  const saveHandlerBatch = (formData, batch) => {
    if (!batch.id && batch.path) {
      saveDesignBatch(batch);
    }
    const { date = null, label = null, observation_name, ...values } = formData;
    const id = btoa(`${new Date(date).getTime()}-${node.id}`);

    const observationData = {
      id,
      observation_name,
      value: JSON.stringify(values),
      time: new Date(date).getTime(),
      node_id: node.id,
    };
    const requestData = {
      thing_id: batch.thing_id,
      batch_id: batch._id,
      observations: [observationData],
    }

    saveDesignObservations(requestData);
  };

  const saveHandlerRecipe = (formData, recipe, nodesData, nodeId) => {
    const nodes_data = {
      ...nodesData,
      [nodeId]: {
        ...nodesData?.[nodeId],
        ...formData,
      }
    }
    setNodesData(nodes_data);
    saveDesign({
      ...recipe,
      nodes_data,
    });
  }

  const saveHandler = useCallback((formData) => {
    if (selfCausedUpdate) return;
    setIsLoading(true);

    setSelfCausedUpdate(true);

    if (mode === 'schedule') {
      saveHandlerBatch(formData, batch);
    } else {
      saveHandlerRecipe(formData, recipe, nodesData, node?.id);
    }

    setSelectedNode(null);
    setSelfCausedUpdate(false);
    setIsLoading(false);
  }, [selfCausedUpdate, node]);

  if (!node) return null;
  const icon = iconFromName(node?.data?.icon);
  const WrappedIcon = icon ? <Box sx={iconStyles}>{icon}</Box> : null;
  return (
    <ModalWithClose
      open={Boolean(node)}
      onClose={() => setSelectedNode(null)}
      title={node?.data?.label}
      icon={WrappedIcon}
    >
      <NodeDataContextProvider
        batch={batch}
        nodeId={selectedNode?.id}
        nodeData={nodeData}
        saveHandler={saveHandler}
      >
        <Form />
      </NodeDataContextProvider>
    </ModalWithClose >
  );
};

export default NodeModal;
