import { Person3, Restore, Update, ViewHeadline } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Box,
  Card,
  CardContent,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { LIQUID_ACCOUNT_TYPE_OPTIONS } from '@pw/consts/asset';
import { useMemo, useState } from 'react';
import { AssetListItemComponent } from '../AssetListItem';
import LocationContent from '../AssetListItem/LocationContent';
import { ClientInfo } from '../Client';
import { FlexBox } from '../Layout/FlexBox';
import { ThingDisplay } from '../ThingSelector/SelectedThing';
import { Body2, Overline } from '../Typography';
import LiquidContent from '../AssetListItem/LiquidContent';

function LiquidStatus({ item }) {
  const { imported, duty_paid } = item;
  return (
    <Stack>
      <FlexBox>
        <Body2>Imported</Body2>
        {imported ? (
          <CheckBoxIcon color='primary' />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </FlexBox>
      <FlexBox>
        <Body2>Duty Paid</Body2>
        {duty_paid ? (
          <CheckBoxIcon color='primary' />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </FlexBox>
    </Stack>
  );
}

function DisplayInfo({ label, children }) {
  return (
    <Stack gap={0.5}>
      <Overline>{label}</Overline>
      {children}
    </Stack>
  );
}

function Liquid({ item }) {
  const { thing, account, batch, date, liquid, enable } = item;

  const liquidAccount = useMemo(() => {
    const l = LIQUID_ACCOUNT_TYPE_OPTIONS.find((i) => i.value === account);
    return l ? l.label : 'Empty account';
  }, [account]);
  return (
    <Stack gap={2}>
      <FlexBox>
        <Body2>Enable</Body2>
        {enable ? (
          <CheckBoxIcon color='primary' />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </FlexBox>

      <DisplayInfo label='Liquid Source'>
        <ThingDisplay item={thing} />
      </DisplayInfo>

      <DisplayInfo label='Liquid Account'>
        <Body2>{liquidAccount}</Body2>
      </DisplayInfo>

      <DisplayInfo label='Batch'>
        <Body2>{batch || 'Empty'}</Body2>
      </DisplayInfo>

      <DisplayInfo label='Production date'>
        <Body2>{new Date(date).toLocaleString()}</Body2>
      </DisplayInfo>

      <DisplayInfo label='Tags'>
        <Body2>{liquid?.join(', ')}</Body2>
      </DisplayInfo>

      <DisplayInfo label='Liquid level'>
        <LiquidContent liquid={item} />
      </DisplayInfo>
    </Stack>
  );
}

function Account({ item }) {
  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <ClientInfo
          item={item}
          icon={<Person3 style={{ width: '18px', height: 'auto' }} />}
        />
      </Box>
    </Box>
  );
}

function Accounts({ item }) {
  return (
    <Stack spacing={2}>
      <FlexBox>
        <Body2>Enable</Body2>
        {item?.enable ? (
          <CheckBoxIcon color='primary' />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </FlexBox>
      <DisplayInfo label='Deed Holder'>
        <Account item={item?.bo} />
      </DisplayInfo>
      <DisplayInfo label='Managing Agent'>
        <Account item={item?.mo} />
      </DisplayInfo>
      <DisplayInfo label='Representative agent'>
        <Account item={item?.ro} />
      </DisplayInfo>
    </Stack>
  );
}

function ChargeCode({ item }) {
  const { code, start_date, end_date, enable } = item;
  return (
    <Stack spacing={2}>
      <FlexBox>
        <Body2>Enable</Body2>
        {enable ? (
          <CheckBoxIcon color='primary' />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )}
      </FlexBox>
      <DisplayInfo label='Charge Code'>
        <Body2>{code}</Body2>
      </DisplayInfo>
      <DisplayInfo label='Start date'>
        <Body2>{<Body2>{new Date(start_date).toLocaleString()}</Body2>}</Body2>
      </DisplayInfo>
      <DisplayInfo label='End date'>
        <Body2>{<Body2>{new Date(end_date).toLocaleString()}</Body2>}</Body2>
      </DisplayInfo>
    </Stack>
  );
}

function Changes({ field, item, type }) {
  const itemExist = typeof item === 'object' ? Object.keys(item).length : item;
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
          {field}
        </Typography>
        {!itemExist && <Body2>Empty</Body2>}
        {type === 'thing' && itemExist && <ThingDisplay item={item} />}
        {type === 'asset' && itemExist && (
          <AssetListItemComponent item={item} display={null} />
        )}
        {type === 'location' && itemExist && (
          <LocationContent location={item} />
        )}
        {type === 'text' && itemExist && <Body2>{item}</Body2>}
        {type === 'liquid_status' && itemExist && <LiquidStatus item={item} />}
        {type === 'liquid' && itemExist && <Liquid item={item} />}
        {type === 'accounts' && itemExist && <Accounts item={item} />}
        {type === 'charge_code' && itemExist && <ChargeCode item={item} />}
      </CardContent>
    </Card>
  );
}

const styles = {
  '&.chip': {
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
    backdropFilter: 'blur(2px)',
    border: 'rgba(0, 0, 0, 1)',
    position: 'absolute',
    maxWidth: 'min-content',
    padding: '4px',
    borderRadius: '25px',
    zIndex: 10,
    top: '-0.5rem',
    right: '0',
    '--shadow-color': '180deg 4% 9%',
    boxShadow: `
		  0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.45),
		  0.6px 0.7px 1px -1.3px hsl(var(--shadow-color) / 0.43),
		  1.4px 1.9px 2.6px -2.7px hsl(var(--shadow-color) / 0.41)`,
  },
  '.icon': {
    color: 'white',
    fill: 'white',
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  '.icon:hover': {
    color: '#C6DB5C',
    fill: '#C6DB5C',
  },
};

function RevertMessage({
  title,
  onRevert,
  isReverted = false,
  item,
  type,
  onUndo,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'info-popover' : undefined;

  return (
    <>
      <Stack direction='row' spacing={1} sx={styles} className='chip'>
        <ViewHeadline onClick={handleClick} id={id} className='icon' />
        {isReverted ? (
          <Update color='secondary' id={id} onClick={onUndo} className='icon' />
        ) : (
          <Restore
            color='secondary'
            id={id}
            onClick={onRevert}
            className='icon'
          />
        )}
      </Stack>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Changes field={title} item={item} type={type} />
      </Popover>
    </>
  );
}

export default RevertMessage;
