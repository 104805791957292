import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikCheckBox, FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { Body2, H5 } from '@pw/components/Typography';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { ArrayFormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function ConfirmationType({ name }) {
  return <FormikCheckBox name='value' label={<Body2>{name}</Body2>} />;
}

function StringType() {
  return <FormikTextField label='Value' name='value' fullWidth />;
}

function NumberType() {
  return (
    <FormikUnitField
      label='Value'
      name='value'
      options={UNITS_OPTIONS}
      fullWidth
    />
  );
}

function SelectionType({ name, item }) {
  const options = item?.range ?? [];
  return <FormikSelect label={name} name='value' options={options} fullWidth />;
}

function TypeParams({ item }) {
  console.log('Type', item);
  return (
    <>
      {item?.type?.toString() === '0' && <ConfirmationType name={item?.name} />}
      {item?.type?.toString() === '1' && <StringType />}
      {item?.type?.toString() === '2' && <NumberType />}
      {item?.type?.toString() === '3' && <SelectionType item={item} name={item?.name} />}
    </>
  );
}

function PolicyEntry({ item, index }) {
  const { name, description } = item;

  return (
    <ArrayFormikProvider index={index} path='entries'>
      <Box className='listItem'>
        <Box className='listContent'>
          <Stack spacing='1rem'>
            <Stack>
              <NameDisplay name={name} />
              <DescriptionDisplay value={description} />
            </Stack>
            <TypeParams item={item} />
          </Stack>
        </Box>
      </Box>
    </ArrayFormikProvider>
  );
}

function PolicyEditor({ item, open, onClose }) {
  const { name, _id, policy_stage, entries = [] } = item ?? {};

  const changeSet = useMemo(
    () => ({
      entries: [entries ?? [], yup.array().of(yup.object())],
    }),
    [entries],
  );

  const handleSubmit = (values) => {
    console.log('Setting Entry', values);
    onClose({
      name,
      policy_stage,
      _id,
      entries: values?.entries,
    });
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={name}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
          <Stack className='inventory'>
            <Box className='inventory-contents'>
              <Stack className='list'>
                {entries.map((entry, index) => (
                  <PolicyEntry item={entry} key={entry.name} index={index} />
                ))}
              </Stack>
            </Box>
          </Stack>

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default PolicyEditor;
