import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import SvgBottleFiller from '@pw/components/icons/BottleFiller';
import SvgPlant from '@pw/components/icons/Plant';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import BottlingLineDesigner from '@pw/components_v2/CompanyView/modals/plant/BottlingLineDesigner';
import DistilleryDesigner from '@pw/components_v2/CompanyView/modals/plant/DistilleryDesigner';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { DESIGN_TYPE } from '@pw/consts/designs';
import FormikContext from '@pw/context/FormikContext';
import { upsertDesignThunk } from '@pw/redux/thunks/designs';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useEdgesState, useNodesState } from 'reactflow';
import * as yup from 'yup';

function DesignSelector({ name, state }) {
  const { values, setFieldValue } = useContext(FormikContext);
  const confirm = useConfirm();

  const setDistilleryDesigner = useCallback(() => {
    confirm({
      title: 'Switch Designer',
      content: (
        <Stack>
          <Body1>Switch to Distillery Designer?</Body1>
          <Body3 color="error">NOTE: If there is a design, everything will be cleared, if you save this update, all historical data will be lost!</Body3>
        </Stack>
      ),
    })
    .then(() => debounce(() => {
      setFieldValue('design_type', DESIGN_TYPE.DISTILLERY);
      // Clear the nodes and edges
      state.nodesState[1]([]);
      state.edgesState[1]([]);
      // Clear all the node data
      state.nodesData[1]([]); // Reset the data
      state.edgesData[1]([]); // Reset the data
    }, 25))
    .catch(() => {
    });
  }, [confirm]);

  const setBottlingDesigner = useCallback(() => {
    confirm({
      title: 'Switch Designer',
      content: (
        <Stack>
          <Body1>Switch to Bottling Line Designer?</Body1>
          <Body3 color="error">NOTE: If there is a design, everything will be cleared, if you save this update, all historical data will be lost!</Body3>
        </Stack>
      ),
    })
    .then(() => debounce(() => {
      setFieldValue('design_type', DESIGN_TYPE.BOTTLING_LINE);
      // Clear the nodes and edges
      state.nodesState[1]([]);
      state.edgesState[1]([]);
      // Clear all the node data
      state.nodesData[1]([]); // Reset the data
      state.edgesData[1]([]); // Reset the data
    }, 25))
    .catch(() => {
    });
  }, [confirm]);

  return (
    <>
      <FlexBox justifyContent="space-evenly">
        <TitledButton handleClick={setDistilleryDesigner} label="Distillery">
          <SvgPlant height={24} width={24} />
        </TitledButton>
        <TitledButton handleClick={setBottlingDesigner} label="Bottling">
          <SvgBottleFiller height={24} width={24} />
        </TitledButton>
      </FlexBox>

      {values?.design_type === DESIGN_TYPE.DISTILLERY && (
        <DistilleryDesigner name={name} state={state} />
      )}
      {values?.design_type === DESIGN_TYPE.BOTTLING_LINE && (
        <BottlingLineDesigner name={name} state={state} />
      )}
    </>
  )
}

function DesignEditor({ entry, onClose, options, readonly }) {
  const dispatch = useDispatch();

  const {
    name,
    description = '',
    type = options[0].value,
    nodes = [],
    edges = [],
    nodes_data = [],
    edges_data = []
  } = entry;

  const nodesState = useNodesState(nodes);
  const edgesState = useEdgesState(edges);

  const nodesData = useItemListManager({ id: ID.id, comp: COMP.id, initialData: nodes_data });
  const edgesData = useItemListManager({ id: ID.id, comp: COMP.id, initialData: edges_data });

  const changeSet = {
    design_type: [type, yup.string()],
    description: [description, yup.string().required('Please provide a description for the design')]
  };

  const handleSubmit = useCallback(
    (values) => {
      console.log('Submitting', values);
      dispatch(
        upsertDesignThunk({
          ...entry,
          ...values,
          nodes: nodesState[0],
          edges: edgesState[0],
          nodes_data: nodesData[0],
          edges_data: edgesData[0],
        })
      )
      .unwrap()
      .then(onClose);
    },
    [entry, onClose, nodesState, edgesState, nodesData, edgesData]
  );

  return (
    <Stack>
      <FlexBox>
        <H5>{name}</H5>
      </FlexBox>
      <Box className="form-content">
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
          <Stack className="form" spacing="2rem">
            <FormikTextField label="Description" name="description" fullWidth required />

            <DesignSelector name={name} state={{ ...entry, nodesState, edgesState, nodesData, edgesData }} />

            <FormButtons onCancel={onClose} readonly={readonly} />
          </Stack>
        </FormikForm>
      </Box>
    </Stack>
  );
}

export default DesignEditor;
