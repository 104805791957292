import { Box, Tab, Tabs } from '@mui/material';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import SvgTeams from '@pw/components/icons/Teams';
import SvgUser from '@pw/components/icons/User';
import TeamsView from '@pw/components_v2/CompanyView/modals/users/TeamsView';
import UsersView from '@pw/components_v2/CompanyView/modals/users/UsersView';
import { useState } from 'react';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  console.log('Re-rendering tab', index);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function UserModal({ company, open, onClose }) {
  const title = `${company.name} Users`;
  const [value, setValue] = useState(0);

  console.log('User modal', company.name);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ModalWithClose onClose={() => onClose()} open={open} title={title}>
      <Box sx={{ border: '1px solid #d9d9d9', borderRadius: '5px', overflow: 'hidden' }}>
        <Tabs value={value} onChange={handleChange} aria-label="user management">
          <Tab label="Teams" icon={<SvgTeams />} {...a11yProps(0)} iconPosition="start" />
          <Tab label="Users" icon={<SvgUser />} {...a11yProps(1)} iconPosition="start" />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <TeamsView company={company} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UsersView company={company} />
        </CustomTabPanel>
      </Box>
    </ModalWithClose>
  );
}

export default UserModal;
