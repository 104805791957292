import generalInformationFields from './generalInformationFields';
import transactionsFields from './Transactions/transactionsFields';
import productionOfAlcoholFields from './ProductionOfAlcohol/productionOfAlcoholFields';
import productionOfWhiskeyFields from './ProductionOfWhiskey/productionOfWhiskeyFields';
import productionOfBrandyFields from './ProductionOfBrandy/productionOfBrandyFields';
import usedInRedistillationFields from './UsedInRedistillation/usedInRedistillationFields';
import materialsUsedFields from './MaterailsUsed/materialsUsedFields';
import signatureFields from './signatureFields';
import * as yup from 'yup';
import transactionsSpiritsFields from './TransactionsSpirits/transactionsSpiritsFields';
import transactionsRumFields from './TransactionsRum/transactionsRumFields';

export default function TTBReportFields(fields = {}, company, companyName) {
	const {
		transactionsData,
		transactionsRumData,
		transactionsSpiritsData,
		production_of_alcohol,
		production_of_whiskey,
		production_of_Brandy,
		used_in_redistillation,
		materials_used,
		signature,
		formUrlId = '1737199',
	} = fields;

	const defaultValues = {
		nameOfProprietor: companyName ?? '',
		doingBusinessAs: company?.account_name ?? '',
		streetAddress: company?.company_address.address_1 ?? '',
		city: company?.company_address.city ?? '',
		state: company?.company_address.state ?? '',
		zipCode: company?.company_address.post_code ?? '',
		plantNumber: company.company_compliance.compliance_id ?? '',
	};
	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields({...defaultValues}),
		'PartI-Transactions-WhiskeyAndBrandy': transactionsFields(transactionsData),
		'PartI-Transactions-Rum,GinAndVodka':
			transactionsRumFields(transactionsRumData),
		'PartI-Transactions-AlcoholAndSpiritsAndOther,AndTotals':
			transactionsSpiritsFields(transactionsSpiritsData),
		'PartII-ProductionOfAlcoholAndSpiritsOf190OrMore':
			productionOfAlcoholFields(production_of_alcohol),
		'PartIII-ProductionOfWhiskeyByKindAndCoorperageUsed':
			productionOfWhiskeyFields(production_of_whiskey),
		'PartIV-ProductionOfBrandy': productionOfBrandyFields(production_of_Brandy),
		'PartV-UsedInRedistillation': usedInRedistillationFields(
			used_in_redistillation,
		),
		'PartVI-MaterialsUsed': materialsUsedFields(materials_used),
		Signature: signatureFields(signature),
	};
}
