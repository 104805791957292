function SvgDatabase(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M1.5 18.75c0 2.125 13.2 4.25 30 4.25s30-2.125 30-4.25v25.5c0 2.125-13.2 4.25-30 4.25s-30-2.125-30-4.25z'
        fill='#ccf'
      />
      <path
        d='M61.5 18.75c0 2.125-13.2 4.25-30 4.25s-30-2.125-30-4.25 13.2-4.25 30-4.25 30 2.125 30 4.25z'
        fill='#ccf'
      />
      <path
        d='M1.5 20.875c0 2.125 13.2 4.25 30 4.25s30-2.125 30-4.25'
        fill='none'
      />
      <path
        d='M1.5 23c0 2.125 13.2 4.25 30 4.25s30-2.125 30-4.25'
        fill='none'
      />
    </svg>
  );
}
export default SvgDatabase;
