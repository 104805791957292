import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { unit_price = '0' } = fields;
	return {
		unit_price: [unit_price, yup.string().required('Unit Price is required!')],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<Divider><Overline>Properties</Overline></Divider>
				<FormikTextField label='Unit Price' name='unit_price' fullWidth />
			</Stack>
		</FormikProvider>
	);
}

function ThingPage(props) {
	const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...thingSettingsFields(initialValues, THING_TYPES.SERVICES),
      properties: propertyFields(initialValues.properties),
    }),
    [],
  );

	const handleBeforeSave = (entity) => entity;

	return (
    <ThingForm
      thingLabel='Service'
      thingType={THING_TYPES.SERVICES}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      {...props}
    >
      <Properties />
    </ThingForm>
  );
}

export default withAppLayout(ThingPage, { title: 'Services' });
