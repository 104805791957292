function SvgSensor(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Sensor_svg__clip0_99_17)' fill='#404040'>
        <path d='M0 7.31c.056-.412.098-.826.17-1.235.39-2.246 1.394-4.178 2.973-5.81.311-.322.716-.35.986-.079.267.269.247.662-.06.978C2.74 2.54 1.848 4.156 1.49 6.041c-.58 3.06.107 5.815 2.076 8.234.171.21.366.403.548.606.266.295.266.696 0 .947-.257.242-.653.23-.924-.047C1.593 14.155.582 12.218.176 9.968.1 9.545.058 9.116.001 8.69V7.309H0zM22 7.947c-.051 3.054-1.083 5.642-3.161 7.8-.297.308-.701.335-.971.07s-.255-.663.05-.977c1.332-1.374 2.223-2.992 2.582-4.877.585-3.07-.108-5.83-2.086-8.253-.167-.205-.356-.393-.535-.59-.267-.294-.268-.696-.002-.946.259-.244.657-.232.925.042 1.675 1.715 2.707 3.756 3.065 6.136.031.205.062.412.077.62.026.344.04.69.056.976zM13.706 8.01a2.716 2.716 0 01-2.726 2.715A2.715 2.715 0 018.285 7.98a2.715 2.715 0 012.714-2.704 2.714 2.714 0 012.707 2.734zM10.994 6.57c-.786 0-1.416.632-1.42 1.42a1.42 1.42 0 001.424 1.438 1.42 1.42 0 001.42-1.42 1.418 1.418 0 00-1.424-1.438z' />
        <path d='M2.757 7.771c.04-2.029.81-3.954 2.352-5.56.292-.306.676-.337.953-.08.282.262.279.663-.024.977C4.963 4.223 4.3 5.543 4.112 7.087c-.262 2.15.336 4.036 1.778 5.649.067.075.14.144.206.22.241.287.226.672-.033.913-.26.242-.645.234-.908-.032a8.275 8.275 0 01-1.602-2.28c-.522-1.1-.806-2.376-.796-3.785zM19.236 8.1c-.036 2.143-.806 4.076-2.352 5.689-.3.313-.695.338-.97.067-.267-.265-.257-.655.039-.96 1.11-1.15 1.778-2.517 1.944-4.108.22-2.1-.382-3.949-1.794-5.522-.063-.07-.13-.134-.192-.204-.256-.291-.248-.68.016-.929.265-.248.651-.233.924.045a8.22 8.22 0 011.921 3.08c.312.89.455 1.81.465 2.843z' />
        <path d='M5.518 8.007c.016-1.47.515-2.74 1.518-3.808.312-.331.702-.368.984-.101.276.262.256.666-.05.994-1.455 1.564-1.558 3.882-.244 5.537.098.123.21.235.313.354.242.282.235.673-.014.915a.644.644 0 01-.924-.03c-1.042-1.075-1.563-2.366-1.582-3.86zM16.473 7.994c-.017 1.475-.522 2.75-1.531 3.822-.298.315-.695.348-.97.087-.273-.262-.26-.661.034-.978 1.581-1.706 1.577-4.136-.009-5.86-.242-.262-.29-.557-.135-.825a.603.603 0 01.697-.293c.136.04.275.13.373.233 1.017 1.065 1.522 2.342 1.54 3.814z' />
      </g>
      <defs>
        <clipPath id='Sensor_svg__clip0_99_17'>
          <path fill='#fff' d='M0 0h22v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgSensor;
