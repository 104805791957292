function SvgOr(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <circle cx={31.5} cy={31.5} fill='#ccffd5' r={12} />
      <g fill='none'>
        <path d='M31.5 19.5v24M43.5 31.5h-24' />
      </g>
    </svg>
  );
}
export default SvgOr;
