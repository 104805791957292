export const PRICE_CURVE_TYPE = {
  STORAGE: "storage",
  LIQUID: "liquid",
  FINISHED_GOODS: "finished_goods",
  MARKETPLACE: "marketplace",
};

export const PRICE_CURVE_TYPES_OPTIONS = [
  { label: 'Storage', value: PRICE_CURVE_TYPE.STORAGE },
  { label: 'Liquid', value: PRICE_CURVE_TYPE.LIQUID },
  { label: 'Finished Goods', value: PRICE_CURVE_TYPE.FINISHED_GOODS },
  { label: 'Marketplace', value: PRICE_CURVE_TYPE.MARKETPLACE },
];

