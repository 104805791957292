import { Divider } from '@mui/material';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestID from '@pw/components/RequestID';
import { Overline } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import ContactSearch from '@pw/components_v2/search/contact';
import { upsertRequestThunk } from '@pw/redux/thunks/request';
import { summarizeInventory } from '@pw/utilities/summarizeInventory';
import { useDispatch } from 'react-redux';

function RequestForm({
  requestType,
  entity,
  changeSet,
  readonly = false,
  edit = false,
  onCancel,
  onBeforeSave,
  onSave,
  noClient = false,
  children
}) {
  const dispatch = useDispatch();
  // const sameCompany = useCheckCompany(entity);

  // const [policyList, init, , upsertPolicy, removePolicy] = useItemListManager(
  //   ID.policy,
  //   COMP.policy,
  // );

  // useEffect(() => {
  //   if (entity?.policies)
  //     init(entity?.policies);
  // }, [entity?.policies]);

  const handleSubmit = async (values) => {
    // if (sameCompany) {
    // 	downloadJson(values?.properties, 'form-data.json');
    // 	returnl
    // }

    const updated = onBeforeSave ? onBeforeSave(values) : values;
    const sourceSummary = summarizeInventory(
      updated?.sources?.assets ?? [],
      updated?.sources?.things ?? []
    );
    const destSummary = summarizeInventory(
      updated?.destinations?.assets ?? [],
      updated?.destinations?.things ?? []
    );

    const request = {
      ...entity,
      ...updated,
      sources: { 
        assets: updated?.sources?.assets?.map((i)=>i),
        things: updated?.sources?.things?.map((i)=>({
          ...i,
          thing: i?.thing?.id ?? i?.thing
        })),
        summary: sourceSummary,
      },
      destinations: { 
        assets: updated?.destinations?.assets?.map((i)=>i),
        things: updated?.destinations?.things?.map((t)=>({
          ...t,
          thing: t?.thing?.id ?? t?.thing
        })),
        summary: destSummary,
      },
      type: requestType,
      // chain,
      // platform,
      // policies: policyList,
    };

    console.log('Submitting', request);
    dispatch(upsertRequestThunk(request))
    .unwrap()
    .then((response) => {
      onSave(response);
    });
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={handleSubmit}
      enableReinitialize
      readonly={readonly}
      edit={edit}
    >
      <FormWrapper>
        <RequestID label="ID." />
        {children}

        {!readonly && !noClient && (
          <>
            <Divider textAlign="center"><Overline>Invoicing</Overline></Divider>
            <ContactSearch field="client" label="Client" />
          </>
        )}

        <FormButtons onCancel={onCancel} readonly={readonly} />
      </FormWrapper>
    </FormikForm>
  );
}

export default RequestForm;
