import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import SvgSearch from '@pw/components/icons/Search';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SearchResults from '@pw/components_v2/search/team/SearchResults';
import { searchTeamThunk } from '@pw/redux/thunks/search';
import debounce from '@pw/utilities/debounce';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

function Search(props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { label, id, onSelected, className = 'field', ...rest } = props;

  const [value, setValue] = useState('');
  const [results, setResults] = useState(null);

  const search = useCallback(() => {
    console.log('Searching for team', value);
    if (value.length > 1) {
      dispatch(searchTeamThunk({ search: value }))
      .unwrap()
      .then((results) => {
        if (!results || !results.items || results.items.length === 0) {
          enqueueSnackbar('Failed to find a matching team!', { variant: 'error' });
          return;
        }
        console.log('Results', results);
        debounce(() => setResults(results), 25);
      })
      .catch((e) => {
        console.log('Search error', e);
        enqueueSnackbar('Failed to find a matching team!', { variant: 'error' });
      });
    }
  }, [value]);

  const clear = useCallback(() => {
    setValue('');
  }, [setValue]);

  const handleSelect = useCallback((selected) => {
    debounce(() => {
      setResults(null);
      setValue('');
      onSelected(selected);
    }, 25);
  }, [setResults, setValue, onSelected]);

  return (
    <>
    <TextField
      label={label}
      id={id}
      variant='filled'
      className={className}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      // onKeyDown={(e) => {
      //   if (e.key === 'Enter') e.preventDefault();
      // }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            {value && (
              <FlexBox>
                <IconCircleButton
                  aria-label='Remove value'
                  onClick={clear}
                  edge='end'
                >
                  <CloseIcon />
                </IconCircleButton>
                <IconCircleButton
                  aria-label='Search'
                  onClick={search}
                  edge='end'
                  color='secondary'
                >
                  <SvgSearch />
                </IconCircleButton>
              </FlexBox>
            )}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
      {!!results && (
        <SearchResults open={!!results} initial={results} onClose={handleSelect} />
      )}
    </>
  );
}

export default Search;
