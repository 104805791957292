import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import LayoutStatsWidget from '@pw/components/Dashboard/components/LayoutStatsWidget';
import {
  FormikDatePicker,
  FormikForm,
  FormikSelect,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import Filter from '@pw/components/icons/Filter';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useReportOverviewLazyQuery } from '@pw/utilities/hooks/service/useReportOverviewQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import LayoutNumberWidget from './LayoutNumberWidget';

function LayoutReportWidget({ title, currentWidth }) {
  const [fetchReportData, { data, isLoading }] = useReportOverviewLazyQuery();

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const converter = useConverter();

  const bl_unit = converter.unit(UNIT.BL);
  const la_unit = converter.unit(UNIT.LA);
  const {
    new_assets_count = 0,
    new_requests_count = 0,
    total_bl = 0,
    total_la = 0,
    total_la_in = 0,
    total_la_out = 0,
    total_bl_in = 0,
    total_bl_out = 0,
    total_bl_storage = 0,
    total_la_storage = 0,
    total_bl_operation = 0,
    total_la_operation = 0,
    start_date,
    end_date,
    report_type,
  } = data ?? {};

  const [tb, ta] = useMemo(() => {
    return [
      converter.from(total_bl ?? 0, UNIT.BL),
      converter.from(total_la ?? 0, UNIT.LA),
    ];
  }, [total_bl, total_la]);

  const [li, lo] = useMemo(() => {
    return [
      converter.from(total_la_in ?? 0, UNIT.LA),
      converter.from(Math.abs(total_la_out ?? 0), UNIT.LA),
    ];
  }, [total_la_in, total_la_out]);

  const [bi, bo] = useMemo(() => {
    return [
      converter.from(total_bl_in ?? 0, UNIT.BL),
      converter.from(Math.abs(total_bl_out ?? 0), UNIT.BL),
    ];
  }, [total_bl_in, total_bl_out]);

  const [tbs, tls] = useMemo(() => {
    return [
      converter.from(total_bl_storage ?? 0, UNIT.BL),
      converter.from(total_la_storage ?? 0, UNIT.LA),
    ];
  }, [total_bl_storage, total_la_storage]);

  const [tbo, tlo] = useMemo(() => {
    return [
      converter.from(total_bl_operation ?? 0, UNIT.BL),
      converter.from(total_la_operation ?? 0, UNIT.LA),
    ];
  }, [total_bl_operation, total_la_operation]);

  const changeSet = useMemo(() => {
    return {
      start_date: [
        start_date,
        yup.number().min(0, 'Invalid date!').required('Date is required!'),
      ],
      end_date: [
        end_date,
        yup.number().min(0, 'Invalid date!').required('Date is required!'),
      ],
      report_type: [report_type, yup.string()],
    };
  }, [start_date, end_date, report_type]);

  const reports = [
    {
      value: 'HMRC',
      label: 'HMRC',
    },
    {
      value: 'TTB',
      label: 'TTB',
    },
  ];

  const onSubmit = useCallback(
    (values) => {
      console.log('values ', values);
      fetchReportData({
        start_date: values?.start_date,
        end_date: values?.end_date,
      });
    },
    [fetchReportData],
  );

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;

  const styles = {
    contentSectionTitle: {
      fontWeight: '600',
      fontSize: '19px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    formContainer: {
      height:(isMediumScreen || currentWidth < 420)  ? '200px' : null,
      backgroundColor: '#C6C6C6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
      borderRadius: '5px',
      padding: '8px',
      flexDirection: (currentWidth < 420 || currentWidth > 600) ? 'column' : 'row',
    },
    formBox1: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '95%',
      backgroundColor: '#fff',
      height:(isMediumScreen || currentWidth < 420)  ? '112px' : '56px',
      // height: '56px',
      borderRadius: '5px',
      flexDirection: (currentWidth < 420 || isMediumScreen) ? 'column' : 'row',
    },
    formBox2: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '95%',
      backgroundColor: '#fff',
      height: '56px',
      borderRadius: '5px',
      // border:"solid red",
    },
    sectionDivider: {
      borderBottom: '1px solid #DDDDDD',
    },
    contentSectionContent: {
      color: '#393C3C',
    },
    contentSectionSummaryTitle: {
      fontSize: '15px',
      fontWeight: '400',
      lineHeight: '22px',
    },
  };
  return (
    <Stack className='content-section'>
      <Stack>
        <FlexBox>
          <H5 className='content-section-title' sx={styles.contentSectionTitle}>
            {title}
          </H5>
          <IconCircleButton onClick={toggleExpanded}>
            <Filter width='1.5em' height='1.5em' />
          </IconCircleButton>
        </FlexBox>
        {isExpanded && (
          <FormikForm changeSet={changeSet} onSubmit={onSubmit}>
            <Stack sx={styles.formContainer}>
              <Box sx={styles.formBox1}>
                <FormikDatePicker
                  name='start_date'
                  label='From'
                  fullWidth
                  required
                />
                <FormikDatePicker
                  name='end_date'
                  label='To'
                  fullWidth
                  required
                />
              </Box>
              <Box sx={styles.formBox2}>
                <FormikSelect
                  name='report_type'
                  label='Report Type'
                  options={reports}
                  fullWidth
                />
              </Box>
            </Stack>
          </FormikForm>
        )}
      </Stack>
      <Stack
        className='content-section-content'
        sx={styles.contentSectionContent}
      >
        <H5 sx={styles.contentSectionSummaryTitle}>Summary</H5>
        <LayoutNumberWidget value={new_assets_count} label='Asset Count' />
        <Box sx={styles.sectionDivider} />
        <LayoutNumberWidget value={new_requests_count} label='Request Count' />

        <H5 sx={styles.contentSectionSummaryTitle}>TOTAL</H5>
        <LayoutStatsWidget
          primary={{ value: tb, unit: bl_unit }}
          secondary={{ value: ta, unit: la_unit }}
          label='Total Liquid'
        />
        <LayoutStatsWidget
          primary={{ value: bi, unit: bl_unit }}
          secondary={{ value: li, unit: la_unit }}
          label='Bulk In'
        />
        <LayoutStatsWidget
          primary={{ value: bo, unit: bl_unit }}
          secondary={{ value: lo, unit: la_unit }}
          label='Bulk Out'
        />
        <LayoutStatsWidget
          primary={{ value: tbs, unit: bl_unit }}
          secondary={{ value: tls, unit: la_unit }}
          label='Storage'
        />
        <LayoutStatsWidget
          primary={{ value: tbo, unit: bl_unit }}
          secondary={{ value: tlo, unit: la_unit }}
          label='Operations'
          border={false}
        />
      </Stack>
      {/* <FlexBox className='content-section-footer'>
        <TitledButton
          handleClick={() => navigate(`/app/duty-submission`)}
          label='Submit'
          // disabled={!isVisibleButton}
        >
          <UploadOutlined height={24} width={24} />
        </TitledButton>
      </FlexBox> */}
    </Stack>
  );
}

export default LayoutReportWidget;
