/**
 * Convert from Thing to ThingItem
 * @param thing
 */
import { INVENTORY_STATUS, THING_TYPES } from '@pw/consts/thing';

const toThingItem = (thing) => {
  // Inbound thing details
  const {
    _id,
    name,
    type,
    description,
    entries = [],
    properties = {},
    vendors = [],
    policies = [],
  } = thing ?? {};
  // Return the SKUItem
  return {
    id: _id,
    name,
    type,
    description,
    entries,
    properties,
    vendors,
    policies,
    amount: entries[0]?.amount,
    // // TODO: this is hack for now for tracked items, eventually, once we generate the ids, then we should mark as done
    processed: [THING_TYPES.TRACKED, THING_TYPES.SERVICES].includes(type)
      ? INVENTORY_STATUS.CONFIRMED
      : INVENTORY_STATUS.PENDING,
  };
};

export default toThingItem;
