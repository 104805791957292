import { CheckCircle } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetTag from '@pw/components/Tasks/AssetTag';
import TeamSelector from '@pw/components_v2/CompanyView/modals/users/TeamSelector';
import { requestAssignThunk } from '@pw/redux/thunks/request';
import { COMP } from '@pw/utilities/comp';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

function AssignModal({ tasks, onClose }) {
  const dispatch = useDispatch();

  const [teams, , , upsertTeams, removeTeams] = useItemListManager({
    comp: COMP.target,
  });

  const assignTasks = useCallback(() => {
    if (!teams || teams.length === 0) return;
    const requests = tasks.map((t) => t._id);
    const assignees = teams
      .map((t) => t?.roles?.map((r) => r?.users.map((u) => u?._id) ?? []))
      .flat(3);
    dispatch(requestAssignThunk({ requests, assignees }))
      .unwrap()
      .then(() => onClose(true, true));
  }, [tasks, teams]);

  return (
    <ModalWithClose title='Assign Tasks' open onClose={onClose}>
      <FlexBox className='asset-container' justifyContent='start' spacing={1}>
        {tasks.map((task) => (
          <AssetTag
            key={task._id}
            name={task?.name}
            action={task.action}
            type={task.type}
          />
        ))}
      </FlexBox>
      <Stack className='assignees' spacing={1}>
        <TeamSelector
          title='Assignees'
          teams={teams}
          upsert={upsertTeams}
          remove={removeTeams}
          readonly={false}
        />
      </Stack>
      <FlexBox justifyContent='end'>
        <TextButton
          size='small'
          handleClick={() => onClose(false, true)}
          color='secondary'
          label='Cancel'
        />
        <FilledButton
          size='small'
          icon={<CheckCircle />}
          iconPosition='start'
          handleClick={assignTasks}
        >
          Confirm
        </FilledButton>
      </FlexBox>
    </ModalWithClose>
  );
}

export default AssignModal;
