
import { useMemo } from 'react';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ProductionDesigner from '@pw/components/ProductionDesigner';
import DesignerProvider from '@pw/providers/DesignerProvider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useProductionDesignerQuery from '@pw/utilities/hooks/logic/useNavigateProductionDesigner';

import {
	materials,
	productionObservations,
	operations,
	machines,
} from '@pw/components/ProductionDesigner/nodes';

export default withAppLayout(() => {
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const thing_id = params.get('thing_id');
  const batch_id = params.get('batch_id');

	const mode = useMemo(() => batch_id ? 'schedule' : 'design', [batch_id]);
	// const mode = batch_id ? 'schedule' :'design';
	console.log('mode', { mode, thing_id, batch_id });

	// const nodeGroups = useMemo(() => ({
	// 	materials, observations, operations, processes: machines,
	// }), [materials, observations, operations, machines]);

	const link = batch_id
		? useProductionDesignerQuery(thing_id)
		: useProductionDesignerQuery(thing_id, 'label');
	console.log('production-designer-page', { link });

	// // removes any "free-floating" nodes caused by removing an edge
	// const deleteOrphanedNodes = ({ changes, setNodes, edges, setEdges, applyEdgeChanges }) => {
	// 	console.log('deleteOrphanedNodes')
	// 	// handler for removals
	// 	const removals = changes.filter((c) => c.type === 'remove');
	// 	removals.forEach(({ id: removeId }) => {
	// 		// get the nodes this edge connects
	// 		const { id: edgeId, source: nodeSource, target: nodeTarget } = edges.find(
	// 			({ id }) => id === removeId
	// 		);
	//
	// 		// see if any other edges connect to these nodes
	// 		const sourceHanging = edges.filter(
	// 			({ id, source, target }) => id !== edgeId && (
	// 				(source === nodeSource || target === nodeSource)
	// 			)).length === 0;
	// 		const targetHanging = edges.filter(
	// 			({ id, source, target }) => id !== edgeId && (
	// 				(source === nodeTarget || target === nodeTarget)
	// 			)).length === 0;
	//
	// 		if (sourceHanging || targetHanging) {
	// 			// remove the free-floating nodes
	// 			setNodes((prev) => prev.filter(({ id }) => !(
	// 				(sourceHanging && id === nodeSource)
	// 				|| (targetHanging && id === nodeTarget)
	// 			)));
	// 		}
	// 	});
	// 	// default handler
	// 	setEdges((eds) => applyEdgeChanges(changes, eds));
	// }

	return (
		<>
			<DesignerProvider mode={mode} thingId={thing_id} batchId={batch_id}>
				{/*<ProductionDesigner*/}
				{/*	nodeGroups={nodeGroups}*/}
				{/*	onEdgesChange={deleteOrphanedNodes}*/}
				{/*/>*/}

			</DesignerProvider>
		</>
	);

}, { title: 'Production Design' });
