import { Add } from '@mui/icons-material';
import { Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DefectItem from '@pw/components/Defect/DefectItem';
import DefectModal from '@pw/components/Defect/DefectModal';
import DefectAssetModal from '@pw/components/InventorySelector/DefectAssetModal';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function ReportDefectForm() {
  const [defect, setDefect] = useState(null);
  const [defects, setDefects] = useState([]);
  let [searchParams] = useSearchParams();
  const asset = searchParams.get('asset');
  const [fetchAsset] = useAssetLazyQuery();

  const [
    ,
    [sourceAssets, initSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Assets',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
        ASSET_TYPES.FILLING_TANK,
      ],
    },
    assetFilter: (asset) =>
      (asset.child_assets && asset.child_assets.length > 0) ||
      (asset.things && asset.things.length > 0),
    AssetModal: DefectAssetModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => {
      return {
        ...requestIdFields(initialValues),
      };
    },
    [],
  );

  const upsertDefect = useCallback(
    (entry, index) => {
      console.log('Upsert', entry, index, defects);
      const v = [...defects];
      if (index !== null && index !== undefined) {
        // updating
        v[index] = entry;
      } else {
        v.push(entry);
      }
      // v.sort((l, r) => l[0].localeCompare(r[0]));
      debounce(() => setDefects(v), 50);
    },
    [defects],
  );

  const removeDefect = useCallback(
    (index) => {
      const v = [...defects];
      if (index !== null && index !== undefined) {
        // removing
        v.splice(index, 1);
      }
      debounce(() => setDefects(v), 50);
    },
    [defects],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      setDefects(entity?.properties?.defects ?? []);
    },
    [initSources],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        assets: sourceAssets,
      },
      properties: { defects },
    }),
    [defects, sourceAssets],
  );

  useEffect(() => {
    if (asset) {
      fetchAsset({id: asset}).then((a) => {
        debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
      });
    }
  }, [asset, initSourceAssets, fetchAsset]);

  return (
    <>
      <RequestForm
        requestLabel='Defect Report'
        requestType={REQUEST_TYPES.REPORT_DEFECT}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <FlexBox>
          <H5>Defects</H5>
          <Stack direction='row' justifyContent='flex-end'>
            <IconCircleButton onClick={() => setDefect([])}>
              <Add />
            </IconCircleButton>
          </Stack>
        </FlexBox>
        <Stack spacing={0} className='list'>
          {defects.map((d, i) => (
            <DefectItem
              index={i}
              item={d}
              remove={() => removeDefect(i)}
              edit={() => setDefect([d, i])}
              key={`${i}`}
            />
          ))}
        </Stack>
      </RequestForm>
      <DefectModal
        open={!!defect}
        item={defect}
        upsert={upsertDefect}
        onClose={() => setDefect(null)}
      />
      <SourceModals />
    </>
  );
}

export default withAppLayout(ReportDefectForm, { title: 'Report Defect' });
