import { Box } from "@mui/material";
import { Body1 } from "@pw/components/Typography";

const LayoutAssetByTypeCountWidget = () => {
  return (
    <Box className="widget" sx={{display:"flex", alignItems:"center", justifyContent:"center", marginTop:"50px"}}>
      <Body1 sx={{color:"#000"}}>Figma design is not ready</Body1>
    </Box>
  );
};

export default LayoutAssetByTypeCountWidget;
