import AuthGuard from '@pw/components/Auth/AuthGuard';
import { COMPANY_FEATURES, COMPANY_TYPE } from '@pw/consts/company';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import Custom403 from '@pw/pages/403';
import Custom404 from '@pw/pages/404';
import Custom426 from '@pw/pages/426';

import AccountSettingsPage from '@pw/pages/account/AccountSettings';
import CompaniesPage from '@pw/pages/account/companies';
import NABCALabelPage from '@pw/pages/admin/labels/nabca';
import StandardLabelPage from '@pw/pages/admin/labels/standard';
import SensorsPage from '@pw/pages/admin/sensors';

import ThingCons from '@pw/pages/admin/things/cons';
import ThingExp from '@pw/pages/admin/things/exp';
import ThingExpenses from '@pw/pages/admin/things/expenses';
import ThingFinished from '@pw/pages/admin/things/finished';
import ThingLiquid from '@pw/pages/admin/things/liquid';
import ThingPart from '@pw/pages/admin/things/parts';
import ThingRaw from '@pw/pages/admin/things/raw';
import ThingServices from '@pw/pages/admin/things/services';
import ThingTracked from '@pw/pages/admin/things/tracked';
import ThingWaste from '@pw/pages/admin/things/waste';
// import TeamsPage from '@pw/pages/admin/Teams';
// import UsersPage from '@pw/pages/admin/Users';
import AssetTablePage from '@pw/pages/app/asset-table';
import CaskPage from '@pw/pages/app/cask';
import ChangeOwnershipPage from '@pw/pages/app/change-ownership';
import ContainerPage from '@pw/pages/app/container';
import DeliveryPage from '@pw/pages/app/delivery';
import DispatchPage from '@pw/pages/app/dispatch';
import DutySubmissionPage from '@pw/pages/app/duty-submission';
import DutyTransferPage from '@pw/pages/app/duty-transfer';
import ExpenseReport from '@pw/pages/app/expense-report';
import ExplorerPage from '@pw/pages/app/explorer';
import IbcPage from '@pw/pages/app/ibc';
import InventoryAuditPage from '@pw/pages/app/inventory-audit';
import InvoicePage from '@pw/pages/app/invoice';
import InvoicePaymentPage from '@pw/pages/app/invoice-payment';
import LocationsPage from '@pw/pages/app/locations';
import MachinePage from '@pw/pages/app/machine';
import MaintenancePage from '@pw/pages/app/maintenance';
import OwnershipAudit from '@pw/pages/app/ownership-audit';
import PackPage from '@pw/pages/app/pack';
import PalletPage from '@pw/pages/app/pallet';
import PickPage from '@pw/pages/app/pick';
import ProcessDesigner from '@pw/pages/app/process-designer';
import ProductionDesigner from '@pw/pages/app/production-designer';

import ProductionRunPage from '@pw/pages/app/production-run';
import ProductionRunDetail from '@pw/pages/app/production-run-detail';
import ProductionSchedule from '@pw/pages/app/production-schedule';
import PurchaseOrderPage from '@pw/pages/app/purchase-order';
import PurchaseReturnPage from '@pw/pages/app/purchase-return';
import ReguagePage from '@pw/pages/app/reguage';
import ReportDefectPage from '@pw/pages/app/report-defect';
import RequestScheduler from '@pw/pages/app/request-scheduler';
import RestockPage from '@pw/pages/app/restock';
import SalesOrderPage from '@pw/pages/app/sales-order';
import SalesReturnPage from '@pw/pages/app/sales-return';
import SamplePage from '@pw/pages/app/sample';
import SearchPage from '@pw/pages/app/search';
import ShipPage from '@pw/pages/app/ship';
import SyncPage from '@pw/pages/app/sync';
import FillingTankPage from '@pw/pages/app/tank';
import TankerPage from '@pw/pages/app/tanker';
import TasksPage from '@pw/pages/app/tasks';
import TransferPage from '@pw/pages/app/transfer';
// import OwnerPage from '@pw/pages/crm/Owners';
// import PartnersPage from '@pw/pages/crm/Partners';
import ContactPage from '@pw/pages/crm/Contacts';
import DashBoard from '@pw/pages/Dashboard';
import LogoutPage from '@pw/pages/Logout';
import QRLocator from '@pw/pages/QRLocator';
import Recover from '@pw/pages/Recover';
import Register from '@pw/pages/Register';
import SignIn from '@pw/pages/SignIn';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';

function RDRedirect({ base }) {
  let { address } = useParams();
  return <Navigate to={`${base}/${address}`} replace />;
}

function AppRoutes() {
  const hasPermission = usePermissions();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to='/app/overview' replace />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/register' element={<Register />} />
          <Route path='/recover' element={<Recover />} />
          <Route path='/s' element={<QRLocator />} />

          {/**next config re routes start*/}
          <Route
            path='/pallet'
            element={<Navigate to='/app/pallet' replace />}
          />
          <Route
            path='/logout'
            element={
              <AuthGuard>
                <LogoutPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/cask/edit/:platform/:chain/:address'
            element={<RDRedirect base='/app/cask' />}
          />
          <Route
            path='/app/cask//rd/:platform/:chain/:address'
            element={<RDRedirect base='/app/cask' />}
          />
          <Route
            path='/app/pallet//rd/:platform/:chain/:address'
            element={<RDRedirect base='/app/pallet' />}
          />
          <Route
            path='/app/pallet/rd/:platform/:chain/:address'
            element={<RDRedirect base='/app/pallet' />}
          />
          {/** redirects */}
          <Route
            path='/app/pallet/:platform/:chain/:address'
            element={<RDRedirect base='/app/pallet' />}
          />
          <Route
            path='/app/cask/:platform/:chain/:address'
            element={<RDRedirect base='/app/cask' />}
          />
          <Route
            path='/app/ibc/:platform/:chain/:address'
            element={<RDRedirect base='/app/ibc' />}
          />
          <Route
            path='/app/container/:platform/:chain/:address'
            element={<RDRedirect base='/app/container' />}
          />
          <Route
            path='/app/tank/:platform/:chain/:address'
            element={<RDRedirect base='/app/tank' />}
          />
          <Route
            path='/app/machine/:platform/:chain/:address'
            element={<RDRedirect base='/app/machine' />}
          />
          <Route
            path='/app/tanker/:platform/:chain/:address'
            element={<RDRedirect base='/app/tanker' />}
          />
          {/**next config re routes end*/}
          <Route
            path='/app/search'
            element={
              <AuthGuard>
                <SearchPage />
              </AuthGuard>
            }
          />

          <Route
            path='/app/overview'
            element={
              <AuthGuard>
                <DashBoard />
              </AuthGuard>
            }
          />
          <Route
            path='/app/sync'
            element={
              <AuthGuard features={COMPANY_FEATURES.ALL}>
                <SyncPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/explorer'
            element={
              <AuthGuard>
                <ExplorerPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/tasks'
            element={
              <AuthGuard features={COMPANY_FEATURES.ALL}>
                <TasksPage />
              </AuthGuard>
            }
          />

          <Route
            path='/app/locations'
            element={
              <AuthGuard>
                <LocationsPage />
              </AuthGuard>
            }
          />

          {/*<Route*/}
          {/*	path='/app/reports/instant/:type/:nonce'*/}
          {/*	element={*/}
          {/*		<AuthGuard>*/}
          {/*			<ReportsInstantPage />*/}
          {/*		</AuthGuard>*/}
          {/*	}*/}
          {/*/>*/}
          {/*<Route*/}
          {/*	path='/app/reports/monthly/:type/:nonce'*/}
          {/*	element={*/}
          {/*		<AuthGuard>*/}
          {/*			<ReportsMonthlyPage />*/}
          {/*		</AuthGuard>*/}
          {/*	}*/}
          {/*/>*/}

          <Route
            path='/app/pallet/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PALLET,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <PalletPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/cask/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CASK,
                  Object.values(PermissionType),
                )}
              >
                <CaskPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/container/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CONTAINER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <ContainerPage />
              </AuthGuard>
            }
          />

          <Route
            path='/app/ibc/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.IBC,
                  Object.values(PermissionType),
                )}
              >
                <IbcPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/tank/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.FILLING_TANK,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <FillingTankPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/machine/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.MACHINE,
                  Object.values(PermissionType),
                )}
                features={[
                  COMPANY_FEATURES.BOTTLING,
                  COMPANY_FEATURES.PRODUCTION,
                ]}
              >
                <MachinePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/tanker/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.TANKER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <TankerPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/transfer/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.TRANSFER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <TransferPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/ownership-audit/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.OWNERSHIP_AUDIT,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <OwnershipAudit />
              </AuthGuard>
            }
          />
          <Route
            path='/app/maintenance/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.MAINTENANCE,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.ALL]}
              >
                <MaintenancePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/pack/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PACK,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.BOTTLING]}
              >
                <PackPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/pick/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PICK,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <PickPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/duty-transfer/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.DUTY_TRANSFER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <DutyTransferPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/duty-submission/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.DUTY_SUBMISSION,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <DutySubmissionPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/purchase-order/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PURCHASE_ORDER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <PurchaseOrderPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/sales-order/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.SALES_ORDER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.ALL]}
              >
                <SalesOrderPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/ship/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.SHIP,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <ShipPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/dispatch/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.DISPATCH,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <DispatchPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/sample/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.SAMPLE,
                  Object.values(PermissionType),
                )}
              >
                <SamplePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/report-defect/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.REPORT_DEFECT,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <ReportDefectPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/regauge/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.REGAUGE,
                  Object.values(PermissionType),
                )}
              >
                <ReguagePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/restock/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.RESTOCK,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <RestockPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/change-ownership/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CHANGE_OWNERSHIP,
                  Object.values(PermissionType),
                )}
                types={[COMPANY_TYPE.BROKER]}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <ChangeOwnershipPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/inventory-audit/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.INVENTORY_AUDIT,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <InventoryAuditPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/delivery/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.DELIVERY,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.WAREHOUSING]}
              >
                <DeliveryPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/production-run/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PRODUCTION_ORDER,
                  Object.values(PermissionType),
                )}
                features={[COMPANY_FEATURES.PRODUCTION]}
              >
                <ProductionRunPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/invoice/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.INVOICE,
                  Object.values(PermissionType),
                )}
              >
                <InvoicePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/invoice-payment/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.INVOICE_PAYMENT,
                  Object.values(PermissionType),
                )}
              >
                <InvoicePaymentPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/sales-return/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.SALES_RETURN,
                  Object.values(PermissionType),
                )}
              >
                <SalesReturnPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/purchase-return/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.PURCHASE_RETURN,
                  Object.values(PermissionType),
                )}
              >
                <PurchaseReturnPage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/expense-report/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.EXPENSE_REPORT,
                  Object.values(PermissionType),
                )}
              >
                <ExpenseReport />
              </AuthGuard>
            }
          />

          <Route
            path='/app/asset-table'
            element={
              <AuthGuard>
                <AssetTablePage />
              </AuthGuard>
            }
          />
          <Route
            path='/app/production-run-detail'
            element={
              <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
                <ProductionRunDetail />
              </AuthGuard>
            }
          />

          <Route
            path='/app/production-designer/:thing_id/batch/:batch_id'
            element={
              // <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
              <ProductionDesigner />
              // </AuthGuard>
            }
          />

          <Route
            path='/app/production-designer'
            element={
              <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
                <ProductionDesigner />
              </AuthGuard>
            }
          />

          <Route
            path='/app/process-designer'
            element={
              <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
                <ProcessDesigner />
              </AuthGuard>
            }
          />
          <Route
            path='/app/production-schedule'
            element={
              <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
                <ProductionSchedule />
              </AuthGuard>
            }
          />

          <Route
            path='/app/request-schedule'
            element={
              <AuthGuard features={[COMPANY_FEATURES.PRODUCTION]}>
                <RequestScheduler />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/labels/nabca'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
              >
                <NABCALabelPage />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/labels/standard'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
              >
                <StandardLabelPage />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/cons/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingCons />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/exp/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingExp />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/finished/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingFinished />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/liquid/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingLiquid />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/parts/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingPart />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/raw/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingRaw />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/services/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingServices />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/expenses/:id?'
            element={
              // <AuthGuard
              // 	permissions={[PERMISSIONS.THING_ADMIN]}
              // 	features={COMPANY_FEATURES.ALL}
              // >
              <ThingExpenses />
              // </AuthGuard>
            }
          />
          <Route
            path='/admin/things/tracked/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingTracked />
              </AuthGuard>
            }
          />
          <Route
            path='/admin/things/waste/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.THING,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <ThingWaste />
              </AuthGuard>
            }
          />
          {/* <Route
            path='/admin/users'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.USER,
                  Object.values(PermissionType),
                )}
              >
                <UsersPage />
              </AuthGuard>
            }
          /> */}
          {/* <Route
            path='/admin/roles'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.USER,
                  Object.values(PermissionType),
                )}
              >
                <RolesPage />
              </AuthGuard>
            }
          /> */}
          {/* <Route
            path='/admin/teams'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.USER,
                  Object.values(PermissionType),
                )}
              >
                <TeamsPage />
              </AuthGuard>
            }
          /> */}
          <Route
            path='/admin/sensors'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.SENSOR,
                  Object.values(PermissionType),
                )}
                features={COMPANY_FEATURES.ALL}
              >
                <SensorsPage />
              </AuthGuard>
            }
          />
          <Route
            path='/account/settings'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CRM,
                  Object.values(PermissionType),
                )}
              >
                <AccountSettingsPage />
              </AuthGuard>
            }
          />
          <Route
            path='/account/companies/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CRM,
                  Object.values(PermissionType),
                )}
              >
                <CompaniesPage />
              </AuthGuard>
            }
          />
          <Route
            path='/crm/contacts/:id?'
            element={
              <AuthGuard
                hasPermission={hasPermission(
                  PermissionKey.CRM,
                  Object.values(PermissionType),
                )}
              >
                <ContactPage />
              </AuthGuard>
            }
          />

          <Route path='/not-found' element={<Custom404 />} />
          <Route path='/not-online' element={<Custom403 />} />
          <Route path='/upgrade' element={<Custom426 />} />
          <Route path='*' element={<Custom404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default AppRoutes;
