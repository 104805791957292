import { useCallback, useEffect, useState } from 'react';

const usePaginated = ([lazyFetch, { isLoading }], params) => {
  // const [fetchCustomers,{isLoading}] = useCustomersLazyQuery();
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const load = useCallback(
    async (p) => {
      try {
        const res = await lazyFetch(p);
        setPages((v) => [...v, res]);
      } catch (e) {
        setPages([]);
        setCurrentPage(0);
      }
    },
    [currentPage, lazyFetch],
  );

  useEffect(() => {
    setPages([]);
    setCurrentPage(0);
    console.log('Loading...', params);
    load({ ...params, page: 0 }).finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const prev = useCallback(() => {
    if (currentPage) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const next = useCallback(async () => {
    if (currentPage + 1 < pages.length) {
      setCurrentPage(currentPage + 1);
    } else {
      if (pages.length > 0) {
        const cp = pages[pages.length - 1]?.page ?? 0;
        if (cp >= 0) {
          await load({ ...params, page: currentPage + 1 }).finally();
          setCurrentPage(currentPage + 1);
        } else {
          // If we're loading from local...
          if (pages[pages.length - 1]?.localPage) {
            await load({ ...params, page: currentPage + 1 }).finally();
            setCurrentPage(currentPage + 1);
          }
        }
      }
    }
  }, [currentPage, pages, load, params]);

  const refetchCurrentPage = useCallback(async () => {
    if (pages.length > 0) {
      const res = await lazyFetch({ ...params, page: currentPage });
      // replace current page with res
      setPages((v) => [
        ...v.slice(0, currentPage),
        res,
        // ...v.slice(currentPage + 1),// lets cut from there
      ]);
    }
  }, [pages, currentPage, lazyFetch, params]);

  return [
    isLoading,
    pages[currentPage] && pages[currentPage].items
      ? pages[currentPage].items
      : [],
    currentPage > 0,
    prev,
    currentPage + 1 < pages.length ||
      (pages.length > 0 &&
        (pages[pages.length - 1]?.localPage ||
          pages[pages.length - 1]?.items?.length >= params?.limit)),
    next,
    refetchCurrentPage,
  ];
};

export default usePaginated;
