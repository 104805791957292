import ColumnStill from '@pw/components_v2/ThingView/modals/recipe/modals/ColumnStill';
import ContinuousMill from '@pw/components_v2/ThingView/modals/recipe/modals/ContinuousMill';
import HammerMill from '@pw/components_v2/ThingView/modals/recipe/modals/HammerMill';
import KilnOven from '@pw/components_v2/ThingView/modals/recipe/modals/KilnOven';
import LSSeparator from '@pw/components_v2/ThingView/modals/recipe/modals/LSSeparator';
import Mash from '@pw/components_v2/ThingView/modals/recipe/modals/Mash';
import MashCooler from '@pw/components_v2/ThingView/modals/recipe/modals/MashCooler';
import Material from '@pw/components_v2/ThingView/modals/recipe/modals/Material';
import Observation from '@pw/components_v2/ThingView/modals/recipe/modals/Observation';
import Policy from '@pw/components_v2/ThingView/modals/recipe/modals/Policy';
import PotStill from '@pw/components_v2/ThingView/modals/recipe/modals/PotStill';
import Steep from '@pw/components_v2/ThingView/modals/recipe/modals/Steep';
import Washback from '@pw/components_v2/ThingView/modals/recipe/modals/Washback';
import WashStill from '@pw/components_v2/ThingView/modals/recipe/modals/WashStill';
import { MACHINE_TYPES } from '@pw/consts/asset';
import { DESIGN_NODE_TYPE } from '@pw/consts/designs';
import DesignerContext from '@pw/context/DesignerContext';
import { useContext } from 'react';

function ModalSwitch() {
  // Grab the selected node
  const {
    selectedNode,
  } = useContext(DesignerContext);

  if (!selectedNode) return null;

  console.log('Selected node', selectedNode);

  const { category, type } = selectedNode?.data ?? {};

  if (category === DESIGN_NODE_TYPE.MACHINE) {
    switch (type) {
      // Machines
      case MACHINE_TYPES.steep:
        return (<Steep />);
      case MACHINE_TYPES.kiln_oven:
        return (<KilnOven />);
      case MACHINE_TYPES.hammer_mill:
        return (<HammerMill />);
      case MACHINE_TYPES.continuous_mill:
        return (<ContinuousMill />);
      case MACHINE_TYPES.mash:
        return (<Mash />);
      case MACHINE_TYPES.ls_separator:
        return (<LSSeparator />);
      case MACHINE_TYPES.mash_cooler:
        return (<MashCooler />);
      case MACHINE_TYPES.washback:
        return (<Washback />);
      case MACHINE_TYPES.wash_still:
        return (<WashStill />);
      case MACHINE_TYPES.pot_still:
        return (<PotStill />);
      case MACHINE_TYPES.column_still:
        return (<ColumnStill />);
      default:
        return null;
    }
  }

  if (category === DESIGN_NODE_TYPE.OBSERVATION) {
    // Single form to capture the observation settings...
    if (type === 'policy') {
      return (<Policy />);
    } else {
      return (<Observation />);
    }
  }

  if (category === DESIGN_NODE_TYPE.MATERIAL) {
    // Single form to capture the material
    return (<Material />);
  }
}

export default ModalSwitch;
