function SvgPurchaseOrder(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M.001 4.932h4.38c.02-.427.014-.84.064-1.245C4.665 1.905 6.25.537 8.072.538c1.822.001 3.398 1.37 3.618 3.159.049.4.043.805.064 1.24h4.366v2.95h-1.449V6.418H11.74v1.436h-1.462V6.423H5.873v1.435H4.411v-1.44H1.468v13.06c0 .977.609 1.59 1.582 1.59 1.97.001 3.942.002 5.912-.002.15 0 .245.05.351.156.44.443.897.87 1.346 1.3-.017.001-.07.012-.122.012-2.544.001-5.087.008-7.63-.002a2.913 2.913 0 01-2.903-2.907C-.003 14.79 0 9.956 0 5.12v-.189zm10.263-.003c0-.292.009-.56 0-.825-.059-1.529-1.684-2.548-3.074-1.91C6.04 2.72 5.746 3.73 5.897 4.929h4.368z'
        fill='currentColor'
      />
      <path
        d='M15.39 22.536C11.75 22.53 8.792 19.559 8.8 15.92c.01-3.638 2.984-6.595 6.622-6.582 3.63.013 6.58 2.973 6.577 6.603-.002 3.64-2.971 6.601-6.61 6.595zm.005-11.732a5.142 5.142 0 00-5.127 5.133 5.142 5.142 0 005.127 5.132 5.142 5.142 0 005.139-5.145 5.141 5.141 0 00-5.14-5.12z'
        fill='currentColor'
      />
      <path
        d='M17.573 13.469l1.007 1.004-3.898 3.896-2.43-2.43.974-.974 1.42 1.433 2.927-2.93z'
        fill='currentColor'
      />
    </svg>
  );
}
export default SvgPurchaseOrder;
