import Box from '@mui/material/Box';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';

function FormButtons({ readonly, onCancel }) {
  return (
    <Box className="action-buttons">
      {onCancel && (
        <TextButton
          size="small"
          handleClick={() => onCancel()}
          color="secondary"
        >
          Cancel
        </TextButton>
      )}
      <FilledButton type="submit" size="small" disabled={readonly}>
        Save
      </FilledButton>
    </Box>
  )
}

export default FormButtons;
