import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TEMPERATURE_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      target_temperature: unitField(initialValues?.target_temperature),
      target_duration: unitField(initialValues?.target_duration),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <FlexBox>
        <FormikUnitField name="target_temperature" label="Target Temperature" options={TEMPERATURE_UNIT_OPTIONS} fullWidth />
        <FormikUnitField name="target_duration" label="Target Duration" options={TIME_UNIT_OPTIONS} fullWidth />
      </FlexBox>
    </ModalWrapper>
  );
}

export default NodeModal;
