import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import RequestTypeSelector from '@pw/components/RequestTypeSelector';
import { H5 } from '@pw/components/Typography';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function PolicyModal({ item, open, onClose }) {
	const { enqueueSnackbar } = useSnackbar();

    const { requests, policy } = item

    const changeSet = useMemo(
        () => ({
            requests: [requests ?? [], yup.array().required('Requests are required!').min(1, 'Select at least one request type for the policy!')],
        }),
        [requests],
	);

	const handleSubmit = (values) => {
		try {
			onClose({ policy: policy ?? item, ...values });
		} catch (err) {
			enqueueSnackbar(err.message, { variant: 'error' });
		}
	};

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={name}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing="1.5rem" sx={{ paddingTop: '1rem' }}>
                    <Stack spacing="1.5rem">
						<H5>Request Types</H5>
						<RequestTypeSelector />
					</Stack>

                    <Errors />

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default PolicyModal;
