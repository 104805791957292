import { CheckBoxRounded, TaskOutlined } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import { ASSET_NAMES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { useCallback, useMemo } from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';

function ThingStorageItem({
  item,
  unit,
  selected = false,
  onSelect = null,
  onSelectStorage,
}) {
  const {
    name,
    type,
    amount,
    available_quantity,
    duty_paid,
    reserved_quantity,
    entry_quantity,
    processed = ASSET_PROCESSED_STATUS.PENDING,
  } = item;

  const onClick = useCallback(() => {
    if (onSelect) {
      onSelect(!selected);
    }
  }, [selected, onSelect]);

  const cardStyle = useMemo(() => {
    if (onSelect) {
      return '';
    }
    switch (processed) {
      case ASSET_PROCESSED_STATUS.PENDING:
        return 'card-tab-pending';
      case ASSET_PROCESSED_STATUS.CONFIRMED:
        return 'card-tab-confirmed';
      default:
        return '';
    }
  }, [processed, onSelect]);

  // console.log('SKU Storage item', item);

  return (
    <Box className='card' action={ASSET_NAMES[type]} onClick={onClick}>
      <Box className={`card-tab ${cardStyle}`}>
        {selected && <CheckBoxRounded className='check' />}
        {processed && processed === ASSET_PROCESSED_STATUS.CONFIRMED && (
          <TaskOutlined className='check' />
        )}
      </Box>
      <Stack
        className={`card-content ${duty_paid ? 'card-highlight' : ''}`}
        spacing={0.25}
      >
        <IDDisplay value={name} />
        <DutyPaidDisplay value={duty_paid} />
        <Divider variant='middle' sx={{ opacity: 0.6 }} />
        <AmountDisplay label='Amount' amount={amount} unit={unit} />
        <FlexBox>
          <AmountDisplay label='Original' amount={entry_quantity} unit={unit} />
          <AmountDisplay
            label='Available'
            amount={available_quantity}
            unit={unit}
          />
          <AmountDisplay
            label='Reserved'
            amount={reserved_quantity}
            unit={unit}
          />
          {!!onSelectStorage &&
            Number(entry_quantity) !== Number(available_quantity) && (
              <IconCircleButton onClick={() => onSelectStorage(item)}>
                <ArrowForward />
              </IconCircleButton>
            )}
        </FlexBox>
      </Stack>
    </Box>
  );
}

export default ThingStorageItem;
