import { Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgDelete from '@pw/components/icons/Delete';
import { Body1 } from '@pw/components/Typography';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import Search from '@pw/components_v2/search/general';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect } from 'react';

function Policy({ item }) {
  const { name } = item;
  return (
    <Stack>
      <EntryTitle value={name} />
    </Stack>
  );
}

const search = {
  [SEARCH_TYPES.POLICIES]: {
  }
};

function PolicySelector({ field = 'policies', initialValues, readonly }) {
  const { setFieldValue } = useContext(FormikContext);
  const confirm = useConfirm();

  const [policies, initPolicies, , upsertPolicies, removePolicies] = useItemListManager({
    id: ID._id,
    comp: COMP._id,
    initialData: [],
  });

  useEffect(() => {
    if (initialValues) {
      initPolicies(initialValues);
    }
  }, [initialValues, initPolicies]);

  useEffect(() => {
    debounce(() => setFieldValue(field, policies), 25);
  }, [policies]);


  const handleRemove = useCallback((removedPolicy) => {
    if (removedPolicy) {
      confirm({
        title: 'Remove Policy',
        content: <Body1>{`Remove '${removedPolicy?.name}'?`}</Body1>,
      })
      .then(() => removePolicies(removedPolicy))
      .catch(() => {
      });
    }
  }, [removePolicies]);

  const Toolbar = ({ onClose }) => {
    const handleClear = useCallback(() => {
      onClose();
      confirm({
        title: 'Clear Policies',
        content: <Body1>Clear all?</Body1>,
      })
      .then(() => debounce(() => initPolicies([]), 25))
      .catch(() => {
      });
    }, [initPolicies]);

    const handleSelect = useCallback((value) => {
      if (value && value.length > 0) {
        onClose();
        debounce(() => {
          upsertPolicies(value);
        }, 25);
      }
    }, [setFieldValue]);

    return (
      <>
        <Search multi={false} types={search} onSelected={handleSelect} />
        {policies && policies.length > 0 && (
          <TitledButton handleClick={handleClear} label="Clear">
            <SvgDelete height={24} width={24} />
          </TitledButton>
        )}
      </>
    )
  }

  const tools = [
    {
      title: 'Remove policy',
      Icon: SvgDelete,
      handler: handleRemove
    }
  ];

  return (
    <ToolbarSection title="Policies" Toolbar={Toolbar}>
      <BasicList Content={Policy} items={policies} tools={tools} identity={(i) => i.name} />
    </ToolbarSection>
  );
}

export default PolicySelector;
