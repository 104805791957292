import { Refresh } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetSummaryList from '@pw/components/SKUSelector/items/AssetSummaryList';
import AssetModal from '@pw/components/SKUSelector/modals/dest/AssetModal';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import { useCompanyThings } from '@pw/redux/containers/User';
import { COMP, ID } from '@pw/utilities/comp';
import {
  useAIDateConverter,
  useAIThingMapper,
} from '@pw/utilities/hooks/ai/useAiConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

function AssetUploadProcessing({
  data,
  model,
  mappings,
  assetType,
  id,
  prefix,
  onCancel,
  onConfirm,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const allThings = useCompanyThings();

  const { mapDates } = useAIDateConverter();
  const { mapSKUs } = useAIThingMapper();

  console.log('Model', model);
  console.log('Mappings', mappings);

  const [processing, setProcessing] = useState(false);
  const [editAsset, setEditAsset] = useState(null);

  const assetSkus = useMemo(
    () =>
      allThings
        .filter(
          (s) =>
            s.sku_type === THING_TYPES.TRACKED &&
            s.properties?.asset_type === assetType,
        )
        .map((s) => ({
          sku_id: s.sku_id,
          sku_type: s.sku_type,
          sku_name: s.sku_name,
          sku_description: s.sku_description,
          sku_tags: s.sku_tags,
        })),
    [allThings, assetType],
  );

  const liquidSkus = useMemo(
    () =>
      allThings
        .filter((s) => s.sku_type === THING_TYPES.LIQUID)
        .map((s) => ({
          sku_id: s.sku_id,
          sku_type: s.sku_type,
          sku_name: s.sku_name,
          sku_description: s.sku_description,
          sku_tags: s.sku_tags,
        })),
    [allThings],
  );

  // List of processed entries..
  const [assets, , , upsertAsset, removeAsset] = useItemListManager();

  const [assetSkuMappings, , , upsertAssetSkuMapping, ,] = useItemListManager();

  const [liquidSkuMappings, , , liquidAssetSkuMapping, ,] =
    useItemListManager();

  const handleEditAsset = (a) => {
    setEditAsset(a);
  };

  const handleEditClose = useCallback(
    (a) => {
      if (a) {
        upsertAsset(a);
      }
      setEditAsset(null);
    },
    [upsertAsset],
  );

  const [rows, dates, caskTypes, liquidTypes, parentAssets] = useMemo(() => {
    const rowData = data?.data?.data;

    const dates = new Set();
    const caskTypes = new Set();
    const liquidTypes = new Set();
    const parentAssets = new Set();

    const dateField = mappings['fill_date'];
    const caskTypeField = mappings['cask_type'];
    const liquidTypeField = mappings['liquid_type'];
    const parentAssetId = mappings['parent_reference'];
    console.log(
      'Fields',
      rowData?.length,
      dateField,
      caskTypeField,
      liquidTypeField,
      parentAssetId,
    );

    rowData?.forEach((row) => {
      console.log(
        'Row',
        row[dateField],
        row[caskTypeField ?? ''],
        row[liquidTypeField ?? ''],
        row[parentAssetId ?? ''],
      );
      if (row[dateField]) {
        dates.add(row[dateField]);
      }
      if (row[caskTypeField ?? '']) {
        caskTypes.add(row[caskTypeField ?? '']);
      }
      if (row[liquidTypeField ?? '']) {
        liquidTypes.add(row[liquidTypeField ?? '']);
      }
      if (row[parentAssetId ?? '']) {
        parentAssets.add(row[parentAssetId ?? '']);
      }
    });
    return [
      rowData,
      Array.from(dates.values()),
      Array.from(caskTypes.values()),
      Array.from(liquidTypes.values()),
      Array.from(parentAssets.values()),
    ];
  }, [data, mappings]);

  const mapAllDates = useCallback(async () => {
    console.log('Dates', dates);
    try {
      const mappedDates = await mapDates(dates.map((d) => ({ date: d })));
      // Check we have all of the dates
      const missing = dates.filter((f) => !mappedDates.hasOwnProperty(f));
      if (missing.length > 0) {
        enqueueSnackbar(
          `Failed to parse the following dates [${JSON.stringify(missing)}]!`,
          { variant: 'error' },
        );
        return {};
      } else {
        return mappedDates;
      }
    } catch (err) {
      console.log('Error', err);
      enqueueSnackbar('Failed to map the dates!', { variant: 'error' });
    }
  }, [dates]);

  const mapAllAssetSkus = useCallback(async () => {
    console.log('Cask Types', caskTypes);
    const skuOptions = Promise.all(
      caskTypes.map(async (t) => {
        try {
          const mappedSkus = await mapSKUs(t, assetSkus);
          console.log('Mapped Skus', t, mappedSkus);
          return [t, mappedSkus];
        } catch (err) {
          console.log('Error', err);
          enqueueSnackbar(`Failed to map the type ${t}!`, { variant: 'error' });
        }
      }),
    );
    console.log('Sku Options', skuOptions);
  }, [caskTypes]);

  const mapAllLiquidSkus = useCallback(async () => {
    console.log('Liquid Types', liquidTypes);
    const skuOptions = Promise.all(
      liquidTypes.map(async (t) => {
        try {
          const mappedSkus = await mapSKUs(t, liquidSkus);
          console.log('Mapped Skus', t, mappedSkus);
          return [t, mappedSkus];
        } catch (err) {
          console.log('Error', err);
          enqueueSnackbar(`Failed to map the liquid ${t}!`, {
            variant: 'error',
          });
        }
      }),
    );
    console.log('Sku Options', skuOptions);
  }, [liquidTypes]);

  const createPallet = (id) => {
    // TODO: Use the pallet sku to generate the identity
    // if we don't have one yet, then delay the generation..
    return {
      rw_asset_id: id,
      asset_type: ASSET_TYPES.PALLET,
      asset_status: ASSET_STATUS.PENDING_CREATE,
      properties: {
        reference: id,
      },
      child_assets: [],
    };
  };

  const reloadAssets = useCallback(async () => {
    // First thing's first, grab all the fill dates and convert the dates into epoch milliseconds
    const dates = {};
    // const dates = await mapAllDates();
    // console.log('Dates', dates);

    // const assetSkus = await mapAllAssetSkus();
    const assetSkus = {};
    // console.log('Asset SKUs', assetSkus);

    // const liquidSkus = await mapAllLiquidSkus();
    const liquidSkus = {};
    // console.log('Liquid SKUs', liquidSkus);

    // First create the parent assets
    const pallets = parentAssets.map((id) => createPallet(id));

    // Iterate through the cask list, and create a new asset for each one
    rows.forEach((row) => {
      // If the
    });
  }, [rows, model, mappings, assetType, id, prefix]);

  return (
    <>
      <Stack spacing={2}>
        <FlexBox>
          <Box>&nbsp;</Box>
          <TitledButton
            handleClick={() => reloadAssets()}
            label='Reload'
            disabled={processing}
          >
            <Refresh height={24} width={24} />
          </TitledButton>
        </FlexBox>

        <AssetSummaryList
          items={assets}
          onEdit={handleEditAsset}
          onRemove={removeAsset}
        />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onCancel()}
            color='secondary'
            label='Cancel'
          />
          <FilledButton type='submit' size='small'>
            Confirm
          </FilledButton>
        </Box>
      </Stack>
      {!!editAsset && (
        <AssetModal
          open={editAsset}
          asset={editAsset}
          onClose={handleEditClose}
        />
      )}
    </>
  );
}

export default AssetUploadProcessing;
