import { useContext, useState } from 'react';
import {
	FormikTextField,
	FormikSelect,
	FormikCheckBox,
	FormikRadioGroup,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { KeyboardArrowRightOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import controlStates from '@pw/consts/controlStates.js';
import FormikContext from '@pw/context/FormikContext';

const years = Array.from({ length: 30 }, (_, i) => 2021 + i);
const returnTypes = [
	{ value: '1', label: 'Original Return' },
	{ value: '2', label: 'Amended Return' },
];
const returnCovers = [
	{ value: '1', label: 'Period' },
	{ value: '3', label: 'Quarterly' },
	{ value: '2', label: 'Prepayment' },
];
const payTypes = [
	{ value: 'EFT', label: 'EFT' },
	{ value: 'Other', label: 'Other' },
];
const electronicTypes = [
	{ value: 'ACH', label: 'ACH' },
	{ value: 'FedWire', label: 'FedWire' },
];
const periodOptions = [
	{ value: '', label: '' },
	{ value: 'January 1 - 15', label: 'January 1 - 15' },
	{ value: 'January 16 - 31', label: 'January 16 - 31' },
	{ value: 'February 1 - 15', label: 'February 1 - 15' },
	{ value: 'February 16 - 28', label: 'February 16 - 28' },
	{ value: 'February 16 - 29', label: 'February 16 - 29' },
	{ value: 'March 1 - 15', label: 'March 1 - 15' },
	{ value: 'March 16 - 31', label: 'March 16 - 31' },
	{ value: 'April 1 - 15', label: 'April 1 - 15' },
	{ value: 'April 16 - 30', label: 'April 16 - 30' },
	{ value: 'May 1 - 15', label: 'May 1 - 15' },
	{ value: 'May 16 - 31', label: 'May 16 - 31' },
	{ value: 'June 1 - 15', label: 'June 1 - 15' },
	{ value: 'June 16 - 30', label: 'June 16 - 30' },
	{ value: 'July 1 - 15', label: 'July 1 - 15' },
	{ value: 'July 16 - 31', label: 'July 16 - 31' },
	{ value: 'August 1 - 15', label: 'August 1 - 15' },
	{ value: 'August 16 - 31', label: 'August 16 - 31' },
	{ value: 'September 1 - 15', label: 'September 1 - 15' },
	{ value: 'September 16 - 25', label: 'September 16 - 25' },
	{ value: 'September 16 - 26', label: 'September 16 - 26' },
	{ value: 'September 26 - 30', label: 'September 26 - 30' },
	{ value: 'September 27 - 30', label: 'September 27 - 30' },
	{ value: 'October 1 - 15', label: 'October 1 - 15' },
	{ value: 'October 16 - 31', label: 'October 16 - 31' },
	{ value: 'November 1 - 15', label: 'November 1 - 15' },
	{ value: 'November 16 - 30', label: 'November 16 - 30' },
	{ value: 'December 1 - 15', label: 'December 1 - 15' },
	{ value: 'December 16 - 31', label: 'December 16 - 31' },
	{ value: 'Annual', label: 'Annual' },
];
const periodOptions2 = [
	{ value: '', label: '' },
	{ value: 'January 1 - March 31', label: 'January 1 - March 31' },
	{ value: 'April 1 - June 30', label: 'April 1 - June 30' },
	{ value: 'July 1 - September 30', label: 'July 1 - September 30' },
	{ value: 'October 1 - December 31', label: 'October 1 - December 31' },
];

export default function GeneralInformation({
	name,
	title = 'General Information',
	disabled = false,
}) {
	const { values } = useContext(FormikContext);
	const selectedPeriodOptions =
		values.general_information?.RETURN_COVERS_TYPE === '1'
			? periodOptions
			: values.general_information?.RETURN_COVERS_TYPE === '3'
				? periodOptions2
				: [];
	const [expanded, setExpanded] = useState(true);
	return (
		<FormikProvider path={name}>
			<Stack direction='row' alignItems='center'>
				<H4>{title}</H4>
				<IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
					{!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
				</IconCircleButton>
			</Stack>
			{expanded && (
				<>
					<Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
						<Typography variant='h6' gutterBottom>
							Company Information
						</Typography>
						<Box noValidate autoComplete='off'>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<FormikTextField
										name='partyCompanyName'
										label='Company Name'
										required
										fullWidth
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormikTextField
										name='DBA'
										label='Doing Business As'
										fullWidth
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormikTextField
										name='partyAddress1'
										label='Street Address'
										required
										fullWidth
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormikTextField
										name='partyCity'
										label='City'
										required
										fullWidth
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={6}>
									<FormikSelect
										name='partyState'
										label='State'
										options={controlStates}
										value={values.general_information?.partyState || ''}
										required
										fullWidth
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={12}>
									<FormikTextField
										name='partyZipcode'
										label='Zip Code'
										required
										fullWidth
										disabled={disabled}
									/>
								</Grid>
							</Grid>
						</Box>
					</Paper>

					<FormikRadioGroup
						name='ReturnType'
						label='Return Type'
						options={returnTypes}
						required
						disabled={disabled}
					/>
					<Stack direction='row' alignItems='center'>
						<FormikCheckBox
							name='SERIAL_FINAL'
							label={'Final'}
							disabled={disabled}
						/>
					</Stack>

					<FormikTextField
						name='PERMIT_NUMBER'
						label='Plant, Registry, or Permit Number '
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='sensEMPLOYER_ID'
						label='Employer Identification Number '
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikTextField
						name='SERIAL_NUMBER'
						label='Serial Number'
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikSelect
						name='Pay'
						label='Form of Payment '
						options={payTypes}
						value={values.general_information?.Pay || ''}
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikSelect
						name='PaymentMethodMethodCode'
						label='Electronic Type'
						options={electronicTypes}
						value={values.general_information?.PaymentMethodMethodCode || ''}
						required
						fullWidth
						disabled={disabled}
					/>
					<FormikRadioGroup
						name='RETURN_COVERS_TYPE'
						label='Return Covers'
						options={returnCovers}
						required
						disabled={disabled}
					/>
					{values.general_information?.RETURN_COVERS_TYPE !== '2' && (
						<FlexBox alignItems='top'>
							<FormikSelect
								name='PERIOD_YEAR'
								label='Year'
								options={years.map((year) => ({ value: year, label: year }))}
								value={values.general_information?.PERIOD_YEAR || ''}
								required
								fullWidth
								disabled={disabled}
							/>
							<FormikSelect
								name='PeriodList'
								label='Period'
								options={selectedPeriodOptions}
								value={values.general_information?.PeriodList || ''}
								required
								fullWidth
								disabled={disabled}
							/>
						</FlexBox>
					)}
				</>
			)}
		</FormikProvider>
	);
}
