import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import InventorySelector from '@pw/components/InventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import ThingSearch from '@pw/components_v2/search/thing';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import * as yup from 'yup';

function ChildAssets({ childAssets, addAsset, removeAsset, disabled = false }) {
  return (
    <>
      <InventorySelector
        title='Assets'
        list={childAssets}
        types={{ asset_types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC] }}
        onAdd={addAsset}
        onRemove={removeAsset}
        disabled={disabled}
      />
    </>
  );
}

function propertyFields(fields = {}) {
  const { tags = [], thing } = fields;

  return {
    tags: [
      typeof tags === 'string' ? tags?.split(',') : tags,
      yup.array().of(yup.string()),
    ],
  };
}

function Properties({ entity }) {
  // const filter = (item) => item?.properties?.asset_type === ASSET_TYPES.PALLET;
  return (
    <FormikProvider path='properties'>
      <Stack spacing={2}>
        <Divider><Overline>Properties</Overline></Divider>
        <ThingSearch field={"thing"} label='Type' types={[THING_TYPES.TRACKED]} />
        <TagSelect entity={entity} name='tags' type={TAG_CATEGORY.ASSET} />
      </Stack>
    </FormikProvider>
  );
}

function PalletForm(props) {
  const [childAssets, init, addAsset, , removeAsset] = useItemListManager();
  const [entity, setEntity] = useState(null);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...assetIdFields(initialValues),
      properties: propertyFields(initialValues.properties),
    }),
    [],
  );

  const handleInit = (entity) => {
    const { child_assets = [] } = entity ?? {};
    setEntity(entity);
    child_assets.sort(COMP.asset);
    debounce(() => init(child_assets), 50);
  };

  // On the way out, add the children and sku
  const handleBeforeSave = useCallback(
    (entity) => {
      return {
        ...entity,
        child_assets: childAssets,
      };
    },
    [childAssets],
  );

  return (
    <AssetForm
      assetLabel='Pallet'
      assetType={ASSET_TYPES.PALLET}
      changeSetGenerator={changeSetGenerator}
      onInit={handleInit}
      onBeforeSave={handleBeforeSave}
      {...props}
    >

      <Properties entity={entity} />
      <ChildAssets
        childAssets={childAssets}
        addAsset={addAsset}
        removeAsset={removeAsset}
      />
    </AssetForm>
  );
}

export default withAppLayout(PalletForm, { title: 'Pallet Management' });
