function SvgUser(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M2.803 18c-.212-.044-.428-.076-.636-.136-1.29-.37-2.099-1.351-2.155-2.691-.059-1.392.095-2.763.63-4.065.344-.841.845-1.568 1.684-2.013a3.352 3.352 0 011.654-.393c.183.004.376.095.543.186.27.144.52.325.78.485 1.521.93 3.043.929 4.563-.003.21-.13.426-.255.624-.402.364-.27.764-.303 1.196-.24 1.18.171 2.012.81 2.574 1.83.502.911.728 1.9.828 2.92.051.524.076 1.052.069 1.578-.02 1.57-1.108 2.739-2.686 2.911-.039.004-.077.022-.115.033H2.803zm4.773-1.055c1.42 0 2.84.002 4.26 0 .196 0 .391-.013.585-.035.887-.102 1.535-.714 1.644-1.584.088-.707.02-1.416-.082-2.118-.105-.732-.278-1.448-.638-2.107-.424-.778-1.036-1.283-1.96-1.336a.46.46 0 00-.249.057c-.268.16-.527.336-.794.499a5.284 5.284 0 01-2.382.787c-1.173.09-2.245-.227-3.232-.847-.235-.147-.467-.298-.704-.443-.048-.03-.108-.063-.16-.061-.575.026-1.091.203-1.505.613-.331.328-.566.721-.739 1.148-.468 1.158-.583 2.374-.545 3.604.027.847.502 1.461 1.262 1.687.3.09.627.128.942.131 1.432.013 2.864.006 4.297.006zM11.848 4.33c.02 2.362-1.955 4.338-4.37 4.345-2.4.007-4.376-1.926-4.39-4.326C3.075 1.955 5.018.016 7.45-.001a4.35 4.35 0 014.4 4.33zM7.442 7.615c1.792.038 3.33-1.446 3.346-3.255.016-1.77-1.424-3.26-3.24-3.305a3.29 3.29 0 00-3.396 3.217c-.05 1.786 1.45 3.351 3.29 3.343z'
        fill='#404040'
      />
    </svg>
  );
}
export default SvgUser;
