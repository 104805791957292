export const POLICY_EDIT = '/admin/policies';

export const POLICY_STATUS = {
  ACTIVE: "active",
  DISABLED: "disabled",
};
export const POLICY_STATUS_OPTIONS = [
  { label: 'Active', value: POLICY_STATUS.ACTIVE },
  { label: 'Disabled', value: POLICY_STATUS.DISABLED },
];

export const POLICY_STAGE = {
  PRE: "pre", //"0",
  DURING: "during", //"1",
  POST: "post", //"2",
}

export const POLICY_STAGE_OPTIONS = [
  { label: 'Pre', value: POLICY_STAGE.PRE },
  { label: 'During', value: POLICY_STAGE.DURING },
  { label: 'Post', value: POLICY_STAGE.POST },
];

export const POLICY_ENTRY_TYPE = {
  BOOLEAN: 0,
  STRING: 1,
  NUMBER: 2,
  SELECTION: 3,
};

export const POLICY_ENTRY_TYPE_OPTIONS = [
  {
    label: 'Confirmation',
    value: POLICY_ENTRY_TYPE.BOOLEAN,
  },
  {
    label: 'Text',
    value: POLICY_ENTRY_TYPE.STRING,
  },
  {
    label: 'Number',
    value: POLICY_ENTRY_TYPE.NUMBER,
  },
  {
    label: 'Selection',
    value: POLICY_ENTRY_TYPE.SELECTION,
  },
];

export const POLICY_COMPLIANCE = {
  NON_BLOCKING: "non-blocking",
  MUST_APPROVE: "must-approve",
  BLOCKING: "blocking",
};

export const POLICY_CONDITION_OPTIONS = [
  {
    label: 'Not Required',
    value: POLICY_COMPLIANCE.NON_BLOCKING,
  },
  {
    label: 'Approval Required',
    value: POLICY_COMPLIANCE.MUST_APPROVE,
  },
  {
    label: 'Block',
    value: POLICY_COMPLIANCE.BLOCKING,
  },
];
