import Box from '@mui/material/Box';
// import { useViewport } from 'reactflow';
import { gridGap, itemHeight, itemWidth } from '@pw/components/ProductionDesigner/ProductionDesigner.styles';
import { useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';

const styles = {
  '.dropzone-container': {
    width: '100%',
    height: '100%'
  },
  '.instructions': {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  '.hidden': {
    visibility: 'hidden'
  },
  '.lock-ovelay': {
    position: 'absolute'
  }
};

function DropZone({
  children,
  className,
  accept = 'dnd-item',
  name = 'dropzone',
  instructions,
  x,
  y,
  zoom,
  ...rest
}) {
  const rootRef = useRef();
  const [offsetParent, setOffsetParent] = useState();

  useEffect(() => {
    if (!rootRef.current) return;
    const { left, top } = rootRef.current.getBoundingClientRect();
    setOffsetParent({ left, top });
  }, [rootRef?.current]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept,
      // Props to collect
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }),
      drop: (_, monitor) => {
        const dropOffset = monitor.getClientOffset();
        const roundedX = Math.round(
          (dropOffset.x - (offsetParent?.left || 0) - itemWidth / 2 - x) /
          (gridGap * zoom)
        );
        const roundedY = Math.round(
          (dropOffset.y - (offsetParent?.top || 0) - itemHeight / 2 - y) /
          (gridGap * zoom)
        );

        return {
          x: roundedX * gridGap,
          y: roundedY * gridGap
        };
      }
    }),
    [offsetParent, x, y, zoom]
  );

  return (
    <Box
      ref={rootRef}
      sx={styles}
      className={`dropzone-root ${className || ''}`}
      {...rest}
    >
      <Box
        className="dropzone-container"
        ref={drop}
        role={'Dustbin'}
      >
        {instructions(canDrop)}
        {children}
      </Box>
    </Box>
  );
}

export default DropZone;
