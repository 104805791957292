import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import { Body3, Overline } from '@pw/components/Typography';

function Assignee({ entry }) {
  const { id = {} } = entry ?? {};
  const { target_id = {} } = id;

  return <AccountDisplay name={target_id?.name} />;
}

function AssigneeProps({ entity }) {
  const { assignees = [] } = entity ?? {};

  return (
    <FlexBox className='creator-view'>
      <Stack spacing={0.5} className='column'>
        <Overline>Assignees</Overline>
        {assignees.map((a, i) => (
          <Assignee key={`a_${i}`} entry={a} />
        ))}
        {assignees.length === 0 && <Body3>Not Assigned</Body3>}
      </Stack>
    </FlexBox>
  );
}

export default AssigneeProps;
