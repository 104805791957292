import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Pagination from '@pw/components/Pagination';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { Body3, H5 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactListItem from './ContactListItem';
import ContactSearch from './ContactSearch';
import { PATH } from './consts';
import { useContactsQueryPaginated } from '@pw/utilities/hooks/service/useContactsQueries';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';

function ContactsList() {
  const navigate = useNavigate();

  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      limit: Number(pageSize),
      filter_types: [RELATIONSHIP_TYPE.CUSTOMER, RELATIONSHIP_TYPE.PARTNER],
    };
  }, [pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useContactsQueryPaginated(reqParams);

  const goToItem = (params) => {
    const id = params?._id ?? 'new';
    navigate(`${PATH}/${id}`, { replace: true });
  };

  return (
    <Stack spacing={1}>
      <Stack
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <H5>Contact List</H5>
      </Stack>
      {!isLoading && page && page.length > 0 && (
        <Stack spacing={0.5}>
          <Pagination
            hasNextPage={hasNext}
            hasPrevPage={hasPrev}
            onPressNextPage={loadNext}
            onPressPrevPage={loadPrev}
            onChangePageSize={setPageSize}
            defaultPageSize={pageSize}
            pageSize={pageSize}
          />
          <Stack className='list'>
            {page.map((i) => (
              <ContactListItem
                key={i._id}
                item={i}
                buttonIcon={<ArrowForward />}
                onClick={() => goToItem(i)}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {!isLoading && (!page || page.length === 0) && (
        <Body3>Nothing yet.</Body3>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton />
            <ListItemSkeleton />
            <ListItemSkeleton />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function Navigation() {
  const navigate = useNavigate();

  const goToItem = (params) => {
    console.log('goToItem.params', {params});
    const id = params?._id ?? 'new';
    console.log('Navigating to', params, PATH, id);
    navigate(`${PATH}/${id}`);
  };

  return (
    <Stack sx={styles} className='root' spacing={3}>
      <Box className='section'>
        <ContactSearch
          title='Search'
          sectionTitle={null}
          label='Contact'
          buttonIcon={<ArrowForward />}
          onItemClick={goToItem}
        />
      </Box>
      <ContactsList />
    </Stack>
  );
}

export default withSmallDialogCard(Navigation);
