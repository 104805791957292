import { useCallback } from 'react';

import { Box, Button, Select, MenuItem } from "@mui/material";
import { East, West } from "@mui/icons-material";

import FilledButton from "@pw/components/Buttons/FilledButton";

const PrevNextButtons = ({ setRange, viewing, handleRangeChange }) => (
  <Box className="flex paging-buttons">
    <Button className="pan prev" onClick={() => { handleRangeChange('-') }}>
      <West />
    </Button>
    <Box className="viewing">{viewing}</Box>
    <Button className="pan next" onClick={() => { handleRangeChange('+') }}>
      <East />
    </Button>
  </Box>
);

const TimeframeButtons = ({ viewModes, viewMode, setViewMode }) => {
  return (
    <Box className="flex timeframe-buttons">
      {viewModes.map(({ label, key }) => (
        <Button
          key={key}
          className={`viewmode ${viewMode === key ? 'active' : ''} `}
          onClick={() => { console.log('setViewMode', key); setViewMode(key); }}>{label}</Button >
      ))}
    </Box>
  );
}

const TopBar = ({
  viewModes, viewMode, setViewMode, updatePanOffset, viewing, handleRangeChange
}) => (
  <Box className={`top-bar-root viewmode-${viewMode} flex`}>
    <PrevNextButtons viewing={viewing} handleRangeChange={handleRangeChange} />
    <TimeframeButtons viewModes={viewModes} viewMode={viewMode} setViewMode={setViewMode} />
  </Box>
);

export default TopBar;
