import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { ABV_UNIT_OPTIONS, TEMPERATURE_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      target_time: unitField(initialValues?.target_time),
      target_temp: unitField(initialValues?.target_temp),
      heads_abv: unitField(initialValues?.heads_abv),
      heads_offset: unitField(initialValues?.heads_offset),
      hearts_abv: unitField(initialValues?.hearts_abv),
      hearts_offset: unitField(initialValues?.hearts_offset),
      tails_abv: unitField(initialValues?.tails_abv),
      tails_offset: unitField(initialValues?.tails_offset),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FlexBox>
          <FormikUnitField name="target_time" label="Target Time" options={TIME_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="target_temp" label="Target Temperature" options={TEMPERATURE_UNIT_OPTIONS} fullWidth />
        </FlexBox>
        <H5>Cutoff Points</H5>
        <FlexBox>
          <FormikUnitField name="heads_abv" label="Heads ABV" options={ABV_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="heads_offset" label="Heads Offset" options={TIME_UNIT_OPTIONS} fullWidth />
        </FlexBox>
        <FlexBox>
          <FormikUnitField name="hearts_abv" label="Hearts ABV" options={ABV_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="hearts_offset" label="Hearts Offset" options={TIME_UNIT_OPTIONS} fullWidth />
        </FlexBox>
        <FlexBox>
          <FormikUnitField name="tails_abv" label="Tails ABV" options={ABV_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="tails_offset" label="Tails Offset" options={TIME_UNIT_OPTIONS} fullWidth />
        </FlexBox>
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
