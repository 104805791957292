import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';
import { UNIT } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { useIsUnitFormatImperial } from '@pw/redux/containers/User/hooks';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

function LiquidSummary({ level }) {
	const { bl = 0 } = level || {};
	const { values } = useContext(FormikContext);
	const { t } = useTranslation();

	const [state, setState] = useState(null);

	const converter = useConverter();
	const imperial = useIsUnitFormatImperial();

	useEffect(() => {//SKU
		const { expected_bl, filled_bl, updated_bl, updated_abv, updated_tcf } =
			values;

		console.log('Values', level, values);

		// Expected
		let rbl = 0;
		let abv = 0;
		let nla = 0;
		let gain = 0;

		if (filled_bl) {
			if (updated_abv && updated_tcf) {
				rbl = Number(bl) + converter.to(filled_bl, UNIT.BL);
				abv = converter.to(updated_abv, UNIT.ALC) * Number(updated_tcf);
				nla = Number(rbl) * (abv / 100.0);
			}
		} else {
			if (updated_bl && updated_abv && updated_tcf) {
				rbl = Math.max(Number(bl) - converter.to(expected_bl, UNIT.BL), 0);
				abv = converter.to(updated_abv, UNIT.ALC) * Number(updated_tcf);
				const ubl = converter.to(updated_bl, UNIT.BL);
				nla = ubl * (abv / 100.0);
				gain = ubl - rbl;
			}
		}

		setState([
			Number(rbl).toFixed(3), // expected
			Number(abv).toFixed(2), // updated abv
			Number(nla).toFixed(3), // computed alcohol based on entered remainder
			gain ? Number(gain).toFixed(3) : null, // gain or loss between expected remainder and entered remainder
		]);
	}, [values]);

	if (!state) return;

	return (
		<Stack spacing={2}>
			<FlexBox>
				<StrengthDisplay
					label={
						imperial ? `${t('strength')} @ 60°F` : `${t('strength')} @ 20°C`
					}
					value={state[1]}
				/>
				<LiquidDisplay label={t('alcohol')} la={state[2]} />
			</FlexBox>
			<FlexBox>
				<LiquidDisplay label='Total' value={state[0]} />
				{state[3] && <LiquidDisplay label='Gain' value={state[3]} />}
			</FlexBox>
		</Stack>
	);
}

export default LiquidSummary;
