import generalInformationFields from './generalInformationFields';
import part1Fields from './part1Fields';
import part2Fields from './part2Fields';
import signatureFields from './signatureFields';
import * as yup from 'yup';

export default function TTBReport_11Fields(fields = {}, company, companyName) {
	const { part1, part2, signature, formUrlId = '2655' } = fields;

	const defaultValues = {
		BUSINESS: companyName ?? '',
		DBA: company?.account_name ?? '',
		ADDRESS: company?.company_address.address_1 ?? '',
		CITY: company?.company_address.city ?? '',
		STATE: company?.company_address.state ?? '',
		ZIP_CODE: company?.company_address.post_code ?? '',
		plantNumber: company.company_compliance.compliance_id ?? '',
	};
	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields(defaultValues),
		part1: part1Fields(part1),
		part2: part2Fields(part2),
		signature: signatureFields(signature),
	};
}
