import { INVENTORY_STATUS, THING_TYPES } from '@pw/consts/thing';
import debounce from '@pw/utilities/debounce';
import { useEffect } from 'react';

const useThingAssetPick = (assets, skus, upsert) => {
	useEffect(() => {
		// console.log('Updating SKU Stats', assets.length, skus);
		skus
			.filter((s) => s.type === THING_TYPES.TRACKED)
			.forEach((s) => {
				const picked = assets.filter((a) => a.properties?.thing?.id === s._id);

				// Get the amount from the entries
				let amount = 0;
				(s.entries ?? []).forEach((e) => {
					amount += Number(e.amount ?? '0');
				});

				const count = picked.length;
				// console.log('Amount', amount, count);
				const processed =
					count === amount && amount > 0
						? INVENTORY_STATUS.CONFIRMED
						: s.processed || INVENTORY_STATUS.PENDING;

				debounce(() => upsert({ ...s, picked: count, processed }, false), 50);
			});
	}, [assets]);
};

export default useThingAssetPick;
