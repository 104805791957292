import { Stack } from '@mui/material';
import SingleSelect from '@pw/components_v2/elements/SingleSelect';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';
import FormikContext from '@pw/context/FormikContext';
import { useContext, useMemo } from 'react';

function ContactSearch({ label = 'Client', field = 'client' }) {
  const { values } = useContext(FormikContext);

  const search = useMemo(() => ({
    [SEARCH_TYPES.CONTACTS]: {
      types: [RELATIONSHIP_TYPE.PARTNER, RELATIONSHIP_TYPE.CUSTOMER, RELATIONSHIP_TYPE.INTER_COMPANY],
    },
  }), []);

  const { _id, name, company_type, compliance } = values?.[field]?.target_id ?? {};

  return (
    <SingleSelect search={search} label={label} field={field}>
      {_id && (
        <Stack>
          <EntryTitle value={name} />
          <EntryLabel label="Profile" value={COMPANY_TYPE_REVERSE[company_type]} />
          {compliance?.registration_id && (<EntryLabel label="Registration" value={compliance?.registration_id} />)}
        </Stack>
      )}
    </SingleSelect>
  );
}

export default ContactSearch;
