import { setLanguageThunk } from '../language';
import loadCompanyState from './loadCompanyState';

export const handleSignin = async (i18n, response, fulfillWithValue, rejectWithValue, dispatch) => {

  console.log('handleSignin', response);

  const { account, company_id, account_id, permission = {}, company } = response;

  // Save tokens required for http operations
  window.localStorage.setItem('auth-token', response.Authorization);
  window.localStorage.setItem('company-id', company_id);
  window.localStorage.setItem('account-id', account_id);

  // Load company settings
  if (company_id && company) {
    console.log('Company', company_id, company);
    const isUsUser = ['US'].includes(company.address?.country);
    if (isUsUser) {
      dispatch(setLanguageThunk({ i18n, language: 'en-US' }));
    }

    const updatedCompany = await loadCompanyState(company.company_id, dispatch, response.permission);

    return fulfillWithValue({
      ...response,
      company: updatedCompany,
    });
  }

  return fulfillWithValue(response);
};
