import Stack from '@mui/material/Stack';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function SpecEntryEditor({ item, open, onClose, readonly }) {
  const { enqueueSnackbar } = useSnackbar();
  const { name, description, value } = item ?? {};
  const label = item?.name ? `Update ${name}` : `New Entry`;

  const changeSet = useMemo(
    () => ({
      name: [name ?? '', yup.string().required('Name required!')],
      description: [
        description ?? '',
        yup.string().required('Description required!'),
      ],
      value: unitField(value, true),
    }),
    [name, description],
  );

  const handleSubmit = (entry) => {
    try {
      console.log('Setting Entry', entry);
      onClose(entry);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={label}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
          <FormikTextField label='Name' name='name' fullWidth />
          <FormikTextField label='Description' name='description' fullWidth />
          <FormikUnitField
            label='Value'
            name='value'
            options={UNITS_OPTIONS}
            fullWidth
          />
          <FormButtons readonly={readonly} onCancel={onClose} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default SpecEntryEditor;
