import { Box, Divider, Stack } from '@mui/material';
import { FormikDatePicker } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ReportAdjustment from './ReportAdjustment';
import ReportQuantity from './ReportQuantity';
import RequestListItem from '@pw/components/RequestListItem';
import { ArrowForward } from '@mui/icons-material';
import { REQUEST_EDIT_URL } from '@pw/consts/requests';
import { Body3, H6 } from '../Typography';
import { useNavigate } from 'react-router-dom';

export default function MonthlyReport({ requests = [] }) {
  const navigate = useNavigate();
  const goToItem = ({ _id, type }) => {
    const route = REQUEST_EDIT_URL?.[type];
    console.log('Navigating to', `${route}/${_id}`);
    navigate(`${route}/${_id}`, { replace: true });
  };

  return (
    <>
      <Divider />
      <FlexBox alignItems='top'>
        <FormikDatePicker
          name='start_date'
          label='Period Start Date'
          fullWidth
          required
        />
        <FormikDatePicker
          name='end_date'
          label='Period End Date'
          fullWidth
          required
        />
      </FlexBox>
      <Divider />
      <Box className='section'>
        <H6 className='section-title'>Tasks</H6>
        <Stack className='list'>
          {requests.map((i) => (
            <RequestListItem
              key={i._id}
              item={i}
              buttonIcon={<ArrowForward />}
              onItemClick={() => goToItem(i)}
            />
          ))}
          {!requests?.length && <Body3>No tasks have been completed.</Body3>}
        </Stack>
      </Box>
      <Divider />
      <ReportQuantity name='opening_stock' title='Opening Stock' />
      <Divider />
      <ReportQuantity name='closing_stock' title='Closing Stock' />
      <Divider />
      <ReportAdjustment name='adjustment' title='Adjustment' />
      <Divider />
      <ReportQuantity
        name='receipts_from_importation'
        title='Receipts From Importation'
      />
      <Divider />
      <ReportQuantity
        name='receipts_from_uk_and_other_warehouses'
        title='Receipts from UK and Other Warehouses'
      />
      <Divider />
      <ReportQuantity name='gains_in_storage' title='Gains In Storage' />
      <Divider />
      <ReportQuantity
        name='increases_from_operations'
        title='Increases From Operations'
      />
      <Divider />
      <ReportQuantity
        name='removals_to_home_use'
        title='Removals to Home Use'
      />
      <Divider />
      <ReportQuantity
        name='removals_to_exportation'
        title='Removals to Exportation'
      />
      <Divider />
      <ReportQuantity
        name='removals_to_other_uk_warehouses'
        title='Removals to other UK Warehouses'
      />
      <Divider />
      <ReportQuantity
        name='removals_to_other_duty_free_uses'
        title='Removals to other Duty Free Uses'
      />
      <Divider />
      <ReportQuantity name='losses_in_storage' title='Losses in Storage' />
      <Divider />
      <ReportQuantity
        name='losses_from_operations'
        title='Losses from Operations'
      />
    </>
  );
}
