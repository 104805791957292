import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';
import { getAssetsByThing } from '@pw/services/thing.service';
import { useCallback, useState } from 'react';

const serviceFunction = (params = {}) => getAssetsByThing(params);

export const [useAssetsByThingQueryLazy, useAssetsByThingQuery] =
	genServiceCallHooks(serviceFunction);

export const useAssetsByThingPaginated = (params, initialValues) => {
	const [lazyFetch, { isLoading }] = useAssetsByThingQueryLazy();
	const [pages, setPages] = useState(() => [initialValues]);
	const [currentPage, setCurrentPage] = useState(0);
	const [lastPage, setLastPage] = useState(null);

	const load = useCallback(
		async (p) => {
			try {
				const page = p?.page ?? currentPage + 1;
				const res = await lazyFetch(p);
				if (res?.assets && res?.assets[params.assetType]) {
					setPages((v) => [...v, res.assets[params.assetType]]);
					setCurrentPage(page);
				} else {
					setLastPage(page - 1);
					setCurrentPage(page - 1);
				}
			} catch (e) {
				setPages([]);
				setCurrentPage(0);
			}
		},
		[lazyFetch, currentPage],
	);

	const prev = useCallback(() => {
		if (currentPage) {
			setCurrentPage(currentPage - 1);
		}
	}, [currentPage]);

	const next = useCallback(async () => {
		const cp = currentPage + 1;
		if (cp < pages.length) {
			setCurrentPage(cp);
		} else {
			await load({ ...params, page: cp }).finally();
		}
	}, [currentPage, pages, load, params]);

	const refetchCurrentPage = useCallback(async () => {
		const res = await lazyFetch({ ...params, page: currentPage });
		// replace current page with res
		setPages((v) => [...v.slice(0, currentPage), res]);
	}, [currentPage, lazyFetch, params]);

	return [
		isLoading,
		pages?.[currentPage]?.items ?? pages?.[currentPage] ?? [],
		currentPage > 0,
		prev,
		(currentPage + 1 < pages.length ||
			(pages.length > 0 &&
				(pages[pages.length - 1]?.items ?? pages[pages.length - 1] ?? [])
					.length >= params?.limit)) &&
			(lastPage !== null ? currentPage < lastPage : true),
		next,
		refetchCurrentPage,
	];
};
