import { Stack } from '@mui/material';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      sensor_id: [initialValues?.sensor_id, yup.string()],
      interval: unitField(initialValues?.interval),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack spacing={2}>
        <FormikTextField name="sensor_id" label="Sensor ID" />
        <FormikUnitField name="interval" label="Interval" options={TIME_UNIT_OPTIONS} />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
