import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikNumberField, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body2, Overline } from '@pw/components/Typography';
import { THING_TYPES } from '@pw/consts/thing';
import { ArrayFormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyThings } from '@pw/redux/containers/User';
import calculateThingStorageLiquid from '@pw/utilities/calculateThingStorageLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function AuditItem({ thing, index }) {
	return (
		<ArrayFormikProvider path="audit" index={index}>
			<Stack spacing="0.5rem">
				<Body2>{thing?.name}</Body2>
				<FlexBox alignItems='top'>
					<FormikNumberField name="quantity" label="Audit Quantity" placeholder={thing.available_quantity ?? '0'} fullWidth />
					<FormikTextField name="reason" label="Explanation" fullWidth />
				</FlexBox>
			</Stack>
		</ArrayFormikProvider>
	);
}

function ThingAuditConfirmModal({ item, open, onClose }) {
	const companyThings = useCompanyThings();
	const converter = useConverter();

	const { thing_storage } = item ?? {};

	const changeSet = useMemo(
		() => ({
			audit: [
				thing_storage.map((s) => ({ id: s._id, quantity: s.audit_quantity ?? '', reason: s.audit_reason ?? '' })) ?? [],
				yup.array().of(
					yup.object().shape({
						id: yup.string(),
						quantity: yup.number().required('New quantity is required!'),
						reason: yup.string().required('Explanation is required!'),
					})
				)
			],
		}),
		[thing_storage],
	);

	const handleSubmit = useCallback((values) => {
		console.log('+>>handleSubmit', values);

		// Go through the quantities and add an audit_quantity to the Storage item to be handled
		// by the back-end..
		const updatedStorage = [];
		for (const a of values.audit) {
			const { id, quantity, reason } = a;
			const storage = thing_storage.find((s) => s._id === id);

			const thing = companyThings.find((s) => s._id === id);

			const amount = storage.available_quantity ?? storage.amount;
			const liquid_amount = calculateThingStorageLiquid(converter, amount, thing, companyThings);

			// Find the Thing
			const { type, properties } = thing;
			const { unit } = properties ?? {};

			// Re-calculate the liquid if this is a finished goods item.
			if (type === THING_TYPES.FINISHED) {
				updatedStorage.push({
					...storage,
					amount: unit ? converter.cx(quantity, unit) : quantity,
					liquid_amount,
					audit_quantity: unit ? converter.cx(quantity, unit) : quantity,
					audit_liquid_quantity: calculateThingStorageLiquid(converter, quantity, thing, companyThings),
					audit_reason: reason,
				});
			} else {
				updatedStorage.push({
					...storage,
					amount: unit ? converter.cx(quantity, unit) : quantity,
					liquid_amount,
					audit_quantity: unit ? converter.cx(quantity, unit) : quantity,
					audit_reason: reason
				});
			}
		}

		console.log(' -->', updatedStorage);

		onClose({ ...item, thing_storage: updatedStorage });
	}, [item, onClose, thing_storage]);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={`Inventory of ${item?.rw_asset_id}`}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing="2rem">
					<Stack className="inventory">
						<Box className="inventory-header">
							<Overline>Inventory</Overline>
						</Box>
						<Stack spacing="2rem" className="inventory-contents">
						{thing_storage.map((i, index) => (
							<AuditItem thing={i} key={i._id} index={index} />
						))}
						</Stack>
					</Stack>

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default ThingAuditConfirmModal;
