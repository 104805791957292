function SvgStartEnd(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M54.5 24.5h-45C5.345 24.5 2 27.845 2 32s3.345 7.5 7.5 7.5h45c4.155 0 7.5-3.345 7.5-7.5s-3.345-7.5-7.5-7.5z'
        stroke='#838383'
        fill='#efc'
      />
    </svg>
  );
}
export default SvgStartEnd;
