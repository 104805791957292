import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import { H5 } from '@pw/components/Typography';
import Address from '@pw/components_v2/Address';
import { partnerContactFields } from '@pw/components_v2/contact/contactFields';
import { upsertPartnerContactThunk } from '@pw/redux/thunks/contacts';
import { useDispatch } from 'react-redux';

function PartnerContactEditor({
  entity = {},
  edit = false,
  onCancel,
  onBeforeSave = (v) => {},
  onSave,
}) {
  const dispatch = useDispatch();

  const changeSet = partnerContactFields(entity);

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;

    const request = {
      ...entity,
      ...updated,
    };

    console.log('Submitting', request);

    // @ts-ignore
    dispatch(upsertPartnerContactThunk(request))
      // @ts-ignore
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <ModalWithClose open onClose={onCancel} title='Partner Contact'>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit} edit={edit}>
        <FormWrapper>
          <FormikTextField name='contact_tag' label='Tag' fullWidth required />
          <FormikTextField name='name' label='Name' fullWidth required />

          <FormikTextField
            name='public_name'
            label='Display Name'
            fullWidth
            required
          />

          <Stack spacing='1.5rem'>
            <H5>Contact Details</H5>
            <FormikTextField name='telephone' label='Telephone' fullWidth />
            <FormikTextField name='phone' label='Mobile' fullWidth />
            <FormikTextField name='email' label='Email' fullWidth />
          </Stack>

          <Address />
          <Box className='action-buttons'>
            {onCancel && (
              <TextButton
                size='small'
                handleClick={onCancel}
                color='secondary'
                label='Cancel'
              />
            )}
            <FilledButton type='submit' size='small'>
              {edit ? 'Update' : 'Add'}
            </FilledButton>
          </Box>
        </FormWrapper>
      </FormikForm>
    </ModalWithClose>
  );
}

export default PartnerContactEditor;
