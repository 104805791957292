import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { unit } = fields;
	return {
		unit: [unit, yup.string().required('Unit is required!')],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<Divider><Overline>Properties</Overline></Divider>
				<FormikSelect label='Unit' name='unit' options={UNITS_OPTIONS} />
			</Stack>
		</FormikProvider>
	);
}

function ThingPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...thingSettingsFields(initialValues, THING_TYPES.FINISHED),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<ThingForm
			thingLabel='Waste Material'
			thingType={THING_TYPES.WASTE}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</ThingForm>
	);
}

export default withAppLayout(ThingPage, { title: 'Waste Material' });
