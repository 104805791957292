function SvgInternalStorage(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g fill='#fcf'>
        <path d='M1.5 16.5h60v30h-60zM9.5 16.5v30M1.5 24.5h60' />
      </g>
    </svg>
  );
}
export default SvgInternalStorage;
