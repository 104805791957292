import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { Body1, Body2, Body3 } from '@pw/components/Typography';
import LedgerEditor from '@pw/components_v2/CompanyView/modals/ledgers/LedgerEditor';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { removeLedgerThunk } from '@pw/redux/thunks/policy';
import { removePolicyThunk } from '@pw/redux/thunks/policy';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCompanyPoliciesQuery } from '@pw/utilities/hooks/service/useCompanyPoliciesQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PolicyEditor from './PolicyEditor';
import { COMP, ID } from '@pw/utilities/comp';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';

function Policy({ item }) {

  return (
    <Stack>
      <EntryTitle value={item?.name} />
      <EntryLabel label="Entries" value={item.entries?.length ?? 0} />
    </Stack>
  );
}

function PoliciesView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.POLICIES, PermissionType.MODIFY);

  const { isLoading, data } = useCompanyPoliciesQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const [policies, initPolicies, , upsertPolicies, removePolicies] = useItemListManager({
    id: ID.policy,
    comp: COMP.policy,
    initialData: items,
  });
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initPolicies(items);
    }
  }, [initPolicies, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((policy) => {
      setPolicy(policy);
      showEditor();
    }, [setPolicy]);

    const handleRemove = useCallback(
      (policy) => {
        const body = {
          _id: policy._id,
        };
        console.log('Remove policy', body);

        confirm({
          title: 'Remove policy',
          content: (
            <Stack>
              <Body1>{`Remove policy ${policy.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          ),
        })
        .then(() => dispatch(removePolicyThunk(body)).unwrap().then(removePolicies))
        .catch(() => {});
      },
      [removePolicies],
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {          
          onClose();
          setPolicy({
            name,
          });
          showEditor();
        },
        [setPolicy],
      );

      return (
        <AddNewField label="Add New Policy" id="new_policy" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit policy',
        Icon: SvgEdit,
        handler: handleEdit,
      },
      {
        title: 'Remove policy',
        Icon: SvgDelete,
        handler: handleRemove,
      },
    ];

    return (
      <ToolbarSection title="Policies" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={Policy} items={policies} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((policy) => {
      console.log('policy updated', policy);
      if (policy) {
        debounce(() => {
          upsertPolicies(policy);
        }, 25);
      }
      hideEditor();
      setPolicy(null);
    }, [setPolicy, upsertPolicies]);

    if (!policy) {
      return;
    }

    return (
      <PolicyEditor entry={policy} onClose={handleClose} company={company} readonly={readonly} />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default PoliciesView;
