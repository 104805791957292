import { Edit, TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { ASSET_NAMES } from '@pw/consts/asset';
import { useMemo } from 'react';

function AssetThingStorageItem({ item, onEdit, display, selected = false }) {
  const {
    thing_record_id,
    thing = {},
    entry_quantity,
    available_quantity,
    expiry,
    compliance,
  } = item;
  const { unit, size, capacity } = thing?.properties ?? {};
  const cardStyle = useMemo(
    () => (onEdit ? '' : selected ? 'card-tab-confirmed' : 'card-tab-pending'),
    [selected, onEdit],
  );
  return (
    <Box className='card' action={ASSET_NAMES['22']}>
      <Box className={`card-tab ${cardStyle}`}>
        {selected && <TaskOutlined className='check' />}
      </Box>
      <Box className='card-content'>
        <IDDisplay value={thing_record_id} />
        <NameDisplay label='Thing' name={thing?.name} />
        <AmountDisplay amount={entry_quantity} unit={unit} />
        <AmountDisplay
          label='Available'
          amount={available_quantity}
          unit={unit ?? size?.unit ?? capacity?.unit}
        />
        <DutyPaidDisplay value={compliance?.duty_suspended} />
        <DateDisplay date={expiry} />
        {display && display(item)}
      </Box>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 'min-content',
          paddingRight: '1rem',
        }}
      >
        {onEdit && (
          <IconCircleButton onClick={() => onEdit(item)}>
            <Edit />
          </IconCircleButton>
        )}
      </Stack>
    </Box>
  );
}

export default AssetThingStorageItem;
