import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikMeasuresField, FormikNumberField } from '@pw/components/Forms/FormikForm';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

// /*
// // How much to take from each storage item
// export interface SKUStorageItem {
//   amount: number;
//   asset_type: string;
//   asset_id: string;
//   rw_asset_id: string;
// }
//
// open={!!selectedAsset}
// item={selectedAsset}
// items={assets}
// upsert={handleAssetUpdate}
// onClose={handleAssetUpdate}

function StorageSetupModal({
	item,
	open,
	onClose,
	left = 0,
	unit = null,
	title = 'Allocation',
}) {
	// asset_id: path,
	// 	asset_type,
	// 	rw_asset_id,
	// 	unique_location_id,
	// 	processed,
	// 	amount: 0,
	// 	unit: itemUnit,

	console.log('Storage selection item', unit, item, left);

	const { amount, rw_asset_id } = item ?? {};
	const amountVal = Number(amount ?? 0) > 0 ? Number(amount) : '';
	const converter = useConverter();

	const changeSet = useMemo(
		() => ({
			amount: [
				unit && amountVal ? converter.cx(amountVal, null, unit) : amountVal,
				yup.number().min(0, 'Must be positive!').required('Amount required!'),
			],
		}),
		[converter, amount, unit],
	);

	const handleSubmit = useCallback(
		(values) => {
			console.log('Storage item', item, values);

			const thingStorage = {
				...item,
				// processed: ASSET_PROCESSED_STATUS.CONFIRMED,
				amount: unit ? converter.cx(values.amount, unit) : values.amount,
			};
			onClose(thingStorage);
		},
		[converter, item],
	);

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title={rw_asset_id}>
			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={1}>
					{!!unit && (
						<FormikMeasuresField
							label='Amount'
							placeholder={left?.toString() ?? ''}
							name='amount'
							unit={unit}
							fullWidth
						/>
					)}
					{!unit && (
						<FormikNumberField label='Items' placeholder={left?.toString() ?? ''} name='amount' fullWidth />
					)}

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={() => onClose()}
							color='secondary'
						>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Save
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>
		</ModalWithClose>
	);
}

export default StorageSetupModal;
