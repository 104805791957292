import {
  AddBox,
  CheckCircle,
  FilterAltOffOutlined,
  FilterAltOutlined,
  FormatColorFill,
} from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import TitledButton from '@pw/components/Buttons/TitledButton';
import PrintAssetList from '@pw/components/InventorySelector/PrintAssetList';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidSummary from '@pw/components/LiquidSummary';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import ScanQR from '@pw/components/ScanQR';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import StorageSetupModal from '@pw/components/ThingSelector/modals/StorageSetup';
import SelectedThing from '@pw/components/ThingSelector/SelectedThing';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS, THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { useActiveItemHook } from '@pw/redux/containers/App';
import { EQUAL } from '@pw/utilities/comp';
import useFilters, { Filters } from '@pw/utilities/hooks/logic/useFilters';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PolicyListComponent from '../admin/Policy/PolicyListItem';
import ReportAssetList from './ReportAssetList';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';

function InventorySelector({
  title,
  sectionTitle,
  types,
  list,
  onAdd,
  onEdit,
  onRemove,
  display,
  onQrScan,
  single = false,
  summary = true,
  disabled = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { readonly } = useContext(FormikContext);
  const [fetchAsset, { isLoading }] = useAssetLazyQuery();

  const selectItem = useCallback(
    async (item) => {
      try {
        const { type, _id } = item;
        // Handle asset
        if (type && _id) {
          if (list?.find((i) => i._id === _id) || (single && list.length > 0)) {
            enqueueSnackbar('Asset already added!', { variant: 'info' });
            return false;
          }
          const assetFromServer = await fetchAsset({ id: _id });
          onAdd(assetFromServer);
          return;
        }
        enqueueSnackbar('Inventory type not supported!', { variant: 'error' });
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: 'error' });
      }
    },
    [enqueueSnackbar, fetchAsset, list, onAdd, single],
  );

  return (
    <Stack className='section'>
      {title && <H5 className='section-header'>{title}</H5>}
      {sectionTitle && <H6 className='section-title'>{sectionTitle}</H6>}

      <Collapse in={!(single && list.length === 1)} unmountOnExit>
        <GeneralSearch
          label='Name'
          disabled={readonly || disabled}
          types={types}
          onItemClick={selectItem}
          multiple={!single}
          count={list.length}
          buttonIcon={<AddBox />}
        />
      </Collapse>
      {!isLoading ? (
        <>
          {list?.length > 0 && (
            <Box className='inventory'>
              {!!onQrScan && list.length > 0 && (
                <FlexBox className='inventory-header'>
                  <ScanQR onSuccess={onQrScan} closeOnSuccess />
                </FlexBox>
              )}
              <Stack spacing={0} className='list'>
                {list.map((asset) => (
                  <AssetListComponentV2
                    key={asset?._id}
                    item={asset}
                    onEdit={onEdit}
                    onRemove={onRemove}
                    display={display}
                    selected={(i) =>
                      i.processed === ASSET_PROCESSED_STATUS.CONFIRMED
                    }
                  />
                ))}
              </Stack>
              {summary && (
                <FlexBox className='inventory-footer'>
                  <Stack spacing={0.75}>
                    <AmountDisplay label='Items' amount={list.length} />
                  </Stack>
                </FlexBox>
              )}
            </Box>
          )}
          {list?.length === 0 && <Body3>None</Body3>}
        </>
      ) : (
        <LoadingSmall />
      )}
    </Stack>
  );
}

export function AssetResults({ items, refs, onEdit, onRemove, display }) {
  return (
    <>
      {items.map((asset, index) => (
        <AssetListComponentV2
          key={asset._id ?? asset.asset ?? asset.id}
          item={asset}
          onEdit={onEdit}
          onRemove={onRemove}
          display={display}
          selected={(i) => i.processed === ASSET_PROCESSED_STATUS.CONFIRMED}
          ref={refs[index]}
        />
      ))}
    </>
  );
}

export function ThingResults({ items, refs, onEdit, onRemove, display }) {
  return (
    <>
      {items.map((thing, index) => (
        <SelectedThing
          item={thing}
          onEdit={thing?.pick_id ? null : onEdit}
          onRemove={onRemove}
          key={thing._id ?? thing.id}
          display={display}
          selected={(i) => i.processed === INVENTORY_STATUS.CONFIRMED}
          ref={refs[index]}
        />
      ))}
    </>
  );
}

export function PolicyResults({ items, refs, onEdit, onRemove, display }) {
  return (
    <>
      {items.map((policy, index) => (
        <PolicyListComponent
          item={policy}
          onEdit={onEdit}
          onRemove={onRemove}
          key={policy.path}
          display={display}
          ref={refs[index]}
        />
      ))}
    </>
  );
}

function InventoryList(props) {
  const {
    things = [[]],
    // policies = [[]],
    assets = [[]],
    extendedSummary = false,
    dutyPaidSummary = false,
  } = props;
  const [thingList, onEditThing, onRemoveThing, displayThing] = things;
  // const [policyList, onEditPolicy, onRemovePolicy, displayPolicy] = policies;
  const [assetList, onEditAsset, onRemoveAsset, displayAsset] = assets;

  const parentRef = React.createRef();

  const thingChildRefs = thingList.map(() => React.createRef());
  const assetChildRefs = assetList.map(() => React.createRef());
  // const policyChildRefs = policyList.map(() => React.createRef());

  const { activeItem } = useActiveItemHook();

  useEffect(() => {
    if (activeItem) {
      const thingIndex = thingList.findIndex((i) => EQUAL.thing(i, activeItem));
      // console.log('Thing index', thingIndex);
      if (thingIndex >= 0) {
        const ref = thingChildRefs[thingIndex];
        // console.log('thing scroll', ref.current?.getBoundingClientRect());

        parentRef.current?.scrollTo({
          top: ref.current?.getBoundingClientRect().top,
          behavior: 'auto',
        });
        return;
      }

      // const policyIndex = policyList.findIndex((i) => EQUAL.policy(i, activeItem));
      // if (policyIndex >= 0) {
      //   const ref = policyChildRefs[policyIndex];
      //
      //   parentRef.current?.scrollTo({
      //     top: ref.current?.getBoundingClientRect().top,
      //     behavior: 'auto',
      //   });
      //   return;
      // }

      const assetIndex = assetList.findIndex((i) => EQUAL.asset(i, activeItem));
      // console.log('Asset index', assetIndex);
      if (assetIndex >= 0) {
        const ref = assetChildRefs[assetIndex];
        // console.log('Asset scroll', ref.current?.getBoundingClientRect());
        parentRef.current?.scrollTo({
          top: ref.current?.getBoundingClientRect().top,
          behavior: 'auto',
        });
      }
    }
    // thingChildRefs.forEach((ref) => console.log(ref.current?.getBoundingClientRect()));
    // assetChildRefs.forEach((ref) => console.log(ref.current?.getBoundingClientRect()));
  }, [parentRef, thingChildRefs, assetChildRefs, thingList, assetList]);

  return (
    <>
      <Box className='inventory-contents' ref={parentRef}>
        <Stack className='list'>
          {thingList.length > 0 && (
            <ThingResults
              items={thingList}
              refs={thingChildRefs}
              onEdit={onEditThing}
              onRemove={onRemoveThing}
              display={displayThing}
            />
          )}
          {assetList.length > 0 && (
            <AssetResults
              items={assetList}
              refs={assetChildRefs}
              onEdit={onEditAsset}
              onRemove={onRemoveAsset}
              display={displayAsset}
            />
          )}
        </Stack>
      </Box>
      <LiquidSummary
        things={thingList}
        assets={assetList}
        force
        extendedSummary={extendedSummary}
        dutyPaidSummary={dutyPaidSummary}
      />
    </>
  );
}

export const InventorySelectorV2 = React.memo((props) => {
  const { enqueueSnackbar } = useSnackbar();
  const hasPermission = usePermissions();
  const [thing, setThing] = useState(null);

  const {
    title,
    types,
    things,
    assets,
    onAdd,
    onQrScan,
    onProcessAll,
    onFill,
    onUpload,
    enable = true,
    loading = false,
    single = false,
    report = true,
    extendedSummary = false,
    dutyPaidSummary = false,
  } = props;

  const [
    thingList,
    onEditThing,
    onRemoveThing,
    displayThing,
    onCopyAndRemoveThing,
  ] = things ?? [[]];
  // const [policyList, onEditPolicy, onRemovePolicy, displayPolicy] =
  // policies ?? [[]];
  const [assetList, onEditAsset, onRemoveAsset, displayAsset] = assets ?? [[]];
  // TODO: think about permission
  const canApprove = hasPermission(PermissionKey.CASK, PermissionType.APPROVE);

  const filterOptions = [
    {
      value: 'none',
      label: 'None',
    },
    {
      value: 'name',
      getter: (x) => x?.rw_asset_id ?? x?.name,
      label: 'Name',
    },
    {
      value: 'reference',
      getter: (x) => x?.properties?.reference,
      label: 'Reference',
    },
  ];

  const [showFilter, setShowFilter] = useState(false);

  const [filteredAssets, filteredThings, [setFilter, setSort, setDirection]] =
    useFilters(showFilter, thingList, assetList, filterOptions);

  /**
   * Add the selected item
   * @type {(function(*): void)|*}
   */
  const selectItem = useCallback(
    (item) => {
      console.log('Adding item', item);
      if (single) {
        if (thingList.length === 1 || assetList.length === 1) {
          enqueueSnackbar('Item already added!', { variant: 'warning' });
          return;
        }
      }

      const existingThing = thingList?.find((i) => {
        const { _id } = i;
        return _id === item._id;
      });
      if (existingThing) {
        enqueueSnackbar('Thing already added!', { variant: 'info' });
        return;
      }

      // const existingPolicy = policyList?.find((i) => {
      //   const { path, policy_id } = i;
      //   return (path ?? policy_id) === item.policy_id;
      // });
      // if (existingPolicy) {
      //   enqueueSnackbar('Policy already added!', { variant: 'info' });
      //   return;
      // }

      // console.log('Assets', assetList);
      const existingAsset = assetList?.find((i) => {
        const { path, asset_id } = i;
        return (path ?? asset_id) === item.path;
      });

      // console.log('Existing item', existingAsset);
      if (existingAsset) {
        enqueueSnackbar('Asset already added!', { variant: 'info' });
        return;
      }
      onAdd(item);
    },
    [thingList, assetList, onAdd, single],
  );

  const count = useMemo(
    () => assetList.length + thingList.length,
    [assetList, thingList],
  );

  const hasUnits = useMemo(
    () =>
      ![THING_TYPES.TRACKED, THING_TYPES.FINISHED, THING_TYPES.WIP].includes(
        thing?.type,
      ),
    [thing?.type],
  );

  const onUpdateCount = useCallback(
    (updateInfo) => {
      // Initialize remaining quantity with the updated amount or 0 if not provided
      let remainingQuantity = Number(updateInfo?.amount ?? 0);

      // If remaining quantity is 0, remove the Thing and return
      if (remainingQuantity === 0) {
        onCopyAndRemoveThing(thing, [], thing?.entries);
        return;
      }
      // Arrays to store updated and removed entries
      const updatedEntries = [];
      const removedEntries = [];

      // Loop through each entry in the Thing
      thing?.entries?.forEach((entry) => {
        // Calculate the amount to remove from this entry
        let removedEntryAmount = Number(entry?.amount ?? 0) - remainingQuantity;

        if (removedEntryAmount < 0) {
          enqueueSnackbar('Please enter valid remaining amount!', {
            variant: 'warning',
          });
          return;
        }

        console.log(
          Number(entry?.amount ?? 0),
          'removedEntryAmount ',
          removedEntryAmount,
        );

        // If there's an amount to remove, update the entry
        const updatedStorages = [];
        const removedStorages = [];

        // Loop through each storage within the entry
        entry?.storage?.forEach((storage) => {
          // Calculate the remaining amount for this storage
          let remainingStorageAmount = Math.max(
            Number(storage?.amount ?? 0) - remainingQuantity,
            0,
          );

          // Calculate the amount removed from this storage
          const removedCount = Math.max(
            Number(storage?.amount ?? 0) - remainingStorageAmount,
            0,
          );

          // Update removed and updated storages arrays
          if (removedCount > 0) {
            removedStorages.push({
              ...storage,
              amount: removedCount,
            });
          }

          if (remainingStorageAmount > 0) {
            updatedStorages.push({
              ...storage,
              amount: remainingStorageAmount,
            });
          }

          // Update remaining quantity after processing this storage
          remainingQuantity = Math.max(
            remainingQuantity - Number(storage?.amount ?? 0),
            0,
          );
        });

        // Update removed and updated entries arrays
        if (removedEntryAmount > 0) {
          removedEntries.push({
            ...entry,
            amount: removedEntryAmount,
            storage: updatedStorages,
          });
        }
        updatedEntries.push({
          ...entry,
          amount: Number(updateInfo?.amount ?? 0) - remainingQuantity,
          storage: removedStorages,
        });
      });

      // Call the function to copy and remove the Thing with updated and removed entries
      onCopyAndRemoveThing(thing, updatedEntries, removedEntries);
    },
    [enqueueSnackbar, onCopyAndRemoveThing, thing],
  );

  return (
    <Stack spacing={1} className='section'>
      {title && <H5 className='section-header'>{title}</H5>}
      {enable && (
        <GeneralSearch
          label='Name'
          types={types}
          scan={!onQrScan}
          onItemClick={selectItem}
          multiple={!single}
          count={count}
          buttonIcon={<AddBox />}
        />
      )}

      <Stack className='inventory'>
        <Box className='inventory-header'>
          {!loading && (
            <>
              {enable && !!onQrScan && (
                <ScanQR
                  onSuccess={onQrScan}
                  closeOnSuccess
                  variant='outlined'
                  color='secondary'
                  width={24}
                  height={24}
                  withLabel
                  Component={TitledButton}
                />
              )}
              {(!enable || !onQrScan) && <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>}
              <Stack direction='row' spacing={1}>
                {enable && canApprove && onProcessAll && (
                  <TitledButton handleClick={onProcessAll} label='Confirm'>
                    <CheckCircle height={24} width={24} />
                  </TitledButton>
                )}
                {enable && onFill && (
                  <TitledButton handleClick={onFill} label='Fill'>
                    <FormatColorFill height={24} width={24} />
                  </TitledButton>
                )}
                {enable && onUpload && (
                  <TitledButton handleClick={onUpload} label='Upload'>
                    <UploadFileIcon height={24} width={24} />
                  </TitledButton>
                )}
                {report && assetList.length > 0 && (
                  <ReportAssetList assets={assetList} />
                )}
                {filteredAssets.length > 0 && (
                  <PrintAssetList assets={filteredAssets} />
                )}
                <TitledButton
                  handleClick={() => setShowFilter((x) => !x)}
                  label='Filter'
                >
                  {showFilter && (
                    <FilterAltOffOutlined height={24} width={24} />
                  )}
                  {!showFilter && <FilterAltOutlined height={24} width={24} />}
                </TitledButton>
              </Stack>
            </>
          )}
          {loading && <LoadingSmall />}
        </Box>
        <Filters
          showFilter={showFilter}
          setFilter={setFilter}
          setSort={setSort}
          setDirection={setDirection}
          options={filterOptions}
        />
        {count > 0 && (
          <InventoryList
            things={[filteredThings, onEditThing, onRemoveThing, displayThing]}
            assets={[filteredAssets, onEditAsset, onRemoveAsset, displayAsset]}
            extendedSummary={extendedSummary}
            dutyPaidSummary={dutyPaidSummary}
          />
        )}
        {count === 0 && (
          <Box p={2} className='inventory-footer'>
            <Body3>None</Body3>
          </Box>
        )}
        {!!thing && (
          <StorageSetupModal
            open={!!thing}
            unit={hasUnits ? thing?.unit : null}
            item={thing}
            onClose={(v) => {
              if (v) {
                onUpdateCount(v);
              }
              setThing(null);
            }}
            title='Remaining Quantity'
          />
        )}
      </Stack>
    </Stack>
  );
});

export default InventorySelector;
