import { UNIT_FORMAT_TYPES } from '@pw/consts/company';
import { UNIT, UNITS } from '@pw/consts/units';
import { useSelector } from 'react-redux';
import {
	activeCompany,
	activeUser,
	selectAccountChain,
	selectAccountEmail,
	selectAccountId,
	selectAccountIdentity,
	selectAccountLastAccess,
	selectAccountLocation,
	selectAccountName,
	selectAccountPasskeys,
	selectAccountPerms,
	selectAccountPlatform,
	selectAccountStatus,
	selectAccountTokenActive,
	selectAccountUsername,
	selectAllCompanies,
	selectBaseUnits,
	selectCompanyCC,
	selectCompanyFacilities,
	selectCompanyHasExternalInventory,
	selectCompanyHasOwnInventory,
	selectCompanyId,
	selectCompanyLiquidTypes,
	selectCompanyName,
	selectCompanyOwners,
	selectCompanyPartners,
	selectCompanyPermissions,
	selectCompanyPolicies,
	selectCompanyPriceCurves,
	selectCompanyRoles,
	selectCompanySensors,
	selectCompanySKUs,
	selectCompanySpecifications,
	selectCompanyTeams,
	selectCompanyThings,
	selectCompanyType,
	selectCompanyUsers,
	selectIsAccountActive,
	selectIsBrokerCompany,
	selectIsUsUser,
	selectLiquidParsedData,
	selectUnitFormat,
} from './selectors';

export const useAccount = () => useSelector(activeUser);
export const useAccountId = () => useSelector(selectAccountId);
export const useAccountIdentity = () => useSelector(selectAccountIdentity);
export const useAccountName = () => useSelector(selectAccountName);
export const useAccountTokenActive = () =>
  useSelector(selectAccountTokenActive);
export const useAccountUsername = () => useSelector(selectAccountUsername);
export const useAccountEmail = () => useSelector(selectAccountEmail);
export const useAccountLocation = () => useSelector(selectAccountLocation);
export const useAccountPasskeys = () => useSelector(selectAccountPasskeys);
export const useAccountLastAccess = () => useSelector(selectAccountLastAccess);
export const useAccountStatus = () => useSelector(selectAccountStatus);
export const useAccountPlatform = () => useSelector(selectAccountPlatform);
export const useAccountChain = () => useSelector(selectAccountChain);
export const useIsAccountActive = () => useSelector(selectIsAccountActive);
export const useAccountPerms = () => useSelector(selectAccountPerms);
export const useLiquidParsedData = () => useSelector(selectLiquidParsedData);

export const useCurrentCompany = () => useSelector(activeCompany);
export const useCompanyCountry = () => useSelector(selectCompanyCC);
export const useCompanyId = () => useSelector(selectCompanyId);
export const useCompanyName = () => useSelector(selectCompanyName);
export const useCompanyUsers = () => useSelector(selectCompanyUsers);
export const useAllCompanies = () => useSelector(selectAllCompanies);
export const useCompanySKUs = () => useSelector(selectCompanySKUs);
export const useCompanyThings = () => useSelector(selectCompanyThings);
export const useCompanyPolicies = () => useSelector(selectCompanyPolicies);
export const useCompanySpecifications = () =>
  useSelector(selectCompanySpecifications);
export const useCompanyLiquidTypes = () =>
  useSelector(selectCompanyLiquidTypes);
export const useCompanySensors = () => useSelector(selectCompanySensors);
export const useCompanyFacilities = () => useSelector(selectCompanyFacilities);
export const useCompanyPriceCurves = () =>
  useSelector(selectCompanyPriceCurves);

export const useCompanyPartners = () => useSelector(selectCompanyPartners);
export const useCompanyOwners = () => useSelector(selectCompanyOwners);
export const useCompanyRoles = () => useSelector(selectCompanyRoles);
export const useCompanyTeams = () => useSelector(selectCompanyTeams);
export const useIsBrokerCompany = () => useSelector(selectIsBrokerCompany);
export const useCompanyType = () => useSelector(selectCompanyType);
export const useCompanyHasOwnInventory = () =>
  useSelector(selectCompanyHasOwnInventory);
export const useCompanyHasExternalInventory = () =>
  useSelector(selectCompanyHasExternalInventory);
export const useCompanyPermissions = () =>
  useSelector(selectCompanyPermissions);

export const useIsUsUser = () => useSelector(selectIsUsUser);

export const useIsUnitFormatImperial = () =>
  UNIT_FORMAT_TYPES.Imperial === useSelector(selectUnitFormat);

export const usePreferredUnits = () => {
  const state = useSelector(selectBaseUnits);
  return {
    [UNIT.LIQ]: state?.[UNIT.LIQ] || UNITS.liters,
    [UNIT.MAS]: state?.[UNIT.MAS] || UNITS.kilograms,
    [UNIT.ALC]: state?.[UNIT.ALC] || UNITS.abv,
    [UNIT.TMP]: state?.[UNIT.TMP] || UNITS.celsius,
    [UNIT.TIM]: state?.[UNIT.TIM] || UNITS.days,
    [UNIT.BL]: state?.[UNIT.BL] || UNITS.liters,
    [UNIT.LA]: state?.[UNIT.LA] || UNITS.liters,
  };
};
