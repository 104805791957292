import * as yup from 'yup';

export function locationFields(fields = {}) {
	const {
		enable = !!fields?.location_id,
		location_id = '',
		bay_id = '',
		row_id = '',
		level_id = '',
	} = fields;

	console.log('Location>', enable, location_id, bay_id, row_id, level_id);

	return {
		enable: [enable, yup.bool()],
		location_id: [
			location_id,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Facility is required!'),
				otherwise: (schema) => schema,
			}),
		],
		bay_id: [
			bay_id,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Bay is required!'),
				otherwise: (schema) => schema,
			}),
		],
		row_id: [row_id, yup.string()],
		level_id: [level_id, yup.string()],
	};
}
