import * as yup from 'yup';

export function addressFields(params) {
	return {
		enable: [!!params, yup.bool()],
		name: [params?.name ?? '', yup.string()],
		address_1: [
			params?.address_1 ?? '',
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Address line 1 is required!'),
				otherwise: (schema) => schema,
			}),
		],
		address_2: [params?.address_2, yup.string()],
		address_3: [params?.address_3, yup.string()],
		city: [
			params?.city,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('City is required!'),
				otherwise: (schema) => schema,
			}),
		],
		state: [params?.state, yup.string()],
		country: [
			params?.country,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Country is required!'),
				otherwise: (schema) => schema,
			}),
		],
		post_code: [
			params?.post_code,
			yup.string().when('enable', {
				is: true,
				then: (schema) => schema.required('Post code is required!'),
				otherwise: (schema) => schema,
			}),
		],
		exciseId: [params?.exciseId, yup.string()],
		warehouseId: [params?.warehouseId, yup.string()],
	};
}
