function SvgDisplay(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M13.2 46.5L1 31.5l12.2-15h34.567C55.9 16.5 62 22.929 62 31.5s-6.1 15-14.233 15z'
        fill='#cfe'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgDisplay;
