import { listTeams, listUsers } from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const searchUserThunk = createAsyncThunk(
  'search/user',
  async (params, { rejectWithValue }) => {
    try {
      return await listUsers(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const searchTeamThunk = createAsyncThunk(
  'search/team',
  async (params, { rejectWithValue }) => {
    try {
      return await listTeams(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
