import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import SvgAddNew from '@pw/components/icons/AddNew';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import debounce from '@pw/utilities/debounce';
import { useCallback, useState } from 'react';

function AddNewField(props) {
  const { label, id, onAddNew, className = 'field', ...rest } = props;

  const [value, setValue] = useState('');

  const addNew = useCallback(() => {
    onAddNew(value);
    debounce(() => setValue(''), 250);
  }, [value]);

  const clear = useCallback(() => {
    setValue('');
  }, [setValue]);

  return (
    <TextField
      label={label}
      id={id}
      variant='filled'
      className={className}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') e.preventDefault();
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            {value && (
              <FlexBox>
                <IconCircleButton
                  aria-label='Remove value'
                  onClick={clear}
                  edge='end'
                >
                  <CloseIcon />
                </IconCircleButton>
                <IconCircleButton
                  aria-label='Add team'
                  onClick={addNew}
                  edge='end'
                  color='secondary'
                >
                  <SvgAddNew />
                </IconCircleButton>
              </FlexBox>
            )}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

export default AddNewField;
