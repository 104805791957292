import { Collapse } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import ContactSearch from '@pw/components_v2/search/contact';
import { upsertSpecificationThunk } from '@pw/redux/thunks/specification';
import { useSpecificationLazyQuery } from '@pw/utilities/hooks/service/useSpecificationQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import SpecEntryField from './SpecEntry';

function SpecEditor({ entry, company, readonly, onClose }) {
  const dispatch = useDispatch();

  // Load the item on demand
  const [load, { isLoading, cancel }] = useSpecificationLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = useMemo(() => ({
    name: [item?.name ?? '', yup.string().required('Name is required!')],
    description: [item?.description ?? '', yup.string()],
    entries: [
      item?.entries ?? [],
      yup.array().of(yup.object()).min(1, 'Entry is required'),
    ],
    client: [item?.client, yup.object()],
  }), [item]);

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?._id) {
      load({ id: entry._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry);
    }
  }, [entry]);

  const handleSubmit = useCallback(
    (values) => {
      console.log('Submitting', values);
      dispatch(
        upsertSpecificationThunk({
          ...(item ?? {}),
          ...values,
          company_id: company?._id,
        }),
      )
        .unwrap()
        .then(onClose);
    },
    [item, onClose],
  );

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.name}</H5>
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className='form-content' in={!isLoading} unmountOnExit>
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
          <Stack className='form' spacing='2rem'>
            <FormikTextField label='Description' name='description' fullWidth />
            <SpecEntryField initialValues={item?.entries} readonly={readonly} />

            <ContactSearch
              label='Client'
              field='client'
            />

            <FormButtons onCancel={onClose} readonly={readonly} />
          </Stack>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default SpecEditor;
