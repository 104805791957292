import { Box } from '@mui/material';
import AssetForm from '@pw/components/AssetForm/AssetForm';
import withDialogCard from '@pw/components/Cards/DialogCard';
import LatestAddedAssets from '@pw/components/LatestAdded/LatestAddedAssets';
import ItemSkeleton from '@pw/components/sekeletons/ItemSkeleton';
import SkeletonSummary from '@pw/components/sekeletons/SkeletonSummary';
import AssetSummary from '@pw/components/summary/AssetSummary';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import styles from '@pw/styles/content.styles';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import useAssetQuery from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import {
  // useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import AssetView from './AssetView';

const AssetFormHandlerImpl = withDialogCard(
  ({ children, assetType, readonly, onSave, ...rest }) => {
    const navigate = useNavigate();
    const [target, scroll] = useScrollTarget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleSave = (mutatedAsset) => {
      console.log('Mutated asset', mutatedAsset);
      const route = ASSET_EDIT_URL?.[assetType];
      navigate(`${route}/${mutatedAsset?._id}`, { replace: true });
      onSave?.(mutatedAsset);
    };

    return (
      <Box sx={styles} className='root' ref={target}>
        <AssetForm
          onSave={handleSave}
          assetType={assetType}
          readonly={readonly}
          {...rest}
        >
          {children}
        </AssetForm>
      </Box>
    );
  },
);

function NewForm({ changeSetGenerator, onInit, ...rest }) {
  let [searchParams] = useSearchParams();

  const { assetType, assetLabel } = rest;

  // AI stuff disabled for now
  // const [suggested, setSuggested] = useState(null);
  let clone = searchParams.get('clone');

  const formValues = useMemo(() => {
    // See if there is a clone parameter
    let obj = {};
    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);

        if (onInit) {
          onInit(obj);
        } else {
          onInit({});
        }
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }
    // if (suggested) {
    //   const { properties = {} } = suggested;
    //   obj = { ...suggested, properties: { ...properties, [suggested.asset_type]: properties.tags ?? [] } };
    // }
    return { ...obj, type: assetType };
  }, [assetType, clone]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  usePageTitleHook(`Create ${assetLabel}`);

  return <AssetFormHandlerImpl changeSet={changeSet} {...rest} />;
}

function LoadAsset({ changeSetGenerator, onInit, assetLabel, ...rest }) {
  const { id, assetType } = rest;
  const { data: entity, isError, refetch, isLoading } = useAssetQuery({ id });
  const { readonly } = useReadOnly(entity);
  usePageTitleHook(`${assetLabel} ${entity?.name ?? '...'}`);
  // Generate the change set
  const formValues = useMemo(() => {
    // Specific initializer
    if (onInit) {
      onInit(entity);
    }
    return { ...(entity ?? {}), type: assetType };
  }, [entity, assetType]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  const isAssetEditable = useMemo(
    () => [ASSET_TYPES.MACHINE].includes(assetType),
    [assetType],
  );

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <>
      {isLoading && (
        <>
          <SkeletonSummary />
          <ItemSkeleton />
        </>
      )}

      {isError && (
        <NewForm
          changeSetGenerator={changeSetGenerator}
          onInit={onInit}
          assetLabel={assetLabel}
          {...rest}
        />
      )}
      {entity && changeSet && (
        <>
          <AssetSummary entity={entity} refresh={refreshData} />
          {isAssetEditable ? (
            <AssetFormHandlerImpl
              entity={entity}
              changeSet={changeSet}
              edit
              readonly={readonly}
              onSave={refreshData}
              assetLabel={assetLabel}
              {...rest}
            />
          ) : (
            <AssetView asset={entity}/>
          )}
        </>
      )}
    </>
  );
}

function AssetFormHandler(props) {
  const { id } = useParams();
  console.log('AssetFormHandler', id, props);
  return (
    <Box className='adaptive-content'>
      {id ? <LoadAsset id={id} {...props} /> : <NewForm {...props} />}
      <LatestAddedAssets assetType={props.assetType} />
    </Box>
  );
}

export default AssetFormHandler;
