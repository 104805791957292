import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import AssetSearch from '@pw/components_v2/search/asset';
import { ASSET_TYPES } from '@pw/consts/asset';
import { LIQUID_UNIT_OPTIONS, TEMPERATURE_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      machine: [initialValues?.machine, yup.object()],
      input_temperature: unitField(initialValues?.input_temperature),
      capacity: unitField(initialValues?.capacity),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack  sx={{ pt: '1rem' }} spacing={2}>
        <AssetSearch field="machine" label='Machine' types={[ASSET_TYPES.MACHINE]} />
        <FormikUnitField name="input_temperature" label="Input Temperature" options={TEMPERATURE_UNIT_OPTIONS} />
        <FormikUnitField name="capacity" label="Capacity" options={LIQUID_UNIT_OPTIONS} />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
