import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import DependentSKUModal from '@pw/components/SKUSelector/modals/DependentSKUModal';
import { H5, Overline } from '@pw/components/Typography';
import { THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import debounce from '@pw/utilities/debounce';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const {
		parts = [],
	} = fields;

	return {
		parts: [parts, yup.array().of(yup.object())],
	};
}


function PropertiesImpl() {
	const { setFieldValue, values } = useContext(FormikContext);

	const dependentThingProps = useMemo(
		() => ({
			title: 'Parts',
			filter: {
				thing_types: [THING_TYPES.PARTS],
			},
			initialThings: values?.parts,
			SKUModal: DependentSKUModal,
		}),
		[values?.parts],
	);

	const [[dependentThings], , , DependentInventory, DependentModals] =
		useInventorySelectorHook(dependentThingProps);

	useEffect(() => {
		console.log('Updating Things', dependentThings);
		debounce(() => setFieldValue('parts', dependentThings), 25);
	}, [dependentThings]);

	return (
		<Stack spacing={3}>
			<Divider><Overline>Properties</Overline></Divider>
			<DependentInventory />
			<DependentModals />
		</Stack>
	);
}

function Properties(props) {
	return (
		<FormikProvider path='properties'>
			<PropertiesImpl {...props} />
		</FormikProvider>
	);
}

function ThingPage(props) {
	const changeSetGenerator = (initialValues) => ({
		...thingSettingsFields(initialValues, THING_TYPES.PARTS),
		properties: propertyFields(initialValues.properties),
	});

	const handleBeforeSave = (entity) => entity;

	return (
		<ThingForm
			thingLabel='Part'
			thingType={THING_TYPES.PARTS}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</ThingForm>
	);
}

export default withAppLayout(ThingPage, {
	title: 'Parts',
});

