import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import PoliciesView from './PoliciesView';

function PolicyModal({ company, open, onClose }) {
  const title = `${company.name} Policies`;
  console.log('Policy modal', company.name);

  return (
    <ModalWithClose onClose={() => onClose()} open={open} title={title}>
      <PoliciesView company={company} /> 
    </ModalWithClose>
  );
}

export default PolicyModal;
