/**
 * a function to parse data from csv file
 * @param {File} file
 * @returns {Promise<Papa.ParseResult>}
 */
export function parseDataFromCsvFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onerror = reject;
		reader.onload = (e) => {
			async function read() {
				const Papa = await import('papaparse');
				const csv = e.target.result;
				const result = Papa.parse(csv, { header: true, skipEmptyLines: true });
				resolve(result);
			}
			read().finally();
		};
	});
}

/**
 * a function to check if file is excel file
 * @param {File} file uploaded file
 * @returns {boolean} true if file is excel file
 */
export function isExcelFile(file) {
	const fileExtension = file.name.split('.').pop();
	return fileExtension === 'xlsx' || fileExtension === 'xls';
}

/**
 * a function to parse data from excel file
 * @param {File} file
 * @returns {Promise<Papa.ParseResult>}
 */
export function parseDataFromExcelFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.onerror = reject;
		reader.onload = (e) => {
			async function read() {
				const Papa = await import('papaparse');
				const XLSX = await import('xlsx');

				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: 'array' });
				const firstSheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[firstSheetName];

				console.log('worksheet', worksheet);
				const csv = XLSX.utils.sheet_to_csv(worksheet);
				const result = Papa.parse(csv, { header: true, skipEmptyLines: true });

				console.log('result', result);
				resolve(result);
			}
			read().finally();
		};
	});
}
