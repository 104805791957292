import { combineReducers } from '@reduxjs/toolkit';
// Reducers
// 	SLICE as authorizationSliceName,
// 	reducer as authorizationReducer,
// } from '../containers/Authorization';
// import {
// 	reducer as companiesReducer,
// 	SLICE as companiesSliceName,
// } from '../containers/Companies';
// import {
// 	SLICE as networkSliceName,
// 	reducer as networkReducer,
// } from '../containers/NetworkSwitch';
// import {
// 	SLICE as notificationSliceName,
// 	reducer as notificationReducer,
// } from '../containers/Notifications';
// import {
// 	reducer as userReducer,
// 	SLICE as userSliceName,
// } from '../containers/User';
// import {
// 	reducer as appStateReducer,
// 	SLICE as appStateSliceName,
// } from '../containers/AppState';
// import {
// 	reducer as baseUnitsReducer,
// 	SLICE as baseUnitsSliceName,
// } from '../containers/BaseUnits';

// import {
// 	reducer as appReducer,
// 	SLICE as appSlice,
// } from '../containers/AppState';
// import {
// 	reducer as userReducer,
// 	SLICE as userSlice,
// } from '../containers/UserState';
// import {
// 	reducer as notificationsReducer,
// 	SLICE as notificationsSlice,
// } from '../containers/Notifications';

import { reducer as appReducer } from '@pw/redux/containers/App/slice';
import { reducer as userReducer } from '@pw/redux/containers/User/slice';
import { reducer as publicReducer } from '@pw/redux/containers/Public/slice';

// import { reducer as notificationsReducer } from '../containers/Notifications/slice';

import { SLICE as appSlice } from '@pw/redux/containers/App/consts';
import { SLICE as userSlice } from '@pw/redux/containers/User/consts';
import { SLICE as publicSlice } from '@pw/redux/containers/Public/consts';

// import { SLICE as notificationsSlice } from '../containers/Notifications/consts';

const rootReducer = combineReducers({
	[appSlice]: appReducer,
	[userSlice]: userReducer,
	[publicSlice]: publicReducer,
	// [notificationsSlice]: notificationsReducer,
});

export default rootReducer;
