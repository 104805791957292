import { Stack } from '@mui/material';
import SingleSelect from '@pw/components_v2/elements/SingleSelect';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import FormikContext from '@pw/context/FormikContext';
import { useContext, useMemo } from 'react';

function LedgerSearch({ label = 'Ledger', field = 'ledger' }) {
  const { values } = useContext(FormikContext);
  const search = useMemo(
    () => ({
      [SEARCH_TYPES.LEDGERS]: {},
    }),
    [],
  );

  const { _id, name, description } = values?.[field] ?? {};

  return (
    <SingleSelect search={search} label={label} field={field}>
      {_id && (
        <Stack>
          <EntryTitle value={name} />
          <EntryLabel value={description} />
        </Stack>
      )}
    </SingleSelect>
  );
}

export default LedgerSearch;
