import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';

function ConfirmationDialog({ open, options, onCancel, onConfirm, onClose }) {
	const {
		title,
		content,
		confirmationText = 'Confirm',
		cancellationText = 'Cancel',
		dialogProps = {},
		confirmationButtonProps = {},
		cancellationButtonProps = {},
		titleProps = {},
		contentProps = {},
		hideCancelButton = false,
		buttonOrder = ['cancel', 'confirm'],
	} = options;

	const dialogActions = buttonOrder.map((buttonType) => {
		if (buttonType === 'cancel') {
			return (
				!hideCancelButton && (
					<TextButton
						size='small'
						key='cancel'
						{...cancellationButtonProps}
						handleClick={onCancel}
						color='secondary'
					>
						{cancellationText}
					</TextButton>
				)
			);
		}

		if (buttonType === 'confirm') {
			return (
				<FilledButton
					key='confirm'
					size='small'
					{...confirmationButtonProps}
					handleClick={onConfirm}
					autoFocus
				>
					{confirmationText}
				</FilledButton>
			);
		}

		throw new Error(
			`Supported button types are only "confirm" and "cancel", got: ${buttonType}`,
		);
	});

	return (
		<ModalWithClose {...dialogProps} rootStyle="small-root" title={title} open={open} onClose={onClose}>
			<Stack spacing={2}>
				{content && <Box {...contentProps}>{content}</Box>}
				<Box className='action-buttons'>{dialogActions}</Box>
			</Stack>
		</ModalWithClose>
	);
}

export default ConfirmationDialog;
