export class UrlDataEncoder {
  static decodeData(data = '') {
    let obj;
    if (data) {
      try {
        const cv = Buffer.from(`${data}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode!', e);
      }
    }
    return obj;
  }
  static encodeData(data = {}) {
    const str = JSON.stringify(data);
    const base = Buffer.from(str).toString('base64');
    return base;
  }
}
