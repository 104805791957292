function SvgSearch(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M21.053 10.44c-.034 2.387-.774 4.524-2.285 6.38-.17.21-.172.328.022.52 1.63 1.616 3.25 3.243 4.873 4.865.192.19.36.393.334.684a.702.702 0 01-1.107.517c-.111-.077-.206-.18-.303-.276-1.594-1.592-3.19-3.182-4.776-4.783-.199-.201-.322-.207-.543-.028-1.999 1.617-4.297 2.389-6.857 2.26a10.14 10.14 0 01-9.536-8.553C.029 6.647 3.565 1.584 8.917.49 14.49-.648 19.996 3.153 20.91 8.774c.09.55.153 1.105.143 1.666zm-1.426-.003c-.002-4.688-3.736-8.718-8.725-8.718-4.854 0-8.722 3.853-8.727 8.716-.004 4.995 4.033 8.742 8.74 8.737 4.812-.006 8.714-3.916 8.712-8.735z'
        fill='#404040'
      />
    </svg>
  );
}
export default SvgSearch;
