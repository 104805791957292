import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import LiquidProperties, { liquidPropertyFields } from '@pw/components/Liquid/LiquidProperties';
import LiquidStatus from '@pw/components/Liquid/LiquidStatus';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import SKUField from '@pw/components/SKUSelector/SKUField';
import skuFields from '@pw/components/SKUSelector/skuFields';
import Switch from '@pw/components/SwitchComponent';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { FINISHED_GOODS_TYPES, THING_TYPES } from '@pw/consts/thing';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import match from '@pw/utilities/match';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function AssetModal({ open, asset, onClose }) {
  const converter = useConverter();

  const { rw_asset_id, properties = {}, asset_type, sku_id, sku, location, accounts } = asset ?? {};
  const {
    sku_id: asset_sku_id,
    sku: asset_sku,
    liquid = {},
    reference = '',
    imported = false,
    duty_paid = false,
  } = properties;

  console.log('Asset properties', asset_sku_id, asset_sku);

  const changeSet = useMemo(
    () => ({
      ...skuFields(sku_id, sku),
      location: locationFields(location),
      accounts: assetAccountsFields(accounts),
      // Specific properties
      ...match(asset_type, {
        [ASSET_TYPES.CASK]: {
          ...skuFields(asset_sku_id, asset_sku, 'asset_sku', 'asset_sku_id'),
          cask: [properties?.cask, yup.array().of(yup.string())],
          imported: [imported, yup.boolean()],
          duty_paid: [duty_paid, yup.boolean()],
          reference: [reference, yup.string()],
        },
        [ASSET_TYPES.IBC]: {
          ...skuFields(asset_sku_id, asset_sku, 'asset_sku', 'asset_sku_id'),
          ibc: [properties?.ibc, yup.array().of(yup.string())],
          imported: [imported, yup.boolean()],
          duty_paid: [duty_paid, yup.boolean()],
          reference: [reference, yup.string()],
        },
        [ASSET_TYPES.PALLET]: {
          ...skuFields(asset_sku_id, asset_sku, 'asset_sku', 'asset_sku_id'),
          pallet: [properties?.pallet, yup.array().of(yup.string())],
        },
        [ASSET_TYPES.TANKER]: {
          ...skuFields(asset_sku_id, asset_sku, 'asset_sku', 'asset_sku_id'),
          tags: [properties?.tags, yup.array().of(yup.string())],
        },
        _: {},
      }),
      // Liquid properties
      liquid: liquidPropertyFields(liquid, converter, true),
    }),
    [asset, converter],
  );

  console.log('Change set', changeSet);

  const handleSubmit = useCallback(
    (values) => {
      try {
        const newAsset = {
          ...asset,
          sku_id: values?.sku_id,
          sku: values?.sku,
          properties: {
            ...properties,
            sku_id: values?.asset_sku_id,
            sku: values?.asset_sku,
            ...match(asset_type, {
              [ASSET_TYPES.CASK]: {
                cask: values?.cask,
                reference: values?.reference,
                imported: values?.imported,
                duty_paid: values?.duty_paid,
              },
              [ASSET_TYPES.IBC]: {
                ibc: values?.ibc,
                reference: values?.reference,
                imported: values?.imported,
                duty_paid: values?.duty_paid,
              },
              [ASSET_TYPES.PALLET]: {
                pallet: values?.pallet,
                reference: values?.reference,
              },
              [ASSET_TYPES.TANKER]: {
                tags: values?.tags,
                imported: values?.imported,
                duty_paid: values?.duty_paid,
              },
              _: {},
            }),
            liquid: {
              ...liquid,
              ...values.liquid,
            },
          },
        };
        console.log('Upserting asset', newAsset);
        onClose(newAsset);
      } catch (e) {
        console.log('Failed to update', e);
      }
    },
    [asset, properties, asset_type, liquid, onClose],
  );

  // Only allow this type of finished goods sku
  const productSkuFilter = (sku) => {
    if (sku?.sku_type === THING_TYPES.FINISHED) {
      if (sku?.properties?.type === FINISHED_GOODS_TYPES.CONTAINER) {
        return sku;
      }
    }
    return null;
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={rw_asset_id}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Switch value={asset_type}>
            <Switch.Case condition={ASSET_TYPES.CASK}>
              <SKUField
                title='Product'
                skuTypes={[THING_TYPES.FINISHED]}
                itemFilter={productSkuFilter}
              />
              <Location name='location' />
              <H5>Cask Properties</H5>
              <SKUField
                title='Wood'
                skuTypes={[THING_TYPES.TRACKED]}
                tags='cask'
                field='asset_sku'
                idField='asset_sku_id'
                itemFilter={(item) => item?.properties?.asset_type === ASSET_TYPES.CASK}
              />
              <FormikTextField name='reference' label='Reference' fullWidth />
              <TagSelect name='cask' type={TAG_CATEGORY.ASSET} />
              <LiquidStatus />
              <LiquidProperties name='liquid' />
              <AssetAccounts />
            </Switch.Case>
            <Switch.Case condition={ASSET_TYPES.IBC}>
              <SKUField
                title='Product'
                skuTypes={[THING_TYPES.FINISHED]}
                itemFilter={productSkuFilter}
              />
              <Location name='location' />
              <H5>IBC Properties</H5>
              <SKUField
                title='Container'
                skuTypes={[THING_TYPES.TRACKED]}
                tags='ibc'
                field='asset_sku'
                idField='asset_sku_id'
                itemFilter={(item) => item?.properties?.asset_type === ASSET_TYPES.IBC}
              />
              <FormikTextField name='reference' label='Reference' fullWidth />
              <TagSelect name='ibc' type={TAG_CATEGORY.ASSET} />
              <LiquidStatus />
              <LiquidProperties name='liquid' />
              <AssetAccounts />
            </Switch.Case>
            <Switch.Case condition={ASSET_TYPES.PALLET}>
              <Location name='location' />
              <H5>Pallet Properties</H5>
              <SKUField
                title='Pallet'
                skuTypes={[THING_TYPES.TRACKED]}
                tags='pallet'
                field='asset_sku'
                idField='asset_sku_id'
                itemFilter={(item) => item?.properties?.asset_type === ASSET_TYPES.PALLET}
              />
              <TagSelect name='pallet' type={TAG_CATEGORY.ASSET} />
            </Switch.Case>
            <Switch.Case condition={ASSET_TYPES.TANKER}>
              <H5>Tanker Properties</H5>
              <SKUField
                title='Tanker'
                skuTypes={[THING_TYPES.TRACKED]}
                tags='tanker'
                field='asset_sku'
                idField='asset_sku_id'
                itemFilter={(item) => item?.properties?.asset_type === ASSET_TYPES.TANKER}
              />
              <TagSelect name='tags' type={TAG_CATEGORY.ASSET} />
              <LiquidStatus />
              <LiquidProperties name='liquid' useBulk={false} />
            </Switch.Case>
          </Switch>

          <Errors />
        </Stack>

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
            label='Cancel'
          />
          <FilledButton size='small' type='submit'>
            Save
          </FilledButton>
        </Box>
      </FormikForm>
    </ModalWithClose>
  );
}

export default AssetModal;
