import { useIsUsUser } from '@pw/redux/containers/User';
import useLilly from '@pw/utilities/hooks/ai/useLilly';

export const useAIDateConverter = () => {
  const lilly = useLilly();
  const isUS = useIsUsUser()

  const mapDates = async (dates) => {
    const mappedDates = await lilly.chat(
      { date: `determine based on whole list or assume ${isUS ? 'US date format' : 'international date formats'}`, parsed: 'parsed date in iso date format' },
      `Given the date list ${JSON.stringify(dates)}, output a json array as field dates`
    );
    console.log('mappedDates', mappedDates);
    if (mappedDates?.dates) {
      // Map the dates correctly..
      const parsedDates = {};
      mappedDates.dates.forEach((d) => {
        if (d.parsed) {
          try {
            d.parsed = new Date(d.parsed);
            parsedDates[d.date] = new Date(d.parsed).getTime();
          } catch (err) {
            console.log('Error', err);
          }
        }
      });
      return parsedDates;
    }
    return {};
  }

  return { mapDates };
};

export const useAIThingMapper = () => {
  const lilly = useLilly();

  const mapThings = async (thing, things) => {
    const mappedThings = await lilly.chat(
      { name: 'name of this thing', description: 'description of this thing', tags: 'array of tags' },
      `Find the closest matching thing from ${JSON.stringify(things)} for the thing ${thing}, if nothing sounds like it return empty array, output a json array of the matches as field thing_matches`
    );
    console.log('mappedThings', mappedThings);
    return mappedThings?.thing_matches ?? [];
  }

  return { mapThings: mapThings };
};
