import { ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import SearchField from '@pw/components/Search/SearchField';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { useCallback, useState } from 'react';
import ContactListItem from './ContactListItem';

function PartnerContactSearch({
  id,
  title,
  sectionTitle,
  label,
  onItemClick,
  buttonIcon,
  disabled = false,
  data = [],
}) {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  // const handleButtonClick = useCallback(
  //   (item) => {
  //     onItemClick?.(item);
  //     setShow(false);
  //   },
  //   [onItemClick],
  // );

  // // https://test.metacask.com:4430/cask/edit/c/c-t/123 => [c,c-t,123]
  // const handleOnQrRead = useCallback(
  //   async ({ text: qrString }) => {
  //     console.log('Read QR code', qrString);
  //     try {
  //       const origin =
  //         (typeof window !== 'undefined' && window?.location?.origin) ||
  //         PUBLIC_APP_BASE_URL;
  //       const route = PATH;
  //       const check = `${origin}${route}/`;
  //       if (qrString.includes(check)) {
  //         const id = qrString.split('/').pop();
  //         const company = await getContactApi({ id });
  //         if (company) handleButtonClick(company);
  //       }
  //     } catch (error) {
  //       enqueueSnackbar('Contact not found', { variant: 'info' });
  //       console.log(error);
  //     }
  //   },
  //   [enqueueSnackbar, handleButtonClick],
  // );

  const handleClear = () => {
    setShow(false);
    // setSearching(false);
  };

  const handleSearch = useCallback(
    async (search) => {
      console.log('Remote search', search);
      const searchResult = data.filter((item) =>
        item.contact_id.name.toLowerCase().includes(search.toLowerCase()),
      );
      setItems(searchResult);
      setShow(true);
    },
    [id], // Added id to dependencies
  );

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      {!!title && <H5 className='section-header'>{title}</H5>}
      {!!sectionTitle && <H6 className='section-title'>{sectionTitle}</H6>}
      <Stack className='search-results' spacing='1rem'>
        <FlexBox gap={1}>
          {/* <ScanQR
            onSuccess={handleOnQrRead}
            closeOnSuccess
            disabled={disabled}
          /> */}
          <SearchField
            label={label}
            id={label}
            disabled={disabled}
            // searching={isLoading}
            onClear={handleClear}
            onSearch={handleSearch}
            fullWidth
          />
        </FlexBox>
        {show && (
          <Collapse in={show}>
            {items.length ? (
              <Box className='search-result-contents'>
                <Stack className='search-section'>
                  <Stack className='list'>
                    {items.map((a) => (
                      <ContactListItem
                        key={a._id}
                        item={a}
                        buttonIcon={buttonIcon || <ArrowForward />}
                        onEdit={() => {
                          handleClear();
                          onItemClick(a);
                        }}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Body3>Nothing matches</Body3>
            )}
          </Collapse>
        )}
      </Stack>
    </Stack>
  );
}

export default PartnerContactSearch;
