import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import TaskModal from '@pw/components/Tasks/TaskModal';
import styles from '@pw/components/Tasks/styles';
import { H4 } from '@pw/components/Typography';
import { mr1 } from '@pw/styles/margins';
import slugify from '@pw/utilities/slugihfy';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import TaskCard from '../TaskCard';
import { BaseMenu } from './Menus';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';

function TaskGroup({ title, tasks, refresh, menuItems = [] }) {
  const { enqueueSnackbar } = useSnackbar();
  const hasPermission = usePermissions();
  // TODO: think about permission
  const canApprove = hasPermission(PermissionKey.CASK, PermissionType.APPROVE);
  const assignTask = hasPermission(PermissionKey.CASK, PermissionType.ASSIGN);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [actionKey, setActionKey] = useState(null);

  const onCloseModal = useCallback(
    (forceRefresh, reset) => {
      if (forceRefresh) {
        refresh();
      }
      if (reset) {
        setSelectAll(false);
        setSelectedTasks([]);
      }
      setActionKey(null);
    },
    [refresh],
  );

  const isSelected = useCallback(
    (t) => !!selectedTasks.find((a) => a._id === t._id),
    [selectedTasks],
  );

  useEffect(
    () => (selectAll ? setSelectedTasks(tasks) : setSelectedTasks([])),
    [selectAll],
  );

  const onClick = useCallback(
    (key) => {
      if (selectedTasks.length !== 0) {
        setActionKey(key);
      } else {
        enqueueSnackbar('Please select some tasks first!', {
          variant: 'warning',
        });
      }
    },
    [selectedTasks],
  );

  return (
    <>
      <Box sx={styles} className='column'>
        <Box className='column-header'>
          {selectAll ? (
            <CheckBoxRounded
              className='check'
              sx={{ ...mr1 }}
              onClick={() => setSelectAll(false)}
            />
          ) : (
            <CheckBoxOutlineBlankRounded
              className='check'
              sx={{ ...mr1 }}
              onClick={() => setSelectAll(true)}
            />
          )}
          <H4 className='title' sx={{ flexGrow: 1 }}>
            {title}
          </H4>
          {menuItems && (
            <BaseMenu
              items={menuItems}
              idSuffix={slugify(title)}
              onClick={onClick}
            />
          )}
        </Box>
        <Box className='column-content'>
          {tasks.map((t) => (
            <TaskCard
              key={t._id}
              task={t}
              selected={isSelected(t)}
              onSelect={(add) =>
                setSelectedTasks((v) =>
                  add ? [...v, t] : v.filter((x) => x._id !== t._id),
                )
              }
            />
          ))}
        </Box>
      </Box>
      {(canApprove || assignTask) && actionKey && (
        <TaskModal
          tasks={selectedTasks}
          onClose={onCloseModal}
          actionKey={actionKey}
        />
      )}
    </>
  );
}

export default TaskGroup;
