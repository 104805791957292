import { connectWithPartner } from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const connectWithPartnerThunk = createAsyncThunk(
  'partner/connect',
  async (params, { rejectWithValue }) => {
    try {
      return await connectWithPartner(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
