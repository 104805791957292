import { DESIGN_VIEW_MODE } from '@pw/consts/designs';
import { useContext } from 'react';
import DesignerContext from '@pw/context/DesignerContext';
import Bottom from './Bottom';

const ToolBox = () => {
	const { mode } = useContext(DesignerContext);

	if (mode === DESIGN_VIEW_MODE.DESIGN) {
		return (
			<Bottom className="bottom" />
		);
	}

	return <></>;
};

export default ToolBox;
