import { clientFields } from '@pw/components/Client/clientFields';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';

function PickForm(props) {
  const [FormSubmitter, submitForm] = useFormSubmissionHook();

  const [
    [sourceThings],
    [sourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Materials',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
      ],
      thing_types: [
        THING_TYPES.FINISHED,
        THING_TYPES.TRACKED,
        THING_TYPES.RAW,
        THING_TYPES.WASTE,
        THING_TYPES.CONSUMABLE,
        THING_TYPES.EXPIRING,
      ],
    },
    submitForm,
    ThingModal: SourceThingModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
    },
    [initSources],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        things: sourceThings,
        assets: sourceAssets,
      },
    }),
    [sourceThings, sourceAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel='Pick'
        requestType={REQUEST_TYPES.PICK}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />
        <FormSubmitter />
      </RequestForm>

      <SourceModals />
    </>
  );
}

export default withAppLayout(PickForm, { title: 'Picks' });
