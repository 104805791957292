import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { FormikNumberField, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5, Overline } from '@pw/components/Typography';
import { ASSET_OPTIONS, ASSET_TYPES } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import { MASS_UNIT_OPTIONS, UNITS_OPTIONS } from '@pw/consts/units';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const {
		capacity = {},
		gross_weight = {},
		net_weight = {},
		asset_type = ASSET_TYPES.CASK,
		prefix = '',
		last_number = '0',
	} = fields;

	return {
		capacity: unitField(capacity),
		gross_weight: unitField(gross_weight),
		net_weight: unitField(net_weight),
		asset_type: [asset_type, yup.string().required('Asset type is required!')],
		prefix: [prefix, yup.string().required('Prefix is required!')],
		last_number: [
			last_number,
			yup.string().required('Last Number is required!'),
		],
	};
}

function Properties() {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={3}>
				<Divider><Overline>Properties</Overline></Divider>
        <FormikSelect
          fullWidth
          options={ASSET_OPTIONS}
          label='Asset Type'
          name='asset_type'
        />
				<FlexBox alignItems='top'>
					<FormikTextField
						label='Prefix'
						name='prefix'
						fullWidth
						required
						autoComplete='prefix'
					/>
					<FormikNumberField
						label='Last Number'
						name='last_number'
						required
						fullWidth
					/>
				</FlexBox>
				<Divider><Overline>Capacity</Overline></Divider>
        <FormikUnitField label='Capacity' name='capacity' options={UNITS_OPTIONS} fullWidth />
				<Divider><Overline>Weight</Overline></Divider>
				<FlexBox alignItems='top'>
					<FormikUnitField
						label='Gross Weight'
						name='gross_weight'
						options={MASS_UNIT_OPTIONS}
						fullWidth
					/>
					<FormikUnitField
						label='Net Weight'
						name='net_weight'
						options={MASS_UNIT_OPTIONS}
						fullWidth
					/>
				</FlexBox>
			</Stack>
		</FormikProvider>
	);
}

function ThingPage(props) {
	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...thingSettingsFields(initialValues, THING_TYPES.TRACKED),
			properties: propertyFields(initialValues.properties),
		}),
		[],
	);

	const handleBeforeSave = (entity) => entity;

	return (
		<ThingForm
			thingLabel='Tracked Asset'
			thingType={THING_TYPES.TRACKED}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</ThingForm>
	);
}

export default withAppLayout(ThingPage, { title: 'Tracked Assets' });
