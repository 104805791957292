const flexLayoutConfig = {
  global: {
    tabSetEnableClose: false,
    tabSetEnableDrop: true,
    tabEnableClose: true,
    tabSetMinWidth: 130,
		tabSetMinHeight: 350,
  },
  layout: {
    type: "splitter",
    weight: 100,
    children: [
      {
        type: "splitter",
        orientation: "vertical",
        weight: 50,
        children: [
          {
            type: "tabset",
            id: "MONITORING_LAYOUT_TABSET",
            weight: 50,
            children: [
              {
                type: "tab",
                name: "Sensor Data Monitoring",
                component: "LayoutMonitoring",
                enableClose: false,
              },
            ],
          },
        ],
      },
    ],
  },
};

export default flexLayoutConfig;
