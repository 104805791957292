import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part2Report({
	name,
	title = 'On Hand First of Month',
	disabled,
	bonded,
	hospital,
	wine,
	identify,
	inventory5,
	inventory21,
}) {
	const otherOptions = [
		{ value: 'Tequila', label: 'Tequila' },
		{ value: 'Other', label: 'Other' },
	];
	const inventoryOptions = [
		{ value: 'Inventory Gain', label: 'Inventory Gain' },
		{ value: 'Other', label: 'Other' },
	];
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikSelect
				name='OtherandTequila'
				label='Other and Tequila'
				options={otherOptions}
				fullWidth
				disabled={disabled}
				sx={{ ...(!identify && styles.transaction) }}
			/>
			<FormikSelect
				name='InventorOther_5'
				label='Other or Inventory Gain'
				options={inventoryOptions}
				fullWidth
				disabled={disabled}
				sx={{ ...(!inventory5 && styles.transaction) }}
			/>
			<FormikSelect
				name='InventorOther_21'
				label='Other or Inventory Gain'
				options={inventoryOptions}
				fullWidth
				disabled={disabled}
				sx={{ ...(!inventory21 && styles.transaction) }}
			/>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='h'
					label='Vodka'
					fullWidth
					sx={{
						...((bonded || hospital || identify || inventory5 || inventory21) &&
							styles.transaction),
					}}
				/>
				<FormikTextField
					name='i'
					label='Alcohol and Spirits 190° and Over'
					fullWidth
					sx={{
						...((identify || inventory5 || inventory21) && styles.transaction),
					}}
				/>
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='j'
					label='Alcohol and Spirits Under 190°'
					fullWidth
					sx={{
						...((hospital || identify || inventory5 || inventory21) &&
							styles.transaction),
					}}
				/>
				<FormikTextField
					name='k'
					label='Other'
					fullWidth
					sx={{
						...((bonded || hospital || identify || inventory5 || inventory21) &&
							styles.transaction),
					}}
				/>
			</FlexBox>
			<FormikTextField
				name='l'
				label='Wine'
				fullWidth
				sx={{
					...((bonded ||
						hospital ||
						wine ||
						identify ||
						inventory5 ||
						inventory21) &&
						styles.transaction),
				}}
			/>
		</FormikProvider>
	);
}
Part2Report.defaultProps = {
	identify: false,
	inventory5: false,
	inventory21: false,
	bonded: false,
	hospital: false,
	wine: false,
	disabled: false,
};
