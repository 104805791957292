import { TaskOutlined } from '@mui/icons-material';
import { Avatar, Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import UsersDisplay from '@pw/components/properties/UsersDisplay';
import { Body2, Body3 } from '@pw/components/Typography';
import { useCallback, useState } from 'react';

function TeamResult({ item, onSelect }) {
  console.log('Team item', item.name);

  const [selected, setSelected] = useState(false);

  const handleSelect = useCallback(() => {
    setSelected((v) => {
      onSelect(item, !v);
      return !v;
    });
  }, [setSelected]);

  return (
    <FlexBox className="listItem" sx={{ p: '0.25rem' }} onClick={handleSelect}>
      {selected && (
        <Box className="listSelected">
          <TaskOutlined className="check" />
        </Box>
      )}

      <Stack direction="row" alignItems="center" className="listContent" spacing={0.75}>
        <Stack spacing={0.75}>
          <Body2><strong>{item?.name}</strong></Body2>
          <Body3>{item?.roles?.length} Roles</Body3>
          <UsersDisplay count={item?.users?.length} />
        </Stack>
      </Stack>
    </FlexBox>
  );
}

function SearchResults({ open, onClose, initial }) {

  const [teams] = useState(initial.items);

  const [selectedTeams, setSelectedTeams] = useState([]);

  const onSelect = useCallback((item, selected) => {
    setSelectedTeams((v) => {
      if (selected) {
        return [...v, item];
      }
      return v.filter((i) => i?._id === item?._id);
    });
  }, [setSelectedTeams]);

  return (
    <ModalWithClose open={open} onClose={onClose} title="Search Results">
      <Stack className="list">
        {teams.map((t) => (
          <TeamResult
            key={t._id}
            item={t}
            onSelect={onSelect}
          />
        ))}
      </Stack>

      <Box className="action-buttons">
        <TextButton
          size="small"
          handleClick={() => onClose()}
          color="secondary"
          label="Cancel"
        />
        <FilledButton
          size="small"
          disabled={selectedTeams.length === 0}
          handleClick={() => onClose(selectedTeams)}
        >
          Add
        </FilledButton>
      </Box>
    </ModalWithClose>
  );
}

export default SearchResults;
