import Stack from '@mui/material/Stack';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { ContactType } from '@pw/components_v2/contact/consts';

function ContactTypeSelector({ disabled = false }) {
  const companyOptions = [
    { value: ContactType.COMPANY, label: 'Legal Entity' },
    { value: ContactType.PERSON, label: 'Natural Person' },
  ];
  return (
    <Stack spacing='1.5rem'>
      <FormikSelect
        name='contact_type'
        label='Contact Type'
        options={companyOptions}
        disabled={disabled}
        required
      />
    </Stack>
  );
}

export default ContactTypeSelector;
