import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Section1Report({
	name,
	title = 'On hand first month',
	disabled,
	specify = false,
	withdrawn = false,
}) {
	const { values } = useContext(FormikContext);
	console.log(values.Specify_3.a);
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='b'
					label='Completely Denatured Alcohol'
					fullWidth
					disabled={
						((name === 'Specify_3' && !values.Specify_3.a) ||
							(name === 'Specify_13' && !values.Specify_13.a)) &&
						specify
					}
				/>
				<FormikTextField
					name='c'
					label='Specially Denatured Alcohol'
					fullWidth
					sx={{ ...(withdrawn && styles.transaction) }}
					disabled={
						((name === 'Specify_3' && !values.Specify_3.a) ||
							(name === 'Specify_13' && !values.Specify_13.a)) &&
						specify
					}
				/>
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='d'
					label='Specially Denatured Rum'
					fullWidth
					sx={{ ...(withdrawn && styles.transaction) }}
					disabled={
						((name === 'Specify_3' && !values.Specify_3.a) ||
							(name === 'Specify_13' && !values.Specify_13.a)) &&
						specify
					}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
