import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import { Overline } from '@pw/components/Typography';
import ActionDisplay from '../properties/ActionDisplay';

export default function SingleTimeline({
  item = { message: 'default tag', time: null, id: {} },
  title = false,
}) {
  const getStatusColor = (param = '') => {
    if (
      param.toLocaleLowerCase().includes('approve') ||
      param.toLocaleLowerCase().includes('create')
    ) {
      return 'success';
    } else if (param.toLocaleLowerCase().includes('update')) {
      return 'info';
    }
    return 'gray';
  };
  const color = getStatusColor(item?.message);

  return (
    <>
      {title ? (
        <TimelineItem
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TimelineSeparator sx={{ flexShrink: 0 }}>
            <TimelineDot color={color} />
            {!title && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent
            style={{ textAlign: 'left', maxWidth: '400px', minWidth: '370px' }}
          >
            <Overline>{new Date(item?.date).toLocaleString()}</Overline>
            <AccountDisplay name={item?.id?.name} />
            <ActionDisplay color={color} label={item?.message} />
          </TimelineContent>
        </TimelineItem>
      ) : (
        <TimelineItem
          sx={{
            display: 'flex',
            position: 'relative',
            bottom: '35px',
          }}
        >
          <TimelineSeparator sx={{ flexShrink: 0 }}>
            <TimelineDot color={color} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent
            style={{ textAlign: 'left', maxWidth: '400px', minWidth: '420px' }}
          >
            <Overline>{new Date(item.date).toLocaleString()}</Overline>
            <AccountDisplay name={item?.id?.name} />
            <ActionDisplay color={color} label={item?.message} />
          </TimelineContent>
        </TimelineItem>
      )}
    </>
  );
}
