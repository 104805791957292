import { CheckCircle, Edit, ImportantDevicesRounded, TaskOutlined } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { Body1, H5 } from '@pw/components/Typography';
import { THING_TYPE_REVERSE } from '@pw/consts/thing';

import { useCompanyThings } from '@pw/redux/containers/User/hooks';
import { cloneThingThunk } from '@pw/redux/thunks/thing';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function ThingMapping({ thing, editThing }) {
	// const dispatch = useDispatch();
	//
	// const { enqueueSnackbar } = useSnackbar();
	//
	const { name, type, description, mapped_thing } = thing;

	// const skus = useCompanySKUs();

	// const importSKU = () => {
	// 	setLoading(true);
	// 	// Trigger back-end import operation of the SKU, this will copy the SKU details across
	// 	cloneSKU({ sku_name: name, company_id: companyId })
	// 		.then((s) => {
	// 			enqueueSnackbar(`SKU ${s.sku_name} imported!`, {
	// 				variant: 'success',
	// 			});
	// 			upsert({ ...sku, mapped_sku: s });
	//
	// 			// Update Company SKUs
	// 			const xs = [...skus];
	// 			const index = xs.findIndex((x) => x.sku_name === s.sku_name);
	// 			if (index >= 0) {
	// 				xs[index] = { ...xs[index], ...s };
	// 			} else {
	// 				xs.push(s);
	// 			}
	// 			dispatch(setCompanySKUs(xs));
	// 		})
	// 		.catch((error) => {
	// 			enqueueSnackbar(`SKU ${name} could not be imported: ${error.message}`, {
	// 				variant: 'error',
	// 			});
	// 		})
	// 		.finally(() => setLoading(false));
	// };

	return (
		<Box className='listItem'>
			{mapped_thing && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack spacing={0.5}>
					<NameDisplay name={`${name} [${THING_TYPE_REVERSE[type]}]`} />
					<DescriptionDisplay value={description} />
					{mapped_thing && (
						<>
							<Divider />
							<IDDisplay
								value={`${mapped_thing.sku_id} [${THING_TYPE_REVERSE[type]}]`}
							/>
						</>
					)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{!mapped_thing && (
					<IconCircleButton onClick={() => editThing(thing)}>
						<Edit />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

function ThingEditor({ open, item, onClose }) {

	const handleSave = (thing) => {
		onClose(thing);
	};

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title="Thing Editor">
				{/* <SKU
					entity={item}
					onSave={handleSave}
					cancel={onClose}
					taxCodes={taxCodes}
				/> */}
		</ModalWithClose>
	);
}

function ThingMapper({ companyId, thing, onClose }) {
	const dispatch = useDispatch();
	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();

	const [editThing, setEditThing] = useState(null);

	let { name, type, description, mapped_thing } = thing;

	const allThings = useCompanyThings();
	const filteredThings = (allThings ?? []).filter((s) => s.type === type);

	const isImported = filteredThings.find((s) => s.name.toLowerCase() === mapped_thing?.name.toLowerCase());

	const changeSet = useMemo(
		() => ({
			_id: [
				mapped_thing?._id ?? '',
				yup.string().required('Thing is required!'),
			],
		}),
		[mapped_thing],
	);

	const importThing = () => {
		confirm({
			title: 'Import Thing',
			content: <Body1>{`Import the Thing ${name}?`}</Body1>,
		})
		.then(() => {
			dispatch(cloneThingThunk({ name, company_id: companyId }))
				.unwrap()
				.then((thing) => {
					setEditThing(thing);
				});
		});
	};

	const handleThingImportComplete = (newThing) => {
		if (newThing) {
			console.log('Added Thing', newThing);
			onClose({ ...thing, mapped_thing: newThing });
		}
		setEditThing(null);
	};

	const handleSubmit = (values) => {
		console.log('Saving thing mapping', values);
		const mappedThing = filteredThings.find((s) => s._id === values._id);
		if (!mappedThing) {
			enqueueSnackbar('Failed to find selected Thing!', {
				variant: 'error',
			});
		} else {
			onClose({ ...thing, mappedThing });
		}
	};

	return (
		<Stack spacing={1}>
			<H5>{name}</H5>

			<Instructions>
				{description}
			</Instructions>

			<FormWrapper>
				<FormikForm
					changeSet={changeSet}
					onSubmit={handleSubmit}
				>
					<FlexBox>
						<FormikSelect
							label='Mapped Thing'
							name='_id'
							options={filteredThings.map((s) => ({ label: s.name, value: s._id }))}
							fullWidth
						/>
						{!isImported && (
							<IconCircleButton onClick={importThing}>
								<ImportantDevicesRounded />
							</IconCircleButton>
						)}
					</FlexBox>

					<Box className='action-buttons'>
						<TextButton
							size='small'
							handleClick={onClose}
							color='secondary'
							label='Cancel'
						/>
						<FilledButton type='submit' size='small'>
							Apply
						</FilledButton>
					</Box>

				</FormikForm>
			</FormWrapper>

			{!!editThing && (
				<ThingEditor
					open={!!editThing}
					item={editThing}
					onClose={handleThingImportComplete}
				/>
			)}
		</Stack>
	);
}

function ImportThingModal({
	things,
	companyId,
	open,
	handleImportComplete,
	onClose,
}) {
	const [thingList, , , upsert] = useItemListManager({ initialData: things });

	const [editThing, setEditThing] = useState(null);

	const confirmMapping = useCallback(() => {
		handleImportComplete(thingList);
	}, [thingList]);

	const handleMappedThing = (mappedThing) => {
		if (mappedThing) {
			debounce(() => upsert(mappedThing), 25);
		}
		setEditThing(null);
	}

	return (
		<ModalWithClose open={open} onClose={() => onClose()} title="Thing Mapper">
			<Instructions>
				Please select the mappings for the Things in the shipment
			</Instructions>

			{!!editThing && (
				<Collapse in={!!editThing} unmountOnExit>
					<ThingMapper
						companyId={companyId}
						thing={editThing}
						onClose={handleMappedThing}
					/>
				</Collapse>
			)}


			<Box
				sx={{
					overflowY: 'auto',
					height: 'auto',
					maxHeight: 'calc(95vh - 9rem)',
				}}
			>
				<Box className='inventory'>
					<Box className='inventory-contents'>
						<Stack className='inventory-section'>
							<Stack className='list'>
								{thingList.map((thing) => (
									<ThingMapping
										key={thing._id}
										thing={thing}
										editThing={setEditThing}
									/>
								))}
							</Stack>
						</Stack>
					</Box>
				</Box>
			</Box>

			<FlexBox justifyContent='end'>
				<TextButton
					size='small'
					handleClick={() => onClose()}
					color='secondary'
					label='Cancel'
				/>
				<FilledButton
					size='small'
					icon={<CheckCircle />}
					iconPosition='start'
					handleClick={confirmMapping}
				>
					Confirm
				</FilledButton>
			</FlexBox>
		</ModalWithClose>
	);
}

export default ImportThingModal;
