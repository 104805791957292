import rootStyles from '../../designer.modal.styles';

const styles = {
  ...rootStyles,

  '&.root': {
    position: 'absolute',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '95vw',
      sm: '35vw'
    },
    maxWidth: '95vw',
    height: 'fit-content',
    maxHeight: '95vh',
    backgroundColor: '#fff',
    boxShadow: 24,
    pt: 3,
    px: 3,
    pb: 4,
    outline: 0,
    border: 0,
    borderRadius: 2,
  },

  '&.edge-form-root': {
    position: 'absolute',
    zIndex: 9,
    width: 'fit-content',
    pointerEvents: 'all',
    transform: `translate(-50%, -50%)`,
    marginTop: '0.25rem',
    marginLeft: '0.5rem',
  },

  '.edge-button': {
    marginTop: '0.5rem',
    border: '1px solid ',
    background: '#fff',
    borderRadius: '99rem',
    minHeight: 0,
    width: '1rem',
    height: '1rem',
    padding: '0.5rem',
    minWidth: 'unset',
    boxSizing: 'content-box',
    transition: 'all 0.3s',
    opacity: 0.5,

    '&.with-label': {
      opacity: 1,
      width: 'auto',
    },

    svg: {
      display: 'block',
      lineHeight: 1,
      fontSize: '1.5rem',
      width: '1em',
      height: '1em'
    },
  },
  '&.edge-form-root:hover .edge-button': {
    opacity: 1,
  },
  '.run-mode.edge-button:not(.with-label)': {
    display: 'none'
  },

  '.token': {
    display: 'inline-flex',
    marginRight: '0.5rem',
    alignItems: 'center',
  },
};

export default styles;
