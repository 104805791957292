
const styles = {
	'&.toolbox-root': {
		position: 'fixed',
		boxSizing: 'border-box',
		zIndex: 5,
		right: 0,
		width: '100%',
		overflowY: 'visible',

		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		justifyItems: 'center',
		backgroundColor: 'white',
		overflow: 'visible',
		transition: 'all 0.3s',

		'&.bottom': {
			top: 'auto',
			bottom: 0,
			right: 0,
			height: 'auto',

			'&::before': {
				position: 'absolute',
				content: '""',
				display: 'block',
				left: 0,
				right: 0,
				top: '-100%',
				height: '100%',
				pointerEvents: 'none',
				backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
			},
		},
		'&.left': {
			top: '6rem',
			background: 'none',
			left: 'calc(250px + 1rem)'
		},

		'&.top': {
			top: '6rem',
			right: 0,
			padding: '1rem',
			backgroundColor: 'white',
		},

	},

	'&.toolbox-root.toolbox-root--batch': {
		// '&::before': { display: 'none' },
		marginBottom: '-1.5rem',
		background: 'white',

		'.tabs': {
			marginTop: '-0.5rem',
			paddingBottom: '1rem'
		},
		button: {
			margin: '-0.25rem -1rem 0 2rem'
		},
	},

	'.toggle-global-menu': {
		margin: '0 0 1rem',
		color: '#888',
		borderColor: 'currentColor',
	},


	'.toolbox_content': {
		position: 'relative',
		width: '100%',
		overflowX: 'auto',
		display: 'flex',
		marginBottom: '-1.5rem',
		top: '-3rem',
		px: '1rem',

		'&.batch': {
			background: 'white',
			bottom: 0,
			button: { top: '0.75rem' }
		},

		'.MuiTabScrollButton-root': {
			marginTop: '1.5rem'
		},
	},

	'.tabs': {
		// mx: 'auto',
		alignItems: 'center',
		'.MuiTab-root': {
			paddingLeft: '1rem',
			paddingRight: '1rem',
			border: 'none',
		},
		'.MuiTouchRipple-root': {
			borderTop: '2px solid transparent',
		},
		'.Mui-selected': {
			color: '#00A89D',
			'.MuiTouchRipple-root': {
				borderColor: 'currentColor',
			},
		}
	},

	'.tiles-list': {
		minHeight: '9rem',
		// width: 'fit-content',
		display: 'flex',
		flexFlow: 'row nowrap',
		position: 'relative',
		mx: 'auto',
		columnGap: '2.5rem',
		justifyContent: 'center'
	},

	// '.name-field': {
	// 	position: 'relative',
	// 	marginTop: '1rem',

	// 	div: {
	// 		width: '100%',
	// 	},

	// 	label: {
	// 		position: 'absolute',
	// 		top: '0.5rem',
	// 		left: '0.75rem',
	// 		fontSize: '14px',
	// 	},

	// 	input: {
	// 		position: 'relative',
	// 		width: '100%',
	// 		paddingRight: '5rem',
	// 		paddingTop: '25px',
	// 		paddingBottom: '4px',
	// 		lineHeight: 1,
	// 	},

	// 	button: {
	// 		position: 'absolute',
	// 		right: '0.5rem',
	// 		top: '0.5rem',
	// 		lineHeight: '28px',
	// 		backgroundColor: '#C7DA65',
	// 	},
	// },

	'.batch-tabs': {
		flexShrink: 1,
	},

	'.save-button': {
		marginLeft: '1rem',
		alignSelf: 'flex-start',
		justifySelf: 'flex-end',
		position: 'relative',
		right: '1rem',
		fontSize: '0.75rem',
		height: '2rem'
	},
};

export default styles;
