import { Box } from '@mui/material';
import React from 'react';
import SensorPanel from './SensorPanel';
export const mockSensorData = {
  temp: 22.5,
  humidity: 55,
  voc: 350,
};

const SensorDataWidget = () => {
  return (
    <Box className='content-row content-light' >
      <h3>Sensor Data</h3>
      <Box className='content-body content-3col'>
      <SensorPanel data={mockSensorData} />
    </Box>
    </Box>
  );
};

export default SensorDataWidget;
