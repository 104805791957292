import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import { THING_TYPES } from '@pw/consts/thing';
import GeneralSourceThing from './forms/GeneralSourceThing';
import TrackedThing from './source/TrackedThing';

function SourceThingModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the Thing Item level
	const { name, type, description } = item ?? {};

	return (
		<ModalWithClose onClose={() => onClose()} open={open} title={name}>
			<Instructions>{description}</Instructions>

			{type === THING_TYPES.TRACKED && (
				<TrackedThing item={item} onClose={onClose} {...rest} />
			)}

			{![THING_TYPES.TRACKED].includes(
				type,
			) && <GeneralSourceThing item={item} onClose={onClose} {...rest} />}
		</ModalWithClose>
	);
}

export default SourceThingModal;
