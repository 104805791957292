import { FormikProvider } from '@pw/providers/FormikProvider';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part1Report({
	name,
	title = 'On Hand First of Month',
	specify = false,
	wine = false,
	spirit = true,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox spacing={2} alignItems='top'>
				<FormikTextField name='b' label='Wine (Proof gallons)' fullWidth sx={{ ...(!wine && styles.transaction) }}/>
				<FormikTextField name='c' label='Spirits (Proof gallons)' fullWidth sx={{ ...(!spirit && styles.transaction) }}/>
			</FlexBox>
		</FormikProvider>
	);
}
