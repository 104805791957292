import { ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import SearchField from '@pw/components/Search/SearchField';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useCompaniesLazyQuery } from '@pw/utilities/hooks/service/useCompaniesQueries';
import { useCompanyLazyQuery } from '@pw/utilities/hooks/service/useCompanyQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import CompanyListItem from './CompanyListItem';
import { PATH } from './consts';

function CompanySearch({
  title,
  sectionTitle,
  label,
  onItemClick,
  buttonIcon,
  disabled = false,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [searchApi, { data: companies = [], isLoading }] =
    useCompaniesLazyQuery({ limit: 5 });
  // const [getCompanyApi, { isLoading: isGettingCompany }] =
  //   useCompanyLazyQuery();

  const handleButtonClick = useCallback(
    (item) => {
      onItemClick?.(item);
      setShow(false);
    },
    [onItemClick],
  );

  // // https://test.metacask.com:4430/cask/edit/c/c-t/123 => [c,c-t,123]
  // const handleOnQrRead = useCallback(
  //   async ({ text: qrString }) => {
  //     console.log('Read QR code', qrString);
  //     try {
  //       const origin =
  //         (typeof window !== 'undefined' && window?.location?.origin) ||
  //         PUBLIC_APP_BASE_URL;
  //       const route = PATH;
  //       const check = `${origin}${route}/`;
  //       if (qrString.includes(check)) {
  //         const id = qrString.split('/').pop();
  //         const company = await getCompanyApi({ id });
  //         if (company) {
  //           handleButtonClick(company);
  //         }
  //       }
  //     } catch (error) {
  //       enqueueSnackbar('Company not found', { variant: 'info' });
  //       console.log(error);
  //     }
  //   },
  //   [enqueueSnackbar, handleButtonClick],
  // );

  const handleClear = () => {
    setShow(false);
    // setSearching(false);
  };

  const handleSearch = useCallback(
    async (search) => {
      console.log('Remote search', search);
      searchApi({ search })
        .catch((e) => {
          console.log('Failed to search', e);
        })
        .finally(() => {
          setShow(true);
        });
    },
    [searchApi],
  );

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      {!!title && <H5 className='section-header'>{title}</H5>}
      {!!sectionTitle && <H6 className='section-title'>{sectionTitle}</H6>}
      <Stack className='search-results' spacing='1rem'>
        <SearchField
          label={label}
          id={label}
          disabled={disabled}
          searching={isLoading}
          onClear={handleClear}
          onSearch={handleSearch}
          fullWidth
        />

        {show && !isLoading && (
          <Collapse in={show}>
            {companies.length ? (
              <Box className='search-result-contents'>
                <Stack className='search-section'>
                  <Stack className='list'>
                    {companies.map((a) => (
                      <CompanyListItem
                        key={a._id}
                        item={a}
                        buttonIcon={buttonIcon || <ArrowForward />}
                        onClick={onItemClick}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Body3>Nothing matches</Body3>
            )}
          </Collapse>
        )}
      </Stack>
    </Stack>
  );
}

export default CompanySearch;
