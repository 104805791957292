/* eslint-disable react-hooks/rules-of-hooks */
import { persistor } from '@pw/redux/store';
import { UnauthorizedError } from '@pw/utilities/checkResponseStatus';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useServiceCallHook =
	(
		serviceFunction,
		{ useLoadingHook = useState } = {},
		converterFunction = (d) => d,
	) =>
	(initialData, initialLoadingState = false) => {
		const [isLoading, setIsLoading] = useLoadingHook(initialLoadingState);
		const [data, setData] = useState(initialData);
		const [error, setError] = useState(false);

		const refetch = useCallback(
			async (...params) => {
				try {
					setError(false);
					setIsLoading(true);
					const res = await serviceFunction(...params);
					const converted = await converterFunction(res);
					// console.log('Result', res);
					setData(converted);
					return converted;
				} catch (error) {
					if (error instanceof UnauthorizedError) {
						persistor.purge();
					} else {
						setError(error);
						throw error;
					}
				} finally {
					setIsLoading(false);
				}
				// eslint-disable-next-line react-hooks/exhaustive-deps
			},
			[setIsLoading],
		);

		// Abort?
		const cancel = () => setIsLoading(false);

		return useMemo(
			() => [
				refetch,
				{
					data,
					error,
					refetch,
					cancel,
					isLoading,
					isError: !!error,
					// eslint-disable-next-line react-hooks/exhaustive-deps
				},
			],
			[data, error, refetch, isLoading],
		);
	};

export const genAutoServiceCallHook =
	(lazyHook) =>
	(params, { skip = false, initialData, initialLoadingState = false } = {}) => {
		const [refetch, res] = lazyHook(initialData, initialLoadingState);

		useEffect(() => {
			// console.log('Fetching', params, skip);
			if (!skip) {
				refetch(params).catch((e) => console.error('Failed to fetch', e));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [refetch]);

		return res;
	};

export const genServiceCallHooks = (
	serviceFunction,
	{ useLoadingHook = useState } = {},
	converterFunction = (d) => d,
) => {
	const lazyHook = useServiceCallHook(
		serviceFunction,
		{ useLoadingHook },
		converterFunction,
	);
	const queryHook = genAutoServiceCallHook(lazyHook);
	return [lazyHook, queryHook];
};
