import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getLedger = async (params, headers = {}) =>
	fetchGet(`${BASE}/v2/get${objectToQueryString({ ...params, type: 'ledger' })}`, headers);

export const createLedger = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/create`, headers, { ...data, type: 'ledger' });

export const updateLedger = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/update`, headers, { ...data, type: 'ledger' });

export const removeLedger = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/remove`, headers, { ...data, type: 'ledger' });

export const listLedgers = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/list`, headers, { ...data, type: 'ledger' });
