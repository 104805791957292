import Avatar from '@pw/components/Avatar';
import QRImage from '@pw/components/QRImage/index';
import { Body2 } from '@pw/components/Typography';
import { PATH } from '@pw/components_v2/CompanyView/consts';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useMemo } from 'react';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    pageBreakInside: 'none',
    pageBreakAfter: 'auto',
  },
  rootFullScreen: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    pageBreakInside: 'none',
    pageBreakAfter: 'auto',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  footer: {
    borderTop: '1px solid',
    paddingTop: '0.25rem',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  qrDetails: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  qrIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    // border: '2px solid',
    padding: '5px',
    height: '144px',
    width: '144px',
    borderRadius: '50%',
  },
  type: {
    textTransform: 'uppercase',
    fontSize: '0.8rem',
    marginTop: '.25rem',
  },
  title: {
    fontSize: '12pt',
    fontWeight: '600',
    fontFamily:
      'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
  },
};

function QrDetails({ item }) {
  return (
    <div style={styles.qrDetails}>
      <div style={styles.qrIcon}>
        <Avatar alt={item.name} sx={{ height: 128, width: 128, fontSize: '5rem' }} />
      </div>
    </div>
  );
}

function QRCode({ entity, fullScreen = true }) {
  // const loc = typeof window !== 'undefined' && window?.location?.origin;
  // const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
  // const route = PATH;
  // const qrValue = useMemo(
  //   () => `${origin}${route}/${entity?._id}`,
  //   [entity, origin],
  // );

  return (
    <div style={fullScreen ? styles.rootFullScreen : styles.root}>
      <div style={styles.content}>
        <QRImage value={entity.user?._id} />
        <QrDetails item={entity} />
      </div>
      <div style={styles.footer}>
        {entity?.name && (
          <Body2 sx={styles.title}>{entity?.name}</Body2>
        )}
      </div>
    </div>
  );
}

export default QRCode;
