function SvgPolicy(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Policy_svg__clip0_99_2)'>
        <path d='M0 8.998V2.272C0 .916.91.003 2.264.002 4.392.003 6.52.009 8.649 0a2.296 2.296 0 011.737.73c1.159 1.197 2.324 2.39 3.494 3.576.467.474.695 1.032.692 1.695-.005.884.001 1.767-.004 2.65-.002.14.045.211.169.277.825.435 1.688.77 2.583 1.032.45.13.665.433.676.9.021.948-.035 1.884-.36 2.789-.463 1.285-1.238 2.333-2.353 3.121-.492.347-.996.676-1.502 1.003a.888.888 0 01-.388.146c-.478.04-.958.076-1.437.077-3.245.006-6.492.004-9.737.002-1.065 0-1.909-.64-2.152-1.637a2.63 2.63 0 01-.064-.616C-.002 13.496 0 11.247 0 8.998zm11.755 8.147l.011-.047a54.787 54.787 0 00-.28-.188 6.437 6.437 0 01-2.464-3.012c-.383-.942-.479-1.926-.458-2.931.012-.61.201-.874.788-1.042a12.359 12.359 0 003.23-1.44c.349-.22.69-.39 1.115-.177V5.716c0-.154-.106-.14-.208-.14-.763 0-1.526.005-2.29-.002-1.156-.01-2.035-.756-2.178-1.893-.06-.474-.02-.962-.023-1.444-.003-.447 0-.895 0-1.36-.134-.007-.253-.02-.372-.02H2.301c-.923 0-1.447.52-1.447 1.44V15.71c0 .908.526 1.435 1.432 1.435h9.47zm5.386-5.62c0-.18-.011-.362.003-.542.013-.152-.051-.21-.188-.247-1.25-.352-2.42-.88-3.514-1.578-.12-.076-.202-.074-.32.001a13.42 13.42 0 01-3.053 1.444c-.208.069-.5.083-.598.227-.107.158-.04.438-.04.665.001 1.508.523 2.809 1.56 3.902.643.678 1.466 1.112 2.228 1.63.039.026.137 0 .188-.033.403-.262.804-.528 1.202-.799 1.645-1.121 2.47-2.689 2.533-4.669l-.001-.001zm-4.068-6.813l-3.207-3.28c0 .747-.036 1.45.01 2.146.044.668.585 1.121 1.262 1.132.555.008 1.11.002 1.667.002h.268z' fill="currentColor" />
        <path d='M6.425 8.569h3.23c.06 0 .121-.003.18.001.266.018.442.187.446.424.004.245-.178.42-.453.437-.033.002-.067.001-.1.001l-6.6-.001a.834.834 0 01-.277-.035.393.393 0 01-.277-.4.39.39 0 01.284-.393.977.977 0 01.297-.033c1.09-.002 2.18-.001 3.27-.001zM6.005 5.998L8.856 6c.087 0 .175.005.258.025a.419.419 0 01-.004.812.984.984 0 01-.238.024c-1.914.001-3.828.002-5.742 0a.846.846 0 01-.276-.034.393.393 0 01-.279-.4.392.392 0 01.283-.395A.899.899 0 013.135 6c.957-.002 1.914-.002 2.87-.002v.001zM5.15 11.14h1.966c.066 0 .134-.003.2.006.238.031.397.207.394.43-.002.215-.154.384-.383.419-.06.009-.12.008-.18.008h-4.01c-.08 0-.162-.004-.239-.022a.415.415 0 01-.326-.404.416.416 0 01.334-.417c.077-.018.159-.02.239-.02H5.15zM5.157 13.71c.689 0 1.377-.002 2.066.002.294.002.482.17.488.421.006.258-.187.432-.49.44-.053.002-.106 0-.16 0H3.632c-.214 0-.429.009-.642-.005-.25-.015-.418-.197-.418-.427 0-.23.17-.403.42-.428.059-.005.12-.003.18-.003h1.985zM4.72 3.427l1.666.001c.188 0 .342.072.425.25.074.158.072.337-.063.444a.761.761 0 01-.428.162c-.862.014-1.726.007-2.589.007-.24 0-.482.007-.722-.004-.264-.012-.438-.193-.437-.43.002-.238.177-.41.441-.428.054-.004.107-.002.16-.002h1.548zM12.843 13.121c.473-.474.91-.925 1.367-1.357.1-.096.272-.183.398-.168.125.014.266.142.342.258.093.144.043.315-.077.442-.17.18-.347.353-.522.528-.374.374-.746.748-1.121 1.12-.271.27-.482.268-.756-.005-.247-.245-.494-.49-.737-.738-.206-.21-.217-.459-.035-.64.177-.178.434-.166.64.038.166.164.324.337.501.522z' fill="currentColor" />
      </g>
      <defs>
        <clipPath id='Policy_svg__clip0_99_2'>
          <path fill='#fff' d='M0 0h18v18H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgPolicy;
