import { Box, Chip, Stack } from '@mui/material';
import { DialogCard } from '@pw/components/Cards/DialogCard';
import styles from '@pw/styles/content.styles';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { ThingDisplay } from '@pw/components/ThingSelector/SelectedThing';
import { toLocationText } from '@pw/utilities/format';
import { useCompanyFacilities } from '@pw/redux/containers/User';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import SourceTypeIcon from '@pw/components/Liquid/SourceTypeIcon';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import StrengthDisplay from '@pw/components/properties/StrengthDisplay';
import LiquidIcon from '@pw/components/icons/Liquid';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ContainerListItem from '@pw/components/AssetListItem/ContainerListItem';
import { assetCardStyles } from './styles';

/*
  TODOS: implement this elements
  batches: {type: [batchInput], required: [true, "Batches are required"]},
  commissions: {type: [assetCommission], default: []},
  marketplace: {type: String, ref: "Marketplace"},
  accounts: {type: assetAccounts},
  charge_code: {type: chargeCode},
*/
const AssetInfoCard = ({ asset }) => {
  const locations = useCompanyFacilities();

  if (!asset) return null;

  return (
    <Box sx={assetCardStyles} className='asset-card'>
      <SourceTypeIcon type={asset?.type} height={60} width={60} />
      <Stack>
        <Body3>
          <Bolder>Name:</Bolder> {asset.name}
        </Body3>
        <Body3>
          <Bolder>Created Date:</Bolder>{' '}
          {new Date(asset.created).toLocaleString()}
        </Body3>
        {!!asset.asset_label && (
          <Body3>
            <Bolder>Label:</Bolder> {asset.asset_label}
          </Body3>
        )}
        <Body3>
          <Bolder>Location:</Bolder>{' '}
          {toLocationText(asset.location, locations) || 'No Location'}
        </Body3>
      </Stack>
    </Box>
  );
};

const Section = ({ label, children }) => (
  <Box className='section' sx={{ gap: '0.5rem !important', marginBottom: 2 }}>
    <H5 className='section-header'>{label}</H5>
    {children}
  </Box>
);

const ThingCard = ({ thing, label }) => {
  if (!thing?._id) return null;
  return (
    <Section label={label}>
      <ThingDisplay item={thing} />
    </Section>
  );
};

const ParentAsset = ({ asset, label = 'Pallet' }) => {
  if (!asset?._id) return null;
  return (
    <Section label={label}>
      <AssetListComponentV2 item={asset} />
    </Section>
  );
};

const ChildAssets = ({ assets = [], label = 'Assets' }) => {
  if (!assets.length) return null;
  return (
    <Section label={label}>
      <Box className='inventory-contents'>
        <Stack className='list'>
          {assets.map((asset) => (
            <AssetListComponentV2 key={asset._id ?? asset.name} item={asset} />
          ))}
        </Stack>
      </Box>
    </Section>
  );
};

const Properties = ({ asset, label = 'Properties' }) => {
  const { properties, utilisation } = asset ?? {};
  if (!properties) return null;
  return (
    <Section label={label}>
      {!!properties.type && (
        <Body3>
          <Bolder>Type:</Bolder> {properties.type}
        </Body3>
      )}
      {!!properties.reference && (
        <Body3>
          <Bolder>Reference:</Bolder> {properties.reference}
        </Body3>
      )}
      {!!properties.compliance && (
        <>
          <Body3>
            <Bolder>Compliance Source:</Bolder> {properties.compliance.source}
          </Body3>
          <Body3>
            <Bolder>Compliance Duty Suspended:</Bolder>{' '}
            {properties.compliance.duty_suspended}
          </Body3>
        </>
      )}
      <Body3>
        <Bolder>Utilisation:</Bolder> {utilisation}
      </Body3>
      <Body3>
        <Bolder>Tags:</Bolder>
      </Body3>
      <Box flexWrap='wrap'>
        {properties.tags.map((tag) => (
          <Chip
            color='primary'
            sx={{ margin: '0.1rem' }}
            label={tag}
            key={tag}
            size='small'
          />
        ))}
      </Box>
      <ThingCard thing={properties.thing} label='Wood' />
    </Section>
  );
};

const LiquidProperties = ({ liquid, label = 'Liquid Properties' }) => {
  if (!liquid) return null;
  return (
    <Section label={label}>
      <Stack spacing={0.5}>
        <LiquidDisplay value={liquid?.level?.bl} la={liquid?.level?.la} />
        <StrengthDisplay value={liquid?.level?.abv} tcf={liquid?.level?.tcf} />
        {liquid?.weight?.weightFactor && (
          <Box sx={{ flexDirection: 'row', display: 'flex', gap: '0.25rem' }}>
            <LiquidIcon style={{ width: '12px', height: 'auto' }} />
            <Body3>
              <Bolder>Weight Factor:</Bolder> {liquid.weight.weightFactor}
            </Body3>
          </Box>
        )}
      </Stack>
      <Body3>
        <Bolder>Account:</Bolder> {liquid.account}
      </Body3>
      <Body3>
        <Bolder>Tags:</Bolder>
      </Body3>
      <Box flexWrap='wrap'>
        {liquid.tags.map((tag) => (
          <Chip
            color='primary'
            sx={{ margin: '0.1rem' }}
            label={tag}
            key={tag}
            size='small'
          />
        ))}
      </Box>
      <ThingCard thing={liquid.thing} label='Liquid' />
    </Section>
  );
};

const Things = ({ things = [], label = 'Things' }) => {
  if (!things?.length) return null;
  return (
    <Section label={label}>
      <FlexBox gap={1} justifyContent='space-between'>
        <ContainerListItem thingStorages={things} />
      </FlexBox>
    </Section>
  );
};

const AssetView = ({ asset, thingLabel = 'Marketplace Product' }) => {
  if (!asset) return null;

  return (
    <Stack spacing={1.5}>
      <AssetInfoCard asset={asset} />
      <DialogCard>
        <Box sx={{ ...styles, gap: 1.5 }} className='root'>
          <ThingCard thing={asset.thing} label={thingLabel} />
          <ParentAsset asset={asset.parent_asset} />
          <Properties asset={asset} />
          <LiquidProperties liquid={asset?.properties?.liquid} />
          <Things things={asset?.things} />
          <ChildAssets assets={asset.child_assets} />
        </Box>
      </DialogCard>
    </Stack>
  );
};

export default AssetView;
