import { Box } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { Body1 } from '@pw/components/Typography';
import { resetOwnerPasswordThunk } from '@pw/redux/thunks/owners';
import { useDispatch } from 'react-redux';

export function ResetPasswordDialog({ item, handleClose }) {
  const dispatch = useDispatch();
  const handleSubmit = () =>
    dispatch(resetOwnerPasswordThunk(item))
      .unwrap()
      .then(() => handleClose());

  return (
    <ModalWithClose title='Reset Password' open={!!item} onClose={handleClose}>
      <Body1>Full name: {item?.name}</Body1>
      <Body1>Contact email: {item?.email}</Body1>

      <Box className='action-buttons'>
        <TextButton size='small' handleClick={handleClose} color='secondary'>
          Cancel
        </TextButton>
        <FilledButton type='submit' size='small' handleClick={handleSubmit}>
          Confirm
        </FilledButton>
      </Box>
    </ModalWithClose>
  );
}
