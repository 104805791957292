import { Stack } from '@mui/material';
import Avatar from '@pw/components/Avatar';
import SvgDelete from '@pw/components/icons/Delete';
import { Body1 } from '@pw/components/Typography';
import RoleSelectorModal from '@pw/components_v2/CompanyView/modals/users/RoleSelectorModal';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import Search from '@pw/components_v2/search/general';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import { useCallback, useContext, useEffect, useState } from 'react';

function User({ item }) {
  // console.log('User item', item);
  const { user, roles = [] } = item;
  const { name } = user?.target_id ?? {};

  const rolesList = (roles.length > 0) ? roles.map((r) => r.name).join(', ') : 'No Roles';

  return (
    <Stack direction="row" alignItems="center" spacing="0.5rem">
      <Avatar alt={name} sx={{ height: '42px', width: '42px', fontSize: '0.75rem', fontWeight: '600' }} />
      <Stack>
        <EntryTitle value={name} />
        <EntryLabel label="Roles" value={rolesList} />
      </Stack>
    </Stack>
  );
}

const search = {
  [SEARCH_TYPES.USERS]: {
    types: [RELATIONSHIP_TYPE.USER]
  }
};

function UserSelector({ readonly }) {
  const { values, setFieldValue } = useContext(FormikContext);
  const [users, setUsers] = useState([]);
  const confirm = useConfirm();

  // Refresh the user list if roles change..
  useEffect(() => {
    // console.log('Getting user list', values?.roles);
    const users = [];
    (values?.roles ?? []).forEach((r) => {
      (r.users ?? []).forEach((u) => {
        const exists = users.find((item) => item.id === u._id);
        if (!exists) {
          users.push({
            id: u._id,
            name: u.target_id?.name,
            user: u,
            roles: [r]
          });
        } else {
          // Add the role to the list
          exists.roles.push(r);
        }
      });
    });
    setUsers(users);
  }, [values?.roles]);

  // console.log('users', users)

  const handleRemoveUser = useCallback((removedUser) => {
    if (removedUser) {
      confirm({
        title: 'Remove User',
        content: <Body1>{`Remove '${removedUser?.name}'?`}</Body1>
      })
      .then(() => {
        const updatedRoles = (values?.roles ?? []).map((role) => ({
          ...role,
          users: (role.users ?? []).filter((u) => u._id !== removedUser.id)
        }));
        debounce(() => setFieldValue('roles', updatedRoles), 25);
      })
      .catch(() => {
      });
    }
  }, [setFieldValue, values?.roles]);

  const Toolbar = ({ onClose }) => {
    const [roleSelection, setRoleSelection] = useState(null);

    const handleSelect = useCallback((selectedUsers) => {
      // Need a modal to select the roles the user has
      // console.log('Selected users', selectedUsers);
      if (selectedUsers && selectedUsers.length > 0) {
        setRoleSelection({ users: selectedUsers, roles: values?.roles ?? [] });
      }
      onClose();
    }, [values?.roles, onClose]);

    const handleClose = useCallback((roles) => {
      // Updated roles
      if (roles) {
        // console.log('selected roles for the users')
        const { users: userList, roles: selectedRoles } = roles;
        if (!userList || userList.length === 0 || !selectedRoles || selectedRoles.length === 0) {
          return;
        }

        const updatedRoles = [];
        (values?.roles ?? []).forEach((role) => {
          if (selectedRoles.includes(role.name)) {
            // Add all the users (if they are not there..
            const newRole = { ...role, users: role.users ?? [] };
            // console.log('Adding user to role', role);

            userList.forEach((u) => {
              const exists = newRole.users.find((ru) => ru._id === u._id);
              if (!exists) {
                newRole.users.push(u);
              }
            });
            updatedRoles.push(newRole);
          } else {
            updatedRoles.push(role);
          }
        });

        // console.log('Updated roles', updatedRoles);

        debounce(() => setFieldValue('roles', updatedRoles), 25);
      }
      setRoleSelection(null);
      onClose();
    }, [setFieldValue, values?.roles, onClose]);

    return (
      <>
        <Search multi={true} types={search} onSelected={handleSelect}
                disabled={readonly || (values?.roles ?? []).length === 0} />
        {!readonly && !!roleSelection && (
          <RoleSelectorModal open={!readonly && !!roleSelection} item={roleSelection} onClose={handleClose} />
        )}
      </>
    );
  };

  const tools = [
    {
      title: 'Remove user',
      Icon: SvgDelete,
      handler: handleRemoveUser
    }
  ];

  return (
    <ToolbarSection title="Users" Toolbar={Toolbar}>
      <BasicList Content={User} items={users} tools={tools} identity={(i) => i.id} />
    </ToolbarSection>
  );
}

export default UserSelector;
