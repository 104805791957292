import { Grid, Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import CostDisplay from '@pw/components/properties/CostDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import VendorDisplay from '@pw/components/properties/VendorDisplay';
import { THING_TYPES } from '@pw/consts/thing';
import { useMemo } from 'react';
import ThingStorageItem from './ThingStorageItem';

function ThingEntryItemInfo({
  item,
  unit,
  thing_type: thing_type,
  onSelectStorage,
}) {
  const {
    expiry,
    duty_paid,
    amount,
    available_quantity,
    storage = [],
    tracked = [],
  } = item;

  const uu = useMemo(
    () =>
      [
        THING_TYPES.TRACKED,
        THING_TYPES.FINISHED,
        THING_TYPES.WIP,
        THING_TYPES.SERVICES,
        THING_TYPES.CONSUMABLE,
      ].includes(thing_type)
        ? undefined
        : unit,
    [thing_type, unit],
  );

  console.log('ThingEntryItemInfo', unit, item);

  return (
    <Stack spacing={0.5}>
      <Grid container spacing={0.5}>
        {expiry && (
          <Grid item xs={6}>
            <DateDisplay date={expiry} />
          </Grid>
        )}
        <Grid item xs={6}>
          <AmountDisplay label='Amount' unit={uu} amount={amount} />
        </Grid>
        <Grid item xs={6}>
          <AmountDisplay
            label='Available'
            unit={uu}
            amount={available_quantity}
          />
        </Grid>
        {duty_paid && (
          <Grid item xs={12}>
            <DutyPaidDisplay value={duty_paid} />
          </Grid>
        )}
      </Grid>
      {storage && storage.length > 0 && (
        <FlexBox
          className='asset-container'
          justifyContent='start'
          gap='0.75rem'
        >
          {storage.map((_item) => (
            <ThingStorageItem
              key={_item.asset}
              item={_item}
              unit={uu}
              onSelectStorage={onSelectStorage}
            />
          ))}
        </FlexBox>
      )}
      {tracked && tracked.length > 0 && (
        <AmountDisplay amount={tracked.length} label='Tracked Assets' />
      )}
    </Stack>
  );
}

export default ThingEntryItemInfo;
