function SvgManualLoop(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path d='M1.5 16.5l8 30h44l8-30z' fill='#fcd' stroke='#838383' />
    </svg>
  );
}
export default SvgManualLoop;
