import { useCompanyThings } from '@pw/redux/containers/User';
import { useCallback } from 'react';

const useSKUSearch = () => {
  const things = useCompanyThings();

  const search = useCallback(
    (filter = [], param = '', itemFilter = (x) => !!x) => {
      // Find all skus matching this
      return things
        .filter((sku) => {
          // Sku type must be in the filter
          if (filter.length !== 0 && !filter.includes(sku.type)) {
            // console.log('Sku type not in filter', sku.sku_type);
            return false;
          }
          // Search term must be in the sku properties..
          const search_key =
            `${sku._id} ${sku.name} ${sku.description}`.toLowerCase();
          // console.log('Search key', search_key, param);
          return search_key.includes(param.toLowerCase());
        })
        .filter(itemFilter);
    },
    [things],
  );

  return [search];
};

export default useSKUSearch;
