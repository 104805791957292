import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal, Stack } from '@mui/material';
import InventorySelector from '@pw/components/InventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { ASSET_TYPES } from '@pw/consts/asset';
import styles from '@pw/styles/modal.styles';
import { useMemo } from 'react';

function ReallocateModal({ onCancel, onSelect, storage = null }) {
	const filter_asset_types = useMemo(
		() => [ASSET_TYPES.PALLET, ...(storage ? [ASSET_TYPES.CONTAINER] : [])],
		[storage],
	);

	const label = useMemo(() => (storage ? 'Holder' : 'Parent'), [storage]);

	return (
		<Modal open={true} onClose={onCancel}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<IconButton
						onClick={onCancel}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>
				<InventorySelector
					title={label}
					list={[]}
					types={{ asset_types: filter_asset_types }}
					single
					onAdd={onSelect}
				/>
			</Stack>
		</Modal>
	);
}

export default ReallocateModal;
