import { pageHeaderHeight } from '@pw/components/Layout/consts';
import { breakpoints } from '@pw/styles/theme';

export const itemWidth = 190;
export const itemHeight = itemWidth;
// const itemGap = 32;
export const gridGap = 50;

export const styles = {
  '&.diagram-root, .dropzone-root': {
    width: '100%',
    height: '100%',
  },
  '.react-flow__node': {},
  '.react-flow__controls': {
    '&.bottom': { bottom: '1rem' },
    '&.right': { right: '1rem' },
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
  },
  '.react-flow__edge-path': {
    strokeWidth: '3px',

    '&.selected': {
      border: '2px dashed red'
    },
  },
};

export const globalStyles = (showMenu) => (
  <style>{`
  '.wrapper-content, .wrapper-box': {
    height: '100%',
  },
  .wrapper-box { flex-grow: 1; }
  .menu {
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-gutter: stable;
    max-height: calc(100vh - ${pageHeaderHeight.xs});
    overflow-y: auto;
    transition: transform 0.3s 0, display 0.3s 0.3s;
    transition-behavior: allow-discrete;
    transform: ${showMenu
      ? 'translateX(0); opacity: 1;'
      : 'translateX(-100%); opacity: 0 !important; display: none !important;'
    }
  }
  @media (min-width: ${breakpoints.sm}px) {
    .menu.menu {
      max-height: calc(100vh - ${pageHeaderHeight.sm});
    }
  }
  .menu-box { padding-top: 0; padding-bottom: 0; }
  .menu .copy-right { padding: 0.5rem 1rem; }
  .wrapper-content {padding: 0 !important; overflow-x: hidden !important; }
  .app-title-bar {display: none !important; }
  .react-flow__attribution { display: none; }
`}</style>
);
