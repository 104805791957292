import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import PriceCurvesView from '@pw/components_v2/CompanyView/modals/curves/PriceCurvesView';

function PriceCurvesModal({ company, open, onClose }) {
  const title = `${company.name} Price Curves`;
  console.log('Curves modal', company.name);

  return (
    <ModalWithClose onClose={() => onClose()} open={open} title={title}>
      <PriceCurvesView company={company} /> 
    </ModalWithClose>
  );
}

export default PriceCurvesModal;
