import { TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import {
  FormikForm,
  FormikTextAreaField,
} from '@pw/components/Forms/FormikForm';
import SvgAddNew from '@pw/components/icons/AddNew';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import { Body2, Body3 } from '@pw/components/Typography';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import { useAiSearch } from '@pw/utilities/hooks/ai/useAiSearch';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCompanyPoliciesLazyQuery } from '@pw/utilities/hooks/service/useCompanyPoliciesQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';

const model = [
  'Mongoose filter per the following properties, only specify the field if a value value is provided else skip the field',
  {
    name: 'This is the name of the policy',
  },
];

function Policy({ item, onSelect }) {
  const { name, entries } = item ?? {};
  const [selected, setSelected] = useState(false);

  const handleSelect = useCallback(() => {
    setSelected((v) => {
      onSelect(item, !v);
      return !v;
    });
  }, [setSelected]);

  return (
    <Box className='listItem'>
      {selected && (
        <Box className='listSelected'>
          <TaskOutlined className='check' />
        </Box>
      )}
      <Stack className='listContent' spacing={0.75}>
        <Body2>
          <strong>{name}</strong>
        </Body2>
        <Body3>{entries?.length ?? 0} Entries</Body3>
      </Stack>
      <Stack className='listButtons'>
        <IconCircleButton size='small' onClick={handleSelect}>
          <SvgAddNew style={{ height: '16px', width: '16px' }} />
        </IconCircleButton>
      </Stack>
    </Box>
  );
}

function Search({ types, multi, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { aiSearch } = useAiSearch();

  const [inputProps, setInputProps] = useState(null);
  const [search, setSearch] = useState(null);

  const [matches, initMatches, , upsertMatches] = useItemListManager({
    comp: COMP.target,
    initialData: [],
  });

  const [selectedItems, , , upsertSelectedItems, removeSelectedItems] =
    useItemListManager({
      comp: COMP.target,
      initialData: [],
    });

  const [doSearch, { data, isLoading: searching }] =
    useCompanyPoliciesLazyQuery();
  const { items: results = [], page } = data ?? {};

  const changeSet = {
    prompt: ['', yup.string().required('Please enter a prompt!')],
  };

  const searchFn = useCallback(
    (filter) => {
      const request = {
        filter_types: types,
        search: filter,
      };
      console.log('Request', request);
      // Clear the existing results
      debounce(() => initMatches([]), 25);
      // Trigger search...
      doSearch(request);
    },
    [types],
  );

  const handleGenerate = useCallback(
    (values) => {
      console.log('Values', values.prompt);

      setInputProps({
        endAdornment: <CircularProgress size={32} color='info' />,
      });

      aiSearch(model, values.prompt)
        .then((b) => {
          console.log('Generated response', b);
          if (b['$match']) {
            console.log('Match expression', b['$match']);
            // Set this
            debounce(() => setSearch(b['$match']), 25);
            // Now trigger the actual search with this filter
            searchFn(b['$match']);
          }
        })
        .catch((e) => {
          console.log('Error', e.message);
          enqueueSnackbar(
            'Failed to generate filter from prompt, please try again!',
            {
              variant: 'error',
            },
          );
        })
        .finally(() => {
          setInputProps(null);
        });
    },
    [searchFn],
  );

  useEffect(() => {
    console.log('Search results...', results);
    if (results.length > 0) {
      initMatches(results);
    }
  }, [results, initMatches]);

  const selectMatch = useCallback(
    (item, select) => {
      if (multi) {
        console.log('Item selected', select, item);
        if (select) {
          upsertSelectedItems(item);
        } else {
          removeSelectedItems(item);
        }
      } else {
        debounce(() => onClose([item]), 25);
      }
    },
    [upsertSelectedItems, removeSelectedItems, onClose, multi],
  );

  return (
    <FormikForm changeSet={changeSet} onSubmit={handleGenerate}>
      <Stack spacing='0.5rem'>
        <FormikTextAreaField
          label='Search'
          name='prompt'
          placeholder='Enter a prompt to search...'
          disabled={!!inputProps || searching}
          InputProps={inputProps}
          onKeyDown={() => {}}
          fullWidth
        />
        <Stack className='inventory'>
          {matches.length > 0 && (
            <>
              <Box className='inventory-header'></Box>
              <Box className='inventory-contents'>
                <Stack className='list'>
                  {matches.map((r) => (
                    <Policy key={r._id} item={r} onSelect={selectMatch} />
                  ))}
                </Stack>
              </Box>
            </>
          )}
          {searching && <LoadingSmall message='Searching...' />}
        </Stack>
      </Stack>
      <Box className='action-buttons'>
        <TextButton
          size='small'
          handleClick={() => onClose()}
          color='secondary'
          label='Cancel'
        />
        {multi && (
          <FilledButton
            size='small'
            disabled={selectedItems.length === 0}
            handleClick={() => onClose(selectedItems)}
          >
            Select
          </FilledButton>
        )}
      </Box>
    </FormikForm>
  );
}

export default Search;
