import { Stack } from '@mui/material';
import PolicyModal from '@pw/components/admin/ThingPolicies/PolicyModal';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function PolicyComponentWrapper() {
  const { values, setFieldValue } = useContext(FormikContext);

  const [[selectedPolicies], PolicyComponent, ModalComponents] =
    usePolicySelectorHook({
      title: 'Policies',
      initialPolicies: values?.policies?.map((i) => ({ ...i, _id: i.id })),
      PolicyModal: PolicyModal,
      policyConverter: (p) => ({ ...p, requests: [] }),
    });

  useEffect(() => {
    debounce(() => setFieldValue('policies', selectedPolicies), 25);
  }, [setFieldValue, selectedPolicies]);

  return (
    <>
      <PolicyComponent />
      <ModalComponents />
    </>
  );
}

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      policies: [initialValues?.policies, yup.array()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack sx={{ pt: '1rem' }}>
        <PolicyComponentWrapper />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
