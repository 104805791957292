import { Stack } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import { FormikCheckBox, FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DeliveryDestinationAssetModal from '@pw/components/ThingSelector/modals/DeliveryDestinationAssetModal';
import DeliveryThingModal from '@pw/components/ThingSelector/modals/DeliveryThingModal';
import { H5 } from '@pw/components/Typography';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyThings } from '@pw/redux/containers/User/hooks';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

function Properties() {
  const { values, readonly } = useContext(FormikContext);
  return (
    !readonly && (
      <FormikProvider path='properties'>
        <Stack spacing={2}>
          <H5>Invoice Details</H5>
          <FlexBox alignItems='top'>
            <FormikTextField
              name='invoice_number'
              label='Invoice Number'
              fullWidth
            />
            <FormikDatePicker
              name='invoice_date'
              label='Invoice Date'
              fullWidth
            />
          </FlexBox>
          <FlexBox alignItems='top'>
            <FormikTextField
              name='invoice_amount'
              label='Total Cost'
              fullWidth
            />
            <FormikTextField name='tax_amount' label='Tax' fullWidth />
          </FlexBox>
          <Stack>
            <FormikCheckBox name='paid' label='Paid' />
            <Collapse in={Boolean(values?.properties?.paid)}>
              <FormikTextField
                name='payment_confirmation'
                label='Payment confirmation'
                fullWidth
              />
            </Collapse>
          </Stack>
        </Stack>
      </FormikProvider>
    )
  );
}

function propertyFields(fields = {}) {
  const {
    invoice_number = '',
    invoice_date = Date.now(),
    invoice_amount = '',
    tax_amount = '',
    paid = false,
    payment_confirmation = '',
  } = fields;

  return {
    invoice_number: [invoice_number, yup.string()],
    invoice_date: [invoice_date, yup.number()],
    invoice_amount: [invoice_amount, yup.number()],
    tax_amount: [tax_amount, yup.number()],
    paid: [paid, yup.bool()],
    payment_confirmation: [payment_confirmation, yup.string()],
  };
}

function DeliveryForm(props) {
  const location = useLocation();

  const [, submitForm] = useFormSubmissionHook();
  const { thing_id } = location.state ?? {};

  const things = useCompanyThings();

  const onThingAdded = useCallback(
    (thing) => {
      // TODO: Load policies
    },
    [],
  );

  const [
    [destinationThings],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Material & Services',
    filter: {
      thing_types: [
        THING_TYPES.TRACKED,
        THING_TYPES.RAW,
        THING_TYPES.EXPIRING,
        THING_TYPES.CONSUMABLE,
        THING_TYPES.SERVICES,
        THING_TYPES.FINISHED,
      ],
    },
    submitForm,
    assetAdapter: toTaggedAsset,
    assetFilter: (asset) =>
      [
        ASSET_TYPES.PALLET,
        ASSET_TYPES.IBC,
        ASSET_TYPES.CASK,
        ASSET_TYPES.TANKER,
        ASSET_TYPES.CONTAINER,
      ].includes(asset.asset_type),
    ThingModal: DeliveryThingModal,
    AssetModal: DeliveryDestinationAssetModal,
    parentAssetSupport: true,
    onThingAdded,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      properties: propertyFields(initialValues?.properties),
      policies: [initialValues?.policies, yup.array()],
      client: [initialValues?.client, yup.object()]
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      const { things = [], assets = [] } = entity?.destinations ?? {};

      if (thing_id) {
        const exists = things.find((s) => s._id === thing_id);
        if (!exists) {
          const selectedThing = things.find((s) => s._id === thing_id);
          if (selectedThing) {
            things.push(selectedThing);
          }
        }
      }

      initDestinations({
        ...entity,
        things: things.map((i) => {
          return {
            ...(i?.thing ?? {}),
            ...i,
            entries: i.entries.map((e) => ({
              ...e,
              storage: e.storage.map((s) => ({
                ...(i?.asset ?? {}),
                ...s
              })),
            })),
          };
        }),
        assets,
      });
    },
    [thing_id, things, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      destinations: {
        things: destinationThings,
        assets: destinationAssets,
      },
    }),
    [destinationThings, destinationAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel='Delivery'
        requestType={REQUEST_TYPES.DELIVERY}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <DestinationInventory />

        <Properties />

        <PolicySelector />
      </RequestForm>

      <DestinationModals />
    </>
  );
}

export default withAppLayout(DeliveryForm, { title: 'Deliveries' });
