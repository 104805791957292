import swapKeyValue from '@pw/utilities/swapKeyValue';

export const REQUEST_TYPES = {
  SAMPLE: 'sample', //"1",
  REGAUGE: 'regauge', //"2",
  TRANSFER: 'transfer', //"3",
  PICK: 'pick', //"4",
  SHIP: 'ship', //"5",
  PRODUCTION_ORDER: 'production_order', //"6",
  CHANGE_OWNERSHIP: 'change_ownership', //"8",
  RESTOCK: 'restock', //"9",
  INVENTORY_AUDIT: 'inventory_audit', //"10",
  DELIVERY: 'delivery', //"11",
  DUTY_TRANSFER: 'duty_transfer', //"12",
  REPORT_DEFECT: 'report_defect', //"13",
  DUTY_SUBMISSION: 'duty_submission', //"14",
  INVOICE: 'invoice', //"15",
  PURCHASE_ORDER: 'purchase_order', //"16",
  EXPENSE_REPORT: 'expense_report', //"17",
  PACK: 'pack', //"18",
  SALES_ORDER: 'sales_order', //"19",
  DISPATCH: 'dispatch', //"20",
  MAINTENANCE: 'maintenance', //"21",
  OWNERSHIP_AUDIT: 'ownership_audit', //"22",
  INVOICE_PAYMENT: 'invoice_payment',
  SALES_RETURN: 'sales_return',
  PURCHASE_RETURN: 'purchase_return',
};

export const REQUEST_NAMES = {
  [REQUEST_TYPES.SAMPLE]: 'Sample',
  [REQUEST_TYPES.REGAUGE]: 'Reguage',
  [REQUEST_TYPES.TRANSFER]: 'Transfer',
  [REQUEST_TYPES.PICK]: 'Pick',
  [REQUEST_TYPES.SHIP]: 'Ship',
  [REQUEST_TYPES.PRODUCTION_ORDER]: 'Production Order',
  [REQUEST_TYPES.CHANGE_OWNERSHIP]: 'Change Ownership',
  [REQUEST_TYPES.RESTOCK]: 'Restock',
  [REQUEST_TYPES.INVENTORY_AUDIT]: 'Inventory Audit',
  [REQUEST_TYPES.DELIVERY]: 'Delivery',
  [REQUEST_TYPES.DUTY_TRANSFER]: 'Duty Transfer',
  [REQUEST_TYPES.REPORT_DEFECT]: 'Report Defect',
  [REQUEST_TYPES.DUTY_SUBMISSION]: 'Duty Submission',
  [REQUEST_TYPES.INVOICE]: 'Invoice',
  [REQUEST_TYPES.PURCHASE_ORDER]: 'Purchase Order',
  [REQUEST_TYPES.EXPENSE_REPORT]: 'Expense Report',
  [REQUEST_TYPES.PACK]: 'Pack',
  [REQUEST_TYPES.INVOICE_PAYMENT]: 'Invoice Payment',
  [REQUEST_TYPES.SALES_ORDER]: 'Sales Order',
  [REQUEST_TYPES.DISPATCH]: 'Dispatch',
  [REQUEST_TYPES.MAINTENANCE]: 'Maintenance',
  [REQUEST_TYPES.OWNERSHIP_AUDIT]: 'Ownership Audit',
  [REQUEST_TYPES.SALES_ORDER]: 'Sales Return',
  [REQUEST_TYPES.PURCHASE_RETURN]: 'Purchase Return',
};

export const REQUESTS_WITH_POLICIES = {
  [REQUEST_TYPES.SAMPLE]: 'Sample',
  [REQUEST_TYPES.REGAUGE]: 'Reguage',
  [REQUEST_TYPES.TRANSFER]: 'Transfer',
  [REQUEST_TYPES.SHIP]: 'Ship',
  [REQUEST_TYPES.RESTOCK]: 'Restock',
  [REQUEST_TYPES.DELIVERY]: 'Delivery',
  [REQUEST_TYPES.PACK]: 'Pack',
  [REQUEST_TYPES.DISPATCH]: 'Dispatch',
  [REQUEST_TYPES.MAINTENANCE]: 'Maintenance',
};

export const REQUEST_EDIT_URL = {
  [REQUEST_TYPES.SAMPLE]: '/app/sample',
  [REQUEST_TYPES.REGAUGE]: '/app/regauge',
  [REQUEST_TYPES.TRANSFER]: '/app/transfer',
  [REQUEST_TYPES.PICK]: '/app/pick',
  [REQUEST_TYPES.SHIP]: '/app/ship',
  [REQUEST_TYPES.PRODUCTION_ORDER]: '/app/production-order',
  [REQUEST_TYPES.CHANGE_OWNERSHIP]: '/app/change-ownership',
  [REQUEST_TYPES.RESTOCK]: '/app/restock',
  [REQUEST_TYPES.INVENTORY_AUDIT]: '/app/inventory-audit',
  [REQUEST_TYPES.DELIVERY]: '/app/delivery',
  [REQUEST_TYPES.DUTY_TRANSFER]: '/app/duty-transfer',
  [REQUEST_TYPES.REPORT_DEFECT]: '/app/report-defect',
  [REQUEST_TYPES.DUTY_SUBMISSION]: '/app/duty-submission',
  [REQUEST_TYPES.INVOICE]: '/app/invoice',
  [REQUEST_TYPES.PURCHASE_ORDER]: '/app/purchase-order',
  [REQUEST_TYPES.EXPENSE_REPORT]: '/app/expense-report',
  [REQUEST_TYPES.PACK]: '/app/pack',
  [REQUEST_TYPES.SALES_ORDER]: '/app/sales-order',
  [REQUEST_TYPES.DISPATCH]: '/app/dispatch',
  [REQUEST_TYPES.MAINTENANCE]: '/app/maintenance',
  [REQUEST_TYPES.OWNERSHIP_AUDIT]: '/app/ownership-audit',
  [REQUEST_TYPES.SALES_RETURN]: '/app/sales-return',
  [REQUEST_TYPES.PURCHASE_RETURN]: '/app/purchase-return',
};

export const REQUEST_STATUS = {
  PENDING: 'pending', // "0",
  IN_PROGRESS: 'in_progress', // "1",
  AWAITING_APPROVAL: 'awaiting_approval', // "2",
  DONE: 'done', // "3",
  REJECTED: 'rejected', // "4",
  CANCELLED: 'cancelled', // "5",
  ARCHIVED: 'archived', // "6",
};

export const ASSET_PROCESSED_STATUS = {
  PENDING: 'pending', //"0",
  CONFIRMED: 'confirmed', //"1",
  ERROR: 'error', //"2",
};

export const LOSS_INDICATOR_ENUM = {
  EXPECTED: '0', // exp === bl
  LOSS: '1', // exp < bl
  GAIN: '2', // exp > bl
};

export const REQUEST_TYPES_REVERSE = swapKeyValue(REQUEST_TYPES);

export const PROGRESS_POINT = {
  CREATED: 'created',
  SCHEDULED: 'scheduled',
  START: 'start',
  PAUSED: 'paused',
  RESUMED: 'resumed',
  COMPLETED: 'completed',
  DUE: 'due',
};
