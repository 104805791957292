import Stack from '@mui/material/Stack';
import Avatar from '@pw/components/Avatar';
import SvgBottleFiller from '@pw/components/icons/BottleFiller';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import SvgPlant from '@pw/components/icons/Plant';
import { Body1, Body3 } from '@pw/components/Typography';
import DesignEditor from '@pw/components_v2/CompanyView/modals/plant/DesignEditor';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { DESIGN_TYPE } from '@pw/consts/designs';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { removeDesignThunk } from '@pw/redux/thunks/designs';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCompanyDesignsQuery } from '@pw/utilities/hooks/service/useCompanyDesignsQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';

function Design({ item  }) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.75}
    >
      {item?.type === DESIGN_TYPE.BOTTLING_LINE && (
        <SvgBottleFiller height={24} width={24} />
      )}
      {item?.type === DESIGN_TYPE.DISTILLERY && (
        <SvgPlant height={24} width={24} />
      )}
      <Stack>
        <EntryTitle value={item?.name} />
        <EntryLabel value={item?.description} />
      </Stack>
    </Stack>
  );
}

function PlantView({ company }) {
  const confirm = useConfirm();

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.SPECIFICATIONS, PermissionType.MODIFY);

  // Check if company has either
  const [design_types, design_options] = useMemo(() => {
    const types = [];
    const options = [];

    if (company.features?.bottling) {
      types.push(DESIGN_TYPE.BOTTLING_LINE);
      options.push({ label: 'Bottling', value: DESIGN_TYPE.BOTTLING_LINE });
    }
    if (company.features?.production) {
      types.push(DESIGN_TYPE.DISTILLERY);
      options.push({ label: 'Distillation', value: DESIGN_TYPE.DISTILLERY });
    }
    return [types, options];
  }, [company]);

  const { isLoading, data = [] } = useCompanyDesignsQuery({ company_id: company?._id, filter_types: design_types });

  const [designs, initDesigns, , upsertDesigns, removeDesigns] = useItemListManager({
    initialData: [],
  });
  const { items = [] } = data ?? {};

  const [design, setDesign] = useState(null);

  useEffect(() => {
    console.log('Designs', items);
    if (!isLoading && items.length > 0) {
      initDesigns(items);
    }
  }, [initDesigns, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((design) => {
      setDesign(design);
      showEditor();
    }, [setDesign]);

    const handleRemove = useCallback(
      (design) => {
        const body = {
          _id: design._id
        };
        console.log('Remove design', body);

        confirm({
          title: 'Remove Design',
          content: (
            <Stack>
              <Body1>{`Remove design ${design.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          )
        })
        .then(() => dispatch(removeDesignThunk(body)).unwrap().then(removeDesigns))
        .catch(() => {
        });
      },
      [removeDesigns]
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {
          onClose();
          setDesign({
            name
          });
          showEditor();
        },
        [setDesign]
      );

      return (
        <AddNewField label="Add New Design" id="new_design" onAddNew={handleAddNew} fullWidth
                     disabled={readonly} />
      );
    };

    const tools = [
      {
        title: 'Edit design',
        Icon: SvgEdit,
        handler: handleEdit
      },
      {
        title: 'Remove design',
        Icon: SvgDelete,
        handler: handleRemove
      }
    ];

    return (
      <ToolbarSection title="Designs" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={Design} items={designs} tools={tools} identity={(i) => i._id}
                   readonly={readonly} />
      </ToolbarSection>
    );
  };

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((design) => {
      console.log('design updated', design);
      if (design) {
        debounce(() => {
          upsertDesigns(design);
        }, 25);
      }
      hideEditor();
      setDesign(null);
    }, [setDesign, upsertDesigns]);

    if (!design) {
      return;
    }

    return (
      <DesignEditor entry={design} onClose={handleClose} company={company} readonly={readonly} options={design_options}  />
    );
  };

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default PlantView;
