import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
// import { Connector } from '@pw/components/BatchUpload/Wizard';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import DropZone from '@pw/components/Uploader/DropZone';
import FieldMapper from '@pw/components/Uploader/FieldMapper';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import { useState } from 'react';

export const Connector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: 0,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderWidth: 0,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 0,
  },
}));

const steps = ['Upload', 'Map', 'Preview'];

const useUploaderHook = ({
  model,
  processorProperties = {},
  ProcessorContent,
  onClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [rawData, setRawData] = useState(null);
  const [mappings, setMappings] = useState(null);

  const isMobile = useIsMobile();

  const setData = (data) => {
    console.log('File data', data);
    setRawData(data);
    setActiveStep(1);
  };

  const handleMappings = (m) => {
    console.log('Mappings', m);
    setMappings(m);
    setActiveStep(2);
  };

  const UploaderModal = ({ open, title }) => (
    <ModalWithClose open={open} onClose={() => onClose()} title={title}>
      <Stack spacing={2}>
        <Stepper
          alternativeLabel={isMobile}
          activeStep={activeStep}
          connector={<Connector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className='stepIcon'>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && <DropZone setData={setData} />}
        {activeStep === 1 && (
          <FieldMapper
            data={rawData}
            model={model}
            onCancel={() => onClose()}
            onConfirm={handleMappings}
          />
        )}
        {activeStep === 2 && (
          <ProcessorContent
            data={rawData}
            model={model}
            mappings={mappings}
            onCancel={() => onClose()}
            onConfirm={onClose}
            {...processorProperties}
          />
        )}
      </Stack>
    </ModalWithClose>
  );

  return [UploaderModal];
};

export default useUploaderHook;
