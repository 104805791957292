import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useLiquidTypes from '@pw/compliance/useLiquidTypes';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import {
  FormikDatePicker,
  FormikForm,
  FormikSelect,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { ASSET_TYPES } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { convertSourceAsset } from '@pw/pages/app/transfer';
import { useCompanyThings } from '@pw/redux/containers/User';
import { mt2 } from '@pw/styles/margins';
import debounce from '@pw/utilities/debounce';
import generateTokenId from '@pw/utilities/generateTokenId';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import SpecificationSelectorModal from '../Specification/SpecificationSelectorModal';
import MashThingModal from '@pw/components/ThingSelector/modals/MashThingModal';

function LiquidSelector({ things = [], assets = [] }) {
  const { setFieldValue } = useContext(FormikContext);
  const liquidTypes = useLiquidTypes();
  const companyThings = useCompanyThings();

  useEffect(() => {
    console.log('things', things);
    console.log('assets', assets);

    const uniqueliquids = [];

    // Get the set of liquid things here and if there is only one liquid type, suggest that, else allow user to select the liquid type..
    const thingsIds = things.map((thing) => thing?._id);
    for (const asset of assets) {
      const skuId = asset.properties?.liquid?.sku_id;
      if (skuId && !thingsIds.includes(skuId)) {
        thingsIds.push(skuId);
      }
    }
    const assetLiquidSkus = companyThings.filter((thing) =>
      thingsIds.includes(thing._id),
    );

    for (const sku of assetLiquidSkus) {
      const liquidType = sku.properties?.liquid_type;
      if (liquidType && !uniqueliquids.includes(liquidType)) {
        uniqueliquids.push(liquidType);
      }
    }

    console.log('uniqueliquids', uniqueliquids);
    if (uniqueliquids.length === 1) {
      debounce(() => setFieldValue('liquid_type', uniqueliquids[0]), 250);
    }
  }, [things, assets]);

  return (
    <FormikSelect
      label='Liquid type'
      name='liquid_type'
      options={liquidTypes}
      fullWidth
    />
  );
}

function ReleaseModal({ title, item, open, onClose }) {
  const { id, name, date, liquid_type } = item ?? {};

  const label = name ? `Update ${title}` : `New ${title}`;

  console.log('Releases for', item);

  const changeSet = useMemo(
    () => ({
      name: [name ?? '', yup.string().required('Name required!')],
      date: [date ?? Date.now(), yup.number().required('Date required!')],
      liquid_type: [
        liquid_type ?? '',
        yup.string().required('Liquid type required!'),
      ],
    }),
    [name, date, liquid_type],
  );

  const sourceProps = useMemo(
    () => ({
      title: 'BOM',
      filter: {
        asset_types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC],
        thing_types: [THING_TYPES.CONSUMABLE, THING_TYPES.LIQUID],
      },
      assetFilter: (a) => [ASSET_TYPES.IBC, ASSET_TYPES.CASK].includes(a.type),
      // submitForm,
      assetAdapter: convertSourceAsset,
      SKUModal: MashThingModal,
      AssetModal: SpecificationSelectorModal,
    }),
    [],
  );

  const [[sourceThings], [sourceAssets], init, SourceInventory, SourceModals] =
    useInventorySelectorHook(sourceProps);

  const handleSubmit = (values) => {
    const newRelease = {
      id: id ?? generateTokenId(),
      things: sourceThings,
      assets: sourceAssets,
      ...values,
    };
    console.log('Setting release', newRelease);
    onClose(newRelease);
  };

  useEffect(() => {
    init({
      assets: item?.assets,
      things: item?.things,
    });
  }, [item?.assets, item?.things]);

  return (
    <>
      <ModalWithClose open={open} onClose={onClose} title={label}>
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FlexBox alignItems='top'>
              <FormikTextField label='Name' name='name' fullWidth />
              <FormikDatePicker
                sx={{ ...mt2 }}
                name='date'
                label='Release Date'
                fullWidth
              />
            </FlexBox>

            <SourceInventory />

            <LiquidSelector skus={sourceThings} assets={sourceAssets} />

            <Errors />

            <Box className='action-buttons'>
              <TextButton
                size='small'
                handleClick={() => onClose()}
                color='secondary'
              >
                Cancel
              </TextButton>
              <FilledButton type='submit' size='small'>
                Save
              </FilledButton>
            </Box>
          </Stack>
        </FormikForm>
      </ModalWithClose>
      <SourceModals />
    </>
  );
}

export default ReleaseModal;
