import { Box, Typography } from '@mui/material';

const styles = {
  container: {
    paddingTop: '16px',
  },
  itemWrapper: {
    padding: '8px 0',
    borderBottom: '1px solid #E0E0E0',
  },
  lastItemWrapper: {
    borderBottom: 'none',
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  colorBox: {
    width: '15px',
    height: '14px',
    borderRadius: '2px',
    marginRight: '12px',
  },
  nameText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333333',
  },
  countText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#333333',
  },
};

const LayoutAssetCountsWidget = ({ data }) => {
  return (
    <Box sx={styles.container}>
      {data.map((item, index) => (
        <Box
          key={index}
          sx={{
            ...styles.itemWrapper,
            ...(index === data.length - 1 ? styles.lastItemWrapper : {}),
          }}
        >
          <Box sx={styles.itemContent}>
            <Box sx={styles.nameContainer}>
              <Box sx={{ ...styles.colorBox, backgroundColor: item.color }} />
              <Typography sx={styles.nameText}>{item.name}:</Typography>
            </Box>

            <Typography sx={styles.countText}>{item.count}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default LayoutAssetCountsWidget;
