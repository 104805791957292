import { ClearAll, Remove } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SimpleTreeView } from '@mui/x-tree-view';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import AiGenerate from '@pw/components/icons/AiGenerate';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import BayModal, { renderBays } from '@pw/components_v2/CompanyView/modals/facilities/BayModal';
import TeamEditor from '@pw/components_v2/CompanyView/modals/users/TeamEditor';
import TeamView from '@pw/components_v2/CompanyView/modals/users/TeamView';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import { FACILITY_STATUS, FACILITY_STATUS_OPTIONS, FACILITY_TYPE, FACILITY_TYPES_OPTIONS } from '@pw/consts/facility';
import { upsertFacilityThunk } from '@pw/redux/thunks/facilities';
import { removeTeamThunk } from '@pw/redux/thunks/teams';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function FacilityEditor({ item, onClose, readonly }) {
  const dispatch = useDispatch();

  const editing = useMemo(() => !!item?._id, [item]);
  const { bays: existingBays = [] } = item ?? {};

  const [bays, init, , upsert] = useItemListManager({
    id: ID.name,
    comp: COMP.name,
    initialData: existingBays ?? [],
  });

  const [generateBays, setGenerateBays] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const changeSet = {
    name: [
      item?.name ?? '',
      yup.string().min(1).required('Facility name is required!'),
    ],
    type: [item?.type ?? FACILITY_TYPE.WAREHOUSE, yup.string()],
    status: [item?.status ?? FACILITY_STATUS.ACTIVE, yup.string()],
  };

  const handleSelectedItemsChange = (event, ids) => setSelectedItems(ids);

  const handleSubmit = useCallback(
    (values, { resetForm }) => {
      console.log('Submitting', values);
      dispatch(
        upsertFacilityThunk({
          ...item,
          ...values,
          bays,
        }),
      )
        .unwrap()
        .then(() => {
          resetForm({ values: {} });
          onClose();
        });
    },
    [bays, item],
  );

  const handleClose = useCallback(
    (b) => {
      if (b) {
        upsert(b);
      }
      setGenerateBays(false);
    },
    [upsert],
  );

  const Toolbar = ({ onClose }) => {
    const confirm = useConfirm();

    const handleGenerate = useCallback(
      (team) => {
        onClose();
        setGenerateBays(true);
      },
      [setGenerateBays],
    );

    const removeSelected = useCallback(() => {
      onClose();
      confirm({
        title: 'Remove Locations',
        content: (
          <Stack>
            <Body1>{`Remove selected locations?`}</Body1>
            <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
          </Stack>
        ),
      })
      .then(() => {
        const updatedBays = [];
        for (const b of bays) {
          if (selectedItems.includes(`${b.name}`)) {
            continue;
          }
          const updatedRows = [];
          for (const r of b.rows) {
            if (selectedItems.includes(`${b.name}.${r.name}`)) {
              continue;
            }
            const updatedLevels = [];
            for (const l of r.levels) {
              if (selectedItems.includes(`${b.name}.${r.name}.${l.name}`)) {
                continue;
              }
              updatedLevels.push(l);
            }
            updatedRows.push({
              name: r.name,
              levels: updatedLevels,
            });
          }
          updatedBays.push({
            name: b.name,
            rows: updatedRows,
          });
        }
        // Update the bays
        debounce(() => init(updatedBays), 25);
      })
      .catch(() => {});
    }, [selectedItems, bays, init]);

    const clearAll = useCallback(() => {
      onClose();
      confirm({
        title: 'Remove Locations',
        content: (
          <Stack>
            <Body1>{`Remove all locations?`}</Body1>
            <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
          </Stack>
        ),
      })
      .then(() => {
        debounce(() => init([]), 25);
      })
      .catch(() => {});
    }, [init]);

    return (
      <>
        <TitledButton
          handleClick={handleGenerate}
          disabled={readonly}
          label='Generate'
        >
          <AiGenerate height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={removeSelected}
          disabled={bays.length === 0 || readonly}
          label='Remove'
        >
          <Remove height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={clearAll}
          disabled={bays.length === 0 || readonly}
          label='Clear'
        >
          <ClearAll height={24} width={24} />
        </TitledButton>
      </>
    );
  }

  return (
    <Box className='form-content'>
      <FlexBox>
        <H5>{item?.name}</H5>
      </FlexBox>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack className='form' spacing='2rem'>
          <FlexBox alignItems='top'>
            <FormikSelect
              disabled={editing}
              label='Type'
              name='type'
              options={FACILITY_TYPES_OPTIONS}
              fullWidth
            />
            <FormikSelect
              label='Status'
              name='status'
              options={FACILITY_STATUS_OPTIONS}
              fullWidth
            />
          </FlexBox>

          <ToolbarSection title="Bays" Toolbar={Toolbar}>
            {bays.length > 0 && (
              <Box
                sx={{ maxHeight: 350, minWidth: '100%', overflow: 'auto' }}
              >
                <SimpleTreeView
                  onSelectedItemsChange={handleSelectedItemsChange}
                  multiSelect
                >
                  {renderBays(bays)}
                </SimpleTreeView>
              </Box>
            )}
          </ToolbarSection>

          <FormButtons onCancel={onClose} readonly={readonly} />
        </Stack>
      </FormikForm>
      {generateBays && (
        <BayModal open={generateBays} existing={bays} onClose={handleClose} />
      )}
    </Box>
  );
}

export default FacilityEditor;
