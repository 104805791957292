import { Box } from '@mui/material';
import ThingSelector from '@pw/components/ThingSelector';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useContext, useEffect } from 'react';
import isEqual from 'lodash.isequal';

function ThingField({
  title,
  types,
  tags = false,
  entity = null,
  name = 'thing',
  itemFilter = (x) => x,
  disabled=false
}) {
  const { values, setFieldValue } = useContext(FormikContext);
  console.log('ThingField:', name, title, entity);

  const [thing, init, addThing, , removeThing] = useItemListManager({
    initialData: values?.[name] ? [values[name]] : [],
    hooks: {
      onRemove: () => {
        debounce(() => {
          if(tags){
            setFieldValue('tags', []);
          }
          setFieldValue(name, {});
        }, 50);
      },
      onAdd: (items) => {
        if (items.length === 1) {
          debounce(() => {
            const { _id, tags: thingTags, name: thingName, type, description } = items[0];
            if(tags){
              setFieldValue('tags', thingTags);
            }
            setFieldValue(name, {
              _id,
              tags: thingTags,
              name: thingName,
              type,
              description,
            });
          }, 50);
        }
      },
      onUpsert: (items) => {
        if (items.length === 1) {
          debounce(() => {
            const { _id, tags: thingTags = [], name: thingName, type, description } = items[0];
            if(tags){
              setFieldValue('tags', thingTags);
            }
            setFieldValue(name, {
              _id,
              tags: thingTags,
              name: thingName,
              type,
              description,
            });
          }, 50);
        }
      },
    },
  });

  useEffect(() => {
    const valueThing = !!Object.keys(values[name] ?? {}).length ? [values[name]] : [];
    if (!isEqual(valueThing, thing)) {
      init(valueThing);
    }
  }, [values[name]]);


  return (
    <Box sx={{ position: 'relative' }}>
      {/*historyExist && !isSame && (
        <RevertMessage
          title={title}
          isReverted={isReverted}
          onRevert={onRevert}
          onUndo={onUndo}
          type='thing'
          item={history[name]}
        />
      )*/}
      <ThingSelector
        title={title}
        list={thing}
        onAdd={(i) => addThing(i, true)}
        onRemove={(i) => removeThing(i, true)}
        types={types}
        itemFilter={itemFilter}
        single
        summary={false}
        disabled={disabled}
      />
    </Box>
  );
}

export default ThingField;
