import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Overline } from '@pw/components/Typography';
import ContactSearch from '@pw/components_v2/search/contact';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';

export function AssetAccountsImp() {
	return (
		<Stack spacing='1.5rem' sx={{ ...mt2 }}>
			<Divider textAlign="center"><Overline>Accounts</Overline></Divider>
			<ContactSearch field="bo" label="Deed Owner" />
			<ContactSearch field="mo" label="Managing Agent" />
			<ContactSearch field="ro" label="Duty Representative" />
		</Stack>
	);
}

export default function AssetAccounts({ name = 'accounts', entity = null, disabled = false, ...rest }) {
	return (
		<FormikProvider path={name}>
			<AssetAccountsImp {...rest} disabled={disabled} />
		</FormikProvider>
	);
}
