import * as yup from 'yup';

export function baseUnitFields(fields = {}) {
  const { liq, mas, alc, tmp, tim, bl, la } = fields;
  return {
    liq: [liq, yup.string()],
    mas: [mas, yup.string()],
    alc: [alc, yup.string()],
    tmp: [tmp, yup.string()],
    tim: [tim, yup.string()],
    bl: [bl, yup.string()],
    la: [la, yup.string()],
  };
}
