import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function Part4Report({
	name,
	title = 'On Hand First of Month',
	disabled,
	specify = false,
	columnC = false,
	columnE = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Kind'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='b'
					label='Bulk Spirits Dumped Into Processing '
					fullWidth
				/>
				<FormikTextField
					name='c'
					label='Bottled - Imported'
					fullWidth
					sx={{ ...(columnC && styles.transaction) }}
				/>
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField name='d' label='Bottled' fullWidth />
				<FormikTextField
					name='e'
					label='Bottled in Bond '
					fullWidth
					sx={{ ...(columnE && styles.transaction) }}
				/>
				<FormikTextField name='f' label='Bottled for Export' fullWidth />
			</FlexBox>
		</FormikProvider>
	);
}
