function SvgPreparation(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M9.5 47.5l-8-15 8-15h44l8 15-8 15z'
        fill='#fdc'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgPreparation;
