import generalInformationFields from './generalInformationFields';
import section1Fields from './section1Fields';
import section2Fields from './section2Fields';
import section3Fields from './section3Fields';
import signatureFields from './signatureFields';
import * as yup from 'yup';

export default function TTBReport_43Fields(fields = {}, company, companyname) {
	const {
		section1,
		section2,
		section3,
		signature,
		formUrlId = '1737334',
	} = fields;

	const defaultValues = {
		PROPRIETOR: companyname ?? '',
		DBA: company?.account_name ?? '',
		STREET: company?.company_address.address_1 ?? '',
		CITY: company?.company_address.city ?? '',
		STATE: company?.company_address.state ?? '',
		ZIP: company?.company_address.post_code ?? '',
		PLANT_NUMBER: company.company_compliance.compliance_id ?? '',
	};
	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields({...defaultValues}),
		section1: section1Fields(section1),
		section2: section2Fields(section2),
		section3: section3Fields(section3),
		signature: signatureFields(signature),
	};
}
