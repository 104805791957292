import { Stack } from '@mui/material';
import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { MASS_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      batch_size: unitField(initialValues?.batch_size),
      mesh_size: [initialValues?.mesh_size ?? 4, yup.number()],
      speed: [initialValues?.speed ?? 0, yup.number()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FormikUnitField name="batch_size" label="Batch Size" options={MASS_UNIT_OPTIONS} />
        <FlexBox>
          <FormikNumberField name="mesh_size" label="Mesh Size" fullWidth />
          <FormikNumberField name="speed" label="Hammer Speed" fullWidth />
        </FlexBox>
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
