import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1 } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

const styles = {
  widgetContent: {
    borderBottom: '1px solid #DDDDDD',
  },
  label: {
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '31px',
  },
  secondaryText: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '31px',
    color: '#393C3C',
  },
  primaryText: {
    fontWeight: '600',
    fontSize: 'inherit',
    color: 'inherit',
  },
};

function LayoutStatsWidget({ primary, secondary, label, border = true }) {
  return (
    <Stack spacing={1} className='widget stats'>
      <Box
        className='widget-content stats-content'
        sx={{ ...styles.widgetContent, borderBottom: border ? styles.widgetContent.borderBottom : 'none' }}
      >
        <FlexBox style={{ width: 'auto' }}>
          <Body1 sx={styles.label}>{label}</Body1>
          <Typography sx={styles.secondaryText}>
            {`${formatNumber(secondary.value)} ${secondary.unit}`}/
            <Typography component='span' sx={styles.primaryText}>
              {`${formatNumber(primary.value)} ${primary.unit}`}
            </Typography>
          </Typography>
        </FlexBox>
      </Box>
    </Stack>
  );
}

export default LayoutStatsWidget;
