import { COMPANY_TYPE } from '@pw/consts/company';
import { useOnlineHook } from '@pw/redux/containers/App';
import { useAccountId, useCurrentCompany } from '@pw/redux/containers/User';
import isEmpty from 'lodash.isempty';
import { Navigate, useLocation, useParams } from 'react-router-dom';

export function AuthGuard({
  children,
  hasPermission = true,
  features = [],
  types = [],
  online = false,
}) {
  const params = useParams();
  const location = useLocation();
  const { online: isOnline } = useOnlineHook();

  const accountId = useAccountId();
  const isAuth = !isEmpty(accountId);

  const currentCompany = useCurrentCompany();
  console.log('Current company', currentCompany);
  const { company_type = COMPANY_TYPE.VIEW, company_features = {} } =
    currentCompany || {};

  // const features = company_features.warehousing || company_features.production || company_features.bottling;
  const matchesType = types.length === 0 || types.includes(company_type);
  const matchesFeatures =
    features.length === 0 || features.some((f) => company_features[f]);

  console.debug(
    'User',
    isAuth,
    isOnline,
    hasPermission,
    matchesType,
    matchesFeatures,
    features,
    company_features,
  );

  // If not authenticated, then route to sign in
  if (!isAuth)
    return <Navigate to='/signin' replace state={{ from: location }} />;
  if (!hasPermission)
    return <Navigate to='/not-found' replace state={{ from: location }} />;
  // if (!matchesType || !matchesFeatures)
  // 	return <Navigate to='/upgrade' replace state={{ from: location }} />;
  if (online && !isOnline)
    return <Navigate to='/not-online' replace state={{ from: location }} />;

  return <div key={JSON.stringify(params)}>{children}</div>;
}

export default AuthGuard;
