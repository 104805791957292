import Address from '@pw/components/Address';
import { addressFields } from '@pw/components/Address/addressFields';
import { clientFields } from '@pw/components/Client/clientFields';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function propertyFields(fields = {}) {
  const { destination = {}, duty_due = false } = fields;

  return {
    destination: addressFields(destination),
    duty_due: [duty_due, yup.boolean()],
  };
}

function Properties() {
  const { t } = useTranslation();

  return (
    <FormikProvider path='properties'>
      <Address name='destination' label='Destination' />
      <FormikCheckBox name='duty_due' label={t('dutyDue')} />
    </FormikProvider>
  );
}

function ShipForm(props) {
  const [
    [sourceThings],
    [sourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Order',
    filter: {
      asset_types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC, ASSET_TYPES.TANKER],
      thing_types: [
        THING_TYPES.WASTE,
        THING_TYPES.FINISHED,
        THING_TYPES.TRACKED,
      ],
    },
    ThingModal: SourceThingModal,
  });

  const [
    [destinationThings],
    [],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Services',
    filter: {
      thing_types: [THING_TYPES.SERVICES],
    },
    thingModalProps: { calculateThingLiquid: true },
    ThingModal: DestinationThingModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      properties: propertyFields(initialValues?.properties),
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      initDestinations({ ...entity, things: entity?.destinations?.things });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        things: sourceThings,
        assets: sourceAssets,
      },
      destinations: {
        things: destinationThings,
      },
    }),
    [sourceThings, sourceAssets, destinationThings],
  );

  return (
    <>
      <RequestForm
        requestLabel='Sales Order'
        requestType={REQUEST_TYPES.SALES_ORDER}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <DestinationInventory />

        <Properties />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(ShipForm, { title: 'Shipments' });
