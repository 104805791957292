function SvgLineTank(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#LineTank_svg__clip0_146_107)'>
        <mask
          id='LineTank_svg__a'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={28}
          height={28}
        >
          <path d='M28 0H0v28h28V0z' fill='#fff' />
        </mask>
        <g mask='url(#LineTank_svg__a)'>
          <path
            d='M26.85 1.9c-1.23-.65-2.57-.95-3.6-1.13-3.88-.69-7.91-.91-12.32-.68-1.79.09-4.14.26-6.46.73-1.05.21-2.17.48-3.21 1.02C.54 2.21.16 2.6 0 3.15v21.81c.29.76.94 1.09 1.65 1.4 1.22.53 2.5.8 4.04 1.04 2.11.34 4.3.52 6.68.57.08 0 .16.01.23.03.03 0 .05 0 .08.01h2.87l1.87-.11c2-.12 4.35-.31 6.64-.83.99-.22 2.05-.51 3-1.08.66-.39.95-.87.94-1.59-.02-7.48-.02-14.23 0-20.65 0-.86-.34-1.41-1.15-1.84V1.9zm-25.67.82c.64-.33 1.21-.56 1.74-.73 1.72-.52 3.46-.76 4.93-.92 3.06-.33 6.19-.4 9.59-.22 2.12.11 4.9.35 7.59 1.12.38.11.74.26 1.1.4.12.05.25.1.37.15.13.05.25.13.35.2.15.11.36.28.35.52 0 .27-.27.44-.35.5-.23.15-.47.28-.72.38-1.23.51-2.52.77-3.62.96-2.54.44-5.26.63-8.75.63h-.63c-1.61 0-3.66-.12-5.63-.35-1.57-.18-3.41-.45-5.16-1.06-.41-.14-.8-.32-1.17-.53-.27-.16-.41-.34-.4-.55 0-.14.08-.34.41-.51v.01zm2.36 23.45l-.39-.11c-.71-.2-1.44-.41-2.06-.86a.838.838 0 01-.31-.63c-.01-6.02 0-12.13 0-18.05V4.98v-.14-.04l.02-.28 2.75 1.01v20.64h-.01zm.77-18.98c.01-.29 0-.59 0-.88v-.6l5.13.67v1.29h-.19s-.05.02-.08.02c-.06 0-.12.01-.18.01h-4.2c-.12 0-.28-.02-.38-.13-.11-.11-.12-.27-.11-.38h.01zm.02 8.4h5.12v2.75H4.33v-2.75zm5.13 11.47l-.22-.02c-1.65-.12-3.19-.33-4.74-.64l-.17-.03v-3.68h5.13v4.37zm0-5.17H4.34v-2.75h5.12v2.75zm0-7.11H4.34v-2.75h5.12v2.75zm0-3.55H4.34V8.48h5.12v2.75zm17.3 14.09c-.77.46-1.62.69-2.36.87-2.59.64-5.45.96-8.98 1.02h-1.13c-.76 0-1.53 0-2.28-.02-.52 0-1.04-.01-1.56-.02h-.21V6.42h1.01c2.33.13 4.72.09 7.31-.11 2.29-.18 4.03-.42 5.64-.79.71-.16 1.64-.41 2.49-.85.05-.02.09-.04.14-.06.02 0 .05-.02.08-.03l.27-.11v.29s.02.08.02.11v19.61c0 .38-.15.64-.47.83l.03.01z'
            fill='currentColor'
          />
        </g>
        <path
          d='M12.97 10.29h.75v4.12h1.24v.69h-2v-4.81h.01zM15.29 10.29h.76v4.81h-.76v-4.81zM17.28 11.62v3.48h-.68v-4.81h.95l.76 2.88h.01v-2.88h.67v4.81h-.78l-.93-3.48zM20.29 12.32h1.04v.69h-1.04v1.41h1.3v.69h-2.06V10.3h2.06v.69h-1.3v1.34-.01zM12.75 17.29h2.33v.69h-.79v4.12h-.76v-4.12h-.79v-.69h.01zM17.6 22.1h-.76l-.13-.87h-.93l-.13.87h-.69l.77-4.81h1.11l.77 4.81h-.01zm-1.73-1.53h.73l-.36-2.43h-.01l-.36 2.43zM18.62 18.62v3.48h-.68v-4.81h.95l.76 2.88h.01v-2.88h.67v4.81h-.78l-.93-3.48zM21.87 20.18l-.23.44v1.48h-.76v-4.81h.76v2.08h.01l.97-2.08h.76l-1.05 2.14 1.05 2.66h-.78l-.73-1.92v.01z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='LineTank_svg__clip0_146_107'>
          <path fill='#fff' d='M0 0h28v28H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgLineTank;
