import { createPolicy, removePolicy, updatePolicy } from '@pw/services/policy.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertPolicyThunk = createAsyncThunk(
  'policy/upsert',
  async (policy, { rejectWithValue, fulfillWithValue }) => {
    try {
      console.log('Upserting policy', policy);
      const response = (policy._id) ? await updatePolicy(policy) : await createPolicy(policy);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const removePolicyThunk = createAsyncThunk(
  'policy/remove',
  async (policy, { rejectWithValue }) => {
    try {
      return await removePolicy(policy);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
