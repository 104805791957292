import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import PolicyEditor from '@pw/components/PolicyEditor';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SampleProperties from '@pw/components/SampleProperties';
import sampleFields from '@pw/components/SampleProperties/sampleFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS } from '@pw/consts/units';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

function SampleForm() {
  let [searchParams] = useSearchParams();
  const asset = searchParams.get('asset');
  const [fetchAsset] = useAssetLazyQuery();
  const converter = useConverter();

  const [
    [],
    [sourceAssets, initSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Assets',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.FILLING_TANK,
      ],
    },
  });

  const [
    [destinationThings],
    [],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Services',
    filter: {
      thing_types: [THING_TYPES.SERVICES],
    },
    ThingModal: DestinationThingModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      properties: sampleFields(initialValues?.properties),
      policies: [initialValues?.policies, yup.array()],
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
      });
      initDestinations({ ...entity, things: entity?.destinations?.things });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        assets: sourceAssets,
      },
      destinations: {
        things: destinationThings,
      },
      properties: {
        ...(entity?.properties ?? {}),
        sample_size: converter.cx(
          entity?.properties?.sample?.value,
          entity?.properties?.sample?.unit,
          UNITS.milliliters,
        ),
      },
    }),
    [destinationThings, sourceAssets],
  );

  useEffect(() => {
    if (asset) {
      fetchAsset({id: asset}).then((a) => {
        debounce(() => initSourceAssets([toTaggedAsset(a)]), 25);
      });
    }
  }, [asset, initSourceAssets, fetchAsset]);

  return (
    <>
      <RequestForm
        requestLabel='Sample'
        requestType={REQUEST_TYPES.SAMPLE}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <DestinationInventory />

        <SampleProperties name='properties' />

        <PolicySelector />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(SampleForm, { title: 'Sample' });
