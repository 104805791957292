import {
  resetCustomerPassword,
  uploadOwners,
} from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const uploadOwnersThunk = createAsyncThunk(
  'owners/upload',
  async (params, { rejectWithValue }) => {
    try {
      return await uploadOwners(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetOwnerPasswordThunk = createAsyncThunk(
  'owners/resetPassword',
  async (params, { rejectWithValue }) => {
    try {
      return await resetCustomerPassword(params);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
