import { createSelector } from '@reduxjs/toolkit';
import { SLICE } from './consts';

// Selectors
export const selectPublicState = (state) => state[SLICE];

export const selectInvite = createSelector(
	selectPublicState,
	(state) => state?.invite,
);
