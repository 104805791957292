import Stack from '@mui/material/Stack';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1, Body3 } from '@pw/components/Typography';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { removeLedgerThunk } from '@pw/redux/thunks/ledger';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCompanyLedgersQuery } from '@pw/utilities/hooks/service/useCompanyLedgersQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LedgerEditor from './LedgerEditor';

function Ledger({ item }) {
  return (
    <Stack>
      <EntryTitle value={item?.name} />
      <EntryLabel value={item?.description} />
    </Stack>
   );
}

function LedgersView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.LEDGERS, PermissionType.MODIFY);

  const { isLoading, data } = useCompanyLedgersQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const [ledgers, initLedgers, , upsertLedgers, removeLedgers] = useItemListManager({
    id: ID._id,
    comp: COMP._id,
    initialData: items,
  });
  const [ledger, setLedger] = useState(null);

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initLedgers(items);
    }
  }, [initLedgers, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((ledger) => {
      setLedger(ledger);
      showEditor();
    }, [setLedger]);

    const handleRemove = useCallback(
      (ledger) => {
        const body = {
          _id: ledger._id,
        };
        console.log('Remove ledger', body);

        confirm({
          title: 'Remove ledger',
          content: (
            <Stack>
              <Body1>{`Remove ledger ${ledger.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          ),
        })
        .then(() => dispatch(removeLedgerThunk(body)).unwrap().then(removeLedgers))
        .catch(() => {});
      },
      [removeLedgers],
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {
          onClose();
          setLedger({
            name,
          });
          showEditor();
        },
        [setLedger],
      );

      return (
        <AddNewField label="Add New Ledger" id="new_ledger" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit ledger',
        Icon: SvgEdit,
        handler: handleEdit,
      },
      {
        title: 'Remove ledger',
        Icon: SvgDelete,
        handler: handleRemove,
      },
    ];

    return (
      <ToolbarSection title="Ledgers" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={Ledger} items={ledgers} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((ledger) => {
      console.log('Ledger updated', ledger);
      if (ledger) {
        debounce(() => {
          upsertLedgers(ledger);
        }, 25);
      }
      hideEditor();
      setLedger(null);
    }, [setLedger, upsertLedgers]);

    if (!ledger) {
      return;
    }

    return (
      <LedgerEditor entry={ledger} onClose={handleClose} company={company} readonly={readonly} />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default LedgersView;
