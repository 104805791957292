import { useMemo } from 'react';

import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ProductionDesigner from '@pw/components/ProductionDesigner';
import DesignerProvider from '@pw/providers/DesignerProvider';
import { useSearchParams } from 'react-router-dom';

import {
	flowNodes
} from '@pw/components/ProcessDesigner/nodes';
import Node from '@pw/components/ProcessDesigner/Diagram/NodeBase';
import CustomEdge from '@pw/components/ProcessDesigner/Diagram/Edges/CustomEdge';
import { getFromLS, saveToLS } from '@pw/components/ProcessDesigner/functions/localStorage';

const nodeTypes = {
	default: Node,
	flowchart: Node,
}

const edgeTypes = {
	default: CustomEdge,
	edge: CustomEdge,
}

const isValidConnection = () => true;
const loadHandler = () => {
	const recipes = getFromLS('recipe');
};
const saveHandler = (recipe) => {
	saveToLS(recipe);
};

export default withAppLayout(() => {
	const [params] = useSearchParams();
	const processId = params.get('processId');
	const mode = 'process';

	const nodeGroups = useMemo(() => {
		const ret = {};
		flowNodes.forEach((p) => {
			if (!ret[p.category]?.length) ret[p.category] = [];
			ret[p.category].push(p);
		});
		return ret;
	}, [flowNodes]);

	return (
		<DesignerProvider mode={mode} processId={processId}>
			<ProductionDesigner
				nodeGroups={nodeGroups}
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				isValidConnection={isValidConnection}
				loadHandler={loadHandler}
				saveHandler={saveHandler}
			/>
		</DesignerProvider>

	);

}, { title: 'Process Design' });
