import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Pagination from '@pw/components/Pagination';
import { Body3 } from '@pw/components/Typography';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import styles from '@pw/styles/content.styles';
import { useOwnerAssetQueryPaginated } from '@pw/utilities/hooks/service/useOwnerAssetQuery';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ContactAssetsDialog({ id, onClose }) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      limit: Number(pageSize),
      target_id: id,
    };
  }, [id, pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useOwnerAssetQueryPaginated(reqParams);

  const goToItem = (params) => {
    const id = params?._id ?? 'new';
    const route = ASSET_EDIT_URL?.[params?.type];
    navigate(`${route}/${id}`);
  };

  return (
    <ModalWithClose open title='Contact Assets' onClose={onClose}>
      <Stack sx={styles} className='root' spacing={3}>
        <Stack spacing={1}>
          {!isLoading && page && page.length > 0 && (
            <Stack spacing={0.5}>
              <Pagination
                hasNextPage={hasNext}
                hasPrevPage={hasPrev}
                onPressNextPage={loadNext}
                onPressPrevPage={loadPrev}
                onChangePageSize={setPageSize}
                defaultPageSize={pageSize}
              />
              <Stack className='list'>
                {page.map((i) => (
                  <AssetListComponentV2
                    key={i.path}
                    item={i}
                    buttonIcon={<ArrowForward />}
                    onClick={() => goToItem(i)}
                  />
                ))}
              </Stack>
            </Stack>
          )}
          {!isLoading && (!page || page.length === 0) && (
            <Body3>Nothing yet.</Body3>
          )}
          {isLoading && (
            <Stack spacing={0.5}>
              <FlexBox justifyContent='flex-end' gap='2rem'>
                <Skeleton variant='rectangular' width={45} height={45} />
                <Skeleton variant='rectangular' width={60} height={35} />
              </FlexBox>
              <Stack className='list'>
                <ListItemSkeleton type={ASSET_TYPES.CASK} />
                <ListItemSkeleton type={ASSET_TYPES.CASK} />
                <ListItemSkeleton type={ASSET_TYPES.CASK} />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </ModalWithClose>
  );
}

export default ContactAssetsDialog;
