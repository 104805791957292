/**
 * Convert from Thing to ThingItem
 * @param thing
 */

const toThingDependentItem = (thing) => {
	// Inbound Thing details
	const {
		_id,
		name,
		type,
		description,
		amount,
		unit,
	} = thing ?? {};
	// Return the ThingItem
	return {
		_id,
		name,
		type,
		description,
		amount,
		unit,
	};
};

export default toThingDependentItem;
