import Stack from '@mui/material/Stack';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1 } from '@pw/components/Typography';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';
import SpecEntryEditor from './SpecEntryEditor';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';

function SpecEntry({ item }) {
  const { name, description } = item ?? {};
  return (
    <Stack>
      <EntryTitle value={name} />
      <EntryLabel value={description} />
    </Stack>
  );
}

function SpecEntryField({ initialValues, readonly }) {
  const confirm = useConfirm();
  const [entry, setEntry] = useState(null);
  const [entries, init, , upsert, remove] = useItemListManager({
    id: ID.name,
    comp: COMP.name,
    initialData: [],
  });
  const { setFieldValue } = useContext(FormikContext);

  const handleClose = useCallback(
    (entry) => {
      if (entry) {
        upsert(entry);
      }
      setEntry(null);
    },
    [upsert, setEntry],
  );

  useEffect(() => {
    if (initialValues) {
      init(initialValues);
    }
  }, [initialValues, init]);

  useEffect(() => {
    console.log('Updating entries', entries);
    debounce(() => setFieldValue('entries', entries), 25);
  }, [entries]);

  const handleRemove = useCallback((entry) => {
    confirm({
      title: 'Remove entry',
      content: (
        <Stack>
          <Body1>{`Remove entry ${entry.name}?`}</Body1>
        </Stack>
      ),
    })
    .then(() => remove(entry))
    .catch(() => {});
  }, [remove]);

  const Toolbar = ({ onClose }) => {
    const handleAddNew = useCallback((spec) => {
      onClose();
      setEntry({
        name: spec,
      });
    }, [setEntry]);

    return (
      <AddNewField label="Add New Entry" id="new_entry" onAddNew={handleAddNew} fullWidth disabled={readonly} />
    );
  }

  const tools = [
    {
      title: 'Edit entry',
      Icon: SvgEdit,
      handler: setEntry
    },
    {
      title: 'Remove entry',
      Icon: SvgDelete,
      handler: handleRemove
    }
  ];

  return (
    <ToolbarSection title="Entries" Toolbar={Toolbar}>
      <BasicList Content={SpecEntry} items={entries} tools={tools} identity={(i) => i.name} />
      {!!entry && (
        <SpecEntryEditor
          item={entry}
          open={!!entry}
          onClose={handleClose}
          readonly={readonly}
        />
      )}
    </ToolbarSection>
  );
}

export default SpecEntryField;
