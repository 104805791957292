function SvgHumidity({color = 'black', ...props}) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox="0 0 34 45"
      fill='none'
	  className='MuiSvgIcon-root'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M15.1413 44.1523C13.81 43.9504 12.4786 43.7355 11.2163 43.2429C5.23134 40.9071 1.4946 36.5845 0.304766 30.1943C-0.614255 25.2594 0.562546 20.744 3.60031 16.7719C7.61044 11.5281 11.6656 6.31938 15.6997 1.09418C15.8934 0.843091 16.116 0.634852 16.4049 0.503906H17.2474C17.6376 0.727945 17.8882 1.08964 18.1544 1.43791C19.6912 3.44915 21.2337 5.45596 22.7663 7.47026C23.2502 8.10612 23.031 8.84678 22.3251 9.0108C21.8907 9.11185 21.6004 8.87259 21.3564 8.55579C20.6232 7.60348 19.8942 6.64776 19.1636 5.69341C18.4673 4.78407 17.7644 3.87973 17.0791 2.96186C16.8805 2.69588 16.7594 2.63461 16.5246 2.93947C12.6153 8.01348 8.67144 13.0603 4.79115 18.1569C2.9367 20.5928 1.99432 23.3986 1.82909 26.4831C1.45663 33.4366 5.86899 39.5512 12.0623 41.6393C19.2607 44.0662 27.2663 40.5021 30.3545 33.4966C31.2325 31.5048 31.7013 29.4229 31.6884 27.2597C31.668 23.8364 30.6512 20.7236 28.5777 17.9915C27.6458 16.7636 26.7048 15.543 25.7682 14.3189C25.7082 14.2403 25.646 14.1627 25.5928 14.0794C25.2954 13.6138 25.3576 13.13 25.7514 12.8273C26.1531 12.5185 26.6791 12.5846 27.0179 13.0216C27.98 14.2617 28.9256 15.5146 29.8843 16.7573C31.4468 18.7828 32.5411 21.0412 33.0546 23.5561C34.1834 29.085 32.964 34.0488 29.3459 38.3488C26.7476 41.4368 23.4071 43.2723 19.4615 43.9779C19.0899 44.0442 18.7155 44.0946 18.3425 44.1523H15.1413Z'
        fill={color}
      />
      <path
        d='M12.4416 34.119C11.7576 34.1171 11.3548 33.428 11.6967 32.7776C12.1444 31.926 12.6357 31.0978 13.1113 30.2612C15.342 26.3367 17.5748 22.4132 19.8033 18.4874C19.9849 18.1674 20.1923 17.8902 20.5798 17.8346C20.9317 17.784 21.2191 17.9193 21.4156 18.2114C21.6438 18.5511 21.6177 18.9055 21.4189 19.2551C20.7825 20.3747 20.148 21.4954 19.5118 22.615C17.4773 26.1953 15.4417 29.7751 13.4084 33.3561C13.1872 33.7454 12.9697 34.1315 12.4416 34.119Z'
        fill={color}
      />
      <path
        d='M11.648 17.1836C13.4846 17.1851 14.9216 18.6534 14.915 20.5219C14.9085 22.3486 13.4507 23.806 11.631 23.8051C9.79746 23.8041 8.36716 22.3368 8.37221 20.4621C8.37703 18.6259 9.8196 17.1822 11.648 17.1836ZM13.1399 20.4968C13.1422 19.6612 12.4752 18.9698 11.6549 18.9575C10.839 18.9453 10.111 19.6794 10.1202 20.5047C10.1294 21.3271 10.8197 22.0116 11.6393 22.0113C12.4707 22.0109 13.1377 21.3379 13.1399 20.4968Z'
        fill={color}
      />
      <path
        d='M25.1113 30.8281C25.1085 32.6865 23.6616 34.1279 21.8058 34.1211C20.0032 34.1146 18.5645 32.636 18.5683 30.794C18.5721 28.9463 20.034 27.493 21.8815 27.5C23.6889 27.5068 25.1141 28.9756 25.1113 30.8281ZM23.3631 30.7999C23.3541 29.9804 22.6447 29.2721 21.8447 29.2842C21.0083 29.2968 20.3476 29.9657 20.3432 30.8045C20.3387 31.6562 21.0462 32.3713 21.8681 32.3459C22.6836 32.3208 23.372 31.6089 23.3631 30.7999Z'
        fill={color}
      />
      <path
        d='M25.1083 10.8234C25.1062 11.3321 24.7297 11.7051 24.226 11.6979C23.7269 11.6908 23.3593 11.3016 23.3716 10.7934C23.3832 10.3066 23.751 9.94318 24.2347 9.94045C24.7379 9.93749 25.1104 10.3141 25.1083 10.8234Z'
        fill={color}
      />
    </svg>
  );
}
export default SvgHumidity;
