import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestSchedule from '@pw/components/RequestSchedule';

function RequestScheduler() {
  return (
    <RequestSchedule />
  );
}

export default withAppLayout(RequestScheduler, { title: 'Requests Scheduler' });

