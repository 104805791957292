function SvgFilter({ color = 'currentColor', ...props }) {
	return (
		<svg
			data-name='Layer 1'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 60 60'
			width='1em'
			height='1em'
			className='MuiSvgIcon-root'
			{...props}
		>
			<path
				className='filter_svg__cls-1'
				d='M49.68 8.89c-4.65 0-8.53 3.09-9.82 7.31H3.01C1.35 16.2 0 17.54 0 19.21s1.35 3.01 3.01 3.01h36.85c1.29 4.22 5.18 7.31 9.82 7.31 5.7 0 10.32-4.62 10.32-10.32S55.38 8.89 49.68 8.89zm.13 16.47c-3.4 0-6.15-2.75-6.15-6.15s2.75-6.15 6.15-6.15 6.15 2.75 6.15 6.15-2.75 6.15-6.15 6.15zM0 42.37c0 5.7 4.62 10.32 10.32 10.32 4.65 0 8.53-3.09 9.82-7.31h36.85c1.66 0 3.01-1.35 3.01-3.01s-1.35-3.01-3.01-3.01H20.14c-1.29-4.22-5.18-7.31-9.82-7.31C4.62 32.05 0 36.67 0 42.37zm4.04 0c0-3.4 2.75-6.15 6.15-6.15 3.4 0 6.15 2.75 6.15 6.15 0 3.4-2.75 6.15-6.15 6.15s-6.15-2.75-6.15-6.15z'
				fill={color}
			/>
		</svg>
	);
}
export default SvgFilter;
