import rootModalStyles from '@pw/styles/modal.styles';

const styles = {
  ...rootModalStyles,
  // Override just the root
  '&.root': {
    position: 'absolute',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    maxWidth: '95vw',
    height: '95vh',
    maxHeight: '95vh',
    backgroundColor: '#fff',
    boxShadow: 24,
    pt: 3,
    px: 3,
    pb: 4,
    outline: 0,
    border: 0,
    borderRadius: 2,
  },
};

export default styles;
