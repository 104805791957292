import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { UNITS_OPTIONS } from '@pw/consts/units';
import { ArrayFormikProvider } from '@pw/providers/FormikProvider';
import { useMemo } from 'react';
import * as yup from 'yup';

function SpecificationEntry({ item, index }) {
  const { name, description } = item;

  return (
    <ArrayFormikProvider index={index} path='entries'>
      <Box className='listItem'>
        <Box className='listContent'>
          <Stack spacing='1rem'>
            <Stack>
              <NameDisplay name={name} />
              <DescriptionDisplay value={description} />
            </Stack>
            <FormikUnitField
                label='Value'
                name='value'
                options={UNITS_OPTIONS}
                fullWidth
            />
          </Stack>
        </Box>
      </Box>
    </ArrayFormikProvider>
  );
}

function SpecificationEditor({ item, open, onClose }) {
  const { name, _id, entries = [] } = item ?? {};

  const changeSet = useMemo(
    () => ({
      entries: [entries ?? [], yup.array().of(yup.object())],
    }),
    [entries],
  );

  const handleSubmit = (values) => {
    console.log('Setting Entry', values);
    onClose({ name, _id, entries: values?.entries });
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={name}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
          <Stack className='inventory'>
            <Box className='inventory-contents'>
              <Stack className='list'>
                {entries.map((entry, index) => (
                  <SpecificationEntry item={entry} key={entry.name} index={index} />
                ))}
              </Stack>
            </Box>
          </Stack>

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default SpecificationEditor;
