import { AddCircle, ContentCopy } from '@mui/icons-material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Divider from '@mui/material/Divider';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetSummaryList from '@pw/components/SKUSelector/items/AssetSummaryList';
import AssetModal from '@pw/components/SKUSelector/modals/dest/AssetModal';
import AssetUploadProcessing from '@pw/components/SKUSelector/modals/dest/AssetUploadProcessing';
import GeneratorModal from '@pw/components/SKUSelector/modals/dest/GeneratorModal';
import useUploaderHook from '@pw/components/Uploader';
import { ASSET_STATUS } from '@pw/consts/asset';
import { UPLOAD_ASSET_NEW } from '@pw/consts/models/assets';
import { FINISHED_GOODS_TYPES, THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { useCompanyThings } from '@pw/redux/containers/User';
import debounce from '@pw/utilities/debounce';
import generateId from '@pw/utilities/generateId';
import parseIdPattern from '@pw/utilities/parseIdPattern';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

function SingleAsset({ sku, item, assetType, id, prefix, onClose }) {
  const [lastId, setLastId] = id;

  const [asset, created] = useMemo(() => {
    if (item) {
      return [item, false];
    }
    // Create a new asset item
    const [px, pad] = parseIdPattern(prefix);

    // Check the SKU
    const fs = { sku_id: undefined, sku: undefined };
    const as = { sku_id: undefined, sku: undefined };
    const ls = { sku_id: undefined, sku: undefined };
    // If this is a finished goods sku, then use the asset sku and liquid sku..
    if (sku?.sku_type === THING_TYPES.FINISHED) {
      fs.sku_id = sku?.sku_id;
      fs.sku = {
        sku_id: sku?.sku_id,
        sku_type: sku?.sku_type,
        sku_name: sku?.sku_name,
        sku_description: sku?.sku_description,
      };
      // From the properties, grab what we need
      if (sku?.properties?.container_type_sku_id) {
        as.sku_id = sku?.properties?.container_type_sku_id;
        as.sku = sku?.properties?.container_type_sku;
      }
      if (sku?.properties?.liquid_type_sku_id) {
        ls.sku_id = sku?.properties?.liquid_type_sku_id;
        ls.sku = sku?.properties?.liquid_type_sku;
      }
    }
    // For a tracked item, simply set the asset sku
    if (sku?.sku_type === THING_TYPES.TRACKED) {
      as.sku_id = sku?.sku_id;
      as.sku = {
        sku_id: sku?.sku_id,
        sku_type: sku?.sku_type,
        sku_name: sku?.sku_name,
        sku_description: sku?.sku_description,
      };
    }
    // Create a new, blank asset
    return [
      {
        rw_asset_id: generateId(px, pad, lastId),
        asset_status: ASSET_STATUS.PENDING_CREATE,
        asset_type: assetType,
        ...fs,
        properties: {
          ...as,
          ...(ls.sku_id ? { liquid: { enable: true, ...ls } } : {}),
        },
      },
      true,
    ];
  }, [sku, item, assetType, id, prefix]);

  console.log('Single Asset', asset);

  // Update the last id if we created a new asset...
  const handleClose = useCallback(
    (a) => {
      if (a) {
        if (created) {
          debounce(() => setLastId(lastId + 1), 25);
        }
        onClose(a);
      } else {
        onClose();
      }
    },
    [created, onClose, lastId, setLastId],
  );

  return <AssetModal open asset={asset} onClose={handleClose} />;
}

function Uploader({ sku, assetType, id, prefix, onClose }) {
  console.log('Asset Type', assetType);

  const [UploaderModal] = useUploaderHook({
    model: UPLOAD_ASSET_NEW,
    processorProperties: {
      sku,
      assetType,
      id,
      prefix,
    },
    ProcessorContent: AssetUploadProcessing,
    onClose,
  });

  return <UploaderModal open title='Upload Assets' />;
}

function TrackedSKUSetup({ field, sku, assets }) {
  const { setFieldValue } = useContext(FormikContext);
  const things = useCompanyThings();

  const [assetList, , upsertAsset, removeAsset] = assets ?? [[]];

  // Get the last number from the sku
  const [lastNumber, prefix, assetType] = useMemo(() => {
    let props;
    if (sku?.sku_type === THING_TYPES.TRACKED) {
      props = sku?.properties;
    }
    if (
      sku?.sku_type === THING_TYPES.FINISHED &&
      sku?.properties?.type === FINISHED_GOODS_TYPES.CONTAINER
    ) {
      // We need to get the nested item
      const container_type_sku_id = sku?.properties?.container_type_sku_id;
      if (container_type_sku_id) {
        const containerSku = things.find(
          (s) => s.sku_id === container_type_sku_id,
        );
        props = containerSku?.properties;
      }
    }
    const { last_number = 0, prefix = '', asset_type } = props ?? {};
    return [last_number, prefix, asset_type];
  }, [sku, things]);

  // Track the last identity
  const [lastId, setLastId] = useState(Number(lastNumber) + 1);

  const [dialog, setDialog] = useState(null);

  // const [item, setItem] = useState(null);
  // const [generator, setGenerator] = useState(false);
  // const [upload, setUpload] = useState(false);

  const handleSingle = () => {
    setDialog({ type: 0 });
  };

  const handleGenerate = () => {
    setDialog({ type: 1 });
  };

  const handleUpload = () => {
    setDialog({ type: 2 });
  };

  const handleEditAsset = (a) => {
    setDialog({ type: 0, asset: a });
  };

  const handleClose = useCallback(
    (a) => {
      if (upsertAsset && a) {
        upsertAsset(a);
      }
      setDialog(null);
    },
    [upsertAsset],
  );

  useEffect(() => {
    console.log('Asset list', assetList.length);
    setFieldValue(field, assetList.length);
  }, [assetList]);

  return (
    <>
      <Divider />
      <Instructions>
        Generate new assets or upload from an existing sheet.
      </Instructions>
      <FlexBox justifyContent='center'>
        <TitledButton handleClick={handleSingle} label='Items'>
          <AddCircle height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={handleGenerate}
          label='Generate'
          disabled={sku?.sku_type === THING_TYPES.FINISHED}
        >
          <ContentCopy height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={handleUpload}
          label='Upload'
          disabled={sku?.sku_type === THING_TYPES.TRACKED}
        >
          <UploadFileIcon height={24} width={24} />
        </TitledButton>
      </FlexBox>

      {dialog && dialog.type === 0 && (
        <SingleAsset
          sku={sku}
          item={dialog.asset}
          assetType={assetType}
          id={[lastId, setLastId]}
          prefix={prefix}
          onClose={handleClose}
        />
      )}
      {dialog && dialog.type === 1 && (
        <GeneratorModal
          open
          sku={sku}
          assetType={assetType}
          id={[lastId, setLastId]}
          prefix={prefix}
          onClose={handleClose}
        />
      )}
      {dialog && dialog.type === 2 && (
        <Uploader
          sku={sku}
          assetType={assetType}
          id={[lastId, setLastId]}
          prefix={prefix}
          onClose={handleClose}
        />
      )}

      <AssetSummaryList
        items={assetList}
        onEdit={handleEditAsset}
        onRemove={removeAsset}
      />
    </>
  );
}

export default TrackedSKUSetup;
