import { Stack } from '@mui/material';
import LocationContent from '@pw/components/AssetListItem/LocationContent';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import SingleSelect from '@pw/components_v2/elements/SingleSelect';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { SEARCH_TYPES } from '@pw/components_v2/search/general/const';
import FormikContext from '@pw/context/FormikContext';
import { useContext, useMemo } from 'react';

function AssetSearch({ label = 'Asset', field, types = [] }) {
  const { values } = useContext(FormikContext);

  const search = useMemo(
    () => ({
      [SEARCH_TYPES.ASSETS]: {
        types,
      },
    }),
    [],
  );

  const { _id, name, type, location } = values?.[field] ?? {};

  return (
    <SingleSelect search={search} label={label} field={field}>
      {_id && (
        <Stack>
          <EntryTitle value={name} />
          <SourceDisplay type={type} name={type?.toUpperCase()} />
          <LocationContent location={location} />
        </Stack>
      )}
    </SingleSelect>
  );
}

export default AssetSearch;
