import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { H5 } from '@pw/components/Typography';
import Address from '@pw/components_v2/Address';
import BaseUnits from '@pw/components/BaseUnits';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import CompanyCompliance from '@pw/components_v2/CompanyView/CompanyCompliance';
import { upsertCompanyThunk } from '@pw/redux/thunks/company';
import { useDispatch } from 'react-redux';
import CompanyTypeSelector from '@pw/components_v2/CompanyView/CompanyTypeSelector';
import CompanyLimits from './CompanyLimits';

function Editor({
  id,
  entity,
  changeSet,
  edit = false,
  onCancel,
  onBeforeSave,
  onSave,
}) {
  const dispatch = useDispatch();
  const { entries = [] } = entity ?? {};

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;

    const request = {
      ...(entity ?? {}),
      ...updated,
    };

    console.log('Submitting', request);

    dispatch(upsertCompanyThunk(request))
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit} edit={edit}>
        <FormWrapper>
          <FormikTextField name='name' label='Name' fullWidth required />

          <FormikTextField
            name='public_name'
            label='Display Name'
            fullWidth
            required
          />

          <CompanyCompliance />

          <CompanyTypeSelector />

          <CompanyLimits limits={entity?.limits} />

          <Stack spacing='1.5rem'>
            <H5>General Contact</H5>
            <FormikTextField name='telephone' label='Telephone' fullWidth />
            <FormikTextField name='email' label='Email' fullWidth />
          </Stack>

          <Address />

          <BaseUnits />

          <Box className='action-buttons'>
            {onCancel && (
              <TextButton
                size='small'
                handleClick={onCancel}
                color='secondary'
                label='Cancel'
              />
            )}
            <FilledButton type='submit' size='small'>
              {id ? 'Update' : 'Add'}
            </FilledButton>
          </Box>
        </FormWrapper>
      </FormikForm>
    </>
  );
}

export default Editor;
