import { Box } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import SvgLocation from '@pw/components/icons/Location';

function LocationDisplay({ label, name }) {
	if (!name) return;

	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<SvgLocation style={{ width: '12px', height: 'auto' }} />
			<Body3>
				{label && <Bolder>{`${label}: `}</Bolder>}
				{name}
			</Body3>
		</Box>
	);
}

export default LocationDisplay;
