import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import { useMemo } from 'react';
import * as yup from 'yup';

function ServiceThing({ item, onClose }) {
  // We track the "amount" we want at the Thing Item level
  const { entries = [{ amount: 0 }], properties = {} } = item ?? {};
  const { unit } = properties;

  console.log('ServiceThing', item);

  // We expect there to be an entry - which has the specific values we want for delivery..
  const entry = entries[0] ?? { amount: 0 };
  const { amount } = entry;

  const changeSet = useMemo(
    () => ({
      amount: [
        amount ? amount : '',
        yup.number().positive('Must be positive!').required('Amount required!'),
      ],
    }),
    [amount],
  );

  const handleSubmit = (values) => {
    const thing = {
      ...item,
      entries: [{ amount: values.amount }],
      amount: values.amount,
    };
    console.log('Setting Thing', values, thing);
    onClose(thing);
  };

  return (
    <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
      <Stack spacing={1}>
        <FormikNumberField label='Items' name='amount' fullWidth />

        <Errors />

        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton type='submit' size='small'>
            Save
          </FilledButton>
        </Box>
      </Stack>
    </FormikForm>
  );
}

export default ServiceThing;
