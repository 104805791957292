export const ContactType = {
  PERSON: 'person',
  COMPANY: 'company',
};

export const ContactStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  REVIEW: 'review',
  READONLY: 'readonly',          // Users from this company are allowed to read data (scan qr codes only..)
};

export const CompanyType = {
  DISTILLERY: "distillery", //"1",
  WAREHOUSE: "warehouse", //"2",
  BRAND: "brand", //"3",
  BROKER: "broker", //"4",
  CO_PACKER: "co_packer", //"5",
  COOPERAGE: "cooperage", //
};
