import { ConnectWithoutContact } from '@mui/icons-material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { Body3, H5 } from '@pw/components/Typography';
import styles from '@pw/styles/content.styles';
import ContactListItem from './ContactListItem';
import PartnerContactSearch from './PartnerContactSearch';

function Navigation({ onEdit, id, isLoading = false, contacts = [] }) {
  return (
    <Stack sx={styles} className='root' spacing={3}>
      <Box className='section'>
        <PartnerContactSearch
          id={id}
          title='Search'
          label='Partner contact'
          sectionTitle={''}
          buttonIcon={<ArrowForward />}
          onItemClick={onEdit}
          data={contacts}
        />
      </Box>
      <Stack spacing={1}>
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <H5>Partner Contact List</H5>
        </Stack>
        {!isLoading && contacts && contacts.length > 0 && (
          <Stack spacing={0.5}>
            <Stack className='list'>
              {contacts.map((i) => (
                <ContactListItem
                  key={i._id}
                  item={i}
                  buttonIcon={<ConnectWithoutContact />}
                  onEdit={() => onEdit(i)}
                />
              ))}
            </Stack>
          </Stack>
        )}
        {!isLoading && (!contacts || contacts.length === 0) && (
          <Body3>Nothing yet.</Body3>
        )}
        {isLoading && (
          <Stack spacing={0.5}>
            <FlexBox justifyContent='flex-end' gap='2rem'>
              <Skeleton variant='rectangular' width={45} height={45} />
              <Skeleton variant='rectangular' width={60} height={35} />
            </FlexBox>
            <Stack className='list'>
              <ListItemSkeleton />
              <ListItemSkeleton />
              <ListItemSkeleton />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default Navigation;
