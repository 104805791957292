import { StoreOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import NameDisplay from './NameDisplay';
import ProductCodeDisplay from './ProductCodeDisplay';

function VendorDisplay({ value }) {
  if (!value) return;
  if (typeof value === 'string') {
    return (
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          gap: '0.25rem',
        }}
      >
        <StoreOutlined style={{ width: '12px', height: 'auto' }} />
        <Body3>
          <Bolder>Vendor:</Bolder>
          &nbsp;
          {value}
        </Body3>
      </Box>
    );
  }
  const { target_id = {} } = value ?? {};
  const { name, public_name } = target_id;

  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      <Body3>
        <Bolder>Vendor:</Bolder>
      </Body3>
      <Stack paddingLeft={'12px'} spacing={0.5}>
        <NameDisplay name={name ?? public_name} />
        {/* <ProductCodeDisplay value={product} /> */}
      </Stack>
    </Box>
  );
}

export default VendorDisplay;
