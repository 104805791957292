import Collapse from '@mui/material/Collapse';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import debounce from '@pw/utilities/debounce';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';

function DutyForm(props) {
  const [FormSubmitter, submitForm] = useFormSubmissionHook();

  const [
    [sourceThings],
    [sourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useInventorySelectorHook({
    title: 'Duty Suspended Goods',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
      ],
      thing_types: [THING_TYPES.FINISHED],
    },
    ThingModal: SourceThingModal,
    thingModalProps: { duty_paid: 'false' },
    assetAdapter: (a) => {
      if (a.id === '/t/' && a.properties?.duty_paid) {
        enqueueSnackbar(`Asset duty is paid!`, { variant: 'error' });
        return undefined;
      } else {
        return a;
      }
    },
  });

  const [
    [destinationThings, , addDestinationThing],
    [destinationAssets, , addDestinationAsset],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Duty Paid Goods & Services',
    filter: {
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
      ],
      thing_types: [THING_TYPES.FINISHED, THING_TYPES.SERVICES],
    },
    submitForm,
    thingModalProps: { calculateThingLiquid: true },
    ThingModal: DestinationThingModal,
  });

  const hasDestinations = useMemo(
    () => destinationThings.length > 0 || destinationAssets.length > 0,
    [destinationThings, destinationAssets],
  );

  useEffect(() => {
    // If source changes, then add this to the destination..
    if (sourceThings.length > 0) {
      // Find any skus that have changed..
      const changedThings = sourceThings.filter((s) => {
        const dest = destinationThings.find((d) => d._id === s._id);
        if (!dest) {
          return true;
        }
        if (s.amount !== dest.amount) {
          return true;
        }
      });
      if (changedThings.length === 1) {
        // Create a destination sku based on this and add it..
        debounce(
          () =>
            addDestinationThing({
              ...changedThings[0],
              entries: (changedThings[0]?.entries ?? []).map(({ amount }) => ({
                amount,
                duty_paid: true,
              })),
            }),
          25,
        );
      }
    }
  }, [sourceThings]);

  useEffect(() => {
    // If source changes, then add this to the destination..
    if (sourceAssets.length > 0) {
      const changedAssets = sourceAssets.filter((s) => {
        const dest = destinationAssets.find(
          (d) => d.rw_asset_id === s.rw_asset_id,
        );
        return !dest;
      });
      if (changedAssets.length > 0) {
        debounce(() => addDestinationAsset(changedAssets[0]), 25);
      }
    }
  }, [sourceAssets]);

  const changeSetGenerator = useMemo(
    () => (initialValues) => requestIdFields(initialValues),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: (entity?.sources?.assets ?? []).map(asset=>({ ...(asset?.asset ?? {}), ...asset})),
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        assets: (entity?.destinations?.assets ?? []).map(asset=>({ ...(asset?.asset ?? {}), ...asset})),
        things: entity?.destinations?.things,
      });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      // Check the details match
      if (
        sourceThings.length > 0 &&
        sourceThings.length !== destinationThings.length
      ) {
        throw new Error('Duty suspended and duty paid SKUs do not match!');
      }
      if (
        sourceAssets.length > 0 &&
        sourceAssets.length !== destinationAssets.length
      ) {
        throw new Error('Duty suspended and duty paid assets do not match!');
      }

      const matchedThings = sourceThings.filter((s) => {
        const dest = destinationThings.find((d) => d._id === s._id);
        if (!dest) {
          return false;
        }
        const sourceThingAmount = (s.entries ?? [])?.reduce(
          (acc, entry) => acc + Number(entry?.amount ?? 0),
          0,
        );
        const destThingAmount = (dest.entries ?? [])?.reduce(
          (acc, entry) => acc + Number(entry?.amount ?? 0),
          0,
        );
        return sourceThingAmount === destThingAmount;
      });
      if (matchedThings.length !== sourceThings.length) {
        throw new Error('Amounts do not match!');
      }

      const matchedAssets = sourceAssets.filter((s) => {
        const dest = destinationAssets.find(
          (d) => d.rw_asset_id === s.rw_asset_id,
        );
        return !!dest;
      });
      if (matchedAssets.length !== sourceAssets.length) {
        throw new Error('Assets do not match!');
      }

      return {
        ...entity,
        sources: {
          assets: sourceAssets,
          things: sourceThings,
        },
        destinations: {
          assets: destinationAssets,
          things: destinationThings,
        },
      };
    },
    [sourceThings, sourceAssets, destinationThings, destinationAssets],
  );

  console.log(destinationAssets);

  return (
    <>
      <RequestForm
        requestLabel='Duty Transfer'
        requestType={REQUEST_TYPES.DUTY_TRANSFER}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />

        <Collapse in={!!hasDestinations}>
          <DestinationInventory />
        </Collapse>
        <FormSubmitter />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(DutyForm, { title: 'Duty Transfer' });
