import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '../utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getPriceCurve = async (params, headers = {}) =>
	fetchGet(`${BASE}/v2/get${objectToQueryString({ ...params, type: 'price_curve' })}`, headers);

export const createPriceCurve = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/create`, headers, { ...data, type: 'price_curve' });

export const updatePriceCurve = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/update`, headers, { ...data, type: 'price_curve' });

export const removePriceCurve = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/remove`, headers, { ...data, type: 'price_curve' });

export const listPriceCurves = async (data, headers = {}) =>
	fetchPost(`${BASE}/v2/list`, headers, { ...data, type: 'price_curve' });
