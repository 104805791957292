import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { H5, Overline } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { FormikProvider } from '@pw/providers/FormikProvider';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo, useState } from 'react';
import * as yup from 'yup';

function propertyFields(fields = {}) {
	const { tags = [] } = fields;

	return {
		tags: [
			typeof tags === 'string' ? tags?.split(',') : tags,
			yup.array().of(yup.string()),
		],
	};
}

function Properties({ entity }) {
	return (
		<FormikProvider path='properties'>
			<Stack spacing={2}>
				<Divider><Overline>Properties</Overline></Divider>
				<TagSelect name='tags' type={TAG_CATEGORY.ASSET} entity={entity} />
			</Stack>
		</FormikProvider>
	);
}

function TankForm(props) {
	const converter = useConverter();
	const [entity, setEntity] = useState(null);

	const changeSetGenerator = useMemo(
		() => (initialValues) => ({
			...assetIdFields(initialValues),
			properties: propertyFields(initialValues.properties, converter),
		}),
		[converter],
	);

	const handleBeforeSave = (entity) => {
		const filteredEntity = processLiquid(entity);
		return {
			...filteredEntity,
		};
	};

	const handleInit = (entity) => {
		setEntity(entity);
	};

	return (
		<AssetForm
			assetLabel='Filling Tank'
			assetType={ASSET_TYPES.FILLING_TANK}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			onInit={handleInit}
			{...props}
		>
			<Properties entity={entity} />
		</AssetForm>
	);
}

export default withAppLayout(TankForm, { title: 'Filling Tank Management' });
