import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import DependentThing from './forms/DependentThing';

function DependentThingModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the thing Item level
	const { name, description } = item ?? {};

	return (
		<ModalWithClose title={name} open={open} onClose={() => onClose()}>
			<Instructions>{description}</Instructions>
			<DependentThing item={item ?? {}} onClose={onClose} />
		</ModalWithClose>
	);
}

export default DependentThingModal;
