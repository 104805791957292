// Icons
// Flow-chart generics
import SummingJunction from '@pw/components/icons/SummingJunction';
import ManualInput from '@pw/components/icons/ManualInput';
import StartEnd from '@pw/components/icons/StartEnd';
import StoredData from '@pw/components/icons/StoredData';
import Preparation from '@pw/components/icons/Preparation';
import Sort from '@pw/components/icons/Sort';
import PredefinedProcess from '@pw/components/icons/PredefinedProcess';
import OffPageConnectorLeft from '@pw/components/icons/OffPageConnectorLeft';
import OffPageConnectorRight from '@pw/components/icons/OffPageConnectorRight';
import LoopLimit from '@pw/components/icons/LoopLimit';
import Merge from '@pw/components/icons/Merge';
import Or from '@pw/components/icons/Or';
import MultipleDocuments from '@pw/components/icons/MultipleDocuments';
import InputOutput from '@pw/components/icons/InputOutput';
import ManualLoop from '@pw/components/icons/ManualLoop';
import InternalStorage from '@pw/components/icons/InternalStorage';
import Inspection from '@pw/components/icons/Inspection';
import DirectData from '@pw/components/icons/DirectData';
import Delay from '@pw/components/icons/Delay';
import Document from '@pw/components/icons/Document';
import Database from '@pw/components/icons/Database';
import Display from '@pw/components/icons/Display';
import Action from '@pw/components/icons/Action';
import Collate from '@pw/components/icons/Collate';
import Decision from '@pw/components/icons/Decision';

// // Forms
// // Ingredients
// import WaterForm from './Diagram/NodeModal/Forms/WaterForm';
// import GrainForm from './Diagram/NodeModal/Forms/GrainForm';
// import YeastForm from './Diagram/NodeModal/Forms/YeastForm';
// import PeatForm from './Diagram/NodeModal/Forms/PeatForm';
// // Processes
// import ContinuousMillForm from './Diagram/NodeModal/Forms/ContinuousMillForm';
// import HammerMillForm from './Diagram/NodeModal/Forms/HammerMillForm';
// import MashCoolerForm from './Diagram/NodeModal/Forms/MashCoolerForm';
// import MashForm from './Diagram/NodeModal/Forms/MashForm';
// import BeerWellForm from './Diagram/NodeModal/Forms/BeerWellForm';
// import StillForm from './Diagram/NodeModal/Forms/StillForm';
// import WashbackForm from './Diagram/NodeModal/Forms/WashbackForm';
// // Observations
// import PhForm from './Diagram/NodeModal/Forms/PhForm';
// import BrixForm from './Diagram/NodeModal/Forms/BrixForm';
// import TemperatureForm from './Diagram/NodeModal/Forms/TemperatureForm';
// import OgSgForm from './Diagram/NodeModal/Forms/OgSgForm';
// import WeightForm from './Diagram/NodeModal/Forms/WeightForm';
// // Operations (TODO)

import { HelpCenter } from '@mui/icons-material';

// type measuredByType = 'weightt' | 'volume' | 'unit';

export const createKey = ({ label, icon, variant = null }) =>
  [label, icon, variant || null].filter((v) => Boolean(v))
    .join('-')
    .replace(/(\W)/, '_') +
  `-${new Date().getTime()}`;

export const iconFromName = (name) => {
  switch (name) {
    // Flow-chart basics
    case 'SummingJunction':
      return <SummingJunction />;
    case 'ManualInput':
      return <ManualInput />;
    case 'StartEnd':
      return <StartEnd />;
    case 'StoredData':
      return <StoredData />;
    case 'Preparation':
      return <Preparation />;
    case 'Sort':
      return <Sort />;
    case 'PredefinedProcess':
      return <PredefinedProcess />;
    case 'OffPageConnectorLeft':
      return <OffPageConnectorLeft />;
    case 'OffPageConnectorRight':
      return <OffPageConnectorRight />;
    case 'LoopLimit':
      return <LoopLimit />;
    case 'Merge':
      return <Merge />;
    case 'Or':
      return <Or />;
    case 'MultipleDocuments':
      return <MultipleDocuments />;
    case 'InputOutput':
      return <InputOutput />;
    case 'ManualLoop':
      return <ManualLoop />;
    case 'InternalStorage':
      return <InternalStorage />;
    case 'Inspection':
      return <Inspection />;
    case 'DirectData':
      return <DirectData />;
    case 'Delay':
      return <Delay />;
    case 'Document':
      return <Document />;
    case 'Database':
      return <Database />;
    case 'Display':
      return <Display />;
    case 'Action':
      return <Action />;
    case 'Collate':
      return <Collate />;
    case 'Decision':
      return <Decision />

    // Fallback
    default:
      return <HelpCenter />;
  }
};


export const formLookup = (form) => {
  switch (form) {

    // Fallback
    default:
      return null;
  }
};


export const sourceTypes = ['materialNode', 'processNode', 'operationNode'];
export const targetTypes = ['observationNode', 'processNode', 'operationNode'];


export const categories = [
  'SummingJunction',
  'ManualInput',
  'StartEnd',
  'StoredData',
  'Preparation',
  'Sort',
  'PredefinedProcess',
  'OffPageConnectorLeft',
  'OffPageConnectorRight',
  'LoopLimit',
  'Merge',
  'Or',
  'MultipleDocuments',
  'InputOutput',
  'ManualLoop',
  'InternalStorage',
  'Inspection',
  'DirectData',
  'Delay',
  'Document',
  'Database',
  'Display',
  'Action',
  'Collate',
  'Decision',
];

const diagrams = [
  'Contract Sales Order - Contract Sales',
  'Contract Sales Order - Shipment',
  'Production - Blend',
  'Production - Bottle',
  'Production - Production',
  'Production - Purchase',
  'Sales Order - Duty Flow',
  'Sales Order - Invoice',
  'Sales Order - Sales Order',
];

export const flowNodes = [
  // Documents
  {
    type: 'flowchart',
    label: 'Sales Order',
    category: 'Documents',
    icon: 'Document'
  },
  {
    type: 'flowchart',
    label: 'Customer Invoice',
    category: 'Documents',
    icon: 'Document'
  },
  {
    type: 'flowchart',
    label: 'Duty Report',
    category: 'Documents',
    icon: 'Document'
  },
  {
    type: 'flowchart',
    label: 'Purchase Order',
    category: 'Documents',
    icon: 'Document'
  },

  // MultipleDocuments
  {
    type: 'flowchart',
    label: 'Deliveries',
    category: 'Documents',
    icon: 'MultipleDocuments'
  },
  {
    type: 'flowchart',
    label: 'Liquid + Inventory',
    category: 'Documents',
    icon: 'MultipleDocuments'
  },
  {
    type: 'flowchart',
    label: 'Invoice Payment',
    category: 'Documents',
    icon: 'MultipleDocuments'
  },

  // PredefinedProcess
  {
    type: 'flowchart',
    label: 'Invoice',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Blend',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Bottle',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  // {
  //   type: 'flowchart',
  //   label: 'Shipment',
  //   category: 'PredefinedProcess',
  //   icon: 'StartEnd'
  // },
  {
    type: 'flowchart',
    label: 'Purchase',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Liquid',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Confirmed',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Duty Flow',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Approved',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Deny',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Inventory',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Produce',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Not Possible',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },
  {
    type: 'flowchart',
    label: 'Complete',
    category: 'PredefinedProcess',
    icon: 'StartEnd'
  },

  // Start
  {
    type: 'flowchart',
    label: 'Production',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Invoice',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Blending',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Shipment',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Duty Request',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Bottling',
    category: 'Start',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Purchase Request',
    category: 'Start',
    icon: 'Action'
  },


  // Decision
  {
    type: 'flowchart',
    label: 'Service',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Blend',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Ship',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Ingredients',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Paid',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Duty',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Dispatched',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Consumables',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Pack',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Have Liquid',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Have Inventory',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Have Casks',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Approval',
    category: 'Decision',
    icon: 'Decision'
  },
  {
    type: 'flowchart',
    label: 'Complete',
    category: 'Decision',
    icon: 'Decision'
  },

  // Action
  {
    type: 'flowchart',
    label: 'Restock',
    category: 'Action',
    icon: 'Action'
  },
  // {
  //   type: 'flowchart',
  //   label: 'Production',
  //   category: 'Action',
  //   icon: 'Action'
  // },
  {
    type: 'flowchart',
    label: 'Mash',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Ferment',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Distil',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Dispatch',
    category: 'Action',
    icon: 'Action'
  },
  // {
  //   type: 'flowchart',
  //   label: 'Duty Request',
  //   category: 'Action',
  //   icon: 'Action'
  // },
  {
    type: 'flowchart',
    label: 'Duty Submission',
    category: 'Action',
    icon: 'Action'
  },
  // {
  //   type: 'flowchart',
  //   label: 'Bottling',
  //   category: 'Action',
  //   icon: 'Action'
  // },
  {
    type: 'flowchart',
    label: 'Bottle',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Pack',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Transfer Order',
    category: 'Action',
    icon: 'Action'
  },
  {
    type: 'flowchart',
    label: 'Fill Casks',
    category: 'Action',
    icon: 'Action'
  },
  // {
  //   type: 'flowchart',
  //   label: 'Purchase Request',
  //   category: 'Action',
  //   icon: 'Action'
  // },
  {
    type: 'flowchart',
    label: 'Delivery',
    category: 'Action',
    icon: 'Action'
  },
];