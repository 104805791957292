function SvgEdit(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M10.8 2.616c-.235.256-.477.519-.72.775l-.156.165a6135.96 6135.96 0 00-8.657 9.128.74.74 0 00-.164.333c-.197.943-.394 1.902-.583 2.83l-.012.06c-.16.786-.326 1.597-.493 2.395a.61.61 0 00.137.544.5.5 0 00.486.14l.473-.108c1.5-.34 3.053-.693 4.58-1.05a.636.636 0 00.295-.17c1.56-1.64 3.132-3.299 4.653-4.904l1.937-2.045 1.7-1.79.014-.014c.891-.938 1.814-1.907 2.717-2.865.65-.69.975-1.544.994-2.613l-.01-.11c-.01-.124-.023-.288-.052-.443C17.703 1.604 16.882.6 15.742.188A3.139 3.139 0 0014.674 0c-.791 0-1.565.311-2.18.877-.59.543-1.151 1.151-1.693 1.74zm2.99 5.37a1.394 1.394 0 01-.06.04.56.56 0 00-.095.072l-2.667 2.81-.004.005a5943.242 5943.242 0 01-5.394 5.68c-.135.141-.31.248-.47.285-.953.225-1.925.444-2.864.655l-.66.148a.342.342 0 01-.073.008H1.47l-.04-.002h-.256l.194-.956c.106-.526.211-1.048.318-1.57l.05-.25c.09-.441.183-.897.281-1.347a.785.785 0 01.184-.364c2.666-2.817 5.358-5.656 7.962-8.402l.284-.299a.56.56 0 01.068-.06l.137-.105.135.124 3.205 3.378-.203.149zm-2.177-4.62c.155-.17.306-.345.466-.53.365-.42.74-.853 1.162-1.236.895-.811 2.344-.683 3.165.28.818.962.787 2.431-.07 3.346-.345.37-.703.73-1.048 1.077l-.454.46-.147.149-3.215-3.393.14-.154z'
        fill='#404040'
      />
    </svg>
  );
}
export default SvgEdit;
