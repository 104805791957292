import {
  FormikDatePicker,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function RequestID({ label }) {
  const { edit } = useContext(FormikContext);
  return (
    <>
      <FormikTextField
        name='name'
        label={label}
        fullWidth
        disabled={edit}
        required
        autoFocus
      />
      <FormikDatePicker name='created' label='Create Date' />
      <FormikDatePicker name='due' label='Due Date' />
    </>
  );
}
