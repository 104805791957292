import { AddBox } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { THING_TYPE_REVERSE } from '@pw/consts/thing';

function ThingInfo({ item, onClick }) {
	return (
		<Stack spacing={0.5} onClick={onClick}>
			<IDDisplay value={`${item?._id} [${THING_TYPE_REVERSE[item.type]}]`} />
			<NameDisplay name={item?.name} />
			<DescriptionDisplay value={item?.description} />
		</Stack>
	);
}

function ThingItem({ item, buttonIcon = <AddBox />, onClick }) {
	return (
		<Box className='listItem'>
			<Box className='listContent'>
				<ThingInfo item={item} onClick={onClick} />
			</Box>

			{onClick && (
				<Stack className='listButtons'>
					<IconCircleButton onClick={onClick}>{buttonIcon}</IconCircleButton>
				</Stack>
			)}
		</Box>
	);
}

export default ThingItem;
