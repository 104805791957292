import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikCheckBox, FormikForm } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { useCallback, useState } from 'react';

import styles from '@pw/styles/modal.styles';
import * as yup from 'yup';

function ReportModal({ open, assets, onClose, onReport }) {
	const [printing, setPrinting] = useState(false);

	const changeSet = {
		casks: [true, yup.boolean()],
		ibcs: [true, yup.boolean()],
		pallets: [true, yup.boolean()],
		tankers: [true, yup.boolean()],
	};

	const handleSubmit = useCallback(
		(values) => {
			const filteredAssets = assets.filter((a) => {
				if (a.asset_type === ASSET_TYPES.CASK && values.casks) return true;
				if (a.asset_type === ASSET_TYPES.IBC && values.ibcs) return true;
				if (a.asset_type === ASSET_TYPES.PALLET && values.pallets) return true;
				return !!(a.asset_type === ASSET_TYPES.TANKER && values.tankers);
			});

			console.log('filteredAssets', filteredAssets);

			onReport(filteredAssets);
			onClose();
		},
		[assets],
	);

	return (
		<Modal open={open} onClose={() => setPrinting(false)}>
			<Stack sx={styles} className='root' spacing={2}>
				<FlexBox>
					<H5>Assets</H5>
					<IconButton
						onClick={() => onClose()}
						disabled={printing}
						className='close-btn'
						aria-label='Close'
					>
						<CloseIcon />
					</IconButton>
				</FlexBox>

				<Box
					sx={{
						overflowY: 'auto',
						height: 'auto',
						maxHeight: 'calc(95vh - 9rem)',
					}}
				>
					<Instructions>Select which asset types to export</Instructions>

					<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
						<Stack spacing={2} direction='row'>
							<FormikCheckBox name='casks' label='Casks' />
							<FormikCheckBox name='pallets' label='Pallets' />
							<FormikCheckBox name='ibcs' label='IBCs' />
							<FormikCheckBox name='tankers' label='Tankers' />
						</Stack>
						<Box className='action-buttons'>
							<TextButton
								size='small'
								handleClick={() => onClose()}
								color='secondary'
							>
								Cancel
							</TextButton>
							<FilledButton type='submit' size='small'>
								Report
							</FilledButton>
						</Box>
					</FormikForm>
				</Box>
			</Stack>
		</Modal>
	);
}

export default ReportModal;
