import { Box } from '@mui/material';
import ThingForm from '@pw/components/admin/ThingForm/ThingForm';
import withDialogCard from '@pw/components/Cards/DialogCard';
import LatestAddedThings from '@pw/components/LatestAdded/LatestAddedThings';
import ItemSkeleton from '@pw/components/sekeletons/ItemSkeleton';
import SkeletonSummary from '@pw/components/sekeletons/SkeletonSummary';
import ThingSummary from '@pw/components/summary/ThingSummary';
import { THING_EDIT_URL } from '@pw/consts/thing';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import styles from '@pw/styles/content.styles';
import debounce from '@pw/utilities/debounce';
import useCheckCompany from '@pw/utilities/hooks/logic/useCheckCompany';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { useThingQuery } from '@pw/utilities/hooks/service/useThingQuery';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const ThingFormHandlerImpl = withDialogCard(
  ({ children, readonly, onSave, ...rest }) => {
    const { thingType } = rest;
    const navigate = useNavigate();
    const [target, scroll] = useScrollTarget();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(scroll, []);

    const handleSave = (mutated) => {
      console.log('Mutated thing', mutated);

      debounce(() => {
        onSave?.(mutated);
        const route = THING_EDIT_URL?.[thingType];
        navigate(`${route}/${mutated?._id}`, { replace: true });
      }, 50);
    };

    return (
      <Box sx={styles} className='root' ref={target}>
        <ThingForm onSave={handleSave} readonly={readonly} {...rest}>
          {children}
        </ThingForm>
      </Box>
    );
  },
);

function NewForm({ changeSetGenerator, onInit, ...rest }) {
  const [searchParams] = useSearchParams();

  const { thingType, thingLabel } = rest;

  usePageTitleHook(`Create ${thingLabel}`);

  // // // AI stuff disabled for now
  // // // const [suggested, setSuggested] = useState(null);
  const clone = searchParams?.get('clone');

  const formValues = useMemo(() => {
    let obj = {};
    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    // Always call onInit, but with different arguments
    if (onInit) {
      onInit(clone ? obj : {});
    }

    return { ...obj, thing_type: thingType };
  }, [thingType, clone, onInit]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  return (
    <>
      <ThingFormHandlerImpl changeSet={changeSet} {...rest} />
    </>
  );
}

function ShowThing({ changeSetGenerator, onInit, thingLabel, ...rest }) {
  const { id, thingType } = rest;
  const { data: entity, isError, refetch, isLoading } = useThingQuery({ id });
  const readonly = useCheckCompany(entity);

  console.log('ShowThing', id, readonly, entity);

  usePageTitleHook(`${thingLabel} ${entity?.name ?? '...'}`);

  // Generate the change set
  const formValues = useMemo(() => {
    // Specific initializer
    if (onInit) {
      onInit(entity);
    }
    return { ...(entity ?? {}), thing_type: thingType };
  }, [entity, thingType]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <Box className='adaptive-content'>
      {isLoading && (
        <>
          <SkeletonSummary />
          <ItemSkeleton />
        </>
      )}
      {!isLoading && (isError || !entity) && (
        <NewForm
          changeSetGenerator={changeSetGenerator}
          onInit={onInit}
          skuLabel={thingLabel}
          {...rest}
        />
      )}
      {!isLoading && entity && changeSet && (
        <>
          <ThingSummary entity={entity} refresh={refreshData} />
          <ThingFormHandlerImpl
            entity={entity}
            changeSet={changeSet}
            edit
            readonly={readonly}
            onSave={refreshData}
            {...rest}
          />
        </>
      )}
      <LatestAddedThings thingType={thingType} thingId={entity?._id} />
    </Box>
  );
}

function ThingFormHandler(props) {
  let { id } = useParams();

  console.log('ThingFormHandler', id, props);

  return (
    <>
      {!id && (
        <Box className='adaptive-content'>
          <NewForm {...props} />
          <LatestAddedThings thingType={props.thingType} />
        </Box>
      )}
      {id && <ShowThing id={id} {...props} />}
    </>
  );
}

export default ThingFormHandler;
