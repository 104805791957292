import {
  ExpandMore as ExpandMoreIcon,
  KeyboardArrowRightOutlined,
} from '@mui/icons-material';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import {
  FormikRadioGroup,
  FormikSelect,
  FormikTextAreaField,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { H4 } from '@pw/components/Typography';
import controlStates from '@pw/consts/controlStates.js';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useReportOverviewLazyQuery } from '@pw/utilities/hooks/service/useReportOverviewQuery';
import { useContext, useEffect, useState } from 'react';
import { UNIT } from '@pw/consts/units';
import useConverter from '@pw/utilities/hooks/logic/useConverter';

const years = Array.from({ length: 30 }, (_, i) => 2021 + i);
const plantTypes = [
  { value: '1', label: 'Small' },
  { value: '2', label: 'Medium' },
  { value: '3', label: 'Large' },
];
// eslint-disable-next-line
export default function Part1({ name, title = 'Part 1', disabled = false }) {
  const converter = useConverter();
  const bl_unit = converter.unit(UNIT.BL);
  const la_unit = converter.unit(UNIT.LA);

  const [fetchReportData, { data, isLoading }] = useReportOverviewLazyQuery();
  // const totalBlProofGallons = converter.cx(
  //   parseFloat(data?.total_bl),
  //   UNIT.BL,
  //   UNIT.proof_gallons,
  // );
  // const totalLaProofGallons = converter.cx(
  //   parseFloat(data?.total_la),
  //   UNIT.LA,
  //   UNIT.proof_gallons,
  // );

  // console.log('Total BL in Proof Gallons:', totalBlProofGallons);
  // console.log('Total LA in Proof Gallons:', totalLaProofGallons);
  console.log('fetchReportData', data);
  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { values } = useContext(FormikContext);
  console.log(values);
  const [expanded, setExpanded] = useState(true);
  return (
    <FormikProvider path={name}>
      <Stack direction='row' alignItems='center'>
        <H4>{title}</H4>
        <IconCircleButton onClick={() => setExpanded((prev) => !prev)}>
          {!expanded ? <KeyboardArrowRightOutlined /> : <ExpandMoreIcon />}
        </IconCircleButton>
      </Stack>
      {expanded && (
        <>
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant='h6' gutterBottom>
              Company Information
            </Typography>
            <Box noValidate autoComplete='off'>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormikTextAreaField
                    name='Proprietor_Name'
                    label='Name of Proprietor'
                    required
                    fullWidth
                    multiline={true}
                    rows={4}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name='address1'
                    label='Address 1'
                    required
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name='address2'
                    label='Address 2'
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikTextField
                    name='city'
                    label='City'
                    required
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikSelect
                    name='state'
                    label='State'
                    options={controlStates}
                    value={values.Part1?.state || ''}
                    required
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikTextField
                    name='zipCode'
                    label='Zip Code'
                    required
                    fullWidth
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <FormikSelect
            name='Report_Year'
            label='Report for calendar year'
            options={years.map((year) => ({ value: year, label: year }))}
            value={values.Part1?.Report_Year || ''}
            required
            fullWidth
            disabled={disabled}
          />
          <FormikRadioGroup
            name='typeOfPlant'
            label='Type of Plant'
            options={plantTypes}
            required
            disabled={disabled}
          />
        </>
      )}
    </FormikProvider>
  );
}
