function SvgDirectData(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M5.88 46.5C3.542 46.5 1 40.071 1 31.5s2.542-15 4.88-15h51.24c-2.44 0-4.88 6.429-4.88 15s2.44 15 4.88 15z'
        fill='#cfd'
      />
      <path
        d='M57.12 46.5c-2.44 0-4.88-6.429-4.88-15s2.44-15 4.88-15S62 22.929 62 31.5s-2.44 15-4.88 15z'
        fill='#cfd'
      />
    </svg>
  );
}
export default SvgDirectData;
