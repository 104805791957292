import { Box } from "@mui/material";
import { BasicMenu } from "@pw/components/Administration/components/BasicMenu";
import { Liquid } from "@pw/components/icons";
import HeadlineWidgetChart from "./HeadlineWidgetChart";
import { useTranslation } from "react-i18next";
import useConverter from "@pw/utilities/hooks/logic/useConverter";
import { UNIT } from "@pw/consts/units";
import LiquidTransfersWidget from "./LiquidTransfersWidget";
import { H5 } from "@pw/components/Typography";

const LiquidPanel = ({ liquidTypes, isLiquidDataLoading, setSkuId, parsed }) => {
  const { t } = useTranslation();
	const converter = useConverter();
	const bl_unit = converter.unit(UNIT.BL);
	const la_unit = converter.unit(UNIT.LA);
  return (
    <Box className='content-row'>
    <Box className='content-header'>
      <Box className='title-box'>
        <Liquid height={24} width={24} />
        <H5>Liquid</H5>
      </Box>
      {!!liquidTypes.length && (
        <BasicMenu
          menuOptions={liquidTypes.map(({ sku_name, sku_id }) => ({
            title: sku_name,
            onClick: () => setSkuId(sku_id),
          }))}
          isLoading={isLiquidDataLoading}
        />
      )}
    </Box>
    <Box className='content-body content-3col'>
      <HeadlineWidgetChart
        title='Total Liquid'
        data={[
          {
            id: 0,
            label: `${t('bulk')}(${bl_unit})`,
            value: parsed.total_bl ?? 0,
            color: '#41424c',
          },
          {
            id: 1,
            label: `${t('alcohol')}(${la_unit})`,
            value: parsed.total_la ?? 0,
            color: '#F0AA29',
          },
        ]}
        lineChartData={{
          bl: parsed.bl,
          la: parsed.la,
          dates: parsed.dates,
        }}
      />
      <HeadlineWidgetChart
        title='Total Production'
        data={[
          {
            id: 0,
            label: `${t('bulk')}(${bl_unit})`,
            value: parsed.total_bl_produced ?? 0,
            color: '#41424c',
          },
          {
            id: 1,
            label: `${t('alcohol')}(${la_unit})`,
            value: parsed.total_la_produced ?? 0,
            color: '#F0AA29',
          },
        ]}
        lineChartData={{
          bl: parsed.produced_bl,
          la: parsed.produced_la,
          dates: parsed.dates,
        }}
      />
      <LiquidTransfersWidget
        title='Liquid Movement'
        bl_in={parsed.total_bl_in}
        la_in={parsed.total_la_in}
        bl_out={parsed.total_bl_out}
        la_out={parsed.total_la_out}
      />
    </Box>
  </Box>
  );
};

export default LiquidPanel;