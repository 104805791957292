import QRImage from '@pw/components/QRImage';
import { LOCATION_PRINT_MAP } from '@pw/consts/facility';
import { encodeLocationQrCode } from '@pw/utilities/hooks/logic/useEncodeLocationQrCode';
import useOrigin from '@pw/utilities/hooks/logic/useOrigin';
import { useMemo } from 'react';

const styles = {
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '4rem',
	},
	rootFullScreen: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
	},
	content: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		justifyItems: 'start',
		pageBreakInside: 'none',
		pageBreadAfter: 'auto',
	},
	qrDetails: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		display: 'inline-block',
		textTransform: 'uppercase',
		textAlign: 'right',
		fontSize: '0.7rem',
		minWidth: '1.5rem',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
	text: {
		fontSize: '0.7rem',
		fontWeight: '600',
		whiteSpace: 'nowrap',
		fontFamily:
			'Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		gap: '1rem',
	},
};

const randomString = (length) => {
	const chars =
		'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let result = '';
	for (let i = length; i > 0; i -= 1) {
		result += chars[Math.floor(Math.random() * chars.length)];
	}
	return result;
};

const generateAllLocations = ({
	filter = LOCATION_PRINT_MAP.ALL,
	origin,
	locations = [],
	location,
	bay,
	row
}) => {
	console.log(`filter `,filter, ' location ', location);
	
	const list = [];
	if (!location?._id || filter === LOCATION_PRINT_MAP.FACILITIES) {
		return locations.map(({_id, name}) => ({
			location_name: name,
			value: encodeLocationQrCode(origin, 'dt', [_id]),
		}));
	}
	if (!bay?._id || filter === LOCATION_PRINT_MAP.BAYS) {
		return (location?.bays ?? [])?.map(({_id, name}) => ({
			location_name: location?.name,
			bay_name: name,
			value: encodeLocationQrCode(origin, 'dt', [location?._id, _id]),
		}));
	}
	if (filter === LOCATION_PRINT_MAP.ROWS) {
		(bay?.rows ?? []).forEach(({_id, name}) => {
			list.push({
				location_name: location?.name,
				bay_name: bay?.name,
				row_name: name,
				value: encodeLocationQrCode(origin, 'dt', [location?._id, bay?._id, _id]),
			});
		});
	}
	if (filter === LOCATION_PRINT_MAP.ALL) {
		(bay?.rows ?? []).forEach(({_id, name, levels = []}) => {
			levels.forEach(({_id: level_id, name: level_name})=>{
				list.push({
					location_name: location?.name,
					bay_name: bay?.name,
					row_name: name,
					level_name,
					value: encodeLocationQrCode(origin, 'dt', [location?._id, bay?._id, _id, level_id]),
				});
			})
		});
	}
	return list;
};

function LocationQRCodes({
	filter = LOCATION_PRINT_MAP.ALL,
	locations = [],
	location = {},
	bay = {},
	row = {},
}) {
	const origin = useOrigin();
	const locationList = useMemo(() => {
		return generateAllLocations({
			origin,
			filter,
			locations,
			location ,
			bay,
			row
		});
	}, [origin, filter, locations, location , bay, row]);

	return (
		<div style={styles.rootFullScreen}>
			{locationList.map((location) => (
				<div style={styles.content} key={location?.id}>
					<QRImage
						boxSize={120}
						value={location?.value}
						sx={{ maxWidth: 'min-content' }}
					/>
					<div style={styles.qrDetails}>
						<div style={styles.row}>
							<span style={styles.title}>F</span>
							<span style={styles.text}>{location?.location_name}</span>
						</div>
						{location?.bay_name && (
							<div style={styles.row}>
								<span style={styles.title}>B</span>
								<span style={styles.text}>{location?.bay_name}</span>
							</div>
						)}
						{location?.row_name && (
							<div style={styles.row}>
								<span style={styles.title}>R</span>
								<span style={styles.text}>{location?.row_name}</span>
							</div>
						)}
						{location?.level_name && (
							<div style={styles.row}>
								<span style={styles.title}>L</span>
								<span style={styles.text}>{location?.level_name}</span>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
}

export default LocationQRCodes;
