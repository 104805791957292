import { CheckBoxRounded } from '@mui/icons-material';
import { Avatar, AvatarGroup, colors } from '@mui/material';
import Box from '@mui/material/Box';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import IdIcon from '@pw/components/icons/Id';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { TASK_EDIT_URL_LOOKUP } from '@pw/consts/tasks';
import { mr1 } from '@pw/styles/margins';
import getInitialsFromString from '@pw/utilities/getInitialsFromString';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles';

function AssigneeUsers({ assignees = [] }) {
  return (
    <AvatarGroup max={4} className='assigneeList'>
      {assignees.map(([id, name]) => (
        <Avatar key={id} alt={name} className='avatar'>
          {name ? getInitialsFromString(name) : null}
        </Avatar>
      ))}
    </AvatarGroup>
  );
}

function AssetSummary({ label, items }) {
  if (!items || !items.count) return null;

  const { bl, abv } = items;

  return <LiquidDisplay label={label} abv={abv} value={bl} />;
}

function ParentAsset({ parent_asset_id }) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      <IdIcon style={{ width: '12px', height: 'auto' }} />
      <Body3>
        <Bolder>{'Parent Asset: '}</Bolder>
        &nbsp;
        {parent_asset_id}
      </Body3>
    </Box>
  );
}

function TaskCard2({ task, selected = false, onSelect }) {
  const navigate = useNavigate();

  const {
    id,
    type,
    batch,
    date,
    dueDate,
    action,
    assignees,
    path,
    // client,
    sources,
    destinations,
    parent_asset_id,
    // shipAddress,
  } = task;
  // console.log('Task', id, sources, destinations);

  const onClick = useCallback(
    (e) => {
      if (e.detail === 2) {
        if (!batch) {
          navigate(`${TASK_EDIT_URL_LOOKUP[type]}/${path}`);
        }
        e.stopPropagation();
      }
      if (e.detail === 1) {
        onSelect(!selected);
        console.log("test");
      }
    },
    [selected, batch, type, navigate, path],
  );

  const _id = useMemo(() => {
    const [, , , ...rest] = id.split('/');
    return batch ? rest.join('/') : id;
  }, [batch, id]);
  return !id ? null : (
    <Box
      // sx={styles}
      // className='root'
      // action={action}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 'max-content',
        cursor: 'pointer',
        border: '1px solid #C7C7C7',
        borderRadius: '5px',
        minHeight: '83px',
      }}
      id={path}
      onClick={onClick}
    >
      <Box
        className='tab'
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#C7DA65',
          color: '#fff',
          fill: '#fff',
		  width: selected ? '16px' : '8px'
        }}
      >
        {selected && (
          <CheckBoxRounded
            className='check'
            sx={{ ...mr1, width: '16px', height: 'auto' }}
          />
        )}
      </Box>
      <Box
        className='task-card-content'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: '.5rem',
        }}
      >
        <Box
          className='header'
          sx={{
            height: 'min-content',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <H5
              className='action'
              sx={{
                flexGrow: 1,
                fontWeight: 700,
                position: 'relative',
                paddingRight: '0.25rem',
                overflowWrap: 'break-word',
                color: '#C7DA65',
              }}
            >
              Unload Tanker
            </H5>
          </Box>
          <Body3
            className='date'
            sx={{ fontWeight: '600', padding: 0, color: '#404040' }}
          >
            {dueDate ?? date}
          </Body3>
        </Box>
        <Box className='body' sx={{ color: '#5D5D5D' }}>
          <SourceDisplay type={type} name={action} />

          {sources && <AssetSummary label='Source' items={sources} />}
          {destinations && (
            <AssetSummary label='Destination' items={destinations} />
          )}
          {parent_asset_id && <ParentAsset parent_asset_id={parent_asset_id} />}
        </Box>
        <Box className='footer'>
          <AssigneeUsers assignees={assignees} />
        </Box>
      </Box>
    </Box>
  );
}

export default TaskCard2;
