import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function Maintenance() {
  const sourceProps = useMemo(
    () => ({
      title: 'Parts',
      filter: {
        thing_types: [THING_TYPES.PARTS],
      },
      assetFilter: (a) => false,
      ThingModal: SourceThingModal,
    }),
    [],
  );

  const [
    [sourceThings, , , upsertSourceThings, removeSourceThings],
    [],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useInventorySelectorHook(sourceProps);

  const onDestinationThingAdded = useCallback(
    (thing) => {
      //
      //
      // 	if (skus.length === 0) {
      // 		skus = sku?.properties?.skus.filter((s) => [SKU_TYPES.CONSUMABLE, SKU_TYPES.FINISHED].includes(s.sku_type)).map((s) => ({
      // 			...s,
      // 			amount: Number(amount) * Number(s?.amount),
      // 		}));
      // 	}
      //
      // 	console.log('SKUs', skus);
      //
      // 	debounce(() => {
      // 		upsertSourceSkus(skus);
      // 	}, 25);
      // }
      // TODO: load policies from this thing...
      // loadPolicies(thing?.policies);
    },
    [],
  );

  const onDestinationThingRemoved = useCallback(
    (thing) => {
      console.log('Destination Thing removed', thing);
      // if (
      // 	sku?.sku_type === SKU_TYPES.FINISHED &&
      // 	sku?.properties?.skus?.length > 0
      // ) {
      // 	removeSourceSkus(sku?.properties?.skus);
      // }
    },
    [removeSourceThings],
  );

  const destProps = useMemo(
    () => ({
      title: 'Machines',
      filter: {
        asset_types: [ASSET_TYPES.MACHINE],
      },
    }),
    [onDestinationThingAdded, onDestinationThingRemoved],
  );

  const [
    [],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook(destProps);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      policies: [initialValues?.policies, yup.array()],
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        assets: entity?.destinations?.assets,
      });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      return {
        ...entity,
        sources: {
          things: sourceThings,
        },
        destinations: {
          assets: destinationAssets,
        },
      };
    },
    [sourceThings, destinationAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel='Maintenance'
        requestType={REQUEST_TYPES.MAINTENANCE}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        noClient
      >
        <SourceInventory />
        <DestinationInventory />

        <PolicySelector />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(Maintenance, { title: 'Maintenance' });
