import { Collapse } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import RequestTypeSelector from '@pw/components/RequestTypeSelector';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { POLICY_STAGE, POLICY_STAGE_OPTIONS, POLICY_STATUS, POLICY_STATUS_OPTIONS } from '@pw/consts/policy';
import { upsertPolicyThunk } from '@pw/redux/thunks/policy';
import { usePolicyLazyQuery } from '@pw/utilities/hooks/service/usePolicyQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import PolicyEntryField from './PolicyEntry';
import ContactSearch from '@pw/components_v2/search/contact';

function PolicyEditor({ entry, company, onClose, readonly }) {
  const dispatch = useDispatch();

  // Load the item on demand
  const [load, { isLoading, cancel }] = usePolicyLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = useMemo(() => ({
    name: [item?.name ?? '', yup.string().required('Name is required!')],
    status: [item?.status ?? POLICY_STATUS.ACTIVE, yup.string().required('Status is required!')],
    stage: [item?.stage ?? POLICY_STAGE.PRE , yup.string().required('Stage is required!')],
    entries: [
      item?.entries ?? [],
      yup.array().of(yup.object()).min(1, 'Entry is required'),
    ],
    client: [item?.client, yup.object()],
    requests: [item?.requests ?? [], yup.array()],
  }), [item]);

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?._id) {
      load({ id: entry._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry);
    }
  }, [entry]);


  const handleSubmit = useCallback((values) => {
    const request = {
      ...(item ?? {}),
      ...values,
      company_id: company?._id,
    };
    console.log('Submitting', request);
    dispatch(upsertPolicyThunk(request)).unwrap().then(onClose);
  }, [item, onClose]);

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.name}</H5>
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className='form-content' in={!isLoading} unmountOnExit>
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
          <FormWrapper>
            <Stack spacing='1.5rem'>
              <FlexBox alignItems='top'>
                <FormikSelect
                  label='Stage'
                  name='stage'
                  options={POLICY_STAGE_OPTIONS}
                  fullWidth
                />
                <FormikSelect
                  label='Status'
                  name='status'
                  options={POLICY_STATUS_OPTIONS}
                  fullWidth
                />
              </FlexBox>
            </Stack>
            <PolicyEntryField
              initialValues={item?.entries}
              readonly={readonly}
            />
            <ContactSearch label='Client' field='client' />
            <Stack spacing='1rem'>
              <H5>Default Policy</H5>
              <RequestTypeSelector />
            </Stack>

            <FormButtons onCancel={onClose} readonly={readonly} />
          </FormWrapper>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default PolicyEditor;
