import {
  CheckCircle,
  NotStarted,
  PauseCircle,
  PlayCircleFilled,
  Today,
} from '@mui/icons-material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { requestAssignThunk } from '@pw/redux/thunks/request';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import ScheduleModal from './modals/ScheduleModal';
import { PROGRESS_POINT } from '@pw/consts/requests';

function ProgressButtons({ entity, refresh }) {
  const dispatch = useDispatch();
  const { progress = [], _id } = entity ?? {};
  const [showSchedule, setShowSchedule] = useState(false);

  const assigned = useMemo(
    () => progress[progress.length - 1]?.type === PROGRESS_POINT.CREATED,
    [progress],
  );
  const scheduled = useMemo(() => {
    return progress[progress.length - 1]?.type === PROGRESS_POINT.SCHEDULED;
  }, [progress]);
  const started = useMemo(() => {
    return progress[progress.length - 1]?.type === PROGRESS_POINT.START;
  }, [progress]);
  const paused = useMemo(() => {
    return progress[progress.length - 1]?.type === PROGRESS_POINT.PAUSED;
  }, [progress]);
  const resumed = useMemo(() => {
    return progress[progress.length - 1]?.type === PROGRESS_POINT.RESUMED;
  }, [progress]);

  const handleAssign = (timePoint) => {
    dispatch(requestAssignThunk({ requests: [_id], timePoint }))
      .unwrap()
      .then(() => {
        refresh();
      });
  };

  const handleSchedule = useCallback(
    async (timePoint) => {
      if (timePoint) {
        await handleAssign(timePoint);
        setShowSchedule(false);
      } else setShowSchedule(false);
    },
    [setShowSchedule],
  );

  const handleProgress = useCallback((type) => {
    const timePoint = {
      type,
      timestamp: Date.now(),
    };
    handleAssign(timePoint);
  }, []);

  return (
    <>
      {assigned && (
        <TitledButton
          handleClick={() => setShowSchedule(true)}
          label='Schedule'
        >
          <Today height={24} width={24} />
        </TitledButton>
      )}

      {scheduled && (
        <TitledButton
          handleClick={() => handleProgress(PROGRESS_POINT.START)}
          label='Start'
        >
          <PlayCircleFilled height={24} width={24} />
        </TitledButton>
      )}
      {(started || resumed) && (
        <TitledButton
          handleClick={() => handleProgress(PROGRESS_POINT.PAUSED)}
          label='Pause'
        >
          <PauseCircle height={24} width={24} />
        </TitledButton>
      )}
      {paused && (
        <TitledButton
          handleClick={() => handleProgress(PROGRESS_POINT.RESUMED)}
          label='Resume'
        >
          <NotStarted height={24} width={24} />
        </TitledButton>
      )}
      {(started || resumed || paused) && (
        <TitledButton
          handleClick={() => handleProgress(PROGRESS_POINT.COMPLETED)}
          label='Completed'
        >
          <CheckCircle height={24} width={24} />
        </TitledButton>
      )}

      {showSchedule && <ScheduleModal onClose={handleSchedule} />}
    </>
  );
}

export default ProgressButtons;
