import { Refresh } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import BasicListItemSkeleton from '@pw/components/sekeletons/BasicListItemSkeleton';
import { Body1 } from '@pw/components/Typography';
import { useHeaderMapperV2 } from '@pw/utilities/hooks/ai/useHeaderMapper';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function FieldMappings({
  mappings,
  options,
  model,
}) {
  const { i18n } = useTranslation();

  const values = useMemo(() =>
    Object.entries(mappings)
    .sort(([lk], [rk]) => i18n.t(model?.fields[lk]?.label).localeCompare(i18n.t(model?.fields[rk]?.label)))
  , [mappings]);

  return (
    <Stack>
      {values.map(([k, v]) => (
        <Stack className="listItem">
          <FlexBox className="listContent">
            <FormikSelect label={i18n.t(model?.fields[k].label)} name={k} options={options} fullWidth />
          </FlexBox>
        </Stack>
      ))}
    </Stack>
  );
}

function FieldMapper({
  data,
  model,
  onCancel,
  onConfirm,
}) {
  const { enqueueSnackbar } = useSnackbar();

  console.log('Model', model);
  console.log('Data', data);

  // This is used to map the headers
  const { mapHeaders } = useHeaderMapperV2(model?.spec);

  const [mappings, setMappings] = useState(null);

  const options = useMemo(() => {
    console.log('Generating options', data?.data?.data?.length);
    console.log(' --> headers', data?.data?.meta?.fields);
    // Cleanup the elements..
    const cleaned = data?.data?.meta?.fields?.filter((v) => v && !v.startsWith('_')).map((v) => ({ label: v.trim(), value: v }))
    console.log('   --> clean', cleaned);
    return cleaned;
  }, [data]);

  const changeSet = useMemo(
    () =>
      Object.entries(mappings ?? {}).reduce((acc, [k, v]) => {
        // Need to map to exact header (without trim etc.)
        const matched = data?.data?.meta?.fields?.find((f) => f.trim() === v);
        acc[k] = [matched ?? '', yup.string().required('Please select mapping')];
        return acc;
      }, {}),
    [mappings],
  );

  const recomputeMapping = useCallback(() => {
    console.log('Recomputing mappings', options);
    setMappings(null);
    // Use the model and
    mapHeaders(options.map((v) => v.label))
      .then(([mapper, mappedHeaders]) => {
        console.log('headerMapper', mappedHeaders);

        setMappings(mappedHeaders);
      })
      .catch((e) => {
        // Enable re-fetch
        console.log('Error', e);
        setMappings({});
        enqueueSnackbar('Failed to map headers, please try again!', {
          variant: 'warning',
        });
      });
  }, [model, options]);

  useEffect(recomputeMapping, [options, model]);

  const handleSubmit = useCallback((values) => {
    onConfirm(values)
  }, [onConfirm]);

  return (
    <Stack spacing="1.5rem">
      <Stack spacing={1} className="section">

        <FormikForm
         changeSet={changeSet}
         onSubmit={handleSubmit}
         enableReinitialize
        >
         <FormWrapper>
            <Body1>Please verify the detected field mappings.</Body1>
            <Stack className="inventory">
              <Box className="inventory-header">
                <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
                <Stack direction="row" spacing={1}>
                  <TitledButton
                    handleClick={() => recomputeMapping()}
                    label="Retry"
                    disabled={!mappings}
                  >
                    <Refresh height={24} width={24} />
                  </TitledButton>
                </Stack>
              </Box>

              <Box className="inventory-contents">
                <Stack className="list">
                  {mappings && (
                    <FieldMappings mappings={mappings} model={model} options={options} />
                  )}
                  {!mappings && (
                    <>
                      <BasicListItemSkeleton />
                      <BasicListItemSkeleton />
                      <BasicListItemSkeleton />
                      <BasicListItemSkeleton />
                    </>
                  )}
                </Stack>
              </Box>
            </Stack>

            <Errors />

            {mappings && (
              <Box className="action-buttons">
                <TextButton
                  size="small"
                  handleClick={() => onCancel()}
                  color="secondary"
                  label="Cancel"
                />
                <FilledButton type="submit" size="small">
                  Confirm
                </FilledButton>
              </Box>
            )}
         </FormWrapper>
        </FormikForm>

      </Stack>
    </Stack>
  );
}

export default FieldMapper;
