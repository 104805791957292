import AssetID from '@pw/components/AssetID';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { ASSET_STATUS } from '@pw/consts/asset';
import { upsertAssetThunk } from '@pw/redux/thunks/asset';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

function AssetForm({
  assetType,
  entity,
  changeSet,
  readonly = false,
  edit = false,
  onCancel = () => {},
  onBeforeSave = () => {},
  onSave = () => {},
  children,
}) {
  const dispatch = useDispatch();
  const isAssetDefective = useMemo(
    () => entity?.asset_status === ASSET_STATUS.DEFECTIVE,
    [entity],
  );

  const handleSubmit = (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;
    const body = {
      ...updated,
      type: assetType,
    };
    console.log('Submitting formatted', values, body);
    dispatch(
      upsertAssetThunk(body),
    )
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <FormikForm
      changeSet={changeSet}
      onSubmit={handleSubmit}
      edit={edit}
      readonly={readonly}
      history={entity?.history}
    >
      <FormWrapper>
        <AssetID label='Name.' />
        {children}
        <FormButtons onCancel={onCancel} readonly={readonly || isAssetDefective} />
      </FormWrapper>
    </FormikForm>
  );
}

export default AssetForm;
