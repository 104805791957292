import generalInformationFields from './generalInformationFields';
import * as yup from 'yup';

export default function TTBReport_24Fields(
	fields = {},
	company = {},
	companyname,
) {
	const defaultValues = {
		partyCompanyName: companyname ?? '',
		DBA: company?.account_name ?? '',
		partyAddress1: company?.company_address.address_1 ?? '',
		partyCity: company?.company_address.city ?? '',
		partyState: company?.company_address.state ?? '',
		partyZipcode: company?.company_address.post_code ?? '',
		PERMIT_NUMBER: company.company_compliance.compliance_id ?? '',
	};
	const { formUrlId = '2659' } = fields;
	return {
		formUrlId: [formUrlId, yup.string()],
		general_information: generalInformationFields({...defaultValues}),
	};
}
