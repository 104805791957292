import { Box, Stack } from '@mui/material';
import { H6 } from '@pw/components/Typography';
import TeamSearch from '@pw/components_v2/search/team';
import TeamView from '@pw/components_v2/CompanyView/modals/users/TeamView';

function TeamSelector({ title="Teams", teams = [], upsert, remove, readonly }) {
  return (
    <Box className='section'>
      <H6 className='section-title'>{title}</H6>
      <Box className='inventory'>
        <Box className='inventory-header'>
          <TeamSearch
            label='Search'
            id='user'
            onSelected={upsert}
            fullWidth
            disabled={readonly}
          />
        </Box>
        <Box className='inventory-contents'>
          <Stack className='list'>
            {teams.map((t) => (
              <TeamView
                key={t._id}
                item={t}
                onRemove={!readonly ? remove : undefined}
              />
            ))}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default TeamSelector;
