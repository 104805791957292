import { createTheme } from '@mui/material/styles';
import { f1, f2, f3, f4, f5, f6 } from '@pw/styles/fonts';

export const breakpoints = {
	xs: 0,
	sm: 600,
	md: 900,
	lg: 1200,
	xl: 1536,
	xxl: 1920,
};
// xs: '480px',
//   sm: '576px',
//   md: '768px',
//   lg: '992px',
//   xl: '1200px',
//   xxl: '1600px',

export default createTheme({
	palette: {
		primary: {
			main: '#C6DB5C',
			dark: '#98AF24',
		},
		secondary: {
			main: '#000',
		},
		error: {
			main: '#f44336',
		},
		warning: {
			main: '#ff9800',
		},
		info: {
			main: '#2196f3',
		},
		success: {
			main: '#C6DB5C',
		},
		background: {
			default: '#fff',
			paper: '#fff',
		},
		text: {
			primary: '#000',
		}
	},
	breakpoints: {
		values: breakpoints
	},

	typography: {
		fontFamily: [
			'Poppins',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Helvetica Neue',
			'Arial',
			'Noto Sans',
			'sans-serif',
			'Apple Color Emoji',
			'Segoe UI Emoji',
			'Segoe UI Symbol',
			'Noto Color Emoji',
		].join(),
		h1: {
			lineHeight: '1.25',
			...f1,
			fontWeight: 700,
		},
		h2: {
			lineHeight: '1.15',
			...f2,
			fontWeight: 700,
		},
		h3: {
			lineHeight: '1.05',
			...f3,
			fontWeight: 600,
		},
		h4: {
			...f4,
			fontWeight: 600,
		},
		h5: {
			...f3,
			lineHeight: '1.35rem',
			fontWeight: 600,
		},
		h6: {
			...f5,
			fontWeight: 600,
		},
		subtitle1: {
			...f6,
			fontWeight: 500,
		},
		subtitle2: {
			...f6,
			fontWeight: 400,
		},
		body1: {
			...f4,
			lineHeight: '1.3rem',
			fontWeight: 400,
		},
		body2: {
			...f5,
			lineHeight: '1.2rem',
			fontWeight: 400,
		},
		overline: {
			lineHeight: 1,
			...f6,
		},
	},
	components: {
		MuiInputLabel: {
			styleOverrides: {
				root: {
					padding: 0,
					color: "#2e2e2e",
					fontWeight: "400",
					"&.Mui-focused": {
						color: "#000",
						fontWeight: "600",
					},
				},
			},
		},

		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					'&.MuiBreadcrumbs-root': {
						'& .MuiBreadcrumbs-li:last-of-type': {
							'& .MuiLink-root': {
								textDecoration: 'none',
								fontWeight: '500',
							},
						},
					},
				},
			},
		},

		MuiInputBase: {
			styleOverrides: {
				root: {
					borderRadius: '4px',
					color: '#000',
					fill: '#000',
					overflow: 'hidden',
				},
			},
		},

		MuiSelect: {
			styleOverrides: {
				root: {
					color: '#000',
					fill: '#000',
					overflow: 'hidden',
					border: '1px solid #efefef',
					backgroundColor: "#efefef",
					borderRadius: "4px",

					"&:before": {
						borderColor: "#efefef",
						borderWidth: "1px",
					},
					"&:after": {
						borderColor: "#efefef",
						borderWidth: "1px",
					},
					"&.Mui-focused": {
						backgroundColor: "#f4f4f4",
						border: '1px solid black',
					},
					":hover:not(.Mui-focused)": {
						"&:before": {
							borderColor: "black",
							borderWidth: "1px",
						},
						backgroundColor: "#f4f4f4",
					},
				},
			},
			defaultProps: {
				disableUnderline: true,
			},
		},

		MuiTextField: {
			styleOverrides: {
				root: {
					'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
						display: 'none',
					},
					'& input[type=number]': {
						MozAppearance: 'textfield',
					},
					'.MuiFormControlLabel': {
						position: 'absolute'
					},

					// Outlined
					"& .MuiOutlinedInput-root": {
						color: "#000",
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: "#2e2e2e",
							borderWidth: "1px",
						},
						"&.Mui-focused": {
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "secondary.main",
								borderWidth: "1px",
							},
						},
						"&:hover:not(.Mui-focused)": {
							"& .MuiOutlinedInput-notchedOutline": {
								borderColor: "secondary.main",
							},
						},
					},

					// Filled
					"& .MuiFilledInput-root": {
						color: "#000",
						border: '1px solid #efefef',
						backgroundColor: "#efefef",
						borderRadius: "4px",
						"&:before": {
							borderColor: "#efefef",
							borderWidth: "1px",
						},
						"&:after": {
							borderColor: "#efefef",
							borderWidth: "1px",
						},
						"&.Mui-focused": {
							backgroundColor: "#f4f4f4",
							border: '1px solid black',
						},
						":hover:not(.Mui-focused)": {
							"&:before": {
								borderColor: "black",
								borderWidth: "1px",
							},
							backgroundColor: "#f4f4f4",
						},
					},

					// Standard
					"& .MuiInput-root": {
						color: "#000",
						border: '1px solid #efefef',
						backgroundColor: "#efefef",
						borderRadius: "4px",
						"&:before": {
							borderColor: "#efefef",
							borderWidth: "1px",
						},
						"&:after": {
							borderColor: "#efefef",
							borderWidth: "1px",
						},
						"&.Mui-focused": {
							backgroundColor: "#f4f4f4",
							border: '1px solid black',
						},
						":hover:not(.Mui-focused)": {
							"&:before": {
								borderColor: "black",
								borderWidth: "1px",
							},
							backgroundColor: "#f4f4f4",
						},
					},

				},
			},
			defaultProps: {
				// InputProps: {
				// 	disableUnderline: true,
				// },
			},
		},

		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					minHeight: '44px',
					fontSize: '16px',
					letterSpacing: '0.05em',
					paddingLeft: '1.5rem',
					paddingRight: '1.5rem',
					paddingTop: '0.3rem',
					paddingBottom: '0.3rem',
					borderRadius: '50px',
					width: '100%',
					borderWidth: 0,
					fontWeight: '500',
					textTransform: 'capitalize',

					'&.MuiButton-containedPrimary': {
						'&[disabled]': {
							background: '#ccc',
							color: '#888',
						},
					},
				},
				outlined: {
					minHeight: 0,
					border: '1px solid rgba(198, 219, 92, 0.15)',
					paddingLeft: '8px',
					paddingRight: '8px',
					paddingTop: '8px',
					paddingBottom: '8px',
					borderRadius: '4px',

					'&:hover': {
						backgroundColor: 'rgb(250, 250, 250)',  // light green background on hover
						// border: '1px solid rgb(245, 245, 245)',  // bottom border color on focus
					},
				},
				sizeSmall: {
					width: 'min-content',
					minHeight: 0,
					borderRadius: '50px',
				},
			},
		},

		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					minWidth: 'min-content',
					whiteSpace: 'nowrap',
				},
			},
		},


		MuiFormLabel: {
			styleOverrides: {
				// root: {
				// 	'&.Mui-focused': { color: '#98AF24' },
				// },
				asterisk: { color: 'red' },
			},
		},

		MuiTabs: {
			styleOverrides: {
				root: {
					margin: 0,
					padding: 0,
					maxHeight: '44px',
					minHeight: '44px',

					'& .MuiTabs-indicator': {
						height: '5px',
						top: 0,
					},
				},
			},
		},

		MuiAvatar: {
			styleOverrides: {
				root: {
					height: '31px',
					width: '31px',
				}
			}
		},

		MuiButtonBase: {
			styleOverrides: {
				root: {
					height: 'auto',
					width: 'auto',
					'& .MuiTab-root': {
						maxWidth: 'min-content',
						maxHeight: '44px',
						minHeight: '44px',
					},
					'& .MuiTab-labelIcon': {
						maxWidth: 'min-content',
						maxHeight: '44px',
						minHeight: '44px',
					},
					'& .MuiTab-textColor': {
						maxWidth: 'min-content',
						maxHeight: '44px',
						minHeight: '44px',
					}
				}
			}
		},

		MuiTab: {
			styleOverrides: {
				root: {
					margin: 0,
					padding: 0,
					maxWidth: 'min-content',
					maxHeight: '44px',
					minHeight: '44px',
					borderRight: '1px solid #d9d9d9'
				},
			},
			defaultProps: {
				disableRipple: true,
				disableTouchRipple: true,
				disableFocusRipple: true,
			},
		}
	},

	shape: {
		borderRadius: 4,
	},
});
