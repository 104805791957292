import { createPriceCurve, removePriceCurve, updatePriceCurve } from '@pw/services/price.curve.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertPriceCurveThunk = createAsyncThunk(
  'priceCurve/upsert',
  async (curve, { rejectWithValue }) => {
    try {
      console.log('Upserting curve', curve);
      return (curve._id) ? await updatePriceCurve(curve) : await createPriceCurve(curve);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const removePriceCurveThunk = createAsyncThunk(
  'priceCurve/remove',
  async (curve, { rejectWithValue }) => {
    try {
      return await removePriceCurve(curve);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
