import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import { inviteThunk } from '@pw/redux/thunks/user';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

export function InvitePartnerConfirmDialog({
  entity,
  onClose,
  partner_id = '',
}) {
  const dispatch = useDispatch();
  const options = useMemo(
    () =>
      entity?.contacts?.map(({ _id, contact_id, contact_tags }) => ({
        value: _id,
        label: contact_id.name ?? contact_tags,
      })) || [],
    [entity.contacts],
  );
  const changeSet = useMemo(
    () => ({
      id: [partner_id, yup.string().required('Contact is required!')],
    }),
    [],
  );
  const handleOnClose = useCallback(() => onClose(), [onClose]);

  const handleSubmit = useCallback((values) => {
    dispatch(inviteThunk(values))
      .unwrap()
      .then(() => handleOnClose());
  }, []);

  return (
    <ModalWithClose title='Connect with partner' open onClose={handleOnClose}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <FormikSelect label='Contact' name='id' options={options} fullWidth />
        </Stack>
        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={handleOnClose}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton type='submit' size='small'>
            Connect
          </FilledButton>
        </Box>
      </FormikForm>
    </ModalWithClose>
  );
}
