import { ICloseType } from "./ICloseType";
export type IBorderLocation = "top" | "bottom" | "left" | "right";
export type ITabLocation = "top" | "bottom";

export interface IJsonModel {
    global?: IGlobalAttributes;
    borders?: IJsonBorderNode[];
    layout: IJsonRowNode; // top level 'row' is horizontal, rows inside rows take opposite orientation to parent row (ie can act as columns)
	popouts?: Record<string, IJsonPopout>;
}

export interface IJsonRect {
	x: number;
	y: number;
	width: number;
	height: number;
}

export interface IJsonPopout {
    layout: IJsonRowNode;
	rect: IJsonRect ;
}

export interface IJsonBorderNode extends IBorderAttributes {
    location: IBorderLocation;
    children: IJsonTabNode[];
}

export interface IJsonRowNode extends IRowAttributes {
    children: (IJsonRowNode | IJsonTabSetNode)[];
}

export interface IJsonTabSetNode extends ITabSetAttributes {
	/** Marks this as the active tab set, read from initial json but 
	 * must subseqently be set on the model (only one tab set can be active)*/
    active?: boolean; 
	/** Marks this tab set as being maximized, read from initial json but 
	 * must subseqently be set on the model (only one tab set can be maximized) */
    maximized?: boolean; 
    children: IJsonTabNode[];
}

export interface IJsonTabNode extends ITabAttributes {
}

//----------------------------------------------------------------------------------------------------------
// below this line is autogenerated from attributes in code via Model static method toTypescriptInterfaces()
//----------------------------------------------------------------------------------------------------------
export interface IGlobalAttributes {
	/**
	  Value for BorderNode attribute autoSelectTabWhenClosed if not overridden

	  whether to select new/moved tabs in border when the border is currently closed

	  Default: false
	 */
	borderAutoSelectTabWhenClosed?: boolean;

	/**
	  Value for BorderNode attribute autoSelectTabWhenOpen if not overridden

	  whether to select new/moved tabs in border when the border is already open

	  Default: true
	 */
	borderAutoSelectTabWhenOpen?: boolean;

	/**
	  Value for BorderNode attribute className if not overridden

	  class applied to tab button

	  Default: undefined
	 */
	borderClassName?: string;

	/**
	  Value for BorderNode attribute enableAutoHide if not overridden

	  hide border if it has zero tabs

	  Default: false
	 */
	borderEnableAutoHide?: boolean;

	/**
	  Value for BorderNode attribute enableDrop if not overridden

	  whether tabs can be dropped into this border

	  Default: true
	 */
	borderEnableDrop?: boolean;

	/**
	  Value for BorderNode attribute maxSize if not overridden

	  the maximum size of the tab area

	  Default: 99999
	 */
	borderMaxSize?: number;

	/**
	  Value for BorderNode attribute minSize if not overridden

	  the minimum size of the tab area

	  Default: 0
	 */
	borderMinSize?: number;

	/**
	  Value for BorderNode attribute size if not overridden

	  size of the tab area when selected

	  Default: 200
	 */
	borderSize?: number;

	/**
	  enable docking to the edges of the layout, this will show the edge indicators

	  Default: true
	 */
	enableEdgeDock?: boolean;

	/**
	  boolean indicating if tab icons should rotate with the text in the left and right borders

	  Default: true
	 */
	enableRotateBorderIcons?: boolean;

	/**
	  the top level 'row' will layout horizontally by default, set this option true to make it layout vertically

	  Default: false
	 */
	rootOrientationVertical?: boolean;

	/**
	  enable a small centralized handle on all splitters

	  Default: false
	 */
	splitterEnableHandle?: boolean;

	/**
	  additional width in pixels of the splitter hit test area

	  Default: 0
	 */
	splitterExtra?: number;

	/**
	  width in pixels of all splitters between tabsets/borders

	  Default: 8
	 */
	splitterSize?: number;

	/**
	  Value for TabNode attribute borderHeight if not overridden

	  height when added to border, -1 will use border size

	  Default: -1
	 */
	tabBorderHeight?: number;

	/**
	  Value for TabNode attribute borderWidth if not overridden

	  width when added to border, -1 will use border size

	  Default: -1
	 */
	tabBorderWidth?: number;

	/**
	  Value for TabNode attribute className if not overridden

	  class applied to tab button

	  Default: undefined
	 */
	tabClassName?: string;

	/**
	  Value for TabNode attribute closeType if not overridden

	  see values in ICloseType

	  Default: 1
	 */
	tabCloseType?: ICloseType;

	/**
	  Value for TabNode attribute contentClassName if not overridden

	  class applied to tab content

	  Default: undefined
	 */
	tabContentClassName?: string;

	/**
	  

	  Default: 0.3
	 */
	tabDragSpeed?: number;

	/**
	  Value for TabNode attribute enableClose if not overridden

	  allow user to close tab via close button

	  Default: true
	 */
	tabEnableClose?: boolean;

	/**
	  Value for TabNode attribute enableDrag if not overridden

	  allow user to drag tab to new location

	  Default: true
	 */
	tabEnableDrag?: boolean;

	/**
	  Value for TabNode attribute enablePopout if not overridden

	  enable popout (in popout capable browser)

	  Default: false
	 */
	tabEnablePopout?: boolean;

	/**
	  Value for TabNode attribute enablePopoutIcon if not overridden

	  whether to show the popout icon in the tabset header if this tab enables popouts

	  Default: true
	 */
	tabEnablePopoutIcon?: boolean;

	/**
	  Value for TabNode attribute enablePopoutOverlay if not overridden

	  if this tab will not work correctly in a popout window when the main window is backgrounded (inactive)
            then enabling this option will gray out this tab

	  Default: false
	 */
	tabEnablePopoutOverlay?: boolean;

	/**
	  Value for TabNode attribute enableRename if not overridden

	  allow user to rename tabs by double clicking

	  Default: true
	 */
	tabEnableRename?: boolean;

	/**
	  Value for TabNode attribute enableRenderOnDemand if not overridden

	  whether to avoid rendering component until tab is visible

	  Default: true
	 */
	tabEnableRenderOnDemand?: boolean;

	/**
	  Value for TabNode attribute icon if not overridden

	  the tab icon

	  Default: undefined
	 */
	tabIcon?: string;

	/**
	  Value for TabNode attribute maxHeight if not overridden

	  the max height of this tab

	  Default: 99999
	 */
	tabMaxHeight?: number;

	/**
	  Value for TabNode attribute maxWidth if not overridden

	  the max width of this tab

	  Default: 99999
	 */
	tabMaxWidth?: number;

	/**
	  Value for TabNode attribute minHeight if not overridden

	  the min height of this tab

	  Default: 0
	 */
	tabMinHeight?: number;

	/**
	  Value for TabNode attribute minWidth if not overridden

	  the min width of this tab

	  Default: 0
	 */
	tabMinWidth?: number;

	/**
	  Value for TabSetNode attribute autoSelectTab if not overridden

	  whether to select new/moved tabs in tabset

	  Default: true
	 */
	tabSetAutoSelectTab?: boolean;

	/**
	  Value for TabSetNode attribute classNameTabStrip if not overridden

	  a class name to apply to the tab strip

	  Default: undefined
	 */
	tabSetClassNameTabStrip?: string;

	/**
	  Value for TabSetNode attribute enableActiveIcon if not overridden

	  whether the active icon (*) should be displayed when the tabset is active

	  Default: false
	 */
	tabSetEnableActiveIcon?: boolean;

	/**
	  Value for TabSetNode attribute enableClose if not overridden

	  allow user to close tabset via a close button

	  Default: false
	 */
	tabSetEnableClose?: boolean;

	/**
	  Value for TabSetNode attribute enableDeleteWhenEmpty if not overridden

	  whether to delete this tabset when is has no tabs

	  Default: true
	 */
	tabSetEnableDeleteWhenEmpty?: boolean;

	/**
	  Value for TabSetNode attribute enableDivide if not overridden

	  allow user to drag tabs to region of this tabset, splitting into new tabset

	  Default: true
	 */
	tabSetEnableDivide?: boolean;

	/**
	  Value for TabSetNode attribute enableDrag if not overridden

	  allow user to drag tabs out this tabset

	  Default: true
	 */
	tabSetEnableDrag?: boolean;

	/**
	  Value for TabSetNode attribute enableDrop if not overridden

	  allow user to drag tabs into this tabset

	  Default: true
	 */
	tabSetEnableDrop?: boolean;

	/**
	  Value for TabSetNode attribute enableMaximize if not overridden

	  allow user to maximize tabset to fill view via maximize button

	  Default: true
	 */
	tabSetEnableMaximize?: boolean;

	/**
	  Value for TabSetNode attribute enableSingleTabStretch if not overridden

	  if the tabset has only a single tab then stretch the single tab to fill area and display in a header style

	  Default: false
	 */
	tabSetEnableSingleTabStretch?: boolean;

	/**
	  Value for TabSetNode attribute enableTabStrip if not overridden

	  enable tab strip and allow multiple tabs in this tabset

	  Default: true
	 */
	tabSetEnableTabStrip?: boolean;

	/**
	  Value for TabSetNode attribute enableTabWrap if not overridden

	  show tabs in location top or bottom

	  Default: false
	 */
	tabSetEnableTabWrap?: boolean;

	/**
	  Value for TabSetNode attribute maxHeight if not overridden

	  maximum height (in px) for this tabset

	  Default: 99999
	 */
	tabSetMaxHeight?: number;

	/**
	  Value for TabSetNode attribute maxWidth if not overridden

	  maximum width (in px) for this tabset

	  Default: 99999
	 */
	tabSetMaxWidth?: number;

	/**
	  Value for TabSetNode attribute minHeight if not overridden

	  minimum height (in px) for this tabset

	  Default: 0
	 */
	tabSetMinHeight?: number;

	/**
	  Value for TabSetNode attribute minWidth if not overridden

	  minimum width (in px) for this tabset

	  Default: 0
	 */
	tabSetMinWidth?: number;

	/**
	  Value for TabSetNode attribute tabLocation if not overridden

	  the location of the tabs either top or bottom

	  Default: "top"
	 */
	tabSetTabLocation?: ITabLocation;

}
export interface IRowAttributes {
	/**
	  the unique id of the row, if left undefined a uuid will be assigned

	  Default: undefined
	 */
	id?: string;

	/**
	  

	  Fixed value: "row"
	 */
	type?: string;

	/**
	  relative weight for sizing of this row in parent row

	  Default: 100
	 */
	weight?: number;

}
export interface ITabSetAttributes {
	/**
	  whether to select new/moved tabs in tabset

	  Default: inherited from Global attribute tabSetAutoSelectTab (default true)
	 */
	autoSelectTab?: boolean;

	/**
	  a class name to apply to the tab strip

	  Default: inherited from Global attribute tabSetClassNameTabStrip (default undefined)
	 */
	classNameTabStrip?: string;

	/**
	  a place to hold json config used in your own code

	  Default: undefined
	 */
	config?: any;

	/**
	  whether the active icon (*) should be displayed when the tabset is active

	  Default: inherited from Global attribute tabSetEnableActiveIcon (default false)
	 */
	enableActiveIcon?: boolean;

	/**
	  allow user to close tabset via a close button

	  Default: inherited from Global attribute tabSetEnableClose (default false)
	 */
	enableClose?: boolean;

	/**
	  whether to delete this tabset when is has no tabs

	  Default: inherited from Global attribute tabSetEnableDeleteWhenEmpty (default true)
	 */
	enableDeleteWhenEmpty?: boolean;

	/**
	  allow user to drag tabs to region of this tabset, splitting into new tabset

	  Default: inherited from Global attribute tabSetEnableDivide (default true)
	 */
	enableDivide?: boolean;

	/**
	  allow user to drag tabs out this tabset

	  Default: inherited from Global attribute tabSetEnableDrag (default true)
	 */
	enableDrag?: boolean;

	/**
	  allow user to drag tabs into this tabset

	  Default: inherited from Global attribute tabSetEnableDrop (default true)
	 */
	enableDrop?: boolean;

	/**
	  allow user to maximize tabset to fill view via maximize button

	  Default: inherited from Global attribute tabSetEnableMaximize (default true)
	 */
	enableMaximize?: boolean;

	/**
	  if the tabset has only a single tab then stretch the single tab to fill area and display in a header style

	  Default: inherited from Global attribute tabSetEnableSingleTabStretch (default false)
	 */
	enableSingleTabStretch?: boolean;

	/**
	  enable tab strip and allow multiple tabs in this tabset

	  Default: inherited from Global attribute tabSetEnableTabStrip (default true)
	 */
	enableTabStrip?: boolean;

	/**
	  show tabs in location top or bottom

	  Default: inherited from Global attribute tabSetEnableTabWrap (default false)
	 */
	enableTabWrap?: boolean;

	/**
	  the unique id of the tab set, if left undefined a uuid will be assigned

	  Default: undefined
	 */
	id?: string;

	/**
	  maximum height (in px) for this tabset

	  Default: inherited from Global attribute tabSetMaxHeight (default 99999)
	 */
	maxHeight?: number;

	/**
	  maximum width (in px) for this tabset

	  Default: inherited from Global attribute tabSetMaxWidth (default 99999)
	 */
	maxWidth?: number;

	/**
	  minimum height (in px) for this tabset

	  Default: inherited from Global attribute tabSetMinHeight (default 0)
	 */
	minHeight?: number;

	/**
	  minimum width (in px) for this tabset

	  Default: inherited from Global attribute tabSetMinWidth (default 0)
	 */
	minWidth?: number;

	/**
	  

	  Default: undefined
	 */
	name?: string;

	/**
	  index of selected/visible tab in tabset

	  Default: 0
	 */
	selected?: number;

	/**
	  the location of the tabs either top or bottom

	  Default: inherited from Global attribute tabSetTabLocation (default "top")
	 */
	tabLocation?: ITabLocation;

	/**
	  

	  Fixed value: "tabset"
	 */
	type?: string;

	/**
	  relative weight for sizing of this tabset in parent row

	  Default: 100
	 */
	weight?: number;

}
export interface ITabAttributes {
	/**
	  if there is no name specifed then this value will be used in the overflow menu

	  Default: undefined
	 */
	altName?: string;

	/**
	  height when added to border, -1 will use border size

	  Default: inherited from Global attribute tabBorderHeight (default -1)
	 */
	borderHeight?: number;

	/**
	  width when added to border, -1 will use border size

	  Default: inherited from Global attribute tabBorderWidth (default -1)
	 */
	borderWidth?: number;

	/**
	  class applied to tab button

	  Default: inherited from Global attribute tabClassName (default undefined)
	 */
	className?: string;

	/**
	  see values in ICloseType

	  Default: inherited from Global attribute tabCloseType (default 1)
	 */
	closeType?: ICloseType;

	/**
	  string identifying which component to run (for factory)

	  Default: undefined
	 */
	component?: string;

	/**
	  a place to hold json config for the hosted component

	  Default: undefined
	 */
	config?: any;

	/**
	  class applied to tab content

	  Default: inherited from Global attribute tabContentClassName (default undefined)
	 */
	contentClassName?: string;

	/**
	  allow user to close tab via close button

	  Default: inherited from Global attribute tabEnableClose (default true)
	 */
	enableClose?: boolean;

	/**
	  allow user to drag tab to new location

	  Default: inherited from Global attribute tabEnableDrag (default true)
	 */
	enableDrag?: boolean;

	/**
	  enable popout (in popout capable browser)

	  Default: inherited from Global attribute tabEnablePopout (default false)
	 */
	enablePopout?: boolean;

	/**
	  whether to show the popout icon in the tabset header if this tab enables popouts

	  Default: inherited from Global attribute tabEnablePopoutIcon (default true)
	 */
	enablePopoutIcon?: boolean;

	/**
	  if this tab will not work correctly in a popout window when the main window is backgrounded (inactive)
            then enabling this option will gray out this tab

	  Default: inherited from Global attribute tabEnablePopoutOverlay (default false)
	 */
	enablePopoutOverlay?: boolean;

	/**
	  allow user to rename tabs by double clicking

	  Default: inherited from Global attribute tabEnableRename (default true)
	 */
	enableRename?: boolean;

	/**
	  whether to avoid rendering component until tab is visible

	  Default: inherited from Global attribute tabEnableRenderOnDemand (default true)
	 */
	enableRenderOnDemand?: boolean;

	/**
	  if enabled the tab will re-mount when popped out/in

	  Default: false
	 */
	enableWindowReMount?: boolean;

	/**
	  An optional help text for the tab to be displayed upon tab hover.

	  Default: undefined
	 */
	helpText?: string;

	/**
	  the tab icon

	  Default: inherited from Global attribute tabIcon (default undefined)
	 */
	icon?: string;

	/**
	  the unique id of the tab, if left undefined a uuid will be assigned

	  Default: undefined
	 */
	id?: string;

	/**
	  the max height of this tab

	  Default: inherited from Global attribute tabMaxHeight (default 99999)
	 */
	maxHeight?: number;

	/**
	  the max width of this tab

	  Default: inherited from Global attribute tabMaxWidth (default 99999)
	 */
	maxWidth?: number;

	/**
	  the min height of this tab

	  Default: inherited from Global attribute tabMinHeight (default 0)
	 */
	minHeight?: number;

	/**
	  the min width of this tab

	  Default: inherited from Global attribute tabMinWidth (default 0)
	 */
	minWidth?: number;

	/**
	  name of tab to be displayed in the tab button

	  Default: "[Unnamed Tab]"
	 */
	name?: string;

	/**
	  class applied to parent tabset when this is the only tab and it is stretched to fill the tabset

	  Default: undefined
	 */
	tabsetClassName?: string;

	/**
	  

	  Fixed value: "tab"
	 */
	type?: string;

}
export interface IBorderAttributes {
	/**
	  whether to select new/moved tabs in border when the border is currently closed

	  Default: inherited from Global attribute borderAutoSelectTabWhenClosed (default false)
	 */
	autoSelectTabWhenClosed?: boolean;

	/**
	  whether to select new/moved tabs in border when the border is already open

	  Default: inherited from Global attribute borderAutoSelectTabWhenOpen (default true)
	 */
	autoSelectTabWhenOpen?: boolean;

	/**
	  class applied to tab button

	  Default: inherited from Global attribute borderClassName (default undefined)
	 */
	className?: string;

	/**
	  a place to hold json config used in your own code

	  Default: undefined
	 */
	config?: any;

	/**
	  hide border if it has zero tabs

	  Default: inherited from Global attribute borderEnableAutoHide (default false)
	 */
	enableAutoHide?: boolean;

	/**
	  whether tabs can be dropped into this border

	  Default: inherited from Global attribute borderEnableDrop (default true)
	 */
	enableDrop?: boolean;

	/**
	  the maximum size of the tab area

	  Default: inherited from Global attribute borderMaxSize (default 99999)
	 */
	maxSize?: number;

	/**
	  the minimum size of the tab area

	  Default: inherited from Global attribute borderMinSize (default 0)
	 */
	minSize?: number;

	/**
	  index of selected/visible tab in border; -1 means no tab selected

	  Default: -1
	 */
	selected?: number;

	/**
	  show/hide this border

	  Default: true
	 */
	show?: boolean;

	/**
	  size of the tab area when selected

	  Default: inherited from Global attribute borderSize (default 200)
	 */
	size?: number;

	/**
	  

	  Fixed value: "border"
	 */
	type?: string;

}