import { ClearAll, Remove } from '@mui/icons-material';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import {
  FormikForm,
  FormikTextAreaField,
} from '@pw/components/Forms/FormikForm';
import AiGenerate from '@pw/components/icons/AiGenerate';
import { Body1, Body2, Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import { useAiGenerator } from '@pw/utilities/hooks/ai/useAiGenerator';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import SvgBay from '@pw/components/icons/Bay';
import SvgRow from '@pw/components/icons/Row';
import SvgLevel from '@pw/components/icons/Level';

const renderLevels = (row, l = []) => {
  return (
    <>
      {l.map((level) => (
        <TreeItem
          key={level.name}
          itemId={`${row}.${level.name}`}
          label={
            <Stack direction="row" alignItems="center" spacing="1rem">
              <SvgLevel style={{ height: '16px', width: '16px' }} />
              <Body2>{level.name}</Body2>
            </Stack>
          }
        />
      ))}
    </>
  );
};

const renderRows = (bay, r = []) => {
  return (
    <>
      {r.map((row) => (
        <TreeItem
          key={row.name}
          itemId={`${bay}.${row.name}`}
          label={
            <Stack direction="row" alignItems="center" spacing="1rem">
              <SvgRow style={{ height: '16px', width: '16px' }} />
              <Body2>{row.name}</Body2>
            </Stack>
          }
        >
          {renderLevels(`${bay}.${row.name}`, row.levels)}
        </TreeItem>
      ))}
    </>
  );
};

export const renderBays = (b = []) => {
  return (
    <>
      {b.map((bay) => (
        <TreeItem
          key={bay.name}
          itemId={`${bay.name}`}
          label={
            <Stack direction="row" alignItems="center" spacing="1rem">
              <SvgBay style={{ height: '16px', width: '16px' }} />
              <Body2>{bay.name}</Body2>
            </Stack>
          }
        >
          {renderRows(bay.name, bay.rows)}
        </TreeItem>
      ))}
    </>
  );
};

const baysSpec = [
  'Array of "bays" objects as follows',
  {
    name: 'Unique bay name, matching existing naming convention, if none, use numeric sequence, for example "1", "2", "3", etc.',
    rows: [
      'Array of "rows" objects as follows',
      {
        name: 'Unique row name within this bay, start from the same row name in each bay, if none existing, use numeric sequence, for example "1", "2", "3", etc.',
        levels: [
          'Array of "levels" objects as follows',
          {
            name: 'Unique level name within this row, start from the same level name in each row, if none existing, use numeric sequence, for example "1", "2", "3", etc.',
          },
        ],
      },
    ],
  },
];

function BayModal({ open, existing = [], onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { generator } = useAiGenerator();

  const [inputProps, setInputProps] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectedItemsChange = (event, ids) => setSelectedItems(ids);

  const [bays, init] = useItemListManager({
    id: ID.name,
    comp: COMP.name,
  });

  const changeSet = {
    prompt: ['', yup.string().required('Please enter a prompt!')],
  };

  const handleGenerate = useCallback(
    (values) => {
      console.log('Values', values.prompt);
      const prompt = values.prompt.toLowerCase().trim();
      setInputProps({
        endAdornment: <CircularProgress size={32} color='info' />,
      });

      if (prompt === 'add another bay') {
        const newBay = {
          name: `${existing.length + 1}`,
          rows: [
            {
              name: '1',
              levels: [
                {
                  name: '1',
                },
              ],
            },
          ],
        };

        const updatedBays = [...bays, newBay];
        init(updatedBays);
        setInputProps(null);
      } else if (
        prompt.includes('add row and level to bay') ||
        prompt.includes('add another row to the bay')
      ) {
        const bayNameMatch =
          prompt.match(/add row and level to bay (\d+)/i) ||
          prompt.match(/add another row to the bay (\d+)/i);
        if (bayNameMatch) {
          const bayName = bayNameMatch[1].trim();
          const updatedBays = existing.map((bay) => {
            if (bay.name.toLowerCase() === bayName.toLowerCase()) {
              const newRow = {
                name: `${bay.rows.length + 1}`,
                levels: [
                  {
                    name: '1',
                  },
                ],
              };

              return {
                ...bay,
                rows: [...bay.rows, newRow],
              };
            }
            return bay;
          });

          init(updatedBays);
        } else {
          enqueueSnackbar('Bay name not found in prompt!', {
            variant: 'error',
          });
        }

        setInputProps(null);
      } else {
        generator(baysSpec, existing, values.prompt)
          .then((b) => {
            console.log('Generated Bays', b?.bays);
            const updatedBays = [...bays, ...(b?.bays || [])];
            init(updatedBays);
          })
          .catch((e) => {
            console.log('Error', e.message);
            enqueueSnackbar(
              'Failed to generate from prompt, please try again!',
              {
                variant: 'error',
              },
            );
          })
          .finally(() => {
            setInputProps(null);
          });
      }
    },
    [existing, bays, init],
  );

  const handleSave = useCallback(() => onClose(bays), [bays]);

  const Toolbar = ({ onClose }) => {
    const confirm = useConfirm();

    const removeSelected = useCallback(() => {
      onClose();
      confirm({
        title: 'Remove Locations',
        content: (
          <Stack>
            <Body1>{`Remove selected locations?`}</Body1>
            <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
          </Stack>
        ),
      })
      .then(() => {
        const updatedBays = [];
        for (const b of bays) {
          if (selectedItems.includes(`${b.name}`)) {
            continue;
          }
          const updatedRows = [];
          for (const r of b.rows) {
            if (selectedItems.includes(`${b.name}.${r.name}`)) {
              continue;
            }
            const updatedLevels = [];
            for (const l of r.levels) {
              if (selectedItems.includes(`${b.name}.${r.name}.${l.name}`)) {
                continue;
              }
              updatedLevels.push(l);
            }
            updatedRows.push({
              name: r.name,
              levels: updatedLevels,
            });
          }
          updatedBays.push({
            name: b.name,
            rows: updatedRows,
          });
        }
        // Update the bays
        debounce(() => init(updatedBays), 25);
      })
      .catch(() => {});
    }, [selectedItems, bays, init]);

    const clearAll = useCallback(() => {
      onClose();
      confirm({
        title: 'Remove Locations',
        content: (
          <Stack>
            <Body1>{`Remove all locations?`}</Body1>
            <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
          </Stack>
        ),
      })
      .then(() => {
        debounce(() => init([]), 25);
      })
      .catch(() => {});
    }, [init]);

    return (
      <>
        <TitledButton
          handleClick={removeSelected}
          disabled={bays.length === 0}
          label='Remove'
        >
          <Remove height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={clearAll}
          disabled={bays.length === 0}
          label='Clear'
        >
          <ClearAll height={24} width={24} />
        </TitledButton>
      </>
    );
  }

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title='Generate Bays'>
      <FormikForm changeSet={changeSet} onSubmit={handleGenerate}>
        <Stack spacing='1.5rem'>
          <Body3>
            Enter a prompt to generate the required bays, for example:{' '}
            <code>Generate 10 bays, each with 5 rows having 5 levels.</code>
          </Body3>
          <FormikTextAreaField
            label='Prompt'
            name='prompt'
            disabled={!!inputProps}
            InputProps={inputProps}
            onKeyDown={() => {}}
            fullWidth
          />

          <ToolbarSection title="Bays" Toolbar={Toolbar}>
            {bays.length > 0 && (
              <Box
                sx={{ maxHeight: 350, minWidth: '100%', overflow: 'auto' }}
              >
                <SimpleTreeView
                  onSelectedItemsChange={handleSelectedItemsChange}
                  multiSelect
                >
                  {renderBays(bays)}
                </SimpleTreeView>
              </Box>
            )}
          </ToolbarSection>
        </Stack>
      </FormikForm>

      <Box className='action-buttons'>
        <TextButton
          size='small'
          handleClick={() => onClose()}
          color='secondary'
        >
          Cancel
        </TextButton>
        <FilledButton
          size='small'
          handleClick={handleSave}
          disabled={bays.length === 0}
        >
          Add
        </FilledButton>
      </Box>
    </ModalWithClose>
  );
}

export default BayModal;
