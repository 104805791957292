import Box from '@mui/material/Box';
import { DESIGN_NODE_TYPE } from '@pw/consts/designs';
import { useCallback } from 'react';
import { EdgeLabelRenderer, getSmoothStepPath, StepEdge, useStore } from 'reactflow';

import styles from './Edge.styles';
import EdgeForm from './EdgeForm';
import { calcOffset } from './Position';

function ConfigurableEdge({ id, source, target, className, selected, children, ...rest }) {
  const sourceNode = useStore(useCallback(
    (store) => store.nodeInternals.get(source)
    , [source]));

  const targetNode = useStore(useCallback(
    (store) => store.nodeInternals.get(target)
    , [target]));

  if (!(sourceNode && targetNode)) return null;

  const showOffsetForm = targetNode.data.category !== DESIGN_NODE_TYPE.OBSERVATION;

  const category = (
    sourceNode.data.category === DESIGN_NODE_TYPE.MACHINE &&
    targetNode.data.category === DESIGN_NODE_TYPE.MACHINE
  )
    ? 'machine'
    : 'default';

  const classNames = [
    category === DESIGN_NODE_TYPE.MACHINE ? 'machine-edge' : 'default-edge',
    selected ? 'selected' : ''
  ].join(' ');

  const { sourcePosition, targetPosition } = rest;
  const [sourceX, sourceY] = calcOffset(sourceNode, sourcePosition, 'source');
  const [targetX, targetY] = calcOffset(targetNode, targetPosition, 'target');

  const [edgePath,
    labelX, labelY, offsetX, offsetY
  ] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
    borderRadius: 0,
    strokeWidth: 3
  });

  // const labelX = (sourceX + targetX) / 2;
  // const labelY = (sourceY + targetY) / 2;

  return (
    <Box
      as="g"
      sx={styles}
      className={`custom-edge ${className || ''} ${classNames || ''}`}
    >
      <StepEdge
        id={id}
        edgePath={edgePath}
        {...rest}
      />
      {showOffsetForm && (
        <EdgeLabelRenderer>
          <EdgeForm
            labelX={labelX}
            labelY={labelY}
            sourcePosition={sourcePosition}
            targetPosition={targetPosition}
            targetNode={targetNode}
            sourceNode={sourceNode}
            id={id}
          />
        </EdgeLabelRenderer>
      )}
    </Box>
  );
}

export default ConfigurableEdge;
