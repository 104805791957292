import { ASSET_TYPES } from '@pw/consts/asset';
import { DEFAULT_CURRENCY } from '@pw/consts/config';
import { REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import { TASK_TABLE_STATUS, TASK_TYPES } from '@pw/consts/tasks';
import dayjs from 'dayjs';

export function toDate({ date = Date.now(), format = 'DD/MM/YYYY' }) {
  return dayjs(date).format(format);
}

export function toLocationText(location, locations = []) {
  const cLocation = locations.find(({ _id }) => _id === location?.location_id);
  const cBay = (cLocation?.bays ?? []).find(
    ({ _id }) => _id === location?.bay_id,
  );
  const cRow = (cBay?.rows ?? []).find(({ _id }) => _id === location?.row_id);
  const cLevel = (cRow?.levels ?? []).find(
    ({ _id }) => _id === location?.level_id,
  );
  if (cLocation && cBay && cRow && cLevel) {
    return `${cLocation?.name}/${cBay?.name}/${cRow?.name}/${cLevel?.name}`;
  }
  if (cLocation && cBay && cRow) {
    return `${cLocation?.name}/${cBay?.name}/${cRow?.name}`;
  }
  if (cLocation && cBay) {
    return `${cLocation?.name}/${cBay?.name}`;
  }
  if (cLocation) {
    return `${cLocation?.name}`;
  }
  return '';
}

export function toUniqueLocationId({ facility_id = '', row = '', level = '' }) {
  if (facility_id && row && level) {
    return `${facility_id}/${row}/${level}`;
  }
  if (facility_id && row) {
    return `${facility_id}/${row}`;
  }
  if (facility_id) {
    return `${facility_id}`;
  }
  return '';
}

export function toTasksCardEntity(values, isAsset = false) {
  const { type } = values ?? {};
  const date = values.created ?? new Date().getTime();
  const dueDate = values?.due ?? date;
  const status = values?.status ?? TASK_TABLE_STATUS.AWAITING_APPROVAL;
  const action = TASK_TYPES[type];
  const assignees = values.assignees ?? [];
  const client = values.client ?? null;
  const sources = values.source_properties ?? null;
  const destinations = values.destination_properties ?? null;
  const location = values.location ?? null;
  const shipAddress = values.properties?.destination;
  let parent_asset_id = null;
  parent_asset_id = values?.destinations?.assets?.find(
    (asset) => asset?.type === ASSET_TYPES.PALLET,
  )?._id;

  console.log('Card', values);

  return {
    _id: values._id,
    name: values.name,
    type,
    isAsset,
    date: new Date(date).toLocaleDateString(),
    dueDate: new Date(dueDate).toLocaleDateString(),
    status,
    action,
    assignees,
    client,
    sources,
    destinations,
    location,
    shipAddress,
    parent_asset_id,
  };
}

export function fromReportId(path) {
  const [report_type = '', nonce = ''] = path?.split('/') ?? [];
  return {
    report_type,
    nonce: Number(nonce),
  };
}

export const toPrice = ({ rawPrice, currencyCode = DEFAULT_CURRENCY }) => {
  const price = rawPrice * 0.01;

  if (Number.isNaN(price)) return '--';

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode,
  }).format(price);
};

export const toSKURequest = (
  converter,
  {
    size = {},
    net_weight = {},
    gross_weight = {},
    capacity = {},
    abv = {},
    ...rest
  },
) => {
  const converted = {
    size: { ...size },
    net_weight: { ...net_weight },
    gross_weight: { ...gross_weight },
    capacity: { ...capacity },
    abv: { ...abv },
  };
  if (size?.value) {
    converted.size.value = converter.cx(size?.value, size?.unit);
  }
  if (net_weight?.value) {
    converted.net_weight.value = converter.cx(
      net_weight?.value,
      net_weight?.unit,
    );
  }
  if (gross_weight?.value) {
    converted.gross_weight.value = converter.cx(
      gross_weight?.value,
      gross_weight?.unit,
    );
  }
  if (capacity?.value) {
    converted.capacity.value = converter.cx(capacity?.value, capacity?.unit);
  }
  if (abv?.value) {
    converted.abv.value = converter.cx(abv?.value, abv?.unit);
  }
  return {
    ...rest,
    ...converted,
  };
};
