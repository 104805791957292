import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgSearch from '@pw/components/icons/Search';
import SearchForm from '@pw/components_v2/search/general/SearchForm';
import { useCallback, useState } from 'react';

function Search({ types, multi = false, onSelected}) {
  const [search, setSearch] = useState(false);

  const openSearch = useCallback((e) => {
    setSearch(true);
  }, [setSearch]);

  const onClose = useCallback((selected) => {
    onSelected(selected);
    setSearch(false);
  }, [setSearch, onSelected]);

  return (
    <>
      <TitledButton onClick={openSearch} label="Search">
        <SvgSearch height={24} width={24} />
      </TitledButton>
      {!!search && (
        <SearchForm open={!!search} types={types} multi={multi} onClose={onClose} />
      )}
    </>
  );
}

export default Search;
