import { ArrowForward } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { Filter, Monitoring } from '@pw/components/icons';
import SvgAirQuality from '@pw/components/icons/AirQuality';
import SvgHumidity from '@pw/components/icons/Humidity';
import SvgPower from '@pw/components/icons/Power';
import Temperature from '@pw/components/icons/Temperature';

const LayoutMonitoring = ({currentWidth, currentHeight}) => {
  const isSmallScreen = currentWidth < 600;
  const isMediumScreen = currentWidth >= 600 && currentWidth < 1024;
  return (
    <Box id="LayoutMonitoring">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          paddingLeft: isSmallScreen ? "8px" : "16px",
          paddingRight: isSmallScreen ? "20px" : "54px",
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: isSmallScreen ? "8px" : "16px",
            boxSizing: 'border-box',
          }}
        >
          <IconButton sx={{ marginRight: '20px' }}>
            <Monitoring height={43} width={29} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: isSmallScreen ? "5px" : "9px",
            }}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? "16px" : "19px",
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              Monitoring
            </Typography>
            <Typography
              variant='body1'
              component='div'
              sx={{
                fontWeight: '600',
                fontSize: isSmallScreen ? "14px" : "16px",
                lineHeight: '22px',
                color: '#393C3C',
              }}
            >
              fr.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.01.24
              </Typography>
              <ArrowForward
                sx={{
                  verticalAlign: 'middle',
                  marginX: '4px',
                  width: '50px',
                }}
              />
              to.
              <Typography
                component='span'
                sx={{
                  fontWeight: '400',
                  fontSize: 'inherit',
                  color: 'inherit',
                }}
              >
                01.02.2024
              </Typography>
            </Typography>
          </Box>
        </Box>

        <IconButton>
          <Filter />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: isSmallScreen
          ? "1fr"
          : isMediumScreen
          ? "1fr 1fr"
          : "1fr 1fr 1fr 1fr",
          margin: 'auto',
          paddingLeft: isSmallScreen ? "20px" : "42px",
          columnGap: '20px',
          rowGap: '7px',
          width: '100%',
        }}
      >
        {/* Temperature */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Temperature
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              padding: '',
              boxSizing: 'border-box',
            }}
          >
            <IconButton sx={{ marginRight: '20px' }}>
              <Temperature height={43} width={24} />
            </IconButton>
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '400',
                fontSize: '39px',
                lineHeight: '58px',
                color: '#393C3C',
              }}
            >
              20.7 °C
            </Typography>
          </Box>
          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Humidity */}
        <Box sx={{ position: 'relative', paddingRight: '42px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Humidity
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              padding: '',
              boxSizing: 'border-box',
            }}
          >
            <IconButton sx={{ marginRight: '20px' }}>
              <SvgHumidity height={43} width={24} />
            </IconButton>
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '400',
                fontSize: '39px',
                lineHeight: '58px',
                color: '#393C3C',
              }}
            >
              40.2 %
            </Typography>
          </Box>

          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Air Quality  */}
        <Box sx={{ position: 'relative', paddingRight: '54px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Air Quality
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              padding: '',
              boxSizing: 'border-box',
            }}
          >
            <IconButton sx={{ marginRight: '20px' }}>
              <SvgAirQuality height={43} width={24} />
            </IconButton>
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '400',
                fontSize: '39px',
                lineHeight: '58px',
                color: '#393C3C',
              }}
            >
              1 %
            </Typography>
          </Box>

          {/* Line */}
          <Box
            sx={{
              position: 'absolute',
              height: '200px',
              width: '1px',
              top: '0%',
              right: '0',
              border: '1px solid #707070',
              opacity: 0.17,
            }}
          />
        </Box>

        {/* Power  */}
        <Box sx={{ position: 'relative', paddingRight: '54px' }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: '600',
              fontSize: '19px',
              lineHeight: '22px',
              color: '#393C3C',
              height: '50px',
            }}
          >
            Power
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              padding: '',
              boxSizing: 'border-box',
            }}
          >
            <IconButton sx={{ marginRight: '20px' }}>
              <SvgPower height={43} width={24} />
            </IconButton>
            <Typography
              variant='h6'
              component='div'
              sx={{
                fontWeight: '400',
                fontSize: '39px',
                lineHeight: '58px',
                color: '#393C3C',
              }}
            >
              100 %
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutMonitoring;
