import { HourglassTop, Settings } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

import Box from '@mui/material/Box';
import { unitField } from '@pw/components/admin/Things/UnitField';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';

import { TIME_UNIT_OPTIONS } from '@pw/consts/measures';

// import StandardEdge from './StandardEdge';
import DesignerContext from '@pw/context/DesignerContext';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useMemo, useState } from 'react';
import styles from './EdgeForm.styles';

const PERCENT_OPTION = [{ label: 'Percent', value: '%' }];

const verticalHandles = ['Top', 'Bottom'];
const horizontalHandles = ['Left', 'Right'];

const EdgeForm = ({ id, labelX, labelY, sourcePosition, targetPosition }) => {
  const { edgesData, saveEdgeData, mode } = useContext(DesignerContext);

  const [open, setOpen] = useState(false);

  const edgeData = edgesData?.find((e) => e.id === id) ?? { id };

  const {
    offset
  } = edgeData ?? {};

  const changeSet = {
    offset: unitField(offset)
  };

  const fixOffsets = useMemo(() => {
    let offsetX = 0;
    let offsetY = 0;
    return [offsetX, offsetY];
  }, [sourcePosition, targetPosition]);

  const handleSubmit = useCallback((payload) => {
    console.log('handleSubmit', { payload });

    debounce(() => {
      saveEdgeData({ ...edgeData, ...payload });
      setOpen(false);
    }, 25);
  }, [edgeData, saveEdgeData]);

  const delay = (offset?.value && offset?.unit)
    ? <Box className="token"><HourglassTop />{offset.value}{offset.unit}</Box>
    : null;

  const label = (delay) ? <>{delay}</> : null;

  return (
    <Box
      className="edge-form-root"
      sx={{
        ...styles,
        left: `${labelX - fixOffsets[0]}px`,
        top: `${labelY - fixOffsets[1]}px`
      }}>

      <Button
        className={`edge-button ${label ? 'with-label' : ''} ${mode === 'design' ? 'design-mode' : 'run-mode'}`}
        onClick={() => {
          setOpen(true);
        }}>
        {label || <Settings />}
      </Button>

      <ModalWithClose open={open} onClose={() => setOpen(false)} title="Offset">
        <FormikForm
          changeSet={changeSet}
          onSubmit={handleSubmit}
        >
          <Stack spacing={2}>
            <FormikUnitField
              label="Offset"
              name="offset"
              options={TIME_UNIT_OPTIONS}
            />

            <Box className="action-buttons">
              <TextButton size="small" handleClick={() => setOpen(false)} color="secondary">
                Cancel
              </TextButton>
              <FilledButton type="submit" size="small">
                Save
              </FilledButton>
            </Box>
          </Stack>
        </FormikForm>
      </ModalWithClose>
    </Box>
  );
};

export default EdgeForm;
