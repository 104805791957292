function SvgDelete(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 19 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Delete_svg__clip0_1_169)' fill='#404040'>
        <path d='M3.978 23.538c-.151-.04-.305-.077-.455-.123-1.118-.34-1.903-1.376-1.96-2.583-.006-.127-.006-.254-.006-.381 0-4.266-.002-8.53.006-12.796 0-.21-.067-.292-.258-.362C.452 6.981-.105 6.046.017 5.16.147 4.2.927 3.42 1.87 3.376c.934-.043 1.871-.025 2.807-.034h.202c.015-.298.021-.586.043-.874.08-1.054.984-1.91 2.126-1.918C8.685.54 10.323.53 11.958.553c1.262.02 2.122.932 2.148 2.21.004.18 0 .359 0 .567.09.005.17.011.25.011.814.001 1.629-.008 2.443.003.967.012 1.676.43 2.056 1.324.423.996-.114 2.235-1.144 2.619-.2.074-.268.166-.268.38.008 4.28.005 8.56.006 12.84 0 .892-.254 1.681-.927 2.309-.385.359-.843.582-1.365.678-.042.007-.082.029-.123.044H3.978zM16.353 7.455h-13.7v.196c0 4.303 0 8.606.002 12.91 0 .148.013.299.036.446.136.857.81 1.451 1.677 1.452 3.426.005 6.853.003 10.279.001.427 0 .805-.137 1.12-.428.449-.416.586-.946.586-1.526V7.686v-.231zM9.494 6.36h7.171c.121 0 .244-.003.365-.016a.948.948 0 00.854-.878.95.95 0 00-.707-1 1.9 1.9 0 00-.452-.044c-4.819-.002-9.637-.002-14.455 0-.114 0-.229.006-.34.024a.968.968 0 00-.82.918.97.97 0 00.738.962c.146.033.302.034.453.034 2.398.003 4.796.002 7.194.002v-.002zM6 3.323h7.012c0-.108.002-.196 0-.285-.003-.157.002-.315-.017-.47-.063-.553-.474-.938-1.037-.94a498.687 498.687 0 00-4.91 0c-.57.002-.998.405-1.045.96-.02.237-.003.477-.003.735z' />
        <path d='M6.815 14.488c0 1.652.002 3.305-.005 4.959 0 .15-.036.315-.107.446-.1.186-.295.255-.508.223-.22-.033-.377-.155-.437-.373a1.01 1.01 0 01-.038-.265c-.001-3.313-.002-6.627 0-9.94 0-.356.152-.58.42-.636.337-.071.627.13.667.47.014.126.007.255.007.382v4.734zM8.956 14.514c0-1.66.001-3.32 0-4.98 0-.248.056-.46.294-.589.286-.153.67-.007.76.3.032.112.041.235.041.354.002 3.268.002 6.536 0 9.805 0 .104-.008.21-.031.31a.532.532 0 01-1.037.003 1.375 1.375 0 01-.026-.31v-4.892zM12.19 14.494V9.603c0-.476.192-.724.554-.717.35.006.542.256.542.713v9.805c0 .467-.204.728-.56.72-.352-.006-.536-.252-.536-.717v-4.913z' />
      </g>
      <defs>
        <clipPath id='Delete_svg__clip0_1_169'>
          <path fill='#fff' transform='translate(0 .538)' d='M0 0h19v23H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgDelete;
