import objectToQueryString from '@pw/utilities/objectToQueryString';

const useProductionDesignerQuery = (thingId, batchId) => {
  const query = batchId
    ? objectToQueryString({ thing_id: thingId, batch_id: batchId })
    : objectToQueryString({ thing_id: thingId });

  return `/app/production-designer${query}`;
};

export default useProductionDesignerQuery;
