import { BATCH_TYPE } from '../consts/batch';
import { TASK_TYPES } from '../consts/tasks';
import cardStyles from './card.styles';
import formStyles from './form.styles';
import listStyles from './list.styles';

const styles = {
	[`&[action="${TASK_TYPES.cask}"]`]: { '--action-color': '#4C4' },
	[`&[action="${TASK_TYPES.ibc}"]`]: { '--action-color': '#2a58af' },
	[`&[action="${TASK_TYPES.pallet}"]`]: { '--action-color': '#5c1479' },
	[`&[action="${TASK_TYPES.container}"]`]: { '--action-color': '#44880a' },
	[`&[action="${TASK_TYPES.pick}"]`]: { '--action-color': '#00a89d' },
	[`&[action="${TASK_TYPES.regauge}"]`]: { '--action-color': '#1a3352' },
	[`&[action="${TASK_TYPES.sample}"]`]: { '--action-color': '#fd3b02' },
	[`&[action="${TASK_TYPES.ship}"]`]: { '--action-color': '#C9C9C2' },
	[`&[action="${TASK_TYPES.transfer}"]`]: { '--action-color': '#D0B158' },
	[`&[action="${TASK_TYPES.production_run}"]`]: { '--action-color': '#D0B158' },
	[`&[action="${BATCH_TYPE}"]`]: { '--action-color': '#616e79' },
	[`&[action="${TASK_TYPES.tanker}"]`]: { '--action-color': '#7a4507' },
	[`&[action="${TASK_TYPES.filling_tank}"]`]: { '--action-color': '#c24f1e' },
	[`&[action="${TASK_TYPES.restock}"]`]: { '--action-color': '#6e0606' },
	[`&[action="${TASK_TYPES.change_ownership}"]`]: {
		'--action-color': '#60b4f1',
	},
	[`&[action="${TASK_TYPES.inventory_audit}"]`]: {
		'--action-color': '#7a136d',
	},
	[`&[action="${TASK_TYPES.delivery}"]`]: { '--action-color': '#a99223' },
	[`&[action="${TASK_TYPES.duty_transfer}"]`]: { '--action-color': '#1a1a1a' },

	'&.root': {
		position: 'absolute',
		overflow: 'hidden',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: {
			xs: '90vw',
			md: '75vw',
			lg: '50vw',
		},
		maxWidth: '95vw',
		height: 'fit-content',
		maxHeight: '95vh',
		backgroundColor: '#fff',
		boxShadow: 24,
		pt: 3,
		px: 3,
		pb: 4,
		outline: 0,
		border: 0,
		borderRadius: 2,
	},

	'&.small-root': {
		position: 'absolute',
		overflow: 'hidden',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: {
			xs: '90vw',
			md: '50vw',
		},
		maxWidth: '95vw',
		height: 'fit-content',
		maxHeight: '95vh',
		backgroundColor: '#fff',
		boxShadow: 24,
		pt: 3,
		px: 3,
		pb: 4,
		outline: 0,
		border: 0,
		borderRadius: 2,
	},

	'.main-content': {
		height: 'auto',
		maxHeight: 'calc(95vh - 9rem)',
		overflowY: 'auto',
	},

	'.action': {
		position: 'relative',
		paddingRight: '0.25rem',
		overflowWrap: 'break-word',
		color: 'var(--action-color)',
	},
	'.asset-container': {
		padding: '0.5rem',
		marginBottom: '1rem',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	'.asset-id': {
		fontSize: '0.75rem',
		marginBottom: '0.25rem',
		display: 'flex',
		alignItems: 'center',
		minWidth: 'max-content',
		svg: {
			verticalAlign: 'middle',
		},
	},
	'.close-btn': {
		position: 'absolute',
		top: '1rem',
		right: '1rem',
	},
	'.label': {
		display: 'block',
		fontWeight: '500',
		fontSize: '12px',
	},
	'.assignees': {
		marginTop: '1rem',
	},
	'.select': {
		marginTop: 0.5,
		height: '48px',
		width: '100%',
	},
	'.action-buttons': {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginTop: 3,
		gap: 1,

		button: {
			maxWidth: 'min-content',
		},
	},
	'& .MuiAvatar-root': {
		width: 18,
		height: 18,
		fontSize: '9',
		marginLeft: 0,
		color: 'text.color_2',
	},
	'.user-list': {
		// backgroundColor: '#fff',
		border: '1px solid #404040',
		borderRadius: '4px',
		padding: '0.5rem',
		minHeight: 'min-content',
		maxHeight: '40vh',
		overflowY: 'auto',
	},
	'.user-entry': {
		// backgroundColor: '#fff',
		padding: '.25rem',
		marginTop: '0.1rem',
		'&:first-of-type': {
			marginTop: 0,
		},
	},

	...cardStyles,
	...listStyles,
	...formStyles,

	'.listItem.storage-container': {
		flexDirection: 'column',
		alignItems: 'stretch',
	},

	'.form-section': {
		borderRadius: '5px',
		backgroundColor: '#fff',
		padding: '0.5rem',
		'--shadow-color': '180deg 4% 9%',
		boxShadow: `
            0.3px 0.4px 0.5px hsl(var(--shadow-color) / 0.45),
            0.6px 0.7px 1px -1.3px hsl(var(--shadow-color) / 0.43),
            1.4px 1.9px 2.6px -2.7px hsl(var(--shadow-color) / 0.41)`,
	},
};

export default styles;
