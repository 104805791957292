import { FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function UsedInRedistillationReport({
	name,
	title = 'Select Kind',
	disabled = false,
	specify = false,
}) {
	const spirits = [
		{
			value: 'Whiskey Distilled Over 160',
			label: 'Whiskey Distilled Over 160',
		},
		{
			value: 'Whiskey Distilled 160 and Under',
			label: 'Whiskey Distilled 160 and Under',
		},
		{ value: 'Brandy Distilled Over 170', label: 'Brandy Distilled Over 170' },
		{
			value: 'Brandy Distilled 170 and Under',
			label: 'Brandy Distilled 170 and Under',
		},
		{ value: 'Rum', label: 'Rum' },
		{ value: 'Gin', label: 'Gin' },
		{ value: 'Vodka', label: 'Vodka' },
		{
			value: 'Alcohol Distilled Under 190',
			label: 'Alcohol Distilled Under 190',
		},
		{
			value: 'Alcohol Distilled 190 and Over',
			label: 'Alcohol Distilled 190 and Over',
		},
		{ value: 'Other', label: 'Other' },
	];
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox alignItems='top'>
				<FormikSelect
					name='a'
					label='Kind of Spirits'
					options={spirits}
					// required
					fullWidth
					disabled={disabled}
				/>
				<FormikTextField
					name='b'
					label='Proof Gallons'
					// required
					fullWidth
					disabled={specify}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
