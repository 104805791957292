function SvgAiGenerate2(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 74.93 77.86'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path d='M30.58 75.2C15.98 75.07 3.23 64.85.1 50.65c-.3-1.38.22-2.47 1.41-2.93 1.18-.45 2.51.03 3.15 1.18.2.36.32.77.44 1.16 3.07 10.83 10.1 17.52 21.14 19.61 14 2.65 27.74-7.09 30.2-21.16.26-1.48.36-3 .44-4.5.09-1.64 1.11-2.76 2.59-2.72 1.44.04 2.44 1.19 2.43 2.79-.11 15.1-11.16 28.07-26.04 30.53-1.74.29-3.51.39-5.27.58zM74.41 17.55c-.01 1.21-.8 2.14-2.12 2.45-1.85.44-3.7.85-5.56 1.27-3.27.73-5.3 2.73-6.07 5.99-.43 1.85-.84 3.71-1.26 5.56-.32 1.41-1.26 2.23-2.53 2.22-1.24-.01-2.15-.83-2.47-2.2-.46-2-.87-4.02-1.39-6.01-.74-2.83-2.57-4.67-5.4-5.41-1.99-.52-4-.94-6.01-1.4-1.4-.32-2.23-1.27-2.22-2.53.01-1.24.84-2.16 2.2-2.47 1.85-.42 3.71-.83 5.56-1.26 3.26-.76 5.27-2.79 6.01-6.06.41-1.83.83-3.65 1.25-5.48C54.72.8 55.66-.02 56.93 0c1.24.02 2.15.82 2.47 2.2.43 1.85.83 3.71 1.26 5.56.76 3.27 2.78 5.27 6.05 6.01 1.85.42 3.71.83 5.56 1.27 1.36.32 2.15 1.28 2.14 2.52zM56.9 22.97c1.3-2.44 3.09-4.16 5.43-5.46-2.39-1.29-4.14-3.06-5.43-5.43-1.29 2.39-3.05 4.15-5.44 5.44 2.38 1.3 4.14 3.04 5.44 5.46z' />
      <path d='M33.12 41.29h12.49a2.5 2.5 0 012.51 2.22c.14 1.26-.71 2.44-1.97 2.72-.3.07-.62.06-.93.06h-12.1v12.47c0 1.2-.72 2.11-1.82 2.44-1.02.3-2.17-.07-2.72-1.02-.27-.47-.43-1.07-.44-1.62-.04-3.78-.02-7.56-.02-11.34v-.94h-.88c-3.83 0-7.66.01-11.49 0-1.94 0-3.18-1.8-2.43-3.49.47-1.06 1.35-1.52 2.51-1.52 3.83.01 7.66 0 11.49 0h.81v-.86-11.34c0-1.67 1-2.8 2.48-2.82 1.5-.01 2.52 1.13 2.53 2.85v12.17zM33.12 15.04c-.01 1.42-1.12 2.46-2.66 2.48-3.67.04-7.2.74-10.53 2.29-7.67 3.56-12.61 9.44-14.79 17.62-.32 1.21-.83 2.19-2.13 2.54a2.379 2.379 0 01-2.96-2.81c2.71-12.78 13.48-22.72 26.41-24.35 1.37-.17 2.75-.25 4.13-.28a2.46 2.46 0 012.53 2.51z' />
    </svg>
  );
}
export default SvgAiGenerate2;
