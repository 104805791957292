import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import SpecificationEditor from '@pw/components/SpecificationEditor';
import useSpecificationSelectorHook from '@pw/components/SpecificationSelector';
import StorageLossGain from '@pw/components/StorageGainLoss';
import DestinationAssetModal from '@pw/components/ThingSelector/modals/DestinationAssetModal';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import SourceAssetModal from '@pw/components/ThingSelector/modals/SourceAssetModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS, REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { UNITS } from '@pw/consts/units';
import { useCompanyThings } from '@pw/redux/containers/User/hooks';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { summarizeTransferLiquids } from '@pw/utilities/calculateTransferLiquidSummary';
import debounce from '@pw/utilities/debounce';
import fround from '@pw/utilities/fround';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import uniq from 'lodash.uniq';
import { useCallback, useMemo } from 'react';

export function convertSourceAsset(item) {
  const { properties = {} } = item ?? {};
  const { liquid = {} } = properties;
  const { level = {} } = liquid;
  const { bl = 0, la = false, expected_bl } = level;
  // Only add non-empty assets
  if (bl && la) {
    return toTaggedAsset({
      ...item,
      processed: ASSET_PROCESSED_STATUS.PENDING,
      properties: {
        ...properties,
        liquid: {
          ...liquid,
          level: {
            ...level,
            expected_bl: expected_bl ?? bl,
          },
        },
      },
    });
  }
  return null;
}

function TransferForm() {
  const thingList = useCompanyThings();
  const converter = useConverter();

  const [
    [specifications, initializeSpecifications],
    SpecificationComponent,
    SpecificationModals,
    loadSpecifications,
  ] = useSpecificationSelectorHook({
    requestType: REQUEST_TYPES.TRANSFER,
    title: 'Specification',
    SpecificationModal: SpecificationEditor,
  });

  const sourceProps = useMemo(
    () => ({
      title: 'Sources',
      filter: {
        asset_types: [
          ASSET_TYPES.PALLET,
          ASSET_TYPES.CASK,
          ASSET_TYPES.IBC,
          ASSET_TYPES.TANKER,
          ASSET_TYPES.FILLING_TANK,
        ],
        request_types: [REQUEST_TYPES.PICK],
        thing_types: [
          THING_TYPES.TRACKED,
          THING_TYPES.RAW,
          THING_TYPES.EXPIRING,
          THING_TYPES.CONSUMABLE,
          THING_TYPES.FINISHED,
        ],
      },
      assetFilter: (a) =>
        [
          ASSET_TYPES.IBC,
          ASSET_TYPES.CASK,
          ASSET_TYPES.TANKER,
          ASSET_TYPES.FILLING_TANK,
          ASSET_TYPES.PALLET,
        ].includes(a.type),
      // submitForm,
      assetAdapter: convertSourceAsset,
      ThingModal: SourceThingModal,
      thingModalProps: { calculateThingLiquid: true },
      AssetModal: SourceAssetModal,
    }),
    [],
  );

  const [
    [sourceThings, , , upsertSourceThings, removeSourceThings],
    [sourceAssets, , , upsertSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useInventorySelectorHook(sourceProps);

  const sourceWeightProperties = useMemo(() => {
    // Find the first asset with weight properties enabled and level properties disabled
    const assetWithWeight = sourceAssets?.find(
      (item) => item?.properties?.liquid?.weight?.enable,
    );
    console.log(
      'Weight properties',
      assetWithWeight?.properties?.liquid?.weight,
    );

    // Return weight properties if found, otherwise return null
    return assetWithWeight
      ? { weightFactor: assetWithWeight?.properties?.liquid?.weight }
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceAssets, sourceAssets.length]);

  const sourcesAbv = useMemo(() => {
    // If weight properties exist or there are no source assets, return 0
    if (sourceAssets.length === 0) return [];

    let totalBulk = 0;
    let totalAbv = 0; // Total baseline
    let totalTcf = 0; // TCF
    // Do a weighted average of the ABV and TCF
    sourceAssets
      .filter((asset) => asset?.properties?.liquid?.level?.bl)
      .forEach((asset) => {
        const bl = Number(asset?.properties?.liquid?.level?.bl);
        totalBulk += bl;
        totalAbv += Number(asset?.properties?.liquid?.level?.abv ?? 0) * bl;
        totalTcf += Number(asset?.properties?.liquid?.level?.tcf || 1.0) * bl;
      });

    console.log('ABV', totalAbv, totalTcf, totalBulk);

    if (totalBulk > 0) {
      return [fround(totalAbv / totalBulk, 2), fround(totalTcf / totalBulk, 3)];
    }
    return [];
  }, [sourceAssets]);

  const sourcesLiquidThing = useMemo(() => {
    // If there are no source assets, return an empty string
    if (sourceAssets.length === 0) return {};
    // Check if the liquid SKU is the same for all source assets
    const liquidThings = sourceAssets
      .map((s) => s.properties?.liquid?.thing?._id ?? '')
      .filter((y) => y !== '')
      .sort()
      .filter((x, i, a) => !i || x !== a[i - 1]);

    console.log('Liquid Things', liquidThings);

    // Grab the batch, the date and the liquid from the propertiess..
    const props = sourceAssets.map((s) => [
      s.properties?.liquid?.batch,
      s.properties?.liquid?.date,
      s.properties?.liquid?.liquid,
    ]);
    const uniqueProps = uniq(props.sort());
    console.log('Unique properties', uniqueProps);
    let [batch, date, liquid] = uniqueProps[0] ?? [];

    // If same, then use that..
    if (liquidThings.length === 1) {
      const thingId = liquidThings[0];
      // Find this thing
      const fs = thingList.find((s) => s._id === thingId);
      if (fs) {
        return {
          batch,
          liquid,
          date,
          thing: thingId,
        };
      }
    }
    return {};
  }, [sourceAssets, thingList]);

  const convertDestinationAsset = useCallback(
    (item) => {
      // Extract the max capacity and set that as the expected..
      const { properties = {}, thing } = item ?? {};

      // Default capacity in liters
      const capacity = thing?.properties?.capacity ?? {
        value: '200',
        unit: UNITS.liters,
      };

      // Update the properties
      const { liquid = {} } = properties;
      const { level = {} } = liquid;
      const { bl = '0' } = level;

      console.log(
        'Adding dest',
        item,
        sourceWeightProperties,
        sourcesAbv,
        sourcesLiquidThing,
      );

      const updatedAbv = Number(sourcesAbv[0] ?? level.updated_abv ?? '0');
      const updatedTcf = Number(sourcesAbv[1] ?? level.updated_tcf ?? '1.000');

      // Factor in existing bulk and capacity to compute what we can fill in this container
      return toTaggedAsset({
        ...item,
        properties: {
          ...properties,
          liquid: {
            ...liquid,
            ...sourcesLiquidThing,
            level: {
              ...level,
              enableWeight: !!sourceWeightProperties,
              ...(sourceWeightProperties ?? {}),
              expected_bl:
                Number(capacity.value) > 0
                  ? Math.min(
                      Number(capacity.value) - Number(bl),
                      Number(capacity.value),
                    )
                  : null,
              updated_abv: updatedAbv,
              updated_tcf: updatedTcf,
            },
          },
        },
      });
    },
    [sourceWeightProperties, sourcesAbv, sourcesLiquidThing],
  );

  const onDestinationThingAdded = useCallback(
    (thing) => {
      console.log(
        'Destination Thing added',
        thing?.properties?.things?.length,
        thing?.properties?.releases?.length,
        thing?.type,
        thing,
      );
      if (thing?.type === THING_TYPES.FINISHED) {
        const amount = thing?.entries?.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0,
        );
        console.log('Amount', amount);

        // which skus to use
        let things = [];
        let assets = [];

        // Find the release
        const releases = thing?.entries?.flatMap((e) => e.release?.id);
        console.log('Releases', releases?.length);

        // Find the release
        const release_list = thing?.properties?.releases?.filter((r) =>
          releases.includes(r.id),
        );

        console.log('Releases', release_list);

        if (release_list && release_list.length > 0) {
          // See if there is a skus list in the releases, if so, add all of them in there..
          release_list
            .map((r) => r.things)
            .flat()
            .filter((s) => s.type === THING_TYPES.CONSUMABLE)
            .forEach((s) => {
              const existing = things.find((k) => k._id === s._id);
              if (!existing) {
                console.log(' --> adding thing', s);
                things.push({
                  ...s,
                  amount: Number(amount) * Number(s?.amount),
                });
              }
            });
          // If there are assets, pull that in as well
          release_list
            .map((r) => r.assets)
            .flat()
            .forEach((a) => {
              const existing = assets.find((k) => k.asset_id === a.asset_id);
              if (!existing) {
                console.log(' --> adding asset', a);
                assets.push({
                  ...a,
                });
              }
            });
        }

        // Deprecated, need to get rid of this eventually, all should come from release
        if (things.length === 0) {
          things = thing?.properties?.things
            .filter((s) => s.type === THING_TYPES.CONSUMABLE)
            .map((s) => ({
              ...s,
              amount: Number(amount) * Number(s?.amount),
            }));
        }
        if (assets.length === 0) {
          assets = thing?.properties?.assets ?? [];
        }

        debounce(() => {
          upsertSourceThings(things);
          upsertSourceAssets(assets);
        }, 25);
      }

      loadSpecifications(thing?.specifications);
    },
    [upsertSourceThings, upsertSourceAssets, loadSpecifications],
  );

  const onDestinationThingRemoved = useCallback(
    (sku) => {
      console.log('Destination thing removed', sku);
      // if (
      // 	sku?.sku_type === SKU_TYPES.FINISHED &&
      // 	sku?.properties?.skus?.length > 0
      // ) {
      // 	removeSourceSkus(sku?.properties?.skus);
      // }
    },
    [removeSourceThings],
  );

  const destProps = useMemo(
    () => ({
      title: 'Destinations',
      filter: {
        asset_types: [
          ASSET_TYPES.PALLET,
          ASSET_TYPES.CASK,
          ASSET_TYPES.IBC,
          ASSET_TYPES.TANKER,
          ASSET_TYPES.FILLING_TANK,
        ],
        request_types: [REQUEST_TYPES.PICK, REQUEST_TYPES.CHANGE_OWNERSHIP],
        thing_types: [
          THING_TYPES.TRACKED,
          THING_TYPES.FINISHED,
          THING_TYPES.WASTE,
          THING_TYPES.SERVICES,
        ],
      },
      assetFilter: (a) =>
        [
          ASSET_TYPES.IBC,
          ASSET_TYPES.CASK,
          ASSET_TYPES.TANKER,
          ASSET_TYPES.FILLING_TANK,
          ASSET_TYPES.PALLET,
        ].includes(a.type),
      // submitForm,
      assetAdapter: convertDestinationAsset,
      ThingModal: DestinationThingModal,
      thingModalProps: { calculateThingLiquid: true },
      AssetModal: DestinationAssetModal,
      parentAssetSupport: true,
      onThingAdded: onDestinationThingAdded,
      onThingRemove: onDestinationThingRemoved,
    }),
    [
      convertDestinationAsset,
      onDestinationThingAdded,
      onDestinationThingRemoved,
    ],
  );

  const [
    [destinationThings],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook(destProps);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        assets: entity?.destinations?.assets,
        things: entity?.destinations?.things,
      });
      initializeSpecifications(entity?.specifications);
    },
    [
      initSources,
      initDestinations,
      initializeSpecifications,
    ],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      const liquidSummary = summarizeTransferLiquids({
        sourceAssets,
        destinationAssets,
        sourceThings,
        destinationSkus: destinationThings,
        converter,
        thingList,
      });
      return {
        ...entity,
        liquid_summary: liquidSummary,
        sources: {
          things: sourceThings,
          assets: sourceAssets,
        },
        destinations: {
          assets: destinationAssets,
          things: destinationThings,
        },
        specifications,
      };
    },
    [
      sourceThings,
      sourceAssets,
      destinationThings,
      destinationAssets,
      specifications,
    ],
  );

  return (
    <>
      <RequestForm
        requestLabel='Transfer'
        requestType={REQUEST_TYPES.TRANSFER}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <DestinationInventory enableFill enableUpload />

        <PolicySelector />

        <SpecificationComponent />

        <StorageLossGain
          sources={sourceAssets}
          destinations={destinationAssets}
        />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
      <SpecificationModals />
    </>
  );
}

export default withAppLayout(TransferForm, { title: 'Liquid Transfer' });
