import { Collapse, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import SvgUser from '@pw/components/icons/User';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, H5 } from '@pw/components/Typography';
import UserSelector from '@pw/components_v2/CompanyView/modals/users/UserSelector';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { upsertTeamThunk } from '@pw/redux/thunks/teams';
import { useTeamLazyQuery } from '@pw/utilities/hooks/service/useTeamQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import RoleSelector from './RoleSelector';

function TeamEditor({ entry, company, onClose, readonly }) {
  const dispatch = useDispatch();

  console.log('Team editor', entry);

  // We need to load the actual contact details from the entry..
  const [load, { isLoading, cancel }] = useTeamLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = {
    description: [entry?.description ?? '', yup.string().required('Description is required!')],
    roles: [[], yup.array().min(1, 'Role is required!')]
  };

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?._id) {
      load({ id: entry?._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry);
    }
  }, [entry]);

  const users = useMemo(() => (item?.roles ?? []).reduce((uc, role) => uc + (role.users?.length ?? 0), 0), [item]);

  const onSubmit = useCallback(async (values) => {
    const body = {
      ...(item ?? {}),
      ...values,
      company_id: company._id
    };
    dispatch(upsertTeamThunk(body))
    .unwrap()
    .then(onClose);
  }, [onClose, item]);

  const handleClose = useCallback(() => onClose(), [onClose]);

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.name}</H5>
        {!isLoading && (
          <Stack direction="row" spacing="0.25rem" alignItems="center">
            <SvgUser with={24} height={24} />
            <Body1>{users}</Body1>
          </Stack>
        )}
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className='form-content' in={!isLoading} unmountOnExit>
        <FormikForm
          changeSet={changeSet}
          onSubmit={onSubmit}
        >
          <Stack className="form" spacing="2rem">
            <FormikTextField name="description" label="Description" required />

            <RoleSelector initialValues={item?.roles} readonly={readonly} />

            <UserSelector readonly={readonly} />

            <FormButtons readonly={readonly} onCancel={onClose} />
          </Stack>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default TeamEditor;
