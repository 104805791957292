import { FormikDatePicker, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { useContext } from 'react';

export default function AssetID({ label }) {
	const { edit } = useContext(FormikContext);

	return (
		<>
			<FormikTextField
				name='name'
				label={label}
				fullWidth
				disabled={edit}
				required
			/>
			<FormikDatePicker name='created' label='Date' required 	disabled={edit} />
		</>
	);
}
