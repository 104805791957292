import { AddCircle, ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import IDDisplay from '@pw/components/properties/IDDisplay';
import LabelDisplay from '@pw/components/properties/LabelDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import QRImage from '@pw/components/QRImage';
import ListItemSkeleton from '@pw/components/sekeletons/ListItemSkeleton';
import { COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useAccountId, useCompanyId } from '@pw/redux/containers/User';
import { switchCompanyThunk } from '@pw/redux/thunks/user';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import { useCompaniesQuery } from '@pw/utilities/hooks/service/useCompaniesQueries';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function CompanyEntry({ company, onClick }) {
  const { _id, company_id: company_details } = company;
  const { _id: company_id, type: company_type, name: company_name} = company_details;

  // const accountId = useAccountId();

  // const qq = btoa(JSON.stringify({ c: company_id, n: company_name, a: accountId }));
  // const loc = typeof window !== 'undefined' && window?.location?.origin;
  // const origin = useMemo(() => loc || PUBLIC_APP_BASE_URL, [loc]);
  // const qrUrl = `${origin}/admin/partners?qq=${qq}`;

  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <Stack direction="row" spacing={1}>
          <Box sx={{ padding: '3px', border: '1px solid #3f3f3f', borderRadius: '3px' }}>
            <QRImage size={56} boxSize={56} value={_id} />
          </Box>
          <Stack>
            <NameDisplay name={company_name} />
            <IDDisplay onClick={onClick} value={company_id} />
            <LabelDisplay label="Profile" value={COMPANY_TYPE_REVERSE[company_type]} />
          </Stack>
        </Stack>
      </Box>
      <Stack className='listButtons'>
        <IconCircleButton onClick={() => onClick()}>
          <ArrowForward />
        </IconCircleButton>
      </Stack>
    </Box>
  );
}

function SwitchCompanyModal({ open, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { data, isLoading } = useCompaniesQuery({ limit: 50 });
  const { items: companies } = data ?? {};

  const company_id = useCompanyId();

  console.log('Companies', isLoading, companies);
  console.log('  -> company_id', company_id);

  const companyOptions = useMemo(() =>
    companies?.filter((c) => c.company_id._id !== company_id) ?? []
    , [companies]);

  const handleSelect = useCallback((id) => {
    dispatch(switchCompanyThunk({ company_id: id, i18n }))
      .unwrap()
      .then(() => onClose());
  }, [i18n]);

  const addNewCompany = () => {
    navigate(`/account/companies`, { state: { create: true }, replace: true });
    debounce(() => onClose(), 50);
  };

  return (
    <ModalWithClose onClose={onClose} open={open} title="Switch Company">
      <Stack className='inventory'>
        <Box className='inventory-header'>
          <Box />
          <TitledButton
            handleClick={addNewCompany}
            label='New'
          >
            <AddCircle height={24} width={24} />
          </TitledButton>
        </Box>
        <Box className='inventory-contents'>
          <Stack className='list'>
            {!isLoading && companyOptions.map((company) => (
              <CompanyEntry key={company._id} company={company} onClick={() => handleSelect(company.company_id._id)} />
            ))}
            {isLoading && (
              <>
                <ListItemSkeleton />
                <ListItemSkeleton />
                <ListItemSkeleton />
              </>
            )}
          </Stack>
        </Box>
      </Stack>
    </ModalWithClose>
  );
}

export default SwitchCompanyModal;
