import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import LedgersView from './LedgersView';

function LedgersModal({ company, open, onClose }) {
  const title = `${company.name} Ledgers`;
  console.log('Ledgers modal', company.name);

  return (
    <ModalWithClose onClose={() => onClose()} open={open} title={title}>
      <LedgersView company={company} />
    </ModalWithClose>
  );
}

export default LedgersModal;
