import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import { ArrowComponent } from '@pw/components/Pagination';
import RequestListComponent from '@pw/components/RequestListItem';
import { Body3, Overline } from '@pw/components/Typography';
import { ASSET_NAMES } from '@pw/consts/asset';
import { REQUEST_NAMES, REQUEST_TYPES_REVERSE } from '@pw/consts/requests';
import {
  useSearchAssetQueryPaginated,
  useSearchRequestQueryPaginated,
  useSearchThingQueryPaginated,
} from '@pw/utilities/hooks/service/useSearchQuery';
import toPairs from 'lodash.topairs';
import { useMemo } from 'react';
import { FlexBox } from '../Layout/FlexBox';
import ListItemSkeleton from '../sekeletons/ListItemSkeleton';
import PolicyListComponent from '../admin/Policy/PolicyListItem';
import SpecificationListComponent from '../admin/Specification/SpecificationListItem';
import { THING_TYPE_REVERSE } from '@pw/consts/thing';
import ThingItem from './items/ThingItem';

function AssetEntry({
  assetType,
  items,
  buttonIcon,
  onItemClick,
  searchInputValue,
}) {
  const reqParams = useMemo(() => {
    return {
      search: searchInputValue,
      asset_types: [assetType],
      limit: 5,
      assetType: assetType,
    };
  }, [assetType, searchInputValue]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useSearchAssetQueryPaginated(reqParams, items);

  return (
    <Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Overline className='search-section-title'>
          {ASSET_NAMES[assetType]}
        </Overline>
        <ArrowComponent
          hasNextPage={hasNext}
          hasPrevPage={hasPrev}
          onPressNextPage={loadNext}
          onPressPrevPage={loadPrev}
          size={'small'}
        />
      </Stack>

      {!isLoading && page && page.length > 0 && (
        <Stack className='list'>
          {page.map((a) => (
            <AssetListComponentV2
              item={a}
              key={a._id}
              buttonIcon={buttonIcon}
              onClick={onItemClick}
            />
          ))}
        </Stack>
      )}
      {!isLoading && (!page || page.length === 0) && (
        <Body3>Nothing yet.</Body3>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function AssetResults({ items, buttonIcon, onItemClick, searchInputValue }) {
  return (
    <Stack className='search-section' spacing={0.75}>
      {toPairs(items).map(([t, l]) => (
        <AssetEntry
          key={t}
          assetType={t}
          items={l}
          buttonIcon={buttonIcon}
          onItemClick={onItemClick}
          searchInputValue={searchInputValue}
        />
      ))}
    </Stack>
  );
}

function RequestEntry({
  requestType,
  items,
  buttonIcon,
  onItemClick,
  searchInputValue,
}) {
  const reqParams = useMemo(() => {
    return {
      search: searchInputValue,
      request_types: [requestType],
      limit: 5,
      requestType: requestType,
    };
  }, [requestType, searchInputValue]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useSearchRequestQueryPaginated(reqParams, items);

  return (
    <Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Overline className='search-section-title'>
          {REQUEST_NAMES[requestType]}
        </Overline>
        <ArrowComponent
          hasNextPage={hasNext}
          hasPrevPage={hasPrev}
          onPressNextPage={loadNext}
          onPressPrevPage={loadPrev}
        />
      </Stack>
      {!isLoading && page && page.length > 0 && (
        <Stack spacing={0.5}>
          <Stack className='list'>
            {page.map((a) => (
              <RequestListComponent
                item={a}
                key={a.path}
                buttonIcon={buttonIcon}
                onItemClick={() => onItemClick(a)}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {!isLoading && (!page || page.length === 0) && (
        <Body3>Nothing yet.</Body3>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
            <ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
            <ListItemSkeleton type={REQUEST_TYPES_REVERSE[requestType]} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function RequestResults({ items, buttonIcon, onItemClick, searchInputValue }) {
  return (
    <Stack className='search-section'>
      {toPairs(items).map(([t, l]) => (
        <RequestEntry
          key={t}
          requestType={t}
          items={l}
          buttonIcon={buttonIcon}
          onItemClick={onItemClick}
          searchInputValue={searchInputValue}
        />
      ))}
    </Stack>
  );
}

function ThingEntry({
  thingType,
  items,
  buttonIcon,
  onItemClick,
  searchInputValue,
}) {	
  const reqParams = useMemo(() => {
    return {
      search: searchInputValue,
      thing_types: [thingType],
      limit: 5,
      thingType,
    };
  }, [searchInputValue]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useSearchThingQueryPaginated(reqParams, items);

  return (
    <Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Overline className='search-section-title'>
          {THING_TYPE_REVERSE[thingType]}
        </Overline>
        <ArrowComponent
          hasNextPage={hasNext}
          hasPrevPage={hasPrev}
          onPressNextPage={loadNext}
          onPressPrevPage={loadPrev}
        />
      </Stack>
      {!isLoading && page && page.length > 0 && (
        <Stack spacing={0.5}>
          <Stack className='list'>
            {page.map((a) => (
              <ThingItem
                item={a}
                key={a._id}
                onClick={() => onItemClick(a)}
                buttonIcon={buttonIcon}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {!isLoading && (!page || page.length === 0) && (
        <Body3>Nothing yet.</Body3>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton type={THING_TYPE_REVERSE[thingType]} />
            <ListItemSkeleton type={THING_TYPE_REVERSE[thingType]} />
            <ListItemSkeleton type={THING_TYPE_REVERSE[thingType]} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function ThingResults({ items, buttonIcon, onItemClick, searchInputValue }) {
  return (
    <Stack className='search-section'>
      {toPairs(items).map(([t, l]) => (
        <ThingEntry
          key={t}
          thingType={t}
          items={l}
          buttonIcon={buttonIcon}
          onItemClick={onItemClick}
          searchInputValue={searchInputValue}
        />
      ))}
    </Stack>
  );
}

function PolicyResults({ items, buttonIcon = null, onItemClick = null }) {
  return (
    <Stack className='search-section'>
      <Overline className='search-section-title'>Policy</Overline>
      <Stack className='list'>
        {items.map((a) => (
          <PolicyListComponent
            item={a}
            key={a.path}
            onClick={() => onItemClick(a)}
            buttonIcon={buttonIcon}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function SpecificationResults({
  items,
  buttonIcon = null,
  onItemClick = null,
}) {
  return (
    <Stack className='search-section'>
      <Overline className='search-section-title'>Specification</Overline>
      <Stack className='list'>
        {items.map((a) => (
          <SpecificationListComponent
            item={a}
            key={a._id}
            onClick={() => onItemClick(a)}
            buttonIcon={buttonIcon}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function DisplayResults({
  assets = {},
  requests = {},
  things = {},
  batches = [],
  policies = [],
  specifications = [],
  ...rest
}) {
  const assetCount = useMemo(
    () =>
      toPairs(assets)
        .map(([, l]) => l.length)
        .reduce((c, v) => c + v, 0),
    [assets],
  );
  const requestCount = useMemo(
    () =>
      toPairs(requests)
        .map(([, l]) => l.length)
        .reduce((c, v) => c + v, 0),
    [requests],
  );
  const thingCount = useMemo(
    () =>
      toPairs(things)
        .map(([, l]) => l.length)
        .reduce((c, v) => c + v, 0),
    [things],
  );
  const results = useMemo(
    () =>
      assetCount +
      requestCount +
	  thingCount +
      batches.length +
      policies.length +
      specifications.length,
    [assetCount, requestCount, thingCount, batches, policies, specifications],
  );

  if (results === 0) {
    return <Body3>Nothing matches</Body3>;
  }

  return (
    <Box className='search-result-contents'>
      {thingCount > 0 && <ThingResults items={things} {...rest} />}
      {assetCount > 0 && <AssetResults items={assets} {...rest} />}
      {requestCount > 0 && <RequestResults items={requests} {...rest} />}
      {policies.length > 0 && <PolicyResults items={policies} {...rest} />}
      {specifications.length > 0 && (
        <SpecificationResults items={specifications} {...rest} />
      )}
    </Box>
  );
}

export default DisplayResults;
