import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import AssetSearch from '@pw/components_v2/search/asset';
import { ASSET_TYPES } from '@pw/consts/asset';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      tank: [initialValues?.tank ?? {}, yup.object()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <AssetSearch field="tank" label='Tank' types={[ASSET_TYPES.FILLING_TANK]} />
    </ModalWrapper>
  );
}

export default NodeModal;
