import { ArrowForward } from '@mui/icons-material';
import Box from '@mui/material/Box';
import withDialogCard from '@pw/components/Cards/DialogCard';
import { FormikForm } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import searchFields from '@pw/components/Search/searchFields';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_EDIT_URL, REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { usePageTitleHook } from '@pw/redux/containers/App';
import styles from '@pw/styles/content.styles';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

function SearchForm() {
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const searchValue = searchParams.get('searchValue') || '';

	usePageTitleHook('Search');

	const changeSet = useMemo(
		() => searchFields('search', searchValue),
		[searchValue],
	);

	const navigateToDetails = useCallback(
		async (item) => {
			console.log('Navigate', item);
			const { path, asset_type, request_type, sku_id } = item;
			if (asset_type) {
				await navigate(`${ASSET_EDIT_URL[asset_type]}/${path}`);
				return;
			}

			if (request_type) {
				await navigate(`${REQUEST_EDIT_URL[request_type]}/${path}`);
				return;
			}

			await navigate(`/admin/sku/?id=${sku_id}`);
		},
		[navigate],
	);

	const type_filter = useMemo(
		() => ({
			asset_types: Object.values(ASSET_TYPES),
			request_types: Object.values(REQUEST_TYPES),
			thing_types: Object.values(THING_TYPES),
		}),
		[],
	);

	return (
		<Box sx={styles} className='root'>
			<FormikForm
				changeSet={changeSet}
				onSubmit={() => {}}
				enableReinitialize={true}
				readonly={false}
			>
				<GeneralSearch
					name='search'
					title=''
					label='Search'
					initValue={searchValue}
					types={type_filter}
					buttonIcon={<ArrowForward />}
					onItemClick={navigateToDetails}
					scanQr
				/>
			</FormikForm>
		</Box>
	);
}

export default withAppLayout(withDialogCard(SearchForm));
