import React from 'react';

const mockLiquidData = [
  { sku: "SKU-001", quantity: 1500 },
  { sku: "SKU-002", quantity: 2500 },
  { sku: "SKU-003", quantity: 1200 },
  { sku: "SKU-004", quantity: 900 },
  { sku: "SKU-005", quantity: 3000 },
];
const LiquidBySkuWidget = () => {
  return (
    <div className="widget">
      <h3>Liquid by SKU</h3>
      <ul>
        {mockLiquidData.map((liquid, index) => (
          <li key={index}>{liquid.sku}: {liquid.quantity} liters</li>
        ))}
      </ul>
    </div>
  );
};

export default LiquidBySkuWidget;
