export const UPLOAD_ASSET_NEW = {
  spec: {
    abv: 'abv, strength, proof',
    parent_reference: 'pallet number, pallet id, pallet no.',
    reference: 'reference, cask number, cask id, cask reference, cask no., ibc number, ibc id, ibc reference, ibc no., tanker number, tanker id, tanker reference',
    la: 'cask lpa, lpa, la, litres of alcohol, total lpa, total la, rla, proof gallons',
    bl: 'cask volume, bulk, bulk litres, bulk litre, bulk liters, bulk liter, total bulk, cask bulk, rbl, wine gallons',
    tcf: 'tcf, temperature correction factor',
    cask_type: 'cask type, cask size, cask capacity, barrel type, wood type',
    liquid_type: 'liquid type, spirit type, material',
    fill_date: 'fill date, fill timestamp, fill datetime, age of spirit, age, production date',
  },
  fields: {
    abv: { label: 'abv' },
    parent_reference: { label: 'Parent Asset Reference' },
    reference: { label: 'Asset Reference' },
    la: { label: 'la' },
    bl: { label: 'bl' },
    tcf: { label: 'TCF' },
    cask_type: { label: 'cask_type' },
    liquid_type: { label: 'Liquid Type' },
    fill_date: { label: 'Production Date' },
  }
};

export const UPLOAD_ASSET_FILL = {
  spec: {
    abv: 'abv',
    rw_asset_id: 'cask number, cask id, cask no.',
    rw_parent_asset_id: 'pallet number, pallet id, pallet no.',
    reference: 'cask number',
    la: 'cask lpa, lpa, la, litres of alcohol, total lpa, total la, rla',
    bl: 'cask volume, bulk, bulk litres, total bulk, cask bulk, rbl',
    tcf: 'tcf, temperature correction factor',
    tare_weight: 'empty weight, tare weight',
    gross_weight: 'gross weight',
    cask_type: 'cask type, cask size, cask capacity, barrel type, wood type',
    liquid_type: 'liquid type, spirit type',
    fill_date: 'fill date, fill timestamp, fill datetime, age of spirit, age, production date',
  },
  fields: {
    abv: { label: 'abv' },
    rw_asset_id: { label: 'Asset ID' },
    rw_parent_asset_id: { label: 'Parent Asset ID' },
    reference: { label: 'Asset Reference' },
    la: { label: 'la' },
    bl: { label: 'bl' },
    tcf: { label: 'TCF' },
    tare_weight: { label: 'Tare Weight' },
    gross_weight: { label: 'Gross Weight' },
    cask_type: { label: 'cask_type' },
    liquid_type: { label: 'Liquid Type' },
    fill_date: { label: 'Production Date' },
  }
};

export const UPLOAD_ASSET_PARENT = {
  spec: {
    rw_asset_id: 'cask number, cask id, cask no.',
    rw_parent_asset_id: 'pallet number, pallet id, pallet no.',
  },
  fields: {
    rw_parent_asset_id: { label: 'Parent Asset ID' },
    rw_asset_id: { label: 'Asset ID' },
  },
};
