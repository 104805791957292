import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function ReportAdjustment({
	name,
	title = 'Adjustment',
	disabled = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='cases'
					label='Cases'
					required
					fullWidth
					disabled={disabled}
				/>
				<FormikTextField
					name='casks'
					label='Casks'
					required
					fullWidth
					disabled={disabled}
				/>
			</FlexBox>

			<FlexBox alignItems='top'>
				<FormikTextField
					name='litres'
					label='LPA'
					required
					fullWidth
					disabled={disabled}
				/>
				<FormikTextField
					name='reason'
					label='Reason'
					required
					fullWidth
					disabled={disabled}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
