import useLilly from '@pw/utilities/hooks/ai/useLilly';

export const useAiGenerator = () => {
  const lilly = useLilly();

  const generator = async (spec, existing, prompt) => {
    const generated = await lilly.chat(
      spec,
      `Generate a new dataset as json based on the spec and the existing data ${JSON.stringify(existing)}, the actual prompt is ${prompt}, output the json object as generated.`
    );
    console.log('generated', generated);
    return generated ?? [];
  }

  return { generator };
};
