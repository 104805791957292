import React from 'react';

const TotalAssetCountWidget = ({ count=5 }) => {
  return (
    <div className="widget">
      <h3>Total Assets</h3>
      <p>{count}</p>
    </div>
  );
};

export default TotalAssetCountWidget;
