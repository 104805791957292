import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import {
	FormikDatePicker,
	FormikForm,
	FormikNumberField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { useMemo } from 'react';
import * as yup from 'yup';

function ExpensesThing({ item, onClose }) {
	const {
		entries = [],
	} = item ?? {};

	// We expect there to be an entry - which has the specific values we want for delivery..
	const entry = entries[0] ?? {};
	const {
		vendor,
		total_cost,
		date_start,
		date_end,
	} = entry;

	const changeSet = useMemo(
		() => ({
			total_cost: [
				total_cost ?? '',
				yup.number().positive('Must be positive!').required('Expense cost required!'),
			],
			date_start: [date_start, yup.number()],
			date_end: [date_end, yup.number()],
		}),
		[total_cost],
	);

	const handleSubmit = (values) => {
		const thing = {
			...item,
			entries: [
				{ ...values }
			]
		};
		console.log('Setting thing', values, thing);
		onClose(thing);
	};

	return (
		<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
			<Stack spacing={1}>
				<FormikNumberField label='Cost' name='total_cost' fullWidth />
				<FlexBox alignItems='top'>
					<FormikDatePicker label='Start' name='date_start' fullWidth />
					<FormikDatePicker label='End' name='date_end' fullWidth />
				</FlexBox>

				<Box className='action-buttons'>
					<TextButton
						size='small'
						handleClick={() => onClose()}
						color='secondary'
						label='Cancel'
					/>
					<FilledButton type='submit' size='small'>
						Save
					</FilledButton>
				</Box>
			</Stack>
		</FormikForm>
	);
}

export default ExpensesThing;
