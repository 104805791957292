import { Box, Stack } from '@mui/material';
import Address from '@pw/components/Address';
import { addressFields } from '@pw/components/Address/addressFields';
import BaseUnits from '@pw/components/BaseUnits';
import { baseUnitFields } from '@pw/components/BaseUnits/baseUnitFields';
import FilledButton from '@pw/components/Buttons/FilledButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import NotificationSettings from '@pw/components/NotificationSettings';
import { notificationFields } from '@pw/components/NotificationSettings/notificationFields';
import { H5 } from '@pw/components/Typography';
import { useAccount, usePreferredUnits } from '@pw/redux/containers/User/hooks';
import { updateProfileThunk } from '@pw/redux/thunks/user';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function Settings() {
  const dispatch = useDispatch();
  const {
    account_id = '',
    account_name = '',
    account_email = '',
    account_notifications,
    account_telephone = '',
    account_phone = '',
    account_address,
    account_username,
  } = useAccount();

  const baseUnitsPref = usePreferredUnits();

  const changeSet = useMemo(
    () => ({
      _id: [account_id, yup.string()],
      name: [account_name, yup.string()],
      email: [
        account_email,
        yup.string().email('Invalid email!').required('Email required!'),
      ],
      public_name: [account_username, yup.string()],
      base_units: baseUnitFields(baseUnitsPref),
      notifications: notificationFields(account_notifications),
      telephone: [account_telephone, yup.string()],
      phone: [account_phone, yup.string()],
      address: addressFields(account_address),
    }),
    [
      baseUnitsPref,
      account_name,
      account_email,
      account_notifications,
      account_telephone,
      account_phone,
      account_address,
    ],
  );

  const handleSubmit = (values) => dispatch(updateProfileThunk(values));

  return (
    <Stack spacing='1.25rem'>
      <H5>Profile</H5>
      <FormikForm
        changeSet={changeSet}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Stack className='form' spacing='1.2rem'>
          <FormikTextField
            name='name'
            label='Full name'
            autoComplete='name'
            disabled
          />
          <FormikTextField
            name='email'
            label='Contact email'
            autoComplete='email'
            disabled
          />
          <BaseUnits />
          <NotificationSettings />
          <Address label='Address' isOptional={false} withCompany={false} />

          <Box className='action-buttons'>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </Stack>
  );
}

export default Settings;
