import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import { Overline } from '@pw/components/Typography';

function CreatorProps({ entity }) {
	const creationLog = entity?.access_log?.[0];
	const lastUpdateLog = entity?.access_log?.length > 1 ? entity?.access_log[entity?.access_log?.length - 1] : null;
	return (
		<FlexBox className='creator-view'>
			<Stack spacing={0.5} className='column'>
				<Overline>Created By</Overline>
				<AccountDisplay name={creationLog?.id?.name} />
				{creationLog?.date && <DateDisplay date={creationLog?.date} />}
			</Stack>

			{lastUpdateLog && (
				<Stack spacing={0.5} className='column'>
					<Overline>Last Updated By</Overline>
					<AccountDisplay name={lastUpdateLog?.id?.name} />
					{lastUpdateLog.date && <DateDisplay date={lastUpdateLog.date} />}
					{entity.properties?.liquid?.level?.enable
						&& entity.properties?.liquid?.level?.filled_date 
						&& <DateDisplay label="Filled on" date={entity.properties?.liquid?.level?.filled_date } />}
				</Stack>
			)}
		</FlexBox>
	);
}

export default CreatorProps;
