function SvgOffPageConnectorLeft(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M43.5 19.5h-12l-12 12 12 12h12z'
        fill='#ccffd5'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgOffPageConnectorLeft;
