import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchDelete from '@pw/utilities/fetchDelete';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/asset`;

export const saveAssetPhoto = async (photos, headers = {}) =>
	fetchPost(`${BASE}/images`, headers, photos);

export const getAssetPhoto = async (params, headers = {}) =>
	fetchGet(`${BASE}/images${objectToQueryString(params)}`, headers);

export const deleteAssetPhoto = async (params = {}, headers = {}) =>
	fetchDelete(`${BASE}/images${objectToQueryString(params)}`, headers);

export const getAsset = async (params, headers = {}) =>
	fetchGet(`${APP_API_GATEWAY}/pw/v2/get${objectToQueryString({...params, type: 'asset'})}`, headers);

// export const createAsset = async (asset, headers = {}) =>
// 	fetchPost(`${BASE}`, headers, asset);
export const createAsset = async (asset, headers = {}) =>
	fetchPost(`${APP_API_GATEWAY}/pw/v2/create`, headers, { asset_type: asset.type, ...asset, type: 'asset' });

export const recent = async (request, headers = {}) =>
	fetchPost(`${APP_API_GATEWAY}/pw/v2/list`, headers, { filter_types: [request.type], ...request, type: 'asset' });

export const listAssets = async (request, headers = {}) =>
	fetchPost(`${APP_API_GATEWAY}/pw/v2/list`, headers, { ...request, type: 'asset' });

// export const updateAsset = async (asset, headers = {}) =>
// 	fetchPut(`${BASE}`, headers, asset);

export const updateAsset = async (asset, headers = {}) =>
	fetchPost(`${APP_API_GATEWAY}/pw/v2/update`, headers, { asset_type: asset.type, ...asset, type: 'asset' });

export const removeAsset = async (asset = {}, headers = {}) =>
	fetchPost(`${APP_API_GATEWAY}/pw/v2/remove`, headers, { ...asset, type: 'asset' });

export const generateAssets = async (payload, headers = {}) =>
	fetchPost(`${BASE}/gen`, headers, payload);

// export const updateAssetsAdmin = async (asset, headers = {}) =>
// 	fetchPost(`${BASE}/admin`, headers, asset);

export const getAssets = async (request = {}, headers = {}) =>
	fetchPost(`${BASE}/list`, headers, request);

// export const getAssetsByType = async (params = {}, headers = {}) =>
// 	fetchGet(`${BASE}/list_by_type${objectToQueryString(params)}`, headers);

export const getAuditList = async (params = {}, headers = {}) =>
	fetchGet(`${BASE}/audit_list${objectToQueryString(params)}`, headers);

export const checkConflict = async (params = {}, headers = {}) =>
	fetchPost(`${BASE}/check-conflict`, headers, params);

export const downloadFacilityAssets = async (params = {}, headers = {}) =>
	fetchPost(`${BASE}/downloadFacilityAssets`, headers, params);
