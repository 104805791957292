import ArrowForward from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ThingListComponent from '@pw/components/admin/ThingListItem';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Pagination from '@pw/components/Pagination';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { H5 } from '@pw/components/Typography';
import { THING_EDIT_URL, THING_TYPE_REVERSE, THING_TYPES } from '@pw/consts/thing';
import styles from '@pw/styles/content.styles';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FlexBox } from '../Layout/FlexBox';
import { Skeleton } from '@mui/material';
import ListItemSkeleton from '../sekeletons/ListItemSkeleton';
import { useRecentThingQueryPaginated } from '@pw/utilities/hooks/service/useRecentThingQuery';
import ThingAssets from '../ThingAssets';
import { ASSET_EDIT_URL } from '@pw/consts/asset';

function ThingList({ thingType }) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    return {
      filter_types: [thingType],
      limit: Number(pageSize),
    };
  }, [thingType, pageSize]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useRecentThingQueryPaginated(reqParams);

  const goToItem = (params) => {
    const id = params?._id ?? 'new';
    const route = THING_EDIT_URL?.[thingType];
    navigate(`${route}/${id}`, { replace: true });
  };

  return (
    <Stack spacing={1}>
      <H5>Thing List</H5>
      {!isLoading && page && page.length > 0 && (
        <Stack spacing={0.5}>
          <Pagination
            hasNextPage={hasNext}
            hasPrevPage={hasPrev}
            onPressNextPage={loadNext}
            onPressPrevPage={loadPrev}
            onChangePageSize={setPageSize}
            defaultPageSize={pageSize}
          />
          <Stack className='list'>
            {page.map((i) => (
              <ThingListComponent
                key={i._id}
                item={i}
                buttonIcon={<ArrowForward />}
                onClick={() => goToItem(i)}
              />
            ))}
          </Stack>
        </Stack>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton type={thingType} />
            <ListItemSkeleton type={thingType} />
            <ListItemSkeleton type={thingType} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function ThingNavigation({ thingType, thingId }) {
  const navigate = useNavigate();

  const goToItem = (params) => {
    const route = THING_EDIT_URL[thingType];
    const id = params?._id ?? '';
    console.log('Navigating to', params, route, id);
    navigate(`${route}/${id}`);
  };

  const goToAssetItem = (params) => {
    const { _id = '', type } = params;
    if (type) {
      navigate(`${ASSET_EDIT_URL[type]}/${_id}`);
      return;
    }
  };

  return (
    <Stack sx={styles} className='root' spacing={3}>
      <Box className='section'>
        <GeneralSearch
          title='Search'
          types={{ thing_types: [thingType] }}
          label={THING_TYPE_REVERSE[thingType]}
          buttonIcon={<ArrowForward />}
          onItemClick={goToItem}
        />
      </Box>
      <ThingList thingType={thingType} />
      {[THING_TYPES.LIQUID, THING_TYPES.TRACKED, THING_TYPES.WIP].includes(thingType) && !!thingId && (
        <ThingAssets
          thing_id={thingId}
          thing_type={thingType}
          buttonIcon={<ArrowForward />}
          onItemClick={goToAssetItem}
        />
      )}
      {/*{[THING_TYPES.LIQUID, THING_TYPES.TRACKED, THING_TYPES.WIP].includes(skuType) &&*/}
      {/*	!!skuId && (*/}
      {/*		<SKUAssets*/}
      {/*			sku_id={skuId}*/}
      {/*			sku_type={skuType}*/}
      {/*			buttonIcon={<ArrowForward />}*/}
      {/*			onItemClick={goToAssetItem}*/}
      {/*		/>*/}
      {/*	)}*/}
    </Stack>
  );
}

export default withSmallDialogCard(ThingNavigation);
