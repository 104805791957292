import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import PermissionSelector from '@pw/components_v2/CompanyView/modals/users/PermissionSelector';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { nanoid } from 'nanoid/non-secure';
import { useCallback } from 'react';
import * as yup from 'yup';

function RoleModal({ open, item, onClose, readonly }) {
  const { _id = nanoid(), name = '', description = '', permissions } = item;

  console.log('Permissions,', permissions);

  const onSubmit = useCallback(async (values) => {
    const body = {
      ...(item ?? {}),
      ...values,
    };
    console.log('Role', body);
    onClose(body);
  }, [onClose, permissions]);

  const changeSet = {
    // company identifiers
    _id: [_id, yup.string()],
    name: [name, yup.string().required('Name is required!')],
    description: [description, yup.string()],
    permissions: [permissions, yup.object()],
  };

  return (
    <ModalWithClose open={open} onClose={onClose} title="Edit Role">
      <FormikForm
        changeSet={changeSet}
        onSubmit={onSubmit}
      >
        <Stack className="form" spacing="2rem">
          <FormikTextField name="name" label="Name" required />
          <FormikTextField name="description" label="Description" />

          <PermissionSelector />

          <FormButtons readonly={readonly} onCancel={onClose} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default RoleModal;
