import AssetAccounts from '@pw/components/AssetAccounts';
import { assetAccountsFields } from '@pw/components/AssetAccounts/assetAccountsFields';
import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';

function Properties() {
	return (
		<FormikProvider path='properties'>
			<AssetAccounts />
		</FormikProvider>
	);}

function propertyFields(fields = {}) {
	const { accounts = {} } = fields;
	return {
		accounts: assetAccountsFields(accounts),
	};
}
function ChangeOwnerShipForm(props) {
	const [FormSubmitter, submitForm] = useFormSubmissionHook();

	const [[], [sourceAssets], initSources, SourceInventory, SourceModals] =
    useSourceInventoryHook({
      title: 'Assets',
      filter: {
        asset_types: [
          ASSET_TYPES.CASK,
          ASSET_TYPES.IBC,
          ASSET_TYPES.PALLET,
          ASSET_TYPES.CONTAINER,
        ],
        request_types: [REQUEST_TYPES.DELIVERY, REQUEST_TYPES.TRANSFER],
      },
      submitForm,
    });

	const [
    [destinationSkus],
    [],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Services',
    filter: {
      thing_types: [THING_TYPES.SERVICES],
    },
    SKUModal: DestinationThingModal,
  });

	const changeSetGenerator = useMemo(() => (initialValues) => ({
		...requestIdFields(initialValues),
		properties: propertyFields(initialValues.properties),
		client: clientFields(initialValues.client),
	}), []);

	/**
	 * Initialization function
	 * @type {(function(*): void)|*}
	 */
	const handleInit = useCallback((entity) => {
		initSources({ ...entity, assets: entity?. sources, skus: entity?.sku_sources });
		initDestinations({ ...entity, skus: entity?.sku_destinations });
	}, [initSources, initDestinations]);

	/**
	 * Prior to saving the entity, this is called to inject in the sources
	 * @type {function(*): *&{sources: *, sku_sources: *}}
	 */
	const handleBeforeSave = useCallback(
		(entity) => ({
			...entity,
			sources: sourceAssets,
			sku_destinations: destinationSkus,
		}),
		[destinationSkus, sourceAssets],
	);

	return (
    <>
      <RequestForm
        requestLabel='Change Ownership'
        requestType={REQUEST_TYPES.CHANGE_OWNERSHIP}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />
        <DestinationInventory />

        <Properties />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(ChangeOwnerShipForm, {
	title: 'Transfer Ownership',
});
