import { DESIGN_NODE_TYPE } from '@pw/consts/designs';
import { nanoid } from 'nanoid/non-secure';
import { createKey } from "../nodes";

const nodeTypeLookup = (type) => {
  switch (type) {
    case DESIGN_NODE_TYPE:
      return 'processNode';
    case 'material':
      return 'materialNode';
    case 'observation':
      return 'observationNode';
    case 'operation':
      return 'operationNode';
    // just for debugging
    default:
      return 'unknownNode';
  }
}

const tileToNode = (item) => {
  console.log('Dropping new tile', item);

  if (!item) return null;
  const { position } = item;

  return {
    id: nanoid(),
    data: item,
    position,
    // dragHandle: '.icon-label-group',
    // position: { x: (itemWidth + itemGap) * i - (i > 0 ? itemGap : 0), y: 0 },
    type: item.category,
  }
}

export default tileToNode;
