import { AddAPhoto } from '@mui/icons-material';
import CameraIcon from '@mui/icons-material/Camera';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, ButtonBase, Dialog, Tooltip } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { Body1 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { saveAssetPhoto } from '@pw/services/asset.service';
import { useToggleState } from '@pw/utilities/hooks/logic/useToggleState';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import '../ScanOCR/polyfillGetUserMedia';
import { styles } from './CameraStyles';
import ImageDropZone from './ImageDropZone';

function CameraModal({ asset_id, onClose = (v) => { console.log('Close', v); } }) {
	const videoRef = useRef(null);
	const canvasRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [fileUploadToggle, setFileUploadToggle] = useState(false);
	const [photos, setPhotos] = useState([]);
	const [newImageCount, setNewImageCount] = useState(0); //TODO if not showing existing images delete this logic
	const { enqueueSnackbar } = useSnackbar();

	const setData = (data) => {
		//TODO add size control

		const remainingSlots = 5 - photos.length;
		const newPhotos = data.slice(0, remainingSlots);

		setNewImageCount(newPhotos.length);

		readFilesAsBase64(newPhotos)
			.then((updatedPhotos) => {
				setPhotos((prevPhotos) => [...prevPhotos, ...updatedPhotos]);
			})
			.catch((error) => {
				console.error('Error reading files:', error);
			});
	};

	const readFilesAsBase64 = (files) => {
		return Promise.all(
			files.map((file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						const base64Data = reader.result;
						resolve({ dataUrl: base64Data, key: null });
					};
					reader.onerror = (error) => reject(error);
					reader.readAsDataURL(file);
				});
			}),
		);
	};

	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({
				video: { width: 300, height: 300 },
			})
			.then((stream) => {
				let video = videoRef.current;
				video.srcObject = stream;
				video.play();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const closeVideo = () => {
		let video = videoRef.current;
		if (video && video.srcObject) {
			let tracks = video.srcObject.getTracks();
			tracks.forEach((track) => track.stop());
			video.srcObject = null;
		}
		onClose(newImageCount > 0);
	};

	const takePhoto = () => {
		setNewImageCount((prev) => prev + 1);
		setLoading(true);
		const width = 300;
		const height = width / (16 / 9);

		let video = videoRef.current;

		const newPhotos = [...photos];
		const photo = document.createElement('canvas');
		photo.width = width;
		photo.height = height;

		let ctx = photo.getContext('2d');
		ctx.drawImage(video, 0, 0, photo.width, photo.height);

		newPhotos.push({ key: null, dataUrl: photo.toDataURL('image/png') });
		setPhotos(newPhotos);
		setLoading(false);
	};

	const deletePhoto = (index) => {
		const newPhotos = [...photos];
		newPhotos.splice(index, 1);
		setNewImageCount((prev) => prev - 1);
		setPhotos(newPhotos);
	};

	const handleSave = async () => {
		try {
			setLoading(true);
			await saveAssetPhoto({
				asset_id,
				photos: photos.filter((item) => item.key === null),
			});
			enqueueSnackbar(`Successfully uploaded`, {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar(error?.message ?? '', {
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	//TODO remove if not in use
	// const getAssetPhotos = async () => {
	// 	if (!asset_uid) return;

	// 	const res = await getAssetPhoto({ assetId: asset_uid });

	// 	setPhotos(res.images);
	// };

	useEffect(() => {
		if (!fileUploadToggle) getVideo();
	}, [videoRef, fileUploadToggle]);

	useEffect(() => {
		//TODO remove if not in use
		//getAssetPhotos();
	}, []);

	return (
		<Dialog sx={styles} className='dialog' open={true} fullWidth>
			<Box className='scanner'>
				<ButtonBase
					onClick={() => closeVideo()}
					className='modalButton dialogClose'
				>
					<CloseIcon />
				</ButtonBase>

				<ButtonBase
					onClick={() => setFileUploadToggle((prev) => !prev)}
					className='modalButton toggleFacingMode'
				>
					{!fileUploadToggle ? <UploadFileIcon /> : <CameraAltIcon />}
				</ButtonBase>

				{!fileUploadToggle && (
					<ButtonBase onClick={takePhoto} className='modalButton capture'>
						<CameraIcon />
					</ButtonBase>
				)}
				<Body1
					className={`photo-limit-warning ${photos.length >= 5 ? 'show' : null}`}
				>
					Can not take more than 5 photos!
				</Body1>

				<section style={styles.containerStyle}>
					<div style={{ ...styles.container, ...styles.videoContainerStyle }}>
						{!fileUploadToggle && (
							<>
								<video
									muted
									playsInline
									autoPlay
									style={{ ...styles.video, ...styles.videoStyle }}
									ref={videoRef}
								/>
								<canvas
									style={{ ...styles.video, ...styles.videoStyle }}
									ref={canvasRef}
								/>
							</>
						)}
						{fileUploadToggle && (
							<Box style={{ ...styles.video, ...styles.videoStyle }}>
								<Box style={{ margin: '100px' }}>
									<ImageDropZone setData={setData} />
								</Box>
							</Box>
						)}
					</div>
				</section>
			</Box>
			<Box
				className={`photos-container ${photos.length > 0 ? 'has-photo' : null}`}
			>
				<Box className='photos'>
					{photos.map((photo, index) => (
						<Box className='photo-item' key={index}>
							<img
								key={index}
								src={photo.dataUrl}
								alt={`Photo ${index + 1}`}
								className='photo-style'
							/>
							{!photo.key && (
								<div className='delete'>
									<ButtonBase onClick={() => deletePhoto(index)}>
										<CloseIcon />
									</ButtonBase>
								</div>
							)}
						</Box>
					))}
				</Box>
				{newImageCount > 0 && (
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							minHeight: '50px',
							minWidth: '100%',
						}}
					>
						{!loading && (
							<FilledButton handleClick={handleSave} size='small'>
								Save
							</FilledButton>
						)}
						{loading && (
							// <Box>
							// 	<CircularProgress
							// 	size='10%'
							// 	thickness={3.5}
							// 	sx={{ opacity: '1' }}
							// />
							// </Box>
							<FilledButton color='secondary' size='small'>
								Saving...
							</FilledButton>
						)}
					</Box>
				)}
			</Box>
		</Dialog>
	);
}

function CameraFormFieldComponent({
	title = 'Take picture',
	asset_id,
	onRefetch,
}) {
	const [open, toggle] = useToggleState(false);
	const { handleChange } = useContext(FormikContext);

	const handleChangeRec = useCallback(
		(value) => {
			if (value) {
				onRefetch();
			}
			toggle();
		},
		[handleChange, toggle],
	);

	return (
		<>
			<Tooltip title={title}>
				<Box sx={{ cursor: 'pointer' }}>
					<TitledButton
						handleClick={toggle}
						label='Photo'
					>
						<AddAPhoto height={24} width={24} />
					</TitledButton>
				</Box>
			</Tooltip>
			{open && <CameraModal onClose={handleChangeRec} asset_id={asset_id} />}
		</>
	);
}

export default CameraFormFieldComponent;
