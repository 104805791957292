/**
 * Convert from Asset to ThingStorageItem
 * @param asset
 */
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';

const toThingStorageItem = (asset, thing_type, unit) => {
  // Inbound asset details
  console.log(' --> Thing Storage Item', thing_type, unit);

  const {
    _id,
    type,
    name,
    location,
    processed = ASSET_PROCESSED_STATUS.PENDING,
    status,
  } = asset;

  const itemUnit = ![
    THING_TYPES.SERVICES,
    THING_TYPES.FINISHED,
    THING_TYPES.TRACKED,
    THING_TYPES.CONSUMABLE,
  ].includes(thing_type)
    ? unit
    : null;

  // Return the ThingItem
  return {
    asset: _id,
    type,
    name,
    location,
    processed,
    amount: 0,
    unit: itemUnit,
    status,
  };
};

export default toThingStorageItem;
