import { CheckBoxRounded } from '@mui/icons-material';
import { Avatar, AvatarGroup } from '@mui/material';
import Box from '@mui/material/Box';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import IdIcon from '@pw/components/icons/Id';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import { TASK_EDIT_URL_LOOKUP } from '@pw/consts/tasks';
import { mr1 } from '@pw/styles/margins';
import getInitialsFromString from '@pw/utilities/getInitialsFromString';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles';

function AssigneeUsers({ assignees = [] }) {
  return (
    <AvatarGroup max={4} className='assigneeList'>
      {assignees?.map(({ id, name }) => (
        <Avatar key={id} alt={name} className='avatar'>
          {name ? getInitialsFromString(name) : null}
        </Avatar>
      ))}
    </AvatarGroup>
  );
}

function AssetSummary({ label, items }) {
  if (!items || !items.count) return null;

  const { bl, abv } = items;

  return <LiquidDisplay label={label} abv={abv} value={bl} />;
}

function ParentAsset({ parent_asset_id }) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        display: 'flex',
        gap: '0.25rem',
      }}
    >
      <IdIcon style={{ width: '12px', height: 'auto' }} />
      <Body3>
        <Bolder>{'Parent Asset: '}</Bolder>
        &nbsp;
        {parent_asset_id}
      </Body3>
    </Box>
  );
}

function TaskCard({ task, selected = false, onSelect }) {
  const navigate = useNavigate();

  const {
    _id,
    name,
    type,
    batch,
    date,
    dueDate,
    action,
    assignees,
    path,
    // client,
    sources,
    destinations,
    parent_asset_id,
    // shipAddress,
  } = task;
  // console.log('Task', id, sources, destinations);

  const onClick = useCallback(
    (e) => {
      if (e.detail === 2) {
        if (!batch) {
          navigate(`${TASK_EDIT_URL_LOOKUP[type]}/${_id}`);
        }
        e.stopPropagation();
      }
      if (e.detail === 1) {
        onSelect(!selected);
      }
    },
    [selected, batch, type, navigate, _id],
  );

  return !_id ? null : (
    <Box
      sx={styles}
      className='root'
      action={action}
      id={path}
      onClick={onClick}
    >
      <Box className='tab'>
        {selected && <CheckBoxRounded className='check' sx={{ ...mr1 }} />}
      </Box>
      <Box className='task-card-content'>
        <Box className='header'>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <H5 className='action' sx={{ flexGrow: 1 }}>
              {name}
            </H5>
          </Box>
          <Body3 className='date'>{dueDate ?? date}</Body3>
        </Box>
        <Box className='body'>
          <SourceDisplay type={type} name={action} />

          {sources && <AssetSummary label='Source' items={sources} />}
          {destinations && (
            <AssetSummary label='Destination' items={destinations} />
          )}
          {parent_asset_id && <ParentAsset parent_asset_id={parent_asset_id} />}
        </Box>
        <Box className='footer'>
          <AssigneeUsers assignees={assignees} />
        </Box>
      </Box>
    </Box>
  );
}

export default TaskCard;
