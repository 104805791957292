import { Body2 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function EntryLabel({ label, value }) {
  if (!value) return;
  return (
    <Body2 sx={{ flexGrow: 1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipses' }} title={value}>
      {label && <Bolder>{`${label}: `}</Bolder>}
      {value}
    </Body2>
  );
}

export default EntryLabel;
