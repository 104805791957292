import { getRequestAssets } from '@pw/services/request.service';
import pathToParams from '@pw/utilities/pathToParams';
import { genServiceCallHooks } from '@pw/utilities/hooks/service/genHooks';

const serviceFunction = (id) => getRequestAssets({ id });
export const [useRequestAssetsLazyQuery, useRequestAssetsQuery] = genServiceCallHooks(
	serviceFunction
);

export default useRequestAssetsQuery;
