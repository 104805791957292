import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import RestockAssetModal from '@pw/components/SKUSelector/modals/RestockAssetModal';
import RestockSKU from '@pw/components/ThingSelector/modals/RestockThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function RestockForm(props) {
  const onThingAdded = useCallback(
    (thing) => {
      // TODO: Load policies
    },
    [],
  );

  const [
    [sourceThings],
    [sourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Source Inventory',
    assetFilter: () => true,
    filter: {
      thing_types: [
        THING_TYPES.RAW,
        THING_TYPES.EXPIRING,
        THING_TYPES.CONSUMABLE,
        THING_TYPES.FINISHED,
      ],
      asset_types: [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
      ],
      request_types: [REQUEST_TYPES.DELIVERY, REQUEST_TYPES.TRANSFER],
    },
    ThingModal: SourceThingModal,
    AssetModal: RestockAssetModal,
  });

  const [
    [destinationThings, , , upsertDestinationThings],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useSourceInventoryHook({
    title: 'Restocked Inventory & Services',
    filter: {
      thing_types: [
        THING_TYPES.RAW,
        THING_TYPES.EXPIRING,
        THING_TYPES.CONSUMABLE,
        THING_TYPES.FINISHED,
      ],
    },
    ThingModal: RestockSKU,
    onThingAdded: onThingAdded,
  });

  useEffect(() => {
    const things = sourceThings.filter(
      (s) => !destinationThings.some((ds) => ds._id === s._id),
    );
    upsertDestinationThings([
      ...things.map((s) => ({
        ...s,
        entries: s.entries.map((e) => ({ ...e, storage: [] })),
      })),
    ]);
  }, [sourceThings]);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      policies: [initialValues?.policies, yup.array()],
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        assets: entity?.destinations?.assets,
        things: entity?.destinations?.things,
      });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        things: sourceThings,
        assets: sourceAssets,
      },
      destinations: {
        things: destinationThings,
        assets: destinationAssets,
      },
    }),
    [
      sourceThings,
      sourceAssets,
      destinationAssets,
      destinationThings,
    ],
  );

  return (
    <>
      <RequestForm
        requestLabel='Restock'
        requestType={REQUEST_TYPES.RESTOCK}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />
        <DestinationInventory />

        <PolicySelector />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(RestockForm, { title: 'Restock' });
