function SvgMultipleDocuments(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path d='M5.5 13.5h56v29.16c-28-11.917-28 12.255-56-2.113z' fill='#cef' />
      <path d='M3.5 15.5h56v29.16c-28-11.917-28 12.255-56-2.113z' fill='#cef' />
      <path d='M1.5 17.5h56v29.16c-28-11.917-28 12.255-56-2.113z' fill='#cef' />
    </svg>
  );
}
export default SvgMultipleDocuments;
