import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import Errors from '@pw/components/Forms/FormErrors';
import {
  FormikCheckBox,
  FormikForm,
  FormikNumberField,
  FormikSelect,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import { Body2, Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS, THING_TYPES } from '@pw/consts/thing';
import { useCompanyThings } from '@pw/redux/containers/User';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import toThingStorageItem from '@pw/utilities/adapters/toThingStorageItem';
import calculateThingStorageLiquid from '@pw/utilities/calculateThingStorageLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useCallback, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function StorageSetupModalWrapper({ total, allocated, ...props }) {
  const left = (total ?? 0) - (allocated ?? 0);
  console.log('Amount', total, allocated, left);
  return <StorageSetupModal left={left} {...props} />;
}

function ThingStorageItem({ item }) {
  const { amount } = item;
  console.log('item', item);

  return (
    <Box className='card'>
      <Box className='card-tab' />
      <Box className='card-content'>
        <Body3>
          <Bolder>Items:</Bolder>
          &nbsp;
          {amount}
        </Body3>
      </Box>
    </Box>
  );
}

function GeneralDestinationThing({
  item,
  onClose,
  calculateThingLiquid = false,
}) {
  const companyThings = useCompanyThings();
  const converter = useConverter();
  const { entries = [{ amount: 0 }], type, properties } = item ?? {};

  console.log('GeneralDestinationThing', item);

  //   const thingItem = companyThings.find((s) => s._id === _id);
  const { unit, cased } = properties ?? {};
  const { releases = [] } = cased ?? {};

  // TODO: Need to support multiple entries
  const entry = entries[0] ?? { amount: 0 };
  const { amount, release, duty_paid, lot_code, storage = [] } = entry;

  const releaseOptions = useMemo(
    () => (releases ?? []).map((v) => ({ label: v?.name, value: v })),
    [releases],
  );

  const finished = useMemo(() => type === THING_TYPES.FINISHED, [type]);

  const [ProgressBar, { setProgress, completion, progressTotal }] =
    useProgress();

  const storageProps = useMemo(
    () => ({
      title: 'Storage Allocation',
      filter: {
        asset_types: [ASSET_TYPES.PALLET, ASSET_TYPES.CONTAINER],
      },
      disableEvents: true,
      assetFilter: (i) => i.status !== ASSET_STATUS.DEFECTED,
      assetAdapter: (i) =>
        i.status !== ASSET_STATUS.DEFECTED
          ? toThingStorageItem(i, type, unit)
          : toTaggedAsset(i),
      assetConverter: (i, s) => ({
        ...i,
        processed: !!s ? ASSET_PROCESSED_STATUS.CONFIRMED : i.processed,
      }),
      initialAssets: [...storage],
      displayAsset: (item) => (
        <ThingStorageItem item={item} key={item.asset_id} />
      ),
      AssetModal: (props) => (
        <StorageSetupModalWrapper
          {...props}
          total={progressTotal}
          allocated={completion}
        />
      ),
      allowAssetSelector: false,
    }),
    [storage, type, unit, progressTotal, completion],
  );

  const [, [storageAssets], , StorageInventory, StorageModals] =
    useInventorySelectorHook(storageProps);

  const changeSet = useMemo(
    () => ({
      amount: [
        amount,
        yup.number().positive('Must be positive!').required('Amount required!'),
      ],
      lot_code: [
        lot_code ?? '',
        yup.string().required('Lot code is required!'),
      ],
      ...(finished
        ? {
            release: [
              release?.name ?? '',
              yup.object().shape({
                id: yup.string(),
                name: yup.string(),
                liquid_type: yup.string(),
              }),
            ],
            duty_paid: [duty_paid, yup.boolean()],
          }
        : {}),
    }),
    [amount],
  );

  const handleSubmit = useCallback(
    (values) => {
      console.log('Values', values);

      // Grab the client information from the Thing, and set that in the storage entry
      // so that we know who owns this stuff..
      //   let client = undefined;
      //   if (thingItem.client?.enable) {
      //     client = {
      //       id: thingItem.client?.id,
      //       name: thingItem.client?.name,
      //       type: thingItem.client?.type,
      //     };
      //   }

      //   console.log('Client', client);

      const storages = storageAssets.map((st) => {
        const liquid_amount =
          finished && calculateThingLiquid
            ? calculateThingStorageLiquid(
                converter,
                st.amount,
                item,
                companyThings,
              )
            : {};
        return {
          ...st,
          amount: unit ? converter.cx(st.amount, unit) : st.amount,
          liquid_amount,
          lot_code: values.lot_code,
          release: values.release,
          client: item?.client,
          duty_paid: values.duty_paid,
          selected: undefined,
        };
      });

      const selectedAmount = storageAssets.reduce(
        (v, i) => v + Number(i.amount),
        0,
      );
      console.log('Selected amount', selectedAmount);
      const updatedEntry = {
        ...entry,
        client: item?.client,
        amount: unit ? converter.cx(values.amount, unit) : values.amount,
        release: values.release,
        duty_paid: values.duty_paid,
        lot_code: values.lot_code,
        storage: storages,
      };

      const pending =
        storageAssets.length === 0 ||
        storageAssets.some(
          (a) => a.processed !== ASSET_PROCESSED_STATUS.CONFIRMED,
        );
      console.log('Pending', pending);

      const thing = {
        ...item,
        amount: unit ? converter.cx(values.amount, unit) : values.amount, // convert the amount back
        allocated_amount: unit
          ? converter.cx(selectedAmount, unit)
          : values.amount,

        ...(finished
          ? {
              liquid_amount: calculateThingLiquid
                ? calculateThingStorageLiquid(
                    converter,
                    values.amount,
                    item,
                    companyThings,
                  )
                : {},
              allocated_liquid_amount: calculateThingLiquid
                ? calculateThingStorageLiquid(
                    converter,
                    selectedAmount,
                    item,
                    companyThings,
                  )
                : {},
            }
          : {}),

        processed: pending
          ? INVENTORY_STATUS.PENDING
          : INVENTORY_STATUS.CONFIRMED,
        entries: [updatedEntry],
      };

      console.log('thing', thing);

      onClose(thing);
    },
    [item, entry, storageAssets, calculateThingLiquid, companyThings],
  );

  useEffect(() => {
    const allocated = storageAssets.reduce((v, i) => v + Number(i.amount), 0);
    setProgress(allocated);
  }, [storageAssets]);

  return (
    <>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='2rem'>
          {type === THING_TYPES.FINISHED && (
            <>
              <FormikSelect
                label='Release'
                name='release'
                options={releaseOptions}
                fullWidth
              />
              <FormikCheckBox
                name='duty_paid'
                label={<Body2>Duty Paid</Body2>}
              />
            </>
          )}
          <FormikNumberField label='Amount' name='amount' fullWidth />
          <FormikTextField label='Lot Code' name='lot_code' fullWidth />

          <Instructions>
            Please select the storage for the output items.
          </Instructions>

          <ProgressBar name='amount' label='Amount' />

          <StorageInventory />

          <Errors />

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
              label='Cancel'
            />
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>

      <StorageModals />
    </>
  );
}

export default GeneralDestinationThing;
