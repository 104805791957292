function SvgLabel(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 15 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M11.678 20.79c-.328.334-.633.645-.94.952-.317.316-.581.314-.899-.002-.31-.307-.614-.616-.944-.946l-.771.775c-.077.076-.153.157-.233.231-.258.24-.536.241-.79-.008-.326-.32-.643-.65-.988-.998-.162.16-.315.311-.466.464-.175.175-.346.352-.523.523-.275.264-.55.266-.82 0-.327-.32-.644-.645-.984-.987l-.918.921c-.357.357-.602.355-.96-.004-.407-.406-.811-.814-1.22-1.216A.694.694 0 010 19.969C.002 13.517 0 7.064.002.612.002.2.204 0 .62 0H14.37c.431 0 .627.194.627.625 0 6.445 0 12.888.002 19.334 0 .21-.062.38-.21.526-.43.422-.852.853-1.28 1.278-.298.293-.566.288-.869-.013-.313-.31-.624-.624-.96-.96h-.003zm1.409-.066c.257-.264.5-.495.72-.747a.56.56 0 00.137-.336c.006-6.117.006-12.237.006-18.354v-.23H1.047v14.107c0 1.498 0 2.995.002 4.492 0 .08.013.185.062.239.256.276.526.538.802.814.066-.062.12-.109.172-.159.278-.276.552-.558.833-.83.255-.248.531-.25.79-.009.148.14.29.285.432.431.188.19.371.382.565.58l.92-.922c.359-.359.604-.359.96 0 .302.301.603.604.895.901.04-.02.054-.025.064-.033l.88-.876c.347-.345.597-.345.947.006.301.301.602.606.92.926.323-.326.629-.635.936-.94.328-.328.587-.33.915-.005.308.308.613.617.945.95v-.005z'
        fill='#404040'
      />
      <path
        d='M5.062 9.767H3.734c-.386-.002-.592-.208-.592-.592-.002-.884-.002-1.77 0-2.655 0-.384.209-.586.598-.588h2.635c.4 0 .602.202.602.602 0 .878.002 1.757 0 2.635 0 .398-.206.596-.608.598H5.062zm-.86-2.781v1.717H5.92V6.986H4.202zM11.86 7.87c0 .435.002.87 0 1.307-.002.384-.208.588-.596.59-.885.002-1.771.002-2.656 0-.37 0-.581-.21-.583-.58-.002-.892-.002-1.785 0-2.678 0-.369.21-.577.583-.577.893-.002 1.786-.002 2.679 0 .36 0 .569.21.57.567.005.458 0 .915 0 1.371h.003zm-1.062.841V6.994H9.08V8.71h1.718zM6.977 12.726v1.328c-.002.387-.206.593-.59.593-.884.002-1.77.002-2.655 0-.384 0-.588-.208-.59-.595-.002-.885-.002-1.772 0-2.656 0-.373.209-.582.58-.584.892-.002 1.785-.002 2.678 0 .371 0 .575.21.577.584.004.443 0 .884 0 1.328v.002zm-1.055-.856H4.2v1.718h1.722V11.87zM10.814 13.256h-.802c-.356-.002-.593-.217-.591-.528.002-.31.239-.516.6-.52h.793c0-.288-.004-.569 0-.847.007-.285.184-.5.442-.54a.518.518 0 01.6.511c.008.936.008 1.874 0 2.81a.513.513 0 01-.52.51.519.519 0 01-.52-.51c-.01-.282-.002-.565-.002-.884v-.002zM8.542 10.823a.504.504 0 01.528.513c0 .28-.245.528-.526.526a.532.532 0 01-.515-.514.5.5 0 01.513-.525zM9.072 14.136a.512.512 0 01-.54.505.506.506 0 01-.501-.521.51.51 0 01.515-.51.521.521 0 01.526.526z'
        fill='#404040'
      />
    </svg>
  );
}
export default SvgLabel;
