import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { unitField } from '@pw/components/admin/Things/UnitField';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import DependentThingModal from '@pw/components/ThingSelector/modals/DependentThingModal';
import { H5, Overline } from '@pw/components/Typography';
import { ASSET_TYPES, MACHINE_TYPES_OPTIONS } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import {
  LIQUID_UNIT_OPTIONS,
  MASS_UNIT_OPTIONS,
  TIME_UNIT_OPTIONS,
  UNITS_OPTIONS,
} from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import debounce from '@pw/utilities/debounce';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
//
// function ReferenceField({ entity }) {
//   const { values, setFieldValue } = useContext(FormikContext);
//   const [historyExist, isSame, history] = useAssetRevertHook(
//     entity,
//     (e, e2) => e?.properties.reference === e2?.properties.reference,
//   );
//   const onRevert = useCallback(() => {
//     setFieldValue('reference', history?.properties.reference ?? '');
//   }, [history, setFieldValue]);
//
//   const onUndo = useCallback(() => {
//     setFieldValue('reference', entity?.properties.reference ?? '');
//   }, [history, setFieldValue]);
//
//   const isReverted = useMemo(() => values['reference'] === history?.properties?.reference, [history, values]);
//
//   return (
//     <Box sx={{ position: 'relative' }}>
//       {historyExist && !isSame && (
//         <RevertMessage
//           title="Reference"
//           onRevert={onRevert}
//           isReverted={isReverted}
//           onUndo={onUndo}
//           item={history?.properties?.reference}
//           type="text"
//         />
//       )}
//       <FormikTextField name="reference" label="Reference" fullWidth />
//     </Box>
//   );
// }

function capabilitiesFields(fields = {}) {
  const { rate, interval, min, max, capacity, trigger } = fields;

  return {
    rate: unitField(rate),
    interval: unitField(interval),
    min: unitField(min),
    max: unitField(max),
    capacity: unitField(capacity),
    trigger: unitField(trigger),
  };
}

function propertyFields(fields = {}) {
  const { type, capabilities = {}, parts = [] } = fields;

  return {
    type: [type ?? '', yup.string().required('Machine type is required!')],
    capabilities: capabilitiesFields(capabilities),
    parts: [parts, yup.array().of(yup.object())],
  };
}

function Capabilities() {
  return (
    <FormikProvider path='capabilities'>
      <FlexBox>
        <FormikUnitField
          name='rate'
          label='Rate'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
        <FormikUnitField
          name='interval'
          label='Interval'
          options={TIME_UNIT_OPTIONS}
        />
      </FlexBox>

      <FlexBox>
        <FormikUnitField name='min' label='Min' options={UNITS_OPTIONS} />
        <FormikUnitField name='max' label='Max' options={UNITS_OPTIONS} />
      </FlexBox>

      <FlexBox>
        <FormikUnitField
          name='capacity'
          label='Capacity'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
        <FormikUnitField
          name='trigger'
          label='Trigger'
          options={[...LIQUID_UNIT_OPTIONS, ...MASS_UNIT_OPTIONS]}
        />
      </FlexBox>
    </FormikProvider>
  );
}

function Parts() {
  const { setFieldValue, values } = useContext(FormikContext);

  const dependentSkuProps = useMemo(
    () => ({
      title: 'Parts',
      filter: {
        thing_types: [THING_TYPES.PARTS],
      },
      initialThings: values?.parts,
      ThingModal: DependentThingModal,
    }),
    [values?.parts],
  );

  const [[dependentThing], , , DependentInventory, DependentModals] =
    useInventorySelectorHook(dependentSkuProps);

  useEffect(() => {
    console.log('Updating Things', dependentThing);
    debounce(() => setFieldValue('parts', dependentThing), 25);
  }, [dependentThing]);

  return (
    <Stack spacing={3}>
      <DependentInventory />
      <DependentModals />
    </Stack>
  );
}

function Properties({ entity }) {
  const { t } = useTranslation();

  const options = MACHINE_TYPES_OPTIONS.map((v) => ({
    label: t(v.label),
    value: v.value?.join(','),
  }));

  return (
    <FormikProvider path='properties'>
      <Stack spacing={2}>
        <Divider><Overline>Properties</Overline></Divider>
        <FormikSelect name='type' label='Type' options={options} />
        <Capabilities />
      </Stack>
      <Parts />
    </FormikProvider>
  );
}

function MachinePage(props) {
  const converter = useConverter();

  const [entity, setEntity] = useState(null);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...assetIdFields(initialValues),
      location: locationFields(initialValues.location),
      properties: propertyFields(initialValues.properties, converter),
    }),
    [converter],
  );

  const handleInit = useCallback(
    (entity) => {
      setEntity(entity);
    },
    [setEntity],
  );

  const handleBeforeSave = (entity) => entity;

  return (
    <AssetForm
      assetLabel='Machine'
      assetType={ASSET_TYPES.MACHINE}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      onInit={handleInit}
      {...props}
    >
      <Location name='location' entity={entity} disabled />

      <Properties entity={entity} />
    </AssetForm>
  );
}

export default withAppLayout(MachinePage, { title: 'Machine Management' });
