function SvgStoredData(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      fontFamily='Roboto'
      fontSize={14}
      textAnchor='middle'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <path
        d='M16.7 46.5c-8 0-15.2-6.429-15.2-15s7.2-15 15.2-15h44.8q-14 15 0 30z'
        fill='#dcf'
        stroke='#838383'
      />
    </svg>
  );
}
export default SvgStoredData;
