import { Box, Typography } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { Id, Liquid, Location } from '@pw/components/icons';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '16px',
    borderBottom: '1px solid #E0E0E0',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9.71px',
    height: '9.61px',
    marginRight: '8px',
  },
  text: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '19px',
    color: '#5D5D5D',
  },
  textBold: {
    fontWeight: '600',
    fontSize: 'inherit',
    color: 'inherit',
  },
  arrowIcon: {
    fontSize: '16px',
    color: '#52535B',
  },
};

function LayoutRecentAssetsWidget({ data }) {
  return (
    <Box sx={styles.container}>
      {/* left side */}
      <Box>
        {/* id */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={styles.iconContainer}>
            <Id height={24} width={24} />
          </Box>
          <Typography sx={styles.text}>{data.id}</Typography>
        </Box>

        {/* Location */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={styles.iconContainer}>
            <Location height={24} width={24} />
          </Box>
          <Typography sx={styles.text}>
            <Typography component="span" sx={styles.textBold}>
              Location:{' '}
            </Typography>
            {data.location}
          </Typography>
        </Box>

        {/* Bulk Liquid */}
        {data.bulkLiquid && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={styles.iconContainer}>
              <Liquid height={24} width={24} />
            </Box>
            <Typography sx={styles.text}>
              <Typography component="span" sx={styles.textBold}>
                Bulk liquid:{' '}
              </Typography>
              {data.bulkLiquid}
            </Typography>
          </Box>
        )}
      </Box>

      {/* icon */}
      <ArrowForward sx={styles.arrowIcon} />
    </Box>
  );
}

export default LayoutRecentAssetsWidget;
