import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { ABV_UNIT_OPTIONS, TEMPERATURE_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      target_time: unitField(initialValues?.target_time),
      target_abv: unitField(initialValues?.target_abv),
      target_temp: unitField(initialValues?.target_temp),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FormikUnitField name="target_time" label="Target Time" options={TIME_UNIT_OPTIONS} />
        <FlexBox>
          <FormikUnitField name="target_abv" label="Target ABV" options={ABV_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="target_temp" label="Target Temperature" options={TEMPERATURE_UNIT_OPTIONS} fullWidth />
        </FlexBox>
      </Stack>
    </ModalWrapper>
  );
}


export default NodeModal;
