function SvgCurve(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Curve_svg__clip0_99_32)' fill='#404040'>
        <path d='M5.355 0c2.884.03 5.279 2.12 5.314 5.553.03 2.977-2.436 5.42-5.362 5.406C2.39 10.945-.02 8.46 0 5.487.02 2.361 2.337-.014 5.355 0zm-.016 9.933c2.48 0 4.368-1.95 4.37-4.516C9.71 2.99 7.726.971 5.338.971 2.95.971.968 2.991.97 5.418.975 7.99 2.855 9.932 5.34 9.933z' />
        <path d='M19.997 1.834c-.343.074-.618.14-.895.192-.36.068-.605-.077-.656-.372-.053-.309.111-.529.465-.608.65-.146 1.304-.288 1.956-.427.385-.083.604.055.69.45.15.69.292 1.381.428 2.074.064.32-.08.569-.342.633-.28.07-.515-.092-.596-.425-.073-.3-.131-.602-.207-.95-.075.09-.13.143-.171.205-4.085 5.988-9.583 10.044-16.223 12.527-.844.316-1.712.563-2.571.83-.356.111-.62-.028-.696-.337-.071-.287.092-.516.45-.618 4.445-1.27 8.502-3.334 12.124-6.282a30.59 30.59 0 006.024-6.551c.064-.093.122-.189.22-.34v-.001zM4.858 2.377c0-.104-.01-.188.002-.269.04-.273.202-.434.468-.442.267-.007.475.156.477.42.001.268.137.337.324.435.607.318.937.836.975 1.539.017.322-.167.542-.44.563-.27.022-.477-.17-.525-.49-.079-.52-.36-.81-.79-.816a.8.8 0 00-.803.733c-.039.46.252.844.692.907.13.018.263.012.392.037.797.156 1.374.796 1.461 1.615A1.839 1.839 0 016.02 8.462c-.055.026-.111.05-.166.074-.077.561-.203.74-.52.736-.313-.004-.447-.195-.5-.73-.612-.228-1.045-.645-1.21-1.314a1.7 1.7 0 01-.046-.462c.01-.266.168-.424.418-.453.25-.03.43.09.51.342.023.068.03.142.041.213.07.477.412.773.856.737a.808.808 0 00.727-.815c0-.446-.332-.756-.786-.834-.35-.06-.731-.14-1.017-.336-1.128-.774-.97-2.525.257-3.122.082-.04.166-.074.275-.121z' />
      </g>
      <defs>
        <clipPath id='Curve_svg__clip0_99_32'>
          <path fill='#fff' d='M0 0h22v16H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgCurve;
