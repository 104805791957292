import { FINISHED_GOODS_TYPES, THING_TYPES } from '@pw/consts/thing';
import { UNITS } from '@pw/consts/units';

// Calculate liquid volume and alcohol content for a single Thing
const calculateLiquidForThing = (converter, amount = 0, thing) => {
  const { size, abv } = thing.properties ?? {};
  if (!size) return {};

  const totalVolume = Number(amount) * Number(size.value);
  const volumeInLiters = converter.cx(totalVolume, size.unit, UNITS.liters);
  const alcoholPercentage =
    converter.cx(abv?.value ?? 0, abv?.unit, UNITS.abv) / 100;

  return {
    bl: Number(volumeInLiters).toFixed(3),
    la: (Number(volumeInLiters) * alcoholPercentage).toFixed(3),
  };
};

// Calculate liquid volume and alcohol content for a pack of Things
export const calculatePackLiquid = (
  converter,
  amount,
  packThings,
  thingList,
) => {
  return packThings.reduce(
    (acc, { amount: s_amount, _id }) => {
      const packItemThing = thingList.find((s) => s?._id === _id);
      if (packItemThing) {
        const totalAmount = Number(amount ?? 1) * Number(s_amount);
        const { bl, la } = calculateLiquidForThing(
          converter,
          totalAmount,
          packItemThing,
        );
        acc.bl += Number(bl);
        acc.la += Number(la);
      }
      return acc;
    },
    { bl: 0, la: 0 },
  );
};

// Main function to calculate liquid volume and alcohol content for Thing storage
const calculateThingStorageLiquid = (converter, amount, thing, thingList) => {
  // Only calculate for finished goods
  if (thing?.type !== THING_TYPES.FINISHED) {
    return undefined;
  }

  let bl = 0,
    la = 0;

  // Calculate based on Thing type (bottle or pack)
  if (thing?.properties?.type === FINISHED_GOODS_TYPES.BOTTLE) {
    ({ bl, la } = calculateLiquidForThing(converter, amount, thing));
  } else if (thing.properties?.type === FINISHED_GOODS_TYPES.PACK) {
    const packThings = thing.properties?.things ?? [];
    ({ bl, la } = calculatePackLiquid(
      converter,
      amount,
      packThings,
      thingList,
    ));
  }

  // Calculate ABV (Alcohol By Volume)
  const abv = la && bl && bl > 0 ? (la / bl) * 100 : undefined;
  return { bl: bl ?? 0, la: la ?? 0, abv: abv ?? 0 };
};

export default calculateThingStorageLiquid;
