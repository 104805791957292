import { clientFields } from '@pw/components/Client/clientFields';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import debounce from '@pw/utilities/debounce';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function PackForm() {
  const sourceProps = useMemo(
    () => ({
      title: 'Original Goods',
      filter: {
        request_types: [REQUEST_TYPES.PICK],
        thing_types: [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED],
      },
      assetFilter: (a) => false,
      // submitForm,
      ThingModal: SourceThingModal,
    }),
    [],
  );

  const [
    [sourceThings, , , upsertSourceThings, removeSourceThings],
    [],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useInventorySelectorHook(sourceProps);

  const onDestinationThingAdded = useCallback(
    (thing) => {
      console.log(
        'Destination Thing added',
        thing?.properties?.things?.length,
        thing?.properties?.releases?.length,
        thing?.type,
        thing,
      );
      if (
        thing?.type === THING_TYPES.FINISHED &&
        (thing?.properties?.things?.length > 0 ||
          thing?.properties?.releases?.length > 0)
      ) {
        const amount = thing?.entries?.reduce(
          (acc, curr) => acc + Number(curr.amount),
          0,
        );
        console.log('Amount', amount);

        // which things to use
        let things = [];

        // Find the release
        const release_ids = thing?.entries
          ?.map((e) => e.release)
          .filter((s) => s);
        console.log('Releases IDs', release_ids);

        // Find the release
        const release_list = thing?.properties?.releases?.filter((r) =>
          release_ids.includes(r.id),
        );

        console.log('Releases', release_list);

        if (release_list && release_list.length > 0) {
          // See if there is a things list in the releases, if so, add all of them in there..
          release_list
            .map((r) => r.things)
            .flat()
            .filter((s) =>
              [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED].includes(s.type),
            )
            .forEach((s) => {
              const existing = things.find((k) => k._id === s._id);
              if (!existing) {
                console.log(' --> adding thing', s);
                things.push({
                  ...s,
                  amount: Number(amount) * Number(s?.amount),
                });
              }
            });
        }

        if (things.length === 0) {
          things = thing?.properties?.things
            .filter((s) =>
              [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED].includes(s.type),
            )
            .map((s) => ({
              ...s,
              amount: Number(amount) * Number(s?.amount),
            }));
        }

        console.log('things', things);

        debounce(() => {
          upsertSourceThings(things);
        }, 25);
      }

      // TODO: Load policies
      // loadPolicies(thing?.policies);
    },
    [upsertSourceThings],
  );

  const onDestinationThingRemoved = useCallback(
    (thing) => {
      console.log('Destination thing removed', thing);
      // if (
      // 	sku?.sku_type === SKU_TYPES.FINISHED &&
      // 	sku?.properties?.skus?.length > 0
      // ) {
      // 	removeSourceSkus(sku?.properties?.skus);
      // }
    },
    [removeSourceThings],
  );

  const destProps = useMemo(
    () => ({
      title: 'Packed Goods & Services',
      filter: {
        thing_types: [THING_TYPES.FINISHED, THING_TYPES.SERVICES],
      },
      assetFilter: (a) => false,
      // submitForm,
      thingModalProps: { calculateThingLiquid: true },
      ThingModal: DestinationThingModal,
      onThingAdded: onDestinationThingAdded,
      onThingRemove: onDestinationThingRemoved,
    }),
    [onDestinationThingAdded, onDestinationThingRemoved],
  );

  const [
    [destinationThings],
    [],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook(destProps);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      policies: [initialValues?.policies, yup.array()],
      client: clientFields(initialValues?.client),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        things: entity?.destinations?.things,
      });
    },
    [initSources, initDestinations],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      return {
        ...entity,
        sources: {
          things: sourceThings,
        },
        destinations: {
          things: destinationThings,
        },
      };
    },
    [sourceThings, destinationThings],
  );

  return (
    <>
      <RequestForm
        requestLabel='Pack'
        requestType={REQUEST_TYPES.PACK}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
      >
        <SourceInventory />
        <DestinationInventory />

        <PolicySelector />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(PackForm, { title: 'Pack' });
