import { clientFields } from '@pw/components/Client/clientFields';
import { FormikTextField } from '@pw/components/Forms/FormikForm';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import PurchaseThingModal from '@pw/components/ThingSelector/modals/PurchaseThingModal';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyThings } from '@pw/redux/containers/User/hooks';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

function Properties() {
  const { readonly } = useContext(FormikContext);
  return (
    !readonly && (
      <FormikProvider path='properties'>
        <FormikTextField name='invoice_id' label='Invoice Id' />
      </FormikProvider>
    )
  );
}

function propertyFields(fields = {}) {
  const { invoice_id = '' } = fields;

  return {
    invoice_id: [invoice_id, yup.string().required('Please enter invoice id')],
  };
}

function PurchaseOrderForm(props) {
  const location = useLocation();

  const [FormSubmitter, submitForm] = useFormSubmissionHook();

  const { thing_id } = location.state ?? {};

  const allThings = useCompanyThings();

  const filter = {
    thing_types: [
      THING_TYPES.TRACKED,
      THING_TYPES.SERVICES,
      THING_TYPES.RAW,
      THING_TYPES.EXPIRING,
      THING_TYPES.CONSUMABLE,
    ],
  };

  const [[things], , initThings, ThingInventory, ThingModals] =
    useInventorySelectorHook({
      title: 'Order',
      filter: filter,
      submitForm,
      ThingModal: PurchaseThingModal,
    });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      client: clientFields(initialValues?.client),
      properties: propertyFields(initialValues?.properties),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      const srcThings = entity?.destinations?.things ?? [];

      if (thing_id) {
        const exists = srcThings.find((s) => s._id === thing_id);
        if (!exists) {
          const selectedThing = allThings.find((s) => s._id === thing_id);
          if (selectedThing) {
            srcThings.push(selectedThing);
          }
        }
      }

      initThings({
        ...entity,
        things: srcThings,
      });
    },
    [thing_id, allThings, initThings],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => {
      return {
        ...entity,
        destinations: {
          things,
        },
      };
    },
    [things],
  );

  return (
    <>
      <RequestForm
        requestLabel='Purchase Order'
        requestType={REQUEST_TYPES.PURCHASE_ORDER}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <ThingInventory />
        <Properties />
        <FormSubmitter />
      </RequestForm>
      <ThingModals />
    </>
  );
}

export default withAppLayout(PurchaseOrderForm, { title: 'Purchase Order' });
