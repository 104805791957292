export const COMP = {
  object: (l, r) => l.name.localeCompare(r.name),
  asset: (l, r) => l?._id.localeCompare(r?._id),
  request: (l, r) => l.rw_request_id.localeCompare(r.rw_request_id),
  thing: (l, r) => l.name.localeCompare(r.name),
  name: (l, r) => l.name.localeCompare(r.name),
  target: (l, r) => l.target_id?.name?.localeCompare(r.target_id?.name),
  state: (l, r) => l.state.localeCompare(r.state),
  date: (l, r) => l.date.toString().localeCompare(r.date.toString()),
  thing_record_id: (l, r) => l.thing_record_id.localeCompare(r.thing_record_id),
  sensor: (l, r) => l.address.localeCompare(r.address),
  gateway: (l, r) => l._id.localeCompare(r._id),
  path: (l, r) => l.path.localeCompare(r.path),
  location: (l, r) => l.location_id.localeCompare(r.location_id),
  company_name: (l, r) => l.company_name.localeCompare(r.company_name),
  image: (l, r) =>
    l.key ? l.key.localeCompare(r.key) : l.dataUrl.localeCompare(r.dataUrl),
  id: (l, r) => l.id.localeCompare(r.id),
  thing_storage: (l, r) => l.name.localeCompare(r.name),
  policy: (l, r) => l._id?.localeCompare(r._id),
  specification: (l, r) => l.name.localeCompare(r.name),
  _id: (l, r) => l?._id?.localeCompare(r?._id),
};

export const EQUAL = {
  object: (l, r) => {
    if (!l || !r) return false;
    if (l._id && r._id) return l._id === r._id;
    if (l.name && r.name) return l.name === r.name;
    return l.type === r.type;
  },
  asset: (l, r) => {
    if (!l || !r) return false;
    if (l.asset_id && r.asset_id) return l.asset_id === r.asset_id;
    if (l._id && r._id) return l._id === r._id;
    return l.rw_asset_id === r.rw_asset_id;
  },
  request: (l, r) => {
    if (!l || !r) return false;
    if (l.request_id && r.request_id) return l.request_id === r.request_id;
    if (l._id && r._id) return l._id === r._id;
    return l.rw_request_id === r.rw_request_id;
  },
  sensor: (l, r) => {
    if (!l || !r) return false;
    if (l._id && r._id) return l._id === r._id;
    return l.thing_id === r.thing_id;
  },
  path: (l, r) => {
    if (!l || !r) return false;
    if (l.path && r.path) return l.path === r.path;
  },
  thing: (l, r) => {
    if (!l || !r) return false;
    if (l._id && r._id) return l._id === r._id;
    return l.name === r.name;
  },
  policy: (l, r) => {
    if (!l || !r) return false;
    if (l._id && r._id) return l._id === r._id;
    return l.name === r.name;
  },
  specification: (l, r) => {
    if (!l || !r) return false;
    return l.name === r.name;
  },
  thing_name: (l, r) => {
    if (!l || !r) return false;
    return l.name === r.name;
  },
  location: (l, r) => {
    if (!l || !r) return false;
    return l.location_id === r.location_id;
  },
  id: (l, r) => {
    if (!l || !r) return false;
    return l.id === r.id;
  },
  thing_storage: (l, r) => {
    if (!l || !r) return false;
    return l._id === r._id;
  },
};

export const ID = {
  object: (o) => o._id ?? o.name,
  asset: (a) => a._id ?? a.rw_asset_id,
  request: (r) => r._id ?? r.rw_request_id,
  thing: (s) => s._id ?? s.name,
  name: (s) => s.name,
  state: (s) => s.state,
  date: (s) =>
    s.date.toString() /** this is number so need to convert to string*/,
  sensor: (s) => s.address,
  gateway: (g) => g._id,
  thing_name: (s) => s.thing_name,
  thing_record_id: (s) => s.thing_record_id,
  path: (s) => s?.path,
  location: (l) => l.location_id,
  image: (i) => i.key || i.dataUrl,
  id: (i) => i.id,
  thing_storage: (s) => s._id,
  policy: (a) => a._id ?? a.name,
  specification: (a) => a._id ?? a.name,
  _id: (i) => i._id,
};
