import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikCheckBox, FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import PermissionSelector from '@pw/components_v2/CompanyView/modals/users/PermissionSelector';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';

function RoleSelectorModal({ open, item, onClose, readonly }) {
  const { enqueueSnackbar } = useSnackbar();

  const { users = [], roles = [] } = item;

  console.log('Role Modal', item);

  if (users.length === 0 || roles.length === 0) {
    enqueueSnackbar('No users or roles selected!', {variant: 'error' });
    return;
  }

  const roleOptions = useMemo(() => roles.map((r) => r.name), [roles]);

  const onSubmit = useCallback((values) => {
    console.log('Values', values);
    const body = {
      users,
      roles: roleOptions.map((r) => values[r] ? r : undefined).filter((v) => v)
    };
    console.log('Role', body);
    onClose(body);
  }, [onClose]);

  const changeSet = useMemo(()=> ({
    // company identifiers
    ...roleOptions.map((r) => ({
      [r]: [false, yup.boolean()]
    }))
  }), [roleOptions]);

  return (
    <ModalWithClose open={open} onClose={onClose} title="Select Roles">
      <FormikForm
        changeSet={changeSet}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Stack className="form" spacing="2rem">

          <Stack className="list">
            {roleOptions.map((p) => (
              <Box className="listItem" key={`b_${p}`} sx={{ padding: '0.5rem' }}>
                <FormikCheckBox key={p} name={p} label={p} />
              </Box>
            ))}
          </Stack>

          <FormButtons readonly={readonly} onCancel={onClose} />
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default RoleSelectorModal;
