import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import { Body1, H5 } from '@pw/components/Typography';
import { ASSET_STATUS } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import debounce from '@pw/utilities/debounce';
import generateIds from '@pw/utilities/generateIds';
import parseIdPattern from '@pw/utilities/parseIdPattern';
import { useCallback } from 'react';
import * as yup from 'yup';

function GeneratorModal({ open, thing, assetType, id, prefix, onClose }) {
  const [lastNumber, setLastNumber] = id;

  const changeSet = {
    amount: [
      '',
      yup
        .number()
        .positive('Quantity must be positive!')
        .required('Quantity required!'),
    ],
    location: locationFields({}),
  };

  const handleSubmit = useCallback(
    (values) => {
      // Create a new asset item
      const [px, pad] = parseIdPattern(prefix);

      // Check the thing
      const as = { thing: undefined };
      // For a tracked item, simply set the asset thing
      if (thing?.type === THING_TYPES.TRACKED) {
        as.thing = thing?.id;
      }
      const st = Number(lastNumber ?? 0) + 1;
      const et = Number(lastNumber ?? 0) + 1 + Number(values?.amount);
      const ids = generateIds(px, pad, st, et);
      const assets = ids.map((i) => ({
        name: i,
        type: assetType,
        status: ASSET_STATUS.PENDING_CREATE,
        properties: {
          ...as,
        },
        location: values?.location,
      }));

      debounce(() => setLastNumber(et), 25);
      onClose(assets);
    },
    [onClose],
  );

  return (
    <ModalWithClose
      open={open}
      onClose={() => onClose()}
      title='Generate Assets'
    >
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <FormikNumberField
            label='Quantity'
            name='amount'
            fullWidth
            required
          />
          <Location name='location' />

          <Stack spacing={1} className='section'>
            <H5 className='section-title'>Generate Assets</H5>
            <Instructions>
              Please check the details below before confirming
            </Instructions>
            <FlexBox sx={{ px: '1rem' }}>
              <Body1>Pattern</Body1>
              <Body1>
                <strong>{prefix}</strong>
              </Body1>
            </FlexBox>
            <FlexBox sx={{ px: '1rem' }}>
              <Body1>Last Number</Body1>
              <Body1>
                <strong>{lastNumber}</strong>
              </Body1>
            </FlexBox>
          </Stack>

          <Errors />
        </Stack>
        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
            label='Cancel'
          />
          <FilledButton size='small' type='submit'>
            Save
          </FilledButton>
        </Box>
      </FormikForm>
    </ModalWithClose>
  );
}

export default GeneratorModal;
