import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import LiquidSummary from '@pw/components/LiquidSummary';
import { H5 } from '@pw/components/Typography';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import AssetInfo from './AssetInfo';

function AssetSummaryList({ items, onEdit, onRemove }) {
	const display = (value) => <AssetInfo item={value} />;

	return (
		<Box className='inventory'>
			<H5 className='inventory-header'>Assets</H5>
			<Box className='inventory-contents'>
				<Stack className='list'>
					{items.map((asset) => (
						<AssetListComponentV2
							key={asset.path ?? asset.asset_id ?? asset.rw_asset_id}
							item={asset}
							onEdit={onEdit}
							onRemove={onRemove}
							display={display}
							selected={(i) => i.processed === ASSET_PROCESSED_STATUS.CONFIRMED}
						/>
					))}
				</Stack>
			</Box>
			<LiquidSummary assets={items} />
		</Box>
	);
}

export default AssetSummaryList;
