import Stack from '@mui/material/Stack';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1, Body3 } from '@pw/components/Typography';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { removePriceCurveThunk } from '@pw/redux/thunks/priceCurves';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { usePriceCurvesQuery } from '@pw/utilities/hooks/service/usePriceCurvesQueries';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PriceCurveEditor from './PriceCurveEditor';

function PriceCurve({ item }) {
  return (
    <Stack>
      <EntryTitle value={item?.name} />
      <EntryLabel value={item?.description} />
    </Stack>
  );
}

function PriceCurvesView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.PRICE_CURVES, PermissionType.MODIFY);

  const { isLoading, data = [] } = usePriceCurvesQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const [priceCurves, initCurves, , upsertCurves, removeCurves] = useItemListManager({
    id: ID._id,
    comp: COMP._id,
    initialData: items,
  });
  const [priceCurve, setPriceCurve] = useState(null);

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initCurves(items);
    }
  }, [initCurves, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((curve) => {
      setPriceCurve(curve);
      showEditor();
    }, [setPriceCurve]);

    const handleRemove = useCallback(
      (curve) => {
        const body = {
          _id: curve._id,
        };
        console.log('Remove curve', body);

        confirm({
          title: 'Remove Curve',
          content: (
            <Stack>
              <Body1>{`Remove curve ${curve.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          ),
        })
        .then(() => dispatch(removePriceCurveThunk(body)).unwrap().then(removeCurves))
        .catch(() => {});
      },
      [removeCurves],
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {
          onClose();
          setPriceCurve({
            name,
          });
          showEditor();
        },
        [setPriceCurve],
      );

      return (
        <AddNewField label="Add New Price Curve" id="new_curve" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit curve',
        Icon: SvgEdit,
        handler: handleEdit,
      },
      {
        title: 'Remove curve',
        Icon: SvgDelete,
        handler: handleRemove,
      },
    ];

    return (
      <ToolbarSection title="Price Curves" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={PriceCurve} items={priceCurves} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((curve) => {
      console.log('Curve updated', curve);
      if (curve) {
        debounce(() => {
          upsertCurves(curve);
        }, 25);
      }
      hideEditor();
      setPriceCurve(null);
    }, [setPriceCurve, upsertCurves]);

    if (!priceCurve) {
      return;
    }

    return (
      <PriceCurveEditor entry={priceCurve} onClose={handleClose} company={company} readonly={readonly} />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default PriceCurvesView;
