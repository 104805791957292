export const THING_TYPES = {
  TRACKED: 'tracked', //"t",             // tracked inventory (each individual asset is tracked)
  FINISHED: 'finished', //"f",            // finished goods, not individually tracked
  CONSUMABLE: 'consumable', //"c",          // general stock (dry goods etc.)
  RAW: 'raw', //"r",                 // raw ingredients
  EXPIRING: 'expiring', //"e",            // expiring ingredients
  SERVICES: 'services', //"s",            // services quantised inventory
  WIP: 'wip', //"i",                 // work-in-progress
  WASTE: 'waste', //"w",               // can be used for potentially tracking waste
  LIQUID: 'liquid', //"l",              // can be used for tracking produced liquid type
  EXPENSES: 'expenses', //"x",            // allow tracking of incidental costs in the system
  PARTS: 'parts', //"p",               // Parts
};

export const THING_OPTIONS = [
  { label: 'Tracked', value: THING_TYPES.TRACKED },
  { label: 'Finished Goods', value: THING_TYPES.FINISHED },
  { label: 'Consumable', value: THING_TYPES.CONSUMABLE },
  { label: 'Raw Material', value: THING_TYPES.RAW },
  { label: 'Expiring Material', value: THING_TYPES.EXPIRING },
  { label: 'Services', value: THING_TYPES.SERVICES },
  { label: 'Marketplace Products', value: THING_TYPES.WIP },
  { label: 'Waste Material', value: THING_TYPES.WASTE },
  { label: 'Liquid', value: THING_TYPES.LIQUID },
  { label: 'Expenses', value: THING_TYPES.EXPENSES },
  { label: 'Parts', value: THING_TYPES.PARTS },
];

export const THING_TYPE_REVERSE = {
  [THING_TYPES.TRACKED]: 'Tracked',
  [THING_TYPES.FINISHED]: 'Finished Goods',
  [THING_TYPES.CONSUMABLE]: 'Consumable',
  [THING_TYPES.RAW]: 'Raw Material',
  [THING_TYPES.EXPIRING]: 'Expiring Material',
  [THING_TYPES.SERVICES]: 'Services',
  [THING_TYPES.WIP]: 'Marketplace Products',
  [THING_TYPES.WASTE]: 'Waste Material',
  [THING_TYPES.LIQUID]: 'Liquid',
  [THING_TYPES.EXPENSES]: 'Expenses',
  [THING_TYPES.PARTS]: 'Parts',
};

export const INVENTORY_STATUS = {
  PENDING: 'pending', //"0",
  CONFIRMED: 'confirmed', //"1",
  ERROR: 'error', //"2",
};

export const FINISHED_GOODS_TYPES = {
  BOTTLE: 'bottle', // "0",
  PACK: 'pack', // "1",
  CONTAINER: 'container', // "2",
};

export const FINISHED_GOODS_TYPE_OPTIONS = [
  { label: 'Bottle', value: FINISHED_GOODS_TYPES.BOTTLE },
  { label: 'Pack', value: FINISHED_GOODS_TYPES.PACK },
  { label: 'Container', value: FINISHED_GOODS_TYPES.CONTAINER },
];

export const THING_EDIT_URL = {
  [THING_TYPES.TRACKED]: '/admin/things/tracked',
  [THING_TYPES.FINISHED]: '/admin/things/finished',
  [THING_TYPES.CONSUMABLE]: '/admin/things/cons',
  [THING_TYPES.RAW]: '/admin/things/raw',
  [THING_TYPES.EXPIRING]: '/admin/things/exp',
  [THING_TYPES.SERVICES]: '/admin/things/services',
  [THING_TYPES.WIP]: '/admin/things/market',
  [THING_TYPES.WASTE]: '/admin/things/waste',
  [THING_TYPES.LIQUID]: '/admin/things/liquid',
  [THING_TYPES.EXPENSES]: '/admin/things/expenses',
  [THING_TYPES.PARTS]: '/admin/things/parts',
};

export const SKU_LIQUID_TYPES = [
  { label: 'Beer', value: 'beer' },
  { label: 'Wine', value: 'wine' },
  { label: 'Bourbon', value: 'bourbon' },
  { label: 'Single Malt', value: 'single_malt' },
  { label: 'Grain Whiskey', value: 'grain_whiskey' },
  { label: 'Rye Whiskey', value: 'rye_whiskey' },
  { label: 'Whiskey', value: 'whiskey' },
  { label: 'Whisky', value: 'whisky' },
  { label: 'Vodka', value: 'vodka' },
  { label: 'Rum', value: 'rum' },
  { label: 'Gin', value: 'gin' },
  { label: 'Tequila', value: 'tequila' },
  { label: 'Brandy', value: 'brandy' },
  { label: 'Liqueurs', value: 'liqueurs' },
  { label: 'Cider', value: 'cider' },
  { label: 'Mead', value: 'mead' },
  { label: 'Sake', value: 'sake' },
  { label: 'Pisco', value: 'pisco' },
  { label: 'Soju', value: 'soju' },
  { label: 'Absinthe', value: 'absinthe' },
  { label: 'Schnapps', value: 'schnapps' },
  { label: 'Mezcal', value: 'mezcal' },
  { label: 'Scotch', value: 'scotch' },
  { label: 'Sotol', value: 'sotol' },
  { label: 'Cognac', value: 'cognac' },
  { label: 'Grappa', value: 'grappa' },
];

export const WASTE_REASONS = {
  contaminated: '1',
  broken: '2',
  lost: '3',
  spilled: '4',
  leaked: '5',
};

export const WASTE_REASON_OPTIONS = [
  { label: 'Contaminated', value: '1' },
  { label: 'Broken', value: '2' },
  { label: 'Lost', value: '3' },
  { label: 'Spilled', value: '4' },
  { label: 'leaked', value: '5' },
];
