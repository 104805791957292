import Machine from '@pw/components_v2/CompanyView/modals/plant/modals/Machine';
import Sensor from '@pw/components_v2/CompanyView/modals/plant/modals/Sensor';
import Tank from '@pw/components_v2/CompanyView/modals/plant/modals/Tank';
import Policy from '@pw/components_v2/ThingView/modals/recipe/modals/Policy';
import { DESIGN_NODE_TYPE } from '@pw/consts/designs';
import DesignerContext from '@pw/context/DesignerContext';
import { useContext } from 'react';

function ModalSwitch() {
  // Grab the selected node
  const {
    selectedNode
  } = useContext(DesignerContext);

  if (!selectedNode) return null;

  console.log('Selected node', selectedNode);

  const { category, type } = selectedNode?.data ?? {};

  if (category === DESIGN_NODE_TYPE.MACHINE) {
    switch (type) {
      case 'isr':
      case 'beer_well':
      case 'feints':
      case 'line_tank':
      case 'blending_tank':
        return (<Tank />);
      // Machines
      default:
        return (<Machine />);
    }
  }

  if (category === DESIGN_NODE_TYPE.OBSERVATION) {
    // Single form to capture the observation settings...
    if (type === 'policy') {
      return (<Policy />);
    } else {
      return (<Sensor />);
    }
  }
}

export default ModalSwitch;
