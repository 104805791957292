import { AddBox } from '@mui/icons-material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import GeneralSearch from '@pw/components/Search/GeneralSearch';
import { Body1, Body3, H5, H6 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { setActiveListItem } from '@pw/redux/containers/App';
import { useCompanySpecifications } from '@pw/redux/containers/User';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import SpecificationListComponent from '../admin/Specification/SpecificationListItem';

function SpecificationSelector({
  title,
  sectionTitle,
  list,
  onAdd,
  onEdit,
  onRemove,
  single = false,
}) {
  const { readonly } = useContext(FormikContext);

  return (
    <Stack className="section">
      {title && <H5 className="section-header">{title}</H5>}
      {sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}

      <Collapse in={!(single && list.length === 1)} unmountOnExit>
        <GeneralSearch
          label="Name"
          disabled={readonly}
          types={{ specifications: true }}
          onItemClick={onAdd}
          multiple={!single}
          count={list.length}
          buttonIcon={<AddBox />}
          scan={false}
        />
      </Collapse>
      {list?.length > 0 && (
        <Box className="inventory">
          <Stack spacing={0} className="list">
            {list.map((spec) => (
              <SpecificationListComponent
                item={spec}
                onEdit={onEdit}
                onRemove={onRemove}
                key={spec._id}
              />
            ))}
          </Stack>
        </Box>
      )}
      {list?.length === 0 && <Body3>None</Body3>}
    </Stack>
  );
}

function useSpecificationSelectorHook(props) {
  const {
    requestType,
    title,
    initialSpecifications = [],
    SpecificationModal,
    specificationFilter = (x) => x,
  } = props;

  const companySpecifications = useCompanySpecifications();
  const defaultSpecifications = useMemo(() => {
    if (requestType) {
      return companySpecifications.filter((spec) => spec.requests?.includes(requestType));
    }
    return [];
  }, [requestType, companySpecifications]);

  console.log('Specification Selector', props, defaultSpecifications);

  const confirm = useConfirm();
  const dispatch = useDispatch();

  const [currentSpecification, setCurrentSpecification] = useState(null);

  const [specifications, initSpecifications, , upsertSpecifications, removeSpecifications] = useItemListManager({
    initialData: initialSpecifications,
  });

  // TODO: This is used to confirm all policy entries of all specifications
  const setSelectedSpecification = useCallback(
    (item) => {
      setCurrentSpecification(item);
      if (item) {
        dispatch(setActiveListItem(item));
      }
    },
    [dispatch, setCurrentSpecification],
  );

  /**
   * Initialize the specification set - including the defaults
   * @type {(function([]=): void)|*}
   */
  const initializeSpecifications = useCallback((specificationSet = []) => {
    debounce(() => initSpecifications([...defaultSpecifications, ...specificationSet]), 25);
  }, [initSpecifications, defaultSpecifications]);

  /**
   * Load policies based on passed in configuration
   * @type {(function([]=): void)|*}
   */
  const loadSpecifications = useCallback((specifications = []) => {
    if (specifications.length > 0) {
      console.log('Specifications', specifications);
      const mappedSpecifications = specifications
      .map((p) => {
        return companySpecifications.find((k) => k._id === p._id);
      })
      .filter((p) => p);

      console.log(' -->', mappedSpecifications);
      debounce(() => {
        upsertSpecifications(mappedSpecifications);
      }, 25);
    }
  }, [upsertSpecifications, companySpecifications]);

  function SpecificationComponent() {
    const upsertSpecification = useCallback(
      (policy) => {
        if (specificationFilter && specificationFilter(policy)) {
          setSelectedSpecification(policy);
        }
      },
      [specificationFilter, setSelectedSpecification],
    );

    const removeSpecification = useCallback(
      (specification) => {
        if (specification) {
          confirm({
            title: 'Remove Specification',
            content: <Body1>{`Remove ${specification?.name}?`}</Body1>,
          })
          .then(() => removeSpecifications(specification))
          .catch(() => {
          });

        }
      },
      [confirm, removeSpecifications],
    );

    return (
      <>
        <SpecificationSelector
          title={title}
          list={specifications}
          onEdit={upsertSpecification}
          onAdd={upsertSpecification}
          onRemove={removeSpecification}
        />
      </>
    );
  }

  function ModalComponents() {
    const handleSpecificationUpdate = useCallback(
      (spec) => {
        console.log(
          '>>handleSpecificationUpdate',
          spec?._id,
          spec?.name,
          spec,
        );

        if (spec) {
          upsertSpecifications(spec);
        }
        setCurrentSpecification(null);
      },
      [upsertSpecifications, setCurrentSpecification],
    );

    return (
      <>
        {SpecificationModal && !!currentSpecification && (
          <SpecificationModal
            item={currentSpecification}
            open={!!currentSpecification}
            onClose={handleSpecificationUpdate}
            upsertPolicies={upsertSpecifications}
          />
        )}
      </>
    );
  }

  return [
    [specifications, initializeSpecifications, upsertSpecifications, removeSpecifications],
    SpecificationComponent,
    ModalComponents,
    loadSpecifications,
  ];
}

export default useSpecificationSelectorHook;

