import Stack from '@mui/material/Stack';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1, Body3 } from '@pw/components/Typography';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { removeSpecificationThunk } from '@pw/redux/thunks/specification';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCompanySpecsQuery } from '@pw/utilities/hooks/service/useCompanySpecsQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SpecEditor from './SpecEditor';

function Spec({ item }) {
  return (
    <Stack>
      <EntryTitle value={item?.name} />
      <EntryLabel value={item?.description} />
    </Stack>
  );
}

function SpecsView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.SPECIFICATIONS, PermissionType.MODIFY);

  const { isLoading, data } = useCompanySpecsQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const [specs, initSpecs, , upsertSpecs, removeSpecs] = useItemListManager({
    id: ID._id,
    comp: COMP._id,
    initialData: items
  });

  const [spec, setSpec] = useState(null);

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initSpecs(items);
    }
  }, [initSpecs, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((spec) => {
      setSpec(spec);
      showEditor();
    }, [setSpec]);

    const handleRemove = useCallback(
      (spec) => {
        const body = {
          _id: spec._id
        };
        console.log('Remove specification', body);

        confirm({
          title: 'Remove Specification',
          content: (
            <Stack>
              <Body1>{`Remove specification ${spec.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          )
        })
        .then(() => dispatch(removeSpecificationThunk(body)).unwrap().then(removeSpecs))
        .catch(() => {
        });
      },
      [removeSpecs]
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {
          onClose();
          setSpec({
            name
          });
          showEditor();
        },
        [setSpec]
      );

      return (
        <AddNewField label="Add New Specification" id="new_spec" onAddNew={handleAddNew} fullWidth
                     disabled={readonly} />
      );
    };

    const tools = [
      {
        title: 'Edit specification',
        Icon: SvgEdit,
        handler: handleEdit
      },
      {
        title: 'Remove specification',
        Icon: SvgDelete,
        handler: handleRemove
      }
    ];

    return (
      <ToolbarSection title="Specifications" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={Spec} items={specs} tools={tools} identity={(i) => i._id}
                   readonly={readonly} />
      </ToolbarSection>
    );
  };

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((spec) => {
      console.log('specification updated', spec);
      if (spec) {
        debounce(() => {
          upsertSpecs(spec);
        }, 25);
      }
      hideEditor();
      setSpec(null);
    }, [setSpec, upsertSpecs]);

    if (!spec) {
      return;
    }

    return (
      <SpecEditor entry={spec} onClose={handleClose} company={company} readonly={readonly} />
    );
  };

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default SpecsView;
