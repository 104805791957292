import {saveDesignBatch} from "@pw/services/production.service";

function getNextName(names, prefix) {
  if (names.length === 0 || !names.includes(prefix)) {
    return prefix;
  }
  // Filter names that start with the given prefix and match the pattern "prefix (number)"
  const suffixNumbers = names
    .filter(name => name.startsWith(prefix))
    .map(name => {
      const match = name.match(/\((\d+)\)$/); // Extract the suffix number, if it exists
      return match ? parseInt(match[1], 10) : 1;
    });

  // Determine the next available suffix
  let nextSuffix = suffixNumbers.length ? suffixNumbers.length + 1 : 2;

  // Return the new name with the appropriate suffix
  return `${prefix} (${nextSuffix})`;
}

export const createNewBatch = async ({recipe, startDate, batches = []}) => {
  const notSaved = batches.filter(({ _id }) => !_id);

  if (notSaved.length > 0)   return;

  const isoDate = startDate.toISOString().split('T')[0];
  let _name = `Batch ${isoDate}`;

  const names = batches.map(({ name }) => name);
  _name = getNextName(names, _name);

  console.log('createNewBatch', { recipe })

  const newBatch = {
    name: _name,
    thing_id: recipe.thing_id,
    start_date: startDate,
    type: recipe.type,
    nodes: recipe.nodes,
    edges: recipe.edges,
    nodes_data: recipe.nodes_data || {},
    edges_data: recipe.edges_data || {},
    company_id: recipe.company_id,
  };

  try {
    await saveDesignBatch(newBatch);
  } catch (e) {
    console.log('error while saving a new design batch', e);
  }

  return newBatch;
}
