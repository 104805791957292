import { Stack } from '@mui/material';
import { FormikCheckBox, RenderIF } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      repeat: [!!initialValues?.repeat, yup.boolean()],
      interval: unitField(initialValues?.interval),
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack spacing={2}>
        <FormikCheckBox name="repeat" label="Repeat" />
        <RenderIF check={({ values }) => values?.repeat}>
          <FormikUnitField name="interval" label="Interval" options={TIME_UNIT_OPTIONS} />
        </RenderIF>
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
