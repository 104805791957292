import { REQUEST_STATUS } from './requests';

export const TASK_TABLE_STATUS = {
	...REQUEST_STATUS,
};

export const TASK_TYPES = {
  bottle: 'Bottle',
  cask: 'Cask',
  ibc: 'IBC',
  pallet: 'Pallet',
  tanker: 'Tanker',
  container: 'Container',
  pick: 'Pick',
  regauge: 'Regauge',
  sample: 'Sample out',
  ship: 'Ship',
  transfer: 'Transfer Liquid',
  filling_tank: 'Filling Tank',
  production_run: 'Production Run',
  restock: 'Restock',
  change_ownership: 'Change Ownership',
  inventory_audit: 'Inventory Audit',
  delivery: 'Delivery',
  duty_transfer: 'Duty Transfer',
  report_defect: 'Report Defect',
  duty_submission: 'Duty Submission',
  invoice: 'Invoice',
  purchase_order: 'Purchase order',
  expense_report: 'Expense report',
  sales_return: 'Sales Return',
  purchase_return: 'Purchase Return',
};

export const TASK_EDIT_URL_LOOKUP = {
  cask: '/app/cask',
  ibc: '/app/ibc',
  pallet: '/app/pallet',
  container: '/app/container',
  bottle: '/app/bottle-pallet',
  pick: '/app/pick',
  regauge: '/app/regauge',
  sample: '/app/sample',
  ship: '/app/ship',
  transfer: '/app/transfer',
  filling_tank: '/app/tank',
  production_run: '/app/production-run',
  tanker: '/app/tanker',
  restock: '/app/restock',
  change_ownership: '/app/change-ownership',
  inventory_audit: '/app/inventory-audit',
  delivery: '/app/delivery',
  duty_transfer: '/app/duty-transfer',
  report_defect: '/app/report-defect',
  duty_submission: '/app/duty-submission',
  invoice: '/app/invoice',
  purchase_order: '/app/purchase-order',
  expense_report: '/app/expense-report',
  sales_return: '/app/sales-return',
  purchase_return: '/app/purchase-return',
};
