import { useCompanySpecifications } from '@pw/redux/containers/User';
import { useCallback } from 'react';

const useSpecificationSearch = () => {
  const specifications = useCompanySpecifications();

  const search = useCallback(
    (param = '', itemFilter = (x) => !!x) => {
      // Find all specifications matching this
      return specifications
      .filter((specification) => {
        // Policy type must be in the filter
        console.log('specifications', specifications, specification);
        // Search term must be in the specification properties..
        const search_key =
          `${specification.name} ${specification.description}`.toLowerCase();
        // console.log('Search key', search_key, param);
        return search_key.includes(param.toLowerCase());
      })
      .filter(itemFilter);
    },
    [specifications],
  );

  return [search];
};

export default useSpecificationSearch;
