import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1 } from '@pw/components/Typography';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { toPriceString } from '@pw/consts/currencies';
import { toUnitString } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';
import CurveEditor from './CurveEditor';

function CurveItem({ item }) {
  return (
    <Stack>
      <EntryTitle value={new Date(item?.date).toLocaleDateString()} />
      <EntryLabel value={`${toPriceString(item?.price)} per 1 ${toUnitString(item?.unit)}`} />
    </Stack>
  );
}

function  Curve({ initialValues, readonly}) {
  const confirm = useConfirm();
  const { setFieldValue } = useContext(FormikContext);

  const [priceCurve, , , upsertPrices, removePrices] = useItemListManager({
    id: o=>o?.date,
    comp: COMP.date,
    initialData: initialValues ?? [],
  });

  // Currently selected price
  const [selectedPrice, setSelectedPrice] = useState(null);

  useEffect(() => {
    debounce(() => setFieldValue('curve', priceCurve), 25);
  }, [priceCurve]);

  const handleRemovePrice = useCallback((price) => {
    confirm({
      title: 'Remove Price',
      content: <Body1>{`Remove price entry?`}</Body1>,
    })
    .then(() => removePrices(price))
    .catch(() => {
    });
  }, [removePrices]);

  const handlePriceUpdate = useCallback(
    (price) => {
      if (price) {
        upsertPrices(price);
      }
      setSelectedPrice(null);
    },
    [upsertPrices, setSelectedPrice],
  );


  const Toolbar = ({ onClose }) => {
    const handleAddNew = useCallback(
      (team) => {
        onClose();
        setSelectedPrice({});
      },
      [setSelectedPrice],
    );

    return (
      <TitledButton handleClick={handleAddNew} label="New" disabled={readonly}>
        <SvgAddNew height={24} width={24} />
      </TitledButton>
    );
  }

  const tools = [
    {
      title: 'Edit price',
      Icon: SvgEdit,
      handler: setSelectedPrice,
    },
    {
      title: 'Remove price',
      Icon: SvgDelete,
      handler: handleRemovePrice,
    },
  ];

  return (
    <ToolbarSection title="Curve" Toolbar={Toolbar}>
      <BasicList Content={CurveItem} items={priceCurve} tools={tools} identity={(i) => i.date} readonly={readonly} />
      {!!selectedPrice && (
        <CurveEditor
          open={!!selectedPrice}
          item={selectedPrice}
          onClose={handlePriceUpdate}
        />
      )}
    </ToolbarSection>
  );
}

export default Curve;
