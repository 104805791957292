import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

const styles = {
	transaction: {
		display: 'none',
	},
};

export default function TransactionsWhiskeyReport({
	name,
	title = 'Tax Payment',
	specify = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FormikTextField
				name='a'
				label='Transaction'
				fullWidth
				sx={{ ...(!specify && styles.transaction) }}
			/>
			<FlexBox alignItems='top'>
				<FormikTextField name='b' label='Whiskey 160° and Under' fullWidth />
				<FormikTextField name='c' label='Whiskey Over 160°' fullWidth />
			</FlexBox>
			<FlexBox alignItems='top'>
				<FormikTextField name='d' label='Brandy 170° and Under' fullWidth />
				<FormikTextField name='e' label='Brandy Over 170°' fullWidth />
			</FlexBox>
		</FormikProvider>
	);
}
