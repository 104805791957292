import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import {
  FormikCheckBox,
  FormikDatePicker,
  FormikForm,
  FormikMeasuresField,
  FormikNumberField,
  FormikSelect,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { InventorySelectorV2 } from '@pw/components/InventorySelector';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import TrackedSKUSetup from '@pw/components/SKUSelector/modals/dest/TrackedSKUSetup';
import StorageSetupModal from '@pw/components/SKUSelector/modals/StorageSetup';
import { ASSET_STATUS, ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import {
  FINISHED_GOODS_TYPES,
  INVENTORY_STATUS,
  THING_TYPES,
} from '@pw/consts/thing';
import { useCompanyThings } from '@pw/redux/containers/User';
import toThingStorageItem from '@pw/utilities/adapters/toThingStorageItem';
import calculateThingStorageLiquid from '@pw/utilities/calculateThingStorageLiquid';
import { COMP, ID } from '@pw/utilities/comp';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgress from '@pw/utilities/hooks/logic/useProgress';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import TrackedThingSetup from './dest/TrackedThingSetup';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { CURRENCY_OPTIONS } from '@pw/consts/currencies';

export function ThingStorageItem({ item, unit }) {
  const { amount } = item;

  return <AmountDisplay label='Allocated' unit={unit} amount={amount} />;
}

function DeliveryThingModal({ item, open, onClose }) {
  const companyThings = useCompanyThings();

  const { enqueueSnackbar } = useSnackbar();

  console.log('Item', item);

  const {
    type,
    name,
    entries = [{ amount: null }],
    cost,
    vendor,
    vendors,
  } = item ?? {};

  const {
    unit,
    cased,
    last_number = 0,
    type: finishedGoodsType,
  } = item?.properties ?? {};

  const { releases = [] } = cased ?? {};

  // We expect there to be an entry - which has the specific values we want for delivery..
  const entry = entries[0] ?? {};
  const {
    expiry,
    release,
    duty_paid = false,
    amount,
    storage = [],
    tracked = [],
  } = entry;

  const converter = useConverter();

  const hasUnits = useMemo(
    () =>
      ![
        THING_TYPES.TRACKED,
        THING_TYPES.FINISHED,
        THING_TYPES.WIP,
        THING_TYPES.SERVICES,
        THING_TYPES.CONSUMABLE,
      ].includes(type),
    [type],
  );

  console.log('Thing Item', hasUnits, item);

  const isExpiring = useMemo(
    () => [THING_TYPES.EXPIRING].includes(type),
    [type],
  );
  const isTracked = useMemo(() => [THING_TYPES.TRACKED].includes(type), [type]);
  const isFinishedGoods = useMemo(
    () => [THING_TYPES.FINISHED].includes(type),
    [type],
  );
  const isService = useMemo(
    () => [THING_TYPES.SERVICES].includes(type),
    [type],
  );
  const isGeneral = useMemo(
    () =>
      ![
        THING_TYPES.TRACKED,
        THING_TYPES.WASTE,
        THING_TYPES.SERVICES,
        THING_TYPES.FINISHED,
      ].includes(type),
    [type],
  );

  // Any tracked assets
  const [
    trackedAssets,
    initTrackedAssets,
    ,
    upsertTrackedAsset,
    removeTrackedAsset,
  ] = useItemListManager({ initialData: tracked });
  // Storage assets
  const [storageAssets, , , upsertAsset, removeAsset] = useItemListManager({
    initialData: storage,
  });
  // Specific storage asset being edited
  const [storageAsset, setStorageAsset] = useState(null);

  console.log('storageAssets', storageAssets);

  const count = useMemo(() => {
    console.log('Amount', amount, 'Tracked', trackedAssets.length);
    if (trackedAssets.length > 0) {
      return trackedAssets.length;
    }
    return amount;
  }, [amount, trackedAssets]);

  const [ProgressBar, { setProgress, progressTotal, completion }] =
    useProgress();

  const exp = useMemo(() => {
    if (isExpiring) {
      return {
        expiry: [
          expiry ?? new Date().getTime(),
          yup.number().integer().required('Expiry required!'),
        ],
      };
    }
    return {};
  }, [expiry, isExpiring]);
  console.log('vendor', vendor);
  const vendorOptions = useMemo(
    () =>
      (item?.vendors ?? [])?.map((v) => ({
        label: `${v.vendor?.target_id?.name} ${v.product}`,
        value: v.vendor?._id,
      })),
    [item],
  );

  const releaseOptions = useMemo(
    () => (releases ?? []).map((v) => ({ label: v?.name, value: v })),
    [releases],
  );

  const changeSet = useMemo(
    () => ({
      ...(!isService
        ? {
            vendor: [
              vendor?._id,
              yup.string().required('Please select a Vendor!'),
            ],
          }
        : {}),
      amount: [
        !isTracked && unit && count
          ? converter.cx(count, null, unit)
          : (count ?? ''),
        yup
          .number()
          .positive('Quantity must be positive!')
          .required('Quantity required!'),
      ],
      ...exp,
      //   lot_code: [lot_code ?? '', yup.string()],
      total_cost: [
        cost?.total,
        yup.object().shape({
          value: yup.number(),
          unit: yup.string(),
        }),
        ,
      ],
      ...(isFinishedGoods
        ? {
            duty_paid: [duty_paid, yup.boolean()],
            release: [release, yup.object()],
          }
        : {}),
    }),
    [],
  );

  const handleSubmit = useCallback(
    (values) => {
      console.log('values', values);
      // Grab the client information from the Thing, and set that in the storage entry
      // so that we know who owns this stuff..
      let client = undefined;
      if (!!item.client) {
        client = {
          id: item.client?._id,
          name: item.client?.target_id?.name,
          type: item.client?.target_id?.type,
        };
      };
      const updatedStorage = (storageAssets ?? []).map((s) => {
        const liquid_amount = isFinishedGoods
          ? calculateThingStorageLiquid(
              converter,
              s.amount,
              item,
              companyThings,
            )
          : undefined;
        return {
          ...s,
          liquid_amount,
        };
      });

      const amount = hasUnits
        ? converter.cx(values.amount, unit)
        : tracked.length > 0
          ? tracked.length
          : values.amount;
          
      let processed = item?.processed ?? INVENTORY_STATUS.PENDING;
      if (storageAssets && storageAssets.length > 0) {
        processed = storageAssets.find(
          (i) => i.processed !== ASSET_PROCESSED_STATUS.CONFIRMED,
        )
          ? INVENTORY_STATUS.PENDING
          : INVENTORY_STATUS.CONFIRMED;
      }
      const c_vendor = vendors.find((i) => i.vendor?._id === values.vendor);

      const updatedEntry = {
        ...entry,
        amount,
        storage: updatedStorage,
        processed
      };
      console.log('Updated Entry', hasUnits, updatedEntry);
      const thing = {
        ...item,
        id: item?.id,
        thing: item,
        processed,
        last_number: Number(last_number) + trackedAssets.length,
        entries: [updatedEntry],
        amount,
        vendor: c_vendor?.vendor,
        ...(values?.total_cost
          ? {
              cost: {
                total: values?.total_cost,
                item: {
                  value: Number(values?.total_cost?.value) / Number(amount),
                  unit: values?.total_cost?.unit,
                },
              },
            }
          : {}),
        release: values?.release,
        expiry: values?.expiry,
        compliance: values?.compliance,
        trackedAssets,
      };
      onClose(thing);
    },
    [item, vendors, entry, storageAssets, trackedAssets],
  );

  useEffect(
    () => {
      const totalAllocated = storageAssets.reduce(
        (v, i) =>
          Number(v) +
          Number(unit ? converter.cx(i.amount, null, unit) : i.amount * 1.0),
        0,
      );
      console.log('Total allocated', totalAllocated);
      setProgress(totalAllocated);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageAssets, unit],
  );

  // Need to adapt the thing to meet the SKUItem/SKUEntryItem/SKUStorage structure
  // As this is a delivery, we'll add one basic entry here
  const handleAdd = (asset) => {
    if (asset?.status === ASSET_STATUS.DEFECTIVE) {
      enqueueSnackbar(`Defective assets cannot be selected!`, {
        variant: 'error',
      });
      return;
    }
    setStorageAsset(toThingStorageItem(asset));
  };

  const display = (value) => (
    <ThingStorageItem
      item={value}
      key={value?.asset_id}
      unit={hasUnits ? unit : null}
    />
  );

  const filter = useMemo(
    () => ({
      asset_types: [ASSET_TYPES.PALLET, ASSET_TYPES.CONTAINER],
    }),
    [],
  );

  return (
    <>
      <ModalWithClose
        open={open}
        onClose={() => onClose()}
        title={`${name} Delivery`}
      >
        <FormikForm
          changeSet={changeSet}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Stack spacing='2rem'>
            {!isService && (
              <FormikSelect
                label='Vendor'
                name='vendor'
                options={vendorOptions}
                fullWidth
              />
            )}
            {isExpiring && (
              <FormikDatePicker label='Expiry' name='expiry' fullWidth />
            )}
            {isFinishedGoods && (
              <>
                <FormikCheckBox label='Duty Paid' name='duty_paid' />
                {finishedGoodsType === FINISHED_GOODS_TYPES.BOTTLE && (
                  <FormikSelect
                    label='Release'
                    name='release'
                    options={releaseOptions}
                    fullWidth
                  />
                )}
              </>
            )}
            {/* <FormikTextField label='Lot Code' name='lot_code' fullWidth /> */}
            <FlexBox alignItems='top'>
              <FormikMeasuresField
                label='Quantity'
                name='amount'
                unit={hasUnits ? unit : null}
                fullWidth
                disabled={
                  isTracked ||
                  (isFinishedGoods &&
                    finishedGoodsType === FINISHED_GOODS_TYPES.CONTAINER)
                }
                required
              />
              {!isService && (
                <FormikUnitField
                  label='Total Cost'
                  name='total_cost'
                  fullWidth
                  options={CURRENCY_OPTIONS}
                />
              )}
            </FlexBox>

            {(isGeneral ||
              (isFinishedGoods &&
                [
                  FINISHED_GOODS_TYPES.BOTTLE,
                  FINISHED_GOODS_TYPES.PACK,
                ].includes(finishedGoodsType))) && (
              <>
                <Instructions>
                  Please select the storage for the contents of this delivery.
                </Instructions>

                <ProgressBar name='amount' label='Amount' />

                <InventorySelectorV2
                  title='Storage Allocation'
                  types={filter}
                  onAdd={handleAdd}
                  assets={[
                    storageAssets,
                    setStorageAsset,
                    removeAsset,
                    display,
                  ]}
                />
              </>
            )}
            {(isTracked ||
              (isFinishedGoods &&
                finishedGoodsType === FINISHED_GOODS_TYPES.CONTAINER)) && (
              <>
                <TrackedThingSetup
                  thing={item}
                  field='amount'
                  assets={[
                    trackedAssets,
                    initTrackedAssets,
                    upsertTrackedAsset,
                    removeTrackedAsset,
                  ]}
                />
              </>
            )}
            {/*<Errors />*/}

            <Box className='action-buttons'>
              <TextButton
                size='small'
                handleClick={() => onClose()}
                color='secondary'
              >
                Cancel
              </TextButton>
              <FilledButton type='submit' size='small'>
                Save
              </FilledButton>
            </Box>
          </Stack>
        </FormikForm>
      </ModalWithClose>

      {!!storageAsset && (
        <StorageSetupModal
          open={!!storageAsset}
          unit={hasUnits ? unit : null}
          item={storageAsset}
          onClose={(v) => {
            if (v) {
              upsertAsset(v);
            }
            setStorageAsset(null);
          }}
          left={progressTotal - completion}
        />
      )}
    </>
  );
}

export default DeliveryThingModal;
