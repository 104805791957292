import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import AssetForm from '@pw/components/AssetForm';
import assetIdFields from '@pw/components/AssetID/assetIdFields';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import thingFields from '@pw/components/ThingSelector/thingFields';
import ThingField from '@pw/components/ThingSelector/ThingField';
import { H5, Overline } from '@pw/components/Typography';
import LedgerSearch from '@pw/components_v2/search/ledger';
import { ASSET_TYPES } from '@pw/consts/asset';
import { THING_TYPES } from '@pw/consts/thing';
import { FormikProvider } from '@pw/providers/FormikProvider';
import processLiquid from '@pw/utilities/adapters/processLiquid';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useMemo, useState } from 'react';
import * as yup from 'yup';
import { TAG_CATEGORY } from '@pw/consts/tag';
import ThingSearch from '@pw/components_v2/search/thing';

function propertyFields(fields = {}) {
  const { tags = [], thing } = fields;
  return {
    thing: [thing, yup.object()],
    tags: [
      typeof tags === 'string' ? tags?.split(',') : tags,
      yup.array().of(yup.string()),
    ],
  };
}

function Properties({ entity }) {
  //const filter = (item) => item?.asset_type === ASSET_TYPES.IBC;
  return (
    <FormikProvider path='properties'>
      <Stack spacing={2}>
        <Divider><Overline>Properties</Overline></Divider>
        <ThingSearch field={"thing"} label='Type' types={[THING_TYPES.TRACKED]} />
        <TagSelect name='tags' entity={entity} type={TAG_CATEGORY.ASSET} />
      </Stack>
    </FormikProvider>
  );
}

function IBCForm(props) {
  const converter = useConverter();
  const [entity, setEntity] = useState(null);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...assetIdFields(initialValues),
      ledger: [initialValues?.ledger, yup.object()],
      properties: propertyFields(initialValues.properties),
    }),
    [converter],
  );

  const handleBeforeSave = (entity) => {
    const filteredEntity = processLiquid(entity);
    return {
      ...filteredEntity,
    };
  };

  const handleInit = (entity) => {
    setEntity(entity);
  };

  return (
    <AssetForm
      assetLabel='IBC'
      assetType={ASSET_TYPES.IBC}
      changeSetGenerator={changeSetGenerator}
      onBeforeSave={handleBeforeSave}
      onInit={handleInit}
      {...props}
    >
      <LedgerSearch field="ledger" label="Ledger" />
      <Properties entity={entity} />
    </AssetForm>
  );
}

export default withAppLayout(IBCForm, { title: 'IBC Management' });
