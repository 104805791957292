// PrintLabelsModal.js
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import { FormikForm, FormikNumberField, FormikSelect } from '@pw/components/Forms/FormikForm';
import { useIsUsUser } from '@pw/redux/containers/User';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

function PrintLabelsModal({ thing, open, onClose }) {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const isUsUser = useIsUsUser();

	// Get the releases from the label
	const releases = thing?.properties?.releases ?? [];

	
	const changeSet = {
		labelCount: [
			1,
			yup.number().positive().integer().required('Label count is required!'),
		],
		release: [
			releases[0]?.id,
			yup.string().required('Release is required!'),
		],
	};

	const handleSubmit = useCallback(
		(values) => {
			console.log('%c values', 'color: #007acc;', values);
			const { labelCount, release } = values;

			// If us customer - navigate to nabca label, for everyone else, standard label
			if (isUsUser) {
				navigate(
					'/admin/labels/nabca',
					{ state: { thing_id: thing._id, labels: labelCount, release } }
				);
			} else {
				navigate(
					'/admin/labels/standard',
					{ state: { thing_id: thing._id, labels: labelCount, release } }
				);
			}
			onClose();
		},
		[onClose],
	);

	const toOptions = useCallback((releases) => {
		return releases.map((release) => ({
			label: release.name,
			value: release.id,
		}));
	}, []);

	return (
		<ModalWithClose title='Print Label' open={open} onClose={onClose}>

			<FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
				<Stack spacing={2}>
					<FormikNumberField
						label='Label Count'
						name='labelCount'
						fullWidth
					/>
					<FormikSelect
						label='Release'
						name='release'
						options={toOptions(releases)}
						fullWidth
					/>

					<Errors />

					<Box className='action-buttons'>
						<TextButton size='small' handleClick={onClose} color='secondary'>
							Cancel
						</TextButton>
						<FilledButton type='submit' size='small'>
							Generate
						</FilledButton>
					</Box>
				</Stack>
			</FormikForm>

		</ModalWithClose>
	);
}

export default PrintLabelsModal;
