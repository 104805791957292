import { Stack } from '@mui/material';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import ThingSearch from '@pw/components_v2/search/thing';
import { THING_TYPES } from '@pw/consts/thing';
import { TEMPERATURE_UNIT_OPTIONS, UNITS_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      thing: [initialValues?.thing, yup.object()],
      quantity: unitField(initialValues?.quantity),
      temperature: unitField(initialValues?.temperature)
    }),
    []
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator} useDefault={false}>
      <Stack spacing={2} sx={{ pt: '1rem' }}>
        <ThingSearch field="thing" label='Material' types={[THING_TYPES.RAW, THING_TYPES.EXPIRING]} />
        <FormikUnitField name="quantity" label="Quantity" options={UNITS_OPTIONS} required />
        <FormikUnitField name="temperature" label="Temperature" options={TEMPERATURE_UNIT_OPTIONS} />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
