import { internalsSymbol } from 'reactflow';

const Position = {
  // process -> process source:source pairs
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom',
  Left: 'left',
  // material -> process, process -> observation, process -> operation
  TopRight: 'top',
  BottomRight: 'right',
  BottomLeft: 'bottom',
  TopLeft: 'left'
};

// offsets of handles
export const calcOffset = (node, position, handleType) => {
  const handleBounds = node[internalsSymbol].handleBounds;
  const handle = (handleBounds[handleType] || handleBounds['source']).find((h) => h.position === position);

  let offsetX = (handle.width / 2);
  let offsetY = (handle.height / 2);

  switch (position) {
    case Position.Left:
      offsetX = 1 * handle.width;
      break;
    case Position.Right:
      offsetX = -1 * handle.width;
      break;
    case Position.Top:
      offsetY = 1 * handle.height;
      break;
    case Position.Bottom:
      offsetY = -1 * handle.height;
      break;
  }
  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

export default Position;

