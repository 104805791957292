import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body1, Overline } from '@pw/components/Typography';
import formatNumber from '@pw/utilities/formatNumber';

const styles = {
  widgetStats: {
    spacing: 1,
  },
  widgetContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  widgetTextPrimary: {
    fontSize: '13px',
  },
  widgetText: {
    display: 'flex',
    alignItems: 'center',
  },
  labelStyle: {
    fontSize: '13px',
    fontWeight: '700',
    lineHeight: '31px',
  },
};

function LayoutNumberWidget({ value, unit = null, label }) {
  return (
    <Stack className="widget stats" sx={styles.widgetStats}>
      <FlexBox className="widget-content stats-content" sx={styles.widgetContent}>
        <Body1 sx={styles.labelStyle}>{label}</Body1>
        <FlexBox style={{ width: 'auto' }}>
          <Box className="widget-text" sx={styles.widgetText}>
            <Body1 className="widget-text-primary" sx={styles.widgetTextPrimary}>
              {formatNumber(value)}
            </Body1>
            {unit && <Overline>{unit}</Overline>}
          </Box>
        </FlexBox>
      </FlexBox>
    </Stack>
  );
}

export default LayoutNumberWidget;
