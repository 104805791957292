import { PermissionType, PermissionKey } from '@pw/consts/permissions';
import { checkPermission as chip } from './checkPermission';

const getPermissions = (canEdit, permission) => {
  console.log(`getPermissions `,canEdit, permission);
  return ({
    companyAdmin: canEdit && chip(permission, PermissionType.COMPANY_ADMIN),
    userAdmin: canEdit && chip(permission, PermissionType.USER_ADMIN),
    facilityAdmin: canEdit && chip(permission, PermissionType.FACILITY_ADMIN),
    priceCurveAdmin: canEdit && chip(permission, PermissionType.PRICE_CURVE_ADMIN),
    policyAdmin: canEdit && chip(permission, PermissionType.POLICY_ADMIN),
    thingAdmin: canEdit && chip(permission, PermissionType.THING_ADMIN),
    viewActivity: canEdit && chip(permission, PermissionType.VIEW_ACTIVITY),
    modifyRequest: canEdit && chip(permission, PermissionType.MODIFY_REQUEST),
    editLocation: canEdit && chip(permission, PermissionType.EDIT_LOCATION),
    editOwners: canEdit && chip(permission, PermissionType.EDIT_OWNERS),
    editLiquid: canEdit && chip(permission, PermissionType.EDIT_LIQUID),
    canApprove: canEdit && chip(permission, PermissionType.CAN_APPROVE),
    assignTask: canEdit && chip(permission, PermissionType.ASSIGN_TASK),
    canInstallContract: canEdit && chip(permission, PermissionType.CONTRACT_INSTALL),
    canFundAccount: canEdit && chip(permission, PermissionType.FUND_ACCOUNT),
    canViewCompanyList: canEdit && chip(permission, PermissionType.VIEW_COMPANY_LIST),
    canMangeSensors: canEdit && chip(permission, PermissionType.SENSOR_ADMIN),
    canModifyAsset: canEdit && chip(permission, PermissionType.MODIFY_ASSET),
    specificationAdmin: canEdit && chip(permission, PermissionType.SPECIFICATION_ADMIN),
  })
};

export default getPermissions;
