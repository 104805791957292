import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '../utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/admin/facility`;

export const upsertFacility = async (facility, headers = {}) =>
	fetchPost(BASE, headers, facility);

export const getAllFacilities = async (params = {}, headers = {}) =>
	fetchGet(`${BASE}${objectToQueryString(params)}`, headers);
