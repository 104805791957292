import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import PalletListItem from '@pw/components/AssetListItem/PalletListItem';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import Pagination from '@pw/components/Pagination';
import { Body1 } from '@pw/components/Typography';
import { ASSET_EDIT_URL, ASSET_TYPES } from '@pw/consts/asset';
import FormikContext from '@pw/context/FormikContext';
import { vh_50 } from '@pw/styles/heights';
import { useAssetsLazyQueryPaginated } from '@pw/utilities/hooks/service/useAssetsLazyQuery';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbar from './Toolbar';
import { FormikSelect } from '../Forms/FormikForm';
import { useCompanyPartners } from '@pw/redux/containers/User';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';

const styles = {
  loader: {
    zIndex: 1000,
    color: '#fff',
  },
  content: {
    ...vh_50,
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    padding: 0.2,
    paddingTop: 2,
  },
  emptyContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    p: 8,
  },
};

function Table({ external = false }) {
  const navigate = useNavigate();
  const { values } = useContext(FormikContext);

  const [pageSize, setPageSize] = useState('5');

  const reqParams = useMemo(() => {
    const { location = {}, partner_id } = values ?? {};
    const search = {};
    if (external) {
      return { partner_id, limit: Number(pageSize) };
    }
    if (location?.location_id) {
      search['location.location_id'] = location.location_id;
      if (location?.bay_id) {
        search['location.bay_id'] = location?.bay_id;
        if (location?.row_id) {
          search['location.row_id'] = location?.row_id;
          if (location?.level_id) {
            search['location.level_id'] = location?.level_id;
          }
        }
      }
    }
    return {
      search,
      limit: Number(pageSize),
    };
  }, [
    values.location?.location_id,
    values.location?.bay_id,
    values.location?.row_id,
    values.location?.level_id,
    pageSize,
    values.partner_id,
  ]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useAssetsLazyQueryPaginated(reqParams);

  const goToItem = useCallback((params) => {
    const id = params?._id ?? 'new';
    const type =  params?.token_type ?? params?.type;
    const route = ASSET_EDIT_URL[type];
    navigate(`${route}/${id}`);
  }, []);

  return (
    <>
      <Pagination
        hasNextPage={hasNext}
        hasPrevPage={hasPrev}
        onPressNextPage={loadNext}
        onPressPrevPage={loadPrev}
        onChangePageSize={setPageSize}
      />
      {!isLoading && (
        <Stack className='list'>
          {page.map((asset) =>
            asset?.type === ASSET_TYPES.PALLET ? (
              <PalletListItem
                key={asset.path}
                item={asset}
                onClick={goToItem}
              />
            ) : (
              <AssetListComponentV2
                key={asset._id}
                item={asset}
                onClick={goToItem}/>
            ),
          )}
          {page.length === 0 && (
            <Box sx={styles.emptyContent}>
              <Body1>Assets not found</Body1>
            </Box>
          )}
        </Stack>
      )}
      {isLoading && (
        <Box sx={styles.emptyContent}>
          <LoadingSmall message='Loading assets' />
        </Box>
      )}
    </>
  );
}

function ExplorerTable() {
  return (
    <Stack spacing={2}>
      <Toolbar />
      <Table />
    </Stack>
  );
}

function SelectCompanyPartners() {
  const partners = useCompanyPartners();
  const partnerOptions = useMemo(
    () =>
      partners
        .filter((i) => i.my_partner_id)
        .map((i) => ({ value: i.my_partner_id, label: i.company_name })),
    partners,
  );
  return (
    <FormikSelect
      fullWidth
      options={partnerOptions}
      label='Companies'
      name='partner_id'
    />
  );
}

export function BrokerTable() {
  return (
    <Stack spacing={2}>
      <SelectCompanyPartners />
      <Table external />
    </Stack>
  );
}

export default ExplorerTable;
