import { Stack } from '@mui/material';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1 } from '@pw/components/Typography';
import RoleModal from '@pw/components_v2/CompanyView/modals/users/RoleModal';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import FormikContext from '@pw/context/FormikContext';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useContext, useEffect, useState } from 'react';

function Role({ item }) {

  const { name, description, permissions = {} } = item;

  console.log('Permissions', permissions);

  return (
    <Stack>
      <EntryTitle value={name} />
      <EntryLabel value={description} />
    </Stack>
  );
}

function RoleSelector({ initialValues, readonly }) {
  const { setFieldValue } = useContext(FormikContext);
  const confirm = useConfirm();

  const [roles, initRoles, , upsertRoles, removeRoles] = useItemListManager({
    id: ID.name,
    comp: COMP.name,
    initialData: [],
  });

  const [role, setRole] = useState(null);

  useEffect(() => {
    // console.log('Initial roles', initialValues);
    if (initialValues) {
      initRoles(initialValues);
    }
  }, [initialValues, initRoles]);

  useEffect(() => {
    // console.log('Setting roles', roles);
    debounce(() => setFieldValue('roles', roles), 25);
  }, [roles]);

  const handleClose = useCallback((role) => {
    // Upserting role
    if (role) {
      // console.log('Updating role..', role);
      upsertRoles(role);
    }
    debounce(() => setRole(null), 25);
  }, [upsertRoles]);

  const handleRemoveRole = useCallback((removedRole) => {
    if (removedRole) {
      confirm({
        title: 'Remove Role',
        content: <Body1>{`Remove '${removedRole?.name}'?`}</Body1>,
      })
      .then(() => removeRoles(removedRole))
      .catch(() => {
      });
    }
  }, [removeRoles]);

  const Toolbar = ({ onClose }) => {
    const handleAddNew = useCallback((role) => {
      // console.log('Adding new role', role);
      onClose();
      setRole({
        name: role,
      });
    }, [setRole]);

    return (
      <AddNewField label="Add New Role" id="new_role" onAddNew={handleAddNew} fullWidth disabled={readonly} />
    );
  }

  const tools = [
    {
      title: 'Edit role',
      Icon: SvgEdit,
      handler: setRole
    },
    {
      title: 'Remove role',
      Icon: SvgDelete,
      handler: handleRemoveRole
    }
  ];

  return (
    <ToolbarSection title="Roles" Toolbar={Toolbar}>
      <BasicList Content={Role} items={roles} tools={tools} identity={(i) => i.name} />
      {!!role && (
        <RoleModal open={!!role} item={role} onClose={handleClose} readonly={readonly} />
      )}
    </ToolbarSection>
  );
}

export default RoleSelector;
