import { Stack } from '@mui/material';
import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { MASS_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      residence_time: unitField(initialValues?.residence_time),
      feed_size: unitField(initialValues?.feed_size),
      feed_interval: unitField(initialValues?.feed_interval),
      mill_speed: [initialValues?.mill_speed ?? 0, yup.number()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FormikUnitField name="residence_time" label="Residence Time" options={TIME_UNIT_OPTIONS} />
        <FlexBox>
          <FormikUnitField name="feed_size" label="Feed Size" options={MASS_UNIT_OPTIONS} fullWidth />
          <FormikUnitField name="feed_interval" label="Feed Interval" options={TIME_UNIT_OPTIONS} fullWidth />
        </FlexBox>
        <FormikNumberField name="mill_speed" label="Mill Speed" />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
