function SvgAddContact(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#AddContact_svg__clip0_129_26)' fill='currentColor'>
        <path d='M5.686 10.33a5.585 5.585 0 01-1.594-1.708C2.057 5.224 3.974.685 7.705.068c2.926-.484 5.633 1.685 6.02 4.824.38 3.099-1.74 5.97-4.646 6.283-.52.056-1.05.04-1.566.124-2.433.396-4.232 1.772-5.399 4.083-.533 1.055-.782 2.195-.805 3.393-.01.557.244.826.773.826h6.354c.523 0 .861.49.666.967-.126.305-.36.433-.672.433-1.376-.004-2.751-.002-4.127-.002H2.096c-1.277 0-2.111-.899-2.095-2.26.04-3.662 2.266-7.038 5.482-8.316.057-.023.111-.05.203-.09v-.002zm-1.11-4.703c-.004 2.293 1.762 4.187 3.907 4.19 2.16.002 3.934-1.868 3.94-4.154.005-2.347-1.736-4.223-3.927-4.232-2.155-.008-3.915 1.876-3.92 4.196z' />
        <path d='M14.058 15.058h.874c.469 0 .94-.005 1.408.002.379.005.665.318.66.707-.005.38-.288.683-.658.686-.68.007-1.361.002-2.042.003h-.242v2.369c-.002.46-.272.778-.658.775-.385-.003-.648-.32-.649-.786v-2.358h-.23c-.668 0-1.335.002-2.002 0-.414-.002-.71-.296-.71-.7 0-.405.294-.696.71-.697.735-.002 1.469 0 2.233 0v-.24V12.7c0-.467.261-.782.647-.787.393-.003.66.317.66.796v2.35-.002z' />
      </g>
      <defs>
        <clipPath id='AddContact_svg__clip0_129_26'>
          <path fill='currentColor' d='M0 0h17v21H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgAddContact;
