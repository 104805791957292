function SvgName(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Name_svg__clip0_70_37)' fill='#404040'>
        <path d='M13.334 4.686L9.217.573C8.85.209 8.399.02 7.83 0l-.06.005c-.059.004-.13.011-.201.022l-5.03.807C1.585.988.994 1.567.835 2.51c-.15.896-.293 1.794-.437 2.692-.12.75-.241 1.501-.365 2.25-.121.731.08 1.332.635 1.884C2.84 11.5 5.011 13.67 7.179 15.84l2.503 2.502c.878.878 2.01.878 2.886.003 1.84-1.838 3.803-3.797 5.77-5.772.192-.195.348-.401.449-.598.39-.756.231-1.6-.422-2.255a3467.45 3467.45 0 00-5.034-5.034h.003zm4.327 7.252l-5.726 5.724c-.268.268-.538.4-.806.4-.269 0-.54-.132-.807-.4l-1.809-1.808c-2.38-2.38-4.758-4.76-7.145-7.135-.36-.358-.483-.731-.403-1.208.186-1.088.362-2.197.534-3.272.087-.543.172-1.084.261-1.627.076-.463.392-.772.87-.85L7.637.955 7.766.94l.048-.006h.016c.296 0 .543.107.759.323l1.04 1.042 8.032 8.03c.544.542.544 1.067 0 1.61z' />
        <path d='M6.955 10.928c-.374-.021-.601-.145-.72-.418a.663.663 0 01.114-.746c.133-.152.285-.29.427-.434.972-.971 1.946-1.943 2.915-2.916.216-.218.47-.29.754-.195.269.09.427.29.461.577a.646.646 0 01-.188.555 521.994 521.994 0 01-3.38 3.373c-.115.112-.296.158-.383.204zM13.412 9.447c-.049.09-.101.282-.223.406A384.82 384.82 0 019.845 13.2a.684.684 0 01-.99-.007.685.685 0 01.018-.976 918.7 918.7 0 013.347-3.346.66.66 0 01.76-.149c.274.112.409.332.434.722l-.002.002zM2.934 3.623c0-.383.3-.685.683-.688a.695.695 0 01.706.692c0 .374-.312.69-.685.695a.703.703 0 01-.704-.7z' />
      </g>
      <defs>
        <clipPath id='Name_svg__clip0_70_37'>
          <path fill='#fff' d='M0 0h19v19H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgName;
