import { useCallback, useEffect, useState, useMemo } from "react";
import DesignerContext from "../context/DesignerContext";
import {
  getDesign,
  getDesignBatch,
  getRecipeBatchesList,
  saveDesign,
  saveDesignBatch
} from "../services/production.service";
import { useEdgesState, useNodesState } from "reactflow";
import { useNavigate } from 'react-router-dom';
import useProductionDesignerQuery from '@pw/utilities/hooks/logic/useNavigateProductionDesigner';
import { createNewBatch } from "@pw/components/ProductionDesigner/functions/designServices";
import { getFromLS, saveToLS } from '@pw/components/ProcessDesigner/functions/localStorage';

const SKIP_LOAD_LABELS = ['label', 'create', 'upload']
function DesignerProvider({ children, mode, thingId, batchId, processId, 
  type = 'liquid' 
}) {
  if (!mode) {
    throw new Error("DesignContext: 'mode' is not provided");
  }
  const navigate = useNavigate();
  const designerType = useMemo(() => {
    switch (mode) {
      case 'pipeline':
        return 'recipe';
      case 'design':
        return 'recipe';
      case 'process':
        return 'recipe';
      case 'schedule':
        return 'batch';
    }
  }, [mode]);

  const [isLoading, setIsLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [recipe, setRecipe] = useState({ type });
  const [batch, setBatch] = useState({});
  const [refreshOptions, setRefreshOptions] = useState(0);

useEffect(() => {
  console.log({ recipe })
}, [recipe])

  const [nodes, setNodes, _onNodesChange] = useNodesState([]);
  const [edges, setEdges, _onEdgesChange] = useEdgesState([]);
  const [nodesData, setNodesData] = useState({});
  const [edgesData, setEdgesData] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [observations, setObservations] = useState({});

  const setRecipeCallback = (recipe) => {
    setRecipe({ ...recipe, type });
    setNodes(recipe.nodes ?? []);
    setEdges(recipe.edges ?? []);
    setNodesData(recipe.nodes_data ?? {});
    setEdgesData(recipe.edges_data ?? {});
  }

  const setBatchCallback = (newBatch, recipe) => {
    setBatch(newBatch);
    setRecipeCallback(recipe);
  };
  const loadRecipe = useCallback(async () => {
    if (!(thingId || processId) || SKIP_LOAD_LABELS.indexOf(thingId) > -1) return;
    if (recipe?.thing_id === thingId) return;
    setIsLoading({ message: 'Loading recipe' });
    if (mode === 'process') {
      console.log('seeking', { processId });
      // if (recipe?.id === processId) return;
      const results = getFromLS('recipe');
      console.log({ results });
      const result = results.find((r) => r.id === processId);
      console.log({ processId, result });
      if (result) setRecipeCallback(result);
    } else {
      try {
        const result = await getDesign(thingId);
        if (result) {
          setRecipeCallback(result);
        }
      } catch (e) {
        console.log(`couldn't load design`, e);
        setRecipe({
          ...(mode === 'process' && processId && { process_id: processId, type: 'workflow' }),
          ...(thingId && { thing_id: thingId, type: 'liquid' }),
          nodes,
          edges,
        });
        setNodesData({});
      }
    }
    setIsLoading(false);
  }, [thingId, processId, recipe, mode, type]);

  const loadBatches = async (targetBatchId = batchId) => {
    setIsLoading({ message: 'Loading batches' });
    try {
      const batchesResponse = await getRecipeBatchesList({ thing_id: thingId });
      const resOpts = batchesResponse.map(({ _id, name, thing_id }) => ({ _id, name, thing_id }));

      if (resOpts.length > 0 && batchId) {
        setBatches(resOpts);
        const foundBatch = batchId && SKIP_LOAD_LABELS.indexOf(batchId) === -1
          ? resOpts.find((r) => r._id === batchId)
          : resOpts[resOpts.length - 1]

        const routeString = useProductionDesignerQuery(thingId, foundBatch?._id);
        navigate(routeString);
      }
    } catch (e) {
      console.log('XXXXXX Error loading batches', e);
    }

    setIsLoading(false);
  }

  const createBatch = useCallback(async (startDate) => {
    setIsLoading({ message: 'creating and saving the new batch' });
    // const d = new Date();
    // const roundingMS =  (15 * 60 * 1_000);
    // const nowRounded = Math.ceil(d.getTime() /roundingMS) * roundingMS;
    const response = await createNewBatch({recipe, date: startDate, batches});
    loadBatches();
    setIsLoading(false);
  }, [recipe, batches]);

  const loadBatch = useCallback(async () => {
    if (!batchId || SKIP_LOAD_LABELS.indexOf(batchId) > -1) {
      return;
    }
    setIsLoading({ message: 'Loading specific batch' });
    try {
      setBatchCallback(await getDesignBatch(batchId), recipe);
    } catch (e) {
      console.log(`couldn't load batch '${batchId}'`, e);
    }
    setIsLoading(false);
  }, [batchId, recipe]);

  useEffect(() => {
    if (!(thingId || processId)) return;
    loadRecipe();
  }, [thingId, processId, recipe]);

  useEffect(() => {
    if (mode === 'schedule') {
      loadBatches();
      return;
    }
    setBatches([]);

    // if (mode === 'design') {
    // }
  }, [mode]);
  useEffect(() => {
    if (designerType === 'recipe') {
      setRecipe((prev) => ({
        ...prev,
        nodes,
        edges,
        nodes_data: nodesData,
        edges_data: edgesData,
      }));
    }
  }, [edges, nodes, nodesData, edgesData]);

  useEffect(() => {
    loadBatch();
  }, [batchId]);

  const saveDesignDocument = useCallback(async () => {
    setIsLoading(true);

    try {
      if (mode === 'process') {
        // await saveProcess(recipe);
        saveToLS(recipe);
      } else if (mode === 'design') {
        await saveDesign(recipe);
      } else {
        await saveDesignBatch(batch);
      }
      setRefreshOptions((prev) => prev + 1);
    } catch (e) {
      console.log('error while saving the document', e);
    }

    setIsLoading(false);
  }, [mode, recipe, batch]);


  console.log('DPP', { mode, recipe, thingId, processId });

  return (
    <DesignerContext.Provider value={{
      isLoading, setIsLoading,
      batches, setBatches,
      batch, setBatch,
      recipe, setRecipe,
      thingId,
      processId,
      nodes, setNodes, _onNodesChange,
      edges, setEdges, _onEdgesChange,
      loadBatch,
      batchId,
      refreshOptions, setRefreshOptions,
      mode, saveDesignDocument, createBatch,
      nodesData, setNodesData,
      edgesData, setEdgesData,
      selectedNode, setSelectedNode,
      observations, setObservations,
    }}>
      {children}
    </DesignerContext.Provider>
  );
}

export default DesignerProvider;
