import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { THING_TYPES } from '@pw/consts/thing';
import TrackedThing from './dest/TrackedThing';
import AmountThing from './forms/AmountThing';
import GeneralDestinationThing from './forms/GeneralDestinationThing';
import ServiceThing from './forms/ServiceThing';

function DestinationThingModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the Thing Item level
	const { name, type } = item ?? {};

	return (
    <ModalWithClose title={name} open={open} onClose={() => onClose()}>
      {[THING_TYPES.TRACKED].includes(type) && (
        <TrackedThing item={item} onClose={onClose} {...rest} />
      )}

      {[THING_TYPES.WASTE].includes(type) && (
        <AmountThing item={item} onClose={onClose} {...rest} />
      )}

      {[THING_TYPES.SERVICES].includes(type) && (
        <ServiceThing item={item} onClose={onClose} {...rest} />
      )}

      {[THING_TYPES.FINISHED, THING_TYPES.WIP].includes(type) && (
        <GeneralDestinationThing item={item} onClose={onClose} {...rest} />
      )}
    </ModalWithClose>
  );
}

export default DestinationThingModal;
