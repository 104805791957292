import { Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3, H5, Overline } from '@pw/components/Typography';
import { CompanyType } from '@pw/components_v2/contact/consts';
import { REQUEST_NAMES, REQUEST_TYPES } from '@pw/consts/requests';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

function LimitValues({ title, value }) {
  return (
    <Stack spacing='0.25rem'>
      <Overline>{title}</Overline>
      <H5>{value}</H5>
    </Stack>
  );
}

function LimitEntry({ item }) {
  return (
    <FlexBox className='listItem' sx={{ p: '0.5rem' }}>
      <Stack spacing='0.25rem' sx={{ flex: 1 }}>
        <Body3>
          <strong>{item?.title}</strong>
        </Body3>
        <Body3>{item?.description}</Body3>
      </Stack>

      <FlexBox sx={{ flex: 1 }}>
        <LimitValues title='Used' value={item?.value?.used} />
        <LimitValues title='Requested' value={item?.value?.requested} />
        <LimitValues title='Updated' value={item?.value?.updated} />
      </FlexBox>
    </FlexBox>
  );
}

function CompanyLimits({ limits }) {
  const { values } = useFormikContext();

  //   if (!limits) return null;
  limits = {
    [REQUEST_TYPES.CHANGE_OWNERSHIP]: {
      used: 0,
      requested: 0,
      updated: 0,
    },
  };

  const mappedLimits = Object.entries(limits).map(([key, value]) => {
    return {
      title: REQUEST_NAMES[key],
      description: `${REQUEST_NAMES[key]} limits`,
      value: value,
    };
  });

  const required = useMemo(() => {
    return [CompanyType.BROKER].includes(values?.company_type);
  }, [values?.company_type]);

  console.log('Required', required, values.company_type);

  return (
    <Stack>
      {required && (
        <>
          <FlexBox>
            <Stack spacing='0.5rem' sx={{ py: '1rem' }}>
              <H5>Limits</H5>
              <Body3>Company limits</Body3>
            </Stack>
          </FlexBox>
          <Stack className='list'>
            {mappedLimits?.map((p) => (
              <LimitEntry item={p} key={p.title} />
            ))}
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default CompanyLimits;
