import Print from '@mui/icons-material/Print';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ExplorerLocation from '@pw/components/ExplorerLocation';
import LocationQRCodes from '@pw/components/ExplorerLocation/LocationQRCodes';
import { explorerLocationFields } from '@pw/components/ExplorerLocation/explorerLocationFields';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { LOCATION_PRINT_MAP, LOCATION_PRINT_OPTIONS } from '@pw/consts/facility';
import FormikContext from '@pw/context/FormikContext';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import printElement from '@pw/utilities/printElement';
import { useContext, useMemo, useRef, useState } from 'react';

function Content({ locations = []}) {
	const [filter, setFilter] = useState(LOCATION_PRINT_MAP.ALL);
	const printRef = useRef();
	const { values } = useContext(FormikContext);
	console.log(`values `,values);
	


	const handlePrint = () => {
		const element = printRef?.current?.innerHTML;
		printElement({
			element,
			styles:
				'p{ margin-top: 0; margin-bottom: 10px;   text-overflow: ellipsis };',
		});
	};

	const currentLocation = useMemo(
		() => locations.find(({_id})=>_id === values?.location?.location_id),
		[locations, values?.location?.location_id],
	);

	const currentBay = useMemo(
		() => (currentLocation?.bays ?? []).find(
			({_id}) => _id === values?.location?.bay_id,
		), [currentLocation?.bays, values?.location?.bay_id]);
	
	const currentRow = useMemo(
		() => (currentBay?.rows ?? []).find(
			({_id}) => _id === values?.location?.row_id,
	), [currentBay?.rows, values?.location?.row_id]);

	const filterOptions = useMemo(() => {
		if (!values?.location?.location_id) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) =>
					o.value !== LOCATION_PRINT_MAP.ROWS &&
					o.value !== LOCATION_PRINT_MAP.BAYS,
			);
		}
		if (!values?.location?.bay_id) {
			return LOCATION_PRINT_OPTIONS.filter(
				(o) => o.value !== LOCATION_PRINT_MAP.ROWS,
			);
		}
		return LOCATION_PRINT_OPTIONS;
	}, [values]);

	return (
		<Stack spacing={3}>
			<FlexBox gap={2}>
				<FormikSelect
					fullWidth
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					options={filterOptions}
					label='Print'
					withNone={false}
				/>
				<TitledButton
					onClick={handlePrint}
					label='Print'
				>
					<Print height={24} width={24} />
				</TitledButton>
			</FlexBox>
			<Box ref={printRef}>
				<LocationQRCodes
					filter={filter}
					locations={locations}
					location={currentLocation}
					bay={currentBay}
					row={currentRow}
				/>
			</Box>
		</Stack>
	);
}

function Locations() {
	const changeSet = { location: explorerLocationFields({}) };

	const locations = useCompanyFacilities();

	return (
		<FormikForm changeSet={changeSet}>
			<Stack spacing={2}>
				<ExplorerLocation
					name='location'
					label='Storage Location'
					locations={locations}
					withPrintAllButton={false}
				/>
				<Divider />
				<Content locations={locations} />
			</Stack>
		</FormikForm>
	);
}

export default withAppLayout(withDialogCard(Locations));
