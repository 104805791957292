import Print from '@mui/icons-material/Print';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import QRImage from '@pw/components/QRImage';
import ScanQR from '@pw/components/ScanQR';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mq } from '@pw/styles/media';
import debounce from '@pw/utilities/debounce';
import useEncodeLocationQrCode from '@pw/utilities/hooks/logic/useEncodeLocationQrCode';
import makeNumberOptions from '@pw/utilities/makeNumberOptions';
import safeParse from '@pw/utilities/safeParse';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const styles = {
	qrCode: {
		[mq('sm', 'down')]: {
			position: 'absolute',
			top: 160,
		},
	},
	scanQrCard: {
		'.scan-card': {
			width: 'min-content',
		},
		'.scan-button': {
			width: 'min-content',
		},
		'qr-print-all-button': {
			width: 'min-content',
		},
	},
};

function LocationImpl({
	label = '',
	withPrintAllButton = true,
	previewQr = false,
	disabled = false,
	locations = [],
}) {
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { values = {}, setFieldValue } = useContext(FormikContext);

	const setLocationValues = useCallback(
		(location_id, bay_id, row_id, level_id) => {
			const found = locations.find(
				({_id}) => _id === location_id,
			);
			if (found) {
				debounce(() => {
					setFieldValue('location_id', location_id);
					setFieldValue('bay_id', bay_id);
					setFieldValue('row_id', row_id);
					setFieldValue('level_id', level_id);
				}, 25);
			}
		},
		[setFieldValue, locations],
	);

	const handleOnQrSuccess = useCallback(
		(data) => {
			const { text } = data; // 'https://test.metacask.com:4430/explorer?dt=1,2,3,4';
			const [location_id, bay_id, row_id, level_id] = decodeURIComponent(
				text.split('?dt=')[1],
			).split(',');
			setLocationValues(location_id, bay_id, row_id, level_id);
		},
		[setLocationValues],
	);

	const handlePrintAll = useCallback(() => {
		const route = `/app/locations?dt=${encodeURIComponent(
			[values.location_id, values.bay_id, values.row_id, values.level_id].join(','),
		)}`;
		navigate(route);
	}, [values?.location_id, values?.bay_id, values?.row_id, values?.level_id, navigate]);

	const encodedQrData = useEncodeLocationQrCode(
		'dt',
		values.location_id,
		values.bay_id,
		values.row_id,
		values.level_id,
	);

	useEffect(() => {
		const dt = searchParams.get('dt');

		if (dt) {
			const [location_id, bay_id, row_id, level_id] =
				decodeURIComponent(dt).split(',');
			console.log('Location', location_id, bay_id, row_id, level_id, dt);
			setLocationValues(location_id, bay_id, row_id, level_id);
		}
	}, [searchParams, setLocationValues]);

	return (
		<Stack spacing={2}>
			<Box>
				<H5 sx={{ mb: 2 }}>{label}</H5>
			</Box>

			<FlexBox sx={styles.scanQrCard} alignItems='flex-start' gap={1}>
				<Stack spacing={1}>
					<ScanQR
						onSuccess={handleOnQrSuccess}
						variant='outlined'
						color='secondary'
						width={24}
						height={24}
						withLabel
						Component={TitledButton}
					/>
					{withPrintAllButton && (
						<TitledButton
							handleClick={handlePrintAll}
							label='Print'
						>
							<Print height={24} width={24} />
						</TitledButton>
					)}
				</Stack>

				<FlexBox spacing={1}>
					<LocationSelect locations={locations} disabled={disabled} />
					{previewQr && (
						<Box sx={styles.qrCode}>
							<QRImage boxSize={72} isLogoVisible value={encodedQrData} />
						</Box>
					)}
				</FlexBox>
			</FlexBox>
		</Stack>
	);
}

export function LocationSelect({ setLocation, disabled = false, locations = [] }) {
	const { values } = useContext(FormikContext);

	const locationOptions = locations.map(({name, _id}) => ({
		label: name,
		value: _id,
	}));

	const currentLocation = useMemo(
		() => locations.find(({_id})=>_id === values?.location_id),
		[locations, values?.location_id],
	);
	
	// console.log(currentLocation);

	const bayOptions = useMemo(
		() =>
			(currentLocation?.bays ?? []).map(({_id, name}) => ({
				label: name,
				value: _id,
			})),
		[currentLocation],
	);

	// console.log(bayOptions);

	const currentBay = useMemo(
		() => (currentLocation?.bays ?? []).find(
			({_id}) => _id === values?.bay_id,
		), [currentLocation?.bays, values?.bay_id]);

	// console.log(currentBay);

	const rowOptions = useMemo(
		() => (currentBay?.rows ?? []).map(({_id, name}) => ({
			label: name,
			value: _id,
		})),
		[currentBay]
	);
	// console.log(rowOptions);

	const currentRow = useMemo(
		() => (currentBay?.rows ?? []).find(
			({_id}) => _id === values?.row_id,
		), [currentBay?.rows, values?.row_id]);

	// console.log(currentRow);

	const levelOptions = useMemo(
		() => (currentRow?.levels ?? []).map(({_id, name}) => ({
		label: name,
		value: _id,
	})), [currentRow]);

	// console.log('Level options', values?.level, levelOptions);

	useEffect(() => {
		if (setLocation) {
			setLocation({
				location_id: values?.location_id,
				bay_id: values?.bay_id,
				row_id: values?.row_id,
				level_id: values?.level_id,
			});
		}
	}, [values, setLocation]);

	return (
		<Stack spacing={2} sx={{ flexGrow: '1' }}>
			<FormikSelect
				fullWidth
				options={locationOptions}
				label='Facility'
				name='location_id'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.bay_id}
				options={bayOptions}
				label='Bay'
				name='bay_id'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.row_id}
				options={rowOptions}
				label='Row'
				name='row_id'
				disabled={disabled}
			/>

			<FormikSelect
				fullWidth
				value={values?.level_id}
				options={levelOptions}
				label='Level'
				name='level_id'
				disabled={disabled}
			/>
		</Stack>
	);
}

export default function ExplorerLocation({ name, ...rest }) {
	return (
		<FormikProvider path={name}>
			<LocationImpl {...rest} />
		</FormikProvider>
	);
}
