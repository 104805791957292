import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import {
  FormikForm,
  FormikMeasuresField,
  FormikNumberField,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import LiquidSummary from '@pw/components/SKUSelector/items/LiquidSummary';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { UNIT } from '@pw/consts/units';
import { AlertView } from '@pw/utilities/hooks/components/useAlertView';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function SourceAssetModal({ item, open, onClose }) {
  const converter = useConverter();
  const { t } = useTranslation();

  const { name, properties = {} } = item ?? {};
  const { liquid = {} } = properties;
  const { level = {} } = liquid;
  const {
    bl,
    la,
    abv,
    tcf,
    expected_bl,
    updated_bl,
    updated_abv,
    updated_tcf,
  } = level;

  const expected_value = expected_bl ?? bl;

  const changeSet = useMemo(
    () => ({
      expected_bl: [
        converter.from(expected_value, UNIT.BL)?.toFixed(3) ?? '',
        yup.number().min(0, 'Must be positive!'),
      ],
      updated_bl: [
        converter.from(updated_bl, UNIT.BL)?.toFixed(3) ?? '',
        yup.number().min(0, 'Must be positive!'),
      ],
      updated_abv: [
        converter.from(updated_abv ?? abv, UNIT.ALC)?.toFixed(2) ?? '',
        yup.number().min(0, 'Must be positive!'),
      ],
      updated_tcf: [
        updated_tcf ?? tcf ?? '1.000',
        yup.number().min(0, 'Must be positive!'),
      ],
    }),
    [converter, bl, updated_bl, expected_value],
  );

  const calculateUpdates = useCallback(
    (values) => {
      const { bl, expected_bl, updated_bl, updated_abv, updated_tcf } =
        values ?? {};
      console.log('Calculating from values', values);
      if (updated_bl) {
        const rla = converter.to(
          updated_bl * ((Number(updated_abv) * Number(updated_tcf)) / 100),
          UNIT.LA,
        );
        const rbl = converter.to(updated_bl, UNIT.BL);
        const abv = converter.to(updated_abv, UNIT.ALC);

        const isEmpty = rbl === 0;
        return [
          true,
          {
            expected_bl: converter.to(expected_bl, UNIT.BL), // how much we wanted to take out
            actual_bl: Math.max(bl - rbl, 0), // how much we took out
            updated_bl: rbl, // how much is left
            updated_la: isEmpty ? 0 : rla,
            updated_abv: isEmpty ? 0 : abv,
            updated_tcf: isEmpty ? 0 : updated_tcf,
          },
        ];
      }

      return [
        false,
        {
          ...values,
          expected_bl: converter.to(expected_bl, UNIT.BL), // how much we wanted to take out
          updated_abv: converter.to(updated_abv, UNIT.ALC), // how much we wanted to take out
        },
      ];
    },
    [converter, level],
  );

  const handleSubmit = (values) => {
    console.log('submitted', values);
    const [valid, updated_level] = calculateUpdates({ ...level, ...values });
    console.log(' --> updated', updated_level);
    // const le = Number(updated_level?.updated_bl) > 0;

    onClose({
      ...item,
      properties: {
        ...properties,
        liquid: {
          ...liquid,
          // enable: Number(updated_level?.updated_bl) > 0,
          level: {
            ...level,
            ...updated_level,
            filled_date: Date.now(),
            // enable: Number(updated_level?.updated_bl) > 0,
          },
        },
      },
      processed: valid
        ? ASSET_PROCESSED_STATUS.CONFIRMED
        : item.processed
          ? item.processed
          : ASSET_PROCESSED_STATUS.PENDING,
    });
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={name}>
      <Instructions>Adjust the disgorging settings.</Instructions>

      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <LiquidDisplay label='Available' value={bl} la={la} />

          <FlexBox alignItems='top'>
            <FormikMeasuresField
              label='Extract'
              name='expected_bl'
              unit={UNIT.BL}
              fullWidth
            />
            <FormikMeasuresField
              label='Remaining'
              name='updated_bl'
              unit={UNIT.BL}
              fullWidth
            />
          </FlexBox>

          <FlexBox alignItems='top'>
            <FormikMeasuresField
              name='updated_abv'
              unit={UNIT.ALC}
              label={t('strength')}
              fullWidth
              required
            />
            <FormikNumberField name='updated_tcf' label='TCF' fullWidth />
          </FlexBox>

          <LiquidSummary level={level} />

          <AlertView />

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small' disabled={!bl}>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default SourceAssetModal;
