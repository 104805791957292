import { useMemo, createContext, useContext } from 'react';

const NodeDataContext = createContext({});

function NodeDataContextProvider({ nodeData, saveHandler, children, batch, nodeId }) {
  const value = useMemo(() => ({
    nodeData, saveHandler, batch, nodeId
  }), [nodeData, saveHandler, batch, nodeId]);

  return (
    <NodeDataContext.Provider value={value}>
      {children}
    </NodeDataContext.Provider>
  );
}

export default NodeDataContextProvider;

export const useNodeDataContext = () => useContext(NodeDataContext);