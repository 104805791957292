import {
  AddCircle,
  CopyAllOutlined,
  Delete,
  DeleteForever,
  Error,
  Print,
} from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { AssetLog } from '@pw/components/AssetLog/AssetLogs';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import CameraFormFieldComponent from '@pw/components/Camera/CameraComponent';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Confirm from '@pw/components/Confirm';
import CreatorProps from '@pw/components/CreatorProps';
import SensorWidget from '@pw/components/Dashboard/components/SensorWidget';
import DefectItem from '@pw/components/Defect/DefectItem';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetQRCode from '@pw/components/QRImage/AssetQRCode';
import { Body1, H5 } from '@pw/components/Typography';
import Regauge from '@pw/components/icons/Regauge';
import Sample from '@pw/components/icons/Sample';
import {
  ASSET_EDIT_URL,
  ASSET_STATUS,
  ASSET_TYPES,
} from '@pw/consts/asset';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import {
  useIsAccountActive,
} from '@pw/redux/containers/User/hooks';
import { removeAssetThunk } from '@pw/redux/thunks/asset';
import { deleteAssetPhoto, getAssetPhoto } from '@pw/services/asset.service';
import styles from '@pw/styles/content.styles';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import printElement from '@pw/utilities/printElement';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function AssetSummary({ entity, refresh, showControls = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasPermission = usePermissions();

  const {
    _id: asset_id,
    type,
    status,
    properties = {},
    defect_note,
    defects = [],
    access_log = [],
  } = entity;

  const { enqueueSnackbar } = useSnackbar();
  const { readonly } = useReadOnly(entity);
  const userIsActive = useIsAccountActive();
  const showActions = useMemo(
    () => userIsActive && !readonly,
    [readonly, userIsActive],
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  const printRef = useRef();

  const isAssetDeletable = useMemo(
    () =>
      !properties?.liquid?.level?.bl &&
      [ASSET_TYPES.CASK, ASSET_TYPES.IBC, ASSET_TYPES.PALLET].includes(type) &&
	  hasPermission(PermissionKey?.[type],PermissionType.DELETE),
    [
      type,
      properties?.liquid?.level?.bl,
    ],
  );

  const isAssetDefected = useMemo(
    () => status === ASSET_STATUS.DEFECTIVE,
    [status],
  );

  const isAssetDefectable = useMemo(
    () =>
      !isAssetDefected &&
      [
        ASSET_TYPES.CASK,
        ASSET_TYPES.IBC,
        ASSET_TYPES.PALLET,
        ASSET_TYPES.CONTAINER,
        ASSET_TYPES.FILLING_TANK,
      ].includes(type),
    [type, isAssetDefected],
  );
  // console.log('isAssetDeletable ', isAssetDeletable, ' properties ', properties);

  const handlePrint = (ref) => {
    const element = ref?.current?.innerHTML;
    printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
  };

  const handleNew = () => {
    const route = ASSET_EDIT_URL[type];
    console.log('Route', type, route);
    navigate(`${route}`, { replace: true });
  };

  const handleClone = () => {
    const route = ASSET_EDIT_URL[type];
    const str = JSON.stringify(entity);
    const base = Buffer.from(str).toString('base64');
    navigate(`${route}?clone=${base}`, { replace: true });
  };

  const handleDelete = async () => {
    dispatch(removeAssetThunk(asset_id))
      .unwrap()
      .then(() => {
        const route = ASSET_EDIT_URL?.[type];
        navigate(`${route}`);
      })
      .finally(() => {
        setDeleteModal(false);
      });
  };

  const handleRegauge = () => {
    navigate(`/app/regauge?asset=${asset_id}`);
  };

  const handleSample = () => {
    navigate(`/app/sample?asset=${asset_id}`);
  };

  const handleReportDefect = () => {
    navigate(`/app/report-defect?asset=${asset_id}`);
  };

  const getAssetPhotos = async () => {
    try {
      setLoading(true);
      const res = await getAssetPhoto({ asset_id });
      setPhotos(res.images ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePhoto = async (image_key) => {
    setLoading(true);
    try {
      await deleteAssetPhoto({
        asset_id,
        image_key,
      });
      setPhotos((prevItems) => prevItems.filter((item) => item.key !== image_key));
      enqueueSnackbar(`Successfully deleted`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssetPhotos();
  }, []);

  return (
    <Stack sx={styles} className='root' spacing={3}>
      {showControls && <H5>Asset</H5>}

      <Box className='summary-qr'>
        <Box ref={printRef} className='qr'>
          <AssetQRCode asset={entity} />
        </Box>

        {showActions && (
          <IconCircleButton
            onClick={() => handlePrint(printRef)}
            variant='outlined'
            color='secondary'
          >
            <Print height={24} width={24} />
          </IconCircleButton>
        )}
      </Box>
      {showActions && isAssetDefected && (
        <Stack className='section'>
          <H5 sx={{ color: 'red' }} className='section-header'>
            Asset Defective
          </H5>
          <Body1>
            {defect_note ?? 'This asset has been marked as defective.'}
          </Body1>
          {defects.map((d, i) => (
            <DefectItem index={i} item={d} key={`${i}`} />
          ))}
        </Stack>
      )}
      {showControls && (
        <FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
          <TitledButton handleClick={handleNew} label='New'>
            <AddCircle height={24} width={24} />
          </TitledButton>

          {showActions && ![ASSET_TYPES.FILLING_TANK].includes(type) && (
            <TitledButton handleClick={handleClone} label='Clone'>
              <CopyAllOutlined height={24} width={24} />
            </TitledButton>
          )}

          {showActions && (
            <CameraFormFieldComponent
              asset_id={asset_id}
              onRefetch={getAssetPhotos}
            />
          )}

          {showActions && !isAssetDefected && isAssetDeletable && (
            <TitledButton
              handleClick={() => setDeleteModal(true)}
              label='Delete'
            >
              <Delete height={24} width={24} color='error' />
            </TitledButton>
          )}

          {showActions && isAssetDefectable && (
            <TitledButton handleClick={handleReportDefect} label='Defect'>
              <Error height={24} width={24} />
            </TitledButton>
          )}
        </FlexBox>
      )}

      {showActions &&
        !isAssetDefected &&
        [ASSET_TYPES.CASK, ASSET_TYPES.IBC, ASSET_TYPES.FILLING_TANK].includes(
          type,
        ) && (
          <FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
            <TitledButton handleClick={handleRegauge} label='Regauge'>
              <Regauge height={24} width={24} />
            </TitledButton>
            <TitledButton handleClick={handleSample} label='Sample'>
              <Sample height={24} width={24} />
            </TitledButton>
          </FlexBox>
        )}

      {showActions && <CreatorProps entity={entity} />}

      {showActions && entity?.metrics && <SensorWidget data={entity.metrics} />}

      {showActions && deleteModal && (
        <Confirm
          open={deleteModal}
          onConfirm={handleDelete}
          onCancel={() => setDeleteModal(false)}
        >
          <Body1>Deleting the asset will remove it permanently.</Body1>
        </Confirm>
      )}

      {photos?.length > 0 && (
        <Box className='section'>
          <H5 className='section-header'>Asset Images</H5>
          <Box className='inventory'>
            <Box
              className={`photos-container ${photos?.length > 0 ? 'has-photo' : null}`}
            >
              <Box className='photos'>
                {(photos ?? []).map((photo, index) => (
                  <Box className='photo-item' key={index}>
                    <img
                      key={index}
                      src={photo.dataUrl}
                      alt={`Photo ${index + 1}`}
                      className='photo-style'
                    />
                    <div className='delete'>
                      {!loading && (
                        <ButtonBase onClick={() => deletePhoto(photo.key)}>
                          <DeleteForever />
                        </ButtonBase>
                      )}
                    </div>
                  </Box>
                ))}
                {loading && (
                  <CircularProgress
                    size='10%'
                    thickness={3.5}
                    sx={{ opacity: '.65' }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <AssetLog access_log={access_log} />
    </Stack>
  );
}

export default withSmallDialogCard(AssetSummary);
