function SvgClone(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Clone_svg__clip0_145_2)' fill='currentColor'>
        <path d='M15.32 7.024c-.001-1.401-.942-2.343-2.34-2.344H2.344C.943 4.68.001 5.621 0 7.02v10.636c0 1.401.94 2.343 2.34 2.343h10.635c1.4 0 2.343-.94 2.344-2.34V10.744 7.024zM14.02 17.67c0 .675-.354 1.032-1.025 1.032H2.336c-.68 0-1.037-.361-1.038-1.044V10.811 7.02c0-.692.35-1.043 1.036-1.043h10.662c.661 0 1.025.366 1.025 1.03v10.663zM6.903 1.305c.116-.002.232-.001.348 0 .133 0 .271.002.406-.002A.648.648 0 008.309.66a.647.647 0 00-.642-.65 16.91 16.91 0 00-.45-.007 9.23 9.23 0 00-.464.01 2.118 2.118 0 00-1.33.55c-.548.49-.8 1.125-.748 1.885V2.66c0 .12-.001.221.001.322.01.37.284.654.637.659.365.006.65-.277.659-.658.003-.13.002-.263 0-.392l.001-.362c.01-.543.385-.914.93-.923v-.001zM18.698 3a.65.65 0 00.646.64h.007a.654.654 0 00.64-.632c.005-.107.004-.219.003-.327l-.002-.154.002-.13v-.136c-.01-1.177-.79-2.074-1.94-2.232a5.058 5.058 0 00-.795-.024 13.59 13.59 0 01-.23.003c-.38.004-.668.281-.669.646 0 .362.287.642.669.65.126.002.256.002.38 0h.175c.058 0 .116 0 .174.002.553.009.93.388.937.944v.325c0 .14-.002.286.003.427V3zM19.994 12.88v-.201-.289c-.006-.402-.277-.696-.645-.698h-.005c-.37 0-.642.288-.647.688-.002.107 0 .215 0 .323 0 .134.001.271-.002.408-.012.527-.388.904-.913.915-.126.004-.254.002-.377.002l-.18-.001c-.075 0-.149 0-.222.003a.644.644 0 00-.643.649c0 .358.28.636.648.645.34.009.616.008.867-.002a2.134 2.134 0 001.358-.542c.556-.49.813-1.13.76-1.9zM13.846.007c-.955-.002-1.942-.003-3.017 0-.408.001-.696.264-.7.64a.62.62 0 00.174.45c.13.132.318.206.53.207.35.002.7.002 1.049.002h.918c.344 0 .7 0 1.049-.002.401-.003.691-.276.69-.65 0-.373-.292-.645-.693-.646zM19.992 6.148c-.003-.4-.276-.688-.648-.688h-.003c-.372.002-.643.294-.644.695-.003.964-.003 1.95 0 3.017.001.402.27.697.64.7h.008c.172 0 .325-.06.442-.174a.741.741 0 00.206-.533c.002-.353.001-.707.001-1.06v-.449-.46c0-.343.001-.698-.001-1.048z' />
        <path d='M8.309 12.989h1.617c.421-.002.717-.268.718-.647.002-.38-.292-.648-.712-.65-.337-.002-.672-.002-1.02-.001H8.31v-.907-.242c.002-.183.004-.372-.003-.556a.65.65 0 00-.64-.631H7.66a.64.64 0 00-.646.635c-.007.357-.005.709-.004 1.081l.001.49v.13H6.22a35.396 35.396 0 00-.91.003.647.647 0 00-.006 1.293c.23.007.463.006.688.004l.306-.002h.713v.13c0 .17 0 .337-.002.501-.002.367-.004.713.004 1.062a.654.654 0 00.342.567c.201.11.437.104.632-.015.218-.134.324-.33.322-.602-.002-.35-.001-.7-.001-1.054v-.59z' />
      </g>
      <defs>
        <clipPath id='Clone_svg__clip0_145_2'>
          <path fill='currentColor' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgClone;
