import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getPolicy = async (params, headers = {}) =>
  fetchGet(`${BASE}/v2/get${objectToQueryString({ ...params, type: 'policy' })}`, headers);

export const createPolicy = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/create`, headers, { ...data, type: 'policy' });

export const updatePolicy = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/update`, headers, { ...data, type: 'policy' });

export const removePolicy = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/remove`, headers, { ...data, type: 'policy' });

export const listPolicies = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/list`, headers, { ...data, type: 'policy' });
