import {
  ConnectWithoutContact,
  LockReset,
  ScheduleSend,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import SvgAddContact from '@pw/components/icons/AddContact';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgAssets from '@pw/components/icons/Assets';
import SvgClone from '@pw/components/icons/Clone';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import {
  CONFIRM_PARTNER_INVITE,
  CONTACT_ASSETS,
  CUSTOMER_RESET_PASSWORD,
  INVITE_CUSTOMER,
  PARTNER_CONTACT_FORM,
  PARTNER_INVITE,
  PATH,
  SCAN_QR,
} from '@pw/components_v2/ContactView/consts';
import CompanyQRCode from '@pw/components_v2/ContactView/ContactQRCode';
import styles from '@pw/styles/content.styles';
import useModalState from '@pw/utilities/hooks/components/useModalState';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ContactAssetsDialog from './modals/ContactAssetsDialog';
import { InviteCustomerConfirmDialog } from './modals/InviteCustomerConfirmDialog';
import { InvitePartnerConfirmDialog } from './modals/InvitePartnerConfirmDialog';
import PartnerContactEditor from './modals/PartnerContactEditor';
import { ResetPasswordDialog } from './modals/ResetPasswordDialog';
import PartnerContactNavigation from './PartnerContactNavigation';
import { ConfirmPartnersInvite } from './modals/ConfirmPartnerInvite';
import { useInviteStateHook } from '@pw/redux/containers/Public';
import { UrlDataEncoder } from '@pw/utilities/UrlDataEncoder';
import { QrScannerDialog } from '@pw/components/ScanQR';
import useInviteHook from '@pw/utilities/hooks/service/useInviteHook';

function Summary({ entity: relationship, refresh }) {
  const { target_id: contact } = relationship;
  /** state */
  const { modal, openModal, closeModal } = useModalState();
  /** mems */
  const navigate = useNavigate();
  const isPartner = useMemo(() => contact?.type === 'company', [contact?.type]);
  const isCustomer = useMemo(() => contact?.type === 'person', [contact?.type]);
  const isVirtualCompany = useMemo(() => !contact?.company_type, [contact?.type]);
  const hasContact = useMemo(
    () => relationship?.contacts?.length > 0,
    [relationship?.contacts],
  );
  const isVirtualCustomer = useMemo(() => false, [contact?.type]); //TODO: need to get from model
  const hasJoined = useMemo(() => !!contact?.joined, [contact?.joined]);
  /** callbacks */
  const handleNew = useCallback(
    () => navigate(`${PATH}`, { replace: true }),
    [navigate],
  );
  const handleClone = useCallback(() => {
    const str = JSON.stringify({ ...relationship });
    const base = Buffer.from(str).toString('base64');
    navigate(`${PATH}?clone=${base}`, { replace: true });
  }, [relationship, navigate]);

  const handleEditPartner = useCallback(async (partner) => {
    const formDt = {
      contact_tag: partner.contact_tag,
      ...partner.contact_id,
    };
    console.log('pp', partner, formDt);
    openModal(PARTNER_CONTACT_FORM, formDt);
  }, []);
  // const loadInvite = useInviteHook();
  const onScan = useCallback(
    async ({ text }, extra = {}) => {
      console.log('Scan url', text, extra);
      // const state = await loadInvite({ id: text }).unwrap();
      openModal(CONFIRM_PARTNER_INVITE, {});
    },
    [openModal],
  );

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const action = searchParams?.get('action');
    const data = UrlDataEncoder.decodeData(searchParams?.get('data'));
    switch (action) {
      case CONFIRM_PARTNER_INVITE:
        /** will open the confirm partner invite  */
        openModal(CONFIRM_PARTNER_INVITE, data);
        break;
      default:
        break;
    }
  });

  return (
    <Stack sx={styles} className='root' spacing={3}>
      <H5>Contact Summary</H5>

      <Box className='summary-qr'>
        <CompanyQRCode entity={contact} />
      </Box>

      <FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
        <TitledButton handleClick={handleNew} label='New'>
          <SvgAddNew height={24} width={24} />
        </TitledButton>

        <TitledButton handleClick={handleClone} label='Clone'>
          <SvgClone height={24} width={24} />
        </TitledButton>

        <TitledButton
          handleClick={() => openModal(CONTACT_ASSETS, contact._id)}
          label='Assets'
        >
          <SvgAssets height={24} width={24} />
        </TitledButton>
        {isPartner && (
          <>
            <TitledButton
              handleClick={() => openModal(PARTNER_CONTACT_FORM, {})}
              label='Contact'
            >
              <SvgAddContact height={24} width={24} />
            </TitledButton>
            {isVirtualCompany && hasContact && (
              <TitledButton
                handleClick={() => openModal(PARTNER_INVITE, '')}
                label='Connect Partner'
              >
                <ConnectWithoutContact height={24} width={24} />
              </TitledButton>
            )}
          </>
        )}
        {isCustomer && (
          <TitledButton
            handleClick={() =>
              openModal(
                hasJoined ? CUSTOMER_RESET_PASSWORD : INVITE_CUSTOMER,
                contact,
              )
            }
            label={hasJoined ? 'Reset password' : 'Invite'}
          >
            {hasJoined ? (
              <LockReset height={24} width={24} />
            ) : (
              <ScheduleSend height={24} width={24} />
            )}
          </TitledButton>
        )}
      </FlexBox>
      {isPartner && (
        <PartnerContactNavigation
          id={relationship._id}
          contacts={relationship?.contacts ?? []}
          onEdit={handleEditPartner}
        />
      )}
      {modal.fold({
        [CUSTOMER_RESET_PASSWORD]: (state) => (
          <ResetPasswordDialog
            item={state.data}
            handleClose={() => {
              closeModal();
              refresh();
            }}
          />
        ),
        [INVITE_CUSTOMER]: (state) => (
          <InviteCustomerConfirmDialog
            item={state.data}
            relationship_id={relationship._id}
            handleClose={closeModal}
          />
        ),
        [CONTACT_ASSETS]: (state) => (
          <ContactAssetsDialog id={state.data} onClose={closeModal} />
        ),
        [PARTNER_CONTACT_FORM]: (state) => (
          <PartnerContactEditor
            entity={state.data}
            edit={!!Object.values(state.data).length}
            // disabled={!(state?.data?.isRegistered ?? true)}
            onCancel={closeModal}
            onSave={() => {
              refresh();
              closeModal();
            }}
            onBeforeSave={(values) => {
              /** attache the relation here */
              values.company_id = contact._id;
              return values;
            }}
          />
        ),
        [PARTNER_INVITE]: (state) => (
          <InvitePartnerConfirmDialog
            entity={relationship}
            onClose={closeModal}
            partner_id={state.data}
          />
        ),
        [CONFIRM_PARTNER_INVITE]: (state) => (
          <ConfirmPartnersInvite
            relationship={state?.data}
            onClose={closeModal}
          />
        ),
        [SCAN_QR]: (state) => (
          <QrScannerDialog
            closeDialog={closeModal}
            closeOnSuccess
            onSuccess={(event) => onScan(event, state.data)}
          />
        ),
      })}
    </Stack>
  );
}

export default withSmallDialogCard(Summary);
