import { createTeam, listTeams, removeTeam, updateTeam } from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const refetchTeamsThunk = createAsyncThunk(
  'teams/refetch',
  async (_, { rejectWithValue }) => {
    try {
      return await listTeams();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const upsertTeamThunk = createAsyncThunk(
  'teams/upsert',
  async (values, { rejectWithValue }) => {
    try {
      return values._id ? await updateTeam(values) : await createTeam(values);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const removeTeamThunk = createAsyncThunk(
  'teams/remove',
  async (values, { rejectWithValue }) => {
    try {
      return await removeTeam(values);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
