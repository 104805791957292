import { AutofpsSelect } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import Instructions from '@pw/components/Instructions';
import useSourceInventoryHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import ThingAuditConfirmModal from '@pw/components/ThingSelector/modals/ThingAuditConfirmModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { FormikProvider } from '@pw/providers/FormikProvider';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import { useAuditListLazyQuery } from '@pw/utilities/hooks/service/useAuditListQuery';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

function propertyFields(properties = {}) {
  const { location = { enable: false } } = properties;
  return {
    location: locationFields(location),
  };
}

function AuditAssetSelector({ assets, updateAssets, location }) {
  const { enqueueSnackbar } = useSnackbar();
  const [fetchAuditList, { isLoading }] = useAuditListLazyQuery();
  const handleGenerateAssets = useCallback(() => {
    // setLoading(true);
    fetchAuditList({ location })
      .then((res) => updateAssets(res.map((t) => toTaggedAsset(t))))
      .catch((err) => {
        console.log('Generate audit list', err);
        enqueueSnackbar(`Failed to generate audit asset list!`, {
          variant: 'error',
        });
      });
    // .finally(() => setLoading(false));
  }, [fetchAuditList, location, updateAssets, enqueueSnackbar]);

  if (!location?.location_id) return;

  return (
    <Stack spacing='2rem'>
      {assets.length === 0 && (
        <Instructions>
          Generate a list of assets to audit for this location
        </Instructions>
      )}
      {assets.length > 0 && (
        <Instructions>List of assets to audit</Instructions>
      )}

      <FlexBox>
        {assets.length === 0 && (
          <>
            <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
            {!isLoading && (
              <TitledButton handleClick={handleGenerateAssets} label='Find'>
                <AutofpsSelect />
              </TitledButton>
            )}
            {isLoading && <LoadingSmall />}
          </>
        )}
      </FlexBox>
    </Stack>
  );
}

function OwnershipAudit(props) {
  const [location, setLocation] = useState(null);

  const [
    [sourceThings],
    [sourceAssets, initSourceAssets],
    initSources,
    SourceInventory,
    SourceModals,
  ] = useSourceInventoryHook({
    title: 'Audit Inventory',
    filter: {
      asset_types: [ASSET_TYPES.CASK, ASSET_TYPES.IBC],
    },
    AssetModal: ThingAuditConfirmModal,
  });

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
      properties: propertyFields(initialValues?.properties),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
    },
    [initSources],
  );

  /**
   * Prior to saving the entity, this is called to inject in the sources
   * @type {function(*): *&{sources: *, sku_sources: *}}
   */
  const handleBeforeSave = useCallback(
    (entity) => ({
      ...entity,
      sources: {
        assets: sourceAssets,
        things: sourceThings,
      },
    }),
    [sourceThings, sourceAssets],
  );

  return (
    <>
      <RequestForm
        requestLabel='Ownership Audit'
        requestType={REQUEST_TYPES.OWNERSHIP_AUDIT}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        noClient
      >
        <FormikProvider path='properties'>
          <Location name='location' setLocation={setLocation} />
        </FormikProvider>

        <AuditAssetSelector
          location={location}
          assets={sourceAssets}
          updateAssets={initSourceAssets}
        />

        <SourceInventory />
      </RequestForm>

      <SourceModals />
    </>
  );
}

export default withAppLayout(OwnershipAudit, { title: 'Ownership Audit' });
