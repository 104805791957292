function SvgFeints(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Feints_svg__clip0_130_47)'>
        <mask
          id='Feints_svg__a'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={23}
          height={23}
        >
          <path d='M23 0H0v23h23V0z' fill='currentColor' />
        </mask>
        <g mask='url(#Feints_svg__a)'>
          <path
            d='M22.055 1.56c-1.01-.533-2.11-.78-2.957-.927-3.187-.567-6.497-.748-10.12-.559-1.47.074-3.4.214-5.306.6-.863.172-1.783.394-2.637.838C.444 1.815.131 2.136 0 2.588v17.915c.238.624.772.895 1.355 1.15 1.002.435 2.054.657 3.319.854 1.733.28 3.532.427 5.487.468.066 0 .132.009.189.025.025 0 .041 0 .066.008h2.357l1.536-.09c1.643-.099 3.574-.255 5.455-.682.813-.18 1.684-.419 2.464-.887.542-.32.78-.715.772-1.306-.016-6.144-.016-11.689 0-16.963 0-.706-.28-1.158-.945-1.511v-.008zM.97 2.235c.526-.27.994-.46 1.43-.6C3.81 1.209 5.24 1.01 6.449.88 8.961.608 11.532.55 14.325.699c1.741.09 4.025.287 6.234.92.313.09.608.213.904.328l.304.123c.107.041.205.107.287.164.124.09.296.23.288.428 0 .221-.222.36-.288.41-.189.123-.386.23-.591.312-1.01.42-2.07.633-2.974.789-2.086.361-4.32.517-7.187.517h-.518c-1.322 0-3.006-.098-4.624-.287-1.29-.148-2.801-.37-4.239-.87a5.64 5.64 0 01-.96-.436c-.223-.132-.338-.28-.33-.452 0-.115.066-.28.337-.419v.008zm1.939 19.263l-.32-.09c-.584-.165-1.183-.337-1.693-.707a.688.688 0 01-.254-.517c-.009-4.945 0-9.964 0-14.827V4.09v-.115-.033l.016-.23 2.26.83v16.954h-.01zm.632-15.59c.009-.24 0-.485 0-.724V4.69l4.214.55V6.3h-.156s-.04.017-.066.017c-.049 0-.098.008-.147.008h-3.45c-.099 0-.23-.016-.313-.107-.09-.09-.098-.221-.09-.312h.008zm.017 6.9h4.205v2.258H3.557v-2.259zm4.214 9.42l-.181-.015a31.1 31.1 0 01-3.894-.526l-.14-.025v-3.023h4.215v3.59zm0-4.246H3.565v-2.259h4.206v2.26zm0-5.84H3.565V9.88h4.206v2.26zm0-2.916H3.565v-2.26h4.206v2.26zm14.21 11.574c-.632.378-1.33.566-1.938.714-2.128.526-4.477.789-7.377.838h-.928c-.624 0-1.257 0-1.873-.016-.427 0-.854-.009-1.281-.017H8.41V5.274H9.241c1.914.106 3.877.074 6.005-.09 1.88-.149 3.31-.346 4.633-.65.583-.131 1.347-.336 2.045-.698.041-.016.074-.033.115-.05.016 0 .041-.016.066-.024l.221-.09v.238s.017.066.017.09V20.11a.727.727 0 01-.386.681l.024.009z'
            fill='currentColor'
          />
        </g>
        <path
          d='M10.21 12.913h.83v.583h-.83v1.684h-.64v-4.074h1.692v.583H10.21v1.224zM12.215 12.822h.879v.584h-.88v1.19h1.101v.584h-1.741v-4.074h1.741v.583h-1.1v1.133zM13.718 11.106h.64v4.074h-.64v-4.074zM15.402 12.223h-.008v2.949h-.575v-4.075h.805l.649 2.44h.008v-2.44h.567v4.075h-.658l-.788-2.95zM17.119 11.106h1.98v.583h-.666v3.491h-.64v-3.491h-.666v-.583h-.008zM20.24 11.056c.624 0 .945.37.945 1.027v.132h-.608v-.173c0-.287-.115-.402-.32-.402-.206 0-.32.106-.32.402 0 .296.13.518.55.887.533.469.706.805.706 1.274 0 .648-.329 1.026-.953 1.026-.624 0-.953-.37-.953-1.026v-.247h.608v.288c0 .287.131.394.329.394.197 0 .328-.107.328-.394 0-.288-.131-.518-.55-.888-.534-.468-.707-.805-.707-1.273 0-.649.32-1.027.945-1.027z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='Feints_svg__clip0_130_47'>
          <path fill='currentColor' d='M0 0h23v23H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgFeints;
