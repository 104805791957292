import * as yup from 'yup';

function assetIdFields(params = {}) {
	const { name = '', created = Date.now() } = params ?? {};
	console.log('params', params);
	return {
		name: [name, yup.string().required('Asset name is required!')],
		created: [created, yup.number().required('Date is required!')],
	};
}
export default assetIdFields;
