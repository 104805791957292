import { SendRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@pw/components/Avatar';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1, Body3 } from '@pw/components/Typography';
import UserEditor from '@pw/components_v2/CompanyView/modals/users/UserEditor';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { inviteThunk, removeUserThunk } from '@pw/redux/thunks/user';
import { COMP } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCompanyUsersQuery } from '@pw/utilities/hooks/service/useCompanyUsersQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function User({ item }) {
  const { name, public_name, email } = item?.target_id ?? {};

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="listContent"
      spacing={0.75}
    >
      <Avatar alt={name} sx={{ height: '42px', width: '42px', fontSize: '0.75rem', fontWeight: '600' }} />
      <Stack>
        <EntryTitle value={name} />
        <EntryLabel label="Username" value={public_name} />
        <EntryLabel label="Email" value={email} />
      </Stack>
    </Stack>
  );
}

function UsersView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  const { isLoading, data } = useCompanyUsersQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.USERS, PermissionType.MODIFY);

  const [users, initUsers , , upsertUsers, removeUsers] = useItemListManager({
    comp: COMP.target,
    initialData: items,
  });

  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initUsers(items);
    }
  }, [initUsers, isLoading, items]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((user) => {
      setUser(user);
      showEditor();
    }, [setUser]);

    const handleRemove = useCallback(
      (team) => {
        const body = {
          company_id: company._id,
          id: user._id,
        };
        console.log('Remove user', body);

        confirm({
          title: 'Remove User',
          content: (
            <Stack>
              <Body1>{`Remove user ${user.target_id?.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          ),
        })
        .then(() => dispatch(removeUserThunk(body)).unwrap().then(removeUsers))
        .catch(() => {});
      },
      [removeUsers],
    );

    const handleInvite = useCallback((user) => {
      confirm({
        title: 'Send Invite',
        content: <Body1>{`Invite user ${user?.target_id?.name}?`}</Body1>,
      })
      .then(() => dispatch(inviteThunk({ id: user?._id })))
      .catch(() => {});
    }, []);

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (user) => {
          console.log('Adding new user', user);
          onClose();
          setUser({
            target_id: {
              name: user
            },
          });
          showEditor();
        },
        [setUser],
      );

      return (
        <AddNewField label="Add New User" id="new_user" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit user',
        Icon: SvgEdit,
        handler: handleEdit,
      },
      {
        title: 'Invite user',
        Icon: SendRounded,
        handler: handleInvite,
        filter: (item) => !item.joined
      },
      {
        title: 'Remove user',
        Icon: SvgDelete,
        handler: handleRemove,
      },
    ];

    return (
      <ToolbarSection title="Users" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={User} items={users} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((user) => {
      console.log('User updated', user);
      if (user) {
        debounce(() => {
          upsertUsers(user);
        }, 25);
      }
      hideEditor();
      setUser(null);
    }, [setUser, upsertUsers]);

    if (!user) {
      return;
    }

    return (
      <UserEditor entry={user} onClose={handleClose} company={company} readonly={readonly} />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default UsersView;
