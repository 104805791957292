import { Divider, Grid } from '@mui/material';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import { THING_TYPES } from '@pw/consts/thing';
import { useMemo } from 'react';
import ThingEntryItemInfo from './ThingEntryItemInfo';
import CostDisplay from '@pw/components/properties/CostDisplay';
import VendorDisplay from '@pw/components/properties/VendorDisplay';

function ThingItemInfo({ thing, onSelectStorage }) {
  const {
    thing_type,
    amount,
    waste_amount,
    picked,
    properties,
    entries = [],
    total_cost,
    vendor,
    vendor_name,
  } = thing ?? {};
  const { unit } = properties ?? {};

  const uu = useMemo(
    () =>
      [
        THING_TYPES.TRACKED,
        THING_TYPES.FINISHED,
        THING_TYPES.WIP,
        THING_TYPES.SERVICES,
        THING_TYPES.CONSUMABLE,
      ].includes(thing_type)
        ? undefined
        : unit,
    [thing_type],
  );

  return (
    <>
      <AmountDisplay label='Amount' amount={amount} unit={uu} />
      <AmountDisplay label='Picked' amount={picked} unit={uu} />
      {!!waste_amount && (
        <AmountDisplay label='Waste Amount' amount={waste_amount} unit={uu} />
      )}
      {vendor && (
        <Grid item xs={12}>
          <VendorDisplay value={vendor_name} />
        </Grid>
      )}
      {total_cost && (
        <Grid item xs={6}>
          <CostDisplay
            label='Total Cost'
            value={total_cost?.value}
            code={total_cost?.unit}
          />
        </Grid>
      )}
      {entries.map((e, index) => {
        return (
          <>
            <Divider
              variant='middle'
              sx={{ opacity: 0.6 }}
              key={`__div_${index}`}
            />
            <ThingEntryItemInfo
              key={`entry_${index}`}
              item={e}
              unit={uu}
              thing_type={thing_type}
              onSelectStorage={onSelectStorage}
            />
          </>
        );
      })}
    </>
  );
}

export default ThingItemInfo;
