import { AddCircle } from '@mui/icons-material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestListItem from '@pw/components/RequestListItem';
import { H5 } from '@pw/components/Typography';
import { REQUEST_EDIT_URL, REQUEST_TYPES } from '@pw/consts/requests';
import { useNavigate } from 'react-router-dom';

function RecentRequestsWidget({ title, data = [] }) {
	const navigate = useNavigate();

	const goToItem = (params) => {
		const id = params?.request_id ?? 'new';
		const requestType = params?.request_type ?? REQUEST_TYPES.DELIVERY;
		const route = REQUEST_EDIT_URL?.[requestType];
		navigate(`${route}/${id}`);
	};

	return data.length ? (
    <Stack className='content-section'>
      <H5 className='content-section-title'>{title}</H5>
      <Stack className='content-section-content list recent-list'>
        {data.map((i) => (
          <RequestListItem
            key={i.path}
            item={i}
            buttonIcon={<ArrowForward />}
            onItemClick={() => goToItem(i)}
          />
        ))}
      </Stack>
      <FlexBox className='content-section-footer'>
        <TitledButton
          handleClick={() => goToItem({ request_type: REQUEST_TYPES.DELIVERY })}
          label='Delivery'
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
        <TitledButton
          handleClick={() => goToItem({ request_type: REQUEST_TYPES.SHIP })}
          label='Ship'
        >
          <AddCircle height={24} width={24} />
        </TitledButton>
      </FlexBox>
    </Stack>
  ) : null;
}

export default RecentRequestsWidget;
