import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import {
  FormikForm,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import SpecificationEditor from '@pw/components/SpecificationEditor';
import useSpecificationSelectorHook from '@pw/components/SpecificationSelector';
import { TIME_UNIT_OPTIONS, UNITS_OPTIONS } from '@pw/consts/units';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as yup from 'yup';

function SpecificationSelectorModal({ item, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [
    [specifications],
    SpecificationComponent,
    SpecificationModals,
  ] = useSpecificationSelectorHook({
    title: 'Specification',
    SpecificationModal: SpecificationEditor,
  });

  const handleSubmit = (entry) => {
    try {
      console.log('Setting Entry', entry);
      onClose({...item, specifications});
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title="Specification">
      <FormikForm onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
        <SpecificationComponent />

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>

      <SpecificationModals />
    </ModalWithClose>
  );
}

export default SpecificationSelectorModal;
