import * as yup from 'yup';

export default function generalInformationFields(fields = {}) {
	const {
		plantNumber = '',
		nameOfProprietor = '',
		doingBusinessAs = '',
		streetAddress = '',
		city = '',
		state = '',
		zipCode = '',
		reportType = 2,
		isFinal = false,
		reportingPeriodYear = '',
		reportingPeriodMonth = '',
	} = fields;

	return {
		plantNumber: [
			plantNumber,
			yup.string().required('Plant number is required!'),
		],
		nameOfProprietor: [
			nameOfProprietor,
			yup.string().required('Name of proprietor is required!'),
		],
		doingBusinessAs: [
			doingBusinessAs,
			yup.string().required('Business name is required!'),
		],
		streetAddress: [
			streetAddress,
			yup.string().required('Street Address is required!'),
		],
		city: [city, yup.string().required('City is required!')],
		state: [state, yup.string().required('State is required!')],
		zipCode: [zipCode, yup.string().required('Zip code is required!')],
		reportType: [reportType, yup.number().required('Report type is required!')],
		isFinal: [isFinal, yup.boolean()],
		reportingPeriodYear: [
			reportingPeriodYear,
			yup.string().required('Reporting period year is required!'),
		],
		reportingPeriodMonth: [
			reportingPeriodMonth,
			yup.string().required('Reporting period month is required!'),
		],
	};
}
