import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw/`;
/** used to get sensor data by sensor id */
export const getSensorData = async (request, headers = {}) =>
	fetchPost(`${BASE}gateway/data`, headers, request);

/** used to upsert sensor coverage locations */
export const upsertSensor = async (request, headers = {}) =>
	fetchPost(`${BASE}sensor/upsert`, headers, request);

/** used to upsert gateway info */
export const upsertGateWay = async (request, headers = {}) =>
	fetchPost(`${BASE}gateway/upsert`, headers, request);

/** use to list company gateway list with sensors */
export const listGateWay = async (params, headers = {}) =>
	fetchGet(`${BASE}gateway/list?${objectToQueryString(params)}`, headers);
