import { useCallback, useMemo } from 'react';

// Hook to check user permissions
const usePermissions = ({ user }) => {
  const permissions = useMemo(() => {
    const perms = {};
    (user?.teams ?? []).forEach(({ roles = []}) => roles.forEach(({ permissions = {} }) => Object.keys(permissions).forEach((key) => {
      if (perms.hasOwnProperty(key)) {
        const v = permissions[key];
        perms[key] = BigInt(permissions[key]).valueOf() | v;
      } else {
        perms[key] = BigInt(permissions[key]).valueOf();
      }
    })));
    return perms;
  }, [user]);

  return useCallback((key, level) => {
    return true;
    // if (!permissions.hasOwnProperty(key)) {
    //   return false;
    // }
    // const perm = permissions[key];
    // const check = BigInt(level);
    // return (perm & check) !== BigInt(0);

    // if (!accountPerms || !resource || !actions) return false;
    //
    // const permissionsForResource = accountPerms[resource];
    // if (!permissionsForResource) return false;
    //
    // // Convert permissions to BigInt
    // const userPermissions = BigInt(permissionsForResource);
    //
    // // If actions is not an array, convert it into one
    // const actionList = Array.isArray(actions) ? actions : [actions];
    //
    // if (checkAll) {
    //   // Check if the user has ALL actions (AND logic)
    //   return actionList.every((action) => (BigInt(userPermissions) & BigInt(action)) !== BigInt(0));
    // }
    //
    // // Check if the user has ANY action (OR logic)
    // return actionList.some((action) => (BigInt(userPermissions) & BigInt(action)) !== BigInt(0));
  }, [permissions]);
};

export default usePermissions;
