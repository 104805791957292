import { Place, WrongLocation } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import SvgEdit from '@pw/components/icons/Edit';
import LocationDisplay from '@pw/components/properties/LocationDisplay';
import FacilityEditor from '@pw/components_v2/CompanyView/modals/facilities/FacilityEditor';
import TeamEditor from '@pw/components_v2/CompanyView/modals/users/TeamEditor';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { FACILITY_STATUS } from '@pw/consts/facility';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { useCompanyFacilities } from '@pw/redux/containers/User';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCallback, useState } from 'react';

function Facility({ item }) {
  console.log('Facility item', item);

  const StatusIcon = useCallback(
    () =>
      item?.status === FACILITY_STATUS.ACTIVE ? <Place /> : <WrongLocation />,
    [item?.status],
  );

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing='0.25rem'
    >
      <StatusIcon />
      <Stack>
        <EntryTitle value={item?.name} />
        <EntryLabel label="Type" value={item?.type?.toLocaleLowerCase()} />
        <EntryLabel label='Bays' value={item?.bays?.length} />
      </Stack>
    </Stack>
  );
}

function FacilitiesView({ company }) {
  console.log('Showing facilities for', company.name);

  const facilities = useCompanyFacilities();

  // Get the user's permission for this company
  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.FACILITIES, PermissionType.MODIFY);

  const [facility, setFacility] = useState(null);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((facility) => {
      setFacility(facility);
      showEditor();
    }, [setFacility]);

    const handleRemove = useCallback(
      (team) => {

      },
      [],
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (name) => {
          onClose();
          setFacility({
            name,
          });
          showEditor();
        },
        [setFacility],
      );

      return (
        <AddNewField label="Add New Facility" id="new_facility" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit facility',
        Icon: SvgEdit,
        handler: handleEdit,
      },
    ];

    return (
      <ToolbarSection title="Facilities" Toolbar={Toolbar}>
        <BasicList Content={Facility} items={facilities} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((facility) => {
      console.log('Facility updated', facility);
      if (facility) {
        // debounce(() => {
        //   upsertTeams(team);
        // }, 25);
      }
      hideEditor();
      setFacility(null);
    }, [setFacility]);

    if (!facility) {
      return;
    }

    return (
      <FacilityEditor
        item={facility}
        onClose={handleClose}
        readonly={readonly}
      />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default FacilitiesView;
