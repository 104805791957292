import { Box, Collapse, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DateTimeDisplay from '@pw/components/properties/DateTimeDisplay';
import LocationDisplay from '@pw/components/properties/LocationDisplay';
import { Body2, Body3, H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { upsertUserThunk } from '@pw/redux/thunks/user';
import generateUsername from '@pw/utilities/generateUsername';
import { useContactLazyQuery } from '@pw/utilities/hooks/service/useContactQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function TeamRole({ item }) {
  const { name, roles = [] } = item;

  return (
    <Box className='listItem'>
      <Stack className='listContent' spacing={0.75}>
        <Body2><strong>{name}</strong></Body2>
        <Body3>{roles.map((r) => r.name).join(', ')}</Body3>
      </Stack>
    </Box>
  );
}

/** entry here is a Relationship to the current company */
function UserEditor({ entry, company, readonly, onClose }) {
  const dispatch = useDispatch();

  // We need to load the actual contact details from the entry..
  const [load, { isLoading, cancel }] = useContactLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = useMemo(() => ({
    name: [item?.name, yup.string().required('Name is required!')],
    public_name: [item?.public_name ?? generateUsername(), yup.string().required('Public name is required!')],
    email: [item?.email ?? '', yup.string().email().required('Email is required!')],
  }), [item]);

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?.target_id?._id) {
      load({ id: entry?.target_id?._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry?.target_id);
    }
  }, [entry]);

  const onSubmit = useCallback(async (values) => {
    dispatch(upsertUserThunk({
      company_id: company._id,
      ...item,
      ...values,
    }))
      .unwrap()
      .then(onClose);
  }, [company, item, onClose]);

  const sendInvite = useCallback(() => {

  }, [entry]);

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.target_id?.name}</H5>
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className="form-content" in={!isLoading} unmountOnExit>
        <FormikForm
          changeSet={changeSet}
          onSubmit={onSubmit}
          enableReinitialize
        >
          <Stack className="form" spacing="2rem">
            <FormikTextField name="public_name" label="Display Name" required />
            <FormikTextField name="email" label="Email" required />

            {item?.teams?.length > 0 && (
              <Stack className="section">
                <H5 className="section-title">Teams</H5>
                <Stack className='list'>
                  {item?.teams?.map((t) => (
                    <TeamRole
                      key={t._id}
                      item={t}
                    />
                  ))}
                </Stack>
              </Stack>
            )}

            {(item?.joined || item?.invited) && (
              <Stack className="section">
                <H5 className="section-title">Access</H5>
                <Stack spacing={0.5}>
                  <DateTimeDisplay label="Created" date={item?.created} />
                  {!item?.joined && item?.invited && <DateTimeDisplay label="Last Invite" date={item?.invited} />}
                  {item?.joined && (
                    <FlexBox>
                      <DateTimeDisplay label="Joined" date={item?.joined} />
                      {item?.location && (
                        <LocationDisplay name={item?.location?.country_name} />
                      )}
                    </FlexBox>
                  )}
                  <DateTimeDisplay label="Last Access" date={item?.last_access} />
                </Stack>
              </Stack>
            )}

            <FormButtons readonly={readonly} onCancel={onClose} />
          </Stack>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default UserEditor;
