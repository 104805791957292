import {
  AddBox,
  CorporateFare,
  Delete,
  Person3,
  Public,
} from '@mui/icons-material';
import { Box, Collapse } from '@mui/material';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
// import OwnerForm from '@pw/components/Company/admin/Owners/components/OwnerForm';
// import PartnerForm from '@pw/components/Company/admin/Partners/components/PartnerForm';
// import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import IDDisplay from '@pw/components/properties/IDDisplay';
import SearchField from '@pw/components/Search/SearchField';
import Switch from '@pw/components/SwitchComponent';
import { Body3, H5, H6, Overline } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { CLIENT_TYPE } from '@pw/consts/contacts';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import useNetworkSearch from '@pw/utilities/hooks/logic/useNetworkSearch';
import { useCallback, useContext, useMemo, useState } from 'react';
import TitledButton from '../Buttons/TitledButton';

export function ClientInfo({ item, onClick, icon }) {
  return (
    <Stack spacing={0.5} onClick={onClick}>
      <IDDisplay value={item?.id} />
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
        }}
      >
        {icon}
        <Body3>
          <Bolder>{`Name:`}</Bolder>
          &nbsp;
          {item?.name}
        </Body3>
      </Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          gap: '0.25rem',
        }}
      >
        <Public style={{ width: '18px', height: 'auto' }} />
        <Body3>
          <Bolder>{`Public Name:`}</Bolder>
          &nbsp;
          {item?.public_name}
        </Body3>
      </Box>
    </Stack>
  );
}

function ListItem({ item, buttonIcon = <AddBox />, onClick, icon }) {
  return (
    <Box className='listItem'>
      <Box className='listContent'>
        <ClientInfo item={item} onClick={onClick} icon={icon} />
      </Box>

      {onClick && (
        <Stack className='listButtons'>
          <IconCircleButton onClick={onClick}>{buttonIcon}</IconCircleButton>
        </Stack>
      )}
    </Box>
  );
}

function Results({
  items = [],
  onItemClick = () => {},
  title = '',
  icon = null,
}) {
  return (
    <Stack className='search-section'>
      <Stack flexDirection='row' alignItems='flex-end'>
        {title ? (
          <Overline className='search-section-title'>{title}</Overline>
        ) : null}
      </Stack>
      <Stack className='list'>
        {items.map((a) => (
          <ListItem
            item={a}
            key={a.path}
            onClick={() => onItemClick(a)}
            icon={icon}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function DisplayResults({ partners = [], customers = [], ...rest }) {
  const results = useMemo(
    () => [...partners, ...customers],
    [partners, customers],
  );

  if (results.length === 0) {
    return <Body3>Nothing matches</Body3>;
  }
  return (
    <Box className='search-result-contents'>
      {partners.length ? (
        <Results
          items={partners}
          title='Partners'
          icon={<CorporateFare style={{ width: '18px', height: 'auto' }} />}
          {...rest}
        />
      ) : null}
      {customers.length ? (
        <Results
          items={customers}
          title='Customers'
          icon={<Person3 style={{ width: '18px', height: 'auto' }} />}
          {...rest}
        />
      ) : null}
    </Box>
  );
}

export function ClientImpl({
  label = 'Client',
  title = '',
  name = 'client',
  noCustomers = false,
  disabled = false,
}) {
  /** state */
  const { values, handleChange, readonly } = useContext(FormikContext);
  const [show, setShow] = useState(false);
  // const [modal, setModal] = useState(false);
  /** api */
  const [{ ownerOptions, partnerOptions }, search] = useNetworkSearch();
  /** computed */
  const value = useMemo(() => values?.[name], [name, values]);
  const hasValue = useMemo(() => !!value?.name, [value?.name]);

  /** callbacks */
  const onItemClick = useCallback(
    (value) => {
      handleChange({
        target: { name, value: value?.id },
      });
      setShow(false);
      // setModal(false);
    },
    [handleChange, name, values],
  );

  const onCustomerSubmit = useCallback(
    (customer) =>
      onItemClick({
        id: customer?.account_id,
        hash: customer?.account_hash,
        name: customer?.account_name ?? customer?.account_username,
        public_name: customer?.account_username,
        type: CLIENT_TYPE.ACCOUNT,
      }),
    [onItemClick],
  );

  const onPartnerSubmit = useCallback(
    (partner) =>
      onItemClick({
        id: partner?.company_id ?? partner?.partner_id,
        hash: partner?.company_key_hash,
        name: partner?.company_name,
        public_name: partner?.company_public_name ?? partner?.company_name,
        type: CLIENT_TYPE.COMPANY,
      }),
    [onItemClick],
  );

  const handleOnSearch = useCallback(
    (value) => {
      // search(value);
      setShow(true);
    },
    [search],
  );

  const handleOnRemove = useCallback(
    () =>
      onItemClick({
        id: undefined,
        hash: undefined,
        name: undefined,
        public_name: undefined,
        type: undefined,
      }),
    [onItemClick],
  );

  return (
    <Stack spacing={1} className='section'>
      {title && <H6 className='section-title'>{title}</H6>}
      {!hasValue && (
        <Stack spacing={1} sx={{ width: '100%' }}>
          <Stack className='search-results' spacing='1rem'>
            <SearchField
              label='Name'
              id='name'
              disabled={disabled}
              onSearch={handleOnSearch}
              onClear={() => setShow(false)}
              fullWidth
            />
            <Collapse in={show} unmountOnExit>
              <DisplayResults
                customers={noCustomers ? [] : ownerOptions}
                partners={partnerOptions}
                onItemClick={onItemClick}
              />
            </Collapse>
          </Stack>
        </Stack>
      )}
      <Stack className='inventory'>
        {!hasValue && (
          <Box className='inventory-header'>
            <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
            <Stack direction='row' spacing={1}>
              {!noCustomers && (
                <TitledButton
                  // handleClick={() => setModal(CLIENT_TYPE.ACCOUNT)}
                  label='Customer'
                >
                  <Person3 height={24} width={24} />
                </TitledButton>
              )}
              <TitledButton
                // handleClick={() => setModal(CLIENT_TYPE.COMPANY)}
                label='Partner'
              >
                <CorporateFare height={24} width={24} />
              </TitledButton>
            </Stack>
          </Box>
        )}

        {hasValue ? (
          <Stack className='list'>
            <Box className='search-result-contents'>
              <Box className='listItem'>
                <Box className='listContent'>
                  <Stack spacing={0.75}>
                    <Switch value={value?.type ?? CLIENT_TYPE.COMPANY}>
                      <Switch.Case condition={CLIENT_TYPE.ACCOUNT}>
                        <ClientInfo
                          item={value}
                          icon={
                            <Person3
                              style={{ width: '18px', height: 'auto' }}
                            />
                          }
                        />
                      </Switch.Case>
                      <Switch.Case condition={CLIENT_TYPE.COMPANY}>
                        <ClientInfo
                          item={value}
                          icon={
                            <CorporateFare
                              style={{ width: '18px', height: 'auto' }}
                            />
                          }
                        />
                      </Switch.Case>
                    </Switch>
                  </Stack>
                </Box>
                <Stack className='listButtons'>
                  {!readonly && (
                    <IconCircleButton onClick={handleOnRemove}>
                      <Delete />
                    </IconCircleButton>
                  )}
                </Stack>
              </Box>
            </Box>
          </Stack>
        ) : (
          <Box p={2} className='inventory-footer'>
            <Body3>None</Body3>
          </Box>
        )}
      </Stack>
      {/* <Switch value={modal}>
        <Switch.Case condition={CLIENT_TYPE.ACCOUNT}>
          <ModalWithClose open onClose={() => setModal(false)}>
            <OwnerForm
              user={{}}
              onCancel={() => setModal(false)}
              onSubmit={onCustomerSubmit}
            />
          </ModalWithClose>
        </Switch.Case>
        <Switch.Case condition={CLIENT_TYPE.COMPANY}>
          <ModalWithClose open onClose={() => setModal(false)}>
            <PartnerForm
              partner={{}}
              onCancel={() => setModal(false)}
              onSubmit={onPartnerSubmit}
            />
          </ModalWithClose>
        </Switch.Case>
      </Switch> */}
    </Stack>
  );
}

export default function Client({
  label = 'Client',
  name = 'client',
  withEnable = true,
  ...rest
}) {
  const { values } = useContext(FormikContext);
  return withEnable ? (
    <Stack>
      <FormikProvider path={name}>
        <FormikCheckBox name='enable' label={<H5>{label}</H5>} />
      </FormikProvider>
      <Collapse in={!!values?.[name]?.enable}>
        <ClientImpl {...rest} name={name} />
      </Collapse>
    </Stack>
  ) : (
    <ClientImpl {...rest} name={name} />
  );
}
