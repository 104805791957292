import { Stack } from '@mui/material';
import { FormikCountrySelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

function AddressImpl() {
  return (
    <Stack spacing='1.5rem'>
      <H5>Address</H5>

      <FormikTextField
        title='address_2'
        name='address_2'
        label='Building'
        fullWidth
      />
      <FormikTextField
        title='address_1'
        name='address_1'
        label='Street/Number'
        fullWidth
        required
      />

      <FlexBox alignItems='top'>
        <FormikTextField
          title='city'
          name='city'
          label='City'
          fullWidth
          required
        />
        <FormikTextField
          title='state'
          name='state'
          label='State'
          fullWidth
        />
      </FlexBox>

      <FlexBox alignItems='top'>
        <FormikTextField
          title='postCode'
          name='post_code'
          label='Postal Code'
          fullWidth
          required
        />
        <FormikCountrySelect
          name='country'
          label='Country'
          valueField="key"
          fullWidth
          required
        />
      </FlexBox>
    </Stack>
  );
}

function Address({
  name = 'address',
}) {
  return (
    <FormikProvider path={name}>
      <AddressImpl />
    </FormikProvider>
  );
}

export default Address;
