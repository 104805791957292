import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3, H5 } from '@pw/components/Typography';
import { useCallback, useEffect, useState } from 'react';

import { Connector } from '@pw/components/BatchUpload/Wizard';
import DropZone from '@pw/components/BatchUpload/components/DropZone';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import AssetSummaryList from '@pw/components/ThingSelector/items/AssetSummaryList';
import { ASSET_TYPES } from '@pw/consts/asset';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import debounce from '@pw/utilities/debounce';
import useHeaderMapper from '@pw/utilities/hooks/ai/useHeaderMapper';
import useIsMobile from '@pw/utilities/hooks/logic/useIsMobile';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import useProgressBar from '@pw/utilities/hooks/logic/useProgressBar';
import { useSearchLazyQuery } from '@pw/utilities/hooks/service/useSearchQuery';
import { enqueueSnackbar } from 'notistack';
import { ModalWithClose } from '../Dialogs/ModalWithClose';
import useDateParser from '@pw/utilities/hooks/logic/useDateParser';

const steps = ['Upload File', 'Processing', 'Asset Preview'];

function InteractivePrompt({ data, assetAdapter, upsert, back }) {
  const spec = {
    abv: 'abv',
    rw_asset_id: 'cask number, cask id, cask no.',
    rw_parent_asset_id: 'pallet number, pallet id, pallet no.',
    reference: 'cask number',
    la: 'cask lpa, lpa, la, litres of alcohol, total lpa, total la',
    bl: 'cask volume, bulk, bulk litres, total bulk, cask bulk',
    tcf: 'tcf, temperature correction factor',
    tare_weight: 'empty weight, tare weight',
    gross_weight: 'gross weight',
    cask_type: 'cask type, cask size, cask capacity',
    liquid_type: 'liquid type, spirit type',
    fill_date: 'fill date, fill timestamp, fill datetime, age of spirit, age',
  };

  const [search] = useSearchLazyQuery({});
  const { dateParse } = useDateParser();
  const { mapHeaders } = useHeaderMapper(spec);

  const [panels, setPanels] = useState([]);

  const [ProgressBar, { setProgress, setTotal }] = useProgressBar({
    total: data.length,
  });

  const createPanel = (p) => (
    <Alert
      severity={p.type ?? 'info'}
      action={p.action ?? false}
      variant='outlined'
    >
      {p.title && <AlertTitle>{p.title}</AlertTitle>}
      <Body3>{p.message}</Body3>
    </Alert>
  );

  const addPanel = useCallback(
    (panel, clear = false) => {
      setPanels((prev) => [panel, ...(clear ? [] : prev)]);
    },
    [setPanels],
  );

  const applyCask = (
    cask,
    fill_date,
    abv,
    bl,
    la,
    tcf = '1.000',
    tare_weight,
    gross_weight,
  ) => {
    const { properties } = cask;
    const { liquid } = properties ?? {};
    const { level } = liquid ?? {};

    let {
      updated_abv,
      updated_bl,
      updated_la,
      updated_tcf = '1.000',
      weightFactor,
    } = level ?? {};

    if (abv && (bl || la)) {
      updated_abv = Number(abv);
      if (updated_abv < 1.0) {
        updated_abv *= 100.0;
      }
      updated_bl = bl ? Number(bl) : null;
      updated_la = la ? Number(la) : null;

      if (!updated_bl) {
        updated_bl = updated_la / ((updated_abv / 100.0) * Number(tcf));
      }
      if (!updated_la) {
        updated_la = updated_bl * (updated_abv / 100.0) * Number(tcf);
      }
      console.log(
        ' --> abv',
        abv,
        updated_abv,
        'bl',
        bl,
        updated_bl,
        'la',
        la,
        updated_la,
      );
    }

    if (tare_weight && gross_weight) {
      if (!weightFactor && !updated_abv && !updated_tcf) {
        throw new Error(
          'Weight factors not found, please select source first!',
        );
      }

      const netWeight = Number(gross_weight) - Number(tare_weight);
      updated_bl = Number(weightFactor?.weightFactor ?? 0) * netWeight;
      updated_la = updated_bl * (updated_abv / 100.0) * Number(updated_tcf);
    }

    if (!updated_bl || !updated_la) {
      throw new Error('No cask data found!');
    }

    const date = fill_date ? dateParse(fill_date) : null;
    if (!date) {
      throw new Error('No fill date found!');
    }

    return {
      ...cask,
      properties: {
        ...(cask?.properties || {}),
        liquid: {
          enable: true,
          ...(cask?.properties?.liquid || {}),
          date: date.valueOf(),
          level: {
            enable: true,
            ...(cask?.properties?.liquid?.level || {}),
            updated_abv,
            updated_bl,
            filled_bl: updated_bl,
            updated_la,
            updated_tcf,
            filled_date: date.valueOf(),
            tearWeight: tare_weight,
            grossWeight: gross_weight,
          },
        },
      },
    };
  };

  const load = useCallback(
    async (headerMapper) => {
      const parentAssetIds = [];
      let loadedAssets = [];

      // Get the filtered assets
      const filteredData = data.filter((d) => headerMapper(d, 'rw_asset_id'));
      setTotal(filteredData.length);

      addPanel({ message: `Processing ${filteredData.length} assets...` });
      let haveErrors = false;
      let index = 0;
      for await (const asset of filteredData) {
        const rw_asset_id = headerMapper(asset, 'rw_asset_id');
        const rw_parent_asset_id = headerMapper(asset, 'rw_parent_asset_id');
        const reference = headerMapper(asset, 'reference');
        const la = Number(headerMapper(asset, 'la'));
        const bl = Number(headerMapper(asset, 'bl'));
        const abv = Number(headerMapper(asset, 'abv'));
        const tcf = Number(headerMapper(asset, 'tcf'));
        const tare_weight = Number(headerMapper(asset, 'tare_weight'));
        const gross_weight = Number(headerMapper(asset, 'gross_weight'));
        const fill_date = headerMapper(asset, 'fill_date');

        console.log(
          'asset',
          rw_asset_id,
          rw_parent_asset_id,
          reference,
          fill_date,
          la,
          bl,
          abv,
          tcf,
          tare_weight,
          gross_weight,
        );

        if (
          rw_parent_asset_id &&
          !parentAssetIds.includes(rw_parent_asset_id)
        ) {
          parentAssetIds.push(rw_parent_asset_id);
        }

        // Validate the fill data
        if (!fill_date) {
          addPanel({
            message: `Fill date not found for ${rw_asset_id}`,
            type: 'error',
          });
          haveErrors = true;
          setProgress(index + 1);
          index++;
          continue;
        }

        if (!la && !bl) {
          if (!tare_weight && !gross_weight) {
            addPanel({
              message: `Weight data not found for ${rw_asset_id}`,
              type: 'error',
            });
            haveErrors = true;
            setProgress(index + 1);
            index++;
            continue;
          }
        } else {
          if (!abv) {
            addPanel({
              message: `ABV not found for ${rw_asset_id}`,
              type: 'error',
            });
            haveErrors = true;
            setProgress(index + 1);
            index++;
            continue;
          }
          if (!la && !bl) {
            addPanel({
              message: `Liquid data not found for ${rw_asset_id}`,
              type: 'error',
            });
            haveErrors = true;
            setProgress(index + 1);
            index++;
            continue;
          }
        }

        const { assets } = await search({
          asset_types: [ASSET_TYPES.CASK],
          search: rw_asset_id,
        });
        console.log(' --> response', assets);
        if (assets && assets[ASSET_TYPES.CASK]?.length === 1) {
          const caskInfo = assets[ASSET_TYPES.CASK][0];

          // One more check
          if (
            caskInfo.reference &&
            caskInfo.reference !== 'undefined' &&
            caskInfo.reference !== reference
          ) {
            addPanel({
              message: `Cask ${rw_asset_id}, already has reference ${caskInfo.reference}!`,
              type: 'error',
            });
            haveErrors = true;
            setProgress(index + 1);
            index++;
            continue;
          }
          // Check if it's already filled
          if (
            caskInfo.properties?.liquid?.level?.bl &&
            Number(caskInfo.properties?.liquid?.level?.bl) > 0
          ) {
            addPanel({
              message: `Cask ${rw_asset_id}, already filled!`,
              type: 'error',
            });
            haveErrors = true;
            setProgress(index + 1);
            index++;
            continue;
          }

          const cask = assetAdapter({
            id: caskInfo.id,
            path: caskInfo.path,
            rw_asset_id,
            asset_type: ASSET_TYPES.CASK,
            asset_status: caskInfo.asset_status,
            ...(rw_parent_asset_id
              ? { parent_asset_id: rw_parent_asset_id }
              : {}),
            parent_asset_id: undefined,
            parent_asset: undefined,
            properties: {
              ...(caskInfo?.properties || {}),
              reference,
            },
          });

          // This will fix this asset and include the bits we need..
          console.log(' --> cask', cask);
          try {
            loadedAssets.push(
              applyCask(
                cask,
                fill_date,
                abv,
                bl,
                la,
                tcf,
                tare_weight,
                gross_weight,
              ),
            );
            console.log(' --> asset', rw_asset_id, cask.path);
          } catch (error) {
            console.error(' --> Error processing asset', rw_asset_id, error);
            addPanel({
              message: `Failed to process asset, ${rw_asset_id}: ${error.message}`,
              type: 'error',
            });
            haveErrors = true;
          }
        } else {
          console.log('--> asset not found', rw_asset_id);

          // Asset not found
          addPanel({
            message: `Asset not found: ${rw_asset_id}`,
            type: 'error',
          });
        }
        setProgress(index + 1);
        index++;
      }

      if (parentAssetIds.length > 0) {
        addPanel({
          message: `Processing ${parentAssetIds.length} parent assets...`,
        });

        // Load the parent assets
        setTotal(parentAssetIds.length);
        index = 0;
        for (const parentAssetId of parentAssetIds) {
          const { assets } = await search({
            asset_types: [ASSET_TYPES.PALLET],
            search: parentAssetId,
          });
          console.log(' --> parentAssets', parentAssetId, assets);
          if (assets && assets[ASSET_TYPES.PALLET]?.length === 1) {
            const pallet = assets[ASSET_TYPES.PALLET][0];
            const children = [];
            // Find all the child assets and set it..
            loadedAssets = loadedAssets.map((asset) => {
              if (asset.parent_asset_id === parentAssetId) {
                children.push({
                  asset_type: ASSET_TYPES.CASK,
                  rw_asset_id: asset.rw_asset_id,
                  asset_id: asset.asset_id,
                });
                return {
                  ...asset,
                  parent_asset_id: pallet.path,
                  parent_asset: {
                    asset_type: ASSET_TYPES.PALLET,
                    rw_asset_id: parentAssetId,
                  },
                };
              }
              return asset;
            });
            loadedAssets.push(
              toTaggedAsset({
                id: pallet.id,
                path: pallet.path,
                rw_asset_id: parentAssetId,
                asset_type: ASSET_TYPES.PALLET,
                asset_status: pallet.asset_status,
                children,
              }),
            );
          } else {
            console.log('--> asset not found', parentAssetId);

            // Asset not found
            addPanel({
              message: `Asset not found: ${parentAssetId}`,
              type: 'error',
            });
          }
          setProgress(index + 1);
          index++;
        }
      }

      if (!haveErrors) {
        return loadedAssets;
      } else {
        throw new Error('Please fix the errors and try again!');
      }
    },
    [data],
  );

  const handleLoad = useCallback(
    (mapper) => {
      load(mapper)
        .then((assets) => {
          addPanel({ message: `Loaded ${assets.length} assets.` });
          upsert(assets);
        })
        .catch((error) => {
          console.error('Error loading assets', error);
          addPanel({
            message: `Looks like we couldn't load the assets in this file, please try again!`,
            type: 'error',
            action: (
              <Button color='inherit' size='small' onClick={back}>
                BACK
              </Button>
            ),
          });
        });
    },
    [data],
  );

  const retry = useCallback(() => {
    addPanel({ message: 'Processing headers...' }, true);

    mapHeaders(data)
      .then(([mapper, mappedHeaders]) => {
        console.log('headerMapper', mappedHeaders);

        // Check we have everything we need
        if (!mappedHeaders['rw_asset_id']) {
          addPanel({
            message: `We couldn't find the cask number in the file, please try again!`,
            type: 'error',
            action: (
              <Button color='inherit' size='small' onClick={retry}>
                RETRY
              </Button>
            ),
          });
          return;
        }

        if (!mappedHeaders['la'] && !mappedHeaders['bl']) {
          if (!mappedHeaders['tare_weight'] && !mappedHeaders['gross_weight']) {
            addPanel({
              message: `We couldn't find liquid or weights data in the file!`,
              type: 'error',
              action: (
                <Button color='inherit' size='small' onClick={retry}>
                  RETRY
                </Button>
              ),
            });
            return;
          } else {
            // We're using weights, need to make sure that we have a weight factor and bav values...
          }
        } else {
          // We need liquid or weights and abv
          if (!mappedHeaders['abv']) {
            addPanel({
              message: `We couldn't find the abv in the file, please try again!`,
              type: 'error',
              action: (
                <Button color='inherit' size='small' onClick={retry}>
                  RETRY
                </Button>
              ),
            });
            return;
          }
        }
        if (!mappedHeaders['rw_parent_asset_id']) {
          addPanel({
            message: `We couldn't find the pallet number in the file!`,
            type: 'warning',
            action: (
              <Button color='inherit' size='small' onClick={retry}>
                RETRY
              </Button>
            ),
          });
        }
        if (!mappedHeaders['fill_date']) {
          addPanel({
            message: `We couldn't find the fill date in the file, we will use today!`,
            type: 'warning',
            action: (
              <Button color='inherit' size='small' onClick={retry}>
                RETRY
              </Button>
            ),
          });
        }

        addPanel({
          message:
            'Looks like we were able to find all the elements we needed, process upload?',
          action: (
            <Button
              color='inherit'
              size='small'
              onClick={() => handleLoad(mapper)}
            >
              GO
            </Button>
          ),
        });
      })
      .catch((error) => {
        console.error('Error processing headers', error);
        addPanel({
          message: `Looks like we couldn't process the headers in this file, please try again!`,
          type: 'error',
          action: (
            <Button color='inherit' size='small' onClick={back}>
              BACK
            </Button>
          ),
        });
      });
  }, [data]);

  useEffect(() => {
    console.log('data', data);

    // Grab the headers and then map data to the headers
    debounce(() => retry(), 250);
  }, [retry]);

  return (
    <Box className='inventory'>
      <H5 className='inventory-header'>Process Upload</H5>
      <ProgressBar />
      <Box className='inventory-contents'>
        <Stack className='list' spacing={0.3}>
          {panels.map((v) => createPanel(v))}
        </Stack>
      </Box>
    </Box>
  );
}

function UploadAssetsModal({ open, assetAdapter, onClose }) {
  const [assets, init, , upsert, removeAsset] = useItemListManager();
  const [activeStep, setActiveStep] = useState(0);
  const [sheetData, setSheetData] = useState(null);

  const isMobile = useIsMobile();

  const handleSubmit = useCallback(() => {
    onClose(assets);
    // beforeClose();
  }, [assets]);

  const handleData = useCallback(
    async ({ data }) => {
      console.log('data', data);
      const { data: assetList } = data;

      if (!assetList || assetList.length === 0) {
        enqueueSnackbar('No assets found', { variant: 'error' });
        return;
      }

      debounce(() => {
        setSheetData(assetList);
        setActiveStep(1);
      }, 250);
    },
    [setSheetData, setActiveStep],
  );

  const handleUpsert = useCallback(
    (assets) => {
      upsert(assets);
      setActiveStep(2);
    },
    [upsert, setActiveStep],
  );

  return (
    <ModalWithClose title='Upload Assets' open={open} onClose={() => onClose()}>
      <Box sx={{ marginBottom: '1rem' }}>
        <Stepper
          alternativeLabel={isMobile}
          activeStep={activeStep}
          connector={<Connector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel className='stepIcon'>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && <DropZone setData={handleData} />}
      {activeStep === 1 && (
        <InteractivePrompt
          data={sheetData}
          assetAdapter={assetAdapter}
          upsert={handleUpsert}
          back={() => setActiveStep(0)}
        />
      )}
      {activeStep === 2 && (
        <AssetSummaryList items={assets} onRemove={removeAsset} />
      )}
      <FlexBox sx={{ marginTop: '1rem', justifyContent: 'flex-end' }}>
        <TextButton
          size='small'
          handleClick={() => onClose()}
          color='secondary'
        >
          Cancel
        </TextButton>
        <FilledButton
          handleClick={handleSubmit}
          size='small'
          disabled={assets.length === 0}
        >
          OK
        </FilledButton>
      </FlexBox>
    </ModalWithClose>
  );
}

export default UploadAssetsModal;
