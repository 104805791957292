import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import LoadingSmall from '@pw/components/Loading/LoadingSmall';
import { Body2 } from '@pw/components/Typography';
import { connectWithPartnerThunk } from '@pw/redux/thunks/partners';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

export function ConfirmPartnersInvite({ relationship, onClose }) {
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);
  const [uiState, setUiState] = useState(1);

  useEffect(() => {
    relationship._id && loadMatchingPartners();
    async function loadMatchingPartners() {
      dispatch(
        connectWithPartnerThunk({
          id: relationship._id,
          type: 'load_partners',
        }),
      )
        .unwrap()
        .then(({ partners = [] }) => {
          setPartners(partners);
          setUiState(2);
        });
    }
  }, []);

  const changeSet = {
    existing_contact: ['', yup.string()],
  };

  const handleConfirm = useCallback(
    (values) => {
      const params = {
        type: 'connect_partners',
        partner: relationship._id, // this is their details and should always be here
        existing_contact: values?.existing_contact, // this is our details for them (if we have it, else null)
      };
      console.log('Connecting...', params);
      dispatch(connectWithPartnerThunk(params)).unwrap().then(onClose);
    },
    [onClose, dispatch, partners],
  );

  return (
    <ModalWithClose open onClose={() => onClose()} title='Confirm Connection'>
      <FormikForm changeSet={changeSet} onSubmit={handleConfirm}>
        {uiState === 1 && (
          <LoadingSmall message='Checking intended company...' />
        )}
        {uiState === 2 && (
          <>
            {partners && partners.length > 1 && (
              <Stack spacing={2}>
                <Instructions>
                  Multiple partners match, please select one that is{' '}
                  <strong>{relationship.target_id.name}</strong>
                </Instructions>
                <FormikSelect
                  name='existing_contact'
                  label='Matching Partner'
                  options={partners.map((c) => ({
                    value: c._id,
                    label: c?.target_id?.name,
                  }))}
                />
              </Stack>
            )}
            {partners && partners.length === 1 && (
              <Body2>
                Linking <strong>{relationship.company_id.name}</strong> to{' '}
                <strong>{partners[0].target_id?.name}</strong>?
              </Body2>
            )}
            {partners && partners.length === 0 && (
              <Body2>
                Connect to <strong>{relationship.company_id.name}</strong>?
              </Body2>
            )}
            {!partners && <LoadingSmall message='Checking partners...' />}
          </>
        )}
        <Box className='action-buttons'>
          <TextButton
            size='small'
            handleClick={() => onClose()}
            color='secondary'
          >
            Cancel
          </TextButton>
          <FilledButton type='submit' size='small' disabled={uiState !== 2}>
            Connect
          </FilledButton>
        </Box>
      </FormikForm>
    </ModalWithClose>
  );
}
