import { FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import { FormikProvider } from '@pw/providers/FormikProvider';

export default function SpiritReport({
	name,
	title = 'Part 2',
	disabled = false,
}) {
	return (
		<FormikProvider path={name}>
			<H4>{title}</H4>
			<FlexBox alignItems='top'>
				<FormikTextField
					name='label'
					label='Spirits Transactions'
					fullWidth
					disabled={disabled}
				/>
				<FormikTextField
					name='proofGallons'
					label='Proof Gallons'
					fullWidth
					disabled={disabled}
				/>
			</FlexBox>
		</FormikProvider>
	);
}
