import * as yup from 'yup';

export default function generalInformationFields(fields = {}) {
	const {
		PROPRIETOR = '',
		DBA = '',
		STREET = '',
		CITY = '',
		PLANT_NUMBER = '',
		STATE = '',
		ZIP = '',
		RETURNTYPE = '1',
		RETURN_FINAL = false,
		PERIOD_YEAR = '',
		PERIOD_MONTH = '',
	} = fields;

	return {
		PROPRIETOR: [
			PROPRIETOR,
			yup.string().required('Name of proprietor is required!'),
		],
		DBA: [DBA, yup.string()],
		STREET: [STREET, yup.string().required('Street Address is required!')],
		CITY: [CITY, yup.string().required('City is required!')],
		PLANT_NUMBER: [
			PLANT_NUMBER,
			yup.string().required('Plant number is required!'),
		],
		STATE: [STATE, yup.string().required('State is required!')],
		ZIP: [ZIP, yup.string().required('Zip code is required!')],
		RETURNTYPE: [RETURNTYPE, yup.number().required('Report type is required!')],
		RETURN_FINAL: [
			RETURN_FINAL,
			yup.boolean().required('Final status is required!'),
		],
		PERIOD_YEAR: [
			PERIOD_YEAR,
			yup.string().required('Reporting period year is required!'),
		],
		PERIOD_MONTH: [
			PERIOD_MONTH,
			yup.string().required('Reporting period month is required!'),
		],
	};
}
