import { ArrowForward, Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import LabelDisplay from '@pw/components/properties/LabelDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import QRImage from '@pw/components/QRImage';
import { COMPANY_TYPE_REVERSE } from '@pw/consts/company';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';
import { PATH } from './consts';

export function CompanyListItemComponent({ item }) {
  const { _id, company_id: company } = item ?? {};
  const { _id: company_id, name, type } = company;

  const openCompany = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      window.open(`${PATH}/${company_id}`, '_blank').focus();
    },
    [item],
  );

  return (
    <Stack direction="row" spacing={1}>
      <Box sx={{ padding: '3px', border: '1px solid #3f3f3f', borderRadius: '3px' }}>
        <QRImage size={56} boxSize={56} value={_id} />
      </Box>
      <Stack>
        <NameDisplay name={name} />
        <IDDisplay onClick={openCompany} value={company_id} />
        <LabelDisplay label="Profile" value={COMPANY_TYPE_REVERSE[type]} />
      </Stack>
    </Stack>
  );
}

const CompanyListItem = forwardRef(
  (
    {
      item,
      onClick,
      selected,
      onEdit,
      onRemove,
      buttonIcon = <ArrowForward />,
    },
    ref,
  ) => {
    const { readonly } = useContext(FormikContext);

    return (
      <Box className='listItem' ref={ref}>
        {selected && selected(item) && (
          <Box className='listSelected'>
            <TaskOutlined className='check' />
          </Box>
        )}
        <Box className='listContent'>
          <Stack spacing={0.75}>
            <CompanyListItemComponent item={item} />
          </Stack>
        </Box>

        <Stack className='listButtons'>
          {!readonly && onClick && (
            <IconCircleButton onClick={() => onClick(item)}>
              {buttonIcon}
            </IconCircleButton>
          )}
          {!readonly && onEdit && (
            <IconCircleButton onClick={() => onEdit(item)}>
              <Edit />
            </IconCircleButton>
          )}
          {!readonly && onRemove && (
            <IconCircleButton onClick={() => onRemove(item)}>
              <Delete />
            </IconCircleButton>
          )}
        </Stack>
      </Box>
    );
  },
);

export default CompanyListItem;
