import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import { ArrowComponent } from '@pw/components/Pagination';
import { Body3, H5 } from '@pw/components/Typography';
import { ASSET_NAMES, ASSET_TYPES } from '@pw/consts/asset';
import toPairs from 'lodash.topairs';
import { useMemo } from 'react';
import { SearchAssetListItemComponent } from '../AssetListItem/SearchAssetListItemComponent';
import { FlexBox } from '../Layout/FlexBox';
import ListItemSkeleton from '../sekeletons/ListItemSkeleton';
import {
  useAssetsByThingQuery,
  useAssetsByThingPaginated,
} from '@pw/utilities/hooks/service/useAssetsByThingQuery';

function AssetEntry({
  assetType,
  thing_id,
  thing_type,
  items,
  buttonIcon,
  onItemClick,
}) {
  const reqParams = useMemo(() => {
    return {
      id: thing_id,
      type: thing_type,
      assetType,
      limit: 5,
    };
  }, [assetType, thing_id, thing_type]);

  const [isLoading, page, hasPrev, loadPrev, hasNext, loadNext] =
    useAssetsByThingPaginated(reqParams, items);

  return (
    <Stack>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <H5>{ASSET_NAMES[assetType]} List</H5>
        <ArrowComponent
          hasNextPage={hasNext}
          hasPrevPage={hasPrev}
          onPressNextPage={loadNext}
          onPressPrevPage={loadPrev}
          size={'small'}
        />
      </Stack>

      {!isLoading && page && page.length > 0 && (
        <Stack className='list'>
          {page.map((a) => (
            <AssetListComponentV2
              item={a}
              key={a._id}
              buttonIcon={buttonIcon}
              onClick={onItemClick}
            />
          ))}
        </Stack>
      )}
      {!isLoading && (!page || page.length === 0) && (
        <Body3>Nothing yet.</Body3>
      )}
      {isLoading && (
        <Stack spacing={0.5}>
          <Stack className='list'>
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
            <ListItemSkeleton type={assetType} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

function ThingAssets({ thing_id, thing_type, ...rest }) {
  const { data: { assets } = { assets: {} }, isLoading } =
    useAssetsByThingQuery({
      id: thing_id,
      type: thing_type,
    });
  // const { assets } = data;
  const assetCount = useMemo(
    () =>
      toPairs(assets)
        .map(([, l]) => l.length)
        .reduce((c, v) => c + v, 0),
    [assets],
  );
  if (!isLoading && assetCount === 0) {
    return <Body3>Nothing matches</Body3>;
  }
  return (
    <Box className='search-result-contents'>
      {isLoading && (
        <Stack spacing={0.5}>
          <FlexBox justifyContent='flex-end' gap='2rem'>
            <Skeleton variant='rectangular' width={45} height={45} />
            <Skeleton variant='rectangular' width={60} height={35} />
          </FlexBox>
          <Stack className='list'>
            <ListItemSkeleton type={ASSET_TYPES.CASK} />
            <ListItemSkeleton type={ASSET_TYPES.CASK} />
          </Stack>
        </Stack>
      )}
      {assetCount > 0 && !isLoading && (
        <Stack className='search-section' spacing={0.75}>
          {toPairs(assets).map(([t, l]) => (
            <AssetEntry
              key={t}
              assetType={t}
              items={l}
              thing_id={thing_id}
              thing_type={thing_type}
              {...rest}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}

export default ThingAssets;
