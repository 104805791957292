import { Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import DescriptionDisplay from '@pw/components/properties/DescriptionDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { THING_EDIT_URL, THING_TYPE_REVERSE } from '@pw/consts/thing';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';
import { Body2 } from '../Typography';

export function ThingDisplay({ item = {}, display = null }) {
  const { id, _id, name, type, description } = item ?? {};
  console.log('thing display', item);
  const thing_id = id || _id;
  const openThing = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const route = THING_EDIT_URL?.[item?.type];
      console.log('Routing to', route);
      window.open(`${route}/${thing_id ?? 'new'}`, '_blank').focus();
    },
    [item],
  );

  if (!thing_id) {
    return <Body2>Empty</Body2>;
  }
  return (
    <Stack spacing={0.5}>
      <IDDisplay
        value={`${thing_id} [${THING_TYPE_REVERSE[type]}]`}
        onClick={openThing}
      />
      <NameDisplay name={name} />
      <DescriptionDisplay value={description} />
      {display && display(item)}
    </Stack>
  );
}

const SelectedThing = forwardRef(
  ({ item, onRemove, onEdit, display, selected }, ref) => {
    const { readonly } = useContext(FormikContext);
    console.log('SelectedThing ', item);

    return (
      <Box className='listItem' ref={ref}>
        {selected && selected(item) && (
          <Box className='listSelected'>
            <TaskOutlined className='check' />
          </Box>
        )}

        <Box className='listContent'>
          <ThingDisplay item={item} display={display} />
        </Box>

        <Stack className='listButtons'>
          {!readonly && onEdit && (
            <IconCircleButton onClick={() => onEdit(item)}>
              <Edit />
            </IconCircleButton>
          )}
          {!readonly && onRemove && (
            <IconCircleButton onClick={() => onRemove(item)}>
              <Delete />
            </IconCircleButton>
          )}
        </Stack>
      </Box>
    );
  },
);

export default SelectedThing;
