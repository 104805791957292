function SvgBottleLabeller(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 18 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#BottleLabeller_svg__clip0_146_155)' fill='currentColor'>
        <path d='M17.183 14.31h-7.07l.002-.184a7.522 7.522 0 00-.025-.814c-.065-1.345-.585-2.542-1.425-3.6a7.819 7.819 0 00-.214-.268 5.663 5.663 0 01-.841-1.333.668.668 0 00-.038-.124 5.83 5.83 0 01-.405-1.645 6.5 6.5 0 01-.011-.227 51.51 51.51 0 01-.022-1.298c.002-.777-.002-1.555 0-2.332 0-.235-.022-.453-.22-.624l-.008-.007c-.068-.143-.057-.293-.058-.45 0-.137-.008-.274-.006-.41A3.616 3.616 0 016.854.74C6.871.411 6.74.163 6.434 0H3.685c-.308.163-.437.41-.421.741l.002.054c.006.066.009.132.01.198.002.137-.005.274-.006.41-.001.158.01.308-.058.45l-.007.008c-.198.17-.222.389-.221.624.002.777-.002 1.555 0 2.332-.002.433-.008.865-.021 1.298a5.452 5.452 0 01-.012.227 5.826 5.826 0 01-.404 1.645.646.646 0 00-.038.124c-.211.473-.493.918-.841 1.333a7.831 7.831 0 00-.215.267C.616 10.771.094 11.968.03 13.312a7.411 7.411 0 00-.025.814c.005.238.007.476.007.714C.005 15.856.002 16.873 0 17.89v.038a884.21 884.21 0 00.007 4.061v1.907c0 .083.003.163.009.24v.013c.003.768.413 1.328 1.117 1.668.168.08.354.124.532.184h6.79c.178-.06.364-.104.531-.184.704-.34 1.115-.9 1.118-1.668v-.013c.005-.077.008-.158.008-.24v-.761H18V14.31h-.817zM4.069.774h1.983v.939H4.069v-.94zm-.283 1.733h2.549v2.05H3.786v-2.05zm-2.673 9.399c.038-.098.079-.196.124-.292a8.047 8.047 0 01.952-1.558c.42-.53.767-1.088 1.027-1.68a.588.588 0 00.05-.112l.014-.037c.213-.52.36-1.065.43-1.644.027-.222.04-.445.05-.671.016-.186.031-.373.05-.56h2.501c.02.187.035.374.05.56.011.226.024.45.05.67.071.58.218 1.126.431 1.645l.013.037a.603.603 0 00.05.112c.26.592.607 1.15 1.027 1.68.377.476.695 1.01.952 1.558.046.097.087.194.125.292a5.96 5.96 0 01.311 2.404H.802a5.952 5.952 0 01.311-2.404zM9.315 24.27a1.137 1.137 0 01-1.133.963c-.969.004-1.937.005-2.906.005h-.429c-.97 0-1.938-.001-2.908-.004a1.138 1.138 0 01-1.133-.964c-.043-.365-.009-.739-.009-1.137h.157c.195 0 .385.002.574 0H8.593c.189.002.378 0 .573 0h.157c0 .398.034.772-.008 1.137zm7.868-1.898H.8V15.11h16.383v7.262z' />
        <path d='M5.18 16.79L3.21 18.722l1.972 1.932 1.971-1.932-1.971-1.932zM15.622 17.381h-4.188v.406h4.188v-.406zM15.622 18.57h-4.188v.405h4.188v-.405zM15.622 19.864h-4.188v.405h4.188v-.405z' />
      </g>
      <defs>
        <clipPath id='BottleLabeller_svg__clip0_146_155'>
          <path fill='#fff' d='M0 0h18v26H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgBottleLabeller;
