import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import RequestForm from '@pw/components/RequestForm';
import requestIdFields from '@pw/components/RequestID/requestIdFields';
import DestinationLiquidModal from '@pw/components/ThingSelector/modals/DestinationLiquidModal';
import DestinationThingModal from '@pw/components/ThingSelector/modals/DestinationThingModal';
import SourceThingModal from '@pw/components/ThingSelector/modals/SourceThingModal';
import { ASSET_TYPES } from '@pw/consts/asset';
import { REQUEST_TYPES } from '@pw/consts/requests';
import { THING_TYPES } from '@pw/consts/thing';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import useFormSubmissionHook from '@pw/utilities/hooks/components/useFormSubmissionHook';
import { useCallback, useMemo } from 'react';

function ProductionRunForm(props) {
  const [FormSubmitter, submitForm] = useFormSubmissionHook();

  const [[sourceThings], , initSources, SourceInventory, SourceModals] =
    useInventorySelectorHook({
      title: 'Input',
      filter: {
        request_types: [REQUEST_TYPES.PICK],
        thing_types: [
          THING_TYPES.RAW,
          THING_TYPES.EXPIRING,
          THING_TYPES.CONSUMABLE,
          THING_TYPES.SERVICES,
          THING_TYPES.WIP,
        ],
      },
      submitForm,
      ThingModal: SourceThingModal,
    });

  const [
    [destinationThings],
    [destinationAssets],
    initDestinations,
    DestinationInventory,
    DestinationModals,
  ] = useInventorySelectorHook({
    title: 'Output',
    filter: {
      asset_types: [ASSET_TYPES.FILLING_TANK],
      thing_types: [THING_TYPES.WIP, THING_TYPES.WASTE, THING_TYPES.SERVICES],
    },
    assetAdapter: toTaggedAsset,
    submitForm,
    ThingModal: DestinationThingModal,
    AssetModal: DestinationLiquidModal,
  });
  //
  // const { data: tanks = [] } = useAssetByTypeQuery({
  // 	type: ASSET_TYPES.FILLING_TANK,
  // });
  // console.log('Tanks', tanks);

  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...requestIdFields(initialValues),
    }),
    [],
  );

  /**
   * Initialization function
   * @type {(function(*): void)|*}
   */
  const handleInit = useCallback(
    (entity) => {
      initSources({
        ...entity,
        assets: entity?.sources?.assets,
        things: entity?.sources?.things,
      });
      initDestinations({
        ...entity,
        assets: entity?.destinations?.assets,
        things: entity?.destinations?.things,
      });
    },
    [initSources, initDestinations],
  );

  // On the way out, add the sku list in
  const handleBeforeSave = useCallback(
    (entity) => {
      // const dest_tank = toTaggedAsset(
      // 	tanks.find((t) => t.path === entity.tank_id),
      // );
      // /** get confirmed tanker asset */
      // if (destinationAssets?.[0]?.processed === '1')
      // 	dest_tank.processed = destinationAssets?.[0].processed;
      return {
        ...entity,
        // tank_id: null,
        sources: {
          things: sourceThings,
        },
        destinations: {
          assets: destinationAssets,
          things: destinationThings,
        },
      };
    },
    [destinationAssets, sourceThings, destinationThings],
  );

  return (
    <>
      <RequestForm
        requestLabel='Production Run'
        requestType={REQUEST_TYPES.PRODUCTION_ORDER}
        changeSetGenerator={changeSetGenerator}
        onInit={handleInit}
        onBeforeSave={handleBeforeSave}
        {...props}
      >
        <SourceInventory />

        {/*<Stack spacing={2}>*/}
        {/*	<H4>Destination Tank</H4>*/}
        {/*	<FormikSelect*/}
        {/*		fullWidth*/}
        {/*		options={tanks.map((t) => ({*/}
        {/*			value: t.path,*/}
        {/*			label: t.rw_asset_id,*/}
        {/*		}))}*/}
        {/*		label='Production Tank'*/}
        {/*		name='tank_id'*/}
        {/*	/>*/}
        {/*</Stack>*/}

        <DestinationInventory />

        {/*<Properties />*/}
        <FormSubmitter />
      </RequestForm>

      <SourceModals />
      <DestinationModals />
    </>
  );
}

export default withAppLayout(ProductionRunForm, { title: 'Liquid Production' });
