import Bolder from '@pw/components/Typography/Bolder';
import { useMemo } from 'react';

export const CURRENCIES = {
  EUR: 'EUR',
  GBP: 'GBP',
  USD: 'USD',
  CAD: 'CAD',
  AUD: 'AUD',
  NZD: 'NZD',
  JPY: 'JPY',
  CNY: 'CNY',
  INR: 'INR',
  ZAR: 'ZAR',
  BRL: 'BRL',
  MXN: 'MXN',
};

export const CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  CAD: '$',
  AUD: '$',
  NZD: '$',
  JPY: '¥',
  CNY: '¥',
  INR: '₹',
  ZAR: 'R',
  BRL: 'R$',
  MXN: '$',
};

export const CURRENCY_OPTIONS = [
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'USD', value: 'USD' },
  { label: 'CAD', value: 'CAD' },
  { label: 'AUD', value: 'AUD' },
  { label: 'NZD', value: 'NZD' },
  { label: 'JPY', value: 'JPY' },
  { label: 'CNY', value: 'CNY' },
  { label: 'INR', value: 'INR' },
  { label: 'ZAR', value: 'ZAR' },
  { label: 'BRL', value: 'BRL' },
  { label: 'MXN', value: 'MXN' },
];

export const toPriceString = (price) => {
  if (!price || !price.value) {
    return '';
  }
  return `${CURRENCY_SYMBOLS[price.unit]} ${Number(price.value).toFixed(2)}`;
}
