import { ArrowDropDownRounded, ArrowDropUpRounded, CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Collapse, Stack } from '@mui/material';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { Body3, H5 } from '@pw/components/Typography';
import EntryLabel from '@pw/components_v2/labels/EntryLabel';
import EntryTitle from '@pw/components_v2/labels/EntryTitle';
import { ASSET_TYPES } from '@pw/consts/asset';
import { GENERAL_FUNCTIONS } from '@pw/consts/permissions';
import { REQUEST_TYPES } from '@pw/consts/requests';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useMemo, useState } from 'react';

const assets = {
  name: 'Assets',
  description: 'Controls permissions for managing assets',
  permissions: [
    {
      key: ASSET_TYPES.CASK,
      value: 'Casks',
      description: 'Manage casks',
    },
    {
      key: ASSET_TYPES.PALLET,
      value: 'Pallets',
      description: 'Manage pallets',
    },
    {
      key: ASSET_TYPES.IBC,
      value: 'IBCs',
      description: 'Manage IBCs',
    },
    {
      key: ASSET_TYPES.FILLING_TANK,
      value: 'Fixed Tanks',
      description: 'Manage fixed tanks',
    },
    {
      key: ASSET_TYPES.TANKER,
      value: 'Tankers',
      description: 'Manage tankers for liquid shipments',
    },
    {
      key: ASSET_TYPES.MACHINE,
      value: 'Machines',
      description: 'Manage machines within the facility',
    }
  ]
};

const requests = {
  name: 'Tasks',
  description: 'Controls permissions for managing tasks',
  permissions: [
    {
      key: REQUEST_TYPES.SAMPLE,
      value: 'Sample',
      description: 'Manage samplings',
      approve: true
    },
    {
      key: REQUEST_TYPES.REGAUGE,
      value: 'Regauge',
      description: 'Manage regauges',
      approve: true
    },
    {
      key: REQUEST_TYPES.TRANSFER,
      value: 'Liquid Transfer',
      description: 'Manage liquid transfers',
      approve: true
    },
    {
      key: REQUEST_TYPES.SHIP,
      value: 'Shipment',
      description: 'Manage shipments',
      approve: true
    },
    {
      key: REQUEST_TYPES.PRODUCTION_ORDER,
      value: 'Production',
      description: 'Manage production activities',
      approve: true
    },
    {
      key: REQUEST_TYPES.CHANGE_OWNERSHIP,
      value: 'Transfer Ownership',
      description: 'Manage ownership transfer of assets',
      approve: true
    },
    {
      key: REQUEST_TYPES.RESTOCK,
      value: 'Restock Inventory',
      description: 'Manage restocking',
      approve: true
    },
    {
      key: REQUEST_TYPES.INVENTORY_AUDIT,
      value: 'Inventory Audit',
      description: 'Manage audits',
      approve: true
    },
    {
      key: REQUEST_TYPES.DELIVERY,
      value: 'Delivery',
      description: 'Manage deliveries',
      approve: true
    },
    {
      key: REQUEST_TYPES.DUTY_TRANSFER,
      value: 'Duty Payment',
      description: 'Manage duty payments',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.REPORT_DEFECT,
      value: 'Report Defect',
      description: 'Manage defect handling',
      approve: true
    },
    {
      key: REQUEST_TYPES.DUTY_SUBMISSION,
      value: 'Duty Submission',
      description: 'Manage duty submissions',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.INVOICE,
      value: 'Invoice',
      description: 'Manage invoices',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.PURCHASE_ORDER,
      value: 'Purchase Order',
      description: 'Manage purchase orders',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.EXPENSE_REPORT,
      value: 'Expense Report',
      description: 'Manage expense reports',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.PACK,
      value: 'Pack Task',
      description: 'Manage packing activities',
      approve: true
    },
    {
      key: REQUEST_TYPES.SALES_ORDER,
      value: 'Sales Order',
      description: 'Manage sales orders',
      approve: true
    },
    {
      key: REQUEST_TYPES.DISPATCH,
      value: 'Dispatch',
      description: 'Manage order dispatching',
      approve: true
    },
    {
      key: REQUEST_TYPES.MAINTENANCE,
      value: 'Maintenance Task',
      description: 'Manage maintenance activities',
      approve: true
    },
    {
      key: REQUEST_TYPES.OWNERSHIP_AUDIT,
      value: 'Ownership Audit',
      description: 'Manage ownership audits',
      approve: true
    },
    {
      key: REQUEST_TYPES.INVOICE_PAYMENT,
      value: 'Invoice Payment',
      description: 'Manage invoice payments',
      approve: true
    },
    {
      key: REQUEST_TYPES.SALES_RETURN,
      value: 'Sales Return',
      description: 'Manage sales order returns',
      approve: true,
      authorize: true
    },
    {
      key: REQUEST_TYPES.PURCHASE_RETURN,
      value: 'Purchase Return',
      description: 'Manage purchase order returns',
      approve: true
    }
  ]
};

const general = {
  name: 'General',
  description: 'General system permissions',
  permissions: [
    { key: GENERAL_FUNCTIONS.FACILITIES, value: 'Facilities', description: 'Manage facilities' },
    { key: GENERAL_FUNCTIONS.THINGS, value: 'Things', description: 'Manage things' },
    { key: GENERAL_FUNCTIONS.SENSORS, value: 'Sensors', description: 'Manage sensors' },
    { key: GENERAL_FUNCTIONS.CRM, value: 'CRM', description: 'Manage contacts' },
    { key: GENERAL_FUNCTIONS.PLANT, value: 'Plant Configuration', description: 'Manage the plant configuration' },
    { key: GENERAL_FUNCTIONS.POLICIES, value: 'Policies', description: 'Manage policies' },
    { key: GENERAL_FUNCTIONS.SPECIFICATIONS, value: 'Specifications', description: 'Manage specifications' },
    { key: GENERAL_FUNCTIONS.PRICE_CURVES, value: 'Price Curves', description: 'Manage price curves' },
    { key: GENERAL_FUNCTIONS.USERS, value: 'Teams & Users', description: 'Manage teams and users' },
    { key: GENERAL_FUNCTIONS.LEDGERS, value: 'Ledgers', description: 'Manage ledgers' },
  ]
};

function PermissionEntry({ item }) {
  const permissions = useMemo(() => {
    const perms = [
      { value: '0b0', label: 'None'},
      { value: '0b1', label: 'Read'},
      { value: '0b1111', label: 'Write'},
    ];

    if (item.approve) {
      perms.push({ value: '0b111111', label: 'Approve'});
    }

    if (item.authorize) {
      perms.push({ value: '0b1111111', label: 'Authorize'});
    }

    return perms;
  }, [item]);
  return (
    <FlexBox className="listItem" sx={{ p: '0.25rem' }}>
      <Stack>
        <EntryTitle value={item.value} />
        <EntryLabel value={item.description} />
      </Stack>

      <FormikSelect name={item.key} label="Permisssion" options={permissions} sx={{ minWidth: '10rem'}} withNone={false} />
    </FlexBox>
  );
}

function PermissionBlock({ block }) {
  const { setFieldValue } = useContext(FormikContext);

  const [expanded, setExpanded] = useState(false);

  const needsApprove = useMemo(() => block.permissions.find((t) => t.approve), [block]);
  const needsAuth = useMemo(() => block.permissions.find((t) => t.authorize), [block]);

  const clearAll = useCallback(() => {
    debounce(() => {
      block.permissions.forEach((item) => {
        console.log('Permissions', item.key, '0b0');
        setFieldValue(item.key, '0b0');
      })
    }, 25);
  }, [setFieldValue, block]);

  const setAllRead = useCallback(() => {
    debounce(() => {
      block.permissions.forEach((item) => {
        console.log('Permissions', item.key, '0b1');
        setFieldValue(item.key, '0b1');
      })
    }, 25);
  }, [setFieldValue, block]);

  const setAllWrite = useCallback(() => {
    debounce(() => {
      block.permissions.forEach((item) => {
        console.log('Permissions', item.key, '0b1111');
        setFieldValue(item.key, '0b1111');
      })
    }, 25);
  }, [setFieldValue, block]);

  const setAllApprove = useCallback(() => {
    debounce(() => {
      block.permissions.forEach((item) => {
        if (item.approve) {
          setFieldValue(item.key, '0b111111');
        }
      })
    }, 25);
  }, [setFieldValue, block]);

  const setAllAuth = useCallback(() => {
    debounce(() => {
      block.permissions.forEach((item) => {
        if (item.authorize) {
          setFieldValue(item.key, '0b1111111');
        }
      })
    }, 25);
  }, [setFieldValue, block]);
  return (
    <Stack className="section">
      <FlexBox>
        <Stack spacing='0.5rem' sx={{ p: '0.5rem'}}>
          <H5>{block.name}</H5>
          <Body3>{block.description}</Body3>
        </Stack>
        {expanded && (
          <ArrowDropUpRounded onClick={() => setExpanded(!expanded)}></ArrowDropUpRounded>
        )}
        {!expanded && (
          <ArrowDropDownRounded onClick={() => setExpanded(!expanded)}></ArrowDropDownRounded>
        )}
      </FlexBox>
      <Collapse in={expanded} unmountOnExit>
        <FlexBox sx={{ mb: '0.5rem'}}>
          <TitledButton
            handleClick={() => clearAll()}
            label='Clear'
          >
            <CheckBoxOutlineBlank height={24} width={24} />
          </TitledButton>
          <Stack direction="row" spacing={1}>
            <TitledButton
              handleClick={() => setAllRead()}
              label='Read'
            >
              <CheckBoxOutlined height={24} width={24} />
            </TitledButton>
            <TitledButton
              handleClick={() => setAllWrite()}
              label='Write'
            >
              <CheckBoxOutlined height={24} width={24} />
            </TitledButton>
            {needsApprove && (
              <TitledButton
                handleClick={() => setAllApprove()}
                label='Approve'
              >
                <CheckBoxOutlined height={24} width={24} />
              </TitledButton>
            )}
            {needsAuth && (
              <TitledButton
                handleClick={() => setAllAuth()}
                label='Authorize'
              >
                <CheckBoxOutlined height={24} width={24} />
              </TitledButton>
            )}
          </Stack>
        </FlexBox>
        <Stack className="list">
          {block.permissions.map((p) => <PermissionEntry item={p} key={p.key} />)}
        </Stack>
      </Collapse>
    </Stack>
  );
}

function PermissionSelector() {
  return (
    <FormikProvider path="permissions">
      <Stack spacing='1rem'>
        <PermissionBlock block={assets} />
        <PermissionBlock block={requests} />
        <PermissionBlock block={general} />
      </Stack>
    </FormikProvider>
  );
}

export default PermissionSelector;
