import { Box, Stack } from '@mui/material';
import { REQUESTS_WITH_POLICIES } from '@pw/consts/requests';
import FormikContext from '@pw/context/FormikContext';
import { FieldArray } from 'formik';
import { useContext } from 'react';

export default function RequestTypeSelector() {
	const { values } = useContext(FormikContext);

	const requestTypes = Object.entries(REQUESTS_WITH_POLICIES).map(([key, value]) => ({ value: key, label: value }));

	return (
		<Stack spacing="0.33rem">
			<Box className="fixedList">
				<FieldArray
					name="requests"
					render={(arrayHelpers) => (
						<Stack spacing="0.75rem">
							{requestTypes.map((req) => (
								<Stack direction="row" spacing="0.5rem" key={req.value}>
									<input
										name="requests"
										type="checkbox"
										value={req.value}
										checked={values?.requests?.includes(req.value)}
										onChange={(e) => {
											if (e.target.checked) {
												arrayHelpers.push(req.value);
											} else {
												const idx = values?.requests?.indexOf(req.value);
												arrayHelpers.remove(idx);
											}
										}}
									/>
									<span>{req.label}</span>
								</Stack>
							))}
						</Stack>
					)}
				/>
			</Box>
		</Stack>
	);
}