import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Errors from '@pw/components/Forms/FormErrors';
import {
  FormikCheckBox,
  FormikForm,
  FormikMeasuresField,
  FormikNumberField,
  FormikTextField,
} from '@pw/components/Forms/FormikForm';
import Instructions from '@pw/components/Instructions';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import Location from '@pw/components/Location';
import { locationFields } from '@pw/components/Location/locationFields';
import LinkedAssetItem from '@pw/components/SKUSelector/items/LinkedAssetItem';
import LiquidSummary from '@pw/components/SKUSelector/items/LiquidSummary';
import OcrFormFieldComponent from '@pw/components/ScanOCR/OcrComponent';
import { H5, Overline } from '@pw/components/Typography';
import LiquidDisplay from '@pw/components/properties/LiquidDisplay';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { UNIT } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { useIsUsUser } from '@pw/redux/containers/User';
import styles from '@pw/styles/modal.styles';
import toLocation from '@pw/utilities/adapters/toLocation';
import toUniqueLocation from '@pw/utilities/adapters/toUniqueLocation';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useAlertView, {
  AlertView,
} from '@pw/utilities/hooks/components/useAlertView';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export function WeightsForm({ weightFactor }) {
  const { values, setFieldValue } = useContext(FormikContext);
  // TDOD: For US - we need to use the TTB table to convert the weights
  // This is not correct, so for now, we'll disable the weight elements
  useEffect(() => {
    // console.log('  --> Weight', values, weightFactor?.weightFactor);

    if (
      values?.enableWeight &&
      values?.tearWeight &&
      values?.grossWeight &&
      weightFactor?.weightFactor
    ) {
      const netWeight =
        Number(values?.grossWeight ?? 0) - Number(values?.tearWeight ?? 0);
      const filled_bl = Number(weightFactor?.weightFactor ?? 0) * netWeight;
      // console.log('Filled', netWeight, weightFactor?.weightFactor, filled_bl);
      debounce(() => setFieldValue('filled_bl', filled_bl.toFixed(2)), 25);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.enableWeight,
    values?.tearWeight,
    values?.grossWeight,
    weightFactor,
  ]);

  return (
    <Stack>
      <FormikCheckBox name='enableWeight' label='Use Weights' />
      <Collapse in={Boolean(values?.enableWeight)}>
        <FlexBox alignItems='top'>
          <FormikMeasuresField
            label='Tear Weight'
            name='tearWeight'
            unit={UNIT.MAS}
            fullWidth
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <OcrFormFieldComponent name='tearWeight' />
                </InputAdornment>
              ),
            }}
          />
          <FormikMeasuresField
            label='Gross Weight'
            name='grossWeight'
            unit={UNIT.MAS}
            fullWidth
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <OcrFormFieldComponent name='grossWeight' />
                </InputAdornment>
              ),
            }}
          />
        </FlexBox>
      </Collapse>
    </Stack>
  );
}

export const calculateTransferDestinationLiquidUpdates = (
  values,
  converter,
) => {
  // console.log('Calculating', values);

  const {
    bl = 0,
    la = 0,
    expected_bl = 0,
    filled_bl,
    updated_abv,
    updated_tcf,
  } = values;

  // if (enableWeight && grossWeight && tearWeight) {
  // 	const { weightFactor } = weightProperties ?? {};
  //
  // 	if (updated_tcf && weightFactor && updated_abv) {
  // 		console.log('Calculating with weight properties', updated_tcf, weightFactor, updated_abv);
  // 		// Convert the weights to metric
  // 		const netWeight = Number(grossWeight) - Number(tearWeight);
  // 		const filledBl = Number(weightFactor) * netWeight;
  // 		const filledLa = filledBl * Number(updated_tcf) * Number(updated_abv / 100.0);
  // 		const updatedBl = Number(bl) + cfrom(filledBl, MEASURE.l_m);
  // 		const updatedLa = Number(la) + cfrom(filledLa, MEASURE.l_m);
  // 		return [
  // 			true,
  // 			{
  // 				expected_bl: cfrom(expected_bl, MEASURE.l_m),
  // 				actual_bl: cfrom(filledBl, MEASURE.l_m),
  // 				updated_bl: Math.max(updatedBl, 0),
  // 				updated_la: Math.max(updatedLa, 0),
  // 				updated_abv: cfrom(updated_abv, MEASURE.alc),
  // 				updated_tcf,
  // 			},
  // 		];
  // 	}
  // }

  // If the user has filled in the actual_bl
  if (filled_bl && Number(filled_bl) > 0) {
    const filledBl = converter.to(filled_bl, UNIT.BL); // Number(cfrom(filled_bl, MEASURE.l_m));
    const abv = converter.to(updated_abv, UNIT.ALC);
    const filledLa = filledBl * updated_tcf * (abv / 100);

    const updatedBl = Number(bl) + filledBl;
    const updatedLa = Number(la) + filledLa;

    return [
      true,
      {
        expected_bl: converter.to(expected_bl, UNIT.BL),
        updated_bl: updatedBl,
        updated_la: updatedLa,
        filled_bl: filledBl,
        updated_abv: abv,
        updated_tcf,
      },
    ];
  }

  return [
    false,
    {
      ...values,
      expected_bl: converter.to(expected_bl, UNIT.BL),
      updated_abv: converter.to(updated_abv, UNIT.ALC),
    },
  ];
};

function TrackedAsset({ item, onClose }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const converter = useConverter();
  const isUsUser = useIsUsUser();

  // We track the "amount" we want at the Thing Item level
  const { name, properties = {}, type } = item ?? {};
  const { liquid = {}, reference = '' } = properties;
  const { level = {} } = liquid;

  // console.log('Level', properties);
  const {
    bl,
    la,
    abv,
    expected_bl,
    filled_bl,
    enableWeight,
    weightFactor,
    tearWeight,
    grossWeight,
    updated_bl,
    updated_abv,
    updated_tcf,
  } = level;

  // const [sourceAbv, sourceTcf] = sourcesAbv ?? [0, 1];

  const default_expected_bl = type === ASSET_TYPES.CASK ? 200 : '';
  const expected_value = expected_bl ?? default_expected_bl;

  const changeSet = useMemo(
    () => ({
      expected_bl: [
        converter.from(expected_value ?? 0, UNIT.BL)?.toFixed(3),
        yup.number().min(0, 'Must be positive!'),
      ],
      filled_bl: [
        converter.from(filled_bl ?? updated_bl ?? '', UNIT.BL)?.toFixed(3),
        yup.number().min(0, 'Must be positive!'),
      ],
      updated_abv: [
        converter.from(updated_abv ?? abv, UNIT.ALC)?.toFixed(2),
        yup.number().min(0, 'Must be positive!'),
      ],
      updated_tcf: [
        Number(updated_tcf ?? 1).toFixed(3),
        yup.number().min(0, 'Must be positive!'),
      ],
      enableWeight: [enableWeight, yup.boolean()],
      tearWeight: [
        converter.from(tearWeight ?? 0, UNIT.MAS)?.toFixed(2),
        yup.number().min(0, 'Must be positive!'),
      ],
      grossWeight: [
        converter.from(grossWeight ?? 0, UNIT.MAS)?.toFixed(2),
        yup.number().min(0, 'Must be positive!'),
      ],
      reference: [reference, yup.string()],
    }),
    [filled_bl, bl, enableWeight, tearWeight, grossWeight, reference],
  );

  const handleSubmit = useCallback(
    (values) => {
      try {
        console.log('submitted', values);
        const [valid, updated_level] =
          calculateTransferDestinationLiquidUpdates(
            { ...level, ...values },
            converter,
          );
        console.log('updated_level ', updated_level);
        // const le =
        // 	Number(updated_level?.filled_bl) > 0 || Number(level?.bl) > 0;
        onClose({
          ...item,
          properties: {
            ...properties,
            reference: values?.reference ?? properties?.reference,
            liquid: {
              ...liquid,
              enable: Number(updated_level?.updated_bl) > 0,
              level: {
                ...level,
                ...updated_level,
                filled_date: Date.now(),
                enableWeight: values.enableWeight,
                ...(values.enableWeight
                  ? {
                      weightFactor,
                      tearWeight: converter.to(values.tearWeight, UNIT.MAS),
                      grossWeight: converter.to(values.grossWeight, UNIT.MAS),
                    }
                  : {}),
                enable: Number(updated_level?.updated_bl) > 0,
              },
            },
          },
          processed: valid
            ? ASSET_PROCESSED_STATUS.CONFIRMED
            : item.processed
              ? item.processed
              : ASSET_PROCESSED_STATUS.PENDING,
        });
      } catch (e) {
        console.log('Failed to update', e);
        enqueueSnackbar(
          `Cannot update liquid settings for ${name}: ${e.message}!`,
          {
            variant: 'error',
          },
        );
      }
    },
    [level],
  );

  return (
    <Box
      sx={{
        overflowY: 'auto',
        height: 'auto',
        maxHeight: 'calc(95vh - 9rem)',
        gap: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Instructions>Adjust fill settings.</Instructions>

      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {[ASSET_TYPES.CASK].includes(type) && (
            <>
              <FormikTextField name='reference' label='Reference' fullWidth />
              <Divider />
            </>
          )}

          <LiquidDisplay label='Current' value={bl} la={la} />

          <FlexBox alignItems='top'>
            <FormikMeasuresField
              label='Expected'
              name='expected_bl'
              unit={UNIT.BL}
              fullWidth
            />
            <FormikMeasuresField
              label='Filled'
              name='filled_bl'
              unit={UNIT.BL}
              fullWidth
            />
          </FlexBox>

          <FlexBox alignItems='top'>
            <FormikMeasuresField
              name='updated_abv'
              unit={UNIT.ALC}
              label={t('strength')}
              fullWidth
              required
            />
            <FormikNumberField name='updated_tcf' label='TCF' fullWidth />
          </FlexBox>

          <LiquidSummary
            level={{
              ...level,
              abv: level.updated_abv,
              tcf: level.updated_tcf,
            }}
          />

          {!isUsUser && <WeightsForm weightFactor={weightFactor} />}

          <AlertView />

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </Box>
  );
}

function ChildList({ label, items, update }) {
  const handleSelect = useCallback(
    (item, selected) => {
      debounce(() => update({ ...item, selected }), 25);
    },
    [update],
  );

  return (
    <Stack className='inventory' sx={{ width: '40%' }}>
      <Box className='inventory-header'>
        <Overline>{label}</Overline>
      </Box>
      <Box className='inventory-contents'>
        <Stack spacing={0.2}>
          {items.map((_item) => (
            <LinkedAssetItem
              key={_item.asset_id}
              asset={_item}
              selected={_item.selected}
              onSelect={(s) => handleSelect(_item, s)}
            />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

export function Pallet({ item, items, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const { unique_location_id } = item ?? {};

  const existingChildren = [...(item.children ?? [])];

  const [available, , , upsertAvailable, removeAvailable] = useItemListManager({
    initialData: items.map((i) => ({
      asset_id: i.asset_id ?? i.id ?? i._id,
      name: i.name,
      type: i.type,
      selected: false,
    })),
  });

  // console.log('Available', available);

  const [selected, , , upsertSelected, removeSelected] = useItemListManager({
    initialData: existingChildren.map((i) => ({
      asset_id: i.asset_id ?? i.id ?? i._id,
      name: i.name,
      type: i.type,
      selected: false,
    })),
  });

  // console.log('Selected', selected);

  // console.log('Delivery for', item);
  const changeSet = useMemo(
    () => ({
      location: locationFields(toLocation(unique_location_id)),
    }),
    [unique_location_id],
  );

  const handleSubmit = useCallback(
    (values) => {
      const { location } = values;
      const unique_location_id = toUniqueLocation(location || {});
      const empty_location = toUniqueLocation({});
      console.log('Location', unique_location_id, empty_location);
      if (unique_location_id !== empty_location) {
        // See if there is another asset with the same location id
        const existing = items?.find(
          (i) =>
            i.unique_location_id !== empty_location &&
            i.unique_location_id === unique_location_id &&
            i.asset_id !== item.asset_id,
        );
        if (existing) {
          enqueueSnackbar(
            `Asset ${existing.rw_asset_id} is already at this location!`,
            { variant: 'warning' },
          );
          return;
        }
      }

      const asset = { ...item, unique_location_id, children: selected };
      onClose(asset);
    },
    [item, onClose, selected],
  );

  const handleAdd = useCallback(() => {
    const selectedItems = available
      .filter((s) => s.selected)
      .map((s) => ({ ...s, selected: false }));
    debounce(() => {
      removeAvailable(selectedItems);
      upsertSelected(selectedItems);
    }, 25);
  }, [available, selected]);

  const handleRemove = useCallback(() => {
    const selectedItems = selected
      .filter((s) => s.selected)
      .map((s) => ({ ...s, selected: false }));
    debounce(() => {
      removeSelected(selectedItems);
      upsertAvailable(selectedItems);
    }, 25);
  }, [available, selected]);

  const handleAddAll = useCallback(() => {
    const selectedItems = available.map((s) => ({ ...s, selected: false }));
    debounce(() => {
      removeAvailable(available);
      upsertSelected(selectedItems);
    }, 25);
  }, [available, selected]);

  const handleRemoveAll = useCallback(() => {
    const selectedItems = selected.map((s) => ({ ...s, selected: false }));
    debounce(() => {
      removeSelected(selected);
      upsertAvailable(selectedItems);
    }, 25);
  }, [available, selected]);

  return (
    <Stack spacing='1.5rem'>
      <Instructions>Configure Pallet.</Instructions>

      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Stack spacing={2}>
          <Location name='location' />

          <H5>Assets</H5>
          <FlexBox alignItems='stretch' sx={{ position: 'relative' }}>
            <ChildList
              label='Available'
              items={available}
              update={upsertAvailable}
            />
            <Stack sx={{ width: '10%' }}>
              <IconButton
                onClick={handleAdd}
                disabled={!available.some((s) => s.selected)}
                aria-label='Add'
              >
                <KeyboardArrowRight />
              </IconButton>
              <IconButton
                onClick={handleRemove}
                disabled={!selected.some((s) => s.selected)}
                aria-label='Remove'
              >
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                onClick={handleAddAll}
                disabled={!available.length}
                aria-label='Add All'
              >
                <KeyboardDoubleArrowRight />
              </IconButton>
              <IconButton
                onClick={handleRemoveAll}
                disabled={!selected.length}
                aria-label='Remove All'
              >
                <KeyboardDoubleArrowLeft />
              </IconButton>
            </Stack>
            <ChildList
              label='Selected'
              items={selected}
              update={upsertSelected}
            />
          </FlexBox>

          <Errors />

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small' disabled={!location}>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </Stack>
  );
}

function DestinationAssetModal({ item, items, open, onClose }) {
  return (
    <ModalWithClose open={open} onClose={() => onClose()} title={item.name}>
      {item.type === ASSET_TYPES.PALLET && (
        <Pallet
          item={item}
          items={items
            ?.filter(
              (i) =>
                !i.parent_asset_id &&
                ![ASSET_TYPES.PALLET, ASSET_TYPES.CONTAINER].includes(i.type),
            )
            .filter(
              (i) => !item?.children?.find((s) => s.asset_id === i.asset_id),
            )}
          onClose={onClose}
        />
      )}
      {item.type !== ASSET_TYPES.PALLET && (
        <TrackedAsset item={item} onClose={onClose} />
      )}
    </ModalWithClose>
  );
}

export default DestinationAssetModal;
