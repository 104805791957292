import { Stack } from '@mui/material';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';
import WithHelp from '../Instructions/WithHelp';

function NotificationSettingsImpl() {
  const { values } = useContext(FormikContext);
  console.log('NOTIFICATIONS: values', values);
  return (
    <Stack>
      <WithHelp instruction='Uncheck this box if you do not want to receive any system notifications (updates to assets, assignments etc.)'>
        <FormikCheckBox name='events' label='Platform notifications' />
      </WithHelp>
      <WithHelp instruction='Uncheck this box if you do not want to receive any marketing communications from us or our carefully curated partners.'>
        <FormikCheckBox name='marketing' label='Marketing communications' />
      </WithHelp>
    </Stack>
  );
}

export default function NotificationSettings({ name = 'notifications' }) {
  return (
    <FormikProvider path={name}>
      <NotificationSettingsImpl />
    </FormikProvider>
  );
}
