import withDialogCard from '@pw/components/Cards/DialogCard';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import NABCALabel from '@pw/components/admin/Things/labels/NABCA';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';

function NABCALabelPage() {
	usePageTitleHook(`NABCA Labels`);

	return <NABCALabel />;
}

export default withAppLayout(withDialogCard(NABCALabelPage));
