function SvgAddNew(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#AddNew_svg__clip0_1_127)' fill='#404040'>
        <path d='M0 12.184v-1.288c.013-.076.029-.152.04-.229.069-.473.108-.953.21-1.42.573-2.64 1.93-4.805 4.082-6.435C7.01.784 10.024.112 13.312.797c2.63.549 4.77 1.928 6.406 4.054 1.767 2.297 2.503 4.912 2.226 7.79-.225 2.331-1.13 4.39-2.684 6.147-1.704 1.927-3.826 3.128-6.363 3.579-.414.074-.834.115-1.251.171h-1.29c-.055-.012-.11-.031-.165-.035-1.721-.122-3.331-.614-4.8-1.514-2.85-1.746-4.604-4.263-5.22-7.557C.094 13.02.056 12.6 0 12.184zm20.294-.643c0-5.125-4.155-9.283-9.287-9.29-5.12-.009-9.283 4.146-9.298 9.277-.015 5.123 4.168 9.308 9.297 9.302 5.13-.007 9.287-4.163 9.288-9.29z' />
        <path d='M11.857 12.313V15.626c-.003.565-.353.946-.862.943-.498-.003-.847-.382-.848-.935-.003-1.095-.001-2.19-.001-3.32h-.233c-1.06 0-2.12.004-3.18-.003a.839.839 0 01-.837-.743.837.837 0 01.57-.903 1.55 1.55 0 01.445-.055c.98-.005 1.962-.002 2.942-.002h.292v-.236c0-1.03-.002-2.061.001-3.092.002-.462.252-.802.65-.897.557-.135 1.053.264 1.058.868.008.916.002 1.833.002 2.748v.609h.263c1.01 0 2.019-.002 3.028 0 .468.001.786.204.915.572.198.566-.208 1.128-.83 1.132-1.038.006-2.076.001-3.114.002h-.262z' />
      </g>
      <defs>
        <clipPath id='AddNew_svg__clip0_1_127'>
          <path fill='#fff' transform='translate(0 .538)' d='M0 0h22v22H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgAddNew;
