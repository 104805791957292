import { Stack } from '@mui/material';
import { FormikSelect } from '@pw/components/Forms/FormikForm';
import { H5 } from '@pw/components/Typography';
import {
  ABV_UNIT_OPTIONS,
  LIQUID_UNIT_OPTIONS,
  MASS_UNIT_OPTIONS,
  TEMPERATURE_UNIT_OPTIONS,
  TIME_UNIT_OPTIONS,
} from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useContext } from 'react';

function BaseUnitsImpl({ label }) {
  const { values } = useContext(FormikContext);
  console.log('BASE_UNIT: values', values);
  return (
    <Stack spacing='1.5rem'>
      {!!label && <H5>{label}</H5>}
      <Stack spacing={2}>
        <FormikSelect
          label='Volume Unit'
          name='liq'
          options={LIQUID_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Mass Unit'
          name='mas'
          options={MASS_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Strength Unit'
          name='alc'
          options={ABV_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Temperature Unit'
          name='tmp'
          options={TEMPERATURE_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Time Unit'
          name='tim'
          options={TIME_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Bulk Volume Unit'
          name='bl'
          options={LIQUID_UNIT_OPTIONS}
        />
        <FormikSelect
          label='Alcohol Volume Unit'
          name='la'
          options={LIQUID_UNIT_OPTIONS}
        />
      </Stack>
    </Stack>
  );
}

export default function BaseUnits({
  name = 'base_units',
  label = 'Base Units',
}) {
  return (
    <FormikProvider path={name}>
      <BaseUnitsImpl label={label} />
    </FormikProvider>
  );
}
