function SvgTeams(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 25 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#Teams_svg__clip0_94_22)' fill='#404040'>
        <path d='M9.238 15c-.149-.033-.3-.056-.445-.1-.902-.272-1.467-.995-1.507-1.982-.04-1.024.066-2.034.44-2.993.242-.62.591-1.155 1.178-1.483.362-.202.75-.297 1.157-.289.128.003.263.07.38.137.188.106.362.24.545.357 1.064.685 2.128.684 3.19-.002.148-.096.299-.188.437-.296.254-.199.534-.223.836-.177.825.126 1.407.597 1.8 1.348.35.67.509 1.4.579 2.15.036.386.053.775.048 1.162-.013 1.156-.774 2.017-1.878 2.144-.027.003-.053.016-.08.024h-6.68zm3.337-.777h2.98c.136 0 .273-.01.408-.026.62-.075 1.074-.525 1.15-1.166.061-.521.014-1.043-.057-1.56-.074-.54-.195-1.066-.447-1.551-.296-.573-.724-.945-1.37-.984a.309.309 0 00-.174.042c-.188.118-.369.247-.555.367a3.568 3.568 0 01-1.666.58c-.82.066-1.57-.167-2.26-.624-.165-.108-.327-.22-.492-.326-.034-.022-.076-.047-.113-.045-.402.02-.762.15-1.052.452-.231.24-.396.53-.516.845-.327.852-.408 1.748-.381 2.654.018.623.35 1.075.882 1.242.21.066.438.095.659.097 1.001.01 2.003.004 3.004.004zM15.563 4.933c.014 1.74-1.367 3.194-3.056 3.2-1.679.005-3.06-1.418-3.07-3.186-.01-1.762 1.35-3.19 3.05-3.203 1.695-.012 3.068 1.411 3.076 3.188zm-3.082 2.42c1.254.028 2.329-1.064 2.34-2.397.012-1.303-.995-2.4-2.265-2.434-1.293-.034-2.338 1.016-2.374 2.37-.036 1.314 1.013 2.467 2.3 2.461zM8.285 3.188c.014 1.74-1.367 3.195-3.056 3.2-1.679.005-3.06-1.417-3.07-3.185C2.15 1.44 3.51.013 5.21 0c1.695-.012 3.068 1.411 3.076 3.188zm-3.082 2.42c1.254.029 2.329-1.064 2.34-2.396.012-1.303-.995-2.4-2.265-2.434C3.985.744 2.94 1.793 2.903 3.148c-.035 1.314 1.014 2.467 2.3 2.46zM22.685 3.188c.014 1.74-1.367 3.195-3.056 3.2-1.678.005-3.06-1.417-3.069-3.185C16.55 1.44 17.91.013 19.61 0c1.694-.012 3.068 1.411 3.075 3.188zm-3.081 2.42c1.253.029 2.328-1.064 2.34-2.396.011-1.303-.996-2.4-2.266-2.434-1.293-.034-2.337 1.015-2.374 2.37-.036 1.314 1.014 2.467 2.3 2.46z' />
        <path d='M24.95 9.926c-.07-.751-.227-1.48-.578-2.15-.393-.752-.975-1.222-1.8-1.349-.302-.046-.582-.021-.836.178-.139.108-.29.2-.437.295-1.062.687-2.126.687-3.19.003-.183-.118-.358-.25-.546-.358-.117-.066-.252-.134-.38-.136a2.248 2.248 0 00-1.156.289c-.586.327-.937.863-1.178 1.482l-.017.046c.193-.08.4-.083.618-.05.068.01.135.024.2.039.106-.212.236-.407.4-.579.29-.302.65-.432 1.052-.451.037-.002.08.022.113.044.165.107.327.219.491.327.69.456 1.44.69 2.261.623a3.572 3.572 0 001.666-.579c.186-.12.367-.25.555-.367a.311.311 0 01.173-.042c.647.038 1.075.41 1.371.984.252.485.373 1.012.447 1.55.07.518.118 1.04.056 1.56-.075.642-.529 1.092-1.15 1.167a3.377 3.377 0 01-.407.025c-.994.002-1.987.001-2.98.001-.608 0-1.215.002-1.823.001.002.12.003.238.002.357a2.524 2.524 0 01-.04.419h5.204c.027-.009.053-.021.08-.025 1.104-.126 1.865-.987 1.879-2.143.004-.387-.013-.777-.049-1.162zM7.286 12.922a10.096 10.096 0 01-.008-.444h-1.98c-1.002 0-2.004.006-3.005-.004a2.304 2.304 0 01-.659-.096c-.531-.166-.863-.619-.882-1.242-.027-.906.054-1.802.381-2.655.12-.314.285-.603.516-.845.29-.302.65-.432 1.053-.451.037-.002.079.022.112.044.165.107.328.219.492.327.69.456 1.44.69 2.26.623A3.572 3.572 0 007.232 7.6c.186-.12.367-.25.555-.367a.311.311 0 01.173-.042c.647.038 1.075.41 1.372.984l.037.075a2.25 2.25 0 01.692-.093.46.46 0 01.092.013 4.455 4.455 0 00-.181-.394c-.393-.752-.976-1.222-1.8-1.349-.302-.046-.582-.021-.837.178-.138.108-.289.2-.436.295-1.063.687-2.127.687-3.19.003-.183-.118-.358-.25-.546-.358-.117-.066-.252-.134-.38-.136a2.248 2.248 0 00-1.156.289C1.04 7.025.69 7.56.449 8.18c-.374.96-.482 1.97-.44 2.994.039.987.604 1.71 1.506 1.982.146.044.296.067.445.1H7.32a2.543 2.543 0 01-.035-.333z' />
      </g>
      <defs>
        <clipPath id='Teams_svg__clip0_94_22'>
          <path fill='#fff' d='M0 0h25v15H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgTeams;
