import * as yup from 'yup';

function thingSettingsFields(params = {}) {
	return {
    name: [params?.name ?? '', yup.string().required('Name is required!')],
    description: [
      params?.description ?? '',
      yup.string().required('Description is required!'),
    ],
    tags: [params?.tags, yup.array().of(yup.string())],
    // Client who may own this Thing
    client: [params?.client ?? '', yup.object()],
    ledger: [params?.ledger ?? '', yup.object()],
    vendors: [params?.vendors, yup.array()],
    policies: [params?.policies, yup.array()],
    companies: [
      params?.companies,
      yup.array().of(
        yup.object().shape({
          id: yup.string(),
          name: yup.string(),
          type: yup.string(),
        }),
      ),
    ],
  };
}

export default thingSettingsFields;
