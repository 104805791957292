import * as yup from 'yup';

export function assetAccountsFields(fields = {}) {
	const { bo, mo , ro } = fields;

	return {
		bo: [
			bo,
			yup.object(),
		],
		mo: [
			mo,
			yup.object(),
		],
		ro: [
			ro,
			yup.object(),
		],
	};
}
