import { createSpecification, removeSpecification, updateSpecification } from '@pw/services/specification.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertSpecificationThunk = createAsyncThunk(
  'specification/upsert',
  async (spec, { rejectWithValue, fulfillWithValue }) => {
    try {
      console.log('Upserting spec', spec);
      const response = (spec._id) ? await updateSpecification(spec) : await createSpecification(spec);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });

export const removeSpecificationThunk = createAsyncThunk(
  'specification/remove',
  async (spec, { rejectWithValue }) => {
    try {
      return await removeSpecification(spec);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
