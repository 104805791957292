import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikDatePicker, FormikForm } from '@pw/components/Forms/FormikForm';
import { PROGRESS_POINT } from '@pw/consts/requests';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
function ScheduleModal({ onClose }) {
  const changeSet = useMemo(
    () => ({
      timestamp: [Date.now(), yup.string().required('Date is required!')],
      type: [PROGRESS_POINT.SCHEDULED, yup.string()],
    }),
    [],
  );

  const handleSubmit = useCallback(
    (values) => {
      console.log('values', values);
      onClose(values);
    },
    [onClose],
  );

  return (
    <ModalWithClose title='Schedule' open onClose={onClose}>
      <FormikForm changeSet={changeSet} onSubmit={handleSubmit}>
        <Stack spacing='1.5rem'>
          <FormikDatePicker label='Date' name='timestamp' fullWidth />
          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton type='submit' size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

export default ScheduleModal;
