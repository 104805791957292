import { ArrowForward } from '@mui/icons-material';
import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import SearchField from '@pw/components/Search/SearchField';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { PUBLIC_APP_BASE_URL } from '@pw/consts/config';
import { useContactLazyQuery } from '@pw/utilities/hooks/service/useContactQuery';
import { useContactsLazyQuery } from '@pw/utilities/hooks/service/useContactsQueries';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import ContactListItem from './ContactListItem';
import { PATH } from './consts';
import { RELATIONSHIP_TYPE } from '@pw/consts/relationship';

function ContactSearch({
  title,
  sectionTitle,
  label,
  onItemClick,
  buttonIcon,
  disabled = false,
  withQR = true,
  readonly = false,
  filter_types = [RELATIONSHIP_TYPE.CUSTOMER, RELATIONSHIP_TYPE.PARTNER],
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [searchApi, { data: { items = [] } = {}, isLoading }] =
    useContactsLazyQuery({ limit: 5 });
  const [getContactApi, { isLoading: isGettingCompany }] =
    useContactLazyQuery();

  const handleButtonClick = useCallback(
    (item) => {
      onItemClick?.(item);
      setShow(false);
    },
    [onItemClick],
  );

  // https://test.metacask.com:4430/cask/edit/c/c-t/123 => [c,c-t,123]
  const handleOnQrRead = useCallback(
    async ({ text: qrString }) => {
      console.log('Read QR code', qrString);
      try {
        const origin =
          (typeof window !== 'undefined' && window?.location?.origin) ||
          PUBLIC_APP_BASE_URL;
        const route = PATH;
        const check = `${origin}${route}/`;
        if (qrString.includes(check)) {
          const id = qrString.split('/').pop();
          const contact = await getContactApi({ id });
          if (contact) handleButtonClick(contact);
        }
      } catch (error) {
        enqueueSnackbar('Contact not found', { variant: 'info' });
        console.log(error);
      }
    },
    [enqueueSnackbar, handleButtonClick],
  );

  const handleClear = () => {
    setShow(false);
    // setSearching(false);
  };

  const handleSearch = useCallback(
    async (search) => {
      const query = { search, filter_types };
      console.log('Remote search', query);
      searchApi(query)
        .catch((e) => {
          console.log('Failed to search', e);
        })
        .finally(() => {
          setShow(true);
        });
    },
    [searchApi, filter_types],
  );

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      {!!title && <H5 className='section-header'>{title}</H5>}
      {!!sectionTitle && <H6 className='section-title'>{sectionTitle}</H6>}
      <Stack className='search-results' spacing='1rem'>
        <FlexBox gap={1}>
          {withQR && (
            <ScanQR
              onSuccess={handleOnQrRead}
              closeOnSuccess
              disabled={disabled}
            />
          )}

          <SearchField
            label={label}
            id={label}
            disabled={disabled}
            searching={isLoading}
            onClear={handleClear}
            onSearch={handleSearch}
            fullWidth
          />
        </FlexBox>
        {!readonly && show && !isLoading && (
          <Collapse in={show}>
            {items.length ? (
              <Box className='search-result-contents'>
                <Stack className='search-section'>
                  <Stack className='list'>
                    {items.map((a) => (
                      <ContactListItem
                        key={a._id}
                        item={a}
                        buttonIcon={buttonIcon || <ArrowForward />}
                        onClick={onItemClick}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Box>
            ) : (
              <Body3>Nothing matches</Body3>
            )}
          </Collapse>
        )}
      </Stack>
    </Stack>
  );
}

export default ContactSearch;
