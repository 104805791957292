import { loadInviteThunk } from '@pw/redux/thunks/invite';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const useInviteHook = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const i = searchParams.get('i');

  console.log('Invite', i);

  return useCallback(
    (id) => {
      console.log('Loading invite', i, id);
      dispatch(loadInviteThunk({ invite: i ?? id }));
    },
    [i],
  );

  // const defaultAccount = location?.state?.account;
  //
  // // console.log('Location', location, defaultAccount, qq);
  //
  // const { account: invitedAccount, invite } = qq ? JSON.parse(base64url.decode(qq)) : {};
  //
  // useEffect(() => {
  //   setInvitedAccount(invitedAccount);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  //
  // const [account, setAccount] = useState(invitedAccount ?? defaultAccount ?? {});
  //
  // const [signed, setSigned] = useState(undefined);
};

export default useInviteHook;
