import { ArrowForward, Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import QRImage from '@pw/components/QRImage';
import IDDisplay from '@pw/components/properties/IDDisplay';
import LabelDisplay from '@pw/components/properties/LabelDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';
import { PATH } from './consts';

export function ContactListItemComponent({ item }) {
  const { _id, type } = item ?? {};
  const isContact = type === 'contact';

  const open = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      window.open(`${PATH}/${_id}`, '_blank').focus();
    },
    [item],
  );

  return (
    <Stack direction='row' spacing={1}>
      <Box
        sx={{
          padding: '3px',
          border: '1px solid #3f3f3f',
          borderRadius: '3px',
        }}
      >
        <QRImage size={56} boxSize={56} value={_id} />
      </Box>
      <Stack>
        <NameDisplay
          name={
            item?.target_id?.name ?? item?.contact_id?.name ?? item?.contact_tag
          }
        />
        <IDDisplay onClick={!isContact ? open : () => {}} value={_id} />
        <LabelDisplay label='Relation' value={type} />
      </Stack>
    </Stack>
  );
}

const ContactListItem = forwardRef(
  (
    {
      item,
      onClick,
      selected,
      onEdit,
      onRemove,
      buttonIcon = <ArrowForward />,
    },
    ref,
  ) => {
    const { readonly } = useContext(FormikContext);

    return (
      <Box className='listItem' ref={ref}>
        {selected && selected(item) && (
          <Box className='listSelected'>
            <TaskOutlined className='check' />
          </Box>
        )}
        <Box className='listContent'>
          <Stack spacing={0.75}>
            <ContactListItemComponent item={item} />
          </Stack>
        </Box>

        <Stack className='listButtons'>
          {!readonly && onClick && (
            <IconCircleButton onClick={() => onClick(item)}>
              {buttonIcon}
            </IconCircleButton>
          )}
          {!readonly && onEdit && (
            <IconCircleButton onClick={() => onEdit(item)}>
              <Edit />
            </IconCircleButton>
          )}
          {!readonly && onRemove && (
            <IconCircleButton onClick={() => onRemove(item)}>
              <Delete />
            </IconCircleButton>
          )}
        </Stack>
      </Box>
    );
  },
);

export default ContactListItem;
