import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import FacilitesView from '@pw/components_v2/CompanyView/modals/facilities/FacilitiesView';

function FacilityModal({ company, open, onClose }) {
  const title = `${company.name} Facilities`;
  console.log('Facility modal', company.name);

  return (
    <ModalWithClose onClose={() => onClose()} open={open} title={title}>
      <FacilitesView company={company} /> 
    </ModalWithClose>
  );
}

export default FacilityModal;
