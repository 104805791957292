const styles = {
  '&.requests-scheduler-root': {
    background: 'red'
  },
  '.top-bar-root': {
    position: 'absolute',
    zIndex: 99,
    width: 'calc(100vw - 250px - 1rem)',
    backgroundColor: 'white',
    top: 0,
    right: 0,
  },

  '.flex': {
    display: 'flex',
    flexWrap: 'row wrap',
  },
};

export default styles;
