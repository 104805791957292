// import { itemWidth, itemHeight } from '@pw/pages/app/production-designer';

export const itemWidth = 190;
export const itemHeight = itemWidth;


const styles = {
  '@keyframes pulse': {
    '0%': { opacity: 0.8, },
    '50%': { opacity: 1, },
    '100%': { opacity: 0.8, },
  },
  '&.node-root': {
    position: 'relative',
    visibility: 'visible',
    overflow: 'visible',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    border: 'none',
    margin: '-10px',

    '&:hover': {
      '.node-handle': { zIndex: 3, }
    },

    '&:hover, &.expanded': {
      '.node-info': {
        maxWidth: 'unset',
        width: 'fit-content',
        flexGrow: 1,
        background: '#fff'
      },
      '.text': {
        opacity: 1,
        transform: 'translateX(0)',
        whiteSpace: 'nowrap'
      },
    }
  },
  '--handle-color': '#EBEAE9',
  // '.label': { color: '#00A89D', },
  '&.node-root:hover': {
    '--handle-color': '#F0AA29',
  },
  '.handle-connected': {
    '--handle-color': '#F0AA29',
    opacity: 1
  },

  '&.node-selected': {
    '&::after': {
      opacity: 1,
      zIndex: -1,
    },
  },


  '.text': {
    display: 'inline-flex',
    paddingRight: '0.5rem',
    transition: 'all 0.3s',
    opacity: 0,
    // transformOrigin: 'left center',
    // transform: 'translateX(-100%)',
  },

  '.node-content': {
    textAlign: 'center',
    width: '10rem',
    height: '10rem',
  },
  '.icon-label-group': {
    width: '100%',
    height: '100%',
    transformOrigin: 'center center'
  },
  '.icon-container': {
    display: 'block',
    position: 'relative',
    width: 'auto',
    lineHeight: 1,

    svg: {
      fontSize: '10rem',
      height: '1em',
      width: 'auto',
      display: 'block',
      // width: 'auto',
    },
  },
  '.label': {
    position: 'relative',
    // top: '1.75rem',
    // top: '-5.5rem',
    // top: '-50%',
    top: '-5.5rem',
    fontSize: `${14 / 16}rem`,
    textTransform: 'capitalize',
  },

  '.handles-container': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },

  '.node-handle': {
    width: '14px',
    height: '14px',
    margin: '-7px',
    transition: 'all 0.8s',
    backgroundImage: 'radial-gradient(var(--handle-color) 0%, var(--handle-color) 35%, white 35%, white 35%)',
    border: '0.125rem solid var(--handle-color)',
    transform: 'unset !important',
    position: 'absolute',
  },
  // offset by padding on root

  '.top': { top: '0', left: '0', },
  '.right': { right: '0', left: 'auto', top: '0', },
  '.bottom': { bottom: '0', top: 'auto', right: '0', left: 'auto' },
  '.left': { left: '0', bottom: '0', top: 'auto' },
  '.top-left': { top: '50%', left: '0' },
  '.top-right': { left: '50%', top: '0' },
  '.bottom-right': { bottom: '50%', right: '0', left: 'auto' },
  '.bottom-left': { left: '50%', bottom: '0' },

};

export default styles;
