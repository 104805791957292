import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import ScanQR from '@pw/components/ScanQR';
import { Body3, H5, H6 } from '@pw/components/Typography';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import SelectedThing from './SelectedThing';
import GeneralSearch from '../Search/GeneralSearch';

function ThingSelector({
	title = null,
	sectionTitle = null,
	list,
	onAdd,
	onEdit,
	onRemove,
	onQrScan,
	display,
	itemFilter = (x) => x,
	types = [],
	single = false,
	summary = true,
	disabled = false
}) {
	const { enqueueSnackbar } = useSnackbar();

	const selectThing = useCallback(
		(thing) => {
			console.log('Selected thing', thing);
			if (
				list.find((i) => i?._id === thing?._id) ||
				(single && list.length > 0)
			) {
				enqueueSnackbar('Thing already added!', { variant: 'info' });
				return false;
			}
			if([thing].filter(itemFilter)?.length === 0){
				enqueueSnackbar('Thing could not be added!', { variant: 'info' });
				return false;
			}
			onAdd(thing);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[enqueueSnackbar, list, onAdd, single],
	);

	return (
		<Stack className='section'>
			{title && <H5 className="section-header">{title}</H5>}
			{sectionTitle && <H6 className="section-title">{sectionTitle}</H6>}
			{
				!(single && list.length === 1)&&(
					<GeneralSearch 
						scan={false}
						// title={title} 
						// sectionTitle={sectionTitle} 
						label="Thing Name"
						types={{thing_types: types}}
						disabled={disabled || (single && list.length === 1)}
						onItemClick={selectThing}
					/>
				)
			}
			{list.length > 0 && (
				<Box className='inventory'>
					{!!onQrScan && (
						<FlexBox className='inventory-header'>
							<ScanQR
								onSuccess={onQrScan}
								closeOnSuccess
								width={24}
								height={24}
								withLabel
								Component={TitledButton}
							/>
						</FlexBox>
					)}
					<Stack spacing={0} className='list'>
						{list.map((thing) => (
							<SelectedThing
								item={thing}
								onEdit={onEdit}
								onRemove={onRemove}
								key={thing?._id}
								display={display}
								disabled={true}
							/>
						))}
					</Stack>
					{summary && (
						<FlexBox className='inventory-footer'>
							<Stack spacing={0.75}>
								<AmountDisplay label='Items' amount={list.length} />
							</Stack>
						</FlexBox>
					)}
				</Box>
			)}
			{list.length === 0 && <Body3>None</Body3>}
		</Stack>
	);
}

export default ThingSelector;
