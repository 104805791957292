
import { memo, useContext, useCallback, useMemo, useEffect } from 'react';
import { useEdges, getConnectedEdges } from 'reactflow';
import { useTranslation } from 'react-i18next';

import DesignerContext from '@pw/context/DesignerContext';
import Handles from './Handles';
// import ProcessToProcessHandles from './ProcessToProcessHandles';
import Box from '@mui/material/Box';

import styles from './Node.styles';
import { Add } from '@mui/icons-material';
import { iconFromName } from '../nodes';

import { ButtonBase } from '@mui/material';
// import Repeat from './NodeBadges/RepeatBadge';
// import TimerBadge from './NodeBadges/TimerBadge';

export default memo(({ data, id, className, dragging, selected, ...rest }) => {
  /* - data prop is the bare data that is set in the node definition in nodes.js
    ( ie category, type, label, icon ...)
     - nodeData is the data saved against the node through forms, start/stop timers etc
  */
  const { nodesData, setNodesData, mode } = useContext(DesignerContext);
  const nodeData = nodesData[id] || {};

  const { i18n } = useTranslation();

  const edges = useEdges();
  const connectedEdges = getConnectedEdges([{ data, id }], edges);

  const updateNodeData = useCallback((payload) => {
    setNodesData((prev) => {
      const update = {
        ...prev,
        [id]: {
          ...prev?.[id],
          ...payload,
        }
      };
      return update;
    });
  }, [nodesData, setNodesData]);

  const { icon = null, label: _label, } = data;
  const {
    repeat = false, interval = null, duration = null, started = null, ended = null
  } = nodeData;

  const label = i18n.t(_label.toLowerCase());

  const Icon = iconFromName(icon);

  return (
    <Box
      className={`node-root ${data.category}-node 
      ${selected ? 'node-selected' : ''}
      ${repeat ? 'repeats' : ''}
      ${className || ''}`}
      sx={styles}
      id={id}
    >
      <Box className="node-content">

        <Box className="icon-label-group">
          <Box className="icon-container">
            {Icon}
          </Box>
          <Box className="label">
            {label}
          </Box>
        </Box>
        {/* <Box className={`more ${data.form ? 'has-form' : ''}`}>
          {data.form && (
            <ButtonBase className="more-button">
              <Add />
            </ButtonBase>
          )}
        </Box> */}
      </Box>

      <Handles
        type={['observation'].includes(data.category)
          ? 'target' : 'source'}
        // type="target"
        isConnectableStart={true}
        connectedEdges={connectedEdges}
        nodeId={id}
      />
    </Box >
  );
});
