import { Box } from '@mui/material';
import Temperature from '@pw/components/icons/Temperature';
import { Body3 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';

function TempDisplay({ label, temp }) {
	if (!temp) return;
	const { value, unit } = temp;
	return (
		<Box
			sx={{
				flexDirection: 'row',
				display: 'flex',
				gap: '0.25rem',
			}}
		>
			<Temperature style={{ width: '12px', height: 'auto' }} />
			<Body3>
				{label && <Bolder>{`${label}: `}</Bolder>}
				{value}
				{unit}
			</Body3>
		</Box>
	);
}

export default TempDisplay;
