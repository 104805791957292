import { Box, Stack } from '@mui/material';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import { FormikForm, FormikNumberField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import SvgAddNew from '@pw/components/icons/AddNew';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import TempDisplay from '@pw/components/properties/TempDisplay';
import TimeDisplay from '@pw/components/properties/TimeDisplay';
import { Body1, Body3, H5 } from '@pw/components/Typography';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TEMPERATURE_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { nanoid } from 'nanoid/non-secure';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';

function TempEditor({ item, open, onClose }) {
  const { id, offset, temp } = item ?? {};

  const changeSet = useMemo(
    () => ({
      id: [id ?? nanoid(), yup.string()],
      offset: unitField(offset, true),
      temp: unitField(temp, true)
    }),
    [offset, temp]
  );

  const handleSubmit = useCallback(
    (temp) => {
      onClose(temp);
    },
    [onClose]
  );

  return (
    <ModalWithClose open={open} onClose={() => onClose()} title="Temperature Setting">
      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Stack spacing={1}>
          <FlexBox>
            <FormikUnitField name="offset" label="Offset" options={TIME_UNIT_OPTIONS} fullWidth />
            <FormikUnitField
              label="Temperature"
              name="temp"
              fullWidth
              options={TEMPERATURE_UNIT_OPTIONS}
            />
          </FlexBox>

          <Box className="action-buttons">
            <TextButton
              size="small"
              handleClick={() => onClose()}
              color="secondary"
            >
              Cancel
            </TextButton>
            <FilledButton type="submit" size="small">
              Save
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </ModalWithClose>
  );
}

function TempItem({ item, onEdit, onRemove }) {
  return (
    <Box className="listItem">
      <Box className="listContent">
        <Stack spacing={0.5}>
          <TimeDisplay label="Offset" time={item?.offset} />
          <TempDisplay label="Temp" time={item?.temp} />
        </Stack>
      </Box>

      <Stack className="listButtons">
        {onEdit && (
          <IconCircleButton onClick={() => onEdit(item)}>
            <SvgEdit style={{ height: '16px', width: '16px' }} />
          </IconCircleButton>
        )}
        {onRemove && (
          <IconCircleButton onClick={() => onRemove(item)}>
            <SvgDelete style={{ height: '16px', width: '16px' }} />
          </IconCircleButton>
        )}
      </Stack>
    </Box>
  );
}

function TempList({ items, onEdit, onRemove }) {
  return (
    <Box className="inventory-contents">
      <Stack className="list">
        {items.map((item) => (
          <TempItem item={item} onEdit={onEdit} onRemove={onRemove} key={item.id} />
        ))}
      </Stack>
    </Box>
  );
}

function TemperatureSequence() {
  const confirm = useConfirm();
  const { values, setFieldValue } = useContext(FormikContext);

  const [temp, , , upsert, remove] = useItemListManager({
    id: o => o.offset,
    comp: (l, r) => l.offset < r.offset,
    initialData: values?.temperature ?? [],
  });

  const [selectedTemp, setSelectedTemp] = useState(null);

  useEffect(() => {
    debounce(() => setFieldValue('temperature', temp), 25);
  }, [temp]);

  const handleRemoveTemp = useCallback((temp) => {
    confirm({
      title: 'Remove Temperature',
      content: <Body1>Remove this temperature point?</Body1>
    })
    .then(() => remove(temp))
    .catch(() => {
    });
  }, [remove]);

  const handleTempUpdate = useCallback(
    (temp) => {
      if (temp) {
        upsert(temp);
      }
      setSelectedTemp(null);
    },
    [upsert, setSelectedTemp]
  );

  return (
    <Stack spacing={1} className="section">
      <H5 className="section-header">Temperature Sequence</H5>
      <Stack className="inventory">
        <Box className="inventory-header">
          <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
          <Stack direction="row" spacing={1}>
            <TitledButton handleClick={() => setSelectedTemp({})} label="New">
              <SvgAddNew height={24} width={24} />
            </TitledButton>
          </Stack>
        </Box>
        {temp.length > 0 && (
          <TempList
            items={temp}
            onEdit={(i) => setSelectedTemp(i)}
            onRemove={(i) => handleRemoveTemp(i)}
          />
        )}
        {temp.length === 0 && (
          <Box p={2} className="inventory-footer">
            <Body3>None</Body3>
          </Box>
        )}
      </Stack>
      {!!selectedTemp && (
        <TempEditor
          open={!!selectedTemp}
          item={selectedTemp}
          onClose={handleTempUpdate}
        />
      )}
    </Stack>
  );
}

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      mash_time: unitField(initialValues?.mash_time),
      agitation_speed: [initialValues?.agitation_speed ?? 0, yup.number()],
      temperature: [initialValues?.temperature ?? [], yup.array().of(yup.object())],
    }),
    []
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FormikUnitField name="mash_time" label="Mash Time" options={TIME_UNIT_OPTIONS} />
        <TemperatureSequence />
        <FormikNumberField name="agitation_speed" label="Agitation Speed" />
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
