import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import { THING_TYPES } from '@pw/consts/thing';
import { useMemo, useState } from 'react';

function LayoutSKUWidget({ title, skus = [] }) {
  const [sku, setSku] = useState(skus?.[0]?.name ?? '');

  const data = useMemo(
    () => (skus ?? [])?.find((item) => item?.sku_name === sku) ?? skus[0] ?? {},
    [sku, skus],
  );

  const unit = useMemo(() => {
    if (data?.sku_type === THING_TYPES.TRACKED) return null;
    return data?.unit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const available = useMemo(() => {
    const total = Number(data?.total_sku_delivered ?? 0);
    const consumed = Number(data?.total_sku_consumed ?? 0);
    const reserved = Number(data?.total_sku_reserved ?? 0);
    return total - (consumed + reserved);
  }, [data]);

  return (
    <Stack className='content-section'>
      <H5 className='content-section-title'>{title}</H5>
      <Stack className='content-section-content'>
        <FlexBox className='headline-widget-input'>
          <FormControl fullWidth variant='filled'>
            <InputLabel id='sku' shrink>
              SKU
            </InputLabel>
            <Select
              native
              fullWidth
              label='SKU'
              labelId='sku'
              value={sku}
              onChange={(event) => setSku(event.target.value)}
            >
              {skus?.map(({ sku_name }) => (
                <option key={sku_name ?? 'none'} value={sku_name}>
                  {sku_name}
                </option>
              ))}
            </Select>
          </FormControl>
        </FlexBox>

        <Stack spacing={1} sx={{ marginTop: '26px' }}>
          <LabelValueRow
            value={data?.total_sku_delivered ?? '0'}
            label='Delivered'
          />
          <LabelValueRow value={available ?? '0'} label='Available' />
          <LabelValueRow
            value={data?.total_sku_reserved ?? '0'}
            label='Reserved'
          />
          <LabelValueRow value={data?.total_sku_consumed ?? '0'} label='Used' />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default LayoutSKUWidget;

export const LabelValueRow = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: label === 'Used' ? 'none' : '1px solid #E0E0E0',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '700',
            lineHeight: '31px',
            color: '#404040',
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: '500',
          color: '#333333',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
