import { ArrowForward, Delete, Edit, TaskOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { SPECIFICATION_EDIT } from '@pw/consts/specification';
import FormikContext from '@pw/context/FormikContext';
import { forwardRef, useCallback, useContext } from 'react';

export function SpecificationListItemComponent({ item }) {
  const {
    _id, name
  } = item ?? {};

  const open = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      window.open(`${SPECIFICATION_EDIT}/${_id}`, '_blank').focus();
    },
    [item],
  );

  return (
    <Stack spacing={0.5} sx={{ flexGrow: 1, textAlign: 'left' }}>
      <Stack spacing={0.5}>
        <IDDisplay value={`${_id}`} onClick={open} />
        <NameDisplay name={name} />
      </Stack>
    </Stack>
  );
}

const SpecificationListComponent = forwardRef(({
  item,
  onClick,
  selected,
  onEdit,
  onRemove,
  buttonIcon = <ArrowForward />,
}, ref) => {
  const { readonly } = useContext(FormikContext);
  return (
    <Box className="listItem" ref={ref}>
      {selected && selected(item) && (
        <Box className="listSelected">
          <TaskOutlined className="check" />
        </Box>
      )}
      <Box className="listContent">
        <Stack spacing={0.75}>
          <SpecificationListItemComponent item={item} />
        </Stack>
      </Box>

      <Stack className="listButtons">
        {!readonly && onClick && (
          <IconCircleButton onClick={() => onClick(item)}>
            {buttonIcon}
          </IconCircleButton>
        )}
        {!readonly && onEdit && (
          <IconCircleButton onClick={() => onEdit(item)}>
            <Edit />
          </IconCircleButton>
        )}
        {!readonly && onRemove && (
          <IconCircleButton onClick={() => onRemove(item)}>
            <Delete />
          </IconCircleButton>
        )}
      </Stack>
    </Box>
  );
});

export default SpecificationListComponent;
