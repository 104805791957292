import { ArrowForward } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5, Overline } from '@pw/components/Typography';
import { ASSET_STATUS } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import { INVENTORY_STATUS } from '@pw/consts/thing';
import styles from '@pw/styles/modal.styles';
import { COMP, ID } from '@pw/utilities/comp';
import debounce from '@pw/utilities/debounce';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { AssetLinks } from '../ThingSelector/items/AssetLinks';
import ReallocateModal from './ReallocateModal';
import AssetThingStorageListItem from '../ThingSelector/items/AssetThingStorageListItem';

function DefectAssetModal({ item, open, onClose }) {
  const [asset, setAsset] = useState(null);
  const [storage, setStorage] = useState(null);

  const [thingStorage, iniThingStorage, , upserThingStorage] =
    useItemListManager();
  const [childAssets, init, , upsertAsset] = useItemListManager();

  const { enqueueSnackbar } = useSnackbar();

  const onCancelHolder = () => {
    setAsset(null);
    setStorage(null);
  };

  const onSelectHolder = useCallback(
    ({
      path,
      rw_asset_id,
      asset_status,
      asset_type,
      unique_location_id,
      processed,
    }) => {
      if (asset_status === ASSET_STATUS.DEFECTIVE) {
        enqueueSnackbar('Defective assets cannot be selected!', {
          variant: 'error',
        });
        return;
      }

      if (path === item?.path) {
        enqueueSnackbar('Please select valid asset!', {
          variant: 'error',
        });
        return;
      }

      if (storage) {
        upserThingStorage({
          ...storage,
          asset_type,
          asset_id: path,
          rw_asset_id,
          processed: INVENTORY_STATUS.CONFIRMED,
        });
      } else if (asset) {
        const parent_asset = {
          asset_id: path,
          rw_asset_id,
          asset_type,
          processed,
          unique_location_id,
        };

        upsertAsset({
          ...asset,
          unique_location_id,
          processed: ASSET_PROCESSED_STATUS.CONFIRMED,
          parent_asset_id: path,
          parent_asset,
        });
      }
      onCancelHolder();
    },
    [
      asset,
      enqueueSnackbar,
      item?.path,
      storage,
      upsertAsset,
      upserThingStorage,
    ],
  );

  const onUpdate = useCallback(() => {
    const isAllChildAssetsConfirmed =
      childAssets.filter(
        ({ processed }) => processed === ASSET_PROCESSED_STATUS.CONFIRMED,
      ).length === childAssets.length;
    const isAllContentConfirmed =
      thingStorage.filter(
        ({ processed }) => processed === ASSET_PROCESSED_STATUS.CONFIRMED,
      ).length === thingStorage.length;

    onClose({
      ...item,
      child_assets: childAssets,
      things: thingStorage,
      processed:
        isAllChildAssetsConfirmed && isAllContentConfirmed
          ? ASSET_PROCESSED_STATUS.CONFIRMED
          : ASSET_PROCESSED_STATUS.PENDING,
    });
  }, [childAssets, item, onClose, thingStorage]);

  useEffect(() => {
    const { child_assets = [], things = [] } = item ?? {};
    things.sort(COMP.thing);
    debounce(() => iniThingStorage(things), 50);
    child_assets.sort(COMP.asset);
    debounce(() => init(child_assets), 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayLinks = (item) => <AssetLinks asset={item} />;

  return (
    <>
      <Modal open={open} onClose={() => onClose()}>
        <Stack sx={styles} className='root' spacing={2}>
          <FlexBox>
            <H5>Reallocate Content</H5>
            <IconButton
              onClick={() => onClose()}
              className='close-btn'
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </FlexBox>
          <Stack spacing={2} className='search-results'>
            <Box className='search-result-contents'>
              {(!!childAssets.length > 0 || !!thingStorage.length) && (
                <>
                  {!!childAssets.length && (
                    <Stack className='search-section'>
                      <Overline className='search-section-title'>
                        Holding
                      </Overline>
                      <Stack spacing={0} className='list'>
                        {childAssets.map((childAsset) => (
                          <AssetListComponentV2
                            key={childAsset.path}
                            item={childAsset}
                            onEdit={setAsset}
                            selected={({ processed }) =>
                              processed === ASSET_PROCESSED_STATUS.CONFIRMED
                            }
                            display={displayLinks}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                  {!!thingStorage.length && (
                    <Stack className='search-section'>
                      <Overline className='search-section-title'>
                        Content
                      </Overline>
                      <Stack spacing={0} className='list'>
                        {thingStorage.map((storage) => (
                          <AssetThingStorageListItem
                            key={storage.thing_record_id}
                            item={storage}
                            buttonIcon={<ArrowForward />}
                            onEdit={setStorage}
                            selected={
                              storage?.processed ===
                              ASSET_PROCESSED_STATUS.CONFIRMED
                            }
                            display={displayLinks}
                          />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </>
              )}
            </Box>
          </Stack>
          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton handleClick={onUpdate} size='small'>
              Save
            </FilledButton>
          </Box>
        </Stack>
      </Modal>
      {(asset || storage) && (
        <ReallocateModal
          onCancel={onCancelHolder}
          onSelect={onSelectHolder}
          storage={storage}
        />
      )}
    </>
  );
}

export default DefectAssetModal;
