import { ArrowForward } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Filter } from '@pw/components/icons';
import { QrScan3 } from '@pw/components/icons';
import { useState } from 'react';
import BasicDatePicker from '@pw/components/Forms/DatePicker';
import dayjs from 'dayjs';
import LayoutRecentAssetsWidget from './LayoutRecentAssetsWidget';
import TasksWidget from './TasksWidget';
import LayoutReportWidget from './LayoutReportWidget';

const LayoutActivity = ({ currentWidth, currentHeight }) => {
  const [activeTab, setActiveTab] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );
  const [selectedToDate, setSelectedToDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );

  const casksData = [
    { id: '23442', location: 'Bay 1/22/22' },
    { id: '23442', location: 'Bay 1/22/22' },
    { id: '23442', location: 'Bay 1/22/22' },
  ];

  const tabs = [
    { id: 1, label: '+ Task' },
    { id: 2, label: '+ Shipment' },
    { id: 3, label: '+ Delivery' },
  ];

  const tasksData = [
    {
      id: '1',
      type: 'cask',
      action: 'Transfer Liquid',
      title: 'Approve budget for Q4',
      description:
        'Review and approve the budget allocation for the upcoming quarter.',
      status: 'Pending',
      dueDate: '2024-12-31',
      sources: { count: 2, bl: 5000, abv: 40 },
    },
    {
      id: '2',
      type: 'ibc',
      action: 'Transfer Liquid',
      title: 'Prepare team presentation',
      description:
        'Create a PowerPoint presentation for the upcoming team meeting.',
      status: 'In Progress',
      dueDate: '2024-12-25',
      sources: { count: 1, bl: 7000, abv: 40 },
    },
  ];

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;
  const styles = {
    layoutActivity: {
      display: 'flex',
      flexDirection: 'column',
    },
    assetsHeader: {
      display: 'flex',
      justifyContent: currentWidth > 789 ? 'space-between' : 'start',
      alignItems: currentWidth > 789 ? 'center' : 'start',
      width: '100%',
      paddingLeft: isSmallScreen ? '8px' : '16px',
      paddingRight: isSmallScreen ? '20px' : '54px',
      boxSizing: 'border-box',
      flexDirection: currentWidth > 789 ? 'row' : 'column',
    },
    assetsHeaderLeft: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '16px',
      boxSizing: 'border-box',
    },
    headerTextBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '9px',
    },
    activityTitle: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '16px' : '19px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    subtitle: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '14px' : '16px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    dateSpan: {
      fontWeight: '400',
      fontSize: 'inherit',
      color: 'inherit',
    },
    filterButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      padding: '10px',
      position: 'relative',
    },
    tabsContainer: {
      display: 'flex',
      gap: '10px',
    },
    tab: (isActive) => ({
      padding: currentWidth < 420 ? '5px 10px' : '10px 20px',
      backgroundColor: isActive ? '#C6C6C6' : 'transparent',
      cursor: 'pointer',
      borderRadius: '4px',
    }),
    tabText: (isActive) => ({
      fontSize: currentWidth < 420 ? '14px' : '16px',
      fontWeight: 400,
      lineHeight: '19px',
      color: isActive ? '#fff' : '#393C3C',
    }),
    filterButtonWrapper: {
      width: '50px',
      height: currentWidth > 789 ? '75px' : '42px',
      position: 'absolute',
      right: '4px',
      top: '10px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    filterButton: {
      borderRadius: '5px',
    },
    fromToDateContainer: {
      height: isSmallScreen ? '150px' : '100px',
      backgroundColor: '#C6C6C6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: isSmallScreen ? 'start' : 'center',
      gap: currentWidth < 420 ? '38px' : isSmallScreen ? '5px' : '15px',
      padding: '16px',
      marginLeft: '42px',
      marginRight: '58px',
      borderRadius: '5px',
      flexDirection: isSmallScreen ? 'column' : 'row',
    },
    dateBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: isSmallScreen ? '100%' :'85%',
      backgroundColor: '#fff',
      height: '56px',
      borderRadius: '5px',
      flexDirection: currentWidth < 420 ? 'column' : 'row',
    },
    datePickerBox: {
      width: '100%',
      backgroundColor: currentWidth < 420 ? '#fff': 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: isSmallScreen ? '4px 8px' : '8px 16px',
    },
    line: {
      width: '1px',
      height: '31px',
      backgroundColor: '#707070',
      opacity: '21%',
    },
    dateLabel: {
      fontWeight: '400',
      fontSize: currentWidth < 420 ? '11px' : isSmallScreen ? '12px' : '13px',
      lineHeight: isSmallScreen ? '1' : '22px',
      color: '#52535B',
    },
    dateValue: {
      fontWeight: '400',
      fontSize: currentWidth < 420 ? '11px' : isSmallScreen ? '14px' : '16px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    updateButton: {
      width: isSmallScreen ? '100px' :'150px',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1565c0',
      },
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: isSmallScreen
        ? '1fr'
        : isMediumScreen
          ? '1fr 1fr'
          : '1fr 1fr 1fr',
      margin: 'auto',
      marginTop: '35px',
      paddingLeft: '42px',
      columnGap: '40px',
      rowGap: '7px',
      width: '100%',
    },
    section: {
      position: 'relative',
      paddingRight: '42px',
    },
    sectionTitle: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '16px' : '19px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    sectionLine: {
      position: 'absolute',
      height: '95%',
      width: '1px',
      top: '0%',
      right: '0',
      border: '1px solid #707070',
      opacity: 0.17,
    },
    reportSection: {
      position: 'relative',
      paddingRight: '40px',
    },
  };
  return (
    <Box id='LayoutActivity' sx={styles.layoutActivity}>
      {/* Assets Header */}
      <Box sx={styles.assetsHeader}>
        <Box sx={styles.assetsHeaderLeft}>
          <IconButton sx={{ marginRight: '20px' }}>
            <QrScan3 height={43} width={29} />
          </IconButton>
          <Box sx={styles.headerTextBox}>
            <Typography variant='h6' sx={styles.activityTitle}>
              ACTIVITY
            </Typography>
            <Typography variant='body1' sx={styles.subtitle}>
              fr.
              <Typography component='span' sx={styles.dateSpan}>
                01.01.24
              </Typography>
              <ArrowForward
                sx={{ verticalAlign: 'middle', marginX: '4px', width: '50px' }}
              />
              to.
              <Typography component='span' sx={styles.dateSpan}>
                01.02.2024
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.filterButtonContainer}>
          {/* Tabs */}
          <Box sx={styles.tabsContainer}>
            {tabs.map((tab) => (
              <Box
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                sx={styles.tab(activeTab === tab.id)}
              >
                <Typography sx={styles.tabText(activeTab === tab.id)}>
                  {tab.label}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Filter Button */}
          {isOpen && <Box sx={styles.filterButtonWrapper} />}
          <IconButton
            sx={styles.filterButton}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Filter color={isOpen ? '#fff' : '#7C7C7C'} />
          </IconButton>
        </Box>
      </Box>

      {/* From-To-Date */}
      {isOpen && (
        <Box sx={styles.fromToDateContainer}>
          <Box sx={styles.dateBox}>
            {/* From */}
            <Box sx={styles.datePickerBox}>
              <Box>
                <Typography variant='body1' sx={styles.dateLabel}>
                  From
                </Typography>
                <Typography variant='caption' sx={styles.dateValue}>
                  {selectedFromDate}
                </Typography>
              </Box>
              <BasicDatePicker
                value={selectedFromDate}
                onChange={(newValue) => {
                  setSelectedFromDate(newValue.format('DD/MM/YYYY'));
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <IconButton ref={inputRef}>
                    {InputProps?.endAdornment}
                  </IconButton>
                )}
              />
            </Box>

            {/* line */}
            <Box sx={styles.line} />

            {/* To */}
            <Box sx={styles.datePickerBox}>
              <Box>
                <Typography variant='body1' sx={styles.dateLabel}>
                  To
                </Typography>
                <Typography variant='caption' sx={styles.dateValue}>
                  {selectedToDate}
                </Typography>
              </Box>
              <BasicDatePicker
                value={selectedFromDate}
                onChange={(newValue) => {
                  setSelectedToDate(newValue.format('DD/MM/YYYY'));
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <IconButton ref={inputRef}>
                    {InputProps?.endAdornment}
                  </IconButton>
                )}
              />
            </Box>
          </Box>

          <Button sx={styles.updateButton}>Update data</Button>
        </Box>
      )}

      <Box sx={styles.gridContainer}>
        {/* Your Tasks */}
        <Box sx={styles.section}>
          <Typography variant='h6' sx={styles.sectionTitle}>
            Your Tasks
          </Typography>
          <TasksWidget
            data={tasksData}
            refetch={() => console.log('Refetch tasks')}
          />
          {/* Line */}
          <Box sx={styles.sectionLine} />
        </Box>

        {/* Shipment/Deliveries */}
        <Box sx={styles.section}>
          <Typography variant='h6' sx={styles.sectionTitle}>
            Shipment/Deliveries
          </Typography>
          {casksData.map((item, index) => (
            <LayoutRecentAssetsWidget key={index} data={item} />
          ))}
          {/* Line */}
          <Box sx={styles.sectionLine} />
        </Box>

        {/* Report */}
        <Box sx={styles.reportSection}>
          <LayoutReportWidget title='Report' currentWidth={currentWidth}/>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutActivity;
