import { ArrowForward } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Filter } from '@pw/components/icons';
import { QrScan3 } from '@pw/components/icons';
import { useState } from 'react';
import BasicDatePicker from '@pw/components/Forms/DatePicker';
import dayjs from 'dayjs';
import LayoutAssetCountsWidget from './LayoutAssetCountsWidget';
import RecentAssetsWidget2 from './LayoutRecentAssetsWidget';
import LayoutSKUWidget from './LayoutSKUWidget';

const LayoutAssets = ({ currentWidth, currentHeight }) => {
  const [activeTab, setActiveTab] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );
  const [selectedToDate, setSelectedToDate] = useState(
    dayjs().format('DD/MM/YYYY'),
  );

  const assets = [
    { name: 'Casks Produced', count: 124, color: '#6B57D2' },
    { name: 'Pallets', count: 45, color: '#C7DA65' },
    { name: 'IBC', count: 8, color: '#C753A8' },
  ];

  const casksData = [
    { id: '23442', location: 'Bay 1/22/22', bulkLiquid: '240 liters' },
    { id: '23442', location: 'Bay 1/22/22', bulkLiquid: '240 liters' },
    { id: '23442', location: 'Bay 1/22/22', bulkLiquid: '240 liters' },
  ];

  const tabs = [
    { id: 1, label: '+ Pallet' },
    { id: 2, label: '+ Cask' },
    { id: 3, label: '+ IBC' },
  ];

  const skus = [
    {
      sku_name: 'Malt Barley',
      name: 'Product A',
      total_sku_delivered: 3,
      total_sku_consumed: 2,
      total_sku_reserved: 0,
      sku_type: 'TRACKED',
      unit: 'kg',
    },
    {
      sku_name: 'Corn',
      name: 'Product B',
      total_sku_delivered: 50,
      total_sku_consumed: 10,
      total_sku_reserved: 5,
      sku_type: 'UNTRACKED',
      unit: 'pcs',
    },
    {
      sku_name: 'Wheat',
      name: 'Product C',
      total_sku_delivered: 200,
      total_sku_consumed: 70,
      total_sku_reserved: 50,
      sku_type: 'UNTRACKED',
      unit: 'liters',
    },
  ];

  const isSmallScreen = currentWidth <= 600;
  const isMediumScreen = currentWidth > 600 && currentWidth <= 1024;

  // Styles object
  const styles = {
    header: {
      display: 'flex',
      justifyContent: currentWidth > 789 ? 'space-between' : 'start',
      alignItems: currentWidth > 789 ? 'center' : 'start',
      width: '100%',
      paddingLeft: isSmallScreen ? '8px' : '16px',
      paddingRight: isSmallScreen ? '20px' : '54px',
      boxSizing: 'border-box',
      flexDirection: currentWidth > 789 ? 'row' : 'column',
    },
    headerContent: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '16px',
      boxSizing: 'border-box',
    },
    headerTextBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '9px',
    },
    title: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '16px' : '19px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    subtitle: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '14px' : '16px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    dateSpan: {
      fontWeight: '400',
      fontSize: 'inherit',
      color: 'inherit',
    },
    filterButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      padding: '10px',
      position: 'relative',
    },
    tabsContainer: {
      display: 'flex',
      gap: '10px',
    },
    tab: (isActive) => ({
      padding: currentWidth < 420 ? '5px 10px' : '10px 20px',
      backgroundColor: isActive ? '#C6C6C6' : 'transparent',
      cursor: 'pointer',
      borderRadius: '4px',
    }),
    tabText: (isActive) => ({
      fontSize: currentWidth < 420 ? '14px' : '16px',
      fontWeight: 400,
      lineHeight: '19px',
      color: isActive ? '#fff' : '#393C3C',
    }),
    filterButtonWrapper: {
      width: '50px',
      height: currentWidth > 789 ? '75px' : '42px',
      position: 'absolute',
      right: '4px',
      top: '10px',
      borderRadius: '5px',
      backgroundColor: '#C6C6C6',
    },
    filterButton: {
      borderRadius: '5px',
    },
    fromToDateContainer: {
      height: isSmallScreen ? '150px' : '100px',
      backgroundColor: '#C6C6C6',
      display: 'flex',
      justifyContent: 'center',
      alignItems: isSmallScreen ? 'start' : 'center',
      gap: currentWidth < 420 ? '38px' : isSmallScreen ? '5px' : '15px',
      padding: '16px',
      marginLeft: '42px',
      marginRight: '58px',
      borderRadius: '5px',
      flexDirection: isSmallScreen ? 'column' : 'row',
    },
    dateBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: isSmallScreen ? '100%' :'85%',
      backgroundColor: '#fff',
      height: '56px',
      borderRadius: '5px',
      flexDirection: currentWidth < 420 ? 'column' : 'row',
    },
    datePickerBox: {
      width: '100%',
      backgroundColor: currentWidth < 420 ? '#fff': 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: isSmallScreen ? '4px 8px' : '8px 16px',
    },
    dateLabel: {
      fontWeight: '400',
      fontSize: currentWidth < 420 ? '11px' : isSmallScreen ? '12px' : '13px',
      lineHeight: isSmallScreen ? '1' : '22px',
      color: '#52535B',
    },
    dateValue: {
      fontWeight: '400',
      fontSize: currentWidth < 420 ? '11px' : isSmallScreen ? '14px' : '16px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    updateButton: {
      width: isSmallScreen ? '100px' :'150px',
      height: isSmallScreen ? '40px' : '56px',
      borderRadius: '5px',
      backgroundColor: '#52535B',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#1565c0',
      },
      fontSize: isSmallScreen ? '12px' : '14px',
      lineHeight: isSmallScreen ? '1.2' : '26px',
      fontWeight: '600',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: isSmallScreen
        ? '1fr'
        : isMediumScreen
          ? '1fr 1fr'
          : '1fr 1fr 1fr',
      margin: 'auto',
      marginTop: '35px',
      paddingLeft: '42px',
      columnGap: '40px',
      rowGap: '7px',
      width: '100%',
    },
    section: {
      position: 'relative',
      paddingRight: '42px',
    },
    sectionTitle: {
      fontWeight: '600',
      fontSize: isSmallScreen ? '16px' : '19px',
      lineHeight: '22px',
      color: '#393C3C',
    },
    line: {
      position: 'absolute',
      height: '95%',
      width: '1px',
      top: '0%',
      right: '0',
      border: '1px solid #707070',
      opacity: 0.17,
    },
  };

  return (
    <Box id='LayoutAssets'>
      {/* Assets Header */}
      <Box sx={styles.header}>
        <Box sx={styles.headerContent}>
          <IconButton sx={{ marginRight: '20px' }}>
            <QrScan3 height={43} width={29} />
          </IconButton>
          <Box sx={styles.headerTextBox}>
            <Typography variant='h6' component='div' sx={styles.title}>
              ASSETS
            </Typography>
            <Typography variant='body1' component='div' sx={styles.subtitle}>
              fr.
              <Typography
                component='span'
                sx={styles.dateSpan}
              >
                01.01.24
              </Typography>
              <ArrowForward
                sx={{ verticalAlign: 'middle', marginX: '4px', width: '50px' }}
              />
              to.
              <Typography
                component='span'
                sx={styles.dateSpan}
              >
                01.02.2024
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.filterButtonContainer}>
          {/* Tabs */}
          <Box sx={styles.tabsContainer}>
            {tabs.map((tab) => (
              <Box
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                sx={styles.tab(activeTab === tab.id)}
              >
                <Typography sx={styles.tabText(activeTab === tab.id)}>
                  {tab.label}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Filter Button */}
          {isOpen && <Box sx={styles.filterButtonWrapper}></Box>}
          <IconButton
            sx={styles.filterButton}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Filter color={isOpen ? '#fff' : '#7C7C7C'} />
          </IconButton>
        </Box>
      </Box>

      {/* From-To-Date */}
      {isOpen && (
        <Box sx={styles.fromToDateContainer}>
          <Box
          sx={styles.dateBox}
          >
            {/* From */}
            <Box sx={styles.datePickerBox}>
              <Box>
                <Typography variant='body1' sx={styles.dateLabel}>
                  From
                </Typography>
                <Typography variant='caption' sx={styles.dateValue}>
                  {selectedFromDate}
                </Typography>
              </Box>

              <BasicDatePicker
                value={selectedFromDate}
                onChange={(newValue) => {
                  setSelectedFromDate(newValue.format('DD/MM/YYYY'));
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <IconButton ref={inputRef}>
                    {InputProps?.endAdornment}
                  </IconButton>
                )}
              />
            </Box>

            {/* line */}
            <Box
              sx={{
                width: '1px',
                height: '31px',
                backgroundColor: '#707070',
                opacity: '21%',
              }}
            />

            {/* To */}
            <Box sx={styles.datePickerBox}>
              <Box>
                <Typography variant='body1' sx={styles.dateLabel}>
                  To
                </Typography>
                <Typography variant='caption' sx={styles.dateValue}>
                  {selectedToDate}
                </Typography>
              </Box>

              <BasicDatePicker
                value={selectedToDate}
                onChange={(newValue) => {
                  setSelectedToDate(newValue.format('DD/MM/YYYY'));
                }}
                renderInput={({ inputRef, InputProps }) => (
                  <IconButton ref={inputRef}>
                    {InputProps?.endAdornment}
                  </IconButton>
                )}
              />
            </Box>
          </Box>

          <Button sx={styles.updateButton}>Update data</Button>
        </Box>
      )}

      <Box sx={styles.gridContainer}>
        {/* Assets Overview */}
        <Box sx={styles.section}>
          <Typography variant='h6' sx={styles.sectionTitle}>
            Assets Overview
          </Typography>

          <LayoutAssetCountsWidget data={assets} />

          {/* Line */}
          <Box sx={styles.line} />
        </Box>

        {/* Recent Casks */}
        <Box sx={styles.section}>
          <Typography variant='h6' sx={styles.sectionTitle}>
            Recent Casks
          </Typography>

          {casksData.map((item, index) => (
            <RecentAssetsWidget2 key={index} data={item} />
          ))}

          {/* Line */}
          <Box sx={styles.line} />
        </Box>

        {/* SKUs  */}
        <Box sx={{ position: 'relative', paddingRight: '54px' }}>
          <Typography variant='h6' sx={styles.sectionTitle}>
            SKUs
          </Typography>

          <LayoutSKUWidget title='Stock Overview' skus={skus} />
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutAssets;