function SvgInspection(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <circle cx={31.5} cy={31.5} fill='#ccffd5' stroke='#838383' r={12} />
    </svg>
  );
}
export default SvgInspection;
