import * as yup from 'yup';

function complianceFields(params) {
  return {
    /** Company registration id */
    registration_id: [params?.registration_id, yup.string()],
    /** Tax registration (US: EIN) */
    ein: [params?.ein, yup.string()],
    /** Tax registration (US: TIN) */
    tin: [params?.tin, yup.string()],
    /** US: DSP | UK: Excise ID */
    bond_id: [params?.bond_id, yup.string()],
    /** UK: WOWGR registration */
    wowgr: [params?.wowgr, yup.string()],
  };
}

export default complianceFields;
