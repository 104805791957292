import { Box, Tab, Tabs } from '@mui/material';
import DesignerContext from '@pw/context/DesignerContext';
import { useContext, useState } from 'react';

import RecipeDesignTile from './DraggableTile';
import styles from './ToolBox.styles';

const Bottom = ({ className }) => {
  const { nodeGroups, setNodes } = useContext(DesignerContext);

  const [tab, setTab] = useState(Object.keys(nodeGroups)[0]);

  const currentNodes = nodeGroups[tab];

  return (
    <Box className={`toolbox-root toolbar-root ${className || ''}`} sx={styles}>
      <Box className="toolbox_content outset">
        <Box className="tiles-list">
          {currentNodes.map((v) => (
            <RecipeDesignTile node={v} setNodes={setNodes} key={v.type} />
          ))}
        </Box>
      </Box>
      <Tabs value={tab} onChange={(_, val) => {
        setTab(val);
      }} className="tabs">
        {Object.keys(nodeGroups).map((v) => <Tab key={v} value={v} label={v} />)}
      </Tabs>
    </Box>
  );
};

export default Bottom;
