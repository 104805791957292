import { Box } from '@mui/material';
import TasksIcon from '@pw/components/icons/Tasks';
import { H5 } from '@pw/components/Typography';
import React from 'react';
import TasksWidget from './TasksWidget';
import RecentRequestsWidget from './RecentRequestsWidget';
import ReportWidget from './ReportWidget';

const ActivityPanel = ({refetch, tasks, data}) => {
  return (
    <Box className='content-row'>
      <Box className='content-header'>
        <Box className='title-box'>
          <TasksIcon height={24} width={24} />
          <H5>Activity</H5>
        </Box>
      </Box>

      <Box className='content-body content-3col'>
        <TasksWidget refetch={refetch} data={tasks ?? []} />
        <RecentRequestsWidget
          data={data?.requests ?? []}
          title='Deliveries/Shipments'
        />
        <ReportWidget title='Report' />
      </Box>
    </Box>
  );
};

export default ActivityPanel;
