import handleError from '@pw/redux/thunks/utils/handleError';
import { createLedger, removeLedger, updateLedger } from '@pw/services/ledgers.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const upsertLedgerThunk = createAsyncThunk(
  `ledger/upsert`,
  async (ledger, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Upserting ledger', ledger);
      const response = (ledger._id) ? await updateLedger(ledger) : await createLedger(ledger);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);

export const removeLedgerThunk = createAsyncThunk(
  `ledger/remove`,
  async (ledger, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      console.log('Removing ledger', ledger);
      const response = await removeLedger(ledger);
      console.log('  -->', response._id);
      return fulfillWithValue(response);
    } catch (e) {
      return handleError(e, rejectWithValue);
    }
  },
);
