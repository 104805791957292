import { useCallback } from 'react';

import liquids from '@pw/consts/liquids.js';
import useCompliance from './useCompliance';

const useTaxCalculator = () => {
  const compliance = useCompliance();

  const getTaxCode = useCallback(
    (type, abv) => {
      const liquid = liquids.find((l) => l.value === type);
      // console.log('liquid', liquid);

      if (!liquid) return null;
      if (!abv || !abv.value || !abv.unit) return null;

      return compliance?.getTaxCode(liquid, abv);
    },
    [compliance],
  );

  return {
    getTaxCode,
  };
};

export default useTaxCalculator;
