import { APP_API_GATEWAY } from '@pw/consts/config';
import fetchGet from '@pw/utilities/fetchGet';
import fetchPost from '@pw/utilities/fetchPost';
import objectToQueryString from '@pw/utilities/objectToQueryString';

const BASE = `${APP_API_GATEWAY}/pw`;

export const getSpecification = async (params, headers = {}) =>
  fetchGet(`${BASE}/v2/get${objectToQueryString({ ...params, type: 'specification' })}`, headers);

export const createSpecification = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/create`, headers, { ...data, type: 'specification' });

export const updateSpecification = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/update`, headers, { ...data, type: 'specification' });

export const removeSpecification = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/remove`, headers, { ...data, type: 'specification' });

export const listSpecifications = async (data, headers = {}) =>
  fetchPost(`${BASE}/v2/list`, headers, { ...data, type: 'specification' });
