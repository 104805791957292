import { CONFIRM_PARTNER_INVITE } from '@pw/components_v2/ContactView/consts';
import { useInviteStateHook } from '@pw/redux/containers/Public';
import { useAccountEmail, useCompanyId } from '@pw/redux/containers/User';
import { UrlDataEncoder } from '@pw/utilities/UrlDataEncoder';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// used to link partner invitation for new users.
function usePartnerConnectLinkProcess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, state } = useInviteStateHook();
  const company_id = useCompanyId();
  const account_email = useAccountEmail();

  const _navigateToNewCompanyForm = useCallback(() => {
    navigate(`/account/companies/new`, {
      replace: true,
    });
  }, [navigate]);

  const _navigateToPartnerConnectModal = useCallback(() => {
    const data = UrlDataEncoder.encodeData(state);
    state?.type === 'contact' &&
      navigate(
        `/crm/contacts/new?action=${CONFIRM_PARTNER_INVITE}&data=${data}`,
        { replace: true },
      );
  }, [navigate]);

  const onAccountCreated = useCallback(
    (res) => {
      /** partnership connection */
      if (state?.type === 'contact') {
        // [√] check auth and is invited user
        if (res?.account?.email === state?.contact_id?.email) {
          // [√] check company setup is complete
          if (res?.company?._id) {
            // partner confirmation popup
            _navigateToPartnerConnectModal();
          } else {
            // [√] navigate to register company flow
            _navigateToNewCompanyForm();
          }
        }
      } else {
        navigate('/account/settings', { replace: true });
      }
    },
    [
      account_email,
      _navigateToPartnerConnectModal,
      _navigateToNewCompanyForm,
      navigate,
    ],
  );

  const setInvitedAccount = useCallback(() => {
    // [√] check invitedAccount url
    if (state?.type === 'contact') {
      // [√] check auth and is invited user
      if (account_email === state?.contact_id?.email) {
        // [√] check company setup
        if (company_id) {
          // partner confirmation popup
          _navigateToPartnerConnectModal();
        } else {
          // [√] navigate to register company flow
          _navigateToNewCompanyForm();
        }
      }
    }
  }, [
    account_email,
    company_id,
    dispatch,
    navigate,
    _navigateToNewCompanyForm,
  ]);

  return {
    setInvitedAccount,
    onCompanyCreated: _navigateToPartnerConnectModal, // [√] listen for company creation success
    onAccountCreated,
  };
}

export default usePartnerConnectLinkProcess;
