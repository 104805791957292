import * as yup from 'yup';

function requestIdFields(params = {}) {
  const { name = '', created = Date.now(), due = Date.now() } = params;
  return {
    name: [name, yup.string().required('Name is required!')],
    created: [created, yup.number().required('Date is required!')],
    due: [due, yup.number().required('Due Date is required!')],
  };
}
export default requestIdFields;
