import * as yup from 'yup';

export function explorerLocationFields(fields = {}) {
	const {
		location_id = '',
		bay_id = '',
		row_id = '',
		level_id = '',
	} = fields;

	return {
		location_id: [location_id, yup.string().required('Facility is required!')],
		bay_id: [bay_id, yup.string().required('Bay is required!')],
		row_id: [row_id, yup.string()],
		level_id: [level_id, yup.string()],
	};
}
