import { SLICE } from '@pw/redux/containers/Public/consts';
import { loadInvite } from '@pw/services/company.service';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const loadInviteThunk = createAsyncThunk('invite/load', async (invite, { getState, rejectWithValue }) => {
  const state = getState();
  console.log('Thunk state', state[SLICE].invite?.loading);
  try {
    console.log('Getting invite...');
    return await loadInvite(invite);
  } catch (error) {
    return rejectWithValue(error.message);
  }
}, {
  condition: (_, { getState }) => {
    const state = getState();
    console.log('Condition state', state[SLICE].invite?.loading);
    // If already loading, don't start a new request
    return !state[SLICE].invite?.loading;
  }
});
//
// export const deleteAssetThunk = createAsyncThunk('asset/delete', async (id, { rejectWithValue }) => {
//   try {
//     return await deleteAsset({id});
//   } catch (error) {
//     return rejectWithValue(error.message);
//   }
// });
//
// export const assetApproveRejectThunk = createAsyncThunk('asset/admin', async (params, { rejectWithValue }) => {
//   try {
//     return await updateAssetsAdmin(params);
//   } catch (error) {
//     return rejectWithValue(error.message);
//   }
// });
