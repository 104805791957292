import * as yup from 'yup';

export function companyTypeFields(params) {
  return {
    company_type: [
      params?.company_type,
      yup.string(),
    ],
    features: {
      warehousing: [!!params?.features?.warehousing, yup.boolean()],
      production: [!!params?.features?.production, yup.boolean()],
      bottling: [!!params?.features?.bottling, yup.boolean()],
    },
  };
}
