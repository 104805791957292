import { withAppLayout } from '@pw/components/Layout/AppLayout';
import ContactView from '@pw/components_v2/ContactView';
import { ContactType } from '@pw/components_v2/contact/consts';
import contactFields from '@pw/components_v2/contact/contactFields';
import { useMemo } from 'react';
import * as yup from 'yup';

function ContactViewPage(props) {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      ...contactFields(initialValues),
      // make type with default value
      contact_type: [
        initialValues?.type ?? ContactType.PERSON,
        yup.string().required('Contact type is required!'),
      ],
    }),
    [],
  );

  return (
    <ContactView
      changeSetGenerator={changeSetGenerator}
      {...props}
    ></ContactView>
  );
}

export default withAppLayout(ContactViewPage, { title: 'Contacts' });
