import {
  AccessTime,
  AccountCircle,
  AddCircle,
  Archive,
  Cancel,
  CheckCircle,
  CopyAllOutlined,
  Delete,
  Done,
  Download,
  FactCheck,
  ImportantDevices,
  PendingActionsTwoTone,
  Print,
} from '@mui/icons-material';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AssigneeProps from '@pw/components/AssigneeProps';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import CreatorProps from '@pw/components/CreatorProps';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import RequestQRCode from '@pw/components/QRImage/RequestQRCode';
import DeliveryModal from '@pw/components/ThingSelector/modals/import/DeliveryModal';
import ImportModal from '@pw/components/ThingSelector/modals/import/ImportModal';
import TaskModal from '@pw/components/Tasks/TaskModal';
import { Body1, H5, Overline } from '@pw/components/Typography';
import { Delivery } from '@pw/components/icons';
import {
  REQUEST_EDIT_URL,
  REQUEST_STATUS,
  REQUEST_TYPES,
  REQUEST_TYPES_REVERSE,
} from '@pw/consts/requests';
import {
  useCompanyThings,
  useCurrentCompany,
  useIsAccountActive,
} from '@pw/redux/containers/User/hooks';
import styles from '@pw/styles/content.styles';
import { toTasksCardEntity } from '@pw/utilities/format';
import { useIsSameCompany } from '@pw/utilities/hooks/logic/useCheckCompany';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import printElement from '@pw/utilities/printElement';
import { useCallback, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import RequestSummaryHTML from './RequestSummaryHTML';
import ImportThingModal from '../ThingSelector/modals/import/ImportThingModal';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import ProgressButtons from '@pw/components_v2/Request/ProgressButtons';

function RequestSummary({ entity, refresh }) {
  const navigate = useNavigate();
  const company = useCurrentCompany();
  const converter = useConverter();
  const hasPermission = usePermissions();

  const { company_id, type, status, sources } = entity;

  const { things, assets } = sources ?? {};

  const { readonly } = useReadOnly(entity);
  const sameCompany = useIsSameCompany();
  const userIsActive = useIsAccountActive();

  const printRef = useRef(null);
  const existingThings = useCompanyThings();

  const [importSkuList, setImportSkuList] = useState(null);
  const [mappedSkuList, setMappedSkuList] = useState(null);
  const [deliverySkus, setDeliverySkus] = useState(null);

  const showImport = useMemo(
    () =>
      !sameCompany &&
      userIsActive &&
      REQUEST_TYPES.SHIP === type &&
      REQUEST_STATUS.DONE === status,
    [status, type, sameCompany, userIsActive],
  );

  const showDownload = useMemo(
    () => sameCompany && userIsActive && REQUEST_TYPES.DUTY_SUBMISSION === type,
    [type, sameCompany, userIsActive],
  );

  const showDelivery = useMemo(
    () =>
      sameCompany &&
      userIsActive &&
      REQUEST_TYPES.PURCHASE_ORDER === type &&
      REQUEST_STATUS.DONE === status,
    [type, sameCompany, userIsActive],
  );

  const showActions = useMemo(
    () => userIsActive && !readonly,
    [readonly, userIsActive],
  );

  const shipment = useMemo(() => {
    return (
      entity?.type === REQUEST_TYPES.SHIP &&
      ![REQUEST_STATUS.CANCELLED, REQUEST_STATUS.REJECTED].includes(
        entity?.status,
      )
    );
  }, [entity]);

  const canApprove = hasPermission(
    PermissionKey?.[entity?.type],
    PermissionType.APPROVE,
  );
  const assignTask = hasPermission(
    PermissionKey?.[entity?.type],
    PermissionType.ASSIGN,
  );

  const [actionKey, setActionKey] = useState(null);

  const requestCards = useMemo(() => {
    const t = REQUEST_TYPES_REVERSE[type];
    return toTasksCardEntity(entity);
  }, [entity, type]);

  const handlePrint = (ref) => {
    let element;
    if (entity?.type !== REQUEST_TYPES.SHIP) {
      element = ref?.current?.innerHTML;
    } else {
      element = renderToString(
        <RequestSummaryHTML
          entity={entity}
          converter={converter}
          skus={existingThings}
        />,
      );
    }
    printElement({
      element,
      styles: `
    * { 
      padding: 0; 
      margin: 0; 
    } 
    
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr:nth-of-type(even) {
      background-color: #dddddd;
    }`,
    });
  };

  const handleNew = () => {
    const route = REQUEST_EDIT_URL?.[type];
    console.log('Route', type, route);
    navigate(route, { replace: true });
  };

  const handleClone = () => {
    const route = REQUEST_EDIT_URL?.[type];
    const str = JSON.stringify({
      ...entity,
      status: REQUEST_STATUS.PENDING,
    });
    const base = Buffer.from(str).toString('base64');

    navigate(`${route}?clone=${base}`, { replace: true });
  };

  const handleImport = useCallback(() => {
    // Need to check this request to make sure we have all the skus from this request
    // There are several places where there are SKUs
    const skus = {};
    // 1. Check the SKU's themselves..
    things?.forEach((thing) => {
      if (!skus.hasOwnProperty(thing._id)) {
        skus[thing._id] = {
          thing_id: thing._id,
          thing_name: thing.name,
          thing_type: thing.type,
          thing_description: thing.description,
        };
      }
    });
    // 2. Check the sources
    assets?.forEach((asset) => {
      const {
        properties = {}, // this contains nested skus
      } = asset;
      const {
        thing_id: assetThingId,
        thing: assetThing,
        liquid = {},
      } = properties;
      const { thing_id: liquidThingId, thing: liquidThing } = liquid;
      // ignore the sales skus
      if (assetThingId && assetThing) {
        if (!skus.hasOwnProperty(assetThingId)) {
          skus[assetThingId] = {
            thing_id: assetThingId,
            thing_name: assetThing.name,
            thing_type: assetThing.type,
            thing_description: assetThing.description,
          };
        }
      }

      if (liquidThingId && liquidThing) {
        if (!skus.hasOwnProperty(liquidThingId)) {
          skus[liquidThingId] = {
            thing_id: liquidThingId,
            thing_name: liquidThing.name,
            thing_type: liquidThing.type,
            thing_description: liquidThing.description,
          };
        }
      }
    });

    // Find any matching ones already and populate it
    console.log('SKUs', Object.values(skus));
    console.log('Existing SKUs', existingThings);
    const mappedSkus = Object.values(skus).map((thing) => {
      const existingSku = existingThings.find(
        (s) => s.name.toLowerCase() === thing.name.toLowerCase(),
      );
      if (existingSku) {
        return {
          ...thing,
          mapped_sku: existingSku,
        };
      }
      return thing;
    });

    console.log('SKUs', mappedSkus);

    // Pop up a window to allow user to map SKUs..
    setImportSkuList(mappedSkus);
  }, [things, assets]);

  // Now that we know
  const onHandleSkuMappings = (skus) => {
    // Now we need to construct a delivery request with the details from this shipment
    // We need the back-end to handle any importing of assets, need to make sure they are migrated assets
    // So, now we can create a synthetic delivery with the details in the shipment
    setMappedSkuList(skus);
  };

  const handleAction = (key) => setActionKey(key);

  const handleDownload = useCallback(() => {
    // Convert JSON object to string
    const jsonString = JSON.stringify(
      {
        ...(entity?.properties ?? {}),
        company_address: company?.company_address,
      },
      null,
      2,
    ); // null and 2 are for pretty printing with 2 spaces of indentation
    // Create a Blob with the JSON string and set its type to 'application/json'
    const blob = new Blob([jsonString], { type: 'application/json' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set the download attribute with a filename
    const a = document.createElement('a');
    a.href = url;
    a.download = `${entity?.properties?.report_type ? entity?.properties?.report_type : new Date(entity?.properties?.start_date ?? '').toLocaleDateString()}.json`;
    // Programmatically trigger a click event on the anchor element to start the download
    a.click();
    // Clean up by revoking the Blob URL
    URL.revokeObjectURL(url);
  }, [entity?.properties, company]);

  const handleDelivery = useCallback(() => {
    setDeliverySkus(things);
  }, [navigate, things]);

  const onCloseModal = useCallback(
    (forceRefresh) => {
      if (forceRefresh) {
        refresh();
      }
      setActionKey(null);
    },
    [refresh],
  );

  return (
    <Stack sx={styles} className='root' spacing={3}>
      <FlexBox>
        <H5>Request</H5>

        {status === REQUEST_STATUS.PENDING && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PendingActionsTwoTone color='disabled' />
            <Overline>Pending</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.IN_PROGRESS && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AccessTime color='secondary' />
            <Overline>In Progress</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.AWAITING_APPROVAL && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FactCheck color='info' />
            <Overline>Awaiting Approval</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.DONE && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckCircle color='success' />
            <Overline>Done</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.CANCELLED && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Cancel color='error' />
            <Overline>Cancelled</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.REJECTED && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <UnpublishedIcon color='error' />
            <Overline>Rejected</Overline>
          </Box>
        )}
        {status === REQUEST_STATUS.ARCHIVED && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Archive color='secondary' />
            <Overline>Archived</Overline>
          </Box>
        )}
      </FlexBox>

      <Box className='summary-qr'>
        <Box ref={printRef} className='qr'>
          <RequestQRCode request={entity} />
        </Box>

        {(showActions || shipment) && (
          <IconCircleButton
            onClick={() => handlePrint(printRef)}
            variant='outlined'
            color='secondary'
          >
            <Print height={24} width={24} />
          </IconCircleButton>
        )}
      </Box>

      {showActions &&
        canApprove &&
        status === REQUEST_STATUS.AWAITING_APPROVAL && (
          <Box className='section'>
            <H5 className='section-header'>Approve Request</H5>
            <Body1>This request needs to be approved.</Body1>
            <FlexBox justifyContent='flex-start'>
              <TitledButton
                handleClick={() => handleAction('approve_selected')}
                label='Approve'
              >
                <Done height={24} width={24} color='success' />
              </TitledButton>
              <TitledButton
                handleClick={() => handleAction('reject_selected')}
                label='Reject'
              >
                <Cancel height={24} width={24} color='error' />
              </TitledButton>
            </FlexBox>
          </Box>
        )}

      <FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
        {userIsActive && (
          <TitledButton handleClick={handleNew} label='New'>
            <AddCircle height={24} width={24} />
          </TitledButton>
        )}
        {userIsActive && sameCompany && (
          <TitledButton handleClick={handleClone} label='Clone'>
            <CopyAllOutlined height={24} width={24} />
          </TitledButton>
        )}

        {showActions &&
          [
            REQUEST_STATUS.PENDING,
            REQUEST_STATUS.IN_PROGRESS,
            REQUEST_STATUS.AWAITING_APPROVAL,
          ].includes(status) && (
            <>
              {assignTask && (
                <TitledButton
                  handleClick={() => handleAction('assign_selected')}
                  label='Assign'
                >
                  <AccountCircle height={24} width={24} />
                </TitledButton>
              )}
              <TitledButton
                handleClick={() => handleAction('cancel_selected')}
                label='Cancel'
              >
                <Delete height={24} width={24} color='error' />
              </TitledButton>
              <ProgressButtons entity={entity} refresh={refresh} />
            </>
          )}
        {showImport && (
          <TitledButton handleClick={handleImport} label='Import'>
            <ImportantDevices height={24} width={24} />
          </TitledButton>
        )}
        {showDownload && (
          <TitledButton handleClick={handleDownload} label='Download'>
            <Download height={24} width={24} />
          </TitledButton>
        )}
        {showDelivery && (
          <TitledButton handleClick={handleDelivery} label='Delivery'>
            <Delivery height={24} width={24} />
          </TitledButton>
        )}
      </FlexBox>

      {sameCompany && (
        <>
          <CreatorProps entity={entity} />

          <AssigneeProps entity={entity} />
        </>
      )}

      {(canApprove || assignTask) && actionKey && (
        <TaskModal
          tasks={[requestCards]}
          onClose={onCloseModal}
          actionKey={actionKey}
        />
      )}

      {!!importSkuList && (
        <ImportThingModal
          open={!!importSkuList}
          things={importSkuList}
          companyId={company_id}
          handleImportComplete={onHandleSkuMappings}
          onClose={() => setImportSkuList(null)}
        />
      )}

      {!!mappedSkuList && (
        <ImportModal
          open={!!mappedSkuList}
          things={mappedSkuList}
          entity={entity}
          onClose={() => setMappedSkuList(null)}
        />
      )}

      {!!deliverySkus && (
        <DeliveryModal
          open={!!deliverySkus}
          things={deliverySkus}
          entity={entity}
          onClose={() => setDeliverySkus(null)}
        />
      )}
    </Stack>
  );
}

export default withSmallDialogCard(RequestSummary);
