import { Done } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import TaskCard from '@pw/components/Tasks/TaskCard';
import TaskModal from '@pw/components/Tasks/TaskModal';
import { H5 } from '@pw/components/Typography';
import { PermissionKey, PermissionType } from '@pw/consts/permissions';
import { useCompanyUsers } from '@pw/redux/containers/User/hooks';
import { EQUAL } from '@pw/utilities/comp';
import usePermissions from '@pw/utilities/hooks/logic/usePermissions';
import useSelections from '@pw/utilities/hooks/logic/useSelections';
import { useState } from 'react';
import { nanoid } from 'nanoid/non-secure';
import TaskCard2 from '@pw/components/Tasks/TaskCard2';

function TasksWidget({ data = [], refetch }) {
	const hasPermission = usePermissions();
	const [selection = [], , toggleSelection, isSelected] = useSelections([], EQUAL.request);
	// TODO: think about permission
	const canApprove = hasPermission(PermissionKey.CASK, PermissionType.APPROVE);
	const [actionKey, setActionKey] = useState(null);
	return data.length ? (
		<Stack className='content-section'>
			<H5 className='content-section-title'>Tasks</H5>
			<Stack gap={1} className='content-section-content list recent-list task-content'>
				{data.map((t) => (
					<TaskCard2
						key={nanoid()}
						task={t}
						selected={isSelected(t)}
						onSelect={() => toggleSelection(t)}
					/>
				))}
				{/* {data.map((t) => (
					<TaskCard
						key={nanoid()}
						task={t}
						selected={isSelected(t)}
						onSelect={() => toggleSelection(t)}
					/>
				))} */}
			</Stack>
			{canApprove && (
				<FlexBox className='content-section-footer'>
					<TitledButton
						handleClick={() => setActionKey('approve_selected')}
						label='Approve'
						disabled={!selection.length}
					>
						<Done height={24} width={24} />
					</TitledButton>
					<TaskModal
						tasks={selection}
						onClose={(forceRefresh) => {
							setActionKey(null);
							if (forceRefresh) {
								refetch();
							}
						}}
						actionKey={actionKey}
					/>
				</FlexBox>
			)}
		</Stack>
	) : null;
}

export default TasksWidget;
