import { ModalWithClose } from '@pw/components/Dialogs/ModalWithClose';
import Instructions from '@pw/components/Instructions';
import { THING_TYPES } from '@pw/consts/thing';
import AmountThing from './forms/AmountThing';
import ServiceThing from './forms/ServiceThing';

function InvoiceThingModal({ item, open, onClose, ...rest }) {
	// We track the "amount" we want at the Thing Item level
	const { name, type, description } = item ?? {};

	return (
    <ModalWithClose open={open} onClose={() => onClose()} title={name}>
      <Instructions>{description}</Instructions>

      {[THING_TYPES.SERVICES].includes(type) && (
        <ServiceThing item={item} onClose={onClose} {...rest} />
      )}

      {![THING_TYPES.SERVICES].includes(type) && (
        <AmountThing item={item} onClose={onClose} {...rest} />
      )}
    </ModalWithClose>
  );
}

export default InvoiceThingModal;
