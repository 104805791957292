import { Box } from '@mui/material';
import withDialogCard from '@pw/components/Cards/DialogCard';
import ItemSkeleton from '@pw/components/sekeletons/ItemSkeleton';
import SkeletonSummary from '@pw/components/sekeletons/SkeletonSummary';
import ContactEditor from '@pw/components_v2/ContactView/ContactEditor';
import ContactSummary from '@pw/components_v2/ContactView/ContactSummary';
import { PATH } from '@pw/components_v2/ContactView/consts';
import { usePageTitleHook } from '@pw/redux/containers/App/hooks';
import styles from '@pw/styles/content.styles';
import debounce from '@pw/utilities/debounce';
import useScrollTarget from '@pw/utilities/hooks/logic/useScrollTarget';
import { usePartnerContactV2Query } from '@pw/utilities/hooks/service/usePartnerContactV2Query';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CompanyNavigation from './ContactNavigation';

const FormImpl = withDialogCard(({ children, readonly, entity, onSave, ...rest }) => {
  const navigate = useNavigate();
  const [target, scroll] = useScrollTarget();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(scroll, []);

  const handleSave = (mutated) => {
    console.log('Mutated', mutated);

    debounce(() => {
      onSave?.(mutated);
      navigate(`${PATH}/${mutated?.target_id?._id}`, {
        replace: true,
      });
    }, 50);
  };

  return (
    <Box sx={styles} className='root' ref={target}>
      <ContactEditor onSave={handleSave} entity={entity?.target_id} {...rest}>
        {children}
      </ContactEditor>
    </Box>
  );
});

function NewForm({ changeSetGenerator, onInit, ...rest }) {
  const [searchParams] = useSearchParams();

  usePageTitleHook('Create Contact');

  const clone = searchParams?.get('clone');

  const formValues = useMemo(() => {
    let obj = {};
    if (clone) {
      try {
        const cv = Buffer.from(`${clone}`, 'base64').toString('utf-8');
        obj = JSON.parse(cv);
      } catch (e) {
        console.log('Failed to decode clone!', e);
      }
    }

    // Always call onInit, but with different arguments
    if (onInit) {
      onInit(clone ? obj : {});
    }
    return { ...obj };
  }, [clone, onInit]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  return <FormImpl changeSet={changeSet} {...rest} />;
}

function ShowForm({ changeSetGenerator, onInit, ...rest }) {
  const { id } = rest;
  const { data: entity, isError, refetch, isLoading } = usePartnerContactV2Query({ id });
  const contact = entity?.target_id;

  usePageTitleHook(`Contact ${contact?.name ?? '...'}`);

  // Generate the change set
  const formValues = useMemo(() => {
    // Specific initializer
    if (onInit) {
      onInit(contact);
    }
    return contact ?? {};
  }, [entity]);

  const changeSet = useMemo(
    () => changeSetGenerator(formValues),
    [changeSetGenerator, formValues],
  );

  const refreshData = useCallback(() => refetch({ id }), [id, refetch]);

  return (
    <>
      {isLoading && (
        <>
          <SkeletonSummary />
          <ItemSkeleton />
        </>
      )}

      {isError && (
        <NewForm
          changeSetGenerator={changeSetGenerator}
          onInit={onInit}
          {...rest}
        />
      )}

      {entity && changeSet && (
        <>
          <ContactSummary entity={entity} refresh={refreshData} />
          <FormImpl
            entity={entity}
            changeSet={changeSet}
            edit
            onSave={refreshData}
            {...rest}
          />
        </>
      )}
    </>
  );
}

function FormHandler(props) {
  // See if there is a path parameter
  let { id } = useParams();

  return (
    <Box className='adaptive-content'>
      {!id && <NewForm {...props} />}
      {id && <ShowForm id={id} {...props} />}
      <CompanyNavigation />
    </Box>
  );
}

export default FormHandler;
