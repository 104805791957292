function SvgBottleCapper(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 17 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#BottleCapper_svg__clip0_146_145)' fill='currentColor'>
        <path d='M.006 7.98c0-1.298.018-2.596-.006-3.893-.016-.813.298-1.449.876-1.967C1.743 1.338 2.789.912 3.88.604c2.126-.597 4.287-.716 6.472-.51 1.419.135 2.813.393 4.116 1.004a8.812 8.812 0 011.655 1.04c.611.478.883 1.15.877 1.962a741.48 741.48 0 000 7.948c0 .355-.112.575-.44.707-.621.254-1.23.535-1.853.784-.18.072-.233.157-.233.348.007 2.1.007 4.199.005 6.298-.005 2.883-1.832 5.202-4.606 5.674-1.77.3-3.527.188-5.053-.973-1.471-1.118-2.266-2.62-2.29-4.499-.029-2.162-.011-4.324-.003-6.489 0-.204-.059-.292-.246-.368-.636-.258-1.262-.548-1.897-.81-.277-.115-.38-.308-.38-.607.007-1.378.002-2.757.002-4.135v.002zm13.627 5.927c-3.437.997-6.84.986-10.256.005-.004.094-.01.157-.01.22 0 2.081-.023 4.16.006 6.242.022 1.697.77 3.022 2.15 3.958 1.322.896 2.809.92 4.282.66 2.276-.402 3.815-2.362 3.824-4.72.006-1.836 0-3.675 0-5.511v-.851l.004-.003zm2.502-8.332c-.356.236-.668.472-1.006.66-1.325.746-2.77 1.09-4.254 1.276-1.917.24-3.832.204-5.734-.158-1.27-.242-2.5-.608-3.606-1.318-.22-.141-.426-.305-.661-.476-.009.088-.02.149-.02.21 0 2.009 0 4.016-.007 6.025 0 .185.088.247.224.31a17.284 17.284 0 005.673 1.592c3.193.326 6.241-.213 9.158-1.574.171-.08.252-.166.25-.375-.009-1.973-.004-3.945-.007-5.918 0-.079-.008-.157-.013-.252l.002-.002zM8.948.87c-1.601-.004-2.75.097-3.883.33-1.083.225-2.143.53-3.079 1.152a4.122 4.122 0 00-.924.867c-.295.368-.281.808-.018 1.208.127.19.283.37.452.523.525.469 1.147.765 1.795 1.006 1.53.57 3.12.792 4.739.83 1.941.045 3.852-.152 5.69-.835.716-.265 1.401-.597 1.948-1.154.63-.642.626-1.286 0-1.929-.463-.473-1.032-.779-1.63-1.026-1.774-.736-3.639-.95-5.09-.97V.87z' />
        <path d='M11.969 18.856c0-.297-.007-.595 0-.892.008-.287.171-.455.417-.455.246 0 .415.166.422.453.01.604.008 1.208 0 1.81-.005.285-.178.456-.42.456-.244 0-.408-.171-.417-.456-.009-.306 0-.613-.002-.919v.003zM2.1 9.915c0-.296-.007-.595 0-.891.008-.288.17-.456.417-.456.246 0 .415.166.421.453a55.25 55.25 0 010 1.81c-.004.285-.177.456-.42.456s-.408-.17-.416-.456c-.01-.305 0-.613-.003-.918v.002zM8.35 11.017c0-.296-.007-.595 0-.89.008-.288.17-.457.417-.457.246 0 .415.166.421.454a55.25 55.25 0 010 1.81c-.004.285-.177.455-.419.455-.244 0-.408-.17-.417-.455-.009-.306 0-.614-.002-.919v.002zM14.562 9.915c0-.296-.006-.595 0-.891.01-.288.172-.456.418-.456.245 0 .415.166.421.453.011.604.009 1.208 0 1.81-.004.285-.178.456-.42.456-.243 0-.408-.17-.416-.456-.01-.305 0-.613-.003-.918v.002zM11.973 16.098c-.006-.46.132-.669.426-.66.286.01.413.211.413.656 0 .099.002.197-.013.296-.037.236-.176.366-.41.375-.223.007-.392-.155-.416-.397-.009-.09 0-.18 0-.27zM5.297 2.137l-.292-.462h1.223l2.543 4.028h-.6l-2.25-3.566h-.626.002zM10.164 4.363c.158-.243.26-.499.31-.764.047-.265-.012-.528-.172-.783a1.75 1.75 0 00-.564-.57 1.475 1.475 0 00-.793-.21c-.303 0-.475.079-.538.236-.064.157-.018.368.136.633H7.97c-.246-.415-.301-.736-.167-.963.134-.227.421-.339.858-.339s.868.106 1.265.317c.396.21.712.503.947.875.195.308.277.609.246.9-.03.293-.112.549-.246.77-.134.22-.32.479-.56.773h2.24l.268.425H9.69L9.463 5.3c.312-.38.547-.69.705-.934l-.004-.004z' />
      </g>
      <defs>
        <clipPath id='BottleCapper_svg__clip0_146_145'>
          <path fill='#fff' d='M0 0h17v26H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgBottleCapper;
