import { SaveRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal, Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import styles from '@pw/components/ProductionDesigner/designer.modal.styles';
import DesignerView from '@pw/components/ProductionDesigner/DesignerView';
import ConfigurableEdge from '@pw/components/ProductionDesigner/Diagram/Edges/ConfigurableEdge';
import Node from '@pw/components/ProductionDesigner/Diagram/NodeBase';
import { materials, production, productionObservations } from '@pw/components/ProductionDesigner/nodes';
import { H5 } from '@pw/components/Typography';
import ModalSwitch from '@pw/components_v2/ThingView/modals/recipe/modals';
import RecipeProvider from '@pw/components_v2/ThingView/modals/recipe/RecipeProvider';
import { DESIGN_NODE_TYPE, DESIGN_TYPE } from '@pw/consts/designs';
import { upsertDesignThunk } from '@pw/redux/thunks/designs';
import { upsertThingThunk } from '@pw/redux/thunks/thing';
import { COMP, ID } from '@pw/utilities/comp';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEdgesState, useNodesState } from 'reactflow';

function RecipeModal({ thing, design, open, onClose }) {
  const dispatch = useDispatch();

  const {
    _id,
    name,
    nodes = [],
    edges = [],
    nodes_data = [],
    edges_data = []
  } = design;

  const nodesState = useNodesState(nodes);
  const edgesState = useEdgesState(edges);

  const nodesData = useItemListManager({ id: ID.id, comp: COMP.id, initialData: nodes_data });
  const edgesData = useItemListManager({ id: ID.id, comp: COMP.id, initialData: edges_data });

  const [selectedNode, setSelectedNode] = useState(null);

  const nodeGroups = useMemo(() => ({
    'Distillation': production,
    'Materials': materials,
    'Observations': productionObservations
  }), []);

  // Supported edge types
  const edgeTypes = useMemo(() => ({
    default: ConfigurableEdge
  }), []);

  // Supported node types
  const nodeTypes = useMemo(() => ({
    [DESIGN_NODE_TYPE.MACHINE]: Node,
    [DESIGN_NODE_TYPE.MATERIAL]: Node,
    [DESIGN_NODE_TYPE.OBSERVATION]: Node
  }), []);

  const state = useMemo(() => ({
    nodesState,
    edgesState,
    nodesData,
    edgesData,
    node: [selectedNode, setSelectedNode]
  }), [nodesState, edgesState, nodesData, edgesData]);

  const handleSave = useCallback(() => {
    // First we will save the design
    dispatch(upsertDesignThunk({
      ...design,
      nodes: nodesState[0],
      edges: edgesState[0],
      nodes_data: nodesData[0],
      edges_data: edgesData[0]
    }))
    .unwrap()
    .then((updatedDesign) => {
      // If previously we had no _id, means first time creating this design for this thing..
      // Update the thing with the recipe
      console.log('Upserted design', updatedDesign._id);
      if (!_id) {
        const updatedThing = {
          ...(thing ?? {}),
          design: updatedDesign._id,
        };
        console.log('Updating thing', updatedThing);
        // Update the thing too
        dispatch(upsertThingThunk(updatedThing))
        .unwrap()
        .then(onClose);
      } else {
        onClose();
      }
    });
  }, [thing, design, _id, nodesState, edgesState, nodesData, edgesData]);

  return (
    <Modal open={open} onClose={onClose}>
      <Stack sx={styles} className="root" spacing={2}>
        <FlexBox>
          <Stack direction="row" spacing={2} alignItems="center">
            <H5>Recipe Design - {name}</H5>
          </Stack>

          <Stack direction="row" spacing={2}>
            <IconButton
              onClick={handleSave}
              aria-label="Save"
            >
              <SaveRounded />
            </IconButton>
            <IconButton
              onClick={() => onClose()}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </FlexBox>

        <RecipeProvider
          name={name}
          state={{ ...state, node: [selectedNode, setSelectedNode] }}
          type={DESIGN_TYPE.LIQUID}
          nodeGroups={nodeGroups}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
        >
          <DesignerView>
            <ModalSwitch />
          </DesignerView>
        </RecipeProvider>
      </Stack>
    </Modal>
  );
}

function RecipeDesign({ thing, open, onClose }) {
  // Load the specific design for this liquid
  const { design } = thing;

  const newDesign = useCallback(() => ({
    name: thing.name,
    type: DESIGN_TYPE.LIQUID,
    description: `Recipe Design for ${thing.name}`,
    company_id: thing.company_id,
    edges: [],
    nodes: [],
    edges_data: [],
    nodes_data: []
  }), [thing]);

  return (
    <RecipeModal thing={thing} design={design ?? newDesign()} open={open} onClose={onClose} />
  );
}

export default RecipeDesign;
