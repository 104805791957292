import { Collapse } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import ContactSearch from '@pw/components_v2/search/contact';
import CurveSearch from '@pw/components_v2/search/curves';
import { upsertLedgerThunk } from '@pw/redux/thunks/ledger';
import { useLedgerLazyQuery } from '@pw/utilities/hooks/service/useLedgerQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

function LedgerEditor({ entry, company, readonly, onClose }) {
  const dispatch = useDispatch();

  // Load the item on demand
  const [load, { isLoading, cancel }] = useLedgerLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = useMemo(() => ({
    name: [item?.name, yup.string()],
    description: [
      item?.description ?? '',
      yup.string().required('Ledger description required!'),
    ],
    client: [item?.client, yup.object()],
    curve: [item?.curve, yup.object()],
  }), [item]);

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?._id) {
      load({ id: entry._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry);
    }
  }, [entry]);

  const handleSubmit = useCallback((values) => {
    console.log('Submitting', values);
    dispatch(
      upsertLedgerThunk({
        ...(item ?? {}),
        ...values,
        company_id: company?._id,
      }),
    )
      .unwrap()
      .then(onClose);
  }, [item, onClose]);

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.name}</H5>
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className='form-content' in={!isLoading} unmountOnExit>
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize>
          <Stack spacing='1.5rem'>
            <FormikTextField label='Description' name='description' fullWidth />

            <ContactSearch field="client" />

            <CurveSearch field="curve" />

            <FormButtons onCancel={onClose} readonly={readonly} />
          </Stack>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default LedgerEditor;
