export const SEARCH_TYPES = {
  USERS: 'users',
  TEAMS: 'teams',
  POLICIES: 'policies',
  SPECIFICATIONS: 'specs',
  LEDGERS: 'ledgers',
  CURVES: 'curves',
  CONTACTS: 'contacts',
  COMPANIES: 'companies',
  ASSETS: 'assets',
  REQUESTS: 'requests',
  DESIGNS: 'designs',
  THINGS: 'things',
};
