import { useState, useCallback, useEffect, useMemo } from 'react';
import "@bitnoi.se/react-scheduler/dist/style.css";
import Box from '@mui/material/Box';
import styles from './styles';
import { Scheduler, SchedulerData } from "@bitnoi.se/react-scheduler/dist/index.umd";
import TopBar from './TopBar';
import { getFromLS, saveRToLS } from '@pw/components/ProcessDesigner/functions/localStorage';
import { useSearchParams } from 'react-router-dom';
import { parseRecipe } from '@pw/components/ProductionSchedule/Timeline/functions';

const viewModes = [
  { label: 'Day', key: 2 },
  { label: 'Week', key: 1 },
  { label: 'Month', key: 0 },
];

const defaultTaskDuration = 8 * 60 * 60 * 1_000;

const timeShort = (date) => {
  const [h, m,] = date.toLocaleTimeString().split(':');
  return `${h}:${m}`;
}

export default function RequestScheduler() {
  const [params] = useSearchParams();
	const requestId = params.get('request');
  const [designs, setDesigns] = useState([]);
  const [filterButtonState, setFilterButtonState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState(2);
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date()
  });

  const daysVisible = useMemo(() =>
    ( range.endDate.getTime() - range.startDate.getTime() )
    / (24 * 60 * 60 * 1_000)
  , [range?.startDate, range?.endDate]);

  useEffect(() => {
    console.log({ daysVisible });
  }, [daysVisible]);

  const getDateOffsets = useCallback((direction) => {
    const currentDate = new Date(range.startDate);
    console.log('getDateOffsets', { viewMode, currentDate, direction });
    // 0 - Month, 1 - Week, 2 - Day
    switch (viewMode) {
      case 0:
        return (direction === '+')
        ? currentDate.setMonth(currentDate.getMonth() + 1)
        : currentDate.setMonth(currentDate.getMonth() - 1);
      case 1:
        return (direction === '+')
        ? currentDate.setDate(`${currentDate.getDate() + 7}`)
        : currentDate.setDate(`${currentDate.getDate() - 7}`)
      case 2:
        return (direction === '+')
        ? currentDate.setDate(`${currentDate.getDate() + 1}`)
        : currentDate.setDate(`${currentDate.getDate() - 1}`)
    }
  }, [viewMode, range]);

  const createDateOffset = (viewMode, startDate, direction) => {
    const d = new Date(startDate);
    const newDate = new Date( getDateOffsets(direction) );
    console.log('createDateOffset', { d, newDate });
    return newDate;
  }

  const handleRangeChange = useCallback((direction) => {
    console.log('----', { direction, viewMode });
    const startDate = createDateOffset(viewMode, range.startDate, direction);
    const endDate = createDateOffset(viewMode, startDate, '+');

    console.log('handleRangeChange', { direction, viewMode, startDate, endDate });

    if (range.startDate === startDate && range.endDate === enddate) return;
    setRange({ startDate, endDate });
  }, [range?.startDate, viewMode]);

  const now = new Date().getTime();
  const parsedDesigns = useMemo(() => {
    if (!designs?.length) return [];
    const parsed = designs.map((d) => parseRecipe(d));
    return parsed;
  }, [designs]);

  const batches = useMemo(() => {
    let currentTime = new Date().getTime();
    const ret = (parsedDesigns).map((d) => {
      const timings = [currentTime];
      d.steps.forEach((s, i) => {
        if (i === 0 && d.batch_started && s.duration) {
          currentTime = new Date(d.recipe_started).getTime() + s.duration;
        } else {
          currentTime += ( s.duration || defaultTaskDuration );
        }
        timings.push(currentTime);
      });

      console.log({ timings });

      return {
        id: d.id,
        label:  {
          icon: null,
          title:  d?.batch_name || d?.recipe_name || 'Batch',
          // subtitle: `${timeShort(start)} - ${timeShort(end)}`,
        },
        data: daysVisible < 5
        ? d.steps.map((s, i) => {
          const start = new Date(timings[i] + 1);
          const end = new Date(timings[i] + (
            s.duration || defaultTaskDuration
          ));
          const step = {
            id: s.id,
            title: s.label,
            subtitle: `${timeShort(start)} - ${timeShort(end)}`,
            // add one ms to prevent overlapping which creates a 2nd row
            startDate: start,
            endDate: end,
            bgColor: '#00A89D'
          };
          return step;
        })
        // when viewing more days, merge whole batch to 1 step as too small
        // to visualize individually
        : [{
          id: d.id,
          title: d?.batch_name || d?.recipe_name || 'Batch',
          // subtitle: `${timeShort(new Date(timings[0]))} - ${timeShort(new Date(timings[timings.length -1]))}`,
          startDate: timings[0],
          endDate: timings[timings.length -1],
          bgColor: '#00A89D'
        }]
      };
    });
    return ret;
  }, [parsedDesigns, daysVisible]);

  useEffect(() => {
    const recipes = getFromLS('design');
    const target = recipes.find(({ id }) =>
      id === requestId
    );
    if (target) setDesign(target);
    setDesigns(recipes);
  }, [requestId]);

  const config = useMemo(() => ({
    zoom: viewMode,
    filterButtonState: -1,
    showTooltip: false,
    showThemeToggle: false,
    theme: 'light'
  }), [viewMode]);

  return (
    <Box sx={styles} className="requests-scheduler-root">
      <Box className="container">
      <Scheduler
        data={batches}
        isLoading={isLoading}
        onRangeChange={(r) => { setRange(r) }}
        onTileClick={(clickedResource) => console.log(clickedResource)}
        onItemClick={(item) => console.log(item)}
        // onFilterData={() => {
        //   // Some filtering logic...
        //   setFilterButtonState(1);
        // }}
        // onClearFilterData={() => {
        //   // Some clearing filters logic...
        //   setFilterButtonState(0)
        // }}
        config={config}
      />
      </Box>
    </Box>
  );
}
