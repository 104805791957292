import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { LocationSelect } from '@pw/components/ExplorerLocation';
import { FormikCheckBox } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import { H5 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { useCompanyFacilities } from '@pw/redux/containers/User/hooks';
import debounce from '@pw/utilities/debounce';
import { useCallback, useContext, useEffect, useMemo } from 'react';

function LocationImpl({
	hide,
	setLocation,
	disabled = false,
	showCheckBox = true,
}) {
	const { values, setFieldValue } = useContext(FormikContext);

	// We need map here
	const locations = useCompanyFacilities();

	console.log('locations', locations);

	useEffect(() => {
		if (hide) {
			debounce(() => setFieldValue('enable', false), 50);
		}
	}, [hide]);

	const setValues = (location_id, bay_id, row_id, level_id) => {
		console.log('Setting location', location_id, bay_id, row_id, level_id);
		debounce(() => {
			setFieldValue('location_id', location_id);
			setFieldValue('bay_id', bay_id);
			setFieldValue('row_id', row_id);
			setFieldValue('level_id', level_id);
		}, 25);
	};

	const setLocationValues = useCallback(
		(location_id, bay_id, row_id, level_id) => {
			console.log('Checking location', location_id, bay_id, row_id, level_id);
			if (!location_id) {
				setValues('', '', '', '');
				return;
			}

			// Find the location
			const location = locations.find(({_id})=>_id === location_id);

			// Find the bay
			const bay = (location.bays ?? []).find(({_id}) => _id === bay_id);
			if (!bay) {
				setValues(location_id, '', '', '');
				return;
			}

			// Find the row
			const row = (bay.rows ?? []).find(({_id}) => _id === row_id);
			if (!row) {
				setValues(location_id, bay_id, '', '');
				return;
			}

			// Find the level
			const level = (row.levels ?? []).find(({_id}) => _id === level_id);
			if (!level) {
				setValues(location_id, bay_id, row_id, '');
				return;
			}
			setValues(location_id, bay_id, row_id, level_id);
		},
		[setFieldValue, locations],
	);

	const handleOnQrSuccess = useCallback(
		(data) => {
			const { text } = data; // 'https://test.metacask.com:4430/explorer?dt=1,2,3,4';
			const [location_id, bay_id, row_id, level_id] = decodeURIComponent(
				text.split('?dt=')[1],
			).split(',');
			setLocationValues(location_id, bay_id, row_id, level_id);
		},
		[setLocationValues],
	);

	// will remove form data if location is disabled
	useEffect(() => {
		// console.log('Values>', values);
		if (values?.enable === false) {
			setValues('', '', '', '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.enable]);

	return (
		<Stack>
			{!!showCheckBox && (
				<FormikCheckBox
					name='enable'
					label={<H5>Location</H5>}
					disabled={hide || disabled}
				/>
			)}

			<Collapse in={showCheckBox ? !!values?.enable : !showCheckBox}>
				<FlexBox alignItems='flex-start' gap={2}>
					<ScanQR
						onSuccess={handleOnQrSuccess}
						width={24}
						height={24}
						withLabel
						disabled={disabled}
						Component={TitledButton}
					/>
					<LocationSelect
						locations={locations}
						setLocation={setLocation}
						disabled={disabled}
					/>
				</FlexBox>
			</Collapse>
		</Stack>
	);
}

export default function Location({ name, ...rest }) {
	const { readonly } = useContext(FormikContext);
	return (
		<FormikProvider path={name}>
			<LocationImpl {...rest} disabled={readonly || rest.disabled} />
		</FormikProvider>
	);
}
