import { ASSET_TYPES } from './asset';
import { REQUEST_TYPES } from './requests';
/**
 * Permissions
 * 0b1 - Read
 * 0b10 - Create
 * 0b100 - Modify
 * 0b1000 - Delete
 * 0b10000 - Assign
 * 0b100000 - Approve
 * 0b1000000 - Notify
 */
export const PermissionType = {
  READ:             BigInt('0b1'),
  CREATE:          BigInt('0b10'),
  MODIFY:         BigInt('0b100'),
  DELETE:        BigInt('0b1000'),
  ASSIGN:       BigInt('0b10000'),
  APPROVE:     BigInt('0b100000'),
  AUTHORIZE:  BigInt('0b1000000'),
  NOTIFY:    BigInt('0b10000000'),
};

export const PermissionKey = {
  ...REQUEST_TYPES,
  ...ASSET_TYPES,
  FACILITY: 'facility',
  THING: 'thing',
  SENSOR: 'sensor',
  CRM: 'crm', // partners owners
  PLANT: 'plant',
  POLICY: 'policy',
  SPECIFICATION: 'specification',
  PRICE_CURVE: 'price_curve',
  USER: 'user', // roles team users.
};

export const RequireAssign = [
  ...Object.values(REQUEST_TYPES),
];

export const RequireApproval = [
  ...Object.values(REQUEST_TYPES),
  ...Object.values(ASSET_TYPES),
];

export const RequireAuthorization = [
  REQUEST_TYPES.INVOICE,
  REQUEST_TYPES.PURCHASE_ORDER,
  REQUEST_TYPES.PURCHASE_RETURN,
  REQUEST_TYPES.SALES_RETURN,
  REQUEST_TYPES.DUTY_SUBMISSION,
  REQUEST_TYPES.DUTY_TRANSFER,
];

export const AssetPermissionName = {
  [ASSET_TYPES.CASK]: 'Casks', //"1",
  [ASSET_TYPES.PALLET]: 'Pallets', //"1",
  [ASSET_TYPES.IBC]: 'IBCs', //"1",
  [ASSET_TYPES.FILLING_TANK]: 'Filling Tanks', //"1",
  [ASSET_TYPES.TANKER]: 'Tankers', //"1",
  [ASSET_TYPES.CASK]: 'Casks', //"1",
};

export const TaskPermissionName = {
  [REQUEST_TYPES.SAMPLE]: 'Sample', //"1",
  [REQUEST_TYPES.REGAUGE]: 'Regauge', //"2",
  [REQUEST_TYPES.TRANSFER]: 'Liquid Transfer', //"3",
  [REQUEST_TYPES.SHIP]: 'Shipment', //"5",
  [REQUEST_TYPES.PRODUCTION_ORDER]: 'Production', //"6",
  [REQUEST_TYPES.CHANGE_OWNERSHIP]: 'Transfer Ownership', //"8",
  [REQUEST_TYPES.RESTOCK]: 'Restock Inventory', //"9",
  [REQUEST_TYPES.INVENTORY_AUDIT]: 'Inventory Audit', //"10",
  [REQUEST_TYPES.DELIVERY]: 'Delivery', //"11",
  [REQUEST_TYPES.DUTY_TRANSFER]: 'Duty Payment', //"12",
  [REQUEST_TYPES.REPORT_DEFECT]: 'Report Defect', //"13",
  [REQUEST_TYPES.DUTY_SUBMISSION]: 'Duty Submission', //"14",
  [REQUEST_TYPES.INVOICE]: 'Invoice', //"15",
  [REQUEST_TYPES.PURCHASE_ORDER]: 'Purchase Order', //"16",
  [REQUEST_TYPES.EXPENSE_REPORT]: 'Expense Report', //"17",
  [REQUEST_TYPES. PACK]: 'Pack Task', //"18",
  [REQUEST_TYPES.SALES_ORDER]: 'Sales Order', //"19",
  [REQUEST_TYPES.DISPATCH]: 'Dispatch', //"20",
  [REQUEST_TYPES.MAINTENANCE]: 'Maintenance Task', //"21",
  [REQUEST_TYPES.OWNERSHIP_AUDIT]: 'Ownership Audit', //"22",
  [REQUEST_TYPES.INVOICE_PAYMENT]: 'Invoice Payment',
  [REQUEST_TYPES.SALES_RETURN]: 'Sales Return',
  [REQUEST_TYPES.PURCHASE_RETURN]: 'Purchase Return',
};

export const GENERAL_FUNCTIONS = {
  FACILITIES: 'facility',
  THINGS: 'thing',
  SENSORS: 'sensor',
  CRM: 'crm',
  PLANT: 'plant',
  POLICIES: 'policy',
  SPECIFICATIONS: 'specification',
  PRICE_CURVES: 'price_curve',
  USERS: 'user',
  LEDGERS: 'ledger',
};

export const GeneralPermissionName = {
  [GENERAL_FUNCTIONS.FACILITIES]: "Facilities",
  [GENERAL_FUNCTIONS.THINGS]: "Things",
  [GENERAL_FUNCTIONS.SENSORS]: "Sensors",
  [GENERAL_FUNCTIONS.CRM]: "CRM",
  [GENERAL_FUNCTIONS.PLANT]: "Plant Configuration",
  [GENERAL_FUNCTIONS.POLICIES]: "Policies",
  [GENERAL_FUNCTIONS.SPECIFICATIONS]: "Specifications",
  [GENERAL_FUNCTIONS.PRICE_CURVES]: "Price Curves",
  [GENERAL_FUNCTIONS.USERS]: "Users",
  [GENERAL_FUNCTIONS.LEDGERS]: "Ledger",
};

export const AllPermissionNames = {
  ...AssetPermissionName,
  ...TaskPermissionName,
  ...GeneralPermissionName
}
