import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';

const toTaggedAsset = (asset, forceComplete = false) => ({
  id: asset?._id ?? asset?.id,
  asset: asset?._id ?? asset?.id,
  type: asset?.type,
  name: asset?.name,
  parent_asset: asset?.parent_asset,
  thing: asset?.thing,
  location: asset?.location,

  properties: {
    reference: asset?.properties?.reference,
    tags: asset?.properties?.tags,
    thing: asset?.properties?.thing,
    compliance: asset?.properties?.compliance,
    liquid: asset?.properties?.liquid,
  },

  child_assets: asset?.child_assets,
  accounts: asset?.accounts,
  processed: forceComplete
    ? ASSET_PROCESSED_STATUS.CONFIRMED
    : (asset?.processed ?? ASSET_PROCESSED_STATUS.PENDING),
});

export default toTaggedAsset;
