function SvgBlendingTank(props) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g clipPath='url(#BlendingTank_svg__clip0_146_122)'>
        <mask
          id='BlendingTank_svg__a'
          style={{
            maskType: 'luminance',
          }}
          maskUnits='userSpaceOnUse'
          x={0}
          y={0}
          width={28}
          height={28}
        >
          <path d='M28 0H0v28h28V0z' fill='#fff' />
        </mask>
        <g mask='url(#BlendingTank_svg__a)'>
          <path
            d='M26.85 1.9c-1.23-.65-2.57-.95-3.6-1.13-3.88-.69-7.91-.91-12.32-.68-1.79.09-4.14.26-6.46.73-1.05.21-2.17.48-3.21 1.02C.54 2.21.16 2.6 0 3.15v21.81c.29.76.94 1.09 1.65 1.4 1.22.53 2.5.8 4.04 1.04 2.11.34 4.3.52 6.68.57.08 0 .16.01.23.03.03 0 .05 0 .08.01h2.87l1.87-.11c2-.12 4.35-.31 6.64-.83.99-.22 2.05-.51 3-1.08.66-.39.95-.87.94-1.59-.02-7.48-.02-14.23 0-20.65 0-.86-.34-1.41-1.15-1.84V1.9zm-25.67.82c.64-.33 1.21-.56 1.74-.73 1.72-.52 3.46-.76 4.93-.92 3.06-.33 6.19-.4 9.59-.22 2.12.11 4.9.35 7.59 1.12.38.11.74.26 1.1.4.12.05.25.1.37.15.13.05.25.13.35.2.15.11.36.28.35.52 0 .27-.27.44-.35.5-.23.15-.47.28-.72.38-1.23.51-2.52.77-3.62.96-2.54.44-5.26.63-8.75.63h-.63c-1.61 0-3.66-.12-5.63-.35-1.57-.18-3.41-.45-5.16-1.06-.41-.14-.8-.32-1.17-.53-.27-.16-.41-.34-.4-.55 0-.14.08-.34.41-.51v.01zm2.36 23.45l-.39-.11c-.71-.2-1.44-.41-2.06-.86a.838.838 0 01-.31-.63c-.01-6.02 0-12.13 0-18.05V4.98v-.14-.04l.02-.28 2.75 1.01v20.64h-.01zm.77-18.98c.01-.29 0-.59 0-.88v-.6l5.13.67v1.29h-.19s-.05.02-.08.02c-.06 0-.12.01-.18.01h-4.2c-.12 0-.28-.02-.38-.13-.11-.11-.12-.27-.11-.38h.01zm.02 8.4h5.12v2.75H4.33v-2.75zm5.13 11.47l-.22-.02c-1.65-.12-3.19-.33-4.74-.64l-.17-.03v-3.68h5.13v4.37zm0-5.17H4.34v-2.75h5.12v2.75zm0-7.11H4.34v-2.75h5.12v2.75zm0-3.55H4.34V8.48h5.12v2.75zm17.3 14.09c-.77.46-1.62.69-2.36.87-2.59.64-5.45.96-8.98 1.02h-1.13c-.76 0-1.53 0-2.28-.02-.52 0-1.04-.01-1.56-.02h-.21V6.42h1.01c2.33.13 4.72.09 7.31-.11 2.29-.18 4.03-.42 5.64-.79.71-.16 1.64-.41 2.49-.85.05-.02.09-.04.14-.06.02 0 .05-.02.08-.03l.27-.11v.29s.02.08.02.11v19.61c0 .38-.15.64-.47.83l.03.01z'
            fill='currentColor'
          />
        </g>
        <path
          d='M14.79 11.54v.17c0 .49-.15.79-.48.95.4.17.55.52.55 1.02v.39c0 .73-.39 1.12-1.13 1.12h-1.17v-4.73h1.12c.77 0 1.1.36 1.1 1.09l.01-.01zm-1.48-.41v1.25h.29c.28 0 .45-.12.45-.5v-.26c0-.34-.11-.49-.38-.49h-.36zm0 1.93v1.45h.43c.25 0 .39-.11.39-.47v-.41c0-.44-.14-.57-.48-.57h-.34zM15.31 10.45h.74v4.06h1.22v.68H15.3v-4.73l.01-.01zM18.35 12.45h1.02v.68h-1.02v1.39h1.28v.68H17.6v-4.73h2.03v.68h-1.28v1.32-.02zM20.77 11.76h-.01v3.43h-.67v-4.73h.93l.75 2.83h.01v-2.83h.66v4.73h-.76l-.91-3.43zM22.98 10.45h1.18c.74 0 1.11.41 1.11 1.17v2.39c0 .76-.37 1.17-1.11 1.17h-1.18v-4.73zm.75.68v3.38h.42c.24 0 .38-.12.38-.46v-2.46c0-.34-.14-.46-.38-.46h-.42zM12.35 18.57h2.3v.68h-.78v4.06h-.74v-4.06h-.78v-.68zM17.13 23.3h-.75l-.13-.86h-.91l-.13.86h-.68l.76-4.73h1.09l.76 4.73h-.01zm-1.7-1.5h.72l-.35-2.39h-.01l-.35 2.39h-.01zM18.15 19.87h-.01v3.43h-.67v-4.73h.93l.75 2.83h.01v-2.83h.66v4.73h-.76l-.91-3.43zM21.33 21.41l-.23.43v1.45h-.74v-4.73h.74v2.05h.01l.96-2.05h.74l-1.03 2.11 1.03 2.62h-.76l-.72-1.89v.01z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='BlendingTank_svg__clip0_146_122'>
          <path fill='#fff' d='M0 0h28v28H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default SvgBlendingTank;
