import Timeline from '@mui/lab/Timeline';
import { Box } from '@mui/material';
import SingleTimeline from './SingleTimeline';

export default function BasicTimeline({ access_log = [] }) {
	console.log('Access log', access_log);
	return (
		// <Timeline>
		// 	{access_log.map((item, i) => (
		// 		<SingleTimeline item={item} key={i} />
		// 	))}
		// </Timeline>
		<Box>History</Box>
	);
}
