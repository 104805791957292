import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import VendorProperties from '@pw/components/admin/VendorProperties';
import FilledButton from '@pw/components/Buttons/FilledButton';
import TextButton from '@pw/components/Buttons/TextButton';
import { FormikForm, FormikTextField } from '@pw/components/Forms/FormikForm';
import { TagSelect } from '@pw/components/Forms/TagSelect';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import FormWrapper from '@pw/components/Layout/FormWrapper';
import usePolicySelectorHook from '@pw/components/PolicySelector';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import PolicySelector from '@pw/components_v2/search/policy/PolicySelector';
import VendorSelector from '@pw/components_v2/search/vendor/VendorSelector';
import { TAG_CATEGORY } from '@pw/consts/tag';
import { upsertThingThunk } from '@pw/redux/thunks/thing';
import { useDispatch } from 'react-redux';
import PolicyModal from '../ThingPolicies/PolicyModal';
import ContactSearch from '@pw/components_v2/search/contact';
import LedgerSearch from '@pw/components_v2/search/ledger';

function ThingForm({
  id,
  entity,
  changeSet,
  readonly = false,
  edit = false,
  onCancel,
  onBeforeSave,
  onSave,
  children,
  thingType
}) {
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const updated = onBeforeSave ? onBeforeSave(values) : values;
    const body = {
      ...(entity ?? {}),
      ...updated,
      type: thingType
    };
    console.log('Submitting', body);
    dispatch(upsertThingThunk(body))
      .unwrap()
      .then((response) => onSave(response));
  };

  return (
    <>
      <FormikForm
        changeSet={changeSet}
        onSubmit={handleSubmit}
        edit={edit}
        readonly={readonly}
      >
        <FormWrapper>
          <Stack spacing='1.5rem'>
            <FlexBox alignItems='top'>
              <FormikTextField label='Name' name='name' fullWidth />
            </FlexBox>
            <FormikTextField label='Description' name='description' fullWidth />
            <TagSelect name='tags' type={TAG_CATEGORY.THING} />
          </Stack>

          {children}

          <ContactSearch />
          <LedgerSearch />
          <VendorSelector initialValues={entity?.vendors} />
          <PolicySelector initialValues={entity?.policies} />

          <FormButtons readonly={readonly} onCancel={onCancel} />
        </FormWrapper>
      </FormikForm>
    </>
  );
}

export default ThingForm;
