import { Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import Releases from '@pw/components/admin/Releases';
import thingSettingsFields from '@pw/components/admin/Things/thingSettingsFields';
import ThingForm from '@pw/components/admin/ThingForm';
import { FormikDatePicker, FormikNumberField, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import useInventorySelectorHook from '@pw/components/InventorySelector/GeneralInventorySelector';
import { withAppLayout } from '@pw/components/Layout/AppLayout';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import DependentSKUModal from '@pw/components/SKUSelector/modals/DependentSKUModal';
import SKUField from '@pw/components/SKUSelector/SKUField';
import { H5, Overline } from '@pw/components/Typography';
import { ASSET_TYPES } from '@pw/consts/asset';
import { FINISHED_GOODS_TYPE_OPTIONS, FINISHED_GOODS_TYPES, THING_TYPES } from '@pw/consts/thing';
import { ABV_UNIT_OPTIONS, LIQUID_UNIT_OPTIONS, MASS_UNIT_OPTIONS } from '@pw/consts/units';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import debounce from '@pw/utilities/debounce';
import { useContext, useEffect, useMemo } from 'react';
import * as yup from 'yup';

function casedProperties(fields = {}) {
	const {
		count = 1,
		size = {},
		abv = {},
		// Configured releases of these cased goods
		releases = [],
	} = fields;

	return {
		count: [count, yup.number()],
		size: unitField(size),
		abv: unitField(abv),
		releases: [releases, yup.array().of(yup.object())],
	};
}

function packProperties(fields = {}) {
	const {
		// BOM for the pack
		things = [],
		// Supported label formats
		labels = [],
	} = fields;

	return {
		things: [things, yup.array().of(yup.object())],
	};
}

function containerProperties(fields = {}) {
	const {
		container = {},
		liquid = {},
		// If available on the marketplace, we need to have this setup
		collection = {},
		listing_status = 'active',
		listing_date = Date.now(),
		sale_price,
		storage_price,
	} = fields;

	return {
		container: [container, yup.object()],
		liquid: [liquid, yup.object()],
		collection_id: [collection, yup.object()],
		listing_status: [listing_status, yup.string()],
		listing_date: [listing_date, yup.number()],
		sale_price: [sale_price, yup.number()],
		storage_price: [storage_price, yup.number()],
	};
}

function BottleProperties() {
	return (
		<>
			<FlexBox alignItems='top'>
				<FormikNumberField label='Bottles per Case' name='count' fullWidth />
				<FormikUnitField
					label='Bottle Size'
					name='size'
					options={LIQUID_UNIT_OPTIONS}
					fullWidth
				/>
			</FlexBox>
			<FormikUnitField label='Strength' name='abv' options={ABV_UNIT_OPTIONS} />
			<Releases />
		</>
	);
}

function PackProperties() {
	const { setFieldValue, values } = useContext(FormikContext);

	const thingProps = useMemo(
		() => ({
			title: 'BOM',
			filter: {
				thing_types: [THING_TYPES.CONSUMABLE, THING_TYPES.FINISHED],
			},
			initialThings: values?.things,
			SKUModal: DependentSKUModal,
		}),
		[values?.things],
	);

	const [[things], , , DependentInventory, DependentModals] =
		useInventorySelectorHook(thingProps);

	useEffect(() => {
		console.log('Updating things', things);
		debounce(() => setFieldValue('things', things), 25);
	}, [things]);

	return (
		<>
			<DependentInventory />
			<DependentModals />
		</>
	);
}

function ContainerProperties() {
	const containerTypeFilter = (item) => [ASSET_TYPES.CASK, ASSET_TYPES.IBC].includes(item?.properties?.asset_type);

	return (
		<>
			<SKUField
				title='Container Type'
				skuTypes={[THING_TYPES.TRACKED]}
				itemFilter={containerTypeFilter}
				field="container_type_sku"
				idField="container_type_sku_id"
			/>
			<SKUField
				title='Liquid Type'
				skuTypes={[THING_TYPES.LIQUID]}
				field="liquid_type_sku"
				idField="liquid_type_sku_id"
			/>

			<H5>Marketplace Link</H5>
			{/*<FormikTextField*/}
			{/*	label='Marketplace Collection'*/}
			{/*	name='collection_id'*/}
			{/*	fullWidth*/}
			{/*/>*/}
			<FlexBox alignItems='top'>
				<FormikSelect
					label='Status'
					name='listing_status'
					options={[
						{ label: 'Active', value: 'active' },
						{ label: 'Paused', value: 'paused' },
						{ label: 'Completed', value: 'completed' },
					]}
					fullWidth
				/>
				<FormikDatePicker
					sx={{ ...mt2 }}
					name='listing_date'
					label='Listing Date'
					fullWidth
					required
				/>
			</FlexBox>

			<FlexBox alignItems='top'>
				<FormikNumberField label='List Price' name='sale_price' fullWidth />
				<FormikNumberField
					label='Storage Price'
					name='storage_price'
					fullWidth
				/>
			</FlexBox>
		</>
	);
}

function propertyFields(fields = {}) {
	const {
		type = FINISHED_GOODS_TYPES.BOTTLE,
		cased,
		pack,
		container,
		gross_weight,
		net_weight,
	} = fields;

	return {
		type: [type, yup.string().required('Type is required')],
		cased: casedProperties(cased),
		pack: packProperties(pack),
		container: containerProperties(container),
		gross_weight: unitField(gross_weight),
		net_weight: unitField(net_weight),
	};
}

function ItemProperties() {
	const { values } = useContext(FormikContext);
	const type = useMemo(() => values?.['type'], [values]);

	return (
		<>
			{type === FINISHED_GOODS_TYPES.BOTTLE && (
				<FormikProvider path='cased'>
					<BottleProperties />
				</FormikProvider>
			)}
			{type === FINISHED_GOODS_TYPES.PACK && (
				<FormikProvider path='pack'>
					<PackProperties />
				</FormikProvider>
			)}
			{type === FINISHED_GOODS_TYPES.CONTAINER && (
				<FormikProvider path='container'>
					<ContainerProperties />
				</FormikProvider>
			)}
		</>
	);
}

function PropertiesImpl() {
	return (
		<Stack spacing={3}>
			<Divider><Overline>Properties</Overline></Divider>
			<FormikSelect name='type' label='Type' options={FINISHED_GOODS_TYPE_OPTIONS} />
			<ItemProperties />

			<Divider><Overline>Shipping Settings</Overline></Divider>
			<FlexBox alignItems='top'>
				<FormikUnitField
					label='Gross Weight'
					name='gross_weight'
					options={MASS_UNIT_OPTIONS}
					fullWidth
				/>
				<FormikUnitField
					label='Net Weight'
					name='net_weight'
					options={MASS_UNIT_OPTIONS}
					fullWidth
				/>
			</FlexBox>
		</Stack>
	);
}

function Properties(props) {
	return (
		<FormikProvider path='properties'>
			<PropertiesImpl {...props} />
		</FormikProvider>
	);
}

function ThingPage(props) {
	const changeSetGenerator = (initialValues) => ({
		...thingSettingsFields(initialValues),
		properties: propertyFields(initialValues.properties),
	});

	const handleBeforeSave = (entity) => entity;

	return (
		<ThingForm
			thingLabel='Finished Good'
			thingType={THING_TYPES.FINISHED}
			changeSetGenerator={changeSetGenerator}
			onBeforeSave={handleBeforeSave}
			{...props}
		>
			<Properties />
		</ThingForm>
	);
}

export default withAppLayout(ThingPage, {
	title: 'Finished Goods',
});

