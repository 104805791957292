function SvgCollate(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      fill='#fff'
      fillRule='evenodd'
      stroke='#838383'
      strokeLinecap='round'
      strokeLinejoin='round'
      width='1em'
      height='1em'
      className='MuiSvgIcon-root'
      {...props}
    >
      <g fill='#ccffd5'>
        <path d='M19.5 19.5l12 12 12-12zM43.5 43.5l-12-12-12 12z' />
      </g>
    </svg>
  );
}
export default SvgCollate;
