import { useAccountPerms } from '@pw/redux/containers/User';
import { useCallback } from 'react';

// Hook to check user permissions
const usePermissions = () => {
  const accountPerms = useAccountPerms();

  const hasPermission = useCallback((resource, actions, checkAll = false) => {
    return true; // TODO: remove this after roles are fixed

    // if (!accountPerms || !resource || !actions) return false;
    //
    // const permissionsForResource = accountPerms[resource];
    // if (!permissionsForResource) return false;
    //
    // // Convert permissions to BigInt
    // const userPermissions = BigInt(permissionsForResource);
    //
    // // If actions is not an array, convert it into one
    // const actionList = Array.isArray(actions) ? actions : [actions];
    //
    // if (checkAll) {
    //   // Check if the user has ALL actions (AND logic)
    //   return actionList.every((action) => (BigInt(userPermissions) & BigInt(action)) !== BigInt(0));
    // }
    //
    // // Check if the user has ANY action (OR logic)
    // return actionList.some((action) => (BigInt(userPermissions) & BigInt(action)) !== BigInt(0));
  }, [accountPerms]);

  return hasPermission;
};

export default usePermissions;
