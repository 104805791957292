import {
  ArrowCircleLeft,
  ArrowCircleRight,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { AssetListComponentV2 } from '@pw/components/AssetListItem';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TextButton from '@pw/components/Buttons/TextButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ScanQR from '@pw/components/ScanQR';
import { Body1, H5 } from '@pw/components/Typography';
import { UNIT } from '@pw/consts/units';
import styles from '@pw/styles/modal.styles';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetLazyQuery } from '@pw/utilities/hooks/service/useAssetQuery';
import chunk from 'lodash.chunk';
import compact from 'lodash.compact';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ASSET_TYPES } from '@pw/consts/asset';
import { ASSET_PROCESSED_STATUS } from '@pw/consts/requests';
import toTaggedAsset from '@pw/utilities/adapters/toTaggedAsset';
import handleOnQrRead from '@pw/utilities/handleOnQrRead';
import * as yup from 'yup';
import {
  FormikForm,
  FormikMeasuresField,
  FormikNumberField,
} from '../Forms/FormikForm';
import LiquidSummary from '../LiquidSummary';
import LoadingSmall from '../Loading/LoadingSmall';
import {
  WeightsForm,
  calculateTransferDestinationLiquidUpdates,
} from '../ThingSelector/modals/DestinationAssetModal';

function a11yProps(index) {
  return {
    id: `fill-tab-${index}`,
    'aria-controls': `fill-tabpanel-${index}`,
  };
}

function LiquidFillForm({ tab, setTab }) {
  const { t } = useTranslation();
  const handleChange = useCallback((e, v) => {
    setTab(v);
  }, []);
  return (
    <Stack gap={2} sx={{ width: '100%' }}>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons='auto'
        allowScrollButtonsMobile
        aria-label='Fill types'
      >
        {['Filled value', 'Average value'].map((i, ind) => (
          <Tab
            key={ind}
            sx={{ maxWidth: '100%', width: '50%' }}
            label={i}
            {...a11yProps(ind)}
          />
        ))}
      </Tabs>
      <FlexBox gap={2} alignItems='top'>
        <FormikMeasuresField
          label={t('Filled')}
          name='filled_bl'
          unit={UNIT.BL}
          fullWidth
        />
        <FormikMeasuresField
          name='updated_abv'
          unit={UNIT.ABV}
          label={t('strength')}
          fullWidth
          required
        />
      </FlexBox>

      <FlexBox gap={2}>
        <FormikNumberField name='updated_tcf' label='TCF' fullWidth />
      </FlexBox>

      <LiquidSummary />

      <WeightsForm />
    </Stack>
  );
}

function SelectAll({ selected, setSelected }) {
  return (
    <FlexBox justifyContent='end'>
      {selected && <CheckBoxOutlined onClick={() => setSelected(false)} />}
      {!selected && <CheckBoxOutlineBlank onClick={() => setSelected(true)} />}
      <Body1>Select All</Body1>
    </FlexBox>
  );
}

function AssetFillModal({ items, open, onClose, upsertAssets }) {
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);

  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [tab, setTab] = useState(0);

  const converter = useConverter();

  const [fetchAsset, { isLoading: assetLoading }] = useAssetLazyQuery();

  const changeSet = useMemo(
    () => ({
      filled_bl: ['', yup.number().min(0, 'Must be positive!')],
      updated_abv: ['', yup.number().min(0, 'Must be positive!')],
      updated_tcf: [
        Number(1).toFixed(3),
        yup.number().min(0, 'Must be positive!'),
      ],
      enableWeight: [false, yup.boolean()],
      tearWeight: ['', yup.number().min(0, 'Must be positive!')],
      grossWeight: ['', yup.number().min(0, 'Must be positive!')],
    }),
    [],
  );

  useEffect(() => {
    setCurrentItems(items);
  }, [items]);

  useEffect(() => {
    setPages(
      chunk(
        currentItems?.filter((i) => i.asset_type === ASSET_TYPES.CASK),
        5,
      ),
    );
  }, [currentItems]);

  const isSelected = useCallback(
    (x) => !!selected.find((i) => i.path === x.path),
    [selected],
  );

  const flipSelected = useCallback(
    (x) => {
      setSelected((v) => {
        const index = v.findIndex((i) => i.path === x.path);
        if (index >= 0) {
          delete v[index];
          return compact(v);
        }
        return [...v, x];
      });
    },
    [setSelected],
  );

  useEffect(() => {
    if (selectAll) {
      setSelected(items);
    } else {
      setSelected([]);
    }
  }, [selectAll]);

  const handleLiquidProp = useCallback(
    (values) => {
      try {
        console.log('submitted', values, currentItems, selected);
        if (tab === 1) {
          values = {
            ...values,
            filled_bl: Number(values.filled_bl / selected.length),
          };
        }
        const [valid, updated_level] =
          calculateTransferDestinationLiquidUpdates(values, converter);
        console.log('updated_level ', updated_level);
        // const le =
        // 	Number(updated_level?.filled_bl) > 0 || Number(level?.bl) > 0;
        upsertAssets(
          currentItems
            .filter((i) => selected.some((j) => j.asset_id === i.asset_id))
            .map((i) => {
              const { properties } = i;
              const { liquid } = properties;
              const { level } = liquid;
              const { weightFactor } = level;
              return {
                ...i,
                properties: {
                  ...properties,
                  liquid: {
                    ...liquid,
                    enable: Number(updated_level?.updated_bl) > 0,
                    level: {
                      ...level,
                      ...updated_level,
                      enableWeight: values.enableWeight,
                      ...(values.enableWeight
                        ? {
                            weightFactor,
                            tearWeight: converter.from(
                              values.tearWeight,
                              UNIT.MAS,
                            ),
                            grossWeight: converter.from(
                              values.grossWeight,
                              UNIT.MAS,
                            ),
                          }
                        : {}),
                      enable: Number(updated_level?.updated_bl) > 0,
                    },
                  },
                },
                processed: valid
                  ? ASSET_PROCESSED_STATUS.CONFIRMED
                  : i.processed
                    ? i.processed
                    : ASSET_PROCESSED_STATUS.PENDING,
              };
            }),
        );
        onClose();
      } catch (e) {
        console.log('Failed to update', e);
        enqueueSnackbar(`Cannot update liquid settings: ${e.message}!`, {
          variant: 'error',
        });
      }
    },
    [selected, currentItems],
  );

  const handleScan = useCallback(
    async (assetId) => {
      console.log('Handle scan', assetId);
      fetchAsset(assetId)
        .then((asset) => {
          console.log(asset);
          if (asset.asset_type === ASSET_TYPES.PALLET) {
            const { child_assets = [] } = asset;
            if (child_assets.length > 0) {
              setCurrentItems(child_assets.map((i) => toTaggedAsset(i)));
            }
          } else if (asset.asset_type === ASSET_TYPES.CASK) {
            if (currentItems.some((i) => i.path === asset.path)) {
              enqueueSnackbar(`Asset aleary added!`, {
                variant: 'info',
              });
              return;
            }
            setCurrentItems((items) => [...items, toTaggedAsset(asset)]);
          }
        })
        .catch((err) => {
          console.log('Get asset', err);
          enqueueSnackbar(`Failed to find asset!`, {
            variant: 'error',
          });
        });
    },
    [pages],
  );

  return (
    <Modal open={open} onClose={onClose}>
      <FormikForm changeSet={changeSet} onSubmit={handleLiquidProp}>
        <Stack sx={styles} className='root' spacing={2}>
          <FlexBox>
            <H5>Fill liquid</H5>
            <IconButton
              onClick={() => onClose(null)}
              className='close-btn'
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
          </FlexBox>

          <FlexBox alignItems='flex-end'>
            <LiquidFillForm tab={tab} setTab={setTab} onClose={onClose} />
          </FlexBox>
          <FlexBox>
            {assetLoading ? (
              <LoadingSmall />
            ) : (
              <>
                <ScanQR
                  onSuccess={(params) => handleOnQrRead(params)(handleScan)}
                  closeOnSuccess
                  width={24}
                  height={24}
                  withLabel
                  Component={TitledButton}
                />
              </>
            )}
            <SelectAll setSelected={setSelectAll} selected={selectAll} />
          </FlexBox>
          {pages.length > 0 && (
            <Box
              sx={{
                overflowY: 'auto',
                height: 'auto',
                maxHeight: 'calc(95vh - 9rem)',
              }}
            >
              <Stack className='list'>
                {pages[page].map((i) => (
                  <AssetListComponentV2
                    key={i.path}
                    item={i}
                    selected={isSelected}
                    buttonIcon={
                      isSelected(i) ? (
                        <CheckBoxOutlined />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )
                    }
                    onClick={() => flipSelected(i)}
                  />
                ))}
              </Stack>
            </Box>
          )}
          {pages.length > 1 && (
            <FlexBox>
              <IconCircleButton
                onClick={() => setPage((v) => v - 1)}
                disabled={page === 0}
              >
                <ArrowCircleLeft />
              </IconCircleButton>

              {/* {assets.length > 1 && <Overline>{assets.length} Items</Overline>} */}

              <IconCircleButton
                onClick={() => setPage((v) => v + 1)}
                disabled={page + 1 === pages.length}
              >
                <ArrowCircleRight />
              </IconCircleButton>
            </FlexBox>
          )}

          <Box className='action-buttons'>
            <TextButton
              size='small'
              handleClick={() => onClose()}
              color='secondary'
            >
              Cancel
            </TextButton>
            <FilledButton
              size='small'
              disabled={selected.length === 0}
              type='submit'
            >
              OK
            </FilledButton>
          </Box>
        </Stack>
      </FormikForm>
    </Modal>
  );
}

export default AssetFillModal;
