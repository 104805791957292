import * as yup from 'yup';

export default function thingFields(thing, field = 'thing') {
  return {
    [field]: [
      thing,
      yup
        .object({
          _id: yup.string(),
          name: yup.string(),
          type: yup.string(),
          description: yup.string(),
        })
        .optional(),
    ],
  };
}
