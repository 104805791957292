import { Collapse } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { FormikForm, FormikSelect, FormikTextField } from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import FormButtons from '@pw/components_v2/elements/FormButtons';
import { PRICE_CURVE_TYPE, PRICE_CURVE_TYPES_OPTIONS } from '@pw/consts/priceCurve';
import { upsertPriceCurveThunk } from '@pw/redux/thunks/priceCurves';
import { usePriceCurveLazyQuery } from '@pw/utilities/hooks/service/usePriceCurveQuery';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import Curve from './Curve';

function PriceCurveEditor({ entry, company, readonly, onClose }) {
  const dispatch = useDispatch();

  // Load the item on demand
  const [load, { isLoading, cancel }] = usePriceCurveLazyQuery([], true);
  const [item, setItem] = useState(null);

  const changeSet = useMemo(() => ({
    name: [item?.name, yup.string()],
    curve_type: [item?.type ?? PRICE_CURVE_TYPE.STORAGE, yup.string().required('Curve type is required!')],
    description: [
      item?.description ?? '',
      yup.string().required('Description is required!')
    ],
    curve: [
      item?.curve ?? [] ,
      yup.array().min(1, 'Please add curve')
    ]
  }), [item]);

  useEffect(() => {
    console.log('Handling entry', entry);
    if (entry?._id) {
      load({ id: entry._id }).then(setItem);
    } else {
      // New item
      cancel();
      setItem(entry);
    }
  }, [entry]);

  const handleSubmit = useCallback((values) => {
    console.log('Submitting', values);
    dispatch(
      upsertPriceCurveThunk({
        ...(item ?? {}),
        ...values,
        company_id: company?._id
      })
    )
    .unwrap()
    .then(onClose);
  }, [item, onClose]);

  return (
    <Stack>
      <FlexBox>
        <H5>{entry?.name}</H5>
        {isLoading && (
          <CircularProgress size={24} color="secondary" />
        )}
      </FlexBox>
      <Collapse className="form-content" in={!isLoading} unmountOnExit>
        <FormikForm changeSet={changeSet} onSubmit={handleSubmit} enableReinitialize>
          <Stack spacing="1.5rem">
            <FormikSelect
              label="Price Curve Type"
              name="curve_type"
              options={PRICE_CURVE_TYPES_OPTIONS}
              fullWidth
              required
            />
            <FormikTextField label="Description" name="description" fullWidth required />

            <Curve initialValues={entry?.curve} readonly={readonly} />

            <FormButtons onCancel={onClose} />
          </Stack>
        </FormikForm>
      </Collapse>
    </Stack>
  );
}

export default PriceCurveEditor;
