import { Edit, TaskOutlined } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import AmountDisplay from '@pw/components/properties/AmountDisplay';
import DateDisplay from '@pw/components/properties/DateDisplay';
import DutyPaidDisplay from '@pw/components/properties/DutyPaidDisplay';
import IDDisplay from '@pw/components/properties/IDDisplay';
import NameDisplay from '@pw/components/properties/NameDisplay';
import { useCompanyThings } from '@pw/redux/containers/User/hooks';
import { useMemo } from 'react';

function AssetThingStorageListItem({ item, onEdit, display, selected = false }) {
	const { thing_record_id, thing = {}, entry_quantity, expiry, duty_paid } = item;
	const things = useCompanyThings();

	const unit = useMemo(() => {
		const th = things.find((s) => s._id === thing?._id);
		return th?.properties?.unit;
	}, [thing, things]);

	return (
		<Box className='listItem'>
			{selected && (
				<Box className='listSelected'>
					<TaskOutlined className='check' />
				</Box>
			)}

			<Box className='listContent'>
				<Stack>
					<IDDisplay value={thing_record_id} />
					<NameDisplay label='Thing' name={thing?.name} />
					<AmountDisplay amount={entry_quantity} unit={unit} />
					<DutyPaidDisplay value={duty_paid} />
					<DateDisplay date={expiry} />
					{display && display(item)}
				</Stack>
			</Box>

			<Stack className='listButtons'>
				{onEdit && (
					<IconCircleButton onClick={() => onEdit(item)}>
						<Edit />
					</IconCircleButton>
				)}
			</Stack>
		</Box>
	);
}

export default AssetThingStorageListItem;
