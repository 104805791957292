import Stack from '@mui/material/Stack';
import SvgDelete from '@pw/components/icons/Delete';
import SvgEdit from '@pw/components/icons/Edit';
import { Body1, Body3 } from '@pw/components/Typography';
import TeamEditor from '@pw/components_v2/CompanyView/modals/users/TeamEditor';
import AddNewField from '@pw/components_v2/elements/fields/AddNewField';
import BasicList from '@pw/components_v2/elements/lists/BasicList';
import SwipeableView from '@pw/components_v2/elements/SwipeableView';
import ToolbarSection from '@pw/components_v2/elements/ToolbarSection';
import { GENERAL_FUNCTIONS, PermissionType } from '@pw/consts/permissions';
import { removeTeamThunk } from '@pw/redux/thunks/teams';
import debounce from '@pw/utilities/debounce';
import useConfirm from '@pw/utilities/hooks/components/useConfirm';
import useItemListManager from '@pw/utilities/hooks/logic/useItemListManager';
import usePermissionsV2 from '@pw/utilities/hooks/logic/usePermissionsV2';
import { useCompanyTeamsQuery } from '@pw/utilities/hooks/service/useCompanyTeamsQuery';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TeamView from './TeamView';

function TeamsView({ company }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();

  // Get the user's permission for this company
  const checkPermissions = usePermissionsV2(company);
  const readonly = !checkPermissions(GENERAL_FUNCTIONS.USERS, PermissionType.MODIFY);

  const { isLoading, data } = useCompanyTeamsQuery({ company_id: company._id });
  const { items = [] } = data ?? {};

  const [teams, initTeams, , upsertTeams, removeTeams] = useItemListManager({
    initialData: items,
  });
  const [team, setTeam] = useState(null);

  // useEffect to propagate initial data when it is available
  useEffect(() => {
    if (!isLoading && items.length > 0) {
      initTeams(items);
    }
  }, [isLoading]);

  const ListView = ({ showEditor }) => {
    const handleEdit = useCallback((team) => {
      setTeam(team);
      showEditor();
    }, [setTeam]);

    const handleRemove = useCallback(
      (team) => {
        const body = {
          company_id: company._id,
          id: team._id,
        };
        console.log('Remove team', body);

        confirm({
          title: 'Remove Team',
          content: (
            <Stack>
              <Body1>{`Remove team ${team.name}?`}</Body1>
              <Body3 color="error">WARNING: This operation cannot be undone!</Body3>
            </Stack>
          ),
        })
        .then(() => dispatch(removeTeamThunk(body)).unwrap().then(removeTeams))
        .catch(() => {});
      },
      [removeTeams],
    );

    const Toolbar = ({ onClose }) => {
      const handleAddNew = useCallback(
        (team) => {
          onClose();
          setTeam({
            name: team,
          });
          showEditor();
        },
        [setTeam],
      );

      return (
        <AddNewField label="Add New Team" id="new_team" onAddNew={handleAddNew} fullWidth disabled={readonly} />
      );
    }

    const tools = [
      {
        title: 'Edit team',
        Icon: SvgEdit,
        handler: handleEdit,
      },
      {
        title: 'Remove team',
        Icon: SvgDelete,
        handler: handleRemove,
      },
    ];

    return (
      <ToolbarSection title="Teams" Toolbar={Toolbar}>
        <BasicList loading={isLoading} Content={TeamView} items={teams} tools={tools} identity={(i) => i._id} readonly={readonly} />
      </ToolbarSection>
    );
  }

  const Editor = ({ hideEditor }) => {
    const handleClose = useCallback((team) => {
      console.log('Team updated', team);
      if (team) {
        debounce(() => {
          upsertTeams(team);
        }, 25);
      }
      hideEditor();
      setTeam(null);
    }, [setTeam, upsertTeams]);

    if (!team) {
      return;
    }

    return (
      <TeamEditor entry={team} onClose={handleClose} company={company} readonly={readonly} />
    );
  }

  return (
    <SwipeableView ListView={ListView} Editor={Editor} />
  );
}

export default TeamsView;
