import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AssetListItemComponent } from '@pw/components/AssetListItem';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import Switch from '@pw/components/SwitchComponent';
import IDDisplay from '@pw/components/properties/IDDisplay';
import RequestStatusDisplay from '@pw/components/properties/RequestStatusDisplay';
import SourceDisplay from '@pw/components/properties/SourceDisplay';
import {
	REQUEST_NAMES,
	REQUEST_TYPES,
	REQUEST_TYPES_REVERSE,
} from '@pw/consts/requests';
import { b__black_20, bl, bw2 } from '@pw/styles/border';
import { mr3 } from '@pw/styles/margins';
import { pl2 } from '@pw/styles/padding';

function SampleContent({ item }) {
	const { sources = [] } = item;
	if (sources?.length !== 1) {
		return null;
	}
	return (
		<Stack
			sx={{
				...pl2,
				...mr3,
				...bl,
				...bw2,
				...b__black_20,
			}}
		>
			<AssetListItemComponent item={sources[0]} />
		</Stack>
	);
}

function RegaugeContent({ item }) {
	const { sources } = item;
	if (sources?.length !== 1) {
		return null;
	}
	return (
		<Stack
			sx={{
				...pl2,
				...mr3,
				...bl,
				...bw2,
				...b__black_20,
			}}
		>
			<AssetListItemComponent item={sources[0]} />
		</Stack>
	);
}

function PickContent() {
	return <Stack />;
}

function ShipContent() {
	return <Stack />;
}

function TransferContent() {
	return <Stack />;
}

export function RequestListItemComponent({ item }) {
	const requestType = REQUEST_TYPES_REVERSE?.[item?.type] ?? item?.type ?? '';
	return (
    <Stack
      spacing={0.5}
      sx={{ cursor: 'pointer', flexGrow: 1, textAlign: 'left' }}
    >
      <IDDisplay value={item?.name} />
      <SourceDisplay type={requestType} name={REQUEST_NAMES[item?.type]} />
      <RequestStatusDisplay status={item?.status} />
      <Switch value={item?.type}>
        <Switch.Case condition={REQUEST_TYPES.SAMPLE}>
          <SampleContent item={item} />
        </Switch.Case>
        <Switch.Case condition={REQUEST_TYPES.REGAUGE}>
          <RegaugeContent item={item} />
        </Switch.Case>
        <Switch.Case condition={REQUEST_TYPES.PICK}>
          <PickContent item={item} />
        </Switch.Case>
        <Switch.Case condition={REQUEST_TYPES.SHIP}>
          <ShipContent item={item} />
        </Switch.Case>
        <Switch.Case condition={REQUEST_TYPES.TRANSFER}>
          <TransferContent item={item} />
        </Switch.Case>
        <Switch.Default></Switch.Default>
      </Switch>
    </Stack>
  );
}

function RequestListItemContent({ item, onItemClick }) {
	return (
		<Box className='listContent' onClick={onItemClick}>
			<RequestListItemComponent item={item} />
		</Box>
	);
}

export default function RequestListComponent({
	item,
	onItemClick,
	buttonIcon,
}) {
	return (
		<Box className='listItem'>
			<RequestListItemContent item={item} onItemClick={onItemClick} />

			<Stack className='listButtons'>
				<IconCircleButton onClick={onItemClick}>{buttonIcon}</IconCircleButton>
			</Stack>
		</Box>
	);
}
