import { Stack } from '@mui/material';
import { FormikNumberField } from '@pw/components/Forms/FormikForm';
import FormikUnitField from '@pw/components/Forms/FormikUnitField';
import { unitField } from '@pw/components/Forms/unitFieldProperties';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import ModalWrapper from '@pw/components/ProductionDesigner/Diagram/NodeModal/ModalWrapper';
import { TEMPERATURE_UNIT_OPTIONS, TIME_UNIT_OPTIONS } from '@pw/consts/units';
import { useMemo } from 'react';
import * as yup from 'yup';

function NodeModal() {
  const changeSetGenerator = useMemo(
    () => (initialValues) => ({
      fermentation_time: unitField(initialValues?.fermentation_time),
      temperature: unitField(initialValues?.temperature),
      agitation_speed: [initialValues?.agitation_speed ?? 0, yup.number()],
    }),
    [],
  );

  return (
    <ModalWrapper changeSetGenerator={changeSetGenerator}>
      <Stack spacing={2}>
        <FormikUnitField name="fermentation_time" label="Fermentation Time" options={TIME_UNIT_OPTIONS} />
        <FlexBox>
          <FormikUnitField name="temperature" label="Target Temperature" options={TEMPERATURE_UNIT_OPTIONS} fullWidth />
          <FormikNumberField name="agitation_speed" label="Agitation Speed" fullWidth />
        </FlexBox>
      </Stack>
    </ModalWrapper>
  );
}

export default NodeModal;
