import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack } from '@mui/material';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H5 } from '@pw/components/Typography';
import styles from '@pw/styles/modal.styles';

export function ModalWithClose({
  title,
  icon = null,
  open,
  onClose,
  rootStyle = 'root',
  modalStyles,
  children
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Stack sx={modalStyles ?? styles} className={rootStyle} spacing={2}>
        <FlexBox>
          <Stack direction="row" spacing={2} alignItems="center">
            {icon || null}
            <H5>{title}</H5>
          </Stack>

          <IconButton
            onClick={() => onClose()}
            className="close-btn"
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </FlexBox>

        <Box
          sx={{
            overflowY: 'auto',
            height: 'auto',
            maxHeight: '90vh',
            paddingTop: '1rem'
          }}
        >
          <Stack spacing="1.5rem" sx={{ overflowX: 'hidden' }}>
            {children}
          </Stack>
        </Box>
      </Stack>
    </Modal>
  );
}
